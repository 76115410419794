import {NgModule} from '@angular/core';
import {MatLegacyButtonModule as MatButtonModule} from '@angular/material/legacy-button';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatLegacyCheckboxModule as MatCheckboxModule} from '@angular/material/legacy-checkbox';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatLegacyDialogModule as MatDialogModule} from '@angular/material/legacy-dialog';
import {MatLegacyFormFieldModule as MatFormFieldModule} from '@angular/material/legacy-form-field';
import {MatLegacyInputModule as MatInputModule} from '@angular/material/legacy-input';
import {MatLegacyProgressSpinnerModule as MatProgressSpinnerModule} from '@angular/material/legacy-progress-spinner';
import {MatLegacyRadioModule as MatRadioModule} from '@angular/material/legacy-radio';
import {MatLegacySelectModule as MatSelectModule} from '@angular/material/legacy-select';
import {MatLegacyTooltipModule as MatTooltipModule} from '@angular/material/legacy-tooltip';
import {RouterModule} from '@angular/router';
import {AufgabeSharedModule} from '@schir-int-client/aufgabe-shared';
import {TechModule} from '@schir-int-client/tech';
import {AufgabenInVerfuegungContainerComponent} from './aufgaben-in-verfuegung-container/aufgaben-in-verfuegung-container.component';
import {AufgabeAbschriftBodyComponent} from './aufgaben-in-verfuegung/aufgabe-in-verfuegung/aufgabe-abschrift-body/aufgabe-abschrift-body.component';
import {AufgabeInVerfuegungComponent} from './aufgaben-in-verfuegung/aufgabe-in-verfuegung/aufgabe-in-verfuegung.component';
import {AufgabeMitteilungBodyComponent} from './aufgaben-in-verfuegung/aufgabe-in-verfuegung/aufgabe-mitteilung-body/aufgabe-mitteilung-body.component';
import {AufgabeRechnungsstellungBodyComponent} from './aufgaben-in-verfuegung/aufgabe-in-verfuegung/aufgabe-rechnungsstellung-body/aufgabe-rechnungsstellung-body.component';
import {AufgabeVersandBodyComponent} from './aufgaben-in-verfuegung/aufgabe-in-verfuegung/aufgabe-versand-body/aufgabe-versand-body.component';
import {AufgabeWiedervorlageBodyComponent} from './aufgaben-in-verfuegung/aufgabe-in-verfuegung/aufgabe-wiedervorlage-body/aufgabe-wiedervorlage-body.component';
import {AufgabeZertifikatBodyComponent} from './aufgaben-in-verfuegung/aufgabe-in-verfuegung/aufgabe-zertifikat-body/aufgabe-zertifikat-body.component';
import {KorrespondenzButtonComponent} from './aufgaben-in-verfuegung/aufgabe-in-verfuegung/korrespondenz-button/korrespondenz-button.component';
import {KorrespondenzVorlageButtonsComponent} from './aufgaben-in-verfuegung/aufgabe-in-verfuegung/korrespondenz-vorlage-buttons/korrespondenz-vorlage-buttons.component';
import {AufgabenInVerfuegungComponent} from './aufgaben-in-verfuegung/aufgaben-in-verfuegung.component';
import {AufgabeAbschriftEditorComponent} from './aufgaben/aufgabe-abschrift-editor/aufgabe-abschrift-editor.component';
import {AufgabeMitteilungEditorComponent} from './aufgaben/aufgabe-mitteilung-editor/aufgabe-mitteilung-editor.component';
import {AufgabeRechnungsstellungEditorComponent} from './aufgaben/aufgabe-rechnungsstellung-editor/aufgabe-rechnungsstellung-editor.component';
import {AufgabeVersandEditorComponent} from './aufgaben/aufgabe-versand-editor/aufgabe-versand-editor.component';
import {AufgabeWiedervorlageEditorComponent} from './aufgaben/aufgabe-wiedervorlage-editor/aufgabe-wiedervorlage-editor.component';
import {AufgabeWithStaticTaetigkeitEditorComponent} from './aufgaben/aufgabe-with-static-taetigkeit-editor/aufgabe-with-static-taetigkeit-editor.component';
import {AufgabeZertifikatEditorComponent} from './aufgaben/aufgabe-zertifikat-editor/aufgabe-zertifikat-editor.component';
import {NotizInAufgabeEditorComponent} from './aufgaben/notiz-in-aufgabe-editor/notiz-in-aufgabe-editor.component';
import {CreateAufgabeDialogComponent} from './create-aufgabe-dialog/create-aufgabe-dialog.component';
import {CreateKorrespondenzDialogComponent} from './create-korrespondenz-dialog/create-korrespondenz-dialog.component';
import {KontaktKategorieCheckboxesComponent} from './kontakt-kategorie-checkboxes/kontakt-kategorie-checkboxes.component';
import {AufgabeAnschreibenEditorComponent} from './aufgaben/aufgabe-anschreiben-editor/aufgabe-anschreiben-editor.component';
import {AufgabeSchuldtitelEditorComponent} from './aufgaben/lfpr/aufgabe-schuldtitel-editor/aufgabe-schuldtitel-editor.component';
import {AufgabeKostenEditorComponent} from './aufgaben/lfpr/aufgabe-kosten-editor/aufgabe-kosten-editor.component';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {AufgabeLfprMitteilungEditorComponent} from './aufgaben/lfpr/aufgabe-mitteilung-editor/aufgabe-lfpr-mitteilung-editor.component';
import {AufgabeLfprWeglegenEditorComponent} from './aufgaben/lfpr/aufgabe-weglegen-editor/aufgabe-lfpr-weglegen-editor.component';
import {AufgabeLfprBlattabschriftEditorComponent} from './aufgaben/lfpr/aufgabe-blattabschrift-editor/aufgabe-lfpr-blattabschrift-editor.component';
import {AufgabeMessbriefEditorComponent} from './aufgaben/aufgabe-messbrief-editor/aufgabe-messbrief-editor.component';

@NgModule({
	imports: [
		CommonModule,
		TechModule,
		MatFormFieldModule,
		MatSelectModule,
		MatButtonModule,
		MatDialogModule,
		MatProgressSpinnerModule,
		MatDatepickerModule,
		MatCheckboxModule,
		MatButtonToggleModule,
		MatInputModule,
		MatRadioModule,
		FormsModule,
		AufgabeSharedModule,
		ReactiveFormsModule,
		RouterModule,
		MatTooltipModule,
	],
	declarations: [
		AufgabenInVerfuegungComponent,
		AufgabeInVerfuegungComponent,
		CreateAufgabeDialogComponent,
		CreateKorrespondenzDialogComponent,
		AufgabenInVerfuegungContainerComponent,
		KorrespondenzButtonComponent,
		AufgabeZertifikatBodyComponent,
		KontaktKategorieCheckboxesComponent,
		KorrespondenzVorlageButtonsComponent,
		NotizInAufgabeEditorComponent,
		AufgabeWithStaticTaetigkeitEditorComponent,
		AufgabeVersandEditorComponent,
		AufgabeAnschreibenEditorComponent,
		AufgabeZertifikatEditorComponent,
		AufgabeVersandBodyComponent,
		AufgabeAbschriftEditorComponent,
		AufgabeAbschriftBodyComponent,
		AufgabeMitteilungEditorComponent,
		AufgabeMitteilungBodyComponent,
		AufgabeMessbriefEditorComponent,
		AufgabeWiedervorlageBodyComponent,
		AufgabeWiedervorlageEditorComponent,
		AufgabeRechnungsstellungEditorComponent,
		AufgabeRechnungsstellungBodyComponent,
		AufgabeKostenEditorComponent,
		AufgabeSchuldtitelEditorComponent,
		AufgabeLfprMitteilungEditorComponent,
		AufgabeLfprBlattabschriftEditorComponent,
		AufgabeLfprWeglegenEditorComponent,
	],
	exports: [
		AufgabenInVerfuegungComponent,
		AufgabeInVerfuegungComponent,
		CreateAufgabeDialogComponent,
		AufgabeWiedervorlageEditorComponent,
		AufgabeRechnungsstellungEditorComponent,
		AufgabeWithStaticTaetigkeitEditorComponent,
		AufgabeVersandEditorComponent,
		AufgabeAnschreibenEditorComponent,
		AufgabeAbschriftEditorComponent,
		AufgabeMitteilungEditorComponent,
		AufgabeMessbriefEditorComponent,
		AufgabeZertifikatEditorComponent,
		AufgabeWiedervorlageEditorComponent,
		AufgabeKostenEditorComponent,
		AufgabeSchuldtitelEditorComponent,
		AufgabeLfprMitteilungEditorComponent,
		AufgabeLfprBlattabschriftEditorComponent,
		AufgabeLfprWeglegenEditorComponent,
	],
})
export class AufgabeModule {}
