<ul class="c-list" *ngIf="vorlageResources | async as _vorlageResources">
	<div class="c-list__item" *ngFor="let vorlage of _vorlageResources; let i = index" data-test-id="vorlage-list-item">
		<a class="c-list__link">
			<div class="c-row c-row--header">
				<span class="c-row__item c-row__item--header" data-test-id="vorlage-name">{{vorlage.name}}</span>
				<span class="c-row__item"></span>
			</div>
			<div class="c-row">
				<span class="c-row__item" data-test-id="vorlage-datei">Datei: {{ vorlage._embedded.file.name }}</span>
				<span class="c-row__item"
					data-test-id="vorlage-datum">{{vorlage._embedded.file.createdAt | formatDate}}</span>
			</div>
		</a>
		<div class="c-button-bar c-button-bar--overlay">
			<schir-int-client-button-round data-test-id="open-yes-no-dialog" type="button"
				(click)="openSubmitDialog(vorlage)" materialIcon="delete" toolTip="Vorlage löschen">
			</schir-int-client-button-round>
			<input (change)="onFileChanged(vorlage, $event)" id="input-file-{{i}}" data-test-id="file-input" type="file"
				accept=".doc, .docx" />
			<label for="input-file-{{i}}">
				<a mat-mini-fab data-test-id="edit-file-button" matTooltip="Vorlage hochladen und ersetzen" aria-label="Vorlage hochladen und ersetzen" color="primary">
					<mat-icon>edit</mat-icon>
				</a>
				<schir-int-client-button-round data-test-id="download-vorlage-button" (click)="open(vorlage)"
					materialIcon="arrow_downward" toolTip="Vorlage downloaden">
				</schir-int-client-button-round>
			</label>
		</div>
	</div>
</ul>
