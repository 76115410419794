<ul class="c-list" *ngIf="documents as pList">
	<li class="c-list__item" *ngFor="let document of pList; let i = index;"
		[attr.data-test-id]="'dokumentList '+ (document | testIdFromResource)">
		<a class="c-list__link" (click)="download(document)" (keyup.enter)="download(document)" data-test-id="dokumentLink" tabindex="0">
			<div class="c-row c-row--header">
				<span class="c-row__item c-row__item--header" data-test-id="dokumentenname">{{document.name}}</span>
				<span class="c-row__item c-row__item--secondary" data-test-id="lfdNrDokument">{{i+1}}</span>
			</div>
		</a>
	</li>
</ul>
