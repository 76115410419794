<h2 mat-dialog-title data-test-id="assign-vorgang-dialog">Vorgang zuweisen an Rechtspflege</h2>
<mat-dialog-content *ngIf="rechtspfleger | async as _rechtspfleger" [formGroup]="form">
	<mat-form-field appearance="outline" data-test-id="assign-vorgang-dialog-typ-select">
		<mat-label>Zielperson</mat-label>
		<mat-select data-test-id="one-value-select-input" [formControlName]="selectedRechtspflegerControlName">
			<mat-option *ngFor="let oneRechtspfleger of _rechtspfleger" [value]="getUrl(oneRechtspfleger)" data-test-id="one-value-select-value">
				{{oneRechtspfleger.firstName}} {{oneRechtspfleger.lastName}}
			</mat-option>
		</mat-select>
	</mat-form-field>
</mat-dialog-content>

<mat-dialog-actions>
	<button mat-stroked-button (click)="onCancel()" data-test-id="cancel-button">Abbrechen</button>
	<button mat-flat-button (click)="onSave()" [disabled]="!isValid()" color="primary" class="icon-right"
			data-test-id="assign-button">Zuweisen
	</button>
</mat-dialog-actions>
