import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import {AdressatResource, AufgabeResource, AufgabeRootState} from '@schir-int-client/aufgabe-shared';
import {
	DownloadKorrespondenzAction,
	DownloadKorrespondenzenArchivAction,
	DownloadKorrespondenzenMergeAction,
} from './korrespondenz.actions';

@Injectable({ providedIn: 'root' })
export class KorrespondenzFacade {
	constructor(private store: Store<AufgabeRootState>) { }

	getKorrespondenz(adressat: AdressatResource) {
		this.store.dispatch(new DownloadKorrespondenzAction(adressat));
	}

	getKorrespondenzenArchiv(aufgabe: AufgabeResource) {
		this.store.dispatch(new DownloadKorrespondenzenArchivAction(aufgabe));
	}

	getKorrespondenzenMerge(aufgabe: AufgabeResource) {
		this.store.dispatch(new DownloadKorrespondenzenMergeAction(aufgabe));
	}
}
