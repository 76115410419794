import {Action} from '@ngrx/store';
import {ResourceUri} from '@ngxp/rest';
import {VerfuegungResource} from '@schir-int-client/verfuegung-shared';
import {VorlageResource} from '@schir-int-client/vorlage-shared';
import {
	Adressat,
	AdressatListResource,
	AdressatWithResource,
	Aufgabe,
	AufgabeListResource,
	AufgabeResource,
} from './aufgabe.model';

export enum AufgabeActions {
	LOAD_AUFGABEN_BY_VERFUEGUNG = 'LOAD_AUFGABEN_BY_VERFUEGUNG',
	LOAD_AUFGABEN_BY_VERFUEGUNG_SUCCESS = 'LOAD_AUFGABEN_BY_VERFUEGUNG_SUCCESS',
	CREATE_AUFGABE = 'CREATE_AUFGABE',
	CREATE_AUFGABE_SUCCESS = 'CREATE_AUFGABE_SUCCESS',
	UPDATE_AUFGABE = 'UPDATE_AUFGABE',
	UPDATE_AUFGABE_SUCCESS = 'UPDATE_AUFGABE_SUCCESS',
	LOAD_ADRESSATEN = 'LOAD_ADRESSATEN',
	LOAD_ADRESSATEN_SUCCESS = 'LOAD_ADRESSATEN_SUCCESS',
	ADRESSATEN_ENRICHED = 'ADRESSATEN_ENRICHED',
	ADD_ADRESSAT_TO_AUFGABE = 'ADD_ADRESSAT_TO_AUFGABE',
	SET_CHANNEL = 'SET_CHANNEL',
	REMOVE_KONTAKT_FROM_AUFGABE = 'REMOVE_KONTAKT_FROM_AUFGABE',
	AUFGABE_MARK_AS_ERLEDIGT = 'AUFGABE_MARK_AS_ERLEDIGT',
	AUFGABE_MARK_AS_FREIGEGEBEN = 'AUFGABE_MARK_AS_FREIGEGEBEN',
	LOAD_VORLAGE = 'LOAD_VORLAGE',
	VORLAGE_LOADED = 'VORLAGE_LOADED',
	LOAD_AUFGABE_SUCCESS = 'LOAD_AUFGABE_SUCCESS',
	CLEAR_ADRESSATEN_BY_AUFGABE = 'CLEAR_ADRESSATEN_BY_AUFGABE',
	DELETE_AUFGABE = 'DELETE_AUFGABE',
	CLEAR_SELECTED_AUFGABE = 'CLEAR_SELECTED_AUFGABE'
}

export class LoadAufgabenByVerfuegungAction implements Action {
	type = AufgabeActions.LOAD_AUFGABEN_BY_VERFUEGUNG;

	constructor() { }
}

export class LoadAufgabenByVerfuegungSuccessAction implements Action {
	type = AufgabeActions.LOAD_AUFGABEN_BY_VERFUEGUNG_SUCCESS;

	constructor(public verfuegung: VerfuegungResource, public aufgaben: AufgabeListResource) { }
}

export class CreateAufgabeAction implements Action {
	type = AufgabeActions.CREATE_AUFGABE;

	constructor(public aufgabe: Aufgabe) { }
}

export class CreateAufgabeSuccessAction implements Action {
	type = AufgabeActions.CREATE_AUFGABE_SUCCESS;

	constructor(public aufgabe: AufgabeResource) { }
}

export class UpdateAufgabeAction implements Action {
	type = AufgabeActions.UPDATE_AUFGABE;

	constructor(public aufgabe: Aufgabe) { }
}

export class UpdateAufgabeSuccessAction implements Action {
	type = AufgabeActions.UPDATE_AUFGABE_SUCCESS;

	constructor(public aufgabe: AufgabeResource) { }
}

export class LoadAdressatenByAufgabeAction implements Action {
	type = AufgabeActions.LOAD_ADRESSATEN;

	constructor(public aufgabe: AufgabeResource) { }
}

export class LoadAdressatenSuccessAction implements Action {
	type = AufgabeActions.LOAD_ADRESSATEN_SUCCESS;

	constructor(public aufgabeUri: ResourceUri, public adressaten: AdressatListResource) { }
}

export class AdressatenEnrichedAction implements Action {
	type = AufgabeActions.ADRESSATEN_ENRICHED;

	constructor(public aufgabeUri: ResourceUri, public adressaten) { }
}

export class SetChannelAction implements Action {
	type = AufgabeActions.SET_CHANNEL;

	constructor(public adressat: Adressat) { }
}

export class RemoveAdressatAction implements Action {
	type = AufgabeActions.REMOVE_KONTAKT_FROM_AUFGABE;

	constructor(public adressat: AdressatWithResource) { }
}

export class AddAdressatToAufgabeAction implements Action {
	type = AufgabeActions.ADD_ADRESSAT_TO_AUFGABE;

	constructor(public adressat: AdressatWithResource) { }
}

export class AufgabeMarkAsErledigtAction implements Action {
	type = AufgabeActions.AUFGABE_MARK_AS_ERLEDIGT;

	constructor(public aufgabeResource: AufgabeResource) { }
}

export class AufgabeMarkAsFreigegebenAction implements Action {
	type = AufgabeActions.AUFGABE_MARK_AS_FREIGEGEBEN;

	constructor(public aufgabeResource: AufgabeResource) { }
}

export class LoadVorlageAction implements Action {
	type = AufgabeActions.LOAD_VORLAGE;

	constructor(public aufgabe: AufgabeResource) { }
}

export class VorlageLoadedAction implements Action {
	type = AufgabeActions.VORLAGE_LOADED;

	constructor(public vorlage: VorlageResource) { }
}

export class LoadAufgabeSuccessAction implements Action {
	type = AufgabeActions.LOAD_AUFGABE_SUCCESS;

	constructor(public aufgabe: AufgabeResource) { }
}

export class ClearAdressatenByAufgabeAction implements Action {
	type = AufgabeActions.CLEAR_ADRESSATEN_BY_AUFGABE;

	constructor(public aufgabeUri: ResourceUri) { }
}

export class DeleteAufgabeAction implements Action {
	type = AufgabeActions.DELETE_AUFGABE;

	constructor(public aufgabe: AufgabeResource) { }
}

export class ClearSelectedAufgabeAction implements Action {
	type = AufgabeActions.CLEAR_SELECTED_AUFGABE;

	constructor() { }
}
