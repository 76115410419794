import {Component, EventEmitter, Input, Output} from '@angular/core';
import {KontaktKategorie, KontaktResource, KontaktWithKategorie} from '@schir-int-client/adressverwaltung-shared';
import {VerfahrenResource} from '@schir-int-client/verfahren-shared';

@Component({
	selector: 'schir-int-client-kontakte-in-kategorie',
	templateUrl: './kontakte-in-kategorie.component.html',
	styleUrls: ['./kontakte-in-kategorie.component.scss'],
})
export class KontakteInKategorieComponent {

	@Input() kontakte: KontaktResource[] = [];
	@Input() kategorie: KontaktKategorie;
	@Input() draggable: boolean = false;
	@Input() unassignable: boolean = false;
	@Input() verfahren: VerfahrenResource;

	@Output() unassignKontakt: EventEmitter<KontaktWithKategorie> = new EventEmitter();
}


