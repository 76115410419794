export enum VorgangLinkRel {
	SELF = 'self',
	VERFUEGUNGEN = 'verfuegungen',
	VERFAHREN = 'verfahren',
	RECHTSPFLEGER = 'rechtspfleger',
	ASSIGN_TO_RECHTSPFLEGE = 'assignToRechtspflege',
	OFFENE_VERFUEGUNG = 'offeneVerfuegung',
	ASSIGN_POSTEINGANG = 'assignPosteingang',
	UPDATE_NOTIZ = 'updateNotiz',
	POSTEINGANG = 'posteingang',
	POSTEINGAENGE = 'posteingaenge',
	MARK_AS_RECHTSPFLEGE = 'markAsRechtspflege',
	MARK_AS_RUECKLAUF = 'markAsRuecklauf',
	CREATE_WIEDERVORLAGE = 'createWiedervorlage',
	MARK_AS_ABGESCHLOSSEN = 'markAsAbgeschlossen',
	PROPOSE_WIEDERVORLAGEDATUM = 'proposeWiedervorlageDatum',
	UPDATE_WIEDERVORLAGE = 'updateWiedervorlage',
	DELETE = 'delete'
}

export enum VorgangListLinkRel {
	VORGANG_LIST = 'vorgangList'
}
