import {Component, Input} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {AufgabenTyp} from '@schir-int-client/aufgabe-shared';
import {CreateAufgabeFormService} from '../../create-aufgabe-dialog/create-aufgabe.formservice';

@Component({
	selector: 'schir-int-client-aufgabe-wiedervorlage-editor',
	templateUrl: './aufgabe-wiedervorlage-editor.component.html',
	styleUrls: ['./aufgabe-wiedervorlage-editor.component.scss'],
})
export class AufgabeWiedervorlageEditorComponent {
	@Input() aufgabenTyp: AufgabenTyp;

	constructor(private formService: CreateAufgabeFormService) { }

	get formGroup(): UntypedFormGroup {
		return this.aufgabenTyp == AufgabenTyp.LFPR_WIEDERVORLAGE ? this.formService.controlGroupLfprWiedervorlage : this.formService.controlGroupWiedervorlage;
	}

	get bodyGroup(): UntypedFormGroup {
		return <UntypedFormGroup>this.formGroup.controls[CreateAufgabeFormService.feldAufgabeBody];
	}

	get wiedervorlageDatumFieldControl(): UntypedFormControl {
		return <UntypedFormControl>this.bodyGroup.controls[CreateAufgabeFormService.feldWiedervorlageWiedervorlageDatum];
	}
}
