import {Action} from '@ngrx/store';
import {ResourceUri} from '@ngxp/rest';
import {VorgangResource} from '@schir-int-client/vorgang-shared';
import {VerfuegungListResource, VerfuegungResource} from './verfuegung.model';

export enum VerfuegungActions {
	CREATE_VERFUEGUNG_BY_VORGANG = 'CREATE_VERFUEGUNG_BY_VORGANG',
	CREATE_VERFUEGUNG_BY_VORGANG_SUCCESS = 'CREATE_VERFUEGUNG_BY_VORGANG_SUCCESS',
	DELETE_VERFUEGUNG = 'DELETE_VERFUEGUNG',
	DELETE_VERFUEGUNG_SUCCESS = 'DELETE_VERFUEGUNG_SUCCESS',
	DELETE_VERFUEGUNG_FAIL = 'DELETE_VERFUEGUNG_FAIL',
	CREATE_KORRESPONDENZ_VERFUEGUNG_BY_VORGANG = 'CREATE_KORRESPONDENZ_VERFUEGUNG_BY_VORGANG',
	CREATE_KORRESPONDENZ_VERFUEGUNG_BY_VORGANG_SUCCESS = 'CREATE_KORRESPONDENZ_VERFUEGUNG_BY_VORGANG_SUCCESS',
	LOAD_VERFUEGUNGEN_BY_VORGANG = 'LOAD_VERFUEGUNGEN_BY_VORGANG',
	LOAD_VERFUEGUNGEN_BY_VORGANG_SUCCESS = 'LOAD_VERFUEGUNGEN_BY_VORGANG_SUCCESS',
	LOAD_VERFUEGUNG = 'LOAD_VERFUEGUNG',
	LOAD_VERFUEGUNG_SUCCESS = 'LOAD_VERFUEGUNG_SUCCESS',
	SET_SELECTED_VERFUEGUNG_ACTION = 'SET_SELECTED_VERFUEGUNG_ACTION',
	MARK_AS_ERLEDIGT = 'MARK_AS_ERLEDIGT',
	MARK_AS_ERLEDIGT_SUCCESS = 'MARK_AS_ERLEDIGT_SUCCESS',
	UPDATE_VERFUEGUNG_NOTIZ = 'UPDATE_VERFUEGUNG_NOTIZ',
	UPDATE_VERFUEGUNG_NOTIZ_SUCCESS = 'UPDATE_VERFUEGUNG_NOTIZ_SUCCESS',
	VERFUEGUNG_DRUCKEN = 'VERFUEGUNG_DRUCKEN',
	VERFUEGUNG_DRUCKEN_SUCCESS = 'VERFUEGUNG_DRUCKEN_SUCCESS'
}

export class LoadVerfuegungenByVorgangAction implements Action {
	type = VerfuegungActions.LOAD_VERFUEGUNGEN_BY_VORGANG;
}

export class LoadVerfuegungenByVorgangSuccessAction implements Action {
	type = VerfuegungActions.LOAD_VERFUEGUNGEN_BY_VORGANG_SUCCESS;

	constructor(public vorgang: VorgangResource, public verfuegungen: VerfuegungListResource) { }
}

export class CreateVerfuegungByVorgangAction implements Action {
	type = VerfuegungActions.CREATE_VERFUEGUNG_BY_VORGANG;

	constructor(public notiz: string) { }
}

export class CreateVerfuegungByVorgangSuccessAction implements Action {
	type = VerfuegungActions.CREATE_VERFUEGUNG_BY_VORGANG_SUCCESS;

	constructor(public vorgang: VorgangResource, public verfuegung: VerfuegungResource) { }
}

export class CreateKorrespondenzVerfuegungByVorgangAction implements Action {
	type = VerfuegungActions.CREATE_KORRESPONDENZ_VERFUEGUNG_BY_VORGANG;

	constructor(public notiz: string) { }
}

export class CreateKorrespondenzVerfuegungByVorgangSuccessAction implements Action {
	type = VerfuegungActions.CREATE_KORRESPONDENZ_VERFUEGUNG_BY_VORGANG_SUCCESS;

	constructor(public vorgang: VorgangResource, public verfuegung: VerfuegungResource) { }
}

export class LoadVerfuegungAction implements Action {
	type = VerfuegungActions.LOAD_VERFUEGUNG;

	constructor(public verfuegungUri: ResourceUri) { }
}

export class LoadVerfuegungSuccessAction implements Action {
	type = VerfuegungActions.LOAD_VERFUEGUNG_SUCCESS;

	constructor(public verfuegung: VerfuegungResource) { }
}

export class SetSelectedVerfuegungAction implements Action {
	type = VerfuegungActions.SET_SELECTED_VERFUEGUNG_ACTION;

	constructor(public verfuegung: VerfuegungResource) { }
}

export class VerfuegungMarkAsErledigtAction implements Action {
	type = VerfuegungActions.MARK_AS_ERLEDIGT;

	constructor(public verfuegung: VerfuegungResource) { }
}

export class VerfuegungMarkAsErledigtSuccessAction implements Action {
	type = VerfuegungActions.MARK_AS_ERLEDIGT_SUCCESS;
}

export class VerfuegungDeleteAction implements Action {
	type = VerfuegungActions.DELETE_VERFUEGUNG;

	constructor(public verfuegung: VerfuegungResource) { }
}

export class VerfuegungDeleteSuccessAction implements Action {
	type = VerfuegungActions.DELETE_VERFUEGUNG_SUCCESS;

	constructor(public verfuegungen: VerfuegungListResource, public deleted: VerfuegungResource) { }
}

export class VerfuegungDeleteFailAction implements Action {
	type = VerfuegungActions.DELETE_VERFUEGUNG_FAIL;

	constructor(public error: any) { }
}

export class UpdateVerfuegungNotizAction implements Action {
	type = VerfuegungActions.UPDATE_VERFUEGUNG_NOTIZ;

	constructor(public notiz: string, public verfuegungUri: ResourceUri) { }
}

export class UpdateVerfuegungNotizSuccessAction implements Action {
	type = VerfuegungActions.UPDATE_VERFUEGUNG_NOTIZ_SUCCESS;

	constructor(public verfuegung: VerfuegungResource) { }
}

export class CreateVerfuegungDruckAction implements Action {
	type = VerfuegungActions.VERFUEGUNG_DRUCKEN;

	constructor() { }
}

export class CreateVerfuegungDruckSuccessAction implements Action {
	type = VerfuegungActions.VERFUEGUNG_DRUCKEN_SUCCESS;

	constructor(public file: Blob) { }
}
