<h2 mat-dialog-title tabindex="0" class="narrow" data-test-id="create-korrespondenz-verfuegung-dialog-title">Korrespondenz erstellen</h2>

<form [formGroup]="form">
	<div mat-dialog-content data-test-id="create-korrespondenz-verfuegung-dialog-content">
		<schir-int-client-aufgabe-anschreiben-editor data-test-id="korrespondenz-verfuegung-aufgabe-anschreiben"
													 [vorlagen]="vorlagen | async">
		</schir-int-client-aufgabe-anschreiben-editor>
		<mat-form-field appearance="outline">
			<mat-label>Notiz zur Verfügung</mat-label>
			<textarea matInput data-test-id="verfuegungs-notiz-input" [formControl]="formService.controlVerfuegungNotiz"></textarea>
		</mat-form-field>
	</div>

	<mat-dialog-actions>
		<button mat-stroked-button (click)="onCancel()" type="button" class="focus-box-shadow-dark" data-test-id="abbrechen-button">
			Abbrechen
		</button>
		<button mat-flat-button color="primary" type="submit" (click)="onSave()" [disabled]="!isValid()" class="icon-right focus-box-shadow-dark"
				data-test-id="speichern-button">
			Speichern
		</button>
	</mat-dialog-actions>
</form>
