export enum KontaktListLinkRel {
	KONTAKT_LIST = 'kontaktList'
}

export enum KontaktLinkRel {
	SELF = 'self',
	EDIT = 'edit',
	DELETE = 'delete',
	VERFAHREN_NOTIZ = 'verfahrenNotiz',
	VERFAHREN_AKTENZEICHEN = 'verfahrenAktenzeichen',
	VERFAHREN_BY_KONTAKT = 'verfahrenByKontakt'
}
