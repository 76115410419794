import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatLegacyButtonModule as MatButtonModule} from '@angular/material/legacy-button';
import {MatLegacyCheckboxModule as MatCheckboxModule} from '@angular/material/legacy-checkbox';
import {MatLegacyDialogModule as MatDialogModule} from '@angular/material/legacy-dialog';
import {MatLegacyFormFieldModule as MatFormFieldModule} from '@angular/material/legacy-form-field';
import {MatLegacyInputModule as MatInputModule} from '@angular/material/legacy-input';
import {MatLegacyProgressSpinnerModule as MatProgressSpinnerModule} from '@angular/material/legacy-progress-spinner';
import {AlternativeTextSharedModule} from '@schir-int-client/alternative-text-shared';
import {RegisterSharedModule} from '@schir-int-client/register-shared';
import {TechModule} from '@schir-int-client/tech';
import {AmtlichenAusdruckErstellenDialogComponent} from './amtlichen-ausdruck-erstellen-dialog/amtlichen-ausdruck-erstellen-dialog.component';
import {AmtlichenAusdruckErstellenEntryComponent} from './amtlichen-ausdruck-erstellen-dialog/amtlichen-ausdruck-erstellen-entry/amtlichen-ausdruck-erstellen-entry.component';


@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		MatFormFieldModule,
		MatCheckboxModule,
		MatInputModule,
		MatDialogModule,
		AlternativeTextSharedModule,
		RegisterSharedModule,
		MatButtonModule,
		MatProgressSpinnerModule,
		TechModule,
	],
	declarations: [
		AmtlichenAusdruckErstellenDialogComponent,
		AmtlichenAusdruckErstellenEntryComponent,
	],
	exports: [
		AmtlichenAusdruckErstellenDialogComponent,
	],
})
export class AlternativeTextModule {}
