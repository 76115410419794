import {
	AfterViewInit,
	Component,
	ElementRef,
	EventEmitter,
	Input,
	OnChanges,
	OnInit,
	Output,
	SimpleChanges,
	ViewChild,
} from '@angular/core';
import {FormGroupDirective, NgForm, UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {isEmpty, isNil} from 'lodash-es';
import {Moment} from 'moment';
import {Observer, Subscription} from 'rxjs';
import {addAriaAttributesToMatSelect, Selectable, SelectableSpaltenFeld, SpaltenFeld} from '../schir-utils';
import {ErrorStateMatcher} from '@angular/material/core';
import {formatAsDatepicker} from '../../../../../apps/int-client-e2e/src/helpers/date';

@Component({
	selector: 'schir-int-client-one-value-editor',
	templateUrl: './one-value-editor.component.html',
	styleUrls: ['./one-value-editor.component.scss'],
})
export class OneValueEditorComponent implements OnInit, AfterViewInit, OnChanges {

	@Input() formGroup: UntypedFormGroup;
	@Input() column; // RegisterBlattColumn;
	@Input('type') inputType: string;
	@Input('values') selectableValues: Selectable[];
	@Input('defaultSelectable') defaultSelectable: Selectable;

	//Neue Struktur
	@Input('fieldControl') inputFieldControl: UntypedFormControl;

	@Input('label') inputLabel: string;
	@Input() feld: SpaltenFeld;
	@Input() readonly: boolean = false;
	@Input() required: boolean = false;
	@Input() stepsize: number = 0.01;
	@Input() initialFocus: boolean;
	@Input() evaluateAfterChange: boolean = false;
	@Input() hideBoxShadow: boolean = false;

	@Input() allowEmptyString: boolean = true;

	@Output() value: EventEmitter<string | number | boolean | Moment | Date> = new EventEmitter();

	@ViewChild('field') field: ElementRef;

	values: Selectable[];
	defaultValue: string;
	messages = ValidationMessages;
	subscription: Subscription = null;
	readonly matcher: ErrorStateMatcher = new class implements ErrorStateMatcher {
		isErrorState(control: UntypedFormControl | null, form: FormGroupDirective | NgForm | null): boolean {
			return Boolean(control && control.invalid && (control.dirty || control.touched));
		}
	};

	resetToDefaultValueObserver: Observer<any> = new class implements Observer<any> {
		constructor(private parent: OneValueEditorComponent) {
			// nothing to do
		}

		next(value) {
			let control = this.parent.fieldControl;
			let defaultValue = this.parent.defaultSelectable;

			if (defaultValue && !value && control && !control.value && control.pristine) {
				let emit = this.parent.feld?.type == 'select';
				if (this.parent.isTextType) {
					control.patchValue(defaultValue, { onlySelf: true, emitEvent: emit });
				} else {
					control.patchValue(defaultValue.value, { onlySelf: true, emitEvent: emit });
				}
			}
		}

		complete(): void {}

		error(err: any): void {}
	}(this);

	ngOnInit(): void {
		addAriaAttributesToMatSelect();

		if (this.feld?.validators != null) {
			this.fieldControl.setValidators(this.feld.validators);
		}
	}

	ngAfterViewInit() {
		// Achtung! Jeder der beiden Werte kann bei numerischen Feldern undefiniert sein, mindestens einer ist aber 'number'
		if (this.field && (this.feld?.type === 'number' || this.inputType === 'number')) {
			this.field.nativeElement.addEventListener('keydown', function (e) {
				// prevent: "e", "+", "."
				if ([69, 187, 190].includes(e.keyCode)) {
					e.preventDefault();
				}
			});
		}
	}

	get fieldControl() {
		if (!isNil(this.inputFieldControl)) {
			return this.inputFieldControl;
		}
		if (isNil(this.formGroup)) {
			console.warn('Missing Input formGroup');
			return null;
		}
		if (isNil(this.formGroup.controls)) {
			return this.formGroup;
		} // manchmal wird das Element anstelle der Gruppe gegeben

		if (isNil(this.feld)) {
			console.warn('Missing Input column (feld)');
			return null;
		}

		const control = this.formGroup.controls[this.feld.name];
		if (isNil(control)) {
			console.warn('missing form field control for column field ' + this.feld.name + ' in form Group');
			return null;
		}
		return control;
	}

	get errorMessages() {
		switch (this.type) {
			case 'text':
				if (this.fieldControl?.hasError('uSigTooLong')) {
					return this.messages.USIG_TOO_LONG;
				}
				if (this.fieldControl?.hasError('required')) {
					return this.messages.NO_VALID_VALUE;
				}
				if (this.fieldControl?.hasError('maxlength')) {
					const error = this.fieldControl?.getError('maxlength');

					return 'Der eingegebene Wert darf maximal ' + error.requiredLength + ' Zeichen lang sein.';
				}
				break;
			case 'select':
				if (this.fieldControl?.hasError('required')) {
					return this.label + ' ' + this.messages.MISSING_REQUIRED_SELECT_VALUE;
				}
				break;
			case 'number':
				if (this.fieldControl?.hasError('required')) {
					return this.label + ' ' + this.messages.MISSING_REQUIRED_SELECT_VALUE;
				} else if (this.fieldControl?.hasError('pattern')) {
					return this.messages.WRONG_DECIMAL_NUMBER;
				}
				break;
			default:
				return isNil(this.formGroup) ? this.fieldControl.errors : this.formGroup.errors;
		}
	}

	get type(): string {
		if (!isNil(this.inputType)) {
			return this.inputType;
		}
		if (!isNil(this.feld)) {
			return this.feld.type;
		}
		return 'text';
	}

	clearField(formControl: UntypedFormControl, event: Event) {
		event.preventDefault();
		formControl.setValue(null);
		formControl.markAsDirty();
	}

	get label(): string {
		return !isNil(this.inputLabel) ? this.inputLabel : this.feld?.label;
	}

	get isTextType(): boolean {
		return this.type === 'text';
	}

	get isNumberType(): boolean {
		return this.type === 'number';
	}

	get isSelectType(): boolean {
		return this.type === 'select';
	}

	get isAutocompleteType(): boolean {
		return this.type === 'autocomplete';
	}

	get isDateType(): boolean {
		return this.type === 'date';
	}

	get isLongTextType(): boolean {
		return this.type === 'longText';
	}

	get isCheckboxType(): boolean {
		return this.type === 'checkbox';
	}

	onChange(value: string | number | boolean | Moment) {
		if (!this.isCheckboxType) {
			if (!this.allowEmptyString && isEmpty(value)) {
				value = null;

				this.fieldControl.setValue(null);
			}
		}

		this.value.emit(value);
	}

	ngOnChanges(changes: SimpleChanges) {
		this.values = this.getValues();

		// if the defaultSelectable was given with a label only, find the corresponding value matching by label
		if (this.defaultSelectable && !this.defaultSelectable.value) {
			let found = this.values.find(value => value.label == this.defaultSelectable.label);

			if (found) {
				this.defaultSelectable = found;
			}
		}

		if (this.subscription) {
			this.subscription.unsubscribe();
		}

		if (this.defaultSelectable && this.fieldControl) {
			this.subscription = this.fieldControl.valueChanges.subscribe(this.resetToDefaultValueObserver);

			// trigger the observer to set the default value initially if applicable
			this.resetToDefaultValueObserver.next(null);
		}

		if (this.defaultSelectable && this.formGroup && !this.fieldControl.value) {
			this.fieldControl.setValue(this.feld.defaultValue.value);
			this.fieldControl.markAsDirty();
		}
	}

	private getValues(): Selectable[] {
		if (!isNil(this.selectableValues)) {
			return this.selectableValues;
		}
		if (isNil(this.feld)) {
			return [];
		}
		const feldValues = (<SelectableSpaltenFeld>this.feld).values;

		return isNil(feldValues) ? [] : feldValues.map(value => {
			return <Selectable>{ label: value, value };
		});
	}

	onlyGainFocus(event: MouseEvent, element: HTMLElement): void {
		element.focus();
		event.preventDefault();
	}

	getDisplayValue(): string {
		const currentValue = this.fieldControl.value;
		switch (this.type) {
			case 'date':
				return formatAsDatepicker(currentValue);
			case 'select':
				return this.values.find(v => v.value === currentValue)?.label;
			default:
				return currentValue;
		}
	}
}

export enum ValidationMessages {
	MISSING_REQUIRED_SELECT_VALUE = 'Ein Wert muss ausgewählt werden.',
	WRONG_DECIMAL_NUMBER = 'Es sind maximal zwei Nachkommastellen zulässig',
	NO_VALID_VALUE = 'Bitte geben sie einen gültigen Wert ein.',
	USIG_TOO_LONG = 'Es sind maximal 8 Zeichen zulässig.'
}
