import {ScrollingModule} from '@angular/cdk/scrolling';
import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatLegacyButtonModule as MatButtonModule} from '@angular/material/legacy-button';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatLegacyCheckboxModule as MatCheckboxModule} from '@angular/material/legacy-checkbox';
import {MatLegacyOptionModule as MatOptionModule} from '@angular/material/legacy-core';
import {MatLegacyDialogModule as MatDialogModule} from '@angular/material/legacy-dialog';
import {MatLegacyFormFieldModule as MatFormFieldModule} from '@angular/material/legacy-form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatLegacyInputModule as MatInputModule} from '@angular/material/legacy-input';
import {MatLegacyProgressSpinnerModule as MatProgressSpinnerModule} from '@angular/material/legacy-progress-spinner';
import {MatLegacySelectModule as MatSelectModule} from '@angular/material/legacy-select';
import {MatLegacyTableModule as MatTableModule} from '@angular/material/legacy-table';
import {MatLegacyTabsModule as MatTabsModule} from '@angular/material/legacy-tabs';
import {MatLegacyTooltipModule as MatTooltipModule} from '@angular/material/legacy-tooltip';
import {RouterModule, Routes} from '@angular/router';
import {RestModule} from '@ngxp/rest';
import {AdressverwaltungModule} from '@schir-int-client/adressverwaltung';
import {AlternativeTextModule} from '@schir-int-client/alternative-text';
import {AufgabeModule} from '@schir-int-client/aufgabe';
import {TechModule} from '@schir-int-client/tech';
import {VerfahrenSharedModule} from '@schir-int-client/verfahren-shared';
import {VorgangModule} from '@schir-int-client/vorgang';
import {VerfuegungModule} from 'libs/verfuegung/src/lib/verfuegung.module';
import {CreateVerfahrenDialogComponent} from './create-verfahren-dialog/create-verfahren-dialog.component';
import {VerfahrenGlobalSearchComponent} from './verfahren-global-search/verfahren-global-search.component';
import {VerfahrenListComponent} from './verfahren-list/verfahren-list.component';
import {VerfahrenSingleButtonsComponent} from './verfahren-list/verfahren-single/verfahren-single-buttons/verfahren-single-buttons.component';
import {VerfahrenSingleComponent} from './verfahren-list/verfahren-single/verfahren-single.component';
import {VerfahrenMainComponent} from './verfahren-main/verfahren-main.component';
import {VerfahrenSearchSingleComponent} from './verfahren-search/verfahren-search-single/verfahren-search-single.component';
import {VerfahrenSearchComponent} from './verfahren-search/verfahren-search.component';
import {MatLegacyMenuModule as MatMenuModule} from '@angular/material/legacy-menu';
import {MatLegacyListModule as MatListModule} from '@angular/material/legacy-list';
import {AuthGuard} from '../../../guards/src';
import {AktenzeichenModule} from '@schir-int-client/aktenzeichen';

const routes: Routes = [
	{
		path: 'verfahren',
		component: VerfahrenMainComponent,
		canActivate: [AuthGuard],
		canActivateChild: [AuthGuard],
	},
	{
		path: 'verfahrenDetail/:verfahrenUri',
		loadChildren: () => import('@schir-int-client/register').then(m => m.RegisterModule),
	},
];

@NgModule({
	imports: [
		CommonModule,
		TechModule,
		VerfahrenSharedModule,
		FormsModule,
		ReactiveFormsModule,
		MatTableModule,
		MatButtonModule,
		MatButtonToggleModule,
		MatDialogModule,
		MatIconModule,
		MatTabsModule,
		MatTooltipModule,
		MatOptionModule,
		MatSelectModule,
		MatCheckboxModule,
		MatFormFieldModule,
		ScrollingModule,
		RestModule,
		RouterModule.forChild(routes),
		MatInputModule,
		FormsModule,
		VerfuegungModule,
		VorgangModule,
		AufgabeModule,
		AdressverwaltungModule,
		MatProgressSpinnerModule,
		AlternativeTextModule,
		MatMenuModule,
		MatListModule,
		AktenzeichenModule,
	],
	declarations: [
		VerfahrenListComponent,
		VerfahrenSearchComponent,
		VerfahrenSingleComponent,
		VerfahrenSearchSingleComponent,
		CreateVerfahrenDialogComponent,
		VerfahrenGlobalSearchComponent,
		VerfahrenMainComponent,
		VerfahrenSingleButtonsComponent,
	],
	exports: [
		VerfahrenListComponent,
		VerfahrenSearchComponent,
		VerfahrenSingleComponent,
		VerfahrenGlobalSearchComponent,
	],
})
export class VerfahrenModule {}
