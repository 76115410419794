<div class="x-section__headline">
	<h2 class="mat-h1" data-test-id="monats-export-header">Monats-Statistik</h2>
</div>
<div class="mstatistik-container">
	<table data-test-id="monats-statistik-table" mat-table [dataSource]="dataSource" aria-labelledby="legende">
		<ng-container *ngFor="let col of displayedColumns" matColumnDef="{{col}}">
			<td mat-cell *matCellDef="let element" class="col-center">{{element[col]}}</td>
		</ng-container>
		<tr mat-row *matRowDef="let row;let i = index; columns: displayedColumns;" class="helper" [attr.data-test-id]="'mat-row-'+i"></tr>
	</table>
</div>
<p id="legende">Gesamtzahl der Verfahren nach Status und Register</p>
<div class="export-actions">
	<button mat-stroked-button (click)="onCancel()" dialogFocus color="primary" data-test-id="dialog-close-button" class="focus-box-shadow-dark">Schließen</button>
	<button mat-flat-button color="primary" data-test-id="dialog-export-button" class="focus-box-shadow-dark" (click)="onSave()"
			matTooltip="Monatsstatistik in die Zwischenablage kopieren" aria-label="Monatsstatistik in die Zwischenablage kopieren">Exportieren</button>
</div>
