import {AfterContentInit, Component, ElementRef, Input, OnChanges, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Actions} from '@ngrx/effects';
import {getUrl} from '@ngxp/rest';
import {VerfahrenLinkRel, VerfahrenResource} from '@schir-int-client/verfahren-shared';
import {VorgangActions, VorgangFacade, VorgangLinkRel, VorgangResource} from '@schir-int-client/vorgang-shared';
import {Spinner} from '@schir-int-client/tech';
import {Observable} from 'rxjs';

@Component({
	selector: 'schir-int-client-vorgaenge-in-verfahren',
	templateUrl: './vorgaenge-in-verfahren.component.html',
	styleUrls: ['./vorgaenge-in-verfahren.component.scss'],
})
export class VorgaengeInVerfahrenComponent implements OnInit, OnChanges, AfterContentInit, OnDestroy {

	@Input() verfahren: VerfahrenResource;
	@ViewChild('spinner', { static: true }) spinnerRef: ElementRef;

	vorgaengeInVerfahren: Observable<VorgangResource[]>;
	readonly verfahrenLinkRel = VerfahrenLinkRel;

	private spinner: Spinner;

	constructor(private vorgangFacade: VorgangFacade, private actions: Actions) {}

	ngOnInit(): void {
		this.vorgaengeInVerfahren = this.vorgangFacade.getVorgaengeByVerfahren(this.verfahren);
	}

	ngOnChanges(): void {
		this.vorgaengeInVerfahren = this.vorgangFacade.getVorgaengeByVerfahren(this.verfahren);
	}

	ngAfterContentInit() {
		this.spinner = new Spinner(this.actions, VorgangActions.LOAD_VORGAENGE_BY_VERFAHREN, VorgangActions.LOAD_VORGAENGE_BY_VERFAHREN_SUCCESS, this.spinnerRef);
	}

	ngOnDestroy() {
		this.spinner.destroy();
	}

	isSelected(vorgang: VorgangResource): Observable<boolean> {
		return this.vorgangFacade.isSelectedVorgang(vorgang);
	}

	setSelected(vorgang: VorgangResource) {
		this.vorgangFacade.setSelectedVorgang(vorgang);
	}

	updateNotiz(vorgang: VorgangResource, notiz: string) {
		this.vorgangFacade.updateNotiz(notiz, getUrl(vorgang, VorgangLinkRel.UPDATE_NOTIZ));
	}
}
