import {Injectable} from '@angular/core';
import {ResourceFactory} from '@ngxp/rest';
import {ApiRootFacade, ApiRootLinkRel, ApiRootResource} from '@schir-int-client/api-root';
import {filter, take} from 'rxjs/operators';
import {isNil} from 'lodash-es';
import {Observable} from 'rxjs';
import {TranslationResource} from './translation.model';
import {TranslationLinkRel} from './translation.linkrel';

export enum Language {
	EN = 'EN'
}

export enum Context {
	NONE = 'NONE',
	SHIP = 'SHIP',
	COUNTRY = 'COUNTRY',
	FLAG = 'FLAG',
	FLAGGENRECHT = 'FLAGGENRECHT'
}

@Injectable({ providedIn: 'root' })
export class TranslationService {

	private apiRoot: ApiRootResource;
	private translationResource: TranslationResource;

	constructor(private resourceFactory: ResourceFactory, private apiRootFacade: ApiRootFacade) {
		apiRootFacade.getCurrentApiRoot().pipe(filter(apiRoot => !isNil(apiRoot))).subscribe(apiRoot => {
			this.apiRoot = apiRoot;

			this.resourceFactory.from(this.apiRoot).get(ApiRootLinkRel.TRANSLATION).pipe(take(1)).subscribe((resource: TranslationResource) => {
				this.translationResource = resource;
			});
		});
	}

	public translate(language: Language, context: Context, text: string): Observable<TranslationResponse> {
		const request: TranslateRequest = {
			language: language,
			context: context,
			text: text,
		};

		return this.resourceFactory.from(this.translationResource).post(TranslationLinkRel.TRANSLATE, request);
	}

	public translateAndReplace(language: Language, context: Context, changeEntryId: string): Observable<TranslationResponse> {
		const request: TranslateEintragungRequest = {
			entryId: changeEntryId,
			language: language,
			context: context,
		};

		return this.resourceFactory.from(this.translationResource).post(TranslationLinkRel.TRANSLATE_EINTRAGUNG, request);
	}
}

class TranslateRequest {
	readonly language: Language;
	readonly context: Context;

	readonly text: string;
}

class TranslateEintragungRequest {
	readonly entryId: string;

	readonly language: Language;
	readonly context: Context;
}

class TranslationResponse {
	readonly text: string;
}
