import {Pipe, PipeTransform} from '@angular/core';
import {isEmpty} from 'lodash-es';
import {Selectable} from '@schir-int-client/tech';

@Pipe({ name: 'asSelectable' })
export class AsSelectablePipe implements PipeTransform {
	transform(values: [], field?: string): Selectable[] {
		if (!isEmpty(field)) {
			return values.map(value => <Selectable>{ label: value[field], value: value[field] });
		}
		if (field === undefined) {
			return values.map(value => <Selectable>{ label: value, value });
		}
		return [];
	}
}
