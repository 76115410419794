import {InjectionToken} from '@angular/core';
import {ActionReducerMap} from '@ngrx/store';
import {ReducerConfig} from '@schir-int-client/test-helpers';
import {vorlageReducer, VorlageState} from './vorlage.reducer';

export const vorlageFeatureState = 'VorlageState';
export const vorlageReducerInjectionToken = new InjectionToken<ActionReducerMap<VorlageRootState>>('Registered VorlageReducer');

export interface VorlageRootState {
	vorlageRoot: VorlageState;
}

export function getVorlageReducer(): ActionReducerMap<VorlageRootState> {
	return {
		vorlageRoot: vorlageReducer,
	};
}

export const reducerConfig: ReducerConfig<VorlageRootState> = {
	reducers: getVorlageReducer(),
	injectionToken: vorlageReducerInjectionToken,
};
