import {Component, Inject} from '@angular/core';
import {BaseEditorComponent} from '@schir-int-client/tech';
import {FormControl, FormGroup, UntypedFormGroup} from '@angular/forms';
import {
	orderedZaehlblattAktionTypes,
	VerfahrenResource,
	ZaehlblattAktionen,
	ZaehlblattAktionTyp,
	ZaehlblattAktionTypLabel,
} from '@schir-int-client/verfahren-shared';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA} from '@angular/material/legacy-dialog';
import {ZaehlblattFacade} from './zaehlblatt.facade';

@Component({
	selector: 'schir-int-client-zaehlblatt-erfassen',
	templateUrl: './zaehlblatt-erfassen.component.html',
	styleUrls: ['./zaehlblatt-erfassen.component.scss'],
})
export class ZaehlblattErfassenComponent extends BaseEditorComponent {

	private readonly verfahren: VerfahrenResource;

	form: UntypedFormGroup = this.createForm();

	labels = ZaehlblattAktionTypLabel;
	types = orderedZaehlblattAktionTypes;

	readonly aktenzeichenRendered: string;

	private alreadyClicked = [];

	constructor(@Inject(MAT_DIALOG_DATA) data, private facade: ZaehlblattFacade) {
		super();
		this.verfahren = data['verfahren'];
		this.aktenzeichenRendered = data['aktenzeichen'];
	}

	createForm(): UntypedFormGroup {
		var controls = {};
		for (const key in ZaehlblattAktionTyp) {
			controls[key] = new FormControl<number>(null);
		}
		return new FormGroup(controls);
	}

	async submit(): Promise<boolean> {
		if (!this.form.valid) {
			return false;
		}
		this.facade.addZaehlblattAktionen(<ZaehlblattAktionen>this.form.value, this.verfahren);
		return true;
	}

	getFormControl(control: string) {
		return <FormControl<number>>this.form.controls[control];
	}


	canSafe() {
		return Object.values(this.form.controls).some(c => c.value > 0);
	}

	increment(controlName: string) {
		if (!this.alreadyClicked.includes(controlName)) {
			this.alreadyClicked.push(controlName);
			this.getControl(controlName).patchValue(1);
		}
	}

	getControl(controlName: string): FormControl {
		return this.form.get(controlName) as FormControl;
	}
}
