import {InjectionToken} from '@angular/core';
import {ActionReducerMap} from '@ngrx/store';
import {ReducerConfig} from '@schir-int-client/test-helpers';
import {adminReducer, AdminState} from './admin.reducer';

export const adminRootFeatureState = 'AdminRootState';
export const adminReducerInjectionToken = new InjectionToken<ActionReducerMap<AdminRootState>>('Registered AdminReducer');

export interface AdminRootState {
	adminRoot: AdminState;
}

export function getAdminReducer(): ActionReducerMap<AdminRootState> {
	return {
		adminRoot: adminReducer,
	};
}

export const reducerConfig: ReducerConfig<AdminRootState> = {
	reducers: getAdminReducer(),
	injectionToken: adminReducerInjectionToken,
};
