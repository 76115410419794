import {Resource} from '@ngxp/rest';

export interface GerichtProfile {
	bezeichnung: string,
	abteilungsbezeichnung: string,
	ort: string,
	strasse: string,
	plzStrasse: string,
	postfach: string,
	plzPostfach: string,
	sprechzeiten1: string,
	sprechzeiten2: string,
	telefon: string,
	fax: string,
	internetadresse: string,
	iban: string,
	bic: string,
	datenschutzlink: string,
	rbbGericht: string,
	dateSource: DateSource
}

export interface GerichtProfileResource extends GerichtProfile, Resource {}


export enum DateSource {
	NOW = 'NOW',
	SIGNATURE = 'SIGNATURE'
}
