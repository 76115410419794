import {InjectionToken} from '@angular/core';
import {ActionReducerMap} from '@ngrx/store';
import {ReducerConfig} from '@schir-int-client/test-helpers';
import {gerichtProfileReducer, GerichtProfileState} from './gerichtprofile.reducer';

export const gerichtProfileFeatureState = 'GerichtProfileState';
export const gerichtProfileReducerInjectionToken = new InjectionToken<ActionReducerMap<GerichtProfileRootState>>('Registered GerichtProfileReducer');

export interface GerichtProfileRootState {
	gerichtProfileRoot: GerichtProfileState;
}

export function getGerichtProfileReducer(): ActionReducerMap<GerichtProfileRootState> {
	return {
		gerichtProfileRoot: gerichtProfileReducer,
	};
}

export const reducerConfig: ReducerConfig<GerichtProfileRootState> = {
	reducers: getGerichtProfileReducer(),
	injectionToken: gerichtProfileReducerInjectionToken,
};
