import {ChangeDetectionStrategy, Component, HostListener, Input} from '@angular/core';
import {ApiRootLinkRel, ApiRootResource} from '@schir-int-client/api-root';
import {DialogService, NotificationType} from '@schir-int-client/tech';
import {formatDate} from '@angular/common';
import {UserResource, UserRole} from '@schir-int-client/user-shared';

@Component({
	selector: 'schir-int-client-navbar',
	templateUrl: './navbar.component.html',
	styleUrls: ['./navbar.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavbarComponent {

	readonly apiRootLinkRel = ApiRootLinkRel;
	toolTipVisible: boolean;

	@Input()
	apiRoot: ApiRootResource;

	@Input()
	currentUser: UserResource;

	constructor(protected dialogService: DialogService) {}

	@HostListener('window:resize', ['$event'])
	onResize(event) {
		this.toolTipVisible = window.innerWidth < 1550;
	}

	showSchiRDetails() {
		let message: string;

		if (!this.apiRoot.features.lfpr) {
			message = '<h2>Schiffsregister (SchiR)</h2>';
		} else {
			message = '<h2>Luftfahrtpfandrechtsregister (eLuPfand)</h2>';
		}

		message = message.concat(
			`
			<table>
				<tr><td>Amtsgericht:</td><td><b>${this.amtsgericht}</b></td></tr>
				<tr><td>Amtsgerichtsnummer:</td><td>${this.amtsgerichtsnummer}</td></tr>
				<tr><td>XJustizId:</td><td>${this.xJustizId}</td></tr>
				<tr><td>Version:</td><td>${this.buildVersion} Build ${this.buildNumber}</td></tr>
				<tr><td>Erzeugt:</td><td>${this.buildTime}</td></tr>
				<tr><td>SchiR-Hostname:</td><td>${this.schirHostName}</td>
				<tr><td>Angemelderter Nutzer:</td><td>${this.schirUser}</td></tr>
				<tr><td>Rollen:</td><td>${this.schirUserRoles}</td></tr>
			</table>
			<br>
			<a href="/assets/Barrierefreiheit.html" title="Link öffnet in neuem Fenster">Erklärung zur Barrierefreiheit</a>
			`,
		);

		this.dialogService.openAcknowledgeDialog(message, NotificationType.INFO, [], 'Schließen');

		// Angular entfernt onclick und id-Attribute aus der Message
		setTimeout(() =>
				document.querySelector('schir-int-client-dialog-acknowledge a')
					.setAttribute('onclick', 'window.open(\'/assets/Barrierefreiheit.html\'); return false')
			, 200);
	}

	get amtsgericht() {
		return this.apiRoot.ort;
	}

	get amtsgerichtsnummer() {
		return this.apiRoot.amtsgerichtnummer;
	}

	get xJustizId() {
		return this.apiRoot.xjustizId;
	}

	get buildVersion() {
		return this.apiRoot.version;
	}

	get buildNumber() {
		return this.apiRoot.buildNumber;
	}

	get buildTime() {
		return formatDate(this.apiRoot.buildTime, 'dd. MMM yyyy, HH:mm', 'de');
	}

	get schirHostName() {
		return this.apiRoot.schirHostname;
	}

	get schirUser() {
		return this.currentUser.lastName + ', ' + this.currentUser.firstName;
	}

	get schirUserRoles() {
		return this.currentUser.roles.map(role => {
				switch (role) {
					case UserRole.ADMIN:
						return 'Administrator';
					case UserRole.GESCHAEFTSSTELLE:
						return 'Geschäftsstelle';
					case UserRole.RECHTSPFLEGER:
						return 'Rechtspfleger';
					case UserRole.DIENSTAUFSICHT:
						return 'Dienstaufsicht';
				}
			},
		).join(', ');
	}
}
