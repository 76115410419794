<div class="x-section__headline" data-test-id="headline">
	<h2 class="mat-h1" tabindex="0" aria-labelledby="sr-label-verf">{{vorgang.typ | enumToLabel: vorgangsTypen }}</h2>
</div>
<div class="x-section__subline">
	<i class="material-icons" aria-hidden="true">insert_drive_file</i>
	<h3 class="mat-body margin-right-auto">Verfügungen</h3>
	<schir-int-client-button-round #button (click)="openCreateKorrespondenzVerfuegungDialog()" popup="dialog"
								   toolTip="Korrespondenz zu Vorgang anlegen" data-test-id="create-korrespondenz-verfuegung" materialIcon="email"
								   cssClass="focus-box-shadow-light smaller-button">
	</schir-int-client-button-round>
	<schir-int-client-button-round #button (click)="openCreateVerfuegungDialog()" popup="dialog"
								   toolTip="Verfügung zu Vorgang anlegen" data-test-id="create-verfuegung" materialIcon="add"
								   cssClass="focus-box-shadow-light smaller-button">
	</schir-int-client-button-round>
</div>
<div class="x-section__main">
	<div class="c-spinner" data-test-id="spinner" #spinner>
		<mat-spinner></mat-spinner>
	</div>

	<ul class="c-list" *ngIf="{ standard: verfuegungenInVorgang | async, korrespondenzen: korrespondenzVerfuegungenInVorgang | async } as verfuegungen">
		<div data-test-id="standard-verfuegungen">
			<schir-int-client-verfuegung-in-vorgang *ngFor="let verfuegung of verfuegungen.standard"
													[verfuegung]="verfuegung">
			</schir-int-client-verfuegung-in-vorgang>
		</div>

		<div data-test-id="korrespondenz-verfuegungen" *ngIf="verfuegungen.korrespondenzen?.length > 0">
			<li>
				<div class="x-section__subline">
					<i class="material-icons" aria-hidden="true">email</i>
					<h3 class="mat-body margin-right-auto">Anschreiben</h3>
				</div>
			</li>
			<schir-int-client-verfuegung-in-vorgang *ngFor="let verfuegung of verfuegungen.korrespondenzen"
													[verfuegung]="verfuegung">
			</schir-int-client-verfuegung-in-vorgang>
		</div>
	</ul>
</div>
<span id="sr-label-verf" class="sr-only">{{vorgang.typ | enumToLabel: vorgangsTypen }} Verfügungen</span>
