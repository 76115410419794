export enum AdminRootLinkRel {
	UNTERSCHEIDUNGSSIGNALE = 'unterscheidungssignale',
	REL_STATISTIK_AGG = 'statistikAggregiert',
	REL_ZAEHLBLATT_STATISTIK = 'zaehlblattStatistik',
	UPLOAD = 'upload',
	GERICHT_PROFILE = 'gerichtprofil'
}

export enum UnterscheidungssignalListLinkRel {
	UNTERSCHEIDUNGSSIGNALE = 'unterscheidungssignalList'
}

export enum UnterscheidungssignalLinkRel {
	DELETE = 'delete'
}
