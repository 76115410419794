import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {Observable, of, throwError} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {UserFacade} from '@schir-int-client/user-shared';
import {ErrorService} from '@schir-int-client/error';

export const HEADER_SCHIR_AUTHENTICATED: string = 'x-schir-authenticated';

/**
 * Fängt http-requests ab, aktualisiert den Authentisierungsstatus und veranlasst im Fehlerfall eine Weiterleitung zum Login oder einer Fehlerseite.
 */
@Injectable({ providedIn: 'root' })
export class AuthenticationInterceptor implements HttpInterceptor {

	private authenticated: boolean = false;

	constructor(private userFacade: UserFacade, @Inject('Window') private window: Window, private errorService: ErrorService) {}

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		return next.handle(request).pipe(
			map((event) => {
				this.updateAuthenticationStatus(event);
				return event;
			}),
			catchError((error: any) => {
				if (this.handleError(error)) {
					return of(error);
				}
				return throwError(error);
			}));
	}

	private updateAuthenticationStatus(event: HttpEvent<any>) {
		if (!this.authenticated) {
			const headers: Map<string, string> = (<any>event).headers;
			if (headers && headers.get(HEADER_SCHIR_AUTHENTICATED)) {
				this.authenticated = true;
				this.userFacade.loggedIn();
			}
		}
	}

	/**
	 * Prüft, ob das Backend den Zugriff aufgrund fehlender/ungültiger Authentisierung abgelehnt hat. Handelt es sich um einen Error aufgrund
	 * fehlender Berechtigung für ein Bundesland wird dem Nutzer über den Error-Service eine Fehlerseite zur Anzeige gebracht. Andernfalls wird der
	 * Nutzer wieder direkt zum Safe-Login weitergeleitet.
	 * @param error
	 * @private
	 */
	private handleError(error: any): boolean {
		if (this.isUnauthorizedStatusError(error)) {
			if (this.isRegionError()) {
				this.errorService.updateErrorStatus('region');
			} else {
				this.userFacade.unauthorized();
			}
			return true;
		}
		return false;
	}


	private isRegionError() {
		return this.window.location.href.endsWith('region');
	}

	private isUnauthorizedStatusError(error: any): boolean {
		return error.status && error.status == 401;
	}
}
