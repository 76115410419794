import {Injectable} from '@angular/core';
import {EipUser} from './eip.model';

@Injectable({ providedIn: 'root' })
export class EipContext {
	currentUser(): EipUser {
		return {
			firstName: 'Justus',
			lastName: 'Sijus',
			jobDesciption: 'Richter',
			title: 'Herr',
			loginId: 'USER_14',
		};
	}
}
