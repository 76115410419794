import {Action} from '@ngrx/store';
import {getUrl} from '@ngxp/rest';
import {createEmptyStateResource, createStateResource, StateResource} from '@schir-int-client/ngrx-helpers';
import {VerfahrenActions} from '@schir-int-client/verfahren-shared';
import {
	AlternativeTextActions,
	CreateAlternativeTextEntryAction,
	CreateAlternativeTextEntrySuccessAction,
	DeleteAlternativeTextEntryAction,
	DeleteAlternativeTextEntrySuccessAction,
	LoadAlternativeTexteSuccessAction,
	UpdateAlternativeTextEntryAction,
	UpdateAlternativeTextEntrySuccessAction,
} from './alternative-text.actions';
import {AlternativeTextLinkrel} from './alternative-text.linkrel';
import {AlternativeTextListResource, AlternativeTextResource} from './alternative-text.model';

export interface AlternativeTextState {
	alternativeTexte: StateResource<AlternativeTextListResource>;
	alternativeTextByChangeEntry: {
		[changeEntryId: string]: StateResource<AlternativeTextResource>;
	};
}

export const initialState: AlternativeTextState = {
	alternativeTexte: createEmptyStateResource(),
	alternativeTextByChangeEntry: {},
};

export function alternativeTextReducer(state: AlternativeTextState = initialState, action: Action): AlternativeTextState {
	switch (action.type) {
		// change to NAVIGATED when selecting verfahren is using navigation
		case VerfahrenActions.LOAD_VERFAHREN_SINGLE_SUCCESS:
			return {
				...state,
				alternativeTexte: createEmptyStateResource(),
				alternativeTextByChangeEntry: {},
			};
		case AlternativeTextActions.LOAD_ALTERNATIVE_TEXTE:
			return {
				...state,
				alternativeTexte: createEmptyStateResource(true),
			};
		case AlternativeTextActions.LOAD_ALTERNATIVE_TEXTE_SUCCESS:
			return {
				...state,
				alternativeTexte: createStateResource((<LoadAlternativeTexteSuccessAction>action).alternativeTexte),
			};
		case AlternativeTextActions.CREATE_ALTERNATIVE_TEXT_ENTRY:
			return {
				...state,
				alternativeTextByChangeEntry:
					{
						...state.alternativeTextByChangeEntry,
						[getUrl((<CreateAlternativeTextEntryAction>action).flaggenrechtResource)]: createEmptyStateResource(true),
					},
			};
		case AlternativeTextActions.CREATE_ALTERNATIVE_TEXT_ENTY_SUCCESS:
			return {
				...state,
				alternativeTextByChangeEntry: {
					...state.alternativeTextByChangeEntry,
					[getUrl((<CreateAlternativeTextEntrySuccessAction>action).alternativeTextResource, AlternativeTextLinkrel.SPALTEN_EINTRAG)]: createStateResource((<CreateAlternativeTextEntrySuccessAction>action).alternativeTextResource),
				},
			};
		case AlternativeTextActions.UPDATE_ALTERNATIVE_TEXT_ENTRY:
			return {
				...state,
				alternativeTextByChangeEntry:
					{
						...state.alternativeTextByChangeEntry,
						[getUrl((<UpdateAlternativeTextEntryAction>action).alternativeTextResource, AlternativeTextLinkrel.SPALTEN_EINTRAG)]: createEmptyStateResource(true),
					},
			};
		case AlternativeTextActions.UPDATE_ALTERNATIVE_TEXT_ENTRY_SUCCESS:
			return {
				...state,
				alternativeTextByChangeEntry: {
					...state.alternativeTextByChangeEntry,
					[getUrl((<UpdateAlternativeTextEntrySuccessAction>action).alternativeTextResource, AlternativeTextLinkrel.SPALTEN_EINTRAG)]: createStateResource((<UpdateAlternativeTextEntrySuccessAction>action).alternativeTextResource),
				},
			};
		case AlternativeTextActions.DELETE_ALTERNATIVE_TEXT_ENTRY: {
			return {
				...state,
				alternativeTextByChangeEntry:
					{
						...state.alternativeTextByChangeEntry,
						[getUrl((<DeleteAlternativeTextEntryAction>action).alternativeTextResource, AlternativeTextLinkrel.SPALTEN_EINTRAG)]: createEmptyStateResource(true),
					},
			};
		}
		case AlternativeTextActions.DELETE_ALTERNATIVE_TEXT_ENTRY_SUCCESS:
			const newState = { ...state };
			delete newState.alternativeTextByChangeEntry[getUrl((<DeleteAlternativeTextEntrySuccessAction>action).alternativeTextResource, AlternativeTextLinkrel.SPALTEN_EINTRAG)];
			return newState;
		default:
			return state;
	}
}
