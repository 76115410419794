import {InjectionToken} from '@angular/core';
import {ActionReducerMap} from '@ngrx/store';
import {ReducerConfig} from '@schir-int-client/test-helpers';
import {
	datumsBereichReducer,
	DatumsBereichState,
	wiedervorlageReducer,
	WiedervorlageState,
} from './wiedervorlage.reducer';

export const wiedervorlageFeatureState = 'WiedervorlageState';
export const wiedervorlageReducerInjectionToken = new InjectionToken<ActionReducerMap<WiedervorlageRootState>>('Registered WiedervorlageReducer');

export interface WiedervorlageRootState {
	wiedervorlageRoot: WiedervorlageState;
}

export interface DatumsBereichRootState {
	datumsBereichRoot: DatumsBereichState;
}

export function getWiedervorlageReducer(): ActionReducerMap<WiedervorlageRootState> {
	return {
		wiedervorlageRoot: wiedervorlageReducer,
	};
}

export function getDatumsBereichReducer(): ActionReducerMap<DatumsBereichRootState> {
	return {
		datumsBereichRoot: datumsBereichReducer,
	};
}

export const reducerConfig: ReducerConfig<WiedervorlageRootState> = {
	reducers: getWiedervorlageReducer(),
	injectionToken: wiedervorlageReducerInjectionToken,
};


