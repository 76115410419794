import {Component, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {AufgabeLfprKostenTaetigkeit, AufgabenTyp} from '@schir-int-client/aufgabe-shared';
import {isNil} from 'lodash-es';
import {Subscription} from 'rxjs';
import {CreateAufgabeFormService} from '../../../create-aufgabe-dialog/create-aufgabe.formservice';

@Component({
	selector: 'schir-int-client-aufgabe-lfpr-kosten-editor',
	templateUrl: './aufgabe-kosten-editor.component.html',
	styleUrls: ['./aufgabe-kosten-editor.component.scss'],
})
export class AufgabeKostenEditorComponent implements OnInit, OnDestroy {
	readonly formServiceClass = CreateAufgabeFormService;
	readonly aufgabenTyp: AufgabenTyp = AufgabenTyp.LFPR_KOSTEN;
	readonly kostenTaetigkeit = AufgabeLfprKostenTaetigkeit;
	readonly traeger = AufgabeLfprKostenTraeger;
	readonly grund = AufgabeLfprKostenfreiGrund;

	private taetigkeitErfordern: boolean;
	private taetigkeitFrei: boolean;
	private taetigkeitSubscription: Subscription;


	constructor(private formService: CreateAufgabeFormService) {}

	get formGroup(): UntypedFormGroup {
		return <UntypedFormGroup>this.formService.form.controls[this.aufgabenTyp];
	}

	get bodyGroup(): UntypedFormGroup {
		return <UntypedFormGroup>this.formGroup.controls[CreateAufgabeFormService.feldAufgabeBody];
	}

	get berechtigteNr(): UntypedFormControl {
		return <UntypedFormControl>this.bodyGroup.controls[CreateAufgabeFormService.feldLfprKostenBerechtigteNr];
	}

	hasTraeger(): boolean {
		return this.taetigkeitErfordern;
	}

	hasGrund(): boolean {
		return this.taetigkeitFrei;
	}

	ngOnInit() {
		let taetigkeitControl = <UntypedFormControl>this.bodyGroup.controls[CreateAufgabeFormService.feldLfprKostenTaetigkeit];
		this.taetigkeitSubscription = taetigkeitControl.valueChanges.subscribe(v => this.updateGroupsEnabled(v));
		this.updateGroupsEnabled(taetigkeitControl.value);
	}

	private updateGroupsEnabled(value: string) {
		this.taetigkeitErfordern = value == this.kostenTaetigkeit.ERFORDERN;
		this.taetigkeitFrei = value == this.kostenTaetigkeit.FREI;
	}

	ngOnDestroy() {
		if (!isNil(this.taetigkeitSubscription)) {
			this.taetigkeitSubscription.unsubscribe();
		}
	}

	needsNr() {
		return (<UntypedFormControl>this.bodyGroup.controls[CreateAufgabeFormService.feldLfprKostenErfordernVon]).value == AufgabeLfprKostenTraeger.BERECHTIGTE;
	}
}

export enum AufgabeLfprKostenTraeger {
	NOTAR = 'NOTAR',
	EIGENTUEMER = 'EIGENTUEMER',
	BERECHTIGTE = 'BERECHTIGTE',
	RECHTSANWALT = 'RECHTSANWALT',
	SONSTIGE = 'SONSTIGE',
}

export enum AufgabeLfprKostenfreiGrund {
	PARAGRAPH102 = 'PARAGRAPH102',
	GNOTKG = 'GNOTKG',
	GES = 'GES',
}

