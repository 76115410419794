import {Component, EventEmitter, Inject, Output} from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA} from '@angular/material/legacy-dialog';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {SpaltenFeld} from '../schir-utils';
import {isNil} from 'lodash-es';
import {BaseEditorComponent} from '../dialog-warn/support';

@Component({
	selector: 'schir-int-client-kontakt-aktenzeichen-dialog',
	templateUrl: './kontakt-aktenzeichen-dialog.component.html',
	styleUrls: ['./kontakt-aktenzeichen-dialog.component.scss'],
})
export class KontaktAktenzeichenDialogComponent extends BaseEditorComponent {

	readonly kontaktAktenzeichenFeld: SpaltenFeld = {
		label: 'Aktenzeichen',
		name: 'kontaktAktenzeichen',
		type: 'text',
	};

	readonly title: string;

	@Output() kontaktAktenzeichenEvent: EventEmitter<string> = new EventEmitter();

	form: UntypedFormGroup = new UntypedFormGroup({
		kontaktAktenzeichen: new UntypedFormControl(null),
	});

	constructor(@Inject(MAT_DIALOG_DATA) data: KontaktAktenzeichenDialogData) {
		super();

		this.title = data.title;

		if (!isNil(data)) {
			this.form.patchValue(data);
		}
	}

	async submit(): Promise<boolean> {
		this.kontaktAktenzeichenEvent.emit(this.form.value.kontaktAktenzeichen);

		return true;
	}
}

export interface KontaktAktenzeichenDialogData {
	title: string,
	kontaktAktenzeichen: string
}
