export enum RegisterBlattChangeLinkRel {
	CHANGE_ENTRIES_RESOURCE = 'entryResources',
}

export enum ChangeEntryLinkRel {
	EDIT = 'edit',
	ROETEN = 'roeten',
	PARTIELL_ROETEN = 'partiellRoeten',
	DELETE = 'delete',
	TEILLOESCHEN = 'teilloeschen',
	ROETUNGS_ENTRY = 'roetung',
	PARTIELL_ROETUNGS_ENTRY = 'partielleRoetung',
	SIGNATURE = 'signature',
	BETRAG_KORRIGIEREN = 'betragKorrigieren',
	LAUFENDE_NUMMER_KORRIGIEREN = 'laufendeNummerKorrigieren',
	CREATE_ALTERNATIVE_TEXT = 'createAlternativeText',
	ALTERNATIVE_TEXT = 'alternativeText',
	CREATE_UMSCHREIBUNG = 'createUmschreibung',
	VORMERKUNG = 'vormerkungId'
}

export enum ChangeEntryListLinkRel {
	ADD_ENTRY = 'add',
	BATCH = 'batch',
	ADD_VERMERK_ENTRY = 'addVermerk',
	AUFSCHRIFT = 'spaltenEintragungsAufschriftWertList',
	ROETEN_CHANGE_ENTRIES = 'spaltenEintragungsRoetenWertList',
	PARTIELL_ROETEN_CHANGE_ENTRIES = 'spaltenEintragungsPartiellRoetenWertList',

	TEXT_CHANGE_ENTRIES = 'spaltenEintragungsTextWertList',
	NUMERIC_CHANGE_ENTRIES = 'spaltenEintragungsNumericWertList',
	FLAGGENRECHT_CHANGE_ENTRIES = 'spaltenEintragungsFlaggenrechtWertList',
	EIGENTUEMER_CHANGE_ENTRIES = 'spaltenEintragungsEigentuemerSchiffspartenWertList',
	BELASTUNG_CHANGE_ENTRIES = 'spaltenEintragungsBelastungWertList',
	TEILLOESCHUNG_ENTRIES = 'spaltenEintragungsTeilloeschenWertList',
	TAG_EINTRAGUNG_LOESCHUNG_CHANGE_ENTRIES = 'spaltenEintragungsTagEintragungLoeschungWertList',
	UEBEREINSTIMMUNG_ENTRIES = 'spaltenEintragungsUebereinstimmungWertList',
	MIGRATION_ENTRIES = 'spaltenEintragungsMigrationWertList',
	LOESCHGRUND_ENTRIES = 'spaltenEintragungsLoeschgrundWertList',
	TAG_EINTRAGUNG_ENTRIES = 'spaltenEintragungsTagEintragungWertList',
	ERWERBSGRUND_CHANGE_ENTRIES = 'spaltenEintragungsErwerbsgrundWertList',
	EIGENTUEMER_ERWERBSGRUND_CHANGE_ENTRIES = 'spaltenEintragungsEigentuemerErwerbsgrundWertList',
	LOESCHUNGEN_CHANGE_ENTRIES = 'spaltenEintragungsLoeschungenWertList',
	MASCHINENLEISTUNG_CHANGE_ENTRIES = 'spaltenEintragungsMaschinenleistungWertList',
	GATTUNG_HAUPTBAUSTOFF_ENTRIES = 'spaltenEintragungsGattungHauptbaustoffWertList',
	MESSBRIEF_CHANGE_ENTRIES = 'spaltenEintragungsMessbriefWertList',
	STAPELLAUF_CHANGE_ENTRIES = 'spaltenEintragungsStapellaufWertList',
	VERAENDERUNGEN_CHANGE_ENTRIES = 'spaltenEintragungsVeraenderungenWertList',
	VERAENDERUNGEN_HYPOTHEKEN_CHANGE_ENTRIES = 'spaltenEintragungsVeraenderungenHypothekenWertList',
	VERMERK_ENTRIES = 'spaltenEintragungsVermerkWertList',
	BETRAGS_KORREKTUR_ENTRIES = 'spaltenEintragungsBetragsKorrekturWertList',
	LAUFENDE_NUMMER_KORREKTUR_ENTRIES = 'spaltenEintragungsLaufendeNummerKorrekturWertList',

	TEXTBAUSTEINE = 'textbausteine',

	REGISTERBLATT_AUSDRUCK = 'registerBlattAusdruck',

	PENDING_SIGNATURE_TASK = 'pendingSignatureTask',
	SIGNATURE_VALIDATOR_RESPONSES = 'signatureValidatorResponses'
}
