import {HttpClient} from '@angular/common/http';
import {EventEmitter, Injectable, Output} from '@angular/core';
import {getUrl, Resource, ResourceFactory} from '@ngxp/rest';
import {ApiRootLinkRel} from '@schir-int-client/api-root';
import {Observable} from 'rxjs';
import {KontaktLinkRel} from './adressverwaltung.linkrel';
import {
	Kontakt,
	KontakteInVerfahren,
	KontaktKategorie,
	KontaktListResource,
	KontaktResource,
} from './adressverwaltung.model';
import {VerfahrenLinkRel} from '../../../verfahren-shared/src/lib/verfahren.linkrel';
import {VerfahrenListResource, VerfahrenResource} from '../../../verfahren-shared/src/lib/verfahren.model';

@Injectable({ providedIn: 'root' })
export class AdressverwaltungService {

	@Output() adressatenColDisplayed = new EventEmitter<boolean>();

	constructor(private resourceFactory: ResourceFactory, private httpClient: HttpClient) { }

	public getAll(rootResource: Resource): Observable<KontaktListResource> {
		return this.resourceFactory.from(rootResource).get(ApiRootLinkRel.KONTAKTS);
	}

	public get(kontaktUri: string): Observable<KontaktResource> {
		return this.resourceFactory.fromId(kontaktUri).get();
	}

	public create(rootResource: Resource, kontakt: Kontakt): Observable<KontaktResource> {
		return this.resourceFactory.from(rootResource).post(ApiRootLinkRel.KONTAKTS, kontakt);
	}

	public delete(kontakt: KontaktResource): Observable<KontaktListResource> {
		return this.resourceFactory.from(kontakt).post(KontaktLinkRel.DELETE);
	}

	public update(kontaktResource: KontaktResource, newKontakt: Kontakt): Observable<KontaktResource> {
		const updated = { ...<Kontakt>kontaktResource, ...newKontakt };
		return this.resourceFactory.from(kontaktResource).put(KontaktLinkRel.EDIT, updated);
	}

	public assignKontakt(verfahren: VerfahrenResource, kontakt: KontaktResource, kategorie: KontaktKategorie): Observable<VerfahrenResource> {
		const kontaktId: string = kontakt._links.self.href.substring(kontakt._links.self.href.lastIndexOf('/') + 1);
		const url: string = getUrl(verfahren, VerfahrenLinkRel.ASSIGN_KONTAKT_IN_KATEGORIE).replace('{kontaktId}', kontaktId);
		return this.httpClient.post<VerfahrenResource>(url, JSON.stringify(kategorie), { headers: { 'Content-Type': 'application/json' } });
	}

	public loadKontakteInVerfahren(verfahren: VerfahrenResource): Observable<KontakteInVerfahren> {
		return this.resourceFactory.from(verfahren).get(VerfahrenLinkRel.KONTAKTE_IN_KATEGORIEN);
	}

	public adressatenColumDisplayed(show: boolean) {
		this.adressatenColDisplayed.emit(show);
	}

	public updateVerfahrenKontaktNotiz(kontakt: KontaktResource): Observable<KontaktResource> {
		return this.resourceFactory.from(kontakt).patch(KontaktLinkRel.VERFAHREN_NOTIZ, <Kontakt>kontakt);
	}

	public updateVerfahrenKontaktAktenzeichen(kontakt: KontaktResource): Observable<KontaktResource> {
		return this.resourceFactory.from(kontakt).patch(KontaktLinkRel.VERFAHREN_AKTENZEICHEN, <Kontakt>kontakt);
	}

	public getAllVerfahrenForKontakt(kontakt: KontaktResource): Observable<VerfahrenListResource> {
		return this.resourceFactory.from(kontakt).get(KontaktLinkRel.VERFAHREN_BY_KONTAKT);
	}
}
