import {HttpErrorResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
import {getEmbeddedResource, getUrl, hasLink} from '@ngxp/rest';
import {ActionType, ApiRootLoadedAction} from '@schir-int-client/api-root';
import {DoNothingAction} from '@schir-int-client/ngrx-helpers';
import {AppNotificationService, DialogService, NotificationType} from '@schir-int-client/tech';
import {LoadVerfahrenSingleAction, verfahrenSingleSelector} from '@schir-int-client/verfahren-shared';
import {isEmpty} from 'lodash-es';
import {of, throwError} from 'rxjs';
import {catchError, map, mergeMap, switchMap, tap, withLatestFrom} from 'rxjs/operators';
import {
	AcknowledgeSignatureErrorAction,
	CloseInProgressDialogAction,
	CreateSignaturTaskAction,
	DownloadSignatureAction,
	DownloadSignatureSuccessAction,
	DownloadSignaturTaskSuccessAction,
	LoadSignatureValidatorResponseNotFoundAction,
	LoadSignatureValidatorResponsesSuccessAction,
	OpenInProgressDialogAction,
	SignaturActions,
} from './signatur.actions';
import {SignatureTaskLinkRel, SignatureValidatorResponseListLinkRel} from './signatur.linkrel';
import {SignatureValidatorResponseResource} from './signatur.model';
import {SignaturService} from './signatur.service';
import {SignaturRootState} from './signatur.state';
import {inProgressDialogOpenSelector, signatureTaskSelector} from './signature.selectors';
import {
	ChangeEntryListLinkRel,
	ChangeEntryListResource,
	changeEntryListSelector,
	LoadChangeEntriesSuccessAction,
	RegisterBlattChangeActions,
} from '@schir-int-client/register-change';


@Injectable()
export class SignaturEffects {

	constructor(
		private actions: Actions,
		private service: SignaturService,
		private store: Store<SignaturRootState>,
		private notificationService: AppNotificationService,
		private dialogService: DialogService,
	) { }


	createSignaturTask = createEffect(() => this.actions.pipe(
		ofType(SignaturActions.CREATE_SIGNATUR_TASK),
		withLatestFrom(this.store.select(verfahrenSingleSelector)),
		switchMap(([action, verfahren]) => this.service.create(verfahren, (<CreateSignaturTaskAction>action).changeEntries).pipe(
			map(result => new DownloadSignaturTaskSuccessAction(result)),
		)),
	));


	downloadSignerScript = createEffect(() => this.actions.pipe(
		ofType(SignaturActions.DOWNLOAD_SIGNER_SCRIPT),
		withLatestFrom(this.store.select(signatureTaskSelector)),
		tap(([, task]) => {
			this.service.downloadSignerScript(task);
		}),
	), { dispatch: false });


	downloadSignatureTask = createEffect(() => this.actions.pipe(
		ofType(SignaturActions.DOWNLOAD_SIGNATURE_TASK),
		withLatestFrom(this.store.select(signatureTaskSelector)),
		switchMap(([, task]) => this.service.download(task).pipe(
			map(result => new DownloadSignaturTaskSuccessAction(result)),
		)),
	));


	openProgressDialog = createEffect(() => this.actions.pipe(
		ofType(SignaturActions.DOWNLOAD_SIGNATUR_TASK_SUCCESS),
		withLatestFrom(this.store.select(inProgressDialogOpenSelector)),
		switchMap(([action, isOpen]) => {
			if (isOpen) {
				return of(new DoNothingAction());
			}
			const taskAction = <DownloadSignaturTaskSuccessAction>action;
			return hasLink(taskAction.signaturTask, SignatureTaskLinkRel.RELOAD) ? of(new OpenInProgressDialogAction()) : of(new DoNothingAction());
		}),
	));


	closeProgressDialog = createEffect(() => this.actions.pipe(
		ofType(SignaturActions.DOWNLOAD_SIGNATUR_TASK_SUCCESS),
		withLatestFrom(this.store.select(inProgressDialogOpenSelector)),
		mergeMap(([action, isOpen]) => {
			if (!isOpen) {
				return of(new DoNothingAction());
			}

			const taskAction = <DownloadSignaturTaskSuccessAction>action;
			if (hasLink(taskAction.signaturTask, SignatureTaskLinkRel.RELOAD)) {
				return [new DoNothingAction()];
			}
			const closeAction = new CloseInProgressDialogAction();
			if (hasLink(taskAction.signaturTask, SignatureTaskLinkRel.VERFAHREN)) {
				return [closeAction, new LoadVerfahrenSingleAction(getUrl(taskAction.signaturTask, SignatureTaskLinkRel.VERFAHREN))];
			}
			return [closeAction];
		}),
	));


	cancelSignature = createEffect(() => this.actions.pipe(
		ofType(SignaturActions.CANCEL_SIGNATURE),
		withLatestFrom(this.store.select(signatureTaskSelector)),
		switchMap(([, task]) => {
			this.notificationService.handleInfo('Sie haben den Signiervorgang abgebrochen. Es wurden keine Datensätze signiert.', null, 'Signiervorgang abgebrochen');
			return this.service.cancelSignature(task).pipe(
				map(result => new DownloadSignaturTaskSuccessAction(result)),
			);
		}),
	));


	loadSignatureTask = createEffect(() => this.actions.pipe(
		ofType(RegisterBlattChangeActions.LOAD_CHANGE_ENTRIES_SUCCESS),
		switchMap(action => {
			const entryList: ChangeEntryListResource = (<LoadChangeEntriesSuccessAction>action).resources;
			if (hasLink(entryList, ChangeEntryListLinkRel.PENDING_SIGNATURE_TASK)) {
				return this.service.loadSignatureTaskFromRegisterEntries(entryList).pipe(
					map(task => new DownloadSignaturTaskSuccessAction(task)),
				);
			}
			return of(new DoNothingAction());
		}),
	));


	downloadSignature = createEffect(() => this.actions.pipe(
		ofType(SignaturActions.DOWNLOAD_SIGNATURE),
		mergeMap(action => this.service.downloadSignature((<DownloadSignatureAction>action).resource).pipe(
			map(result => new DownloadSignatureSuccessAction(result)),
		)),
	));


	loadGlobalSignatureValidationFailures = createEffect(() => this.actions.pipe(
		ofType(ActionType.ApiRootLoaded),
		switchMap(action => {
			return this.service.loadGlobalSignatureFailures((<ApiRootLoadedAction>action).payload).pipe(
				map(responseListResource => <SignatureValidatorResponseResource[]>getEmbeddedResource(responseListResource, SignatureValidatorResponseListLinkRel.SIGNATURE_VALIDATION_RESPONSE_LIST)),
				mergeMap(errors => {
					if (isEmpty(errors)) {
						return of(new DoNothingAction());
					}
					return this.dialogService.openAcknowledgeDialog(`Es sind ${errors.length} Fehler bei der Prüfung der Register-Signaturen aufgetreten. Bitte informieren Sie umgehend ihren Administrator.<br/>Die Integrität des Registers ist nicht länger gewährleistet.`, NotificationType.ERROR).pipe(
						map(acknowledged => acknowledged ? new AcknowledgeSignatureErrorAction() : new DoNothingAction()),
					);
				}),
			);
		}),
	));


	loadSignatureValidatoreResponses = createEffect(() => this.actions.pipe(
		ofType(SignaturActions.LOAD_SIGNATURE_VALIDATOR_RESPONSES),
		withLatestFrom(this.store.select(changeEntryListSelector)),
		switchMap(([, entriesList]) => {
			return this.service.loadSignatureValidatorResponses(entriesList.resource).pipe(
				map(responses => new LoadSignatureValidatorResponsesSuccessAction(responses)),
				catchError((error: HttpErrorResponse) => {
					if (error.status === 404) {
						return of(new LoadSignatureValidatorResponseNotFoundAction());
					}
					return throwError(error);
				}),
			);
		}),
	));

}
