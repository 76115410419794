<form *ngIf="selectedKontakt" [formGroup]="form" (ngSubmit)="submit()" data-test-id="formular">
	<div class="x-section__subline kontaktdetails">
		<h2 class="mat-h1">Kontaktdetails</h2>
	</div>
	<div class="scroll-area">
		<div class="kontaktTyp">
			<div>
				<span class="material-icons" matTooltip="Kontaktart" aria-label="Kontaktart">contact_page</span>
			</div>
			<mat-divider [vertical]="true"></mat-divider>
			<div>
				<schir-int-client-one-value-editor [type]="'checkbox'" [formGroup]="form" [label]="'Juristische Person'"
												   [fieldControl]="getFormControl('juristischePerson')"
												   (value)="checkForSimilarContact()" data-test-id="juristische-person" id="jurCheckbox"
												   [readonly]="!editMode" [allowEmptyString]="false">
				</schir-int-client-one-value-editor>
				<schir-int-client-one-value-editor [type]="'checkbox'" [formGroup]="form" [label]="'Behörde'"
												   [fieldControl]="getFormControl('behoerde')" data-test-id="behoerde"
												   [readonly]="!editMode" [allowEmptyString]="false">
				</schir-int-client-one-value-editor>
				<div class="behoerdenOptions" *ngIf="getFormControl('behoerde').value">
					<schir-int-client-one-value-editor *ngFor="let r of behoerdenAutoKontaktSelectionList"
													   [type]="'checkbox'" [formGroup]="form" [label]="r"
													   [fieldControl]="getFormControl(r?.toLowerCase())"
													   [readonly]="!editMode"
													   attr.data-test-id="{{r?.toLowerCase() + '-checkbox'}}"
													   [allowEmptyString]="false">
					</schir-int-client-one-value-editor>
				</div>
			</div>
		</div>
		<mat-expansion-panel [expanded]="!form.get('juristischePerson').value" *ngIf="editMode || !isPersonendatenEmpty()"
							 data-test-id="personendaten-panel">
			<mat-expansion-panel-header>
				<mat-panel-title>
					<h3>Personendaten</h3>
					<mat-divider></mat-divider>
				</mat-panel-title>
			</mat-expansion-panel-header>
			<div class="lineGroup">
				<schir-int-client-one-value-editor *ngIf="editMode || getFormControl('anrede').value != null"
												   [type]="'select'" [formGroup]="form" [label]="'Anrede'"
												   [fieldControl]="getFormControl('anrede')"
												   [values]="form.get('juristischePerson').value ? juristischeAnredeValues : anredeValues"
												   [readonly]="!editMode" data-test-id="anrede-input" [allowEmptyString]="false">
				</schir-int-client-one-value-editor>
				<schir-int-client-one-value-editor *ngIf="editMode || getFormControl('titel').value != null"
												   [type]="'text'" [formGroup]="form" [label]="'Titel'"
												   [fieldControl]="getFormControl('titel')"
												   [readonly]="!editMode" data-test-id="titel-input" [allowEmptyString]="false">
				</schir-int-client-one-value-editor>
			</div>
			<div class="lineGroup">
				<schir-int-client-one-value-editor *ngIf="editMode || getFormControl('vorname').value != null"
												   [type]="'text'" [formGroup]="form" [label]="'Vorname'"
												   [fieldControl]="getFormControl('vorname')"
												   (change)="checkForSimilarContact()" [readonly]="!editMode"
												   data-test-id="vorname-input" [allowEmptyString]="false">
				</schir-int-client-one-value-editor>
				<schir-int-client-one-value-editor *ngIf="editMode || getFormControl('name').value != null"
												   [type]="'text'" [formGroup]="form" [label]="'Name'"
												   [fieldControl]="getFormControl('name')"
												   (change)="checkForSimilarContact()" [readonly]="!editMode"
												   [required]="!form.get('juristischePerson').value" data-test-id="name-input"
												   [allowEmptyString]="false">
				</schir-int-client-one-value-editor>
				<div class="warning" *ngIf="aehnlicherKontakt && getFormControl('name').value &&
						editMode && !getFormControl('juristischePerson').value">
					<span class="material-icons">warning</span>
					<mat-error data-test-id="similar-nat-contact-warning" id="similar-nat-contact-warning">{{ MSG_DUPLICATE_NAT }}</mat-error>
				</div>
			</div>
			<div class="lineGroup">
				<schir-int-client-one-value-editor *ngIf="editMode || getFormControl('geburtsname').value != null"
												   [type]="'text'" [formGroup]="form" [label]="'Geburtsname'"
												   [fieldControl]="getFormControl('geburtsname')"
												   [readonly]="!editMode" data-test-id="geburtsname-input" [allowEmptyString]="false">
				</schir-int-client-one-value-editor>
				<schir-int-client-one-value-editor *ngIf="editMode || getFormControl('namenszusatz').value != null"
												   [type]="'text'" [formGroup]="form" [label]="'Namenszusatz'"
												   [fieldControl]="getFormControl('namenszusatz')"
												   [readonly]="!editMode" data-test-id="namenszusatz-input" [allowEmptyString]="false">
				</schir-int-client-one-value-editor>
			</div>
			<div class="lineGroup">
				<schir-int-client-one-value-editor *ngIf="editMode || getFormControl('weitereVornamen').value != null"
												   [type]="'text'" [formGroup]="form" [label]="'Weitere Vornamen'"
												   [fieldControl]="getFormControl('weitereVornamen')"
												   [readonly]="!editMode" data-test-id="weitere-vornamen-input" [allowEmptyString]="false">
				</schir-int-client-one-value-editor>
				<schir-int-client-one-value-editor *ngIf="editMode || getFormControl('geburtsDatum').value != null"
												   [type]="'date'" [formGroup]="form" [label]="'Geburtsdatum'"
												   [fieldControl]="getFormControl('geburtsDatum')"
												   [readonly]="!editMode" data-test-id="geburtsdatum-input" [allowEmptyString]="false">
				</schir-int-client-one-value-editor>
			</div>
		</mat-expansion-panel>

		<mat-expansion-panel [expanded]="form.get('juristischePerson').value" *ngIf="editMode || !isFirmendatenEmpty()"
							 data-test-id="firmendaten-panel">
			<mat-expansion-panel-header>
				<mat-panel-title>
					<h3>Firmendaten</h3>
					<mat-divider></mat-divider>
				</mat-panel-title>
			</mat-expansion-panel-header>

			<div class="lineGroup">
				<schir-int-client-one-value-editor *ngIf="editMode || getFormControl('firmenName').value != null"
												   [type]="'text'" [formGroup]="form" [label]="'Firma'"
												   [fieldControl]="getFormControl('firmenName')"
												   (change)="checkForSimilarContact()" [readonly]="!editMode"
												   [required]="form.get('juristischePerson').value" data-test-id="firma-input"
												   [allowEmptyString]="false">
				</schir-int-client-one-value-editor>
				<schir-int-client-one-value-editor *ngIf="editMode || getFormControl('firmenName1').value != null"
												   [type]="'text'" [formGroup]="form" [label]="'Firma 1'"
												   [fieldControl]="getFormControl('firmenName1')"
												   [readonly]="!editMode" data-test-id="firma1-input" [allowEmptyString]="false">
				</schir-int-client-one-value-editor>
				<schir-int-client-one-value-editor *ngIf="editMode || getFormControl('firmenName2').value != null"
												   [type]="'text'" [formGroup]="form" [label]="'Firma 2'"
												   [fieldControl]="getFormControl('firmenName2')"
												   [readonly]="!editMode" data-test-id="firma2-input" [allowEmptyString]="false">
				</schir-int-client-one-value-editor>
				<div class="warning" *ngIf="aehnlicherKontakt && getFormControl('firmenName').value &&
										editMode && getFormControl('juristischePerson').value">
					<span class="material-icons">warning</span>
					<mat-error data-test-id="similar-jur-contact-warning" id="similar-jur-contact-warning">{{ MSG_DUPLICATE_JUR }}</mat-error>
				</div>
			</div>
			<div class="lineGroup">
				<schir-int-client-one-value-editor *ngIf="editMode || getFormControl('sitz').value != null"
												   [type]="'text'" [formGroup]="form" [label]="'Sitz'"
												   [fieldControl]="getFormControl('sitz')"
												   [readonly]="!editMode" data-test-id="sitz-input" [allowEmptyString]="false">
				</schir-int-client-one-value-editor>
				<schir-int-client-one-value-editor *ngIf="editMode || getFormControl('zustaendigesAmtsgericht').value != null"
												   [type]="'text'" [formGroup]="form" [label]="'Zuständiges Amtsgericht'"
												   [fieldControl]="getFormControl('zustaendigesAmtsgericht')"
												   [readonly]="!editMode" data-test-id="zustaendiges-amtsgericht-input" [allowEmptyString]="false">
				</schir-int-client-one-value-editor>
				<schir-int-client-one-value-editor *ngIf="editMode || getFormControl('handelsregisterNummer').value != null"
												   [type]="'text'" [formGroup]="form" [label]="'Handelsregisternummer'"
												   [fieldControl]="getFormControl('handelsregisterNummer')"
												   [readonly]="!editMode" data-test-id="handelsregisternummer-input" [allowEmptyString]="false">
				</schir-int-client-one-value-editor>
			</div>
		</mat-expansion-panel>

		<mat-expansion-panel [expanded]="true" *ngIf="editMode || (!editMode && !isKontaktdatenEmpty())">
			<mat-expansion-panel-header>
				<mat-panel-title>
					<h3>Kontaktdaten</h3>
					<mat-divider></mat-divider>
				</mat-panel-title>
			</mat-expansion-panel-header>

			<div class="lineGroup">
				<schir-int-client-one-value-editor class="longField" *ngIf="editMode || (!editMode && getFormControl('strasse').value != null)"
												   [type]="'text'" [formGroup]="form" [label]="'Straße'"
												   [fieldControl]="getFormControl('strasse')"
												   [readonly]="!editMode" data-test-id="strasse-input" [allowEmptyString]="false">
				</schir-int-client-one-value-editor>
				<schir-int-client-one-value-editor *ngIf="editMode || (!editMode && getFormControl('hausnummer').value != null)"
												   [type]="'text'" [formGroup]="form" [label]="'Hausnummer'"
												   [fieldControl]="getFormControl('hausnummer')"
												   [readonly]="!editMode" data-test-id="hausnummer-input" [allowEmptyString]="false">
				</schir-int-client-one-value-editor>
			</div>
			<schir-int-client-one-value-editor *ngIf="editMode || (!editMode && getFormControl('adresszusatz').value != null)"
											   [type]="'text'" [formGroup]="form" [label]="'Adresszusatz'"
											   [fieldControl]="getFormControl('adresszusatz')"
											   [readonly]="!editMode" data-test-id="adresszusatz-input" [allowEmptyString]="false">
			</schir-int-client-one-value-editor>
			<div class="lineGroup">
				<schir-int-client-one-value-editor *ngIf="editMode || (!editMode && getFormControl('plz').value != null)"
												   [type]="'text'" [formGroup]="form" [label]="'Postleitzahl'"
												   [fieldControl]="getFormControl('plz')"
												   [readonly]="!editMode" data-test-id="plz-input" [allowEmptyString]="false">
				</schir-int-client-one-value-editor>
				<schir-int-client-one-value-editor *ngIf="editMode || (!editMode && getFormControl('stadt').value != null)"
												   [type]="'text'" [formGroup]="form" [label]="'Stadt'"
												   [fieldControl]="getFormControl('stadt')"
												   [readonly]="!editMode" data-test-id="stadt-input"
												   (change)="fillSitzIfNeededAndEmpty()"
												   [allowEmptyString]="false">
				</schir-int-client-one-value-editor>
				<schir-int-client-one-value-editor *ngIf="editMode || (!editMode && getFormControl('land').value != null)"
												   [type]="'text'" [formGroup]="form" [label]="'Land'"
												   [fieldControl]="getFormControl('land')"
												   [readonly]="!editMode" data-test-id="land-input" [allowEmptyString]="false">
				</schir-int-client-one-value-editor>
			</div>
			<div class="lineGroup">
				<schir-int-client-one-value-editor *ngIf="editMode || (!editMode && getFormControl('postfach').value != null)"
												   [type]="'text'" [formGroup]="form" [label]="'Postfach'"
												   [fieldControl]="getFormControl('postfach')"
												   [readonly]="!editMode" data-test-id="postfach-input" [allowEmptyString]="false">
				</schir-int-client-one-value-editor>
				<schir-int-client-one-value-editor *ngIf="editMode || (!editMode && getFormControl('telefon').value != null)"
												   [type]="'text'" [formGroup]="form" [label]="'Telefon'"
												   [fieldControl]="getFormControl('telefon')"
												   [readonly]="!editMode" data-test-id="telefon1-input" [allowEmptyString]="false">
				</schir-int-client-one-value-editor>
				<schir-int-client-one-value-editor *ngIf="editMode || (!editMode && getFormControl('email').value != null)"
												   [type]="'text'" [formGroup]="form" [label]="'E-Mail'"
												   [fieldControl]="getFormControl('email')"
												   [readonly]="!editMode" data-test-id="email-input" [allowEmptyString]="false">
				</schir-int-client-one-value-editor>
			</div>
		</mat-expansion-panel>

		<mat-expansion-panel [expanded]="true" *ngIf="editMode || (!editMode && !isSonstigedatenEmpty())">
			<mat-expansion-panel-header>
				<mat-panel-title>
					<h3>Sonstige Daten</h3>
					<mat-divider></mat-divider>
				</mat-panel-title>
			</mat-expansion-panel-header>

			<div class="lineGroup">
				<schir-int-client-one-value-editor *ngIf="editMode || (!editMode && getFormControl('telefon2').value != null)"
												   [type]="'text'" [formGroup]="form" [label]="'Telefon 2'"
												   [fieldControl]="getFormControl('telefon2')"
												   [readonly]="!editMode" data-test-id="telefon2-input" [allowEmptyString]="false">
				</schir-int-client-one-value-editor>
				<schir-int-client-one-value-editor *ngIf="editMode || (!editMode && getFormControl('telefon3').value != null)"
												   [type]="'text'" [formGroup]="form" [label]="'Telefon 3'"
												   [fieldControl]="getFormControl('telefon3')"
												   [readonly]="!editMode" data-test-id="telefon3-input" [allowEmptyString]="false">
				</schir-int-client-one-value-editor>
				<schir-int-client-one-value-editor *ngIf="editMode || (!editMode && getFormControl('fax').value != null)"
												   [type]="'text'" [formGroup]="form" [label]="'Fax'"
												   [fieldControl]="getFormControl('fax')"
												   [readonly]="!editMode" data-test-id="fax-input" [allowEmptyString]="false">
				</schir-int-client-one-value-editor>
			</div>
			<div class="lineGroup">
				<schir-int-client-one-value-editor *ngIf="editMode || (!editMode && getFormControl('egvpAdresse').value != null)"
												   [type]="'text'" [formGroup]="form" [label]="'EGVP-Adresse'"
												   [fieldControl]="getFormControl('egvpAdresse')"
												   [readonly]="!editMode" data-test-id="egvp-adresse-input" [allowEmptyString]="false">
				</schir-int-client-one-value-editor>
				<schir-int-client-one-value-editor *ngIf="editMode || (!editMode && getFormControl('deMail').value != null)"
												   [type]="'text'" [formGroup]="form" [label]="'DE-Mail'"
												   [fieldControl]="getFormControl('deMail')"
												   [readonly]="!editMode" data-test-id="de-mail-input" [allowEmptyString]="false">
				</schir-int-client-one-value-editor>
			</div>
			<schir-int-client-one-value-editor *ngIf="editMode || (!editMode && getFormControl('notiz').value != null)"
											   [type]="'text'" [formGroup]="form" [label]="'Notiz'"
											   [fieldControl]="getFormControl('notiz')"
											   [readonly]="!editMode" data-test-id="notiz-input" [allowEmptyString]="false">
			</schir-int-client-one-value-editor>
		</mat-expansion-panel>
	</div>

	<schir-int-client-button-round *ngIf="editMode" buttonType="submit" data-test-id="save-button" class="button-bottom second"
								   toolTip="Speichern" materialIcon="save" cssClass="focus-box-shadow-dark">
	</schir-int-client-button-round>
	<schir-int-client-button-round *ngIf="!editMode && (selectedKontakt | hasResourceLink: linkRel.EDIT) &&  selectedKontakt.active" (click)="edit()"
								   class="button-bottom first"
								   id="edit-button" data-test-id="edit-button" materialIcon="edit" toolTip="Bearbeiten"
								   cssClass="focus-box-shadow-dark">
	</schir-int-client-button-round>
	<schir-int-client-button-round *ngIf="!editMode && (selectedKontakt | hasResourceLink: linkRel.DELETE) && selectedKontakt.active"
								   (click)="inactivate()" class="button-bottom second"
								   id="deactivate-button" data-test-id="deactivate-button" materialIcon="delete" toolTip="Inaktivieren"
								   cssClass="focus-box-shadow-dark">
	</schir-int-client-button-round>
	<schir-int-client-button-round *ngIf="!editMode && (selectedKontakt | hasResourceLink: linkRel.EDIT) && inactiveAndSelected()"
								   (click)="activate()" class="button-bottom second"
								   id="activate-button" data-test-id="activate-button" materialIcon="autorenew" toolTip="Aktivieren"
								   cssClass="focus-box-shadow-dark">
	</schir-int-client-button-round>
</form>
