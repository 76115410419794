import {createFeatureSelector, createSelector} from '@ngrx/store';
import {isNil} from 'lodash-es';
import {gerichtProfileFeatureState, GerichtProfileRootState} from './gerichtprofile.state';

export const selectGerichtProfileFeature = createFeatureSelector<GerichtProfileRootState>(gerichtProfileFeatureState);

export const gerichtProfileSelector =
	createSelector(selectGerichtProfileFeature, (state: GerichtProfileRootState) => {
		if (isNil(state) || isNil(state.gerichtProfileRoot)) {
			return null;
		}
		return state.gerichtProfileRoot.gerichtProfile;
	});

