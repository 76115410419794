import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {UserResource} from '@schir-int-client/user-shared';
import {
	VorgangFacade,
	VorgangResource,
	VorgangStatusLabel,
	VorgangTypLabel,
	VorgangTypLabelLFPR,
	VorgangTypLabelSchiff,
} from '@schir-int-client/vorgang-shared';
import {WiedervorlageResource} from '@schir-int-client/wiedervorlage-shared';
import {ChangeVorgangStatusDialogComponent} from '../../../../../vorgang/src/lib/vorgaenge-in-verfahren/change-vorgang-status-dialog/change-vorgang-status-dialog.component';
import {DialogService, HandlesBackdropClickAndEscapeKey} from '@schir-int-client/tech';
import {
	AktenzeichenRenderer,
	AktenzeichenRendererProvider,
	AktenzeichenUtilService,
} from '@schir-int-client/aktenzeichen-shared';
import {ApiRootFacade} from '@schir-int-client/api-root';
import {Subscription} from 'rxjs';


@Component({
	selector: 'schir-int-client-wiedervorlage',
	templateUrl: './wiedervorlage.component.html',
	styleUrls: ['./wiedervorlage.component.scss'],
})
export class WiedervorlageComponent extends HandlesBackdropClickAndEscapeKey<ChangeVorgangStatusDialogComponent> implements OnInit, OnDestroy {

	@Input() wiedervorlage: WiedervorlageResource;
	@Input() vorgang: VorgangResource;
	@Input() assignedRechtspfleger: UserResource;
	@Output() vorgangSelected = new EventEmitter<VorgangResource>();

	readonly vorgangStatusLabel = VorgangStatusLabel;
	vorgangsTypen: VorgangTypLabel;
	private renderer: AktenzeichenRenderer;
	private subscription: Subscription;

	constructor(protected dialogService: DialogService,
	            private vorgangFacade: VorgangFacade,
	            private aktenzeichenUtil: AktenzeichenUtilService,
	            private aktenzeichenRendererProvider: AktenzeichenRendererProvider,
	            private apiRootFacade: ApiRootFacade) {
		super(dialogService);
		this.renderer = this.aktenzeichenRendererProvider.getRenderer();
	}

	ngOnInit(): void {
		this.subscription = this.apiRootFacade.getCurrentApiRoot().subscribe(apiRoot =>
			this.vorgangsTypen = apiRoot.features.lfpr ? VorgangTypLabelLFPR : VorgangTypLabelSchiff,
		);
	}

	ngOnDestroy(): void {
		if (this.subscription) {
			this.subscription.unsubscribe();
		}
	}

	openStatusDialog(vorgang: VorgangResource) {
		this.vorgangFacade.setSelectedVorgang(vorgang);
		this.matDialogRef = this.dialogService.openEditorDialog(this, ChangeVorgangStatusDialogComponent, {
			data: { vorgang: vorgang },
		});
	}

	get aktenzeichen() {
		const aktenzeichen = this.aktenzeichenUtil.parse(this.vorgang.aktenzeichen);
		return this.renderer.render(aktenzeichen).split(' ').join('_');
	}

}
