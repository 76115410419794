import {Injectable} from '@angular/core';
import {VerfahrenFacade, VerfahrenResource} from '@schir-int-client/verfahren-shared';
import {isNil} from 'lodash-es';
import {EipConnection} from './eip.connection';
import {EipMessages} from './eip.messages';

@Injectable({ providedIn: 'root' })
export class EipLawsuit {
	verfahren: VerfahrenResource;

	constructor(private connection: EipConnection, private verfahrenFacade: VerfahrenFacade) {}

	init() {
		this.connection.addMessageListener((messageName: string, message: any, id: string) => {
			this.onMessage(messageName, message, id);
		});

		this.verfahrenFacade.verfahrenSingle.subscribe(verfahrenSingle => {
			if (!isNil(verfahrenSingle)) {
				this.verfahren = verfahrenSingle;

				this.sendActivateLawsuitContextRequest(verfahrenSingle.aktenzeichen);
			}
		});
	}

	private sendActivateLawsuitContextRequest(aktenzeichen: string) {
		this.connection.sendMessage(EipMessages.activateLawsuitContextRequest(aktenzeichen));
		// Achtung: der Server antwortet mit einem GetLawSuitRequest, der beantwortet werden muss,
		// aber aktuell nicht beantwortet wird, siehe onMessage(). Daraufhin lehnt der Server den activate...Request ab mit einer
		// timeout-Exception.
	}

	private onMessage(messageName: string, messageObj: any, id: string) {
		switch (messageName) {
			case 'ActivateLawsuitContextResponse':
				this.onActivateLawsuitContextResponse(messageObj);
				break;
			case 'GetLawsuitRequest':
				const request: GetLawsuitRequest = <GetLawsuitRequest>messageObj;

				console.log('message object: %o', messageObj);
				console.log('message json: %o', request.LawsuitId);

				this.connection.sendMessageWithId(id, EipMessages.getLawsuitResponse(this.verfahren));
				break;
			default:
				console.log('eIP-Lawsuit: unknown message type: ' + messageName);
		}
	}

	private onActivateLawsuitContextResponse(messageObj: any) {
		console.log('onActivateLawsuitContextResponse: ' + messageObj);
	}
}

interface GetLawsuitRequest {
	LawsuitId: string;
	ExecutionContext: any;
}
