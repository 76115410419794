import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
import {AppState, getApiRoot} from '@schir-int-client/api-root';
import {DoNothingAction} from '@schir-int-client/ngrx-helpers';
import {AppNotificationService, DialogService} from '@schir-int-client/tech';
import {PosteingangFacade} from '@schir-int-client/posteingang-shared';
import {userSelector} from '@schir-int-client/user-shared';
import {isEmpty} from 'lodash-es';
import {of} from 'rxjs';
import {catchError, map, mergeMap, switchMap, tap, withLatestFrom} from 'rxjs/operators';
import {
	AddZaehlblattAktionenAction,
	AskToDeleteVerfahrenAction,
	AskToMarkAsAbgelehntAction,
	AssignUSignalAction,
	CreateNeueintragungAskToAction,
	CreateVerfahrenAction,
	CreateVerfahrenWithoutPosteingangAction,
	DeleteVerfahrenAction,
	DeleteZaehlblattAktionAction,
	DownloadAmtlicherAuszugSuccessAction,
	DownloadAusdruckAction,
	DownloadAusdruckSuccessAction,
	DownloadSchiffsbriefSuccessAction,
	DownloadSchiffszertifikatSuccessAction,
	ExportVerfahrenAction,
	ExportVerfahrenSuccessAction,
	LoadVerfahrenAction,
	LoadVerfahrenSingleAction,
	LoadVerfahrenSingleSuccessAction,
	LoadZaehlblattAction,
	LoadZaehlblattSuccessAction,
	MarkAsAbgelehntAction,
	ReloadSearchResultAction,
	SetVerfahrenPropertyAction,
	UnassignKontaktAction,
	UnassignKontaktSuccessAction,
	UpdateVerfahrenNotizAction,
	VerfahrenActions,
	VerfahrenCreatedAction,
	VerfahrenCreateFailAction,
	VerfahrenLoadedAction,
	VerfahrenSearchAction,
	VerfahrenSearchSuccessAction,
	VerfahrenUpdatedSuccessAction,
} from './verfahren.actions';
import {VerfahrenFacade} from './verfahren.facade';
import {getConfirmationMessageByRegister, VerfahrenMessages} from './verfahren.messages';
import {verfahrenSearchBySelector, verfahrenSelector, verfahrenSingleSelector} from './verfahren.selectors';
import {VerfahrenService} from './verfahren.service';
import {Router} from '@angular/router';
import {getUrl} from '@ngxp/rest';
import {
	AktenzeichenRenderer,
	AktenzeichenRendererProvider,
	AktenzeichenUtilService,
} from '@schir-int-client/aktenzeichen-shared';
import {VerfahrenResource} from '@schir-int-client/verfahren-shared';

@Injectable()
export class VerfahrenEffects {
	private renderer: AktenzeichenRenderer;

	constructor(
		private actions: Actions,
		private service: VerfahrenService,
		private store: Store<AppState>,
		private notificationService: AppNotificationService,
		private posteingangFacade: PosteingangFacade,
		private verfahrenFacade: VerfahrenFacade,
		private dialogService: DialogService,
		private router: Router,
		private rendererProvider: AktenzeichenRendererProvider,
		private aktenzeichenUtil: AktenzeichenUtilService,
	) {
		this.renderer = this.rendererProvider.getRenderer();
	}


	loadVerfahren = createEffect(() => this.actions.pipe(
		ofType(VerfahrenActions.LOAD_VERFAHREN, VerfahrenActions.VERFAHREN_CREATED),
		withLatestFrom(this.store.select(getApiRoot()), this.store.select(verfahrenSearchBySelector)),
		switchMap(([, apiRoot, searchBy]) => {
			if (isEmpty(searchBy.searchString)) {
				return this.service.getAll(apiRoot).pipe(
					mergeMap(verfahren => [
						new VerfahrenLoadedAction(verfahren),
					]),
				);
			}
			return of(new VerfahrenSearchAction(searchBy));
		}),
	));


	loadVerfahrenSingle = createEffect(() => this.actions.pipe(
		ofType(VerfahrenActions.LOAD_VERFAHREN_SINGLE),
		switchMap(action => {
			return this.service.getOne((<LoadVerfahrenSingleAction>action).verfahrenUri).pipe(
				map(verfahrenSingle => {
					return new LoadVerfahrenSingleSuccessAction(verfahrenSingle);
				}),
			);
		}),
	));


	searchVerfahren = createEffect(() => this.actions.pipe(
		ofType(VerfahrenActions.SEARCH_VERFAHREN),
		withLatestFrom(this.store.select(getApiRoot())),
		switchMap(([action, apiRoot]) => {
			return this.service.searchVerfahren(apiRoot, (<VerfahrenSearchAction>action).query).pipe(
				map(verfahrenList => new VerfahrenSearchSuccessAction(verfahrenList)),
			);
		}),
	));


	createVerfahren = createEffect(() => this.actions.pipe(
		ofType(VerfahrenActions.CREATE_VERFAHREN),
		withLatestFrom(this.posteingangFacade.posteingang),
		switchMap(([action, posteingang]) => {
			return this.service.createVerfahren(posteingang, (<CreateVerfahrenAction>action).register).pipe(
				mergeMap(verfahrenList => {
					this.clearSearchString();
					return [new VerfahrenCreatedAction(), new VerfahrenLoadedAction(verfahrenList)];
				}),
			);
		}),
	));


	createVerfahrenWithoutPosteingang = createEffect(() => this.actions.pipe(
		ofType(VerfahrenActions.CREATE_VERAHREN_WITHOUT_POSTEINGANG),
		withLatestFrom(this.store.select(verfahrenSelector)),
		switchMap(([action, verfahrenList]) => this.service.createVerfahrenWithoutPosteingang((<CreateVerfahrenWithoutPosteingangAction>action).newVerfahren, verfahrenList.resource).pipe(
			map((newVerfahren) => {
				this.verfahrenFacade.setSelectedVerfahren(newVerfahren);
				this.router.navigate(['/verfahren', btoa(getUrl(newVerfahren))]);
				this.clearSearchString();
				return new LoadVerfahrenAction();
			}),
			catchError(error => {
				if (error.issues && (
					error.issues[0].messageCode == 'verfahren.duplicate.aktenzeichen' ||
					error.issues[0].messageCode == 'unauthorized.register')) {
					return of(new VerfahrenCreateFailAction(error));
				} else {
					return of(error);
				}
			}),
		)),
	));


	createVerfahrenFail = createEffect(() => this.actions.pipe(
		ofType(VerfahrenActions.VERFAHREN_CREATE_FAIL),
		tap(action => {
			switch ((<VerfahrenCreateFailAction>action).error.issues[0].messageCode) {
				case 'verfahren.duplicate.aktenzeichen':
					this.notificationService.handleInfo('Das Aktenzeichen ist bereits vergeben, das neue Verfahren wurde nicht angelegt',
						null,
						'Doppeltes Aktenzeichen');
					break;
				case 'unauthorized.register':
					this.notificationService.handleInfo('Sie haben keinen Zugriff auf das ausgewählte Register',
						null,
						'Kein Registerzugriff');
					break;
			}
		}),
	), { dispatch: false });


	acknowledgeVerfahrenCreated = createEffect(() => this.actions.pipe(
		ofType(VerfahrenActions.VERFAHREN_CREATED),
		tap(() => {
			this.notificationService.handleInfo(VerfahrenMessages.ACKNOWLEDGE_VERFAHREN_CREATED);
			this.posteingangFacade.loadPosteingaenge();
		}),
	), { dispatch: false });


	saveNotiz = createEffect(() => this.actions.pipe(
		ofType(VerfahrenActions.VERAHREN_UPDATE_NOTIZ),
		switchMap(action => {
			const notiz: string = (<UpdateVerfahrenNotizAction>action).notiz;
			const verfahrenUri: string = (<UpdateVerfahrenNotizAction>action).verfahrenUri;

			return this.service.updateNotiz(notiz, verfahrenUri).pipe(
				map(verfahren => new VerfahrenUpdatedSuccessAction(verfahren)),
			);
		}),
	));

	setProperty = createEffect(() => this.actions.pipe(
		ofType(VerfahrenActions.SET_PROPERTY),
		switchMap(action => {
			const update = <SetVerfahrenPropertyAction>action;
			return this.service.setVerfahrenProperty(update.verfahren, update.linkRel, update.value).pipe(
				map(verfahren => new VerfahrenUpdatedSuccessAction(verfahren)),
			);
		}),
	));

	addZaehlblattAktionen = createEffect(() => this.actions.pipe(
		ofType(VerfahrenActions.ADD_ZAEHLBLATT_AKTIONEN),
		switchMap(action => {
			const addZaehlblatt = <AddZaehlblattAktionenAction>action;
			return this.service.addZaehlblattAktionen(addZaehlblatt.values, addZaehlblatt.zaehlblatt)
				.pipe(map(zaehlblatt => new LoadZaehlblattSuccessAction(zaehlblatt)));
		}),
	));

	deleteZaehlblattAktion = createEffect(() => this.actions.pipe(
		ofType(VerfahrenActions.DELETE_ZAEHLBLATT_AKTION),
		switchMap(action => {
			const deleteEntry = <DeleteZaehlblattAktionAction>action;
			return this.service.deleteZaehlblattAktion(deleteEntry.entry)
				.pipe(map(zaehlblatt => new LoadZaehlblattSuccessAction(zaehlblatt)));
		}),
	));

	loadZaehlblatt = createEffect(() => this.actions.pipe(
		ofType(VerfahrenActions.LOAD_ZAEHLBLATT),
		switchMap(action => {
			return this.service.loadZaehlblatt((<LoadZaehlblattAction>action).verfahren).pipe(
				map(zaehlblatt => new LoadZaehlblattSuccessAction(zaehlblatt)),
			);
		}),
	));

	confirmCreateNeueintragung = createEffect(() => this.actions.pipe(
		ofType(VerfahrenActions.ASKTO_CREATE_NEUEINTRAGUNG),
		switchMap(action => {
			const message = getConfirmationMessageByRegister((<CreateNeueintragungAskToAction>action).register);
			return this.dialogService.openConfirmationDialog(message, [], ['Übernehmen', 'Abbrechen']).pipe(
				map(confirmed => confirmed ? new CreateVerfahrenAction((<CreateNeueintragungAskToAction>action).register) : new DoNothingAction()),
			);
		}),
	));


	confirmDelete = createEffect(() => this.actions.pipe(
		ofType(VerfahrenActions.ASKTO_DELETE),
		switchMap(action => {
			const verfahren: VerfahrenResource = (<AskToDeleteVerfahrenAction>action).verfahren;
			return this.dialogService.openConfirmationDialog(VerfahrenMessages.ASK_TO_DELETE_VERFAHREN).pipe(
				map(confirmed => confirmed ? new DeleteVerfahrenAction(verfahren) : new DoNothingAction()),
			);
		}),
	));

	delete = createEffect(() => this.actions.pipe(
		ofType(VerfahrenActions.DELETE_VERFAHREN),
		switchMap(action => {
			const verfahren: VerfahrenResource = (<DeleteVerfahrenAction>action).verfahren;
			return this.service.delete(verfahren).pipe(
				map(verfahrenList => new VerfahrenLoadedAction(verfahrenList)),
			);
		}),
	));


	confirmMarkAsAbgelehnt = createEffect(() => this.actions.pipe(
		ofType(VerfahrenActions.ASKTO_MARK_AS_ABGELEHNT),
		switchMap(action => {
			return this.dialogService.openConfirmationDialog(VerfahrenMessages.ASK_TO_REJECT_VERFAHREN).pipe(
				map(confirmed => confirmed ? new MarkAsAbgelehntAction((<AskToMarkAsAbgelehntAction>action).verfahren) : new DoNothingAction()),
			);
		}),
	));


	markAsAbgelehnt = createEffect(() => this.actions.pipe(
		ofType(VerfahrenActions.MARK_AS_ABGELEHNT),
		switchMap(action => this.service.markAsAbgelehnt((<MarkAsAbgelehntAction>action).verfahren).pipe(
			map(updated => new VerfahrenUpdatedSuccessAction(updated)),
		)),
	));
	//DRUCK

	downloadAusdruck = createEffect(() => this.actions.pipe(
		ofType(VerfahrenActions.DOWNLOAD_AUSDRUCK),
		withLatestFrom(this.store.select(verfahrenSingleSelector)),
		switchMap(([action, verfahren]) => {
			return this.service.downloadAusdruck((<DownloadAusdruckAction>action).beglaubigt, verfahren).pipe(
				map(data => new DownloadAusdruckSuccessAction(data)),
			);
		}),
	));

	saveAusdruck = createEffect(() => this.actions.pipe(
		ofType(VerfahrenActions.DOWNLOAD_AUSDRUCK_SUCCESS),
		tap(action => {
			this.service.saveFile((<DownloadAusdruckSuccessAction>action).data, 'RegisterAusdruck.pdf');
		}),
	), { dispatch: false });


	downloadAmtlicherAuszug = createEffect(() => this.actions.pipe(
		ofType(VerfahrenActions.DOWNLOAD_AMTLICHER_AUSZUG),
		withLatestFrom(this.store.select(verfahrenSingleSelector)),
		switchMap(([, verfahren]) => {
			return this.service.downloadAmtlicherAuszug(verfahren).pipe(
				map(data => new DownloadAmtlicherAuszugSuccessAction(data)),
			);
		}),
	));


	downloadSchiffszertifikatAction = createEffect(() => this.actions.pipe(
		ofType(VerfahrenActions.DOWNLOAD_SCHIFFSZERTIFIKAT),
		withLatestFrom(this.store.select(verfahrenSingleSelector)),
		switchMap(([, verfahren]) => {
			return this.service.downloadSchiffszertifikat(verfahren).pipe(
				map(data => new DownloadSchiffszertifikatSuccessAction(data)),
			);
		}),
	));


	downloadSchiffsbrief = createEffect(() => this.actions.pipe(
		ofType(VerfahrenActions.DOWNLOAD_SCHIFFSBRIEF),
		withLatestFrom(this.store.select(verfahrenSingleSelector)),
		switchMap(([, verfahren]) => {
			return this.service.downloadSchiffsbrief(verfahren).pipe(
				map(data => new DownloadSchiffsbriefSuccessAction(data)),
			);
		}),
	));

	saveSchiffsbrief = createEffect(() => this.actions.pipe(
		ofType(VerfahrenActions.DOWNLOAD_SCHIFFSBRIEF_SUCCESS),
		tap(action => {
			this.service.saveFile((<DownloadSchiffsbriefSuccessAction>action).data, 'Schiffsbrief.pdf');
		}),
	), { dispatch: false });

	saveAmtlicherAuszug = createEffect(() => this.actions.pipe(
		ofType(VerfahrenActions.DOWNLOAD_AMTLICHER_AUSZUG_SUCCESS),
		tap(action => {
			this.service.saveFile((<DownloadAmtlicherAuszugSuccessAction>action).data, 'AmtlicherAuszug.pdf');
		}),
	), { dispatch: false });

	exportVerfahren = createEffect(() => this.actions.pipe(
		ofType(VerfahrenActions.EXPORT_VERFAHREN),
		switchMap(action => {
			const url = (<ExportVerfahrenAction>action).url;
			return this.service.exportVerfahren(url).pipe(
				map(data => new ExportVerfahrenSuccessAction(data)),
			);
		}),
	));

	saveVerfahren = createEffect(() => this.actions.pipe(
		ofType(VerfahrenActions.EXPORT_VERFAHREN_SUCCESS),
		tap(action => {
			this.service.saveFile((<ExportVerfahrenSuccessAction>action).data, 'Verfahren.json');
		}),
	), { dispatch: false });


	saveSchiffszertifikat = createEffect(() => this.actions.pipe(
		ofType(VerfahrenActions.DOWNLOAD_SCHIFFSZERTIFIKAT_SUCCESS),
		tap(action => {
			this.service.saveFile((<DownloadSchiffszertifikatSuccessAction>action).data, 'Schiffszertifikat.pdf');
		}),
	), { dispatch: false });


	bearbeiten = createEffect(() => this.actions.pipe(
		ofType(VerfahrenActions.BEARBEITEN),
		withLatestFrom(this.store.select(verfahrenSingleSelector), this.store.select(userSelector)),
		switchMap(([, verfahren, user]) => {
			return this.service.verfahrenBearbeiten(verfahren, user).pipe(
				map(updatedVerfahren => new VerfahrenUpdatedSuccessAction(updatedVerfahren)),
			);
		}),
	));


	uebernehmen = createEffect(() => this.actions.pipe(
		ofType(VerfahrenActions.UEBERNEHMEN),
		withLatestFrom(this.store.select(verfahrenSingleSelector), this.store.select(userSelector)),
		switchMap(([, verfahren, user]) => {
			return this.service.verfahrenUebernehmen(verfahren, user).pipe(
				map(updatedVerfahren => new VerfahrenUpdatedSuccessAction(updatedVerfahren)),
			);
		}),
	));


	bearbeitenBeenden = createEffect(() => this.actions.pipe(
		ofType(VerfahrenActions.BEARBEITEN_BEENDEN),
		withLatestFrom(this.store.select(verfahrenSingleSelector)),
		switchMap(([, verfahren]) => {
			return this.service.verfahrenBearbeitenBeenden(verfahren).pipe(
				map(updatedVerfahren => new VerfahrenUpdatedSuccessAction(updatedVerfahren)),
			);
		}),
	));


	confirmUnassignKontakt = createEffect(() => this.actions.pipe(
		ofType(VerfahrenActions.ASKTO_UNASSIGN_KONTAKT),
		switchMap((action) => {
			return this.dialogService.openConfirmationDialog(VerfahrenMessages.ASK_TO_UNASSIGN_KONTAKT).pipe(
				map(confirmed => confirmed ? new UnassignKontaktAction((<UnassignKontaktAction>action).kontakt, (<UnassignKontaktAction>action).kategorie) : new DoNothingAction()),
			);
		}),
	));


	unassignKontakt = createEffect(() => this.actions.pipe(
		ofType(VerfahrenActions.UNASSIGN_KONTAKT),
		withLatestFrom(this.verfahrenFacade.verfahrenSingle),
		switchMap(([action, verfahren]) => {
			return this.service.unassignKontakt(verfahren, (<UnassignKontaktAction>action).kontakt, (<UnassignKontaktAction>action).kategorie).pipe(
				map(updatedVerfahren => new UnassignKontaktSuccessAction(updatedVerfahren, (<UnassignKontaktAction>action).kategorie)),
			);
		}),
	));


	assignUSignalForVerfahren = createEffect(() => this.actions.pipe(
		ofType(VerfahrenActions.ASSIGN_USIGNAL),
		switchMap((action) => {
			const assignAction: AssignUSignalAction = <AssignUSignalAction>action;
			return this.service.assignUsignal(assignAction.verfahren).pipe(
				map(usignal => {
					const { verfahren } = assignAction;
					const aktenzeichen = this.aktenzeichenUtil.parse(verfahren);
					const aktenzeichenRendered = this.renderer.render(aktenzeichen);
					this.notificationService.handleInfo(`Das U-Signal "${usignal.body.signal}" wurde dem Verfahren "${aktenzeichenRendered}" zugeordnet `);
					return new ReloadSearchResultAction();
				}),
				catchError(error => {
					if (error.status == '404') {
						this.notificationService.handleError('Es stehen keine freien U-Signale mehr zur Verfügung');
					}
					return of({
							type: VerfahrenActions.REPORT_NO_USIGNAL_LEFT,
							payload: error,
						},
					);
				}),
			);
		}),
	));

	private clearSearchString() {
		this.verfahrenFacade.verfahrenCreatedSubject?.next('verfahren created ');
	}

}
