import {Action} from '@ngrx/store';
import {
	AdminRootResource,
	UnprocessableEntityError,
	UnterscheidungssignalListResource,
	UnterscheidungssignalResource,
} from './admin.model';

export enum AdminRootActions {
	LOAD_ADMIN_ROOT = 'LOAD_ADMIN_ROOT',
	ADMIN_ROOT_LOADED = 'ADMIN_ROOT_LOADED',
	LOAD_USIGNALE = 'LOAD_USIGNALE',
	USIGNALE_LOADED = 'USIGNALE_LOADED',
	UPLOAD_USIGNALE = 'UPLOAD_USIGNALE',
	USIGNALE_UPLODED = 'USIGNALE_UPLOADED',
	HANDLE_USIGNAL_ERROR = 'HANDLE_USIGNAL_ERROR',
	SAVE_ERROR = 'SAVE_ERROR',
	CLEAR_ERROR = 'CLEAR_ERROR',
	ASKTO_DELETE_USIGNAL = 'ASKTO_DELETE_USIGNAL',
	DELETE_USIGNAL = 'DELETE_USIGNAL'
}

export class LoadAdminRootAction implements Action {
	type = AdminRootActions.LOAD_ADMIN_ROOT;
}

export class AdminRootLoadedAction implements Action {
	type = AdminRootActions.ADMIN_ROOT_LOADED;

	constructor(public payload: AdminRootResource) { }
}

export class LoadUSignaleAction implements Action {
	type = AdminRootActions.LOAD_USIGNALE;

	constructor() { }
}

export class USignaleLoadedAction implements Action {
	type = AdminRootActions.USIGNALE_LOADED;

	constructor(public unterscheidungssignale: UnterscheidungssignalListResource) { }
}

export class UploadUSignaleAction implements Action {
	type = AdminRootActions.UPLOAD_USIGNALE;

	constructor(public file: File) { }
}

export class HandleUsignalErrorAction implements Action {
	type = AdminRootActions.HANDLE_USIGNAL_ERROR;

	constructor(public error: any) { }
}

export class SaveUsignlErrorAction implements Action {
	type = AdminRootActions.SAVE_ERROR;

	constructor(public error: UnprocessableEntityError) { }
}

export class ClearErrorAction implements Action {
	type = AdminRootActions.CLEAR_ERROR;
}

export class AskToDeleteUnterscheidungssignalAction implements Action {
	type = AdminRootActions.ASKTO_DELETE_USIGNAL;

	constructor(public usignal: UnterscheidungssignalResource) { }
}

export class DeleteUnterscheidungssignalAction implements Action {
	type = AdminRootActions.DELETE_USIGNAL;

	constructor(public usignal: UnterscheidungssignalResource) { }
}
