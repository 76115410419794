import {Action} from '@ngrx/store';
import {VorlageListResource, VorlageResource} from './vorlage.model';
import {createStateResource, StateResource} from '@schir-int-client/ngrx-helpers';
import {VorlageActions, VorlagenLoadedAction} from './vorlage.actions';

export interface VorlageState {
	vorlagen: VorlageListResource;
	vorlagenResourcesByUri: {
		[vorlageUri: string]: StateResource<VorlageResource>
	};
}

export const initialState: VorlageState = {
	vorlagen: null,
	vorlagenResourcesByUri: {},
};

export function vorlageReducer(state: VorlageState = initialState, action: Action): VorlageState {
	switch (action.type) {
		case VorlageActions.LOAD_VORLAGEN_SUCCESS:
			return {
				...state,
				vorlagen: (<VorlagenLoadedAction>action).payload,
				vorlagenResourcesByUri: unpackVorlagen((<VorlagenLoadedAction>action).payload),
			};
		default:
			return state;
	}
}

function unpackVorlagen(vorlagen: VorlageListResource): { [vorlageUri: string]: StateResource<VorlageResource> } {
	let vorlagenByUri: { [vorlageUri: string]: StateResource<VorlageResource> } = {};
	let vorlageList = vorlagen._embedded?.vorlageList;

	if (vorlageList) {
		for (let key in vorlageList) {
			const vorlage: VorlageResource = vorlageList[key];

			vorlagenByUri[vorlage._links.self.href] = createStateResource(vorlage);
		}
	}

	return vorlagenByUri;
}
