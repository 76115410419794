import {Component} from '@angular/core';
import {Observable, Subscription} from 'rxjs';
import {ErrorService} from './error.service';
import {map} from 'rxjs/operators';

/**
 * Stellt die Variablen für das Template bereit und aktualisiert die anzuzeigende Nachricht, wenn ein neuer Fehler-Status vorliegt.
 */
@Component({
	selector: 'schir-int-client-error-page',
	templateUrl: './error-page.component.html',
	styleUrls: ['./error-page.component.scss'],
})
export class ErrorPageComponent {

	message: Observable<string>;
	loginUrl = '/api/login';

	subscription: Subscription;

	constructor(private errorService: ErrorService) {
		this.message = this.errorService.getObservable().pipe(map(value => this.errorService.getErrorMessage()));
	}
}
