import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
import {hasLink} from '@ngxp/rest';
import {DoNothingAction} from '@schir-int-client/ngrx-helpers';
import {VerfahrenLinkRel, verfahrenSingleSelector} from '@schir-int-client/verfahren-shared';
import {of} from 'rxjs';
import {map, mergeMap, switchMap, withLatestFrom} from 'rxjs/operators';
import {AppState} from '../../../../apps/int-client/src/app/shared/store/app-state.model';
import {
	AlternativeTextActions,
	CreateAlternativeTextEntryAction,
	CreateAlternativeTextEntrySuccessAction,
	DeleteAlternativeTextEntryAction,
	DeleteAlternativeTextEntrySuccessAction,
	LoadAlternativeTexteSuccessAction,
	UpdateAlternativeTextEntryAction,
	UpdateAlternativeTextEntrySuccessAction,
} from './alternative-text.actions';
import {AlternativeTextService} from './alternative-text.service';

@Injectable()
export class AlternativeTextEffects {
	constructor(
		private actions: Actions,
		private store: Store<AppState>,
		private service: AlternativeTextService,
	) { }


	loadAlternativeTextEntries = createEffect(() => this.actions.pipe(
		ofType(AlternativeTextActions.LOAD_ALTERNATIVE_TEXTE),
		withLatestFrom(this.store.select(verfahrenSingleSelector)),
		switchMap(([, verfahren]) => {
			if (!hasLink(verfahren, VerfahrenLinkRel.ALTERNATIVE_TEXTE)) {
				return of(new DoNothingAction());
			}
			return this.service.getAlternativeTexte(verfahren).pipe(
				map(alternativeTexte => new LoadAlternativeTexteSuccessAction(alternativeTexte)),
			);
		}),
	));


	createAlternativeTextEntry = createEffect(() => this.actions.pipe(
		ofType(AlternativeTextActions.CREATE_ALTERNATIVE_TEXT_ENTRY),
		mergeMap(action => {
			return this.service.createAlternativeText((<CreateAlternativeTextEntryAction>action).flaggenrechtResource, (<CreateAlternativeTextEntryAction>action).alternativeText).pipe(
				map(alternativeText => new CreateAlternativeTextEntrySuccessAction(alternativeText)));
		}),
	));


	updateAlternativeTextEntry = createEffect(() => this.actions.pipe(
		ofType(AlternativeTextActions.UPDATE_ALTERNATIVE_TEXT_ENTRY),
		mergeMap(action => {
			const updateAction: UpdateAlternativeTextEntryAction = <UpdateAlternativeTextEntryAction>action;
			return this.service.updateAlternativeText(updateAction.alternativeTextResource, updateAction.alternativeText).pipe(
				map(alternativeTexte => new UpdateAlternativeTextEntrySuccessAction(alternativeTexte)));
		}),
	));


	deleteAlternativeTextEntry = createEffect(() => this.actions.pipe(
		ofType(AlternativeTextActions.DELETE_ALTERNATIVE_TEXT_ENTRY),
		mergeMap(action => {
			return this.service.deleteAlternativeText((<DeleteAlternativeTextEntryAction>action).alternativeTextResource).pipe(
				map(() => new DeleteAlternativeTextEntrySuccessAction((<DeleteAlternativeTextEntryAction>action).alternativeTextResource)));
		}),
	));
}
