import {Resource} from '@ngxp/rest';

export interface AdminRoot {}

export interface AdminRootResource extends AdminRoot, Resource {}


export interface Unterscheidungssignal {
	signal: string;
	verfahren: string;
	lastChanged: Date;
	aktenzeichen: string;
	status: USIGNAL_STATUS;
}

export interface UnterscheidungssignalResource extends Unterscheidungssignal, Resource {}

export interface UnterscheidungssignalListResource extends Resource {}

export enum USIGNAL_STATUS {
	FREI = 'FREI',
	BLOCKIERT = 'BLOCKIERT',
	VERGEBEN = 'VERGEBEN'
}

export enum ErrorType {
	UNPROCESSABLE_ENTITY
}

export interface EntityError {
	type: ErrorType;
}

export class UnprocessableEntityError implements EntityError {
	error: {
		issues: { field: string, message: string }[]
	};
	type: ErrorType = ErrorType.UNPROCESSABLE_ENTITY;
}
