import {Component, Input} from '@angular/core';
import {createEmptyStateResource, StateResource} from '@schir-int-client/ngrx-helpers';


@Component({
	selector: 'schir-int-client-spinner',
	templateUrl: './spinner.component.html',
	styleUrls: ['./spinner.component.scss'],
})
export class SpinnerComponent {

	@Input() stateResource: StateResource<any> = createEmptyStateResource();
	@Input() diameter: number = 70;

	constructor() { }

	get showSpinner(): boolean {
		return (this.stateResource) ? this.stateResource.loading : false;
	}
}
