export interface Issue {
	messageCode: string,
	message: string
}

export interface ApiError {
	id: string,
	issues: Issue[]
}

export const CONTENT_TYPE__URI_LIST = { 'Content-Type': 'text/uri-list' };
