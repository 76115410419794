import {Component, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {getUrl, hasLink} from '@ngxp/rest';
import {UserFacade, UserResource} from '@schir-int-client/user-shared';
import {VorgangActions, VorgangFacade, VorgangLinkRel, VorgangResource} from '@schir-int-client/vorgang-shared';
import {
	DatumsBereichFacade,
	WiedervorlageActions,
	WiedervorlageFacade,
	WiedervorlageLinkRel,
	WiedervorlageResource,
} from '@schir-int-client/wiedervorlage-shared';
import {StateResource} from '@schir-int-client/ngrx-helpers';
import {combineLatest, Observable, of, Subscription} from 'rxjs';
import {MatDatepickerInputEvent} from '@angular/material/datepicker';
import {Moment} from 'moment/moment';
import {UntypedFormControl, Validators} from '@angular/forms';
import * as moment from 'moment';
import {first, map} from 'rxjs/operators';
import {ofType} from '@ngrx/effects';
import {ActionsSubject} from '@ngrx/store';
import {VerfahrenFacade, VerfahrenResource} from '@schir-int-client/verfahren-shared';


@Component({
	selector: 'schir-int-client-wiedervorlage-list',
	templateUrl: './wiedervorlage-list.component.html',
	styleUrls: ['./wiedervorlage-list.component.scss'],
})
export class WiedervorlageListComponent implements OnInit, OnDestroy {

	wiedervorlagen: Observable<StateResource<WiedervorlageResource[]>>;

	vonDatumControl = new UntypedFormControl(moment(0), Validators.required);
	bisDatumControl = new UntypedFormControl(moment().add(14, 'days'), Validators.required);

	private subscriptions: Subscription[] = [];

	constructor(
		private userFacade: UserFacade,
		private facade: WiedervorlageFacade,
		private datumsBereichFacade: DatumsBereichFacade,
		private vorgangFacade: VorgangFacade,
		private router: Router,
		private actionListener: ActionsSubject,
		private verfahrenFacade: VerfahrenFacade,
	) {}

	ngOnInit() {
		this.wiedervorlagen = combineLatest([this.facade.getWiedervorlagen(<Moment>this.vonDatumControl.value, <Moment>this.bisDatumControl.value), this.verfahrenFacade.verfahren])
			.pipe(
				map(([wiedervorlagen, verfahrenList]) => {
					wiedervorlagen.resource?.forEach(wiedervorlage => {

						const verfahren: VerfahrenResource = verfahrenList.resource?._embedded['verfahrenList'].find(v => v._links.self.href === wiedervorlage.vorgang.verfahrenId);
						if (verfahren) {
							wiedervorlage.standortAkte = verfahren.stammdaten.standortAkte;
						}
					});
					return wiedervorlagen;
				}),
			);

		this.datumsBereichFacade.getDatumsBereich().pipe(first()).subscribe(
			(value: any) => {
				const von: Moment = <Moment>value.datumsBereich.resource.anfangsDatum;
				const bis: Moment = <Moment>value.datumsBereich.resource.endDatum;
				if (von.year() === 1970) {
					this.setVonDatum();
				} else {
					this.vonDatumControl.setValue(von);
					this.bisDatumControl.setValue(bis);
					this.facade.refreshWiedervorlagen(<Moment>this.vonDatumControl.value, <Moment>this.bisDatumControl.value);
				}
			});


		this.subscriptions.push(this.actionListener.pipe(
			ofType(VorgangActions.MARKED_SUCCESS, WiedervorlageActions.CREATE_WIEDERVORLAGE_SUCCESS, WiedervorlageActions.UPDATE_WIEDERVORLAGE_SUCCCESS)).subscribe(value => {
				this.facade.refreshWiedervorlagen(<Moment>this.vonDatumControl.value, <Moment>this.bisDatumControl.value);
			},
		));
	}

	ngOnDestroy() {
		this.subscriptions.forEach(s => s.unsubscribe());
	}

	/**
	 * Setzt das Von-Datum auf das Datum der ältesten Wiedervorlage
	 * @private
	 */
	private setVonDatum() {
		this.subscriptions.push(this.wiedervorlagen.pipe(first(value => value.loaded))
			.subscribe((value: StateResource<WiedervorlageResource[]>) => {
				if (value.resource && value.resource.length > 0) {
					this.vonDatumControl.setValue(value.resource[0].wiedervorlageDatum);
				}
			}));
	}

	/**
	 * Initialisiert einen Reload der Wiedervorlagen, wenn über den Datepicker oder per manueller Eingabe ein anderes Datum ausgewählt wurde.
	 *
	 * @param event
	 */
	dateChosen(event: MatDatepickerInputEvent<Moment>) {

		let von: Moment = <Moment>this.vonDatumControl.value;
		let bis: Moment = <Moment>this.bisDatumControl.value;
		if (event.value && !this.vonDatumControl.errors && !this.bisDatumControl.errors && von && bis) {
			von = (typeof von === 'string') ? moment(von) : von;
			bis = (typeof bis === 'string') ? moment(bis) : bis;

			if (von.isAfter(bis)) {
				return;
			}
			const dateFormat = 'YYYY-MM-DD';
			const datePattern = /^[12]\d{3}-[01]\d-[0123]\d$/;

			const vonString: string = von.format(dateFormat);
			const bisString: string = bis.format(dateFormat);

			if (datePattern.test(vonString) && datePattern.test(bisString)) {
				this.facade.refreshWiedervorlagen(von, bis);
			}
		}
	}

	getVorgang(wiedervorlage: WiedervorlageResource): Observable<StateResource<VorgangResource>> {
		return this.vorgangFacade.getVorgangFromLink(wiedervorlage, WiedervorlageLinkRel.VORGANG);
	}

	/**
	 * Achtung: eine Link Rechtspfleger scheint aktuell gar nicht gesetzt werden zu können.
	 * @param vorgang
	 */
	getRechtspfleger(vorgang: VorgangResource): Observable<UserResource> {
		if (hasLink(vorgang, VorgangLinkRel.RECHTSPFLEGER)) {
			return this.userFacade.getUserByUri(vorgang, VorgangLinkRel.RECHTSPFLEGER);
		}
		return of(null);
	}

	navigateToVorgang(vorgang: VorgangResource) {
		this.vorgangFacade.setSelectedVorgang(vorgang);
		this.router.navigate(['/verfahren', btoa(getUrl(vorgang, VorgangLinkRel.VERFAHREN)), {
			outlets: {
				'vorgang': btoa(getUrl(vorgang)),
			},
		}]);
	}

	loadFristenListe() {
		this.facade.loadFristenListe();
	}
}
