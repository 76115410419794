import {Injectable} from '@angular/core';
import {ResourceFactory} from '@ngxp/rest';
import {VerfuegungLinkRel, VerfuegungResource} from '@schir-int-client/verfuegung-shared';
import {Observable} from 'rxjs';
import {AufgabeLinkRel} from './aufgabe.linkrel';
import {Aufgabe, AufgabeListResource, AufgabenTyp, AufgabeResource, AufgabeStatus} from './aufgabe.model';
import {isNil} from 'lodash-es';

@Injectable({ providedIn: 'root' })
export class AufgabeService {
	constructor(private resourceFactory: ResourceFactory) { }

	getAufgaben(verfuegung: VerfuegungResource): Observable<AufgabeListResource> {
		return this.resourceFactory.from(verfuegung).get(VerfuegungLinkRel.AUFGABEN);
	}

	createAufgabe(verfuegung: VerfuegungResource, aufgabe: Aufgabe): Observable<AufgabeResource> {
		return this.resourceFactory.from(verfuegung).post(VerfuegungLinkRel.CREATE_AUFGABE, aufgabe);
	}

	updateAufgabe(aufgabe: AufgabeResource, newAufgabe: Aufgabe): Observable<AufgabeResource> {
		return this.resourceFactory.from(aufgabe).put(AufgabeLinkRel.EDIT, newAufgabe);
	}

	deleteAufgabe(aufgabe: AufgabeResource): Observable<AufgabeListResource> {
		return this.resourceFactory.from(aufgabe).delete(AufgabeLinkRel.DELETE);
	}

	markAsErledigt(aufgabe: AufgabeResource): Observable<AufgabeListResource> {
		return this.resourceFactory.from(aufgabe).patch(AufgabeLinkRel.MARK_AS_ERLEDIGT, { status: AufgabeStatus.ERLEDIGT });
	}

	markAsFreigegeben(aufgabe: AufgabeResource): Observable<AufgabeListResource> {
		return this.resourceFactory.from(aufgabe).patch(AufgabeLinkRel.MARK_AS_FREIGEGEBEN, { status: AufgabeStatus.FREIGEGEBEN });
	}

	static sortAufgabenByTyp(aufgaben: AufgabeResource[]) {
		if (isNil(aufgaben)) {
			return aufgaben;
		}

		let sortedAufgaben: AufgabeResource[] = [...aufgaben];
		sortedAufgaben = sortedAufgaben.sort((aufgabe1, aufgabe2) => this.compare(aufgabe1.typ, aufgabe2.typ));

		return sortedAufgaben;
	}

	private static compare(typ1: AufgabenTyp, typ2: AufgabenTyp): number {
		return aufgabeTypOrder.indexOf(typ1) < aufgabeTypOrder.indexOf(typ2) ? -1 : 1;
	}

}

const aufgabeTypOrder: AufgabenTyp[] = [
	AufgabenTyp.ZERTIFIKAT,
	AufgabenTyp.ALTES_ZERTIFIKAT,
	AufgabenTyp.ZERTIFIKATSAUSZUG,
	AufgabenTyp.ABSCHRIFT_ZERTIFIKATSAUSZUG,
	AufgabenTyp.ALTER_AUSZUG,
	AufgabenTyp.HYPOTHEKENBESCHEINIGUNG,
	AufgabenTyp.EIGENTUEMERBESCHEINIGUNG,
	AufgabenTyp.LOESCHUNGSBESCHEINIGUNG,
	AufgabenTyp.LASTENFREIHEITSBESCHEINIGUNG,
	AufgabenTyp.NAMENSAENDERUNGSBESCHEINIGUNG,
	AufgabenTyp.REGISTERABSCHRIFT,
	AufgabenTyp.EINTRAGUNGSMITTEILUNG,
	AufgabenTyp.MESSBRIEF,
	AufgabenTyp.SONSTIGE,
	AufgabenTyp.RECHNUNGSSTELLUNG,
	AufgabenTyp.KOSTEN,
	AufgabenTyp.WIEDERVORLAGE,
	AufgabenTyp.ABLAGE,
];
