import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
import {VerfahrenActions} from '@schir-int-client/verfahren-shared';
import {AppState} from 'apps/int-client/src/app/shared/store/app-state.model';
import {catchError, map, mergeMap, switchMap, withLatestFrom} from 'rxjs/operators';
import {
	AddChangeEntryAction,
	AddEntryBatchAction,
	AddVermerkEntryAction,
	CreateUmschreibungEntryAction,
	DeleteEntryAction,
	LoadChangeEntries,
	LoadChangeEntriesSuccessAction,
	RegisterBlattChangeActions,
	UpdateChangeEntryAction,
} from '../../../register-change/src/lib/register-change.actions';
import {changeEntryListSelector} from '../../../register-change/src/lib/register-change.selectors';
import {
	CreateBetragsKorrekturAction,
	CreateLaufendeNummerKorrekturAction,
	CreateTeilloeschenAction,
	PartiellRoetenRegisterBlattEntryAction,
	RegisterBlattActions,
	RoetenRegisterBlattEntryAction,
} from './register.actions';
import {RegisterBlattFacade} from './register.facade';
import {RegisterBlattService} from './register.service';
import {of} from 'rxjs';
import {AppNotificationService} from '@schir-int-client/tech';

@Injectable()
export class RegisterBlattEffects {
	verfahrenService: any;

	constructor(
		private actions: Actions,
		private store: Store<AppState>,
		private facade: RegisterBlattFacade,
		private service: RegisterBlattService,
		private notificationService: AppNotificationService,
	) { }

	loadChangeEntries = createEffect(() => this.actions.pipe(
		ofType(RegisterBlattChangeActions.LOAD_CHANGE_ENTRIES),
		switchMap((action) => {
			return this.service.getOne((<LoadChangeEntries>action).verfahren).pipe(
				map(entries => new LoadChangeEntriesSuccessAction(entries)),
			);
		}),
	));

	roetenEntry = createEffect(() => this.actions.pipe(
		ofType(RegisterBlattActions.ROETEN_REGISTERBLATT_ENTRY),
		mergeMap(action => {
			return this.service.createRoetenEntry((<RoetenRegisterBlattEntryAction>action).entry).pipe(
				map(result => new LoadChangeEntriesSuccessAction(result)),
				catchError(err => this.catchDuplicateUsignalError(this.notificationService, err)),
			);
		}),
	));

	deleteEntry = createEffect(() => this.actions.pipe(
		ofType(RegisterBlattChangeActions.DELETE_ENTRY),
		mergeMap(action => this.service.deleteEntry((<DeleteEntryAction>action).entry).pipe(
			map(result => new LoadChangeEntriesSuccessAction(result)),
			catchError(err => this.catchDuplicateUsignalError(this.notificationService, err)),
		)),
	));

	addChangeEntry = createEffect(() => this.actions.pipe(
		ofType(RegisterBlattChangeActions.ADD_CHANGE_ENTRY),
		withLatestFrom(this.store.select(changeEntryListSelector)),
		mergeMap(([action, entryList]) =>
			this.service.addChangeEntry((<AddChangeEntryAction>action).entry, entryList.resource).pipe(
				map(resultList => new LoadChangeEntriesSuccessAction(resultList)),
				catchError(err => this.catchDuplicateUsignalError(this.notificationService, err)),
			),
		),
	));

	addEntryBatch = createEffect(() => this.actions.pipe(
		ofType(RegisterBlattChangeActions.ADD_ENTRY_BATCH),
		withLatestFrom(this.store.select(changeEntryListSelector)),
		mergeMap(([action, entryList]) =>
			this.service.addBatchEntry((<AddEntryBatchAction>action).entries, entryList.resource).pipe(
				map(resultList => new LoadChangeEntriesSuccessAction(resultList)),
				catchError(err => this.catchDuplicateUsignalError(this.notificationService, err)),
			),
		),
	));

	createUmschreibung = createEffect(() => this.actions.pipe(
		ofType(RegisterBlattChangeActions.CREATE_UMSCHREIBUNG_ENTRY),
		mergeMap(action => {
			const createUmschreibungAction = <CreateUmschreibungEntryAction>action;
			return this.service.createUmschreibungEntry(createUmschreibungAction.entryResource, createUmschreibungAction.newEntry).pipe(
				map(resultList => new LoadChangeEntriesSuccessAction(resultList)),
			);
		}),
	));

	addVermerkEntry = createEffect(() => this.actions.pipe(
		ofType(RegisterBlattChangeActions.ADD_VERMERK_ENTRY),
		withLatestFrom(this.store.select(changeEntryListSelector)),
		mergeMap(([action, entryList]) =>
			this.service.addVermerkEntry((<AddVermerkEntryAction>action).entry, entryList.resource).pipe(
				map(resultList => new LoadChangeEntriesSuccessAction(resultList)),
			),
		),
	));

	updateChangeEntry = createEffect(() => this.actions.pipe(
		ofType(RegisterBlattChangeActions.UPDATE_CHANGE_ENTRY),
		mergeMap(action =>
			this.service.updateChangeEntry((<UpdateChangeEntryAction>action).entry).pipe(
				map(resultList => new LoadChangeEntriesSuccessAction(resultList)),
				catchError(err => this.catchDuplicateUsignalError(this.notificationService, err)),
			),
		),
	));

	partiellRoetenEntry = createEffect(() => this.actions.pipe(
		ofType(RegisterBlattActions.PARTIELL_ROETEN_REGISTERBLATT_ENTRY),
		mergeMap(action =>
			this.service.createPartielleRoetungEntry((<PartiellRoetenRegisterBlattEntryAction>action).changeEntry, (<PartiellRoetenRegisterBlattEntryAction>action).roetungsBereich).pipe(
				map(resultList => new LoadChangeEntriesSuccessAction(resultList)),
				catchError(err => this.catchDuplicateUsignalError(this.notificationService, err)),
			),
		),
	));

	teilloeschungEntry = createEffect(() => this.actions.pipe(
		ofType(RegisterBlattActions.CREATE_TEILLOESCHEN_REGISTERBLATT_ENTRY),
		mergeMap(action =>
			this.service.createTeilloeschung((<CreateTeilloeschenAction>action).changeEntry, (<CreateTeilloeschenAction>action).teilloeschenWert).pipe(
				map(resultList => new LoadChangeEntriesSuccessAction(resultList)),
			),
		),
	));

	createLaufendeNummerKorrektur = createEffect(() => this.actions.pipe(
		ofType(RegisterBlattActions.CREATE_LAUFENDE_NUMMER_KORREKTUR_ENTRY),
		mergeMap(action =>
			this.service.createLaufendeNummerKorrektur((<CreateLaufendeNummerKorrekturAction>action).changeEntry, (<CreateLaufendeNummerKorrekturAction>action).laufendeNummerKorrekturWert).pipe(
				map(resultList => new LoadChangeEntriesSuccessAction(resultList)),
			),
		),
	));

	createBetragsKorrektur = createEffect(() => this.actions.pipe(
		ofType(RegisterBlattActions.CREATE_BETRAGS_KORREKTUR_ENTRY),
		mergeMap(action =>
			this.service.createBetragsKorrektur((<CreateBetragsKorrekturAction>action).changeEntry, (<CreateBetragsKorrekturAction>action).betragsKorrekturWert).pipe(
				map(resultList => new LoadChangeEntriesSuccessAction(resultList)),
			),
		),
	));

	private catchDuplicateUsignalError(notificationService, error) {
		if (error['issues'][0]['messageCode'] == 'usignal.already.assigned') {
			const message = error['issues'][0]['message'];
			notificationService.handleError(message ? message : 'Das Unterscheidungssignal dieses Verfahrens ist doppelt vergeben.\n' +
				' Eine Korrektur des Unterscheidungssignals ist notwendig, bevor weiter Änderungen am Verfahren erfolgen können.');
		}
		return of({
				type: VerfahrenActions.REPORT_DUPLICATE_USIGNAL,
				payload: error,
			},
		);
	}
}
