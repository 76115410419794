import {Injectable} from '@angular/core';
import {getUrl, Resource, ResourceFactory} from '@ngxp/rest';
import {ApiRootLinkRel} from '@schir-int-client/api-root';
import {Observable} from 'rxjs';
import {AdminRootResource, UnterscheidungssignalListResource, UnterscheidungssignalResource} from './admin.model';
import {AdminRootLinkRel, UnterscheidungssignalLinkRel} from './admin.linkrel';

import {HttpClient} from '@angular/common/http';
import {switchMap} from 'rxjs/operators';


@Injectable({ providedIn: 'root' })
export class AdminService {
	constructor(private resourceFactory: ResourceFactory, private httpClient: HttpClient) { }

	public getAdminRoot(resource: Resource): Observable<AdminRootResource> {
		return this.resourceFactory.from(resource).get(ApiRootLinkRel.ADMIN);
	}

	public getUnterscheidungssignale(adminRoot: AdminRootResource): Observable<UnterscheidungssignalListResource> {
		return this.resourceFactory.from(adminRoot).get(AdminRootLinkRel.UNTERSCHEIDUNGSSIGNALE);
	}

	public uploadUnterscheidungssignale(adminRoot: AdminRootResource, file: File): Observable<UnterscheidungssignalListResource> {
		const url: string = getUrl(adminRoot, AdminRootLinkRel.UPLOAD);
		const formData: FormData = new FormData();

		formData.append('file', <Blob>file, file.name);

		return this.httpClient.post(url, formData, { observe: 'response' }).pipe(
			switchMap(resp => {
				const location = resp.headers.get('Location');
				return this.httpClient.get<UnterscheidungssignalListResource>(location);
			}),
		);

	}

	delete(usignal: UnterscheidungssignalResource): Observable<UnterscheidungssignalListResource> {
		return this.resourceFactory.from(usignal).delete(UnterscheidungssignalLinkRel.DELETE);
	}
}
