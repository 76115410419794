import {Pipe, PipeTransform} from '@angular/core';
import {getUrl, Resource} from '@ngxp/rest';
import {isNil} from 'lodash-es';

@Pipe({ name: 'toResourceUri' })
export class ToResourceUriPipe implements PipeTransform {
	transform(value: Resource): string {
		if (isNil(value)) {
			return null;
		}
		return btoa(getUrl(value)).replace('/', '_');
	}
}
