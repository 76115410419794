import {Injectable} from '@angular/core';
import {Aufgabe, AufgabeFacade, AufgabenTyp} from '@schir-int-client/aufgabe-shared';
import {VerfuegungFacade} from '@schir-int-client/verfuegung-shared';
import {CreateAufgabeFormService} from '@schir-int-client/aufgabe';
import {filter, first} from 'rxjs/operators';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';

@Injectable()
export class CreateKorrespondenzVerfuegungFormService {

	readonly anschreiben = new UntypedFormControl();
	readonly controlVerfuegungNotiz = new UntypedFormControl();

	form: UntypedFormGroup = new UntypedFormGroup({});

	constructor(private verfuegungFacade: VerfuegungFacade,
	            private aufgabeFacade: AufgabeFacade,
	            private aufgabeFormService: CreateAufgabeFormService) {
		this.aufgabeFormService.enableAufgabenTyp(AufgabenTyp.ANSCHREIBEN);

		this.form.addControl('anschreiben', this.aufgabeFormService.form);
		this.form.addControl('verfuegungNotiz', this.controlVerfuegungNotiz);
	}

	submit(): boolean {
		if (this.checkPreconditions()) {
			const aufgabe: Aufgabe = this.aufgabeFormService.buildAufgabeByTyp(AufgabenTyp.ANSCHREIBEN);

			this.verfuegungFacade.createKorrespondenzVerfuegungByVorgang(this.controlVerfuegungNotiz.value).pipe(
				filter(created => !created.loading),
				first(),
			).subscribe(() => {
				this.aufgabeFacade.createAufgabe(aufgabe);
			});

			return true;
		}

		return false;
	}

	checkPreconditions(): boolean {
		return this.aufgabeFormService.isValid();
	}

}
