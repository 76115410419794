<div class="navbar">
	   <button class="c-button c-button--rounded focus-box-shadow-dark" [matMenuTriggerFor]="menu" data-test-id="settings-button" [matTooltip]="toolTipVisible ? 'Einstellungen' : null"
	           aria-haspopup="menu" aria-labelledby="bt-settings">
		   <i class="material-icons" aria-hidden="true">settings</i>
		   <span id="bt-settings">Einstellungen</span>
	   </button>
	<mat-menu #menu="matMenu" class="menu">
		<button class="menu-item centered-content focus-box-shadow-dark" mat-menu-item data-test-id="profile-button" (click)="openProfileinstellungen()" aria-labelledby="bt-profil-settings">
			<i class="material-icons" aria-hidden="true">account_circle</i>
			<span id="bt-profil-settings">Profileinstellungen</span>
		</button>
		<button class="menu-item centered-content focus-box-shadow-dark" mat-menu-item data-test-id="gericht-profile-button" *ifResource="apiRoot;hasLink:apiRootLinkRel.ADMIN" (click)="openGerichtseinstellungen()" aria-labelledby="bt-gericht-settings">
			<i class="material-icons" aria-hidden="true">account_balance</i>
			<span id="bt-gericht-settings">Gerichtseinstellungen</span>
		</button>
		<button *ngIf="apiRoot.showLogoutButton" class="menu-item centered-content focus-box-shadow-dark" mat-menu-item data-test-id="logout-button" (click)="logOut()" aria-labelledby="bt-abmelden">
			<i class="material-icons" aria-hidden="true">exit_to_app</i>
			<span id="bt-abmelden">Abmelden</span>
		</button>
	</mat-menu>
</div>
