import {Inject, Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {EipStorage} from '../../../eip-shared/src/lib/eip.storage';
import {ENVIRONMENT_CONFIG} from '@schir-int-client/environment';
import {map, switchMap, tap} from 'rxjs/operators';
import {LOCATION_TOKEN} from '../../../tech/src/lib/location.token';
import {
	LoadUserFromResourceAction,
	LoadUserSuccessAction,
	UserActions,
	UserLoadedAction,
	UserLoggedOutAction,
} from './user.actions';
import {UserService} from './user.service';

@Injectable()
export class UserEffects {
	private redirected: boolean = false;

	constructor(
		private actions: Actions,
		private userService: UserService,
		private eipStorage: EipStorage,
		@Inject(ENVIRONMENT_CONFIG) private envConfig,
		@Inject(LOCATION_TOKEN) private location,
	) {}


	loadUser = createEffect(() => this.actions.pipe(
		ofType(UserActions.LOGGED_IN),
		switchMap(() => {
			return this.userService.getUser().pipe(
				map(user => new UserLoadedAction(user)),
			);
		}),
	));


	logout = createEffect(() => this.actions.pipe(
		ofType(UserActions.LOGOUT),
		switchMap(() => {
			this.eipStorage.clear();
			return this.userService.logout().pipe(
				tap(() => {
					this.redirect(this.envConfig.loginUrl);
				}),
				map(() => new UserLoggedOutAction()),
			);
		}),
	));


	unauthorized = createEffect(() => this.actions.pipe(
		ofType(UserActions.IS_UNAUTHORIZED),
		tap(() => {
			this.redirect(this.envConfig.loginUrl);
		}),
	), { dispatch: false });


	loadUserByResource = createEffect(() => this.actions.pipe(
		ofType(UserActions.LOAD_USER_FROM_RESOURCE),
		switchMap(action => {
			const loadAction = <LoadUserFromResourceAction>action;
			return this.userService.loadUserByResource(loadAction.resource, loadAction.linkRel).pipe(
				map(result => new LoadUserSuccessAction(result)),
			);
		}),
	));

	redirect(url: string) {
		if (!this.redirected) {
			this.location.href = url;
			this.redirected = true;
		}
	}

}
