import {Injectable} from '@angular/core';
import {getUrl, ResourceFactory} from '@ngxp/rest';
import {ApiRootLinkRel, ApiRootResource} from '@schir-int-client/api-root';
import {Observable} from 'rxjs';
import {MonthlyStatistik, StatistikResource, ZaehlblattStatistikResource} from './statistik.model';
import {AdminRootLinkRel, AdminRootResource} from '@schir-int-client/admin-shared';

@Injectable({ providedIn: 'root' })
export class StatistikService {
	constructor(private resourceFactory: ResourceFactory) { }

	public getStatistik(resource: ApiRootResource): Observable<StatistikResource> {
		return this.resourceFactory.from(resource).get(ApiRootLinkRel.STATISTIK);
	}

	public loadMonthlyStatistik(resource: AdminRootResource): Observable<MonthlyStatistik> {
		return this.resourceFactory.from(resource).get(AdminRootLinkRel.REL_STATISTIK_AGG);
	}

	public loadZaehlblattStatistik(resource: AdminRootResource): Observable<ZaehlblattStatistikResource> {
		let url = getUrl(resource, AdminRootLinkRel.REL_ZAEHLBLATT_STATISTIK);
		// An dieser Stelle, wenn gewünscht, Parameter für Anzahl der Monate einsetzen!
		return this.resourceFactory.fromId(url).get();

	}
}
