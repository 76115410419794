import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {ActionType, ApiRootLoadedAction} from '@schir-int-client/api-root';
import {DoNothingAction} from '@schir-int-client/ngrx-helpers';
import {of} from 'rxjs';
import {switchMap} from 'rxjs/operators';
import {EipRegistration} from './eip.registration';


@Injectable()
export class EipEffects {
	constructor(private actions: Actions, private registration: EipRegistration) { }


	register = createEffect(() => this.actions.pipe(
		ofType(ActionType.ApiRootLoaded),
		switchMap(action => {
			if (this.registration.isConfigured() && !this.registration.isStarted()) {
				console.log('Connect to eIP...');
				this.registration.start((<ApiRootLoadedAction>action).payload.eipConfig);
			} else {
				console.log('No EIP-Parameters -> not connecting to EIP');
			}
			return of(new DoNothingAction());
		}),
	));
}
