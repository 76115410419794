import {Component, EventEmitter, Input, Output} from '@angular/core';
import {StateResource} from '@schir-int-client/ngrx-helpers';
import {KorrespondenzVorlageLinkRel, KorrespondenzVorlageResource} from '@schir-int-client/korrespondenz-shared';

@Component({
	selector: 'schir-int-client-korrespondenz-vorlage-buttons',
	templateUrl: './korrespondenz-vorlage-buttons.component.html',
	styleUrls: ['./korrespondenz-vorlage-buttons.component.scss'],
})
export class KorrespondenzVorlageButtonsComponent {

	@Input() korrespondenzVorlage: StateResource<KorrespondenzVorlageResource>;

	@Output() upload: EventEmitter<File> = new EventEmitter<File>();
	@Output() delete: EventEmitter<void> = new EventEmitter<void>();

	readonly korrespondenzVorlageLinkRel = KorrespondenzVorlageLinkRel;

	constructor() { }


}
