import {Resource} from '@ngxp/rest';

export interface User {
	firstName: string,
	lastName: string,
	roles: UserRole[]
}

export interface UserResource extends User, Resource {}

export interface UserListResource extends Resource {}

export enum UserRole {
	ADMIN = 'ADMINISTRATOR',
	RECHTSPFLEGER = 'RECHTSPFLEGER',
	GESCHAEFTSSTELLE = 'GESCHAEFTSSTELLE',
	DIENSTAUFSICHT = 'DIENSTAUFSICHT'
}
