import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import {ResourceUri} from '@ngxp/rest';
import {isNil} from 'lodash-es';
import * as moment from 'moment';
import {combineLatest, Observable, of} from 'rxjs';
import {map} from 'rxjs/operators';
import {updateTextbaustein} from './textbaustein.actions';
import {TextbausteinResource} from './textbaustein.model';
import {TextbausteinParser} from './textbaustein.parser';
import {selectTextbausteinById} from './textbaustein.selectors';
import {TextbausteinState} from './textbaustein.state';

@Injectable({ providedIn: 'root' })
export class TextbausteinFacade {

	constructor(private store: Store<TextbausteinState>, private parser: TextbausteinParser) {}

	getPreviewText(
		textbausteinId: ResourceUri,
		platzhalterWerte: { [fieldName: string]: string | number | moment.Moment | Date | Object[]; },
		vormerkungPrefix: string,
	): Observable<string> {
		return combineLatest([this.getTextbausteinById(textbausteinId), this.getSubTexte(platzhalterWerte)]).pipe(
			map(([textbaustein, subTexte]) => {
				if (vormerkungPrefix) {
					let preview = this.parser.parse(textbaustein, platzhalterWerte, subTexte);
					preview = preview.split('Registerpfandrecht ').join('');
					preview = preview.split('Gesamtregisterpfandrecht ').join('');
					return vormerkungPrefix + ' ' + preview;
				}
				return this.parser.parse(textbaustein, platzhalterWerte, subTexte);
			}),
		);
	}

	getSubTexte(platzhalterWerte: { [fieldName: string]: any; }): Observable<{ [subField: string]: string }> {
		return combineLatest([
			this.fillVollstreckung(platzhalterWerte),
			this.fillGesamthaft(platzhalterWerte),
			this.fillZinsen(platzhalterWerte),
			this.fillRangvermerk(platzhalterWerte),
			this.fillMigration(platzhalterWerte),
			this.fillVawVerfuegung(platzhalterWerte),
			this.fillBewilligungVerfuegung(platzhalterWerte),
			this.fillEinschraenkungEigentuemer(platzhalterWerte),
			this.fillOlg(platzhalterWerte),
			this.fillVormerkung(platzhalterWerte),
			this.fillVerzinsung(platzhalterWerte),
			this.fillRangvorbehalt(platzhalterWerte),
			this.fillRangeintrag(platzhalterWerte),
			this.fillVorrangsvorbehalt(platzhalterWerte)],
		).pipe(
			map(([vollstreckung, gesamthaft, zinsen, rangvermerk, migration, vawVerfuegung, bewilligungVerfuegung, einschraenkungEigetuemer, olg, vormerkung, verzinsung, rangvorbehalt, rangeintrag, vorrangsvorbehalt]) => {
				return {
					VOLLSTRECKUNG: vollstreckung,
					GESAMTHAFT: gesamthaft,
					ZINSEN: zinsen,
					RANGVERMERK: rangvermerk,
					MIGRATION: migration,
					VAW_VERFUEGUNG: vawVerfuegung,
					BEWILLIGUNG_VERFUEGUNG: bewilligungVerfuegung,
					EINSCHRAENKUNG_EIGENTUEMER: einschraenkungEigetuemer,
					OLG: olg,
					VORMERKUNG: vormerkung,
					VERZINSUNG: verzinsung,
					RANGVORBEHALT: rangvorbehalt,
					RANGEINTRAG: rangeintrag,
					VORRANGSVORBEHALT: vorrangsvorbehalt,
				};
			}),
		);
	}

	private fillVollstreckung(platzhalterWerte: any): Observable<string> {
		return this.fill('vollstreckung', platzhalterWerte);
	}

	private fillGesamthaft(platzhalterWerte: any): Observable<string> {
		return this.fill('gesamthaft', platzhalterWerte);
	}

	private fillZinsen(platzhalterWerte: any): Observable<string> {
		return this.fill('zinsen', platzhalterWerte);
	}

	private fillRangvermerk(platzhalterWerte: any): Observable<string> {
		return this.fill('rangvermerk', platzhalterWerte);
	}

	private fillMigration(platzhalterWerte: any): Observable<string> {
		return this.fill('migration', platzhalterWerte);
	}

	private fillVawVerfuegung(platzhalterWerte: any): Observable<string> {
		return this.fill('vawVerfuegung', platzhalterWerte);
	}

	private fillBewilligungVerfuegung(platzhalterWerte: any): Observable<string> {
		return this.fill('bewilligungVerfuegung', platzhalterWerte);
	}

	private fillEinschraenkungEigentuemer(platzhalterWerte: any): Observable<string> {
		return this.fill('einschraenkungEigentuemer', platzhalterWerte);
	}

	private fillOlg(platzhalterWerte: any): Observable<string> {
		return this.fill('olg', platzhalterWerte);
	}

	private fillVormerkung(platzhalterWerte: any): Observable<string> {
		return this.fill('vormerkung', platzhalterWerte);
	}

	private fillVerzinsung(platzhalterWerte: any): Observable<string> {
		return this.fill('verzinsung', platzhalterWerte);
	}

	private fillRangvorbehalt(platzhalterWerte: any): Observable<string> {
		return this.fill('rangvorbehalt', platzhalterWerte);
	}

	private fillRangeintrag(platzhalterWerte: any): Observable<string> {
		return this.fill('rangeintrag', platzhalterWerte);
	}

	private fillVorrangsvorbehalt(platzhalterWerte: any): Observable<string> {
		return this.fill('vorrangsvorbehalt', platzhalterWerte);
	}

	private fill(field: string, platzhalterWerte: any): Observable<string> {
		if (!isNil(platzhalterWerte[field])) {
			return this.fillTextbausteinById(platzhalterWerte[field]['textbausteinId'], platzhalterWerte[field]['platzhalterWerte']);
		}
		return of(null);
	}

	private fillTextbausteinById(textbausteinId: ResourceUri, platzhalterWerte: any): Observable<string> {
		return this.getTextbausteinById(textbausteinId).pipe(
			map(textbaustein => isNil(textbaustein) ? '' : this.parser.parse(textbaustein, platzhalterWerte)),
		);
	}

	getTextbausteinById(textbausteinId: ResourceUri): Observable<TextbausteinResource> {
		return this.store.select(selectTextbausteinById(textbausteinId));
	}

	updateTextbaustein(textbausteinId: ResourceUri, editedText: string): void {
		if (!isNil(textbausteinId)) {
			this.store.dispatch(updateTextbaustein({ textbausteinId, editedText }));
		}
	}
}
