import {formatNumber} from '@angular/common';
import {hasLink, ResourceUri} from '@ngxp/rest';
import {isEmpty, isEqual, isNil, isNull} from 'lodash-es';
import {FachlicheSpalte, SPALTEN_NAMEN_LABELS_SSR} from '..';
import {MultiSpaltenFeld, PhysischeSpalte, SpaltenName} from './register.model';
import {SpaltenFeld} from '@schir-int-client/tech';
import {
	ChangeEntry,
	ChangeEntryBetragsKorrekturResource,
	ChangeEntryLaufendeNummerKorrekturResource,
	ChangeEntryLinkRel,
	ChangeEntryResource,
	ChangeEntryTeilloeschenResource,
	ChangePartiellRoetenEntryResource,
	ChangeRoetenEntryResource,
} from '@schir-int-client/register-change';

export function getEintragungen(entries: ChangeEntryResource[]) {
	return isNil(entries) ? [] : entries
		.filter(entry => !isRoetenEntry(entry) && !isPartiellRoetenEntry(entry))
		.sort((e1, e2) => e1.positionInSpalte - e2.positionInSpalte);
}

export function hasEditableEntries(entries: any[]): boolean {
	if (isEmpty(entries)) {
		return false;
	}
	return !isNil(entries.filter(entry => !isVermerk(entry)).find(entry => isEditable(entry)));
}

export function isEditable(entry: ChangeEntryResource) {
	if (isNil(entry)) {
		return false;
	} else {
		return hasLink(entry, ChangeEntryLinkRel.EDIT);
	}
}

export function isTeilloeschbar(entry: ChangeEntryResource) {
	if (isNil(entry)) {
		return false;
	} else {
		return hasLink(entry, ChangeEntryLinkRel.TEILLOESCHEN);
	}
}

export function isGeroetet(entry: ChangeEntryResource): boolean {
	if (isNil(entry)) {
		return false;
	} else {
		return hasLink(entry, ChangeEntryLinkRel.ROETUNGS_ENTRY);
	}
}

export function isPartiellGeroetet(entry: ChangeEntryResource): boolean {
	if (isNil(entry)) {
		return false;
	} else {
		return hasLink(entry, ChangeEntryLinkRel.PARTIELL_ROETUNGS_ENTRY);
	}
}

export function isRoetbar(entry: ChangeEntryResource): boolean {
	if (isNil(entry)) {
		return false;
	} else {
		return hasLink(entry, ChangeEntryLinkRel.ROETEN);
	}
}

export function isPartiellRoetbar(entry: ChangeEntryResource): boolean {
	if (isNil(entry)) {
		return false;
	} else {
		return hasLink(entry, ChangeEntryLinkRel.PARTIELL_ROETEN);
	}
}

export function isEntroetbar(entry: ChangeEntryResource, roetung: ChangeEntryResource = null): boolean {
	if (isNil(entry)) {
		return false;
	}
	return hasLink(entry, ChangeEntryLinkRel.ROETUNGS_ENTRY)
		&& !isNull(roetung)
		&& hasLink(roetung, ChangeEntryLinkRel.DELETE);
}

export function isDeletable(entry: ChangeEntryResource) {
	if (isNil(entry)) {
		return false;
	} else {
		return hasLink(entry, ChangeEntryLinkRel.DELETE);
	}
}

export function getEntryClass(entry: ChangeEntryResource, roetung: ChangeEntryResource = null, position: number = null): string {
	if (isTemporaryEntry(entry)) {
		return 'c-entry--temporary';
	}
	if (isColumnOneAndTwoRoetbar(entry, position)) {
		if (isEntroetbar(entry, roetung)) {
			return 'c-entry--temporary-geroetet';
		}
		if (isGeroetet(entry)) {
			return 'c-entry--geroetet';
		}
	}
	return '';
}

export function getEntryClassRegardingAllChanges(entry: ChangeEntryResource, changingEntries: ChangeEntryResource[]): string {
	if (changingEntries.length > 0) {
		return changingEntries.every(e => isTemporaryEntry(e)) ? 'c-entry--temporary-geroetet' : 'c-entry--geroetet';
	}
	return isTemporaryEntry(entry) ? 'c-entry--temporary' : '';
}

export function getEntryAriaLabel(entry: ChangeEntryResource, roetung: ChangeEntryResource = null, position: number = null): string {

	let spaltenLabel = entry.spaltenName;
	if (SPALTEN_NAMEN_LABELS_SSR[spaltenLabel]) {
		spaltenLabel = SPALTEN_NAMEN_LABELS_SSR[spaltenLabel];
	}

	switch (getEntryClass(entry, roetung, position)) {
		case 'c-entry--temporary':
			return spaltenLabel + ', temporär';
		case 'c-entry--temporary-geroetet':
			return spaltenLabel + ', temporär gerötet';
		case 'c-entry--geroetet':
			return spaltenLabel + ', gerötet';
		default :
			return spaltenLabel + ', signiert';
	}
}

function isColumnOneAndTwoRoetbar(entry, position) {
	return !isVormerkung(entry) || position == 2;
}

export function isVermerk(entry: ChangeEntryResource): boolean {
	return ('spaltenWert' in entry) && (typeof entry.spaltenWert === 'object') && (!isNull(entry.spaltenWert)) && ('vermerk' in entry.spaltenWert);
}

export function isVormerkung(entry: ChangeEntryResource): boolean {
	return entry && entry.spaltenWert && entry.spaltenWert.vormerkung;
}

export function getMultiColumnClass(index: number, totalColumns: number) {
	return index + 1 === totalColumns ? 'column-content column--right' : index === 0 ? 'column-content column--left' : 'column-content column--center';
}

export function getRoetenEntry(entries: ChangeEntryResource[], geroetetEntryId: ResourceUri): ChangeRoetenEntryResource {
	return <ChangeRoetenEntryResource>entries.filter(entry => isRoetenEntry(entry)).find(entry => (<ChangeRoetenEntryResource>entry).spaltenWert.idGeroeteterEintrag === geroetetEntryId);
}

export function isRoetenEntry(entry: ChangeEntryResource): boolean {
	if ('spaltenWert' in entry) {
		return typeof entry.spaltenWert == 'object' && entry.spaltenWert.hasOwnProperty('idGeroeteterEintrag') && !entry.spaltenWert.hasOwnProperty('geroeteterBereich');
	}
	return false;
}

export function getPartiellRoetenEntry(entries: ChangeEntryResource[], geroetetEntryId: ResourceUri): ChangePartiellRoetenEntryResource {
	return <ChangePartiellRoetenEntryResource>entries.filter(entry => isPartiellRoetenEntry(entry)).find(entry => (<ChangePartiellRoetenEntryResource>entry).spaltenWert.idGeroeteterEintrag === geroetetEntryId);
}

export function getPartiellRoetenEntrys(entries: ChangeEntryResource[], geroetetEntryId: ResourceUri): ChangeRoetenEntryResource[] {
	return entries.filter(entry => isPartiellRoetenEntry(entry)).filter(entry => (<ChangePartiellRoetenEntryResource>entry).spaltenWert.idGeroeteterEintrag === geroetetEntryId);
}

export function isPartiellRoetenEntry(entry: ChangeEntryResource): boolean {
	if ('spaltenWert' in entry) {
		return typeof entry.spaltenWert == 'object' && entry.spaltenWert.hasOwnProperty('geroeteterBereich');
	}
	return false;
}

export function getRelatedEntryClass(relatedEntry: ChangeEntryTeilloeschenResource | ChangeEntryBetragsKorrekturResource | ChangeEntryLaufendeNummerKorrekturResource) {
	if (isNil(relatedEntry)) {
		return '';
	}
	if (isTemporaryEntry(relatedEntry)) {
		return 'c-entry--temporary-geroetet';
	} else {
		return 'c-entry--geroetet';
	}
}

export function isTemporaryEntry(entry: ChangeEntryResource): boolean {
	return !hasLink(entry, ChangeEntryLinkRel.SIGNATURE);
}

export function isTemporaryRoetenEntry(entry: ChangeEntryResource): boolean {
	return isRoetenEntry(entry) && isTemporaryEntry(entry);
}

export function getFieldValue(entry: ChangeEntryResource, fieldName: string, physicalColumn?: PhysischeSpalte) {
	const entryValue = getValue(entry);
	if (isVermerk(entry) && isVermerkSpalte(physicalColumn)) {
		return entryValue.vermerk;
	}
	if (!isNil(physicalColumn) && physicalColumn.formatter) {
		return physicalColumn.formatter(entry);
	}
	return entryValue[fieldName];
}


export function isVermerkSpalte(physicalColumn): boolean {
	return physicalColumn && physicalColumn.isVermerkSpalte;
}

export function getPlaceholderValue(entry: ChangeEntryResource, placeholderName: string) {
	const placeholders = getFieldValue(entry, 'platzhalterWerte');
	return isNil(placeholders) ? null : placeholders[placeholderName];
}

export function getValue(entry: ChangeEntryResource): any {
	if (isNil(entry)) {
		return null;
	}
	if ('spaltenWert' in entry) {
		return entry.spaltenWert;
	} else {
		return null;
	}
}

export function getPrefix(entry: ChangeEntryResource): string {
	if (isNil(entry)) {
		return '';
	} else {
		return '';
	}	//TODO prefix for changeEntryColumns;
}

export function nilToEmptyString(str: String) {
	return (isNil(str)) ? '' : str;
}

export function getFieldByName(spalte: FachlicheSpalte, fieldName: string) {
	return (spalte.felder).filter((feld: SpaltenFeld) => isEqual(feld.name, fieldName))[0];
}

export function getFieldBySpaltenName(spalte: FachlicheSpalte, spaltenName: SpaltenName) {
	return (<any>spalte.felder).filter((feld: MultiSpaltenFeld) => isEqual(feld.spaltenName, spaltenName))[0];
}

export function getAddButtonSpalten(spalte: FachlicheSpalte, entries: ChangeEntryResource[]): SpaltenName[] {
	return spalte.spaltenNamen.filter(spaltenName => !hasEditableEntries(getEntriesBySpaltenName(entries, spaltenName)));
}

export function getEntriesBySpaltenName(entries: ChangeEntryResource[], spaltenName: SpaltenName): ChangeEntryResource[] {
	if (isNil(entries)) {
		return [];
	}
	return entries.filter(entry => isEqual(entry.spaltenName, spaltenName));
}

export function formatPotentialNaturalNumberLocale(value: number) {
	return isNaturalNumber(value) ? formatNumberLocale(value, '1.0-5') : formatNumberLocale(value);
}

export function isNaturalNumber(value: number) {
	return value % 1 == 0;
}

export function formatNumberLocale(value: number, digitsInfo?: string) {
	if (isNaN(value)) {
		return '';
	}

	digitsInfo = isNil(digitsInfo) ? '1.2-5' : digitsInfo;
	return formatNumber(value, 'de-DE', digitsInfo);
}

export function buildDefaultFormValue(spaltenName: SpaltenName): DefaultFormValueBuilder {
	return new DefaultFormValueBuilder(spaltenName);
}

export class DefaultFormValueBuilder {

	private freigabeText: string = null;
	private spaltenWert: Object = null;

	constructor(private spaltenName: SpaltenName) { }

	withFreigabeText(freigabeText: string): DefaultFormValueBuilder {
		this.freigabeText = freigabeText;
		return this;
	}

	withSpaltenWert(spaltenWert: Object): DefaultFormValueBuilder {
		this.spaltenWert = spaltenWert;
		return this;
	}

	build(): ChangeEntry {
		return {
			spaltenName: this.spaltenName,
			positionInSpalte: 0,
			freigabeText: this.freigabeText,
			spaltenWert: this.spaltenWert,
		};
	}
}
