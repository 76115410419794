import {Action} from '@ngrx/store';
import {GerichtProfileResource} from './gerichtprofile.model';
import {
	GerichtProfileActions,
	LoadGerichtProfileSuccessAction,
	UpdateGerichtProfileSuccessAction,
} from './gerichtprofile.actions';

export interface GerichtProfileState {
	gerichtProfile: GerichtProfileResource;
}

export const initialState: GerichtProfileState = {
	gerichtProfile: undefined,
};

export function gerichtProfileReducer(state: GerichtProfileState = initialState, action: Action): GerichtProfileState {
	switch (action.type) {
		case GerichtProfileActions.LOAD_GERICHT_PROFILE_SUCCESS:
			return {
				...state,
				gerichtProfile: (<LoadGerichtProfileSuccessAction>action).gerichtProfile,
			};
		case GerichtProfileActions.UPDATE_GERICHT_PROFILE_SUCCESS:
			return {
				...state,
				gerichtProfile: (<UpdateGerichtProfileSuccessAction>action).gerichtProfile,
			};
		default:
			return state;
	}
}
