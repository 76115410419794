import {Pipe, PipeTransform} from '@angular/core';

@Pipe({ name: 'sanitizeHtml' })
export class SanitizeHtmlPipe implements PipeTransform {
	transform(value: string) {
		return typeof value === 'string'
			? value.replaceAll(/<(?![ 0-9])/g, '&lt;').replaceAll(/&(?!(nbsp;|lt;|amp;))/g, '&amp;')
			: value;
	}
}
