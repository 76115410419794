import {Resource} from '@ngxp/rest';
import {AufgabeResource} from '@schir-int-client/aufgabe-shared';

export const allChannelsFalse = {
	channelBrief: false,
	channelFax: false,
	channelEmail: false,
	channelAbholung: false,
};

export interface Verfuegung {
	createdAt: Date;
	aufgaben: AufgabeResource[];
	notiz: string;
	vorgangId: string;
	status: VerfuegungStatus;
	typ: VerfuegungTyp;
}

export enum VerfuegungTyp {
	STANDARD = 'STANDARD',
	KORRESPONDENZ = 'KORRESPONDENZ'
}

export interface VerfuegungListResource extends Resource {}

export interface VerfuegungResource extends Verfuegung, Resource {}

export enum VerfuegungStatus {
	OFFEN = 'Offen',
	ERLEDIGT = 'Erledigt'
}
