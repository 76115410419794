import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
import {AppState, getApiRoot} from '@schir-int-client/api-root';
import {BinaryActions, ChangeFileAction, ChangeFileSuccessAction, FileType} from '@schir-int-client/binary-shared';
import {DoNothingAction} from '@schir-int-client/ngrx-helpers';
import {
	AufgabeActions,
	AufgabeResource,
	LoadVorlageAction,
	VorlageLoadedAction,
} from '@schir-int-client/aufgabe-shared';
import {isEqual} from 'lodash-es';
import {of} from 'rxjs';
import {map, mergeMap, switchMap, withLatestFrom} from 'rxjs/operators';
import {
	DeleteVorlageAction,
	LoadVorlagenAction,
	SaveVorlageAction,
	VorlageActions,
	VorlagenLoadedAction,
} from './vorlage.actions';
import {VorlageLinkRel} from './vorlage.linkrel';
import {VorlageService} from './vorlage.service';

@Injectable()
export class VorlageEffects {
	constructor(
		private actions: Actions,
		private vorlageService: VorlageService,
		private store: Store<AppState>,
	) { }


	loadVorlagen = createEffect(() => this.actions.pipe(
		ofType(VorlageActions.LOAD_VORLAGEN),
		withLatestFrom(this.store.select(getApiRoot())),
		switchMap(([, apiRoot]) => {
			return this.vorlageService.getAll(apiRoot).pipe(
				map(vorlagen => new VorlagenLoadedAction(vorlagen)),
			);
		}),
	));

	saveVorlage = createEffect(() => this.actions.pipe(
		ofType(VorlageActions.SAVE_VORLAGE),
		withLatestFrom(this.store.select(getApiRoot())),
		switchMap(([action, apiRoot]) => {
			const createVorlage = (<SaveVorlageAction>action).vorlage;
			return this.vorlageService.saveOne(apiRoot, createVorlage).pipe(
				map(vorlage => new ChangeFileAction(vorlage, VorlageLinkRel.UPLOAD_FILE, FileType.VORLAGE, createVorlage.file)),
			);
		}),
	));


	deleteVorlage = createEffect(() => this.actions.pipe(
		ofType(VorlageActions.DELETE_VORLAGE),
		switchMap(action => {
			return this.vorlageService.deleteOne((<DeleteVorlageAction>action).vorlage).pipe(
				map(() => new LoadVorlagenAction()),
			);
		}),
	));


	loadVorlageInAufgage = createEffect(() => this.actions.pipe(
		ofType(AufgabeActions.LOAD_VORLAGE),
		mergeMap(action => {
			const aufgabe: AufgabeResource = (<LoadVorlageAction>action).aufgabe;
			return this.vorlageService.getByAufgabe(aufgabe).pipe(
				map(vorlage => new VorlageLoadedAction(vorlage)),
			);
		}),
	));


	changeFileSuccess = createEffect(() => this.actions.pipe(
		ofType(BinaryActions.CHANGE_FILE_SUCCESS),
		switchMap(action => {
			const fileType = (<ChangeFileSuccessAction>action).fileType;
			return isEqual(fileType, FileType.VORLAGE) ? of(new LoadVorlagenAction()) : of(new DoNothingAction());
		}),
	));
}
