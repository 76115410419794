<div class="x-section__headline" data-test-id="headline">
	<h2 class="mat-h1" tabindex="0" aria-labelledby="sr-label-aufg">{{ verfuegung.createdAt | formatDate }}</h2>
</div>

<div class="x-section__subline">
	<i class="material-icons" aria-hidden="true">playlist_add_check</i>
	<h3 class="mat-body margin-right-auto">Aufgaben</h3>
	<schir-int-client-button-round *ngIf="(verfuegung | hasResourceLink: 'createAufgabe') && !isKorrespondenz(verfuegung)" toolTip="Aufgabe zu Verfügung anlegen"
		(click)="openAufgabeDialog()" data-test-id="create-aufgabe" materialIcon="add"
								   cssClass="focus-box-shadow-light smaller-button">
	</schir-int-client-button-round>
</div>

<div class="x-section__main">

	<schir-int-client-spinner [stateResource]="aufgaben | async"></schir-int-client-spinner>

	<ul class="c-list" *ngIf="(aufgaben | async) as _aufgaben">
		<schir-int-client-aufgabe-in-verfuegung *ngFor="let aufgabe of sort(_aufgaben.resource)"
			(openKorrespondenz)="openKorrespondenz(aufgabe)" (openAufgabeDialog)="openAufgabeDialog(true)" [aufgabe]="aufgabe">
		</schir-int-client-aufgabe-in-verfuegung>
	</ul>
</div>
<span id="sr-label-aufg" class="sr-only">{{ verfuegung.createdAt | formatDate }} Aufgaben</span>
