import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {ApiRootFacade} from '@schir-int-client/api-root';
import {map, switchMap, withLatestFrom} from 'rxjs/operators';
import {LoadRechtspflegerSuccessAction, RechtspflegerActions} from './rechtspfleger.actions';
import {RechtspflegerService} from './rechtspfleger.service';

@Injectable()
export class RechtspflegerEffects {

	constructor(private actions: Actions, private apiRootFacade: ApiRootFacade, private service: RechtspflegerService) { }


	loadRechtspfleger = createEffect(() => this.actions.pipe(
		ofType(RechtspflegerActions.LOAD_RECHTSPFLEGER),
		withLatestFrom(this.apiRootFacade.apiRoot),
		switchMap(([, apiRoot]) => {
			return this.service.loadRechtspfleger(apiRoot).pipe(
				map(rechtspflegerList => new LoadRechtspflegerSuccessAction(rechtspflegerList)),
			);
		}),
	));
}
