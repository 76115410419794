import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {DocumentResource, PosteingangFacade} from '@schir-int-client/posteingang-shared';

@Component({
	selector: 'schir-int-client-posteingang-document-list',
	templateUrl: './posteingang-document-list.component.html',
	styleUrls: ['./posteingang-document-list.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PosteingangDocumentListComponent {

	@Input() documents: DocumentResource[];
	posteingangfacade: PosteingangFacade;

	constructor(private facade: PosteingangFacade) {
		this.posteingangfacade = this.facade;
	}

	download(document: DocumentResource) {
		this.facade.downloadDokument(document);
	}
}
