import {Action} from '@ngrx/store';
import {createEmptyStateResource, createStateResource, StateResource} from '@schir-int-client/ngrx-helpers';
import {VerfahrenActions} from 'libs/verfahren-shared/src/lib/verfahren.actions';
import {
	AdressverwaltungActions,
	ChangeKontaktEditModeAction,
	CreateKontaktSuccessAction,
	LoadKontakteByVerfahrenSuccessAction,
	LoadKontakteSuccessAction,
	SetSelectedKontaktAction,
	UpdateKontaktSuccessAction,
	UpdateVerfahrenKontaktNotizSuccessAction,
} from './adressverwaltung.actions';
import {
	KontakteInVerfahren,
	KontaktListResource,
	KontaktResource,
	KontaktWithKategorie,
} from './adressverwaltung.model';
import {isArray, isNil} from 'lodash-es';

export interface AdressverwaltungState {
	kontakte: StateResource<KontaktListResource>;
	kontakteInVerfahren: StateResource<KontakteInVerfahren>,
	selectedKontakt: KontaktResource;
	editMode: boolean;
}

export const initialState: AdressverwaltungState = {
	kontakte: createEmptyStateResource(),
	kontakteInVerfahren: createEmptyStateResource(),
	selectedKontakt: null,
	editMode: false,
};

export function adressverwaltungReducer(state: AdressverwaltungState = initialState, action: Action): AdressverwaltungState {

	switch (action.type) {
		case AdressverwaltungActions.LOAD_KONTAKTE:
			return {
				...state,
				kontakte: createEmptyStateResource(true),
			};
		case AdressverwaltungActions.LOAD_KONTAKTE_SUCCESS:
			return {
				...state,
				kontakte: createStateResource((<LoadKontakteSuccessAction>action).kontakte),
			};
		case VerfahrenActions.LOAD_VERFAHREN_SINGLE_SUCCESS:
			return {
				...state,
				kontakteInVerfahren: { ...state.kontakteInVerfahren, reload: true },
			};
		case AdressverwaltungActions.LOAD_KONTAKTE_BY_VERFAHREN:
			return {
				...state,
				kontakteInVerfahren: createEmptyStateResource(true),
			};
		case AdressverwaltungActions.LOAD_KONTAKTE_BY_VERFAHREN_SUCCESS:
			return {
				...state,
				kontakteInVerfahren: createStateResource((<LoadKontakteByVerfahrenSuccessAction>action).kontakte),
			};

		case AdressverwaltungActions.ASSIGN_KONTAKT_SUCCESS:
		case VerfahrenActions.UNASSIGN_KONTAKT_SUCCESS:
			return {
				...state,
				kontakteInVerfahren: { ...state.kontakteInVerfahren, reload: true },
			};
		case AdressverwaltungActions.UPDATE_KONTAKT_SUCCESS:
			return {
				...state,
				selectedKontakt: (<UpdateKontaktSuccessAction>action).kontakt,
				kontakte: { ...state.kontakte, reload: true },
				kontakteInVerfahren: setReloadIfAffected(state.kontakteInVerfahren, (<UpdateKontaktSuccessAction>action).kontakt),
			};
		case AdressverwaltungActions.CREATE_KONTAKT_SUCCESS:
			return {
				...state,
				selectedKontakt: (<CreateKontaktSuccessAction>action).kontakt,
				kontakte: { ...state.kontakte, reload: true },
				editMode: false,
			};
		case AdressverwaltungActions.SET_SELECTED_KONTAKT:
			return {
				...state,
				selectedKontakt: (<SetSelectedKontaktAction>action).kontakt,
				editMode: false,
			};
		case AdressverwaltungActions.CHANGE_KONTAKT_EDIT_MODE:
			return {
				...state,
				editMode: (<ChangeKontaktEditModeAction>action).editMode,
			};
		case AdressverwaltungActions.UPDATE_VERFAHREN_KONTAKT_NOTIZ_SUCCESS:
			return {
				...state,
				kontakteInVerfahren: setReloadIfAffected(state.kontakteInVerfahren, (<UpdateVerfahrenKontaktNotizSuccessAction>action).kontakt),
			};
		case AdressverwaltungActions.UPDATE_VERFAHREN_KONTAKT_AKTENZEICHEN_SUCCESS:
			return {
				...state,
				kontakteInVerfahren: setReloadIfAffected(state.kontakteInVerfahren, (<UpdateVerfahrenKontaktNotizSuccessAction>action).kontakt),
			};
		default:
			return state;
	}
}

/**
 * Setzt das Reload-Flag auf KontakteInVerfahren, wenn geänderter Kontakt darin vorkommt.
 * @param kontakteInVerfahren
 * @param kontakt
 */
function setReloadIfAffected(kontakteInVerfahren: StateResource<KontakteInVerfahren>, kontakt: KontaktResource | KontaktWithKategorie): StateResource<KontakteInVerfahren> {
	const eventualKontakt: KontaktResource = kontakt['kontakt'] ? kontakt['kontakt'] : kontakt;
	const href: string = eventualKontakt._links.self.href;
	const kontaktLists = kontakteInVerfahren.resource;
	if (isNil(kontaktLists)) {
		return kontakteInVerfahren;
	}
	const affected = Object.values(kontaktLists)
		.filter(e => isArray(e))
		.some(list => list.some(kr => kr?._links.self.href === href));
	return affected ? { ...kontakteInVerfahren, reload: true } : kontakteInVerfahren;
}
