import {Component, EventEmitter, Output} from '@angular/core';

@Component({
	selector: 'schir-int-client-vorlage-file-upload',
	templateUrl: './vorlage-file-upload.component.html',
	styleUrls: ['./vorlage-file-upload.component.scss'],
})
export class VorlageFileUploadComponent {
	data: string | ArrayBuffer;
	vorlageFile: File = null;

	@Output() fileChanged: EventEmitter<File> = new EventEmitter();

	constructor() { }

	onFileChanged(files: FileList) {
		this.vorlageFile = files.item(0);
		this.fileChanged.emit(this.vorlageFile);
	}
}
