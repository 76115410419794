import {Component, Inject} from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA} from '@angular/material/legacy-dialog';
import {NotificationType} from '../notification.model';
import {DialogDataAcknowledge} from '@schir-int-client/dialog-shared';

@Component({
	selector: 'schir-int-client-dialog-acknowledge',
	templateUrl: './dialog-acknowledge.component.html',
	styleUrls: ['./dialog-acknowledge.component.scss'],
})
export class DialogAcknowledgeComponent {

	readonly notifcationTypes = NotificationType;

	constructor(@Inject(MAT_DIALOG_DATA) public data: DialogDataAcknowledge) { }
}
