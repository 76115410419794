import {createFeatureSelector, createSelector} from '@ngrx/store';
import {isNil} from 'lodash-es';
import {userFeatureState, UserRootState} from './user.state';

export const selectUserFeature = createFeatureSelector<UserRootState>(userFeatureState);

export const userSelector =
	createSelector(selectUserFeature, (state: UserRootState) => {
		if (isNil(state?.userRoot)) {
			return null;
		}
		return state.userRoot.user;
	});

export const userByUriSelector =
	createSelector(selectUserFeature, (state: UserRootState, props) => {
		if (isNil(state?.userRoot?.users)) {
			return null;
		}
		return isNil(state.userRoot.users[props.userUri]) ? null : state.userRoot.users[props.userUri];
	});
