import {Injectable} from '@angular/core';
import {Aktenzeichen} from './aktenzeichen.model';
import {RegisterName} from 'libs/register-shared/src/lib/register.model';
import {Verfahren} from 'libs/verfahren-shared/src/lib/verfahren.model';

/**
 * Unterstützt unter anderem bei der Register-spezifischen Extraktion von Aktenzeichenbestandteilen sowie dem Parsen
 * von Aktenzeichen aus Strings und Verfahren.
 *
 * Besitzt ein Pendant im Backend: AktenzeichenUtil.java
 */
@Injectable({
	providedIn: 'root',
})
export class AktenzeichenUtilService {
	public static readonly MAX_BLATTNUMMER = 99999999;

	private readonly FACTOR = 100000000;

	constructor() {}

	public getBlattNummer(aktenzeichen: Aktenzeichen | string): string {
		aktenzeichen = typeof aktenzeichen === 'string' ? this.parseString(aktenzeichen) : aktenzeichen;

		let blattnummer: string;

		switch (aktenzeichen.register) {
			case RegisterName.AR: {
				blattnummer = this.getBlattNummerString(aktenzeichen).padStart(3, '0') + '/' + this.getYearString(aktenzeichen);
				break;
			}
			default: {
				blattnummer = this.getBlattNummerString(aktenzeichen);
				break;
			}
		}
		return blattnummer;
	}

	public isValidAktenzeichen(aktenzeichen: string): boolean {
		if (aktenzeichen) {
			const aktenzeichenComponents = aktenzeichen.split(' ', 3);

			if (aktenzeichenComponents.length == 3) {
				const register = aktenzeichenComponents[1];

				return !isNaN(Number(aktenzeichenComponents[0].toString()))
					&& (register in RegisterName)
					&& !isNaN(Number(aktenzeichenComponents[2].toString()));
			}
		}
		return false;
	}

	public parse(aktenzeichen: string | Verfahren): Aktenzeichen {
		return (typeof aktenzeichen === 'string') ? this.parseString(<string>aktenzeichen) : this.parseVerfahren(<Verfahren>aktenzeichen);
	}

	private getYearString(aktenzeichen: Aktenzeichen): string {
		let s = aktenzeichen.blattNummer.toString();

		return s.substr(0, 4);
	}

	private getBlattNummerString(aktenzeichen: Aktenzeichen): string {
		return (aktenzeichen.blattNummer % this.FACTOR).toString();
	}

	private parseString(aktenzeichen: string): Aktenzeichen {
		if (!this.isValidAktenzeichen(aktenzeichen)) {
			throw new Error('Invalid Argument: \'' + aktenzeichen + '\' - Aktenzeichen must contain amtsgerichtsnummer + register + blattnummer.');
		}

		const aktenzeichenComponents = aktenzeichen.split(' ', 3);

		return {
			amtsgerichtsNummer: Number.parseInt(aktenzeichenComponents[0]),
			register: RegisterName[aktenzeichenComponents[1]],
			blattNummer: Number.parseInt(aktenzeichenComponents[2]),
		};
	}

	private parseVerfahren(verfahren: Verfahren): Aktenzeichen {
		return {
			amtsgerichtsNummer: verfahren.amtsgerichtNummer,
			blattNummer: verfahren.blattNummer,
			register: verfahren.register,
		};
	}
}
