import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, CanActivateChild, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {ApiRootFacade} from '@schir-int-client/api-root';
import {filter, map} from 'rxjs/operators';
import {UserFacade, UserResource, UserRole} from '@schir-int-client/user-shared';
import {isNotNullOrUndefined} from 'libs/tech/src/lib/filter/common';

@Injectable({
	providedIn: 'root',
})
export class AuthGuard implements CanActivate, CanActivateChild {

	constructor(private rootFacade: ApiRootFacade, private userFacade: UserFacade) {}

	canActivate(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

		return this.isNotOnlyAdmin();
	}

	canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		return this.isNotOnlyAdmin();
	}

	private isNotOnlyAdmin() {
		return this.userFacade.getCurrentUser().pipe(
			filter(isNotNullOrUndefined),
			map((user: UserResource) => {
				return user.roles.indexOf(UserRole.RECHTSPFLEGER) != -1 //
					|| user.roles.indexOf(UserRole.GESCHAEFTSSTELLE) != -1 //
					|| user.roles.indexOf(UserRole.DIENSTAUFSICHT) != -1;
			}),
		);
	}
}
