import {Action} from '@ngrx/store';
import {
	BetragsKorrekturWert,
	ChangeEntryResource,
	LaufendeNummerKorrekturWert,
	TeilloeschenWert,
} from '../../../register-change/src/lib/register-change.model';
import {PartiellRoetungsBereich} from './register.model';

export enum RegisterBlattActions {
	SAVE_REGISTERBLATT_CHANGE = 'SAVE_REGISTERBLATT_CHANGE',
	REGISTERBLATT_CHANGE_SAVED = 'REGISTERBLATT_CHANGED_SAVED',
	ROETEN_REGISTERBLATT_ENTRY = 'ROETEN_REGISTERBLATT_ENTRY',
	PARTIELL_ROETEN_REGISTERBLATT_ENTRY = 'PARTIELL_ROETEN_REGISTERBLATT_ENTRY',
	CREATE_TEILLOESCHEN_REGISTERBLATT_ENTRY = 'CREATE_TEILLOESCHEN_REGISTERBLATT_ENTRY',
	CREATE_LAUFENDE_NUMMER_KORREKTUR_ENTRY = 'CREATE_LAUFENDE_NUMMER_KORREKTUR_ENTRY',
	CREATE_BETRAGS_KORREKTUR_ENTRY = 'CREATE_BETRAGS_KORREKTUR_ENTRY'
}

export class RoetenRegisterBlattEntryAction implements Action {
	type = RegisterBlattActions.ROETEN_REGISTERBLATT_ENTRY;

	constructor(public entry: ChangeEntryResource) { }
}

export class PartiellRoetenRegisterBlattEntryAction implements Action {
	type = RegisterBlattActions.PARTIELL_ROETEN_REGISTERBLATT_ENTRY;

	constructor(public changeEntry: ChangeEntryResource, public roetungsBereich: PartiellRoetungsBereich) { };
}

export class CreateTeilloeschenAction implements Action {
	type = RegisterBlattActions.CREATE_TEILLOESCHEN_REGISTERBLATT_ENTRY;

	constructor(public changeEntry: ChangeEntryResource, public teilloeschenWert: TeilloeschenWert) { };
}

export class CreateLaufendeNummerKorrekturAction implements Action {
	type = RegisterBlattActions.CREATE_LAUFENDE_NUMMER_KORREKTUR_ENTRY;

	constructor(public changeEntry: ChangeEntryResource, public laufendeNummerKorrekturWert: LaufendeNummerKorrekturWert) { }
}

export class CreateBetragsKorrekturAction implements Action {
	type = RegisterBlattActions.CREATE_BETRAGS_KORREKTUR_ENTRY;

	constructor(public changeEntry: ChangeEntryResource, public betragsKorrekturWert: BetragsKorrekturWert) { }
}
