import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {MatLegacyButtonModule as MatButtonModule} from '@angular/material/legacy-button';
import {MatLegacyOptionModule as MatOptionModule} from '@angular/material/legacy-core';
import {MatLegacyDialogModule as MatDialogModule} from '@angular/material/legacy-dialog';
import {MatLegacyFormFieldModule as MatFormFieldModule} from '@angular/material/legacy-form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatLegacyInputModule as MatInputModule} from '@angular/material/legacy-input';
import {MatLegacySelectModule as MatSelectModule} from '@angular/material/legacy-select';
import {MatLegacyTableModule as MatTableModule} from '@angular/material/legacy-table';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatLegacyProgressSpinnerModule as MatProgressSpinnerModule} from '@angular/material/legacy-progress-spinner';
import {RouterModule, Routes} from '@angular/router';
import {adressverwaltungListRoute, AdressverwaltungModule} from '@schir-int-client/adressverwaltung';
import {PosteingangFacade, PosteingangSharedModule} from '@schir-int-client/posteingang-shared';
import {TechModule} from '@schir-int-client/tech';
import {VerfahrenModule} from '@schir-int-client/verfahren';
import {VorgangModule} from '@schir-int-client/vorgang';
import {PdfViewerComponent} from './pdf-viewer/pdf-viewer.component';
import {PosteingangBearbeitenComponent} from './posteingang-bearbeiten/posteingang-bearbeiten.component';
import {PosteingangDocumentListComponent} from './posteingang-document-list/posteingang-document-list.component';
import {PosteingangListComponent} from './posteingang-list/posteingang-list.component';

const routes: Routes = [
	{
		path: 'posteingangBearbeiten/:posteingangUri',
		component: PosteingangBearbeitenComponent,
		children: [
			adressverwaltungListRoute,
		],
	},
];

@NgModule({
	imports: [
		CommonModule,
		MatTableModule,
		MatButtonModule,
		MatGridListModule,
		MatInputModule,
		MatOptionModule,
		MatSelectModule,
		MatFormFieldModule,
		MatIconModule,
		MatDialogModule,
		FormsModule,
		TechModule,
		RouterModule.forChild(routes),
		PosteingangSharedModule,
		TechModule,
		VerfahrenModule,
		AdressverwaltungModule,
		VorgangModule,
		MatProgressSpinnerModule,
	],
	declarations: [
		PosteingangDocumentListComponent,
		PosteingangListComponent,
		PosteingangBearbeitenComponent,
		PdfViewerComponent,
	], // SCHIFF-2338: NICHT LÖSCHEN!  , PosteingangDocumentViewerComponent],
	exports: [
		PosteingangListComponent,
		PosteingangBearbeitenComponent,
	],
	providers: [PosteingangFacade],
})
export class PosteingangModule {}
