import {Action} from '@ngrx/store';
import {UserResource} from '../user.model';
import {UserProfile, UserProfileResource} from './userprofile.model';

export enum UserProfileActions {
	LOAD_USER_PROFILE = 'LOAD_USER_PROFILE',
	LOAD_USER_PROFILE_SUCCESS = 'LOAD_USER_PROFILE_SUCCESS',
	UPDATE_USER_PROFILE = 'UPDATE_USER_PROFILE',
	UPDATE_USER_PROFILE_SUCCESS = 'UPDATE_USER_PROFILE_SUCCESS'
}

export class LoadUserProfileAction implements Action {
	type = UserProfileActions.LOAD_USER_PROFILE;

	constructor(public user: UserResource) { }
}

export class LoadUserProfileSuccessAction implements Action {
	type = UserProfileActions.LOAD_USER_PROFILE_SUCCESS;

	constructor(public userProfile: UserProfileResource) { }
}

export class UpdateUserProfileAction implements Action {
	type = UserProfileActions.UPDATE_USER_PROFILE;

	constructor(public userProfile: UserProfile) { }
}

export class UpdateUserProfileSuccessAction implements Action {
	type = UserProfileActions.UPDATE_USER_PROFILE_SUCCESS;

	constructor(public userProfile: UserProfileResource) { }
}
