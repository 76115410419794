import {createFeatureSelector, createSelector, MemoizedSelector} from '@ngrx/store';
import {getEmbeddedResource} from '@ngxp/rest';
import {StateResource} from '@schir-int-client/ngrx-helpers';
import {sameUri} from '@schir-int-client/tech';
import {isNil} from 'lodash-es';
import {VerfuegungListLinkRel} from './verfuegung.linkrel';
import {VerfuegungResource} from './verfuegung.model';
import {verfuegungFeatureState, VerfuegungRootState} from './verfuegung.state';

export const selectFeature = createFeatureSelector<VerfuegungRootState>(verfuegungFeatureState);

export const verfuegungenSelector: MemoizedSelector<Object, StateResource<VerfuegungResource[]>> =
	createSelector(selectFeature, (state: VerfuegungRootState) => {
		const verfuegungen = state.verfuegungRoot.verfuegungen;
		if (isNil(verfuegungen.resource)) {
			return { ...verfuegungen, resource: <VerfuegungResource[]>[] };
		}

		return ({
			...verfuegungen,
			resource: <VerfuegungResource[]>getEmbeddedResource(verfuegungen.resource, VerfuegungListLinkRel.VERFUEGUNG_LIST),
		});
	});

export const verfuegungSelector: MemoizedSelector<Object, StateResource<VerfuegungResource>> =
	createSelector(selectFeature, (state: VerfuegungRootState) => {
		return state.verfuegungRoot.selectedVerfuegung;
	});

export const istVerfuegungSelectedSelector =
	createSelector(selectFeature, (state: VerfuegungRootState, props) => {
		const stateResource = state.verfuegungRoot.selectedVerfuegung;
		if ((stateResource.loaded) && sameUri(stateResource.resource, props.verfuegung)) {
			return true;
		}
		return false;
	});
