import {InjectionToken} from '@angular/core';
import {ROUTER_NAVIGATED} from '@ngrx/router-store';
import {Action, ActionReducerMap} from '@ngrx/store';
import {getUrl} from '@ngxp/rest';
import {createEmptyStateResource, createStateResource} from '@schir-int-client/ngrx-helpers';
import {ReducerConfig} from '@schir-int-client/test-helpers';
import {
	DownloadSignatureAction,
	DownloadSignatureSuccessAction,
	DownloadSignaturTaskSuccessAction,
	LoadSignatureValidatorResponsesSuccessAction,
	SignaturActions,
} from './signatur.actions';
import {SignaturLinkRel} from './signatur.linkrel';
import {SignaturRootState, SignaturState} from './signatur.state';

export const signaturReducerInjectionToken = new InjectionToken<ActionReducerMap<SignaturRootState>>('Registered SignaturReducer');


export const initialState: SignaturState = {
	signaturTask: null,
	inProgressDialog: { isOpen: false },
	signatures: {},
	signatureValidatorResponse: createEmptyStateResource(),
};

export function getSignaturReducer(): ActionReducerMap<SignaturRootState> {
	return {
		signatur: signaturReducer,
	};
}

export const signatureReducerConfig: ReducerConfig<SignaturRootState> = {
	reducers: getSignaturReducer(),
	injectionToken: signaturReducerInjectionToken,
};

export function signaturReducer(state: SignaturState = initialState, action: Action): SignaturState {
	switch (action.type) {
		case SignaturActions.DOWNLOAD_SIGNATUR_TASK_SUCCESS:
			return {
				...state,
				signaturTask: (<DownloadSignaturTaskSuccessAction>action).signaturTask,
			};
		case SignaturActions.OPEN_IN_PROGRESS_DIALOG:
			return {
				...state,
				inProgressDialog: { ...state.inProgressDialog, isOpen: true },
			};
		case SignaturActions.CLOSE_IN_PROGRESS_DIALOG:
			return {
				...state,
				inProgressDialog: { ...state.inProgressDialog, isOpen: false },
			};
		case SignaturActions.DOWNLOAD_SIGNATURE:
			return {
				...state,
				signatures: {
					...state.signatures,
					[getUrl((<DownloadSignatureAction>action).resource, SignaturLinkRel.DOWNLOAD_SIGNATURE)]: createEmptyStateResource(true),
				},
			};
		case SignaturActions.DOWNLOAD_SIGNATURE_SUCCESS:
			const signature = (<DownloadSignatureSuccessAction>action).signature;
			return {
				...state,
				signatures: { ...state.signatures, [getUrl(signature)]: createStateResource(signature) },
			};
		case SignaturActions.LOAD_SIGNATURE_VALIDATOR_RESPONSES:
			return {
				...state,
				signatureValidatorResponse: { ...state.signatureValidatorResponse, loading: true },
			};
		case SignaturActions.LOAD_SIGNATURE_VALIDATOR_RESPONSES_SUCCESS:
			return {
				...state,
				signatureValidatorResponse: createStateResource((<LoadSignatureValidatorResponsesSuccessAction>action).response),
			};
		case SignaturActions.LOAD_SIGNATURE_VALIDATOR_RESPONSES_NOTFOUND:
			return {
				...state,
				signatureValidatorResponse: { ...createEmptyStateResource(), loaded: true },
			};
		case ROUTER_NAVIGATED:
			return {
				...state,
				signatureValidatorResponse: createEmptyStateResource(),
				inProgressDialog: { ...state.inProgressDialog, isOpen: false },
			};
		default:
			return state;
	}
}
