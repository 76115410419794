import {Component} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {AufgabenTyp} from '@schir-int-client/aufgabe-shared';
import {CreateAufgabeFormService} from '../../../create-aufgabe-dialog/create-aufgabe.formservice';

@Component({
	selector: 'schir-int-client-aufgabe-lfpr-blattabschrift-editor',
	templateUrl: './aufgabe-lfpr-blattabschrift-editor.component.html',
	styleUrls: ['./aufgabe-lfpr-blattabschrift-editor.component.scss'],
})
export class AufgabeLfprBlattabschriftEditorComponent {
	readonly formServiceClass = CreateAufgabeFormService;
	readonly aufgabenTyp: AufgabenTyp = AufgabenTyp.LFPR_BLATTABSCHRIFT;

	constructor(private formService: CreateAufgabeFormService) {}

	get formGroup(): UntypedFormGroup {
		return <UntypedFormGroup>this.formService.form.controls[this.aufgabenTyp];
	}

	get bodyGroup(): UntypedFormGroup {
		return <UntypedFormGroup>this.formGroup.controls[CreateAufgabeFormService.feldAufgabeBody];
	}


	requested(fieldName: string): boolean {
		const val = this.bodyGroup.controls[fieldName].value;
		return val && val > 0;
	}

	subGroup(groupName: string): UntypedFormGroup {
		return <UntypedFormGroup>this.bodyGroup.controls[groupName];
	}

	get glaeubigerNeuNrEinfach(): UntypedFormControl {
		return <UntypedFormControl>this.subGroup(this.formServiceClass.groupLfprAbschriftEinfach).controls[this.formServiceClass.feldLfprAbschriftGlaeubigerNeuNr];
	}

	get glaeubigerAltNrEinfach(): UntypedFormControl {
		return <UntypedFormControl>this.subGroup(this.formServiceClass.groupLfprAbschriftEinfach).controls[this.formServiceClass.feldLfprAbschriftGlaeubigerAltNr];
	}

	get glaeubigerNeuNrBeglaubigt(): UntypedFormControl {
		return <UntypedFormControl>this.subGroup(this.formServiceClass.groupLfprAbschriftBeglaubigt).controls[this.formServiceClass.feldLfprAbschriftGlaeubigerNeuNr];
	}

	get glaeubigerAltNrBeglaubigt(): UntypedFormControl {
		return <UntypedFormControl>this.subGroup(this.formServiceClass.groupLfprAbschriftBeglaubigt).controls[this.formServiceClass.feldLfprAbschriftGlaeubigerAltNr];
	}
}
