import {Component, OnInit} from '@angular/core';
import {Observable} from 'rxjs';
import {VerfahrenFacade, VerfahrenResource} from '@schir-int-client/verfahren-shared';
import {ActivatedRoute} from '@angular/router';
import {map, switchMap, take} from 'rxjs/operators';
import {TitleService} from '../../../../tech/src/lib/title/title.service';
import {AktenzeichenUtilService} from '@schir-int-client/aktenzeichen-shared';
import {AdressverwaltungFacade} from '@schir-int-client/adressverwaltung-shared';

@Component({
	selector: 'schir-int-client-vorgaenge-in-verfahren-container',
	templateUrl: './vorgaenge-in-verfahren-container.component.html',
	styleUrls: ['./vorgaenge-in-verfahren-container.component.scss'],
	host: { 'class': 'x-section' },
})
export class VorgaengeInVerfahrenContainerComponent implements OnInit {
	verfahren: Observable<VerfahrenResource>;

	constructor(
		private verfahrenFacade: VerfahrenFacade,
		private adressverwaltungFacade: AdressverwaltungFacade,
		private route: ActivatedRoute,
		public titleService: TitleService,
		private aktenzeichenUtilService: AktenzeichenUtilService,
	) {
		this.verfahren = this.route.params.pipe(
			map(route => atob(decodeURIComponent(route['verfahrenId']))),
			switchMap(url => {
				verfahrenFacade.loadSingleVerfahren(url);
				return this.verfahrenFacade.verfahrenSingle;
			}),
		);
	}

	ngOnInit() {
		this.verfahren.forEach(value => {
			if (value != null) {
				this.titleService.setTitle(value.register + ' ' + this.aktenzeichenUtilService.getBlattNummer(value.aktenzeichen));
			}
		});
		// Verbirgt die Ladezeit der Kontakte vor dem Benutzer
		this.adressverwaltungFacade.getKontakte(null).pipe(take(1)).subscribe(contacts => console.log("pre-loaded contacts"));
	}

	//switch focus to left or right column by arrow keys
	switchFocus(event: KeyboardEvent) {
		const leftArrow = (event.key == 'ArrowLeft');
		const rightArrow = (event.key == 'ArrowRight');

		if (leftArrow || rightArrow) {
			const activeParents: HTMLElement[] = this.getVisibleParents();
			const lastIndex = activeParents.length - 1;
			const activeElement = document.activeElement;

			for (let p of activeParents) {
				if (p.contains(activeElement)) {
					let focusIndex;
					let index = activeParents.indexOf(p);

					if (index == 0 && leftArrow) {
						focusIndex = lastIndex;
					} else if (index == lastIndex && rightArrow) {
						focusIndex = 0;
					} else if (leftArrow) {
						focusIndex = --index;
					} else if (rightArrow) {
						focusIndex = ++index;
					}
					(<HTMLElement>activeParents[focusIndex].querySelector('h2')).focus();
				}
			}
		}
	}

	private getVisibleParents(): HTMLElement[] {
		const activeElements = [];
		const prefix = 'schir-int-client-';
		const suffix = [
			'vorgaenge-in-verfahren',
			'verfuegungen-in-vorgang-container',
			'aufgaben-in-verfuegung-container',
			'adressaten-in-aufgabe-container',
			'kontakt-list-container',
		];

		for (let s of suffix) {
			const element: HTMLElement = document.querySelector(prefix + s);
			if (element) {
				activeElements.push(element);
			}
		}
		return activeElements;
	}
}
