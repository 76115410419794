import {Component} from '@angular/core';
import {VerfahrenFacade, VerfahrenResource} from '@schir-int-client/verfahren-shared';
import {Observable} from 'rxjs';
import {AdressverwaltungDialogComponent} from '../kontakt-list/adressverwaltung-dialog/adressverwaltung-dialog.component';
import {AccessibilityService, DialogService, HandlesBackdropClickAndEscapeKey} from '@schir-int-client/tech';
import {
	AdressverwaltungFacade,
	ASK_SWITCH_UNSAVED_KONTAKT_IN_EDIT,
	KontaktWithKategorie,
} from '@schir-int-client/adressverwaltung-shared';
import {first} from 'rxjs/operators';

@Component({
	selector: 'schir-int-client-kontakt-list-container',
	templateUrl: './kontakt-list-container.component.html',
	styleUrls: ['./kontakt-list-container.component.scss'],
})
export class KontaktListContainerComponent extends HandlesBackdropClickAndEscapeKey<AdressverwaltungDialogComponent> {

	verfahren: Observable<VerfahrenResource>;
	isVerfahrenSelected: boolean;

	constructor(private verfahrenFacade: VerfahrenFacade,
	            protected dialogService: DialogService,
	            private adressVerwaltungsFacade: AdressverwaltungFacade,
	            private accessibilityService: AccessibilityService) {
		super(dialogService);

		this.verfahren = this.verfahrenFacade.verfahrenSingle;
	}

	openAdressverwaltung() {
		this.matDialogRef = this.dialogService.openEditorDialog(this, AdressverwaltungDialogComponent, {
			panelClass: 'adressverwaltung',
		});
	}

	unassignKontakt(kontaktWithKategorie: KontaktWithKategorie) {
		this.verfahrenFacade.unassignKontakt(kontaktWithKategorie);
	}

	protected showConfirmDialogConditionally(editMode: boolean) {
		if (this.matDialogRef.componentInstance.formService.form.dirty) {
			this.createConfirmDialogAndCloseOnAgree();
		} else {
			if (editMode) {
				this.adressVerwaltungsFacade.selectedKontakt.pipe(first()).subscribe(kontakt => {
					if (kontakt.title) {
						this.accessibilityService.focusElementByDataTitle(kontakt.title);
					}

					this.adressVerwaltungsFacade.setEditMode(false);
				});
			} else {
				this.matDialogRef.close();
			}
		}
	}

	protected closeAndSetActiveEditorNull(): void {
		this.adressVerwaltungsFacade.selectedKontakt.pipe(first()).subscribe(kontakt => {
			if (kontakt.title) {
				this.accessibilityService.focusElementByDataTitle(kontakt.title);
			}

			this.adressVerwaltungsFacade.setEditMode(false);
			this.matDialogRef.close();
		});
	}

	getConfirmMessage(): string {
		return ASK_SWITCH_UNSAVED_KONTAKT_IN_EDIT;
	}
}
