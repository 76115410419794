import {Component, Input} from '@angular/core';
import {VerfahrenResource} from '@schir-int-client/verfahren-shared';
import {CreateVorgangDialogComponent} from './create-vorgang-dialog/create-vorgang-dialog.component';
import {DialogService, HandlesBackdropClickAndEscapeKey} from '@schir-int-client/tech';

@Component({
	selector: 'schir-int-client-create-vorgang-button',
	templateUrl: './create-vorgang-button.component.html',
	styleUrls: ['./create-vorgang-button.component.scss'],
})
export class CreateVorgangButtonComponent extends HandlesBackdropClickAndEscapeKey<CreateVorgangDialogComponent> {
	@Input() verfahren: VerfahrenResource;
	@Input() withPosteingang: boolean = false;
	@Input() cssClass: string;

	constructor(protected dialogService: DialogService) {
		super(dialogService);
	}

	openCreateVorgangDialog() {
		this.dialogService.openEditorDialog(this, CreateVorgangDialogComponent, {
			data: {
				verfahren: this.verfahren,
				withPosteingang: this.withPosteingang,
			},
		});
	}
}
