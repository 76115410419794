import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import {ResourceUri} from '@ngxp/rest';
import {LoadingGuard, StateResource} from '@schir-int-client/ngrx-helpers';
import {VorgangFacade, VorgangLinkRel, VorgangResource} from '@schir-int-client/vorgang-shared';
import {isNil} from 'lodash-es';
import {combineLatest, first, Observable} from 'rxjs';
import {filter, map, tap} from 'rxjs/operators';
import {
	CreateKorrespondenzVerfuegungByVorgangAction,
	CreateVerfuegungByVorgangAction,
	CreateVerfuegungDruckAction,
	LoadVerfuegungAction,
	LoadVerfuegungenByVorgangAction,
	SetSelectedVerfuegungAction,
	UpdateVerfuegungNotizAction,
	VerfuegungDeleteAction,
	VerfuegungMarkAsErledigtAction,
} from './verfuegung.actions';
import {VerfuegungResource} from './verfuegung.model';
import {VerfuegungState} from './verfuegung.reducer';
import {istVerfuegungSelectedSelector, verfuegungenSelector, verfuegungSelector} from './verfuegung.selectors';

@Injectable()
export class VerfuegungFacade {

	verfuegungenByVorgang: Observable<VerfuegungResource[]> = this.getVerfuegungenByVorgang();

	verfuegung: Observable<VerfuegungResource> = this.store.select(verfuegungSelector).pipe(
		tap(res => this.currentlyLoadingVerfuegungUri = ''),
		map(stateResource => stateResource.resource),
	);

	private loadingByVorgang = new LoadingGuard(this.store);
	private currentlyLoadingVerfuegungUri: string;

	constructor(private store: Store<VerfuegungState>, private vorgangFacade: VorgangFacade) { }


	loadVerfuegung(verfuegungUri: string) {
		if (this.currentlyLoadingVerfuegungUri !== verfuegungUri) {
			this.store.dispatch(new LoadVerfuegungAction(verfuegungUri));
			this.currentlyLoadingVerfuegungUri = verfuegungUri;
		}
	}

	getVerfuegungenByVorgang(): Observable<VerfuegungResource[]> {
		return combineLatest([this.vorgangFacade.vorgang, this.store.select(verfuegungenSelector)]).pipe(
			filter(([vorgang]) => !isNil(vorgang)),
			filter(([vorgang, stateResource]) =>
				!this.loadingByVorgang.mustLoadFirst(stateResource, () => new LoadVerfuegungenByVorgangAction(), vorgang._links[VorgangLinkRel.VERFUEGUNGEN]?.href)),
			tap(([vorgang, stateResource]: [VorgangResource, StateResource<VerfuegungResource[]>]) => {
				if (stateResource.resource?.length > 0 && stateResource.resource.find(verfuegung => vorgang._links.self.href.endsWith('/' + verfuegung.vorgangId))) {
					this.vorgangFacade.registerUndeletable(vorgang);
				}
			}),
			map(([, stateResource]) => stateResource),
			map((stateResource => stateResource.resource)),
		);
	}

	createVerfuegungByVorgang(notiz: string): Observable<StateResource<VerfuegungResource>> {
		this.store.dispatch(new CreateVerfuegungByVorgangAction(notiz));

		return this.store.select(verfuegungSelector);
	}

	createKorrespondenzVerfuegungByVorgang(notiz: string): Observable<StateResource<VerfuegungResource>> {
		this.store.dispatch(new CreateKorrespondenzVerfuegungByVorgangAction(notiz));

		return this.store.select(verfuegungSelector);
	}

	markAsErledigt(verfuegung: VerfuegungResource) {
		this.store.dispatch(new VerfuegungMarkAsErledigtAction(verfuegung));
	}

	deleteVerfuegung(verfuegung: VerfuegungResource) {
		this.store.select(verfuegungenSelector).pipe(
			first(),
			map(stateResource => stateResource.resource),
			map(verfuegungen => verfuegungen.filter(v => v.vorgangId === verfuegung.vorgangId)),
			map(verfuegungenForSameVorgang => verfuegungenForSameVorgang.length > 1),
		).subscribe(hasMultipleVerfuegungen => {
			if (!hasMultipleVerfuegungen) {
				this.vorgangFacade.registerDeletable(verfuegung.vorgangId);
			}
			this.store.dispatch(new VerfuegungDeleteAction(verfuegung));
		});
	}

	setSelectedVerfuegung(verfuegung: VerfuegungResource) {
		this.store.dispatch(new SetSelectedVerfuegungAction(verfuegung));
	}

	isSelectedVerfuegung(verfuegung: VerfuegungResource): Observable<boolean> {
		return this.store.select(istVerfuegungSelectedSelector, { verfuegung: verfuegung });
	}

	updateNotiz(notiz: string, verfuegungUri: ResourceUri) {
		this.store.dispatch(new UpdateVerfuegungNotizAction(notiz, verfuegungUri));
	}

	drucken() {
		this.store.dispatch(new CreateVerfuegungDruckAction());
	}
}
