import {Component, EventEmitter, Inject} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA} from '@angular/material/legacy-dialog';
import {Kontakt, KontaktKategorie, KontaktKategorieLabel} from '@schir-int-client/adressverwaltung-shared';
import {AdressatFormService} from './adressat.formservice';
import {BaseEditorComponent} from '@schir-int-client/tech';

@Component({
	selector: 'schir-int-client-add-adressat-to-aufgabe-dialog',
	templateUrl: './add-adressat-to-aufgabe-dialog.component.html',
	styleUrls: ['./add-adressat-to-aufgabe-dialog.component.scss'],
	providers: [AdressatFormService],
})
export class AddAdressatToAufgabeDialogComponent extends BaseEditorComponent {
	form: UntypedFormGroup;
	kategorie: KontaktKategorie;
	kontakt: Kontakt;

	kontaktKategorieLabel = KontaktKategorieLabel;

	reassigned: EventEmitter<boolean> = new EventEmitter();

	constructor(@Inject(MAT_DIALOG_DATA) data: any) {
		super();

		this.form = data.form;
		this.kategorie = data.kategorie;
		this.kontakt = <any>{
			vorname: data.vorname,
			name: data.name,
			firmenName: data.firmenName,
			juristischePerson: data.juristischePerson,
		};
	}

	async submit(): Promise<boolean> {
		this.reassigned.emit(true);
		return true;
	}
}
