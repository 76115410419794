import {Action} from '@ngrx/store';
import {UserListResource} from '../user.model';

export enum RechtspflegerActions {
	LOAD_RECHTSPFLEGER = 'LOAD_RECHTSPFLEGER',
	LOAD_RECHTSPFLEGER_SUCCESS = 'LOAD_RECHTSPFLEGER_SUCCESS'
}

export class LoadRechtspflegerAction implements Action {
	type = RechtspflegerActions.LOAD_RECHTSPFLEGER;
}

export class LoadRechtspflegerSuccessAction implements Action {
	type = RechtspflegerActions.LOAD_RECHTSPFLEGER_SUCCESS;

	constructor(public rechtspflegerList: UserListResource) { }
}
