import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {getUrl, ResourceFactory, ResourceUri} from '@ngxp/rest';
import {VorgangLinkRel, VorgangResource} from '@schir-int-client/vorgang-shared';
import {Observable} from 'rxjs';
import {VerfuegungLinkRel} from './verfuegung.linkrel';
import {VerfuegungListResource, VerfuegungResource} from './verfuegung.model';

@Injectable({ providedIn: 'root' })
export class VerfuegungService {
	constructor(private resourceFactory: ResourceFactory, private httpClient: HttpClient) { }

	public getVerfuegungenByVorgang(vorgang: VorgangResource): Observable<VerfuegungListResource> {
		return this.resourceFactory.from(vorgang).get(VorgangLinkRel.VERFUEGUNGEN);
	}

	public createVerfuegung(notiz: string, vorgangResource: VorgangResource): Observable<VerfuegungResource> {
		return this.resourceFactory.from(vorgangResource).post(VerfuegungLinkRel.CREATE_VERFUEGUNG, notiz);
	}

	public deleteVerfuegung(verfuegung: VerfuegungResource): Observable<VerfuegungListResource> {
		return this.resourceFactory.from(verfuegung).delete(VerfuegungLinkRel.DELETE);
	}

	public createKorrespondenzVerfuegung(notiz: string, vorgangResource: VorgangResource): Observable<VerfuegungResource> {
		return this.resourceFactory.from(vorgangResource).post(VerfuegungLinkRel.CREATE_KORRESPONDENZ_VERFUEGUNG, notiz);
	}

	public getOne(verfuegungUri: ResourceUri): Observable<VerfuegungResource> {
		return this.resourceFactory.fromId(verfuegungUri).get();
	}

	public markAsErledigt(verfuegung: VerfuegungResource): Observable<VerfuegungResource> {
		return this.resourceFactory.from(verfuegung).patch(VerfuegungLinkRel.MARK_AS_ERLEDIGT);
	}

	public updateNotiz(notiz: string, verfuegungUri: ResourceUri): Observable<VerfuegungResource> {
		return this.resourceFactory.fromId(verfuegungUri).post({ notiz });
	}

	public drucken(verfuegung: VerfuegungResource) {
		const url: ResourceUri = getUrl(verfuegung, VerfuegungLinkRel.DRUCKEN);
		let headers = new HttpHeaders();
		headers = headers.set('Accept', 'application/pdf, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/hal+json');

		return this.httpClient.get<Blob>(url, { headers, responseType: 'blob' as 'json' });
	}
}
