import {InjectionToken} from '@angular/core';
import {ActionReducerMap} from '@ngrx/store';
import {ReducerConfig} from '@schir-int-client/test-helpers';
import {verfahrenReducer, VerfahrenState} from './verfahren.reducer';

export const verfahrenFeatureState = 'VerfahrenState';
export const verfahrenReducerInjectionToken = new InjectionToken<ActionReducerMap<VerfahrenRootState>>('Registered VerfahrenReducer');

export interface VerfahrenRootState {
	verfahrenRoot: VerfahrenState;
}

export function getVerfahrenReducer(): ActionReducerMap<VerfahrenRootState> {
	return {
		verfahrenRoot: verfahrenReducer,
	};
}

export const reducerConfig: ReducerConfig<VerfahrenRootState> = {
	reducers: getVerfahrenReducer(),
	injectionToken: verfahrenReducerInjectionToken,
};
