import {InjectionToken} from '@angular/core';
import {ActionReducerMap} from '@ngrx/store';
import {ReducerConfig} from '@schir-int-client/test-helpers';
import {aufgabeReducer, AufgabeState} from './aufgabe.reducer';

export const aufgabeFeatureState = 'AufgabeState';
export const aufgabeReducerInjectionToken = new InjectionToken<ActionReducerMap<AufgabeRootState>>('Registered AufgabeReducer');

export interface AufgabeRootState {
	aufgabeRoot: AufgabeState;
}

export function getAufgabeReducer(): ActionReducerMap<AufgabeRootState> {
	return {
		aufgabeRoot: aufgabeReducer,
	};
}

export const reducerConfig: ReducerConfig<AufgabeRootState> = {
	reducers: getAufgabeReducer(),
	injectionToken: aufgabeReducerInjectionToken,
};
