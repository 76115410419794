import {Injectable} from '@angular/core';
import {ResourceFactory} from '@ngxp/rest';
import {Observable} from 'rxjs';
import {UserResource} from '../user.model';
import {UserProfile, UserProfileResource} from './userprofile.model';
import {UserListLinkRel} from '../user.linkrel';
import {UserProfileLinkRel} from './userprofile.linkrel';

@Injectable({ providedIn: 'root' })
export class UserProfileService {

	constructor(private resourceFactory: ResourceFactory) { }

	getProfile(user: UserResource): Observable<UserProfileResource> {
		return this.resourceFactory.from(user).get(UserListLinkRel.USER_PROFILE);
	}

	updateProfile(profileToUpdate: UserProfileResource, newValues: UserProfile): Observable<UserProfileResource> {
		return this.resourceFactory.from(profileToUpdate).post(UserProfileLinkRel.EDIT, newValues);
	}
}
