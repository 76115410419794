import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import {getUrl, Resource} from '@ngxp/rest';
import {isNil} from 'lodash-es';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';
import {
	LoadUserFromResourceAction,
	UserIsUnauthorizedAction,
	UserLoggedInAction,
	UserLogoutAction,
} from './user.actions';
import {UserResource} from './user.model';
import {userByUriSelector, userSelector} from './user.selectors';
import {UserRootState} from './user.state';

@Injectable({ providedIn: 'root' })
export class UserFacade {

	constructor(private store: Store<UserRootState>) { }

	loggedIn() {
		this.store.dispatch(new UserLoggedInAction());
	}

	logout() {
		this.store.dispatch(new UserLogoutAction());
	}

	unauthorized() {
		this.store.dispatch(new UserIsUnauthorizedAction());
	}

	getUserByUri(resource: Resource, linkRel: string): Observable<UserResource> {
		return this.store.select(userByUriSelector, { userUri: getUrl(resource, linkRel) }).pipe(
			tap(user => {
				if (isNil(user)) {
					this.store.dispatch(new LoadUserFromResourceAction(resource, linkRel));
				}
			}),
		);
	}

	getCurrentUser(): Observable<UserResource> {
		return this.store.select(userSelector);
	}
}
