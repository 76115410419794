<fieldset class="grid" *ngIf="formGroup.enabled" data-test-id="aufgabe-versand-root">
	<legend [attr.aria-label]="'Einstellungen zu '+aufgabenTyp"></legend>
	<div class="column" [formGroup]="bodyGroup">
		<fieldset [ngClass]="formGroup.invalid ? 'error' : 'noerror'" class="column empfaenger">
			<legend aria-label="Empfänger auswählen"> {{ formGroup.valid ? "Empfänger" : "Empfänger *" }}</legend>

			<mat-checkbox [formControlName]="formServiceClass.feldLfprMitteilungEigentuemerNeu" color="primary" class="focus-box-shadow-dark"
						  data-test-id="mitteilung-checkbox-eigentuemer-neu">
				Eigentümer neu
			</mat-checkbox>
			<fieldset *ngIf="checked(formServiceClass.feldLfprMitteilungEigentuemerNeu)"
					  [formGroup]="subGroup(formServiceClass.groupLfprMitteilungEigentuemerNeu)" class="inline-group">
				<mat-checkbox [formControlName]="formServiceClass.feldLfprMitteilungUeberAnwalt" color="primary" class="focus-box-shadow-dark"
							  data-test-id="mitteilung-eigentuemer-neu-checkbox-anwalt">
					über Rechtsanwalt
				</mat-checkbox>
				<mat-checkbox [formControlName]="formServiceClass.feldLfprMitteilungUeberNotar" color="primary" class="focus-box-shadow-dark"
							  data-test-id="mitteilung-eigentuemer-neu-checkbox-notar">
					über Notar
				</mat-checkbox>
			</fieldset>

			<mat-checkbox [formControlName]="formServiceClass.feldLfprMitteilungEigentuemerAlt" color="primary" class="focus-box-shadow-dark"
						  data-test-id="mitteilung-checkbox-eigentuemer-alt">
				Eigentümer alt
			</mat-checkbox>
			<fieldset *ngIf="checked(formServiceClass.feldLfprMitteilungEigentuemerAlt)"
					  [formGroup]="subGroup(formServiceClass.groupLfprMitteilungEigentuemerAlt)" class="inline-group">
				<mat-checkbox [formControlName]="formServiceClass.feldLfprMitteilungUeberAnwalt" color="primary" class="focus-box-shadow-dark"
							  data-test-id="mitteilung-eigentuemer-alt-checkbox-anwalt">
					über Rechtsanwalt
				</mat-checkbox>
				<mat-checkbox [formControlName]="formServiceClass.feldLfprMitteilungUeberNotar" color="primary" class="focus-box-shadow-dark"
							  data-test-id="mitteilung-eigentuemer-alt-checkbox-notar">
					über Notar
				</mat-checkbox>
			</fieldset>

			<mat-checkbox [formControlName]="formServiceClass.feldLfprMitteilungNotar" color="primary" class="focus-box-shadow-dark"
						  data-test-id="mitteilung-checkbox-notar">
				Notar
			</mat-checkbox>
			<fieldset *ngIf="checked(formServiceClass.feldLfprMitteilungNotar)"
					  [formGroup]="subGroup(formServiceClass.groupLfprMitteilungNotar)" class="inline-group">
				<schir-int-client-one-value-editor [type]="'text'" [label]="'zu UVZ-Nr.'" [fieldControl]="uvzNr"
												   color="primary" data-test-id="mitteilung-notar-input-uvznr">
				</schir-int-client-one-value-editor>
			</fieldset>

			<mat-checkbox [formControlName]="formServiceClass.feldLfprMitteilungLFBA" color="primary" class="focus-box-shadow-dark"
						  data-test-id="mitteilung-checkbox-lfba">
				Luftfahrtbundesamt
			</mat-checkbox>
			<fieldset *ngIf="checked(formServiceClass.feldLfprMitteilungLFBA)"
					  [formGroup]="subGroup(formServiceClass.groupLfprMitteilungLFBA)" class="inline-group">
				<schir-int-client-one-value-editor [type]="'text'" [label]="'zu Nr. der Luftfahrzeugrolle'" [fieldControl]="nrRolle"
												   color="primary" data-test-id="mitteilung-lfba-input-nrRolle">
				</schir-int-client-one-value-editor>
			</fieldset>

			<mat-checkbox [formControlName]="formServiceClass.feldLfprMitteilungGlaeubigerNeu" color="primary" class="focus-box-shadow-dark"
						  data-test-id="mitteilung-checkbox-glaeubiger-neu">
				Gläubiger neu
			</mat-checkbox>
			<fieldset *ngIf="checked(formServiceClass.feldLfprMitteilungGlaeubigerNeu)"
					  [formGroup]="subGroup(formServiceClass.groupLfprMitteilungGlaeubigerNeu)" class="inline-group">
				<mat-checkbox [formControlName]="formServiceClass.feldLfprMitteilungGlaeubigerRuecksendung" color="primary"
							  class="focus-box-shadow-dark"
							  data-test-id="mitteilung-glaeubiger-neu-checkbox-ruecksendung">
					Unter Rücksendung
				</mat-checkbox>
			</fieldset>

			<mat-checkbox [formControlName]="formServiceClass.feldLfprMitteilungGlaeubigerAlt" color="primary" class="focus-box-shadow-dark"
						  data-test-id="mitteilung-checkbox-glaeubiger-alt">
				Gläubiger alt
			</mat-checkbox>
			<fieldset *ngIf="checked(formServiceClass.feldLfprMitteilungGlaeubigerAlt)"
					  [formGroup]="subGroup(formServiceClass.groupLfprMitteilungGlaeubigerAlt)" class="inline-group">
				<mat-checkbox [formControlName]="formServiceClass.feldLfprMitteilungGlaeubigerRuecksendung" color="primary"
							  class="focus-box-shadow-dark"
							  data-test-id="mitteilung-glaeubiger-alt-checkbox-ruecksendung">
					Unter Rücksendung
				</mat-checkbox>
			</fieldset>

			<mat-checkbox [formControlName]="formServiceClass.feldLfprMitteilungBerechtigter" color="primary" class="focus-box-shadow-dark"
						  data-test-id="mitteilung-checkbox-berechtigter">
				Berechtigter Abt. I/
			</mat-checkbox>
			<fieldset *ngIf="checked(formServiceClass.feldLfprMitteilungBerechtigter)"
					  [formGroup]="subGroup(formServiceClass.groupLfprMitteilungBerechtigter)" class="inline-group">
				<schir-int-client-one-value-editor [type]="'text'" [label]="'Nr. in Abt. I'" [fieldControl]="berechtigterNr"
												   color="primary" data-test-id="mitteilung-berechtigter-input-nr">
				</schir-int-client-one-value-editor>
				<mat-checkbox [formControlName]="formServiceClass.feldLfprMitteilungUeberAnwalt" color="primary" class="focus-box-shadow-dark"
							  data-test-id="mitteilung-berechtigter-checkbox-anwalt">
					über Rechtsanwalt
				</mat-checkbox>
				<mat-checkbox [formControlName]="formServiceClass.feldLfprMitteilungUeberNotar" color="primary" class="focus-box-shadow-dark"
							  data-test-id="mitteilung-berechtigter-checkbox-notar">
					über Notar
				</mat-checkbox>
			</fieldset>

			<mat-checkbox [formControlName]="formServiceClass.feldLfprMitteilungRechtsanwalt" color="primary" class="focus-box-shadow-dark"
						  data-test-id="mitteilung-checkbox-anwalt">
				Rechtsanwalt
			</mat-checkbox>
			<fieldset *ngIf="checked(formServiceClass.feldLfprMitteilungRechtsanwalt)"
					  [formGroup]="subGroup(formServiceClass.groupLfprMitteilungRechtsanwalt)" class="inline-group">
				<schir-int-client-one-value-editor [type]="'text'" [label]="'zu AZ'" [fieldControl]="zuAz"
												   color="primary" data-test-id="mitteilung-anwalt-input-az">
				</schir-int-client-one-value-editor>
			</fieldset>
		</fieldset>
		<mat-error *ngIf="formGroup.invalid" data-test-id="invalid-checkbox-message">
			Bitte Empfänger wählen
		</mat-error>
	</div>
	<schir-int-client-notiz-in-aufgabe-editor class="row note" [aufgabenTyp]="aufgabenTyp">
	</schir-int-client-notiz-in-aufgabe-editor>
</fieldset>

