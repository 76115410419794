import {Injectable} from '@angular/core';
import {EipStorage} from './eip.storage';

@Injectable({ providedIn: 'root' })
export class EipWebSocketFactory {
	constructor(private storage: EipStorage) { }

	public openWebSocket(): WebSocket {
		return new WebSocket(`ws://127.0.0.1:${this.storage.getPort()}`);
	}
}
