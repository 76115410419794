import {Resource} from '@ngxp/rest';
import {RegisterName} from '@schir-int-client/register-shared';
import {VerfahrenStatus} from '@schir-int-client/verfahren-shared';

export interface Statistik {
	SSR: StatistikEntry;
	SBR: StatistikEntry;
	BSR: StatistikEntry;
	AR: StatistikEntry;
	LFPR: StatistikEntry;
}

export interface StatistikEntry {
	ABGELEHNT: number;
	EINGETRAGEN: number;
	VORLAEUFIG: number;
	GELOESCHT: number;
}

export interface MonthlyStatistikEntry {
	datum: Date,
	register: RegisterName,
	[VerfahrenStatus.VORLAEUFIG]: number,
	[VerfahrenStatus.EINGETRAGEN]: number,
	[VerfahrenStatus.ABGELEHNT]: number,
	[VerfahrenStatus.GESCHLOSSEN]: number,
	[VerfahrenStatus.GELOESCHT]: number
}

export interface MonthlyStatistik extends Resource {
	[RegisterName.SSR]: MonthlyStatistikEntry[],
	[RegisterName.BSR]: MonthlyStatistikEntry[],
	[RegisterName.SBR]: MonthlyStatistikEntry[],
	[RegisterName.AR]: MonthlyStatistikEntry[],
	[RegisterName.LR]: MonthlyStatistikEntry[]
}

export interface StatistikResource extends Statistik, Resource {}

export interface ZaehlblattForMonth {
	month: string;
	count: Record<string, number>;
}

export interface ZaehlblattStatistik {
	data: ZaehlblattForMonth[];
}

export interface ZaehlblattStatistikResource extends ZaehlblattStatistik, Resource {}



