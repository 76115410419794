import {InjectionToken} from '@angular/core';
import {ActionReducerMap} from '@ngrx/store';
import {ReducerConfig} from '@schir-int-client/test-helpers';
import {userProfileReducer, UserProfileState} from './userprofile.reducer';

export const userProfileFeatureState = 'UserProfileState';
export const userProfileReducerInjectionToken = new InjectionToken<ActionReducerMap<UserProfileRootState>>('Registered UserProfileReducer');

export interface UserProfileRootState {
	userProfileRoot: UserProfileState;
}

export function getUserProfileReducer(): ActionReducerMap<UserProfileRootState> {
	return {
		userProfileRoot: userProfileReducer,
	};
}

export const reducerConfig: ReducerConfig<UserProfileRootState> = {
	reducers: getUserProfileReducer(),
	injectionToken: userProfileReducerInjectionToken,
};
