import {createFeatureSelector, createSelector} from '@ngrx/store';
import {getEmbeddedResource} from '@ngxp/rest';
import {createEmptyStateResource} from '@schir-int-client/ngrx-helpers';
import {isNil, isNull, isUndefined} from 'lodash-es';
import {DocumentListLinkRel, PosteingangLinkRel, PosteingangListLinkRel} from './posteingang.linkrel';
import {DocumentListResource, DocumentResource, DokumentTyp, PosteingangResource} from './posteingang.model';
import {featureState, PosteingangRootState} from './posteingang.state';

export const selectFeature = createFeatureSelector<PosteingangRootState>(featureState);
const EMPTY_ARRAY = [];

export const posteingaengeSelector =
	createSelector(selectFeature, (state: PosteingangRootState) => {
		if (!isNull(state.posteingangRoot.posteingaenge)) {
			return state.posteingangRoot.posteingaenge;
		}
		return null;
	});

export const posteingangSelector =
	createSelector(selectFeature, (state: PosteingangRootState) => {
		const detailedPosteingang = state.posteingangRoot.posteingang;
		return isUndefined(detailedPosteingang) ? null : detailedPosteingang;
	});

export const assignedPosteingaengeSelector = createSelector(selectFeature, (state: PosteingangRootState, props) => {
	const selected = state.posteingangRoot.assignedPosteingaenge[props.assignedTo];

	if (isNil(selected)) {
		return createEmptyStateResource<PosteingangResource[]>();
	}

	if (isNull(selected.resource)) {
		return { ...selected, resource: EMPTY_ARRAY };
	}
	return {
		...selected,
		resource: <PosteingangResource[]>getEmbeddedResource(selected.resource, PosteingangListLinkRel.POSTEINGANG_LIST),
	};
});

export const documentsSelector =
	createSelector(selectFeature, (state: PosteingangRootState) => {
		if (!isNull(state.posteingangRoot.posteingang)) {
			const embDocuments: DocumentListResource = getEmbeddedResource(state.posteingangRoot.posteingang, PosteingangLinkRel.DOCUMENTS, false);
			let documents;
			if (!isNull(embDocuments)) {
				documents = getEmbeddedResource<Document[]>(embDocuments, DocumentListLinkRel.DOCUMENT_LIST, false);
			}
			return documents ? sortDocuments(documents) : null;
		}
	});

function sortByName(a: DocumentResource, b: DocumentResource): number {
	return a.name === b.name ? 0 : a.name < b.name ? -1 : 1;
}

export function sortDocuments(documentResources: DocumentResource[]): DocumentResource[] {
	let protocols = documentResources.filter(document => document.type == DokumentTyp.PROTOCOL).sort((a, b) => sortByName(a, b));
	let attachments = documentResources.filter(document => document.type == DokumentTyp.ATTACHMENT).sort((a, b) => sortByName(a, b));
	let pdfs = documentResources.filter(document => document.type == DokumentTyp.PDF_REPRESENTATION).sort((a, b) => sortByName(a, b));
	let certificates = documentResources.filter(document => document.type == DokumentTyp.CERTIFICATE).sort((a, b) => sortByName(a, b));
	let transferNotes = documentResources.filter(document => document.type == DokumentTyp.TRANSFER_NOTE).sort((a, b) => sortByName(a, b));
	return protocols.concat(attachments, pdfs, certificates, transferNotes);
}
