<div class="focus-border-dark">
	<li class="c-list__item" data-test-id="verfuegung" [ngClass]="{'c-list__item--active': (isSelected | async) === true}">
		<a class="c-list__link" data-test-id="set-selected-verfuegung"
		   [routerLink]="['/verfahren', verfahrenUri | async, { outlets: { verfuegung: getVerfuegung(), aufgabe: null } }]"
		   (click)="setSelectedVerfuegung()" [elementFocus]="(isSelected | async) === true">
			<div *ngIf="(isSelected | async)" class="visually-hidden">selektierte Verfuegung</div>
			<div class="c-row c-row--header">
				<span class="c-row__item c-row__item--header" data-test-id="datum">{{verfuegung.createdAt | formatDate}}</span>
				<span class="c-row__item c-row__item--secondary" data-test-id="status">{{verfuegungStatus[verfuegung.status]}}</span>
				<span class="visually-hidden" role="alert">{{audioAlert | async}}</span>
			</div>
			<div class="c-row c-row--secondary">
				<span class="c-row__item" data-test-id="notiz">{{verfuegung.notiz}}</span>
			</div>
			<i *ngIf="!(isSelected | async)" class="material-icons c-list__icon" aria-hidden="true" alt="Pfeil rechts">arrow_right</i>
		</a>

		<div class="c-button-bar c-button-bar--overlay">
			<schir-int-client-button-round data-test-id="open-notiz-dialog-button" (click)="openNotizDialog()"
										   popup="dialog" toolTip="Notiz bearbeiten" materialIcon="notes" cssClass="focus-box-shadow-dark">
			</schir-int-client-button-round>
			<schir-int-client-button-round *ngIf="verfuegung | hasResourceLink: linkRel.DRUCKEN"
										   data-test-id="druck-verfuegung-button" (click)="drucken()" materialIcon="print"
										   toolTip="{{typLabel()}} drucken" cssClass="focus-box-shadow-dark">
			</schir-int-client-button-round>
			<schir-int-client-button-round *ngIf="verfuegung | hasResourceLink: linkRel.MARK_AS_ERLEDIGT"
										   data-test-id="mark-as-erledigt" (click)="markAsErledigt()"
										   toolTip="{{typLabel()}} abschließen"
										   materialIcon="check" cssClass="focus-box-shadow-dark">
			</schir-int-client-button-round>
			<schir-int-client-button-round *ngIf="(verfuegung | hasResourceLink: linkRel.DELETE) && (isAdminUser() | async)"
										   data-test-id="delete-verfuegung-button" (click)="delete()"
										   toolTip="{{typLabel()}} löschen"
										   materialIcon="delete" cssClass="focus-box-shadow-dark">
			</schir-int-client-button-round>
		</div>
	</li>
</div>
