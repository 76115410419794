import {NotificationType} from '@schir-int-client/tech';

export interface DialogDataYesNo {
	message: string,
	additionalContent?: string[],
	headline?: string,
	label: string[],
	agree: boolean,
	keyboard?: boolean
}

export enum DialogButtonType {
	SAVE = 'SAVE',
	CANCEL = 'CANCEL'
}

export interface DialogDataSelection {
	message: string,
	additionalContent?: string[],
	headline?: string,
	label: string[],
	selection: number,
	buttonType: DialogButtonType[],
	disabled?: boolean[],
	keyboard?: boolean
}

export interface DialogDataAcknowledge {
	notificationType: NotificationType,
	message: string,
	buttonLabel: string,
	acknowledged: boolean
}
