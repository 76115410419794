import {Component, Inject, OnInit} from '@angular/core';
import {BaseEditorComponent, format, Selectable} from '@schir-int-client/tech';
import {FormControl, FormGroup} from '@angular/forms';
import {VerfahrenFacade, VerfahrenLinkRel, VerfahrenResource} from '@schir-int-client/verfahren-shared';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA} from '@angular/material/legacy-dialog';
import {toSelectables} from '@schir-int-client/register-shared';
import {isNil} from 'lodash-es';
import {ApiRootFacade} from '@schir-int-client/api-root';

@Component({
	selector: 'schir-int-client-standort-akte',
	templateUrl: './standort-akte.component.html',
	styleUrls: ['./standort-akte.component.scss'],
})
export class StandortAkteComponent extends BaseEditorComponent
	implements OnInit {

	private verfahren: VerfahrenResource;
	private oldStandort: string;

	readonly aktenzeichenRendered: string;
	private readonly standortAkte = 'lfpr.standort_akte';

	form: FormGroup = new FormGroup({
		standort: new FormControl(null),
		updatedAt: new FormControl(null),
	});


	public readonly standortValues: Selectable[];

	constructor(@Inject(MAT_DIALOG_DATA) data, private facade: VerfahrenFacade, private apiRootFacade: ApiRootFacade) {
		super();
		this.verfahren = data['verfahren'];
		this.aktenzeichenRendered = data['aktenzeichen'];
		this.standortValues = toSelectables(apiRootFacade.standortValuesByRegister[this.verfahren.register.toString()]);
	}

	canSafe(): boolean {
		const newStandort = this.getFormControl('standort').value;
		return !isNil(newStandort) && newStandort != this.oldStandort;
	}

	async submit(): Promise<boolean> {
		if (!this.form.valid) {
			return false;
		}
		this.facade.setVerfahrenProperty(this.verfahren, VerfahrenLinkRel.STANDORT_AKTE, this.getFormControl('standort').value);
		return true;
	}

	getFormControl(control: string) {
		return <FormControl>this.form.controls[control];
	}

	ngOnInit(): void {
		if (this.verfahren.properties) {
			this.verfahren.properties
				.filter(prop => this.standortAkte === prop.key)
				.forEach(prop => {
					this.form.controls['standort'].setValue(prop.value);
					this.form.controls['updatedAt'].setValue(format(prop.updatedAt));
					this.oldStandort = prop.value;
				});
		}
	}
}
