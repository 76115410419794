import {Component, EventEmitter, Input, Output} from '@angular/core';
import {VerfahrenLinkRel, VerfahrenResource} from '@schir-int-client/verfahren-shared';
import {hasLink} from '@ngxp/rest';
import {RegisterName} from '@schir-int-client/register-shared';

@Component({
	selector: 'schir-int-client-verfahren-single-buttons',
	templateUrl: './verfahren-single-buttons.component.html',
	styleUrls: ['./verfahren-single-buttons.component.scss'],
})
export class VerfahrenSingleButtonsComponent {

	@Input() verfahren: VerfahrenResource;

	@Output() openAmtlichenAuszugErstellenDialog: EventEmitter<void> = new EventEmitter();
	@Output() downloadSchiffszertifikat: EventEmitter<void> = new EventEmitter();
	@Output() downloadSchiffsbrief: EventEmitter<void> = new EventEmitter();
	@Output() openRegisterblatt: EventEmitter<void> = new EventEmitter();
	@Output() openNotizDialog: EventEmitter<void> = new EventEmitter();
	@Output() markAsAbgelehnt: EventEmitter<void> = new EventEmitter();
	@Output() deleteVerfahren: EventEmitter<void> = new EventEmitter();
	@Output() assignUSignal: EventEmitter<void> = new EventEmitter();

	linkRel = VerfahrenLinkRel;

	constructor() { }

	showAddUsignalButton(): boolean {
		return hasLink(this.verfahren, VerfahrenLinkRel.ASSIGN_USIGNAL) && this.verfahren.register === RegisterName.SSR;
	}
}
