import {ChangeDetectionStrategy, Component, Input, OnChanges, OnInit} from '@angular/core';
import {VerfahrenResource} from '@schir-int-client/verfahren-shared';
import {getUrl} from '@ngxp/rest';
import {VorgangLinkRel, VorgangResource} from '@schir-int-client/vorgang-shared';
import {RegisterName} from '@schir-int-client/register-shared';
import {Router} from '@angular/router';
import {
	Aktenzeichen,
	AktenzeichenRenderer,
	AktenzeichenRendererProvider,
	AktenzeichenUtilService,
} from '@schir-int-client/aktenzeichen-shared';

@Component({
	selector: 'schir-int-client-aktenzeichen',
	templateUrl: './aktenzeichen.component.html',
	styleUrls: ['./aktenzeichen.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AktenzeichenComponent implements OnChanges, OnInit {

	private aktenzeichenData: Aktenzeichen;
	private renderer: AktenzeichenRenderer;
	@Input() useLink: boolean = true;
	@Input() verfahren: VerfahrenResource;
	@Input() vorgang: VorgangResource;

	constructor(private router: Router,
	            private rendererProvider: AktenzeichenRendererProvider,
	            private aktenzeichenUtil: AktenzeichenUtilService) {
		this.renderer = this.rendererProvider.getRenderer();
	}

	ngOnInit(): void {
		if (this.verfahren) {
			this.setPropertiesByVerfahren();
		} else if (this.vorgang) {
			this.setPropertiesByVorgang();
		}
	}

	ngOnChanges(): void {
		if (this.verfahren) {
			this.setPropertiesByVerfahren();
		} else if (this.vorgang) {
			this.setPropertiesByVorgang();
		}
	}


	get aktenzeichen(): string {
		return this.renderer.render(this.aktenzeichenData);
	}

	hasRegisterBlatt() {
		if (this.verfahren) {
			return this.verfahren.register != RegisterName.AR;
		}
		return !this.vorgang.aktenzeichen.includes(RegisterName.AR);
	}

	openRegisterblatt(e: Event) {
		e.stopPropagation();
		this.router.navigate(['/verfahrenDetail', this.getUrl()]);
	}

	getUrl() {
		if (this.verfahren) {
			return btoa(getUrl(this.verfahren));
		} else {
			return btoa(getUrl(this.vorgang, VorgangLinkRel.VERFAHREN));
		}
	}

	private setPropertiesByVerfahren(): void {
		this.aktenzeichenData = this.aktenzeichenUtil.parse(this.verfahren);
	}

	private setPropertiesByVorgang(): void {
		const aktenzeichen = this.vorgang.aktenzeichen;
		this.aktenzeichenData = this.aktenzeichenUtil.parse(aktenzeichen);
	}
}
