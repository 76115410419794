<fieldset class="grid" *ngIf="formGroup.enabled" data-test-id="aufgabe-zertifikat-root">
	<legend aria-label="Einstellungen zu Zertifikat / Schiffsbrief"></legend>
	<div class="row" [formGroup]="bodyGroup">
		<div class="column">
			<mat-radio-group data-test-id="aufgabe-zertifikat-taetigkeit" aria-label="Bitte Aktion wählen" class="column"
				[formControlName]="formServiceClass.feldZertifikatTaetigkeit">
				<mat-radio-button color="primary" class="focus-box-shadow-dark"  data-test-id="fertigen-radio-button"
					[value]="taetigkeiten.FERTIGEN">fertigen</mat-radio-button>
				<mat-radio-button color="primary" class="focus-box-shadow-dark" data-test-id="berichtigen-radio-button"
					[value]="taetigkeiten.BERICHTIGEN">berichtigen</mat-radio-button>
				<mat-radio-button color="primary" class="focus-box-shadow-dark" data-test-id="nach-eingang-berichtigen-radio-button"
					[value]="taetigkeiten.NACH_EINGANG_BERICHTIGEN">
					nach Eingang berichtigen</mat-radio-button>
			</mat-radio-group>
			<mat-radio-group data-test-id="aufgabe-zertifikat-versandtaetigkeit" aria-label="Bitte Versandaktion wählen" class="column indented"
							 [formControlName]="formServiceClass.feldZertifikatVersandTaetigkeit">
				<mat-radio-button color="primary" class="focus-box-shadow-dark" data-test-id="senden-an-radio-button"
								  [value]="versandTaetigkeit.SENDEN_AN">senden an</mat-radio-button>
				<mat-radio-button color="primary" class="focus-box-shadow-dark" data-test-id="aushaendigen-an-radio-button"
								  [value]="versandTaetigkeit.AUSHAENDIGEN_AN">
					aushändigen an</mat-radio-button>
			</mat-radio-group>
		</div>
		<schir-int-client-kontakt-kategorie-checkboxes class="column" data-test-id="kontakt-kategorie-checkbox-root"
			[control]="bodyGroup.controls[formServiceClass.feldZertifikatSendenAn]">
		</schir-int-client-kontakt-kategorie-checkboxes>
	</div>
	<schir-int-client-notiz-in-aufgabe-editor class="row note" data-test-id="aufgabe-zertifikat-notiz"
		[aufgabenTyp]="aufgabenTyp.ZERTIFIKAT">
	</schir-int-client-notiz-in-aufgabe-editor>
</fieldset>
