import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {getUrl, hasLink, Resource, ResourceFactory, ResourceUri, UriListRequestBody} from '@ngxp/rest';
import {ApiRootLinkRel, ApiRootResource} from '@schir-int-client/api-root';
import {VerfahrenLinkRel} from '@schir-int-client/verfahren-shared';
import {saveAs} from 'file-saver';
import {isNil} from 'lodash-es';
import {Observable} from 'rxjs';
import {SignatureTaskLinkRel, SignaturLinkRel} from './signatur.linkrel';
import {
	SignatureResource,
	SignatureValidatorResponseListResource,
	SignatureValidatorResponseResource,
	SignaturTaskResource,
} from './signatur.model';
import {ChangeEntryListLinkRel, ChangeEntryListResource} from '@schir-int-client/register-change';

@Injectable()
export class SignaturService {
	constructor(
		private resourceFactory: ResourceFactory,
		private httpClient: HttpClient) {}

	create(resource: Resource, changeEntries: ResourceUri[]): Observable<SignaturTaskResource> {
		return this.resourceFactory.from(resource).post(VerfahrenLinkRel.CREATE_SIGNATUR_TASK, new UriListRequestBody(changeEntries));
	}

	download(resource: Resource): Observable<SignaturTaskResource> {
		return this.resourceFactory.from(resource).get();
	}

	loadSignatureTaskFromRegisterEntries(entryList: ChangeEntryListResource): Observable<SignaturTaskResource> {
		return this.resourceFactory.from(entryList).get(ChangeEntryListLinkRel.PENDING_SIGNATURE_TASK);
	}

	downloadSignerScript(signaturTask: SignaturTaskResource) {
		let headers = new HttpHeaders();
		headers = headers.set('Accept', 'text/secsignerwr');
		if (hasLink(signaturTask, SignaturLinkRel.SIGNER_SCRIPT)) {
			this.httpClient.get<Blob>(
				getUrl(signaturTask, SignaturLinkRel.SIGNER_SCRIPT),
				{ headers, responseType: 'blob' as 'json' }).subscribe(
				data => {
					if (!isNil(data)) {
						saveAs(data, 'secSignerSign.secsignerwr');
					}
				},
			);
		}
	}

	cancelSignature(task: SignaturTaskResource): Observable<SignaturTaskResource> {
		return this.resourceFactory.from(task).patch(SignatureTaskLinkRel.CANCEL, { status: 'CANCELED' });
	}

	downloadSignature(resource: Resource): Observable<SignatureResource> {
		return this.resourceFactory.from(resource).get(SignaturLinkRel.DOWNLOAD_SIGNATURE);
	}

	loadGlobalSignatureFailures(apiRoot: ApiRootResource): Observable<SignatureValidatorResponseListResource> {
		return this.resourceFactory.from(apiRoot).get(ApiRootLinkRel.SIGNATURE_VALIDATOR_RESPONSES);
	}

	loadSignatureValidatorResponses(entriesList: ChangeEntryListResource): Observable<SignatureValidatorResponseResource> {
		return this.resourceFactory.from(entriesList).get(ChangeEntryListLinkRel.SIGNATURE_VALIDATOR_RESPONSES);
	}
}
