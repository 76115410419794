import {Action} from '@ngrx/store';
import {getEmbeddedResource, getUrl} from '@ngxp/rest';
import {LoadRechtspflegerSuccessAction, RechtspflegerActions} from './rechtspfleger/rechtspfleger.actions';
import {LoadUserSuccessAction, UserActions, UserLoadedAction} from './user.actions';
import {UserListLinkRel} from './user.linkrel';
import {UserListResource, UserResource} from './user.model';

export interface UserState {
	user: UserResource;
	rechtspfleger: UserListResource;
	users: {
		[userId: string]: UserResource;
	};
}

export const initialState: UserState = {
	user: undefined,
	rechtspfleger: null,
	users: null,
};

export function userReducer(state: UserState = initialState, action: Action): UserState {
	switch (action.type) {
		case UserActions.USER_LOADED:
			return {
				...state,
				user: (<UserLoadedAction>action).user,
			};
		case UserActions.LOGGED_OUT:
			return {
				...state,
				user: undefined,
			};
		case UserActions.IS_UNAUTHORIZED:
			return {
				...state,
				user: undefined,
			};
		case RechtspflegerActions.LOAD_RECHTSPFLEGER_SUCCESS:
			const userList = (<LoadRechtspflegerSuccessAction>action).rechtspflegerList;
			return {
				...state,
				rechtspfleger: userList,
				users: fillUsersMap(<UserResource[]>getEmbeddedResource(userList, UserListLinkRel.USER_LIST), state.users),
			};
		case UserActions.LOAD_USER_SUCCESS:
			const user = (<LoadUserSuccessAction>action).user;
			return {
				...state,
				users: { ...state.users, [getUrl(user)]: user },
			};
		default:
			return state;
	}
}

function fillUsersMap(users: UserResource[], existingData: { [userId: string]: UserResource }): { [userId: string]: UserResource } {
	return users.reduce((prev, curr) => { return { ...prev, [getUrl(curr)]: curr }; }, existingData);
}
