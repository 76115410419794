import {Component, Input, OnChanges, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {UntypedFormControl} from '@angular/forms';
import {Observable, of, Subscription} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import {Selectable} from '@schir-int-client/tech';
import {isNil} from 'lodash-es';
import {MatLegacyAutocompleteTrigger as MatAutocompleteTrigger} from '@angular/material/legacy-autocomplete';
import {FreigabeText, WeitereFreigabeText} from '@schir-int-client/register-shared';
import {TextbausteinFreigabetextService} from '../../../../textbaustein/src/lib/textbaustein/textbaustein-freigabetext.service';


@Component({
	selector: 'schir-int-client-autocomplete',
	templateUrl: './autocomplete.component.html',
	styleUrls: ['./autocomplete.component.scss'],
})
export class AutocompleteComponent implements OnChanges, OnInit, OnDestroy {

	@Input() label: string;
	@Input() control: UntypedFormControl;
	@Input() values: Selectable[] = [];
	@Input() defaultValue: Selectable;
	@Input() initialFocus: string;
	@Input() freigabeTextMap: Map<number, FreigabeText | WeitereFreigabeText>;
	@Input() showAllAfterSelect: boolean = false;

	@ViewChild(MatAutocompleteTrigger) _auto: MatAutocompleteTrigger;

	filteredOptions: Observable<Selectable[]> = of(null);

	private subscription: Subscription;

	constructor(private textbausteinService: TextbausteinFreigabetextService) {}

	ngOnChanges() {
		this.filteredOptions = this.control.valueChanges.pipe(
			startWith(''),
			map(value => this.filterSelectables(<any>value)),
		);
	}

	ngOnInit() {
		if (this.defaultValue && this.control) {
			this.control.setValue(this.defaultValue.label);
		}

		if (this.freigabeTextMap) {
			this.subscription = this.createTextbausteinSubscription();
		}
	}

	ngOnDestroy(): void {
		if (this.subscription) {
			this.subscription.unsubscribe();
		}
	}

	private createTextbausteinSubscription() {
		return this.textbausteinService.textbausteinSelectedObservable.subscribe((textbausteinId: number) => {
			if (!isNil(textbausteinId)) {
				if (this.freigabeTextMap) {
					const selection = this.freigabeTextMap.get(textbausteinId);
					const i = this.values.findIndex(value => value.label == selection);

					//trigger reset of filteredOptions to show all of them
					this.control.setValue('');

					setTimeout(() => {
						const availableOptions = this._auto.autocomplete.options.toArray();

						if (availableOptions[i]) {
							this.control.setValue(availableOptions[i].value, { emitEvent: false });
						}
					}, 100);
				}
			}
		});
	}

	private filterSelectables(value: string): Selectable[] {
		const filterValue = value ? value.toLowerCase() : '';

		if (this.showAllAfterSelect) {
			if (this.values.filter(option => option.label.toLowerCase() === filterValue).length > 0) {
				return this.values;
			}
		}

		return this.values.filter(option => option.label.toLowerCase().indexOf(filterValue) === 0);
	}

	hasInitialFocus(): boolean {
		return this.initialFocus === 'true';
	}
}
