import {HttpResponse} from '@angular/common/http';
import {OperatorFunction} from 'rxjs';
import {map} from 'rxjs/operators';

const FILE_NAME_KEY = 'filename=';

export const BlobToFileMapper: OperatorFunction<HttpResponse<Blob>, DownloadedFile> = map((response: HttpResponse<Blob>) => mapBlobToFile(response));

export function mapBlobToFile(response: HttpResponse<Blob>): DownloadedFile {
	const fileName = extractFileNameFromContentDisposition(response);

	return { name: fileName, blob: response.body };
}

function extractFileNameFromContentDisposition(response: HttpResponse<any>) {
	if (response.headers) {
		const contentDisposition = response.headers.get('content-disposition');

		if (contentDisposition) {
			// Extract the file name from content-disposition
			const indexOfFileName = contentDisposition.toLowerCase().indexOf(FILE_NAME_KEY);

			if (indexOfFileName > 0) {
				const part = contentDisposition.substr(indexOfFileName + FILE_NAME_KEY.length);

				const indexOfSemicolon = part.indexOf(';');
				let fileName;

				if (indexOfSemicolon > 0) {
					fileName = part.substr(0, indexOfSemicolon);
				} else {
					fileName = part;
				}

				return fileName.replaceAll('"', '').trim();
			}
		}
	}

	return null;
}

export interface DownloadedFile {
	readonly blob: Blob;
	readonly name: string;
}
