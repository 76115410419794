import {Action} from '@ngrx/store';
import {Resource} from '@ngxp/rest';
import {createEmptyStateResource, createStateResource, StateResource} from '@schir-int-client/ngrx-helpers';
import {VerfahrenActions} from '@schir-int-client/verfahren-shared';
import {
	LoadChangeEntriesSuccessAction,
	RegisterBlattChangeActions,
} from '../../../register-change/src/lib/register-change.actions';
import {RegisterBlattActions} from './register.actions';

export interface ChangeEntryListResource extends Resource {}

export interface RegisterBlattState {
	changeEntries: StateResource<ChangeEntryListResource>;
}

export const initialState: RegisterBlattState = { changeEntries: createEmptyStateResource() };

export function registerBlattReducer(state: RegisterBlattState = initialState, action: Action): RegisterBlattState {
	switch (action.type) {
		case RegisterBlattActions.SAVE_REGISTERBLATT_CHANGE: // TODO: SCHIFF-5004
			return {
				...state,
			};
		case RegisterBlattChangeActions.LOAD_CHANGE_ENTRIES:
			return {
				...state,
				changeEntries: createEmptyStateResource(true),
			};
		case RegisterBlattChangeActions.LOAD_CHANGE_ENTRIES_SUCCESS:
			return {
				...state,
				changeEntries: createStateResource((<LoadChangeEntriesSuccessAction>action).resources),
			};
		case VerfahrenActions.LOAD_VERFAHREN_SINGLE_SUCCESS:
			return {
				...state,
				changeEntries: { ...state.changeEntries, reload: true },
			};
		case RegisterBlattChangeActions.REFRESH_STATE:
			// Forces editors to reload data into input.fields
			// on cancel
			return {
				...state,
			};
		default:
			return state;
	}
}
