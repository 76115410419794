import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {EffectsModule} from '@ngrx/effects';
import {BinaryEffects} from './binary.effects';

@NgModule({
	imports: [
		CommonModule,
		EffectsModule.forFeature([BinaryEffects]),
	],
})
export class BinarySharedModule {}
