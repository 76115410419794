import {Component} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {AufgabenTyp, AufgabeRechnungsstellungErledigung} from '@schir-int-client/aufgabe-shared';
import {CreateAufgabeFormService} from '../../create-aufgabe-dialog/create-aufgabe.formservice';

@Component({
	selector: 'schir-int-client-aufgabe-rechnungsstellung-editor',
	templateUrl: './aufgabe-rechnungsstellung-editor.component.html',
	styleUrls: ['./aufgabe-rechnungsstellung-editor.component.scss'],
})
export class AufgabeRechnungsstellungEditorComponent {

	readonly formServiceClass = CreateAufgabeFormService;
	readonly aufgabenTyp = AufgabenTyp.RECHNUNGSSTELLUNG;
	readonly erledigung = AufgabeRechnungsstellungErledigung;

	constructor(private formService: CreateAufgabeFormService) { }

	get formGroup(): UntypedFormGroup {
		return <UntypedFormGroup>this.formService.controlGroupRechnungsstellung;
	}

	get bodyGroup(): UntypedFormGroup {
		return <UntypedFormGroup>this.formGroup.controls[CreateAufgabeFormService.feldAufgabeBody];
	}
}
