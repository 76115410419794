import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {getUrl, ResourceUri} from '@ngxp/rest';
import {saveAs} from 'file-saver';
import {AdressatLinkRel, AdressatResource, AufgabeLinkRel, AufgabeResource} from '@schir-int-client/aufgabe-shared';
import {BlobToFileMapper} from '@schir-int-client/tech';

@Injectable()
export class KorrespondenzService {
	constructor(private httpClient: HttpClient) { }

	public downloadKorrespondenz(adressat: AdressatResource) {
		const url: ResourceUri = getUrl(adressat, AdressatLinkRel.DOWNLOAD_KORRESPONDENZ);
		let headers = new HttpHeaders();
		headers = headers.set('Accept', 'application/pdf, application/hal+json');

		return this.httpClient.get<Blob>(url, { headers, responseType: 'blob' as 'json' });
	}

	public downloadKorrespondenzenArchiv(aufgabe: AufgabeResource) {
		const url: ResourceUri = getUrl(aufgabe, AufgabeLinkRel.DOWNLOAD_KORRESPONDENZEN_ARCHIV);
		let headers = new HttpHeaders();
		headers = headers.set('Accept', 'application/zip, application/octet-stream');

		return this.httpClient.get<Blob>(url, { headers, responseType: 'blob' as 'json', observe: 'response' })
			.pipe(BlobToFileMapper);
	}

	public downloadKorrespondenzenMerge(aufgabe: AufgabeResource) {
		const url: ResourceUri = getUrl(aufgabe, AufgabeLinkRel.DOWNLOAD_KORRESPONDENZEN_MERGE);
		let headers = new HttpHeaders();
		headers = headers.set('Accept', 'application/zip, application/octet-stream');

		return this.httpClient.get<Blob>(url, { headers, responseType: 'blob' as 'json', observe: 'response' })
			.pipe(BlobToFileMapper);
	}

	public saveFile(data: Blob, fileName: string) {
		saveAs(data, fileName);
	}
}
