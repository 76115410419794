import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';

/**
 * Informiert Subscriber mittels Observable über aktualisierten Error-Status und generiert die dazugehörigen Meldungen zur Anzeige in der component.
 */
@Injectable({
	providedIn: 'root',
})
export class ErrorService {

	private subject = new BehaviorSubject<boolean>(false);

	readonly region_message = 'Es tut uns leid, aber Sie besitzen nicht die notwendigen Berechtigungen, um sich am Schiffsregister anzumelden.';
	readonly default_message = 'Es tut uns leid, aber es ist ein unerwarteter Fehler aufgetreten.';

	private message: string;

	getObservable(): Observable<boolean> {
		return this.subject.asObservable();
	}

	updateErrorStatus(type: string) {
		switch (type) {
			case ErrorType.Region:
				this.message = this.region_message;
				break;
			default:
				this.message = this.default_message;
				break;
		}

		// informiert die subscriber über das Vorliegen eines Fehlers
		this.subject.next(true);
	}

	getErrorMessage(): string {
		return this.message;
	}

}

const enum ErrorType {
	Region = 'region',
}
