const X2JS = require('x2js');
const x2js = new X2JS({
	attributePrefix: "$"
});

export function XmlUtil() { }

XmlUtil.obj2Xml = function (obj) {
	return '<?xml version="1.0" standalone="yes"?>' + x2js.js2xml(obj);
}

XmlUtil.xml2Obj = function (xml) {
	return x2js.xml2js(xml);
}

XmlUtil.rootElementName = function (xml) {
	const keys = Object.keys(XmlUtil.xml2Obj(xml))
	return keys[0];
}
