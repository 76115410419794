import {Injectable} from '@angular/core';
import {EipConfig} from '@schir-int-client/api-root';
import {EipConnection} from './eip.connection';
import {EipContext} from './eip.context';
import {EipLawsuit} from './eip.lawsuit';
import {EipMessages} from './eip.messages';
import {EipStorage} from './eip.storage';

@Injectable({ providedIn: 'root' })
export class EipRegistration {

	private config: EipConfig;
	private started: boolean = false;

	constructor(
		private context: EipContext,
		public storage: EipStorage,
		private connection: EipConnection,
		private eipLawsuit: EipLawsuit) {

		connection.addMessageListener((messageName: string, message: any, id: string) => {
			this.onMessage(messageName, message, id);
		});
	}

	public isConfigured(): boolean {
		return this.storage.isConfigured();
	}

	public isStarted(): boolean {
		return this.started;
	}

	public start(config: EipConfig) {
		this.started = true;
		this.config = config;
		this.connection.setRegistrated(false);
		this.connection.open(() => {
			this.sendRegisterApplicationRequest();
		});
	}

	private sendRegisterApplicationRequest() {
		this.connection.sendMessage(EipMessages.registerApplicationRequest(this.config.clientId, this.storage.getClientInstanceId()));
	}

	public sendClosePartRequest() {
		const closePartRequest = EipMessages.closePartRequest(this.connection.getPartInstanceIdentifier());
		this.connection.sendMessage(closePartRequest);
	}

	onMessage(messageName: string, messageObj: any, id: string) {
		switch (messageName) {
			case 'RegisterApplicationResponse':
				this.onRegisterApplicationResponse();
				break;
			case 'ApplicationReadyResponse':
				this.onApplicationReadyResponse();
				break;
			case 'LoginRequest':
				this.onLoginRequest(id);
				break;
			case 'GetCourtForOrganizationEntityRequest':
				this.onGetCourtForOrganizationEntityRequest(id);
				break;
			case 'ShowViewRequest':
				this.onShowViewRequest(id, messageObj);
				break;
			case 'AddNewPartResponse':
				this.onAddNewPartResponse(messageObj);
				break;
			default:
				console.log('eIP-Registration: unknown message type: ' + messageName);
		}
	}

	private onRegisterApplicationResponse() {
		this.connection.sendMessage(EipMessages.applicationReadyRequest(this.config.clientId, this.storage.getClientInstanceId()));
	}

	private onApplicationReadyResponse() {
		this.eipLawsuit.init();
	}

	private onLoginRequest(id: string) {
		const loginResponse: string = EipMessages.loginResponse(this.context.currentUser());
		this.connection.sendMessageWithId(id, loginResponse);
	}

	private onGetCourtForOrganizationEntityRequest(id: string) {
		const courtResponse = EipMessages.getCourtForOrganizationEntityResponse(this.config);
		this.connection.sendMessageWithId(id, courtResponse);
	}

	private onShowViewRequest(id: string, messageObj: any) {
		const windowHandle = messageObj.ShowViewRequest.Parameter.Value.toString();
		this.connection.sendMessage(EipMessages.addNewPartRequest(windowHandle));
		// laut doku erst auf AddNewPartResponse warten
		this.connection.sendMessageWithId(id, EipMessages.showViewResponse());
	}

	private onAddNewPartResponse(messageObj: any) {
		this.storage.clear();
		this.connection.setRegistrated(true);
		this.connection.setPartInstanceIdentifier(messageObj.AddNewPartResponse.Result.PartInstanceIdentifier.toString());
	}

}
