import {Resource} from '@ngxp/rest';
import {faker} from '@faker-js/faker';
import {isUndefined, times, zipObject} from 'lodash-es';

// @ts-ignore
export function toResource<R = T & Resource, T = any>(
	model: T,
	linkRelations: string[] = [],
	embeddedResources?: { [key: string]: Resource | Resource[] },
): R {
	const resource: Resource = {
		// TODO remove casting when TS issue is resolved
		// see https://github.com/Microsoft/TypeScript/issues/14409
		// see https://github.com/Microsoft/TypeScript/issues/10727
		...(<any>model),
		_links: {
			self: {
				href: faker.internet.url(),
			},
			...zipObject(
				linkRelations,
				times(linkRelations.length, () => ({
					href: faker.internet.url(),
				})),
			),
		},
	};

	if (!isUndefined(embeddedResources)) {
		resource._embedded = embeddedResources;
	}

	return <any>resource;
}
