import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import {hasLink} from '@ngxp/rest';
import {apiRoot, ApiRootFacade, ApiRootLinkRel} from '@schir-int-client/api-root';
import {isNil} from 'lodash-es';
import {combineLatest, Observable} from 'rxjs';
import {filter, map, share, tap} from 'rxjs/operators';
import {
	AskToDeleteUnterscheidungssignalAction,
	ClearErrorAction,
	LoadAdminRootAction,
	LoadUSignaleAction,
	UploadUSignaleAction,
} from './admin.actions';
import {
	AdminRootResource,
	UnprocessableEntityError,
	UnterscheidungssignalListResource,
	UnterscheidungssignalResource,
} from './admin.model';
import {AdminState} from './admin.reducer';
import {
	adminRootSelector,
	freieUnterscheidungssignaleCountSelector,
	unterscheidungssignaleSelector,
	usignalErrorSelector,
} from './admin.selectors';
import {doIfLoadingRequired, StateResource} from '@schir-int-client/ngrx-helpers';

@Injectable({ providedIn: 'root' })
export class AdminFacade {
	constructor(private store: Store<AdminState>, private apiRootFacade: ApiRootFacade) {}

	adminRoot: Observable<AdminRootResource> = this.getAdminRoot();

	private getAdminRoot(): Observable<AdminRootResource> {
		return combineLatest([this.store.select(<any>apiRoot), this.store.select(adminRootSelector)]).pipe(
			tap(([apiRoot, adminRoot]) => {
				if (isNil(adminRoot) && !isNil(apiRoot) && hasLink(apiRoot, ApiRootLinkRel.ADMIN)) {
					this.store.dispatch(new LoadAdminRootAction());
				}
			}),
			share(),
			map(([, adminRoot]) => adminRoot ? adminRoot : null),
		);
	}

	readonly unterscheidungssignale: Observable<StateResource<UnterscheidungssignalListResource>> = this.store.select(unterscheidungssignaleSelector).pipe(
		filter(resource => !doIfLoadingRequired(resource, () => this.store.dispatch(new LoadUSignaleAction()))),
	);

	readonly freieUnterscheidungssignaleCount: Observable<number> = this.store.select(freieUnterscheidungssignaleCountSelector);

	readonly usignalError: Observable<UnprocessableEntityError> = this.store.select(usignalErrorSelector);

	uploadUnterscheidungssignale(file: File): void {
		this.store.dispatch(new UploadUSignaleAction(file));
	}

	loadUnterscheidungssignale() {
		this.store.dispatch(new LoadUSignaleAction());
	}

	clearErrorMsg() {
		this.store.dispatch(new ClearErrorAction());
	}

	deleteUnterscheidungssignal(usignal: UnterscheidungssignalResource) {
		this.store.dispatch(new AskToDeleteUnterscheidungssignalAction(usignal));
	}

}
