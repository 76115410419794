<div class="message-container">
	<div class="message-header">
		<h2>Fehler</h2>
		<hr>
	</div>
	<div class="message-body">
		{{ message | async }}
	</div>
	<div class="message-footer mat-dialog-content">
		<a href={{loginUrl}}>Login</a>
	</div>
</div>
