import {Component, Input} from '@angular/core';
import {AufgabeResource, AufgabeWiedervorlageBody} from '@schir-int-client/aufgabe-shared';

@Component({
	selector: 'schir-int-client-aufgabe-wiedervorlage-body',
	templateUrl: './aufgabe-wiedervorlage-body.component.html',
	styleUrls: ['./aufgabe-wiedervorlage-body.component.scss'],
})
export class AufgabeWiedervorlageBodyComponent {

	@Input() aufgabe: AufgabeResource;

	constructor() { }

	get body(): AufgabeWiedervorlageBody {
		return <AufgabeWiedervorlageBody>this.aufgabe.body;
	}

	get wiedervorlageDatum(): Date {
		return this.body.wiedervorlageDatum;
	}
}
