import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {getUrl, Resource, ResourceFactory} from '@ngxp/rest';
import {ApiRootLinkRel} from '@schir-int-client/api-root';
import {saveAs} from 'file-saver';
import {isNil} from 'lodash-es';
import {Observable} from 'rxjs';
import {DocumentLinkRel, PosteingangLinkRel} from './posteingang.linkrel';
import {DocumentResource, PosteingangListResource, PosteingangResource} from './posteingang.model';

@Injectable({ providedIn: 'root' })
export class PosteingangService {
	constructor(private resourceFactory: ResourceFactory, private httpClient: HttpClient) { }

	public getAll(rootResource: Resource): Observable<PosteingangListResource> {
		return this.resourceFactory.from(rootResource).get(ApiRootLinkRel.POSTEINGANGS);
	}

	public getOne(posteingangUri: string): Observable<PosteingangResource> {
		return this.resourceFactory.fromId(posteingangUri).get();
	}

	public delete(posteingang: PosteingangResource): Observable<PosteingangResource[]> {
		return this.resourceFactory.from(posteingang).delete(PosteingangLinkRel.DELETE);
	}

	downloadDocument(document: DocumentResource): void {
		let headers = new HttpHeaders();
		headers = headers.set('Accept', 'application/*');
		const url = getUrl(document, DocumentLinkRel.DOWNLOAD);

		this.httpClient.get<Blob>(url, { headers, responseType: 'blob' as 'json' })
			.subscribe(data => {
				if (!isNil(data)) {
					saveAs(data, document.name);
				}
			});
	}

	loadAssignedPosteingaenge(resource: Resource, linkRel: string): Observable<PosteingangListResource> {
		return this.resourceFactory.from(resource).get(linkRel);
	}
}
