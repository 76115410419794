import {Injectable} from '@angular/core';
import {getUrl, hasLink, Resource, ResourceFactory, ResourceUri} from '@ngxp/rest';
import {ApiRootLinkRel, ApiRootResource} from '@schir-int-client/api-root';
import {PosteingangLinkRel, PosteingangResource} from '@schir-int-client/posteingang-shared';
import {VerfahrenLinkRel, VerfahrenResource} from '@schir-int-client/verfahren-shared';
import {Observable} from 'rxjs';
import {VorgangLinkRel} from './vorgang.linkrel';
import {
	CreateVorgang,
	ProposedDate,
	VorgangListResource,
	VorgangResource,
	VorgangStatus,
	VorgangTyp,
} from './vorgang.model';

@Injectable({ providedIn: 'root' })
export class VorgangService {
	constructor(private resourceFactory: ResourceFactory) { }

	public createVorgangInVerfahren(createVorgangUri: ResourceUri, posteingaenge: ResourceUri[], vorgangType: VorgangTyp): Observable<VorgangListResource> {
		const newVorgang: CreateVorgang = { posteingaenge, typ: vorgangType };
		return this.resourceFactory.fromId(createVorgangUri).post(newVorgang);
	}

	public getAllForRechtspflege(rootResource: Resource): Observable<VorgangListResource> {
		return this.resourceFactory.from(rootResource).get(ApiRootLinkRel.VORGAENGE_RECHTSPFLEGE);
	}

	/** @deprecated prefer using getByLink */
	public getOne(url: string): Observable<VorgangResource> {
		return this.resourceFactory.fromId(url).get();
	}

	public getByLink(resource: Resource, linkRel: string): Observable<VorgangResource> {
		return this.resourceFactory.from(resource).get(linkRel);
	}

	public getZugeordneteVorgaenge(posteingangResource: PosteingangResource): Observable<VorgangListResource> {
		return this.resourceFactory.from(posteingangResource).get(PosteingangLinkRel.ZUGEORDNETE_VORGAENGE);
	}

	public getVorgaengeByVerfahren(verfahren: VerfahrenResource): Observable<VorgangListResource> {
		return this.resourceFactory.from(verfahren).get(VerfahrenLinkRel.VORGAENGE);
	}

	public assignVorgang(zugeordneterVorgang: VorgangResource, rechtspfleger: string, status: VorgangStatus): Observable<{}> {
		return this.resourceFactory.from(zugeordneterVorgang).patch(VorgangLinkRel.ASSIGN_TO_RECHTSPFLEGE, {
			rechtspfleger,
			status,
		});
	}

	public loadVorgaengeInRuecklauf(apiRoot: ApiRootResource): Observable<VorgangListResource> {
		return this.resourceFactory.from(apiRoot).get(ApiRootLinkRel.VORGAENGE_IN_RUECKLAUF);
	}

	public loadVorgaengeInWiedervorlage(apiRoot: ApiRootResource): Observable<VorgangListResource> {
		return this.resourceFactory.from(apiRoot).get(ApiRootLinkRel.WIEDERVORLAGEN);
	}

	public assignVorgangToPosteingang(posteingangResource: PosteingangResource, vorgangResource: VorgangResource): Observable<VorgangResource> {
		return this.resourceFactory.fromId(getUrl(vorgangResource, VorgangLinkRel.ASSIGN_POSTEINGANG)).patch({ posteingaenge: [getUrl(posteingangResource)] });
	}

	public updateNotiz(notiz: string, vorgangUri: ResourceUri): Observable<VorgangResource> {
		return this.resourceFactory.fromId(vorgangUri).patch({ notiz });
	}

	public markAsRechtspflege(vorgang: VorgangResource): Observable<VorgangResource> {
		return this.resourceFactory.from(vorgang).patch(VorgangLinkRel.MARK_AS_RECHTSPFLEGE, { status: VorgangStatus.RECHTSPFLEGE });
	}

	public markAsRuecklauf(vorgang: VorgangResource): Observable<VorgangResource> {
		return this.resourceFactory.from(vorgang).patch(VorgangLinkRel.MARK_AS_RUECKLAUF, { status: VorgangStatus.RUECKLAUF });
	}

	public markAsAbgeschlossen(vorgang: VorgangResource): Observable<VorgangResource> {
		return this.resourceFactory.from(vorgang).patch(VorgangLinkRel.MARK_AS_ABGESCHLOSSEN, { status: VorgangStatus.ABGESCHLOSSEN });
	}

	public delete(vorgang: VorgangResource): Observable<void> {
		return this.resourceFactory.from(vorgang).delete(VorgangLinkRel.DELETE);
	}

	getProposedWiederVorlageDatum(vorgang: VorgangResource): Observable<ProposedDate> {
		return this.resourceFactory.from(vorgang).get(VorgangLinkRel.PROPOSE_WIEDERVORLAGEDATUM);
	}

	getAllowedNextStatus(vorgang: VorgangResource): VorgangStatus[] {
		return Object.keys(this.linkRelByStatus)
			.filter(status => hasLink(vorgang, this.linkRelByStatus[status]) || vorgang.status == status)
			.map(value => <VorgangStatus>value);
	}

	linkRelByStatus: { [status: string]: VorgangLinkRel } = {
		[VorgangStatus.RECHTSPFLEGE]: VorgangLinkRel.MARK_AS_RECHTSPFLEGE,
		[VorgangStatus.RUECKLAUF]: VorgangLinkRel.MARK_AS_RUECKLAUF,
		[VorgangStatus.ABGESCHLOSSEN]: VorgangLinkRel.MARK_AS_ABGESCHLOSSEN,
		[VorgangStatus.WIEDERVORLAGE]: VorgangLinkRel.CREATE_WIEDERVORLAGE,
	};
}
