<div class="x-section" data-test-id="ruecklauf-root">
	<div class="x-section__headline">
		<i class="material-icons" aria-hidden="true">settings_backup_restore</i>
		<h2 class="mat-h1 margin-right-auto" tabindex="0">{{headline}}</h2>
	</div>
	<div class="x-section__subline"></div>
	<div class="x-section__main">
		<ul class="c-list" *ngIf="vorgaenge">
			<li class="c-list__item focus-border-dark" data-test-id="eintrag" *ngFor="let vorgang of vorgaenge">
				<a class="c-list__link" data-test-id="eintrag-link" (click)="goToVorgang(vorgang)" (keyup.enter)="goToVorgang(vorgang)" tabindex="0">
					<div class="c-row c-row--header c-wrap">
						<schir-int-client-aktenzeichen data-test-id="aktenzeichen" class="c-row__item c-row__item--header" [vorgang]="vorgang"></schir-int-client-aktenzeichen>
						<span class="c-row__item c-row__item--secondary" data-test-id="vorgang-status">{{vorgang.status | enumToLabel: vorgangStatusLabel }}</span>
					</div>
					<div class="c-row c-wrap">
						<span class="c-row__item"
							data-test-id="vorgang-typ">{{vorgang.typ | enumToLabel: vorgangsTypen }}</span>
						<span class="c-row__item" data-test-id="vorgang-datum">{{vorgang.ruecklaufDatum | formatDate }}</span>
					</div>
					<div class="c-row c-row--secondary">
						<span class="c-row__item" data-test-id="vorgang-notiz">{{vorgang.notiz}}</span>
					</div>
					<div *ngIf="vorgang.standortAkte" class="c-row c-row--secondary">
						<span class="c-row__item" data-test-id="vorgang-standortAkte">Standort Akte: {{vorgang.standortAkte}}</span>
					</div>
				</a>
				<div class="c-button-bar c-button-bar--overlay">
					<schir-int-client-button-round data-test-id="open-status-dialog-button" (click)="openStatusDialog(vorgang)"
												   popup="dialog" materialIcon="send" toolTip="Status des Vorgangs setzen"
												   cssClass="focus-box-shadow-dark">
					</schir-int-client-button-round>
				</div>
			</li>
		</ul>
	</div>
</div>
