import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {EffectsModule} from '@ngrx/effects';
import {StoreModule} from '@ngrx/store';
import {VerfuegungEffects} from './verfuegung.effects';
import {getVerfuegungReducer, verfuegungFeatureState, verfuegungReducerInjectionToken} from './verfuegung.state';

@NgModule({
	imports: [
		CommonModule,
		EffectsModule.forFeature([VerfuegungEffects]),
		StoreModule.forFeature(verfuegungFeatureState, verfuegungReducerInjectionToken),
	],
	providers: [
		{
			provide: verfuegungReducerInjectionToken,
			useFactory: getVerfuegungReducer,
		},
	],
})
export class VerfuegungSharedModule {}
