<fieldset class="grid" *ngIf="formGroup.enabled" data-test-id="aufgabe-versand-root">
	<legend [attr.aria-label]="'Einstellungen zu '+aufgabenTyp"></legend>
	<div class="row" [formGroup]="bodyGroup">
		<div class="column taetigkeit">
			<mat-radio-group aria-label="Bitte Option wählen" class="column"
							 [formControlName]="formServiceClass.feldLfprKostenTaetigkeit">
				<mat-radio-button color="primary" class="focus-box-shadow-dark" [value]="kostenTaetigkeit.GEZAHLT"
								  data-test-id="gezahlt-radio-button">gezahlt
				</mat-radio-button>
				<mat-radio-button #enablesTraeger color="primary" class="focus-box-shadow-dark" [value]="kostenTaetigkeit.ERFORDERN"
								  data-test-id="erfordern-radio-button">gemäß KR erfordern von
				</mat-radio-button>
				<div class="column" *ngIf="hasTraeger()">
					<mat-radio-group aria-label="Bitte Kostenträger wählen" class="column"
									 [formControlName]="formServiceClass.feldLfprKostenErfordernVon">
						<mat-radio-button color="primary" class="focus-box-shadow-dark" [value]="traeger.NOTAR"
										  data-test-id="notar-radio-button">Notar
						</mat-radio-button>
						<mat-radio-button color="primary" class="focus-box-shadow-dark" [value]="traeger.EIGENTUEMER"
										  data-test-id="eigentuemer-radio-button">Eigentümer
						</mat-radio-button>
						<mat-radio-button color="primary" class="focus-box-shadow-dark" [value]="traeger.BERECHTIGTE"
										  data-test-id="berechtigte-radio-button">Berechtigte II/
						</mat-radio-button>
						<schir-int-client-one-value-editor *ngIf="needsNr()" [type]="'text'" [label]="'Nr. in Abt. II'" [fieldControl]="berechtigteNr"
														   color="primary" data-test-id="mitteilung-berechtigte-input-nr" class="nr">
						</schir-int-client-one-value-editor>
						<mat-radio-button color="primary" class="focus-box-shadow-dark" [value]="traeger.RECHTSANWALT"
										  data-test-id="berechtigte-radio-button">Rechtsanwalt
						</mat-radio-button>
						<mat-radio-button color="primary" class="focus-box-shadow-dark" [value]="traeger.SONSTIGE"
										  data-test-id="berechtigte-radio-button">Sonstige
						</mat-radio-button>
					</mat-radio-group>
				</div>
				<mat-radio-button color="primary" class="focus-box-shadow-dark" [value]="kostenTaetigkeit.SOLL"
								  data-test-id="soll-radio-button">gemäß anliegender KR zum Soll
				</mat-radio-button>
				<mat-radio-button color="primary" class="focus-box-shadow-dark" [value]="kostenTaetigkeit.FREI"
								  data-test-id="gebuehrenfrei-radio-button">gebührenfrei gemäß
				</mat-radio-button>
				<div class="column" *ngIf="hasGrund()">
					<mat-radio-group aria-label="Bitte Begründung wählen" class="column"
									 [formControlName]="formServiceClass.feldLfprKostenfreiGrund">
						<mat-radio-button color="primary" class="focus-box-shadow-dark" [value]="grund.PARAGRAPH102"
										  data-test-id="paragraph-radio-button">§ 102 LuftFZRG
						</mat-radio-button>
						<mat-radio-button color="primary" class="focus-box-shadow-dark" [value]="grund.GNOTKG"
										  data-test-id="gnotkg-radio-button">GNotKG
						</mat-radio-button>
						<mat-radio-button color="primary" class="focus-box-shadow-dark" [value]="grund.GES"
										  data-test-id="ges-radio-button">Ges. v. 28.02.1959
						</mat-radio-button>
					</mat-radio-group>
				</div>
			</mat-radio-group>
		</div>
	</div>
	<schir-int-client-notiz-in-aufgabe-editor class="row note" [aufgabenTyp]="aufgabenTyp">
	</schir-int-client-notiz-in-aufgabe-editor>
</fieldset>

