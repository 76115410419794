import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {hasLink, ResourceFactory} from '@ngxp/rest';
import {VerfahrenLinkRel, VerfahrenResource} from '@schir-int-client/verfahren-shared';
import {saveAs} from 'file-saver';
import {Observable} from 'rxjs';
import {ChangeEntryLinkRel, ChangeEntryListLinkRel} from '../../../register-change/src/lib/register-change.linkrel';
import {
	BatchEntry,
	BetragsKorrekturWert,
	ChangeEntry,
	ChangeEntryListResource,
	ChangeEntryResource,
	ChangeEntryVermerk,
	LaufendeNummerKorrekturWert,
	TeilloeschenWert,
} from '../../../register-change/src/lib/register-change.model';
import {PartiellRoetungsBereich} from './register.model';

@Injectable({ providedIn: 'root' })
export class RegisterBlattService {
	constructor(private resourceFactory: ResourceFactory, private httpClient: HttpClient) { }

	public getOne(resource: VerfahrenResource): Observable<ChangeEntryListResource> {
		return this.resourceFactory.from(resource).get(this.getRegisterBlattRelByVerfahren(resource));
	}

	private getRegisterBlattRelByVerfahren(verfahren: VerfahrenResource): string {

		if (hasLink(verfahren, VerfahrenLinkRel.SEESCHIFFSREGISTER)) {
			return VerfahrenLinkRel.SEESCHIFFSREGISTER;
		} else if (hasLink(verfahren, VerfahrenLinkRel.BINNENSCHIFFSREGISTER)) {
			return VerfahrenLinkRel.BINNENSCHIFFSREGISTER;
		} else if (hasLink(verfahren, VerfahrenLinkRel.LUFTFAHRTPFANDRECHTSREGISTER)) {
			return VerfahrenLinkRel.LUFTFAHRTPFANDRECHTSREGISTER;
		} else {
			return VerfahrenLinkRel.SCHIFFSBAUREGISTER;
		}
	}

	public createRoetenEntry(entryToRoeten: ChangeEntryResource): Observable<ChangeEntryListResource> {
		return this.resourceFactory.from(entryToRoeten).post(ChangeEntryLinkRel.ROETEN);
	}

	public deleteEntry(entry: ChangeEntryResource): Observable<ChangeEntryListResource> {
		return this.resourceFactory.from(entry).delete(ChangeEntryLinkRel.DELETE);
	}

	public saveFile(data: Blob, fileName: string) {
		saveAs(data, fileName);
	}

	public addChangeEntry(entry: ChangeEntry, entryList: ChangeEntryListResource): Observable<ChangeEntryListResource> {
		return this.resourceFactory.from(entryList).post(ChangeEntryListLinkRel.ADD_ENTRY, entry);
	}

	public addBatchEntry(entries: BatchEntry[], entryList: ChangeEntryListResource): Observable<ChangeEntryListResource> {
		return this.resourceFactory.from(entryList).post(ChangeEntryListLinkRel.BATCH, entries);
	}

	public createUmschreibungEntry(entryResource: ChangeEntryResource, newEntry: ChangeEntry): Observable<ChangeEntryListResource> {
		return this.resourceFactory.from(entryResource).post(ChangeEntryLinkRel.CREATE_UMSCHREIBUNG, newEntry);
	}

	public addVermerkEntry(entry: ChangeEntryVermerk, entryList: ChangeEntryListResource): Observable<ChangeEntryListResource> {
		return this.resourceFactory.from(entryList).post(ChangeEntryListLinkRel.ADD_VERMERK_ENTRY, entry);
	}

	public updateChangeEntry(entry: ChangeEntryResource): Observable<ChangeEntryListResource> {
		return this.resourceFactory.from(entry).put(ChangeEntryLinkRel.EDIT, entry);
	}

	public createPartielleRoetungEntry(entryToPartiellRoeten: ChangeEntryResource, partiellRoetungBereich: PartiellRoetungsBereich): Observable<ChangeEntryListResource> {
		return this.resourceFactory.from(entryToPartiellRoeten).post(ChangeEntryLinkRel.PARTIELL_ROETEN, partiellRoetungBereich);
	}

	public createTeilloeschung(entry: ChangeEntryResource, teilloeschenWert: TeilloeschenWert): Observable<ChangeEntryListResource> {
		return this.resourceFactory.from(entry).post(ChangeEntryLinkRel.TEILLOESCHEN, teilloeschenWert);
	}

	public createLaufendeNummerKorrektur(entry: ChangeEntryResource, laufendeNummerKorrekturWert: LaufendeNummerKorrekturWert): Observable<ChangeEntryListResource> {
		return this.resourceFactory.from(entry).post(ChangeEntryLinkRel.LAUFENDE_NUMMER_KORRIGIEREN, laufendeNummerKorrekturWert);
	}

	public createBetragsKorrektur(entry: ChangeEntryResource, betragsKorrekturWert: BetragsKorrekturWert): Observable<ChangeEntryListResource> {
		return this.resourceFactory.from(entry).post(ChangeEntryLinkRel.BETRAG_KORRIGIEREN, betragsKorrekturWert);
	}
}
