import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import {getUrl} from '@ngxp/rest';
import {LoadingGuard, StateResource} from '@schir-int-client/ngrx-helpers';
import {
	CreateKorrespondenzVorlageAction,
	DownloadKorrespondenzVorlageAction,
} from 'libs/korrespondenz-shared/src/lib/korrespondenz-vorlage/korrespondenz-vorlage.actions';
import {KorrespondenzCreateUserData} from 'libs/korrespondenz-shared/src/lib/korrespondenz-vorlage/korrespondenz-vorlage.model';
import {isNil} from 'lodash-es';
import {combineLatest, Observable} from 'rxjs';
import {filter, map, share, tap} from 'rxjs/operators';
import {
	AufgabeMarkAsErledigtAction,
	AufgabeMarkAsFreigegebenAction,
	CreateAufgabeAction,
	DeleteAufgabeAction,
	LoadAdressatenByAufgabeAction,
	LoadAufgabenByVerfuegungAction,
	LoadAufgabeSuccessAction,
	UpdateAufgabeAction,
} from './aufgabe.actions';
import {AdressatWithResource, Aufgabe, AufgabeResource} from './aufgabe.model';
import {
	adressatenByAufgabeSelector,
	aufgabenSelector,
	aufgabeSelector,
	isAufgabeSelectedSelector,
} from './aufgabe.selectors';
import {AufgabeRootState} from './aufgabe.state';
import {verfuegungSelector} from '../../../verfuegung-shared/src/lib/verfuegung.selectors';

@Injectable({ providedIn: 'root' })
export class AufgabeFacade {

	private emptyArray = [];

	private loadingAufgabenByVerfuegung = new LoadingGuard(this.store);

	aufgabe: Observable<AufgabeResource> = this.store.select(aufgabeSelector);

	constructor(private store: Store<AufgabeRootState>) {}

	createAufgabe(aufgabe: Aufgabe): void {
		this.store.dispatch(new CreateAufgabeAction(aufgabe));
	}

	updateAufgabe(aufgabe: Aufgabe): void {
		this.store.dispatch(new UpdateAufgabeAction(aufgabe));
	}

	getAdressatenByAufgabe(aufgabe: AufgabeResource): Observable<AdressatWithResource[]> {
		return this.store.select(adressatenByAufgabeSelector, { aufgabeUri: getUrl(aufgabe) }).pipe(
			tap(adressaten => {
				if (isNil(adressaten)) {
					this.store.dispatch(new LoadAdressatenByAufgabeAction(aufgabe));
				}
			}),
			map(adressaten => {
				return adressaten ? adressaten : this.emptyArray;
			}),
			share(),
		);
	};

	getAufgaben(): Observable<StateResource<AufgabeResource[]>> {
		return combineLatest([this.store.select(verfuegungSelector), this.store.select(aufgabenSelector)]).pipe(
			filter(([verfuegung]) => !isNil(verfuegung) && !isNil(verfuegung.resource)),
			filter(([verfuegung, aufgaben]) =>
				!this.loadingAufgabenByVerfuegung.mustLoadFirst(aufgaben, () => new LoadAufgabenByVerfuegungAction(), verfuegung.resource._links?.self?.href)),
			map(([, aufgaben]) => aufgaben),
		);
	}

	markAsErledigt(aufgabeResource: AufgabeResource) {
		this.store.dispatch(new AufgabeMarkAsErledigtAction(aufgabeResource));
	}

	markAsFreigegeben(aufgabeResource: AufgabeResource) {
		this.store.dispatch(new AufgabeMarkAsFreigegebenAction(aufgabeResource));
	}

	createKorrespondenz(request: KorrespondenzCreateUserData) {
		this.store.dispatch(new CreateKorrespondenzVorlageAction(request));
	}

	downloadKorrespondenz() {
		this.store.dispatch(new DownloadKorrespondenzVorlageAction());
	}

	setAufgabe(aufgabe: AufgabeResource): void {
		this.store.dispatch(new LoadAufgabeSuccessAction(aufgabe));
	}

	isAufgabeSelected(aufgabe: AufgabeResource): Observable<boolean> {
		return this.store.select(isAufgabeSelectedSelector, { aufgabe: aufgabe });
	}

	deleteAufgabe(aufgabe: AufgabeResource) {
		this.store.dispatch(new DeleteAufgabeAction(aufgabe));
	}
}
