<fieldset class="grid" *ngIf="formGroup.enabled" data-test-id="aufgabe-versand-root">
	<legend [attr.aria-label]="'Einstellungen zu '+aufgabenTyp"></legend>
	<div class="row" [formGroup]="bodyGroup">
		<div class="column taetigkeit">
			<mat-radio-group aria-label="Bitte Aktion wählen" class="column"
				[formControlName]="formServiceClass.feldVersandTaetigkeit">
				<mat-radio-button color="primary" class="focus-box-shadow-dark" [value]="versandTaetigkeit.SENDEN_AN"
					data-test-id="senden-an-radio-button">
					senden an</mat-radio-button>
				<mat-radio-button color="primary" class="focus-box-shadow-dark" [value]="versandTaetigkeit.AUSHAENDIGEN_AN"
					data-test-id="aushaendigen-an-radio-button">aushändigen an</mat-radio-button>
			</mat-radio-group>
		</div>
		<schir-int-client-kontakt-kategorie-checkboxes class="column" data-test-id="kontakt-kategorie-checkbox-root"
			[control]="bodyGroup.controls[formServiceClass.feldVersandEmpfaenger]">
		</schir-int-client-kontakt-kategorie-checkboxes>
	</div>

	<div class="row" *ngIf="hasFax">
		<mat-checkbox class="column focus-box-shadow-dark" color="primary" data-test-id="vorab-per-fax-checkbox"
			[formControl]="vorabPerFaxControl">
			vorab per Fax
		</mat-checkbox>
		<schir-int-client-kontakt-kategorie-checkboxes *ngIf="vorabPerFax" class="column"
			data-test-id="kontakt-kategorie-checkboxes-fax-empfaenger"
			[control]="bodyGroup.controls[formServiceClass.feldVersandFaxEmpfaenger]">
		</schir-int-client-kontakt-kategorie-checkboxes>
	</div>

	<schir-int-client-notiz-in-aufgabe-editor class="row note" [aufgabenTyp]="aufgabenTyp">
	</schir-int-client-notiz-in-aufgabe-editor>
</fieldset>
