import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {EipWebSocketFactory} from './eip.websocket.factory';
import {XmlUtil} from './xml/xml.util';

@Injectable({ providedIn: 'root' })
export class EipConnection {
	private messageListeners: EipMessageListener[] = [];
	private socket: WebSocket;
	private registrationObservable: Subject<boolean>;
	private partInstanceIdentifier: string;

	constructor(private socketFactory: EipWebSocketFactory) {
		this.registrationObservable = new Subject();
	}

	public open(success: () => void): void {
		this.socket = this.socketFactory.openWebSocket();

		this.socket.onmessage = (event: MessageEvent) => {
			this.onMessageReceived(event.data);
		};
		this.socket.onerror = () => {
			console.error('Websocket-Error');
		};
		this.socket.onclose = () => {
			console.log('Closing websocket');
		};
		this.socket.onopen = success;
	}

	public isRegistrated(): Observable<boolean> {
		return this.registrationObservable;
	}

	public sendMessage(xml: string): void {
		console.log(`Sending:\n ${xml}`);
		this.socket.send(xml);
	}

	public sendMessageWithId(id: string, xml: string): void {
		const messageWithId = this.createMessageWithId(id, xml);
		console.log(`Sending:\n ${messageWithId}`);
		this.socket.send(messageWithId);
	}

	public addMessageListener(listener: EipMessageListener): void {
		this.messageListeners.push(listener);
	}

	public setRegistrated(registrated: boolean) {
		this.registrationObservable.next(registrated);
	}

	public getPartInstanceIdentifier(): string {
		return this.partInstanceIdentifier;
	}

	public setPartInstanceIdentifier(partInstanceIdentifier: string) {
		this.partInstanceIdentifier = partInstanceIdentifier;
	}

	onMessageReceived(message): void {
		console.log(`Received message:\n ${message}`);
		let id: string;
		let messageObj: any;
		let messageName: string;
		if (this.isXmlWithId(message)) {
			id = this.extractId(message);
			const xml = this.extractXml(message);
			messageObj = XmlUtil.xml2Obj(xml);
			messageName = XmlUtil.rootElementName(xml);
		} else {
			messageObj = XmlUtil.xml2Obj(message);
			messageName = XmlUtil.rootElementName(message);
		}

		this.fireMessageEvent(messageName, messageObj, id);
	}

	private fireMessageEvent(messageName: string, messageObj: any, id: string): void {
		this.messageListeners.forEach(listener => listener(messageName, messageObj, id));
	}

	private createMessageWithId(id: string, xml: string): string {
		if (!id) {
			throw 'no dialog-id';
		}
		return id + '\n' + xml;
	}

	private isXmlWithId(message: string): boolean {
		const firstLineBreak = message.indexOf('\n');
		if (firstLineBreak < 1) {
			return false;
		}
		const firstLine = message.substr(0, firstLineBreak);
		return firstLine.indexOf('<') == -1;
	}

	private extractId(message: string): string {
		return message.substr(0, message.indexOf('\n'));
	}

	private extractXml(message: string): string {
		return message.substr(message.indexOf('\n') + 1).trim();
	}
}

export interface EipMessageListener {
	(messageName: string, message: any, id: string): void;
}
