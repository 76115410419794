import {createFeatureSelector, createSelector, MemoizedSelector} from '@ngrx/store';
import {getById, textbausteinFeatureState, TextbausteinRootState} from './textbaustein.state';
import {TextbausteinResource} from '@schir-int-client/textbaustein';
import {StateResource} from '@schir-int-client/ngrx-helpers';

export const selectFeature = createFeatureSelector<TextbausteinRootState>(textbausteinFeatureState);

export function selectTextbausteinById(id: string): MemoizedSelector<object, TextbausteinResource> {
	return createSelector(selectFeature, state => getById(state, id));
}

export const selectTextbausteine: MemoizedSelector<object, StateResource<{
	[targetColumn: string]: TextbausteinResource[]
}>> = createSelector(selectFeature, (state: TextbausteinRootState) => {
	return state?.textbausteinRoot?.textbausteine;
});
