<mat-dialog-content>
	<h2 data-test-id="gericht-setting-heading">Gerichtseinstellungen</h2>
	<form class="gericht-settings-form" [formGroup]="form">
		<h3>Adressdaten</h3>
		<schir-int-client-one-value-editor [type]="'text'" [formGroup]="form" [label]="'Bezeichnung'"
										   [fieldControl]="getFormControl('bezeichnung')"
										   data-test-id="bezeichnung-input">
		</schir-int-client-one-value-editor>
		<schir-int-client-one-value-editor [type]="'text'" [formGroup]="form" [label]="'Abteilungsbezeichnung'"
										   [fieldControl]="getFormControl('abteilungsbezeichnung')"
										   data-test-id="abteilungsbezeichnung-input">
		</schir-int-client-one-value-editor>
		<schir-int-client-one-value-editor [type]="'text'" [formGroup]="form" [label]="'Ort'"
										   [fieldControl]="getFormControl('ort')"
										   data-test-id="ort-input">
		</schir-int-client-one-value-editor>
		<schir-int-client-one-value-editor [type]="'text'" [formGroup]="form" [label]="'Straße'"
										   [fieldControl]="getFormControl('strasse')"
										   data-test-id="strasse-input">
		</schir-int-client-one-value-editor>
		<schir-int-client-one-value-editor [type]="'text'" [formGroup]="form" [label]="'Postleitzahl (Straße)'"
										   [fieldControl]="getFormControl('plzStrasse')"
										   data-test-id="plzStrasse-input">
		</schir-int-client-one-value-editor>
		<schir-int-client-one-value-editor [type]="'text'" [formGroup]="form" [label]="'Postfach'"
										   [fieldControl]="getFormControl('postfach')"
										   data-test-id="postfach-input">
		</schir-int-client-one-value-editor>
		<schir-int-client-one-value-editor [type]="'text'" [formGroup]="form" [label]="'Postleitzahl (Postfach)'"
										   [fieldControl]="getFormControl('plzPostfach')"
										   data-test-id="plzPostfach-input">
		</schir-int-client-one-value-editor>
		<h3>Kontaktinformationen</h3>
		<schir-int-client-one-value-editor [type]="'text'" [formGroup]="form" [label]="'Sprechzeiten (Zeile 1)'"
										   [fieldControl]="getFormControl('sprechzeiten1')"
										   data-test-id="sprechzeiten1-input">
		</schir-int-client-one-value-editor>
		<schir-int-client-one-value-editor [type]="'text'" [formGroup]="form" [label]="'Sprechzeiten (Zeile 2)'"
										   [fieldControl]="getFormControl('sprechzeiten2')"
										   data-test-id="sprechzeiten2-input">
		</schir-int-client-one-value-editor>
		<schir-int-client-one-value-editor [type]="'text'" [formGroup]="form" [label]="'Telefon'"
										   [fieldControl]="getFormControl('telefon')"
										   data-test-id="telefon-input">
		</schir-int-client-one-value-editor>
		<schir-int-client-one-value-editor [type]="'text'" [formGroup]="form" [label]="'Fax'"
										   [fieldControl]="getFormControl('fax')"
										   data-test-id="fax-input">
		</schir-int-client-one-value-editor>
		<schir-int-client-one-value-editor [type]="'text'" [formGroup]="form" [label]="'Internetadresse'"
										   [fieldControl]="getFormControl('internetadresse')"
										   data-test-id="internetadresse-input">
		</schir-int-client-one-value-editor>
		<h3>Bankverbindung</h3>
		<schir-int-client-one-value-editor [type]="'text'" [formGroup]="form" [label]="'IBAN'"
										   [fieldControl]="getFormControl('iban')"
										   data-test-id="iban-input">
		</schir-int-client-one-value-editor>
		<schir-int-client-one-value-editor [type]="'text'" [formGroup]="form" [label]="'BIC'"
										   [fieldControl]="getFormControl('bic')"
										   data-test-id="bic-input">
		</schir-int-client-one-value-editor>
		<h3>Weiteres</h3>
		<schir-int-client-one-value-editor [type]="'text'" [formGroup]="form" [label]="'Datenschutzlink'"
										   [fieldControl]="getFormControl('datenschutzlink')"
										   data-test-id="datenschutzlink-input">
		</schir-int-client-one-value-editor>
		<schir-int-client-one-value-editor [type]="'text'" [formGroup]="form" [label]="'RBB Gericht'"
										   [fieldControl]="getFormControl('rbbGericht')"
										   data-test-id="rbbGericht-input">
		</schir-int-client-one-value-editor>
		<schir-int-client-one-value-editor [type]="'select'" [formGroup]="form" [label]="'Datum für die Schiffspapiere'"
										   [fieldControl]="getFormControl('dateSource')" [values]="dateSourceSelectables"
										   data-test-id="dateSource-select">
		</schir-int-client-one-value-editor>
	</form>

</mat-dialog-content>
<mat-dialog-actions data-test-id="user-settings-dialog-actions">
	<button mat-stroked-button data-test-id="cancel-button" class="focus-box-shadow-dark" (click)="onCancel()">Schließen</button>
	<button mat-flat-button data-test-id="save-button" type="submit" color="primary" class="focus-box-shadow-dark" (click)="onSave()" [disabled]="!isValid()">Speichern</button>
</mat-dialog-actions>
