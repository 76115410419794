<div data-test-id="posteingang-bearbeiten-root" *ngIf="posteingang | async as _posteingang">
	<div class="c-areas">
		<div class="c-areas__area">
			<div class="x-section x-section--double" data-test-id="verfahren-list-root">
				<div class="x-section__headline">
					<div class="multiline-headline" tabindex="0">
						<div class="c-row c-row--header">
							<span class="c-row__item c-row__item--header"
								data-test-id="posteingang-aktenzeichen">{{renderAktenzeichen(_posteingang.aktenzeichenVerfahren)}}</span>
							<span
								class="c-row__item c-row__item--secondary">{{_posteingang.status | enumToLabel: posteingangStatusLabel }}</span>
						</div>
						<div class="c-row">
							<span class="c-row__item">
								<ng-container *ngIf="_posteingang.firmenName; else name">{{_posteingang.firmenName}}
								</ng-container>
								<ng-template #name>{{_posteingang.vorname}} {{_posteingang.nachname}}</ng-template>
								<ng-container
									*ngIf="!_posteingang.firmenName && !_posteingang.vorname && !_posteingang.nachname">
									Keine Absender vorhanden</ng-container>
							</span>
							<span class="c-row__item"
								data-test-id="eingangs-datum">{{_posteingang.eingangsDatum | formatDate}}</span>
						</div>
					</div>
				</div>
				<div class="x-section__subline">
					<button class="c-button c-button--rounded button-abschliessen" type="button" data-test-id="beenden-rechnung"
						(click)="abschliessen()" aria-labelledby="bt-abschliessen">
						<i class="material-icons">done</i>
						<span id="bt-abschliessen">Abschließen</span>
					</button>
				</div>
				<div class="x-section__main">
					<schir-int-client-posteingang-document-list *ngIf="(documents | async) as _documents"
						[documents]="_documents">
					</schir-int-client-posteingang-document-list>
				</div>
				<!-- SCHIFF-2338	DIESEN BLOCK NICHT LÖSCHEN
				<div class="x-section__main">
					<schir-int-client-posteingang-document-viewer *ngIf="(documents | async) as _documents" [documents]="_documents">
					</schir-int-client-posteingang-document-viewer>
				</div> -->
			</div>
			<schir-int-client-zugeordnete-vorgaenge class="x-section"></schir-int-client-zugeordnete-vorgaenge>
		</div>
		<div class="c-areas__area c-areas__area--accent">
			<schir-int-client-verfahren-search class="x-section" data-test-id="verfahren-search-root">
			</schir-int-client-verfahren-search>
			<schir-int-client-vorgaenge-zum-verfahren-im-posteingang class="x-section"
				*ngIf="selectedVerfahren | async as verfahren" [verfahren]="verfahren"
				data-test-id="vorgaenge-zum-verfahren-im-posteingang-root">
			</schir-int-client-vorgaenge-zum-verfahren-im-posteingang>
			<div class="x-section" *ngIf="selectedVerfahren | async as verfahren">
				<div class="x-section__headline">
					<h2 class="mat-h1 margin-right-auto" tabindex="0">Kontakte im Verfahren</h2>
				</div>
				<div class="x-section__subline">
					<i class="material-icons" aria-hidden="true">contacts</i>
					<h3 class="mat-body margin-right-auto" tabindex="0">Kontakte</h3>
				</div>
				<schir-int-client-kontakt-list
					[verfahren]="verfahren">
				</schir-int-client-kontakt-list>
			</div>
		</div>
	</div>
</div>
