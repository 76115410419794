import {EventEmitter, Injectable, Output} from '@angular/core';
import {ResourceFactory} from '@ngxp/rest';
import {Observable} from 'rxjs';
import {TextbausteinLinkRel} from './textbaustein.linkrel';
import {TextbausteinListResource, TextbausteinResource} from './textbaustein.model';
import {ChangeEntryListLinkRel, ChangeEntryListResource} from '@schir-int-client/register-change';

@Injectable()
export class TextbausteinService {

	@Output() textbausteinSelectedObservable = new EventEmitter<number>();

	constructor(private resourceFactory: ResourceFactory) { }

	getTextbausteine(entryListResource: ChangeEntryListResource): Observable<TextbausteinListResource> {
		return this.resourceFactory.from(entryListResource).get(ChangeEntryListLinkRel.TEXTBAUSTEINE);
	}

	updateTextbaustein(textbaustein: TextbausteinResource): Observable<TextbausteinResource> {
		return this.resourceFactory.from(textbaustein).put(TextbausteinLinkRel.UPDATE_TRANSIENT_TEXT, textbaustein);
	}
}
