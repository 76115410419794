import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';

import {AppModule} from './app/app.module';
import {environment} from './environments/environment';
import {loadEnvironment} from '@schir-int-client/environment';

loadEnvironment(environment.environmentUrl).then(env => {
	if (env.production) {
		enableProdMode();
	}

	function bootstrap() {
		platformBrowserDynamic()
			.bootstrapModule(AppModule)
			.catch(err => console.log(err));
	}

	bootstrap();
});




