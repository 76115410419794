import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import {StateResource} from '@schir-int-client/ngrx-helpers';
import {Observable} from 'rxjs';
import {DatumsBereichResource} from './wiedervorlage.model';
import {DatumsBereichState} from './wiedervorlage.reducer';
import {Moment} from 'moment/moment';
import {UpdateDatumsBereichAction} from './datumsBereichActions';


@Injectable({ providedIn: 'root' })
export class DatumsBereichFacade {

	constructor(private store: Store<DatumsBereichState>) {}

	updateDatumsBereich(von: Moment, bis: Moment) {
		this.store.dispatch(new UpdateDatumsBereichAction(von, bis));
	}

	getDatumsBereich(): Observable<StateResource<DatumsBereichResource>> {
		return this.store.select(state => state.datumsBereich);
	}
}
