import {Component, Input} from '@angular/core';
import {AufgabeMitteilungBody, AufgabeResource} from '@schir-int-client/aufgabe-shared';
import {isNil} from 'lodash-es';
import {EintragungsmitteilungMitteilungen} from '../../../aufgaben/aufgabe-mitteilung-editor/aufgabe-mitteilung-editor.component';

@Component({
	selector: 'schir-int-client-aufgabe-mitteilung-body',
	templateUrl: './aufgabe-mitteilung-body.component.html',
	styleUrls: ['./aufgabe-mitteilung-body.component.scss'],
})
export class AufgabeMitteilungBodyComponent {
	@Input() aufgabe: AufgabeResource;

	readonly mitteilungEmpfaenger = EintragungsmitteilungMitteilungen;

	constructor() { }

	get body(): AufgabeMitteilungBody {
		return <AufgabeMitteilungBody>this.aufgabe.body;
	}

	get eigentuemerNeu(): string {
		return [
			this.body.eigentuemerNeuMitteilung.blattabschrift ? EintragungsmitteilungMitteilungen.BLATTABSCHRIFT : undefined,
			this.body.eigentuemerNeuMitteilung.zertifikatsBerichtigung ? EintragungsmitteilungMitteilungen.SCHIFFSZERTIFIKAT : undefined,
			this.body.eigentuemerNeuMitteilung.auszugsEinreichung ? EintragungsmitteilungMitteilungen.ZERTIFIKATSAUSZUG : undefined]
			.filter(text => !isNil(text)).join(', ');
	}

	get eigentuemerAlt(): string {
		return [
			this.body.eigentuemerAltMitteilung.blattabschrift ? EintragungsmitteilungMitteilungen.BLATTABSCHRIFT : undefined,
			this.body.eigentuemerAltMitteilung.zertifikatsBerichtigung ? EintragungsmitteilungMitteilungen.SCHIFFSZERTIFIKAT : undefined,
			this.body.eigentuemerAltMitteilung.auszugsEinreichung ? EintragungsmitteilungMitteilungen.ZERTIFIKATSAUSZUG : undefined]
			.filter(text => !isNil(text)).join(', ');
	}

	get glaeubigerNeu(): string {
		return [
			this.body.glaeubigerNeuMitteilung.blattabschrift ? EintragungsmitteilungMitteilungen.BLATTABSCHRIFT : undefined,
			this.body.glaeubigerNeuMitteilung.zertifikatsBerichtigung ? EintragungsmitteilungMitteilungen.SCHIFFSZERTIFIKAT : undefined]
			.filter(text => !isNil(text)).join(', ');
	}

	get glaeubigerAlt(): string {
		return [
			this.body.glaeubigerAltMitteilung.blattabschrift ? EintragungsmitteilungMitteilungen.BLATTABSCHRIFT : undefined,
			this.body.glaeubigerAltMitteilung.zertifikatsBerichtigung ? EintragungsmitteilungMitteilungen.SCHIFFSZERTIFIKAT : undefined]
			.filter(text => !isNil(text)).join(', ');
	}

	get notar(): string {
		return this.body.notarMitteilung.blattabschrift ? EintragungsmitteilungMitteilungen.BLATTABSCHRIFT : '';
	}

	get bsh(): string {
		return this.body.bshMitteilung.blattabschrift ? EintragungsmitteilungMitteilungen.BEGLAUBIGTE_BLATTABSCHRIFT : '';
	}

	get rechtsanwaelte(): string {
		return [
			this.body.rechtsanwaelteMitteilung.blattabschrift ? EintragungsmitteilungMitteilungen.BLATTABSCHRIFT : undefined,
			this.body.rechtsanwaelteMitteilung.zertifikatsBerichtigung ? EintragungsmitteilungMitteilungen.SCHIFFSZERTIFIKAT : undefined]
			.filter(text => !isNil(text)).join(', ');
	}
}
