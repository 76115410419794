export enum VerfahrenLinkRel {
	SELF = 'self',
	SEESCHIFFSREGISTER = 'seeschiffsregister',
	BINNENSCHIFFSREGISTER = 'binnenschiffsregister',
	SCHIFFSBAUREGISTER = 'schiffsbauregister',
	LUFTFAHRTPFANDRECHTSREGISTER = 'luftfahrtpfandrechtsregister',
	SEARCH_VERFAHREN = 'verfahrenSearch',
	VERFAHREN_LIST = 'verfahrenList',
	VORGAENGE = 'vorgaenge',

	KONTAKTE_IN_KATEGORIEN = 'kontakteInKategorien',
	ASSIGN_KONTAKT_IN_KATEGORIE = 'assignKontaktInKategorie',
	UNASSIGN_KONTAKT_IN_KATEGORIE = 'unassignKontaktInKategorie',

	CREATE_VORGANG = 'createVorgang',
	REGISTERBLATT_AUSDRUCK = 'registerBlattAusdruck',
	BEGLAUBIGTER_REGISTERBLATT_AUSDRUCK = 'beglaubigterRegisterBlattAusdruck',
	REGISTERBLATT_AMTLICHER_AUSZUG = 'amtlicherAuszug',
	REGISTERBLATT_SCHIFFSZERTIFIKAT = 'schiffszertifikat',
	REGISTERBLATT_SCHIFFSBRIEF = 'schiffsbrief',
	ADD_UNTERSCHEIDUNGSSIGNAL = 'addUnterscheidungssignal',
	AUFSCHRIFT = 'aufschrift',
	NOTIZ = 'updateNotiz',
	CREATE_SIGNATUR_TASK = 'createSignaturTask',
	BEARBEITEN = 'bearbeiten',
	DELETE = 'delete',
	BEARBEITEN_BEENDEN = 'bearbeitenBeenden',
	UEBERNEHMEN = 'uebernehmen',
	ASSIGN_TO = 'assignTo',
	MARK_AS_ABGELEHNT = 'markAsAbgelehnt',
	ALTERNATIVE_TEXTE = 'alternativeTexte',

	ASSIGN_USIGNAL = 'assignUsignal',
	STANDORT_AKTE = 'standortAkte',
	AR_KENNZEICHEN = 'arKennzeichen',
	ZAEHLBLATT = 'zaehlblatt',
	EXPORT_VERFAHREN = 'exportVerfahren'
}

export enum ZaehlblattLinkRel {
	ADD_AKTIONEN = 'add',
	DELETE_AKTION = 'delete'
}

export enum VerfahrenListLinkRel {
	VERFAHREN_LIST = 'verfahrenList',
	ADD_VERFAHREN = 'add'
}
