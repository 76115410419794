import {InjectionToken} from '@angular/core';
import {ActionReducerMap} from '@ngrx/store';
import {statistikReducer, StatistikState} from './statistik.reducer';
import {ReducerConfig} from '@schir-int-client/test-helpers';

export const statistikFeatureState = 'StatistikState';
export const statistikReducerInjectionToken = new InjectionToken<ActionReducerMap<StatistikRootState>>('Registered StatistikReducer');

export interface StatistikRootState {
	statistikRoot: StatistikState;
}

export function getStatistikReducer(): ActionReducerMap<StatistikRootState> {

	return {
		statistikRoot: statistikReducer,
	};
}

export const reducerConfig: ReducerConfig<StatistikRootState> = {
	reducers: getStatistikReducer(),
	injectionToken: statistikReducerInjectionToken,
};
