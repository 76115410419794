import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatLegacyButtonModule as MatButtonModule} from '@angular/material/legacy-button';
import {MatToolbarModule} from '@angular/material/toolbar';
import {RouterModule, Routes} from '@angular/router';
import {RestModule} from '@ngxp/rest';
import {DashboardModule, DashboardPageComponent} from '@schir-int-client/dashboard';
import {VerfahrenModule} from '@schir-int-client/verfahren';
import {NavbarComponent} from './navbar/navbar.component';
import {MatLegacyTooltipModule as MatTooltipModule} from '@angular/material/legacy-tooltip';
import {SettingsMenuComponent} from './navbar/settings-menu/settings-menu.component';
import {MatLegacyMenuModule as MatMenuModule} from '@angular/material/legacy-menu';
import {MatIconModule} from '@angular/material/icon';
import {UserSettingsDialogComponent} from './navbar/settings-menu/user-settings-dialog/user-settings-dialog.component';
import {MatLegacyDialogModule as MatDialogModule} from '@angular/material/legacy-dialog';
import {TechModule} from '@schir-int-client/tech';
import {GerichtSettingsDialogComponent} from './navbar/settings-menu/gericht-settings-dialog/gericht-settings-dialog.component';

const routes: Routes = [
	{
		path: 'dashboard',
		component: DashboardPageComponent,
	},
	{
		path: 'admin',
		loadChildren: () => import('@schir-int-client/admin').then(m => m.AdminModule),
	},
];

@NgModule({
	imports: [
		CommonModule,
		RestModule,
		RouterModule.forChild(routes),
		MatToolbarModule,
		MatButtonModule,
		DashboardModule,
		FormsModule,
		VerfahrenModule,
		MatTooltipModule,
		MatMenuModule,
		MatIconModule,
		MatDialogModule,
		TechModule,
		ReactiveFormsModule,
	],
	declarations: [
		NavbarComponent,
		SettingsMenuComponent,
		UserSettingsDialogComponent,
		GerichtSettingsDialogComponent,
	],
	exports: [
		NavbarComponent,
	],
})

export class NavbarModule {}
