import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {map, switchMap, tap} from 'rxjs/operators';
import {BinaryActions, ChangeFileAction, ChangeFileSuccessAction, DownloadFileAction} from './binary.actions';
import {BinaryService} from './binary.service';

@Injectable()
export class BinaryEffects {
	constructor(
		private actions: Actions,
		private service: BinaryService,
	) { }


	changeFile = createEffect(() => this.actions.pipe(
		ofType(BinaryActions.CHANGE_FILE),
		switchMap(action => {
			const castedAction = (<ChangeFileAction>action);
			return this.service.changeFile(castedAction.resource, castedAction.linkRel, castedAction.file).pipe(
				map(() => new ChangeFileSuccessAction(castedAction.fileType)),
			);
		}),
	));


	download = createEffect(() => this.actions.pipe(
		ofType(BinaryActions.DOWNLOAD_FILE),
		tap(action => this.service.downloadFile((<DownloadFileAction>action).binaryFile)),
	), { dispatch: false });
}
