import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {EffectsModule} from '@ngrx/effects';
import {StoreModule} from '@ngrx/store';
import {LOCATION_TOKEN} from '../../../tech/src/lib/location.token';
import {RechtspflegerEffects} from './rechtspfleger/rechtspfleger.effects';
import {UserEffects} from './user.effects';
import {getUserReducer, userFeatureState, userReducerInjectionToken} from './user.state';
import {UserProfileEffects} from './profile/userprofile.effects';
import {
	getUserProfileReducer,
	userProfileFeatureState,
	userProfileReducerInjectionToken,
} from './profile/userprofile.state';
import {GerichtProfileEffects} from './profile/gericht/gerichtprofile.effects';
import {
	gerichtProfileFeatureState,
	gerichtProfileReducerInjectionToken,
	getGerichtProfileReducer,
} from './profile/gericht/gerichtprofile.state';
import {
	AdminEffects,
	adminReducerInjectionToken,
	adminRootFeatureState,
	getAdminReducer,
} from '@schir-int-client/admin-shared';

@NgModule({
	imports: [
		CommonModule,
		EffectsModule.forFeature([UserEffects]),
		EffectsModule.forFeature([RechtspflegerEffects]),
		EffectsModule.forFeature([UserProfileEffects]),
		EffectsModule.forFeature([GerichtProfileEffects]),
		EffectsModule.forFeature([AdminEffects]),
		StoreModule.forFeature(userFeatureState, userReducerInjectionToken),
		StoreModule.forFeature(userProfileFeatureState, userProfileReducerInjectionToken),
		StoreModule.forFeature(gerichtProfileFeatureState, gerichtProfileReducerInjectionToken),
		StoreModule.forFeature(adminRootFeatureState, adminReducerInjectionToken),
	],
	providers: [
		{
			provide: userReducerInjectionToken,
			useFactory: getUserReducer,
		},
		{
			provide: userProfileReducerInjectionToken,
			useFactory: getUserProfileReducer,
		},
		{
			provide: gerichtProfileReducerInjectionToken,
			useFactory: getGerichtProfileReducer,
		},
		{
			provide: adminReducerInjectionToken,
			useFactory: getAdminReducer,
		},
		{
			provide: LOCATION_TOKEN,
			useValue: window.location,
		},
	],
})
export class UserSharedModule {}
