import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {EffectsModule} from '@ngrx/effects';
import {StoreModule} from '@ngrx/store';
import {WiedervorlageEffects} from './wiedervorlage.effects';
import {
	getWiedervorlageReducer,
	wiedervorlageFeatureState,
	wiedervorlageReducerInjectionToken,
} from './wiedervorlage.state';

@NgModule({
	imports: [
		CommonModule,
		EffectsModule.forFeature([WiedervorlageEffects]),
		StoreModule.forFeature(wiedervorlageFeatureState, wiedervorlageReducerInjectionToken),
	],
	providers: [
		{
			provide: wiedervorlageReducerInjectionToken,
			useFactory: getWiedervorlageReducer,
		},
	],
})
export class WiedervorlageSharedModule {}
