import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatLegacyButtonModule as MatButtonModule} from '@angular/material/legacy-button';
import {MatLegacyFormFieldModule as MatFormFieldModule} from '@angular/material/legacy-form-field';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatIconModule} from '@angular/material/icon';
import {MatLegacyInputModule as MatInputModule} from '@angular/material/legacy-input';
import {MatLegacyTableModule as MatTableModule} from '@angular/material/legacy-table';
import {MatLegacyDialogModule as MatDialogModule} from '@angular/material/legacy-dialog';
import {TechModule} from '@schir-int-client/tech';
import {VorlageFacade, VorlageSharedModule} from '@schir-int-client/vorlage-shared';
import {AddVorlageDialogComponent} from './add-vorlage-dialog/add-vorlage-dialog.component';
import {VorlageFileUploadComponent} from './vorlage-file-upload/vorlage-file-upload.component';
import {VorlageListComponent} from './vorlage-list/vorlage-list.component';
import {VorlageUploadComponent} from './vorlage-upload/vorlage-upload.component';
import {MatLegacyTooltipModule as MatTooltipModule} from '@angular/material/legacy-tooltip';

@NgModule({
	imports: [
		CommonModule,
		TechModule,
		VorlageSharedModule,
		MatTableModule,
		MatGridListModule,
		MatButtonModule,
		MatInputModule,
		MatFormFieldModule,
		MatDialogModule,
		MatIconModule,
		FormsModule,
		ReactiveFormsModule,
		MatTooltipModule,
	],
	declarations: [
		VorlageListComponent,
		VorlageUploadComponent,
		VorlageFileUploadComponent,
		AddVorlageDialogComponent,
	],
	exports: [
		VorlageListComponent,
		VorlageUploadComponent,
		VorlageFileUploadComponent,
	],
	providers: [VorlageFacade],
})
export class VorlageModule {}
