import {Component, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {AufgabenTyp} from '@schir-int-client/aufgabe-shared';
import {CreateAufgabeFormService} from '../../../create-aufgabe-dialog/create-aufgabe.formservice';
import {RegisterBlattFacade} from '@schir-int-client/register-shared';
import {Subscription} from 'rxjs';
import {debounceTime, take} from 'rxjs/operators';

@Component({
	selector: 'schir-int-client-aufgabe-lfpr-mitteilung-editor',
	templateUrl: './aufgabe-lfpr-mitteilung-editor.component.html',
	styleUrls: ['./aufgabe-lfpr-mitteilung-editor.component.scss'],
})
export class AufgabeLfprMitteilungEditorComponent implements OnInit, OnDestroy {
	readonly formServiceClass = CreateAufgabeFormService;
	readonly aufgabenTyp: AufgabenTyp = AufgabenTyp.LFPR_MITTEILUNG;

	private readonly subscriptions: Subscription[] = [];

	constructor(private formService: CreateAufgabeFormService, private registerBlattFacade: RegisterBlattFacade) {}

	ngOnInit() {
		// Nicht über Defaultwert lösbar, da bisher nur mit statischen Werten möglich.
		// Debounce und take 1, da Subscription zuerst auch alte Werte liefert, wir aber nicht ggf. schon getätigte Änderungen überschreiben wollen.
		this.subscriptions.push(this.registerBlattFacade.blattLuftfahrzeugrolleEntries.pipe(debounceTime(100), take(1)).subscribe(entries => {
			if (this.nrRolle.value === null || this.nrRolle.value === '') {
				if (entries?.length > 0) {
					this.nrRolle.setValue(entries[0].spaltenWert);
				} else {
					this.nrRolle.setValue('');
				}
			}
		}));
	}

	ngOnDestroy() {
		this.subscriptions.forEach(s => s.unsubscribe());
	}

	get formGroup(): UntypedFormGroup {
		return <UntypedFormGroup>this.formService.form.controls[this.aufgabenTyp];
	}

	get bodyGroup(): UntypedFormGroup {
		return <UntypedFormGroup>this.formGroup.controls[CreateAufgabeFormService.feldAufgabeBody];
	}

	get uvzNr(): UntypedFormControl {
		const group = this.subGroup(CreateAufgabeFormService.groupLfprMitteilungNotar);
		return <UntypedFormControl>group.controls[CreateAufgabeFormService.feldLfprMitteilungNotarUvzNr];
	}

	get zuAz(): UntypedFormControl {
		const group = this.subGroup(CreateAufgabeFormService.groupLfprMitteilungRechtsanwalt);
		return <UntypedFormControl>group.controls[CreateAufgabeFormService.feldLfprMitteilungAnwaltZuAz];
	}

	get nrRolle(): UntypedFormControl {
		const group = this.subGroup(CreateAufgabeFormService.groupLfprMitteilungLFBA);
		return <UntypedFormControl>group.controls[CreateAufgabeFormService.feldLfprMitteilungLFBANrRolle];
	}

	get berechtigterNr(): UntypedFormControl {
		return <UntypedFormControl>this.bodyGroup.controls[CreateAufgabeFormService.feldLfprMitteilungBerechtigterNr];
	}

	checked(fieldName: string): boolean {
		return this.bodyGroup.controls[fieldName].value;
	}

	subGroup(groupName: string): UntypedFormGroup {
		return <UntypedFormGroup>this.bodyGroup.controls[groupName];
	}
}
