import {Pipe, PipeTransform} from '@angular/core';
import {isNil} from 'lodash-es';
import {AufgabeTypLabel} from './aufgabe.messages';
import {AufgabenTyp, AufgabeResource} from './aufgabe.model';


@Pipe({ name: 'labelByAufgabe' })
export class LabelByAufgabePipe implements PipeTransform {
	transform(aufgabe: AufgabeResource) {
		if (isNil(aufgabe)) {
			return '';
		}

		const label = AufgabeTypLabel[aufgabe.typ];
		return isNil(label) ? aufgabe.typ.toString() : label;
	}
}

@Pipe({ name: 'labelByAufgabenTyp' })
export class LabelByAufgabenTypPipe implements PipeTransform {
	transform(type: AufgabenTyp | string) {
		const label = AufgabeTypLabel[type];
		return isNil(label) ? type.toString() : label;
	}
}
