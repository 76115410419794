import {NgModule} from '@angular/core';
import {StoreModule} from '@ngrx/store';
import {ENVIRONMENT_CONFIG, getEnvironmentFactory} from './environment.loader';

@NgModule({
	imports: [
		StoreModule,
	],
	providers: [
		{
			provide: ENVIRONMENT_CONFIG,
			useFactory: getEnvironmentFactory,
		},
	],
})
export class EnvironmentModule {}
