import {AfterContentInit, Directive, ElementRef} from '@angular/core';

@Directive({
	selector: '[dialogFocus]',
})
export class DialogFocusDirective implements AfterContentInit {

	constructor(private host: ElementRef) {}

	ngAfterContentInit() {
		this.host.nativeElement.focus();
		//prevent focus jumping back to any elements which also request focus
		setTimeout(() => { this.host.nativeElement.focus(); }, 500);
	}
}
