import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {getUrl, ResourceUri} from '@ngxp/rest';
import {DialogService, format, HandlesBackdropClickAndEscapeKey, NotizDialogComponent} from '@schir-int-client/tech';
import {VerfahrenFacade} from '@schir-int-client/verfahren-shared';
import {
	VerfuegungFacade,
	VerfuegungLinkRel,
	VerfuegungResource,
	VerfuegungStatus,
	VerfuegungTyp,
} from '@schir-int-client/verfuegung-shared';
import {isNil} from 'lodash-es';
import {Observable, Subject, Subscription} from 'rxjs';
import {first, map, take} from 'rxjs/operators';
import {Router} from '@angular/router';
import {VerfuegungMessages} from '../../../../../verfuegung-shared/src/lib/verfuegung.messages';
import {UserFacade, UserRole} from '@schir-int-client/user-shared';

@Component({
	selector: 'schir-int-client-verfuegung-in-vorgang',
	templateUrl: './verfuegung-in-vorgang.component.html',
	styleUrls: ['./verfuegung-in-vorgang.component.scss'],
})
export class VerfuegungInVorgangComponent extends HandlesBackdropClickAndEscapeKey<NotizDialogComponent> implements OnInit, OnDestroy {

	@Input() verfuegung: VerfuegungResource;

	isSelected: Observable<boolean>;

	readonly linkRel = VerfuegungLinkRel;

	verfahrenUri: Observable<ResourceUri>;

	verfuegungStatus = VerfuegungStatus;
	audioAlert: Subject<string> = new Subject<string>();
	private subscriptions: Subscription[] = [];

	constructor(private facade: VerfuegungFacade,
	            private verfahrenFacade: VerfahrenFacade,
	            protected dialogService: DialogService,
	            private router: Router,
	            private userFacade: UserFacade) {
		super(dialogService);
	}

	ngOnInit() {
		this.isSelected = this.facade.isSelectedVerfuegung(this.verfuegung);
		this.verfahrenUri = this.verfahrenFacade.selectedVerfahren.pipe(
			map(verfahren => isNil(verfahren) ? null : btoa(getUrl(verfahren))),
		);

		this.isSelected.pipe(first()).subscribe(selected => {
			if (selected) {
				this.verfahrenUri.pipe(first()).subscribe(uri => {
					this.setSelectedVerfuegung();
					this.router.navigate(['/verfahren', uri, {
						outlets: {
							verfuegung: this.getVerfuegung(),
							aufgabe: null,
						},
					}]);
				});
			}
		});
	}

	ngOnDestroy() {
		this.subscriptions.forEach(sub => sub.unsubscribe());
	}

	markAsErledigt() {
		this.facade.markAsErledigt(this.verfuegung);
		this.audioAlert.next('Die Verfügung wurde als erledigt markiert.');
	}

	delete() {
		this.subscriptions.push(this.dialogService.openDialogYesNo(VerfuegungMessages.DELETE_VERFUEGUNG).pipe(take(1)).subscribe(data => {
			if (data.agree) {
				this.facade.deleteVerfuegung(this.verfuegung);
			}
		}));
	}

	setSelectedVerfuegung() {
		this.facade.setSelectedVerfuegung(this.verfuegung);
	}

	openNotizDialog() {
		const title = 'Notizen zur Verfügung vom ' + format(this.verfuegung.createdAt);
		this.matDialogRef = this.dialogService.openEditorDialog(this, NotizDialogComponent, {
			data: {
				title: title,
				notiz: this.verfuegung.notiz,
			},
		});
		this.subscriptions.push(this.matDialogRef.componentInstance.notizEvent.subscribe((data: string) => {
			this.updateNotiz(data);
		}));
	}

	updateNotiz(notiz: string) {
		this.facade.updateNotiz(notiz, getUrl(this.verfuegung, VerfuegungLinkRel.UPDATE_NOTIZ));
	}

	getVerfuegung() {
		return btoa(getUrl(this.verfuegung));
	}

	drucken() {
		this.facade.setSelectedVerfuegung(this.verfuegung);
		this.facade.drucken();
	}

	typLabel() {
		if (this.verfuegung.typ == VerfuegungTyp.KORRESPONDENZ) {
			return 'Korrespondenzverfügung';
		} else {
			return 'Verfügung';
		}
	}

	isAdminUser(): Observable<boolean> {
		return this.userFacade.getCurrentUser().pipe(map(user => user.roles.includes(UserRole.ADMIN)));
	}
}
