import {Component, Input, OnChanges, OnDestroy} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {KontaktKategorie} from '@schir-int-client/adressverwaltung-shared';
import {isNil, isNull} from 'lodash-es';
import {Subscription} from 'rxjs';

@Component({
	selector: 'schir-int-client-kontakt-kategorie-checkboxes',
	templateUrl: './kontakt-kategorie-checkboxes.component.html',
	styleUrls: ['./kontakt-kategorie-checkboxes.component.scss'],
})
export class KontaktKategorieCheckboxesComponent implements OnChanges, OnDestroy {

	@Input('control') formControl: UntypedFormControl;

	readonly eigentuemerNeuFormControl = new UntypedFormControl();
	readonly eigentuemerAltFormControl = new UntypedFormControl();
	readonly behoerdeFormControl = new UntypedFormControl();
	readonly vertreterFormControl = new UntypedFormControl();
	readonly glaeubigerNeuFormControl = new UntypedFormControl();
	readonly glaeubigerAltFormControl = new UntypedFormControl();
	readonly notarFormControl = new UntypedFormControl();
	readonly sonstigeFormControl = new UntypedFormControl();
	readonly rechtsanwaltFormControl = new UntypedFormControl();
	readonly phgFormControl = new UntypedFormControl();
	readonly gesellschafterFormControl = new UntypedFormControl();
	readonly eingetragenerEigentuemerFormControl = new UntypedFormControl();
	readonly formGroup = new UntypedFormGroup({
		[KontaktKategorie.EIGENTUEMER_NEU]: this.eigentuemerNeuFormControl,
		[KontaktKategorie.EIGENTUEMER_ALT]: this.eigentuemerAltFormControl,
		[KontaktKategorie.BEHOERDEN]: this.behoerdeFormControl,
		[KontaktKategorie.VERTRETER]: this.vertreterFormControl,
		[KontaktKategorie.GLAEUBIGER_NEU]: this.glaeubigerNeuFormControl,
		[KontaktKategorie.GLAEUBIGER_ALT]: this.glaeubigerAltFormControl,
		[KontaktKategorie.NOTAR]: this.notarFormControl,
		[KontaktKategorie.SONSTIGE]: this.sonstigeFormControl,
		[KontaktKategorie.RECHTSANWALT]: this.rechtsanwaltFormControl,
		[KontaktKategorie.PHG]: this.phgFormControl,
		[KontaktKategorie.GESELLSCHAFTER]: this.gesellschafterFormControl,
		[KontaktKategorie.EINGETRAGENER_EIGENTUEMER]: this.eingetragenerEigentuemerFormControl,
	});
	private controlSubscription: Subscription;
	private internalControlsSubscription: Subscription;

	constructor() {
		this.internalControlsSubscription = this.subscribeInternalFormControls();
	}

	ngOnChanges(): void {
		this.unsubscribe();
		this.controlSubscription = this.subscribeFormControl();
		// cause the form value to be not null
		if (!this.formControl.value) {
			this.formControl.setValue([]);
		}
		this.copyValuesFromInputFormControl(this.formControl.value);
	}

	ngOnDestroy(): void {
		this.unsubscribe();
		this.internalControlsSubscription.unsubscribe();
	}

	private subscribeFormControl(): Subscription {
		return this.formControl.valueChanges.subscribe((newValue: KontaktKategorie[]) => {
			this.copyValuesFromInputFormControl(newValue);
		});
	}

	private copyValuesFromInputFormControl(newValue: KontaktKategorie[]): void {
		if (isNull(newValue)) {
			newValue = [];
		}
		Object.keys(KontaktKategorie).map(kategorie => KontaktKategorie[kategorie]).forEach((kategorie: KontaktKategorie) => {
			this.formGroup.controls[kategorie].setValue(newValue.includes(kategorie), { emitEvent: false });
		});
	}

	private subscribeInternalFormControls(): Subscription {
		const subscr = this.eigentuemerNeuFormControl.valueChanges.subscribe(selected => this.processSelectionChange(KontaktKategorie.EIGENTUEMER_NEU, selected));
		subscr.add(this.eigentuemerAltFormControl.valueChanges.subscribe(selected => this.processSelectionChange(KontaktKategorie.EIGENTUEMER_ALT, selected)));
		subscr.add(this.behoerdeFormControl.valueChanges.subscribe(selected => this.processSelectionChange(KontaktKategorie.BEHOERDEN, selected)));
		subscr.add(this.vertreterFormControl.valueChanges.subscribe(selected => this.processSelectionChange(KontaktKategorie.VERTRETER, selected)));
		subscr.add(this.glaeubigerNeuFormControl.valueChanges.subscribe(selected => this.processSelectionChange(KontaktKategorie.GLAEUBIGER_NEU, selected)));
		subscr.add(this.glaeubigerAltFormControl.valueChanges.subscribe(selected => this.processSelectionChange(KontaktKategorie.GLAEUBIGER_ALT, selected)));
		subscr.add(this.notarFormControl.valueChanges.subscribe(selected => this.processSelectionChange(KontaktKategorie.NOTAR, selected)));
		subscr.add(this.sonstigeFormControl.valueChanges.subscribe(selected => this.processSelectionChange(KontaktKategorie.SONSTIGE, selected)));
		subscr.add(this.rechtsanwaltFormControl.valueChanges.subscribe(selected => this.processSelectionChange(KontaktKategorie.RECHTSANWALT, selected)));
		subscr.add(this.phgFormControl.valueChanges.subscribe(selected => this.processSelectionChange(KontaktKategorie.PHG, selected)));
		subscr.add(this.gesellschafterFormControl.valueChanges.subscribe(selected => this.processSelectionChange(KontaktKategorie.GESELLSCHAFTER, selected)));
		subscr.add(this.eingetragenerEigentuemerFormControl.valueChanges.subscribe(selected => this.processSelectionChange(KontaktKategorie.EINGETRAGENER_EIGENTUEMER, selected)));

		return subscr;
	}

	private processSelectionChange(kategorie: KontaktKategorie, selected: boolean): void {
		if (selected) {
			this.addSelectedKategorie(kategorie);
		} else {
			this.removeSelectedKategorie(kategorie);
		}
	}

	private addSelectedKategorie(kategorie: KontaktKategorie): void {
		const curValue = isNull(<KontaktKategorie[]>this.formControl.value) ? [] : [...(<KontaktKategorie[]>this.formControl.value)];
		curValue.push(kategorie);
		this.formControl.setValue(curValue);
	}

	private removeSelectedKategorie(kategorie: KontaktKategorie): void {
		const curValue = [...<KontaktKategorie[]>this.formControl.value];
		const idx = curValue.indexOf(kategorie);

		if (idx >= 0) {
			curValue.splice(idx, 1);
			this.formControl.setValue(curValue);
		}
	}

	private unsubscribe() {
		if (!isNil(this.controlSubscription)) {
			this.controlSubscription.unsubscribe();
		}
	}

}
