import {DebugElement, Type} from '@angular/core';
import {ComponentFixture} from '@angular/core/testing';
import {By} from '@angular/platform-browser';

export function mock<T>(type: Type<T>, givenElements?: any): jasmine.SpyObj<T> {
	const methodNames: string[] = Object.getOwnPropertyNames(type.prototype);
	// MatDialog definiert seine Methoden nicht mehr selber, sondern erbt sie.
	if ('MatDialog' === type.name) {
		methodNames.push('open', 'closeAll');
	}
	const result = jasmine.createSpyObj(type.name, methodNames);
	if (givenElements) {
		Object.keys(givenElements).forEach(key => result[key] = givenElements[key]);
	}
	return result;
}

export function getChildByDataTestId(fixture: ComponentFixture<any>, dataTestId: string): DebugElement {
	return fixture.debugElement.query(By.css('*[data-test-id="' + dataTestId + '"]'));
}

export function getFirstChildByDataTestId(fixture: ComponentFixture<any>, dataTestId: string): DebugElement {
	return fixture.debugElement.queryAll(By.css('*[data-test-id="' + dataTestId + '"]'))[0];
}

export function getChildByCssSelector(fixture: ComponentFixture<any>, selector: string): DebugElement {
	return fixture.debugElement.query(By.css(selector));
}

export function getChildrenByCssSelector(fixture: ComponentFixture<any>, selector: string): DebugElement[] {
	return fixture.debugElement.queryAll(By.css(selector));
}

export function resetAllCalls(...spys: jasmine.SpyObj<any>) {
	spys.forEach(spy => {
		Object.keys(spy).forEach(member => {
			if (spy[member]) {
				spy[member].calls.reset();
			}
		});
	});
}
