import {AfterViewInit, Directive, ElementRef, Input, OnChanges, SimpleChanges} from '@angular/core';

@Directive({
	selector: '[elementFocus]',
})
export class FocusDirective implements AfterViewInit, OnChanges {

	@Input('elementFocus') isSelected: boolean = false;

	constructor(private host: ElementRef) {}

	ngAfterViewInit() {
		if (this.isSelected) {
			this.host.nativeElement.focus();
		}
	}

	ngOnChanges(changes: SimpleChanges): void {
		setTimeout(() => { //prevent focus jumping back to search bar
			if (this.isSelected) {
				this.host.nativeElement.focus();
			}
		}, 100);
	}
}
