import {Action} from '@ngrx/store';
import {
	MonthlyStatistikLoadedAction,
	StatistikActions,
	StatistikLoadedAction,
	ZaehlblattStatistikLoadedAction,
} from './statistik.actions';
import {MonthlyStatistik, StatistikResource, ZaehlblattStatistikResource} from './statistik.model';

export interface StatistikState {
	statistik: StatistikResource;
	monthlyStatistik: MonthlyStatistik;
	zaehlblattStatistik: ZaehlblattStatistikResource;
}

export const initialState: StatistikState = { statistik: null, monthlyStatistik: null, zaehlblattStatistik: null };

export function statistikReducer(state: StatistikState = initialState, action: Action): StatistikState {
	switch (action.type) {
		case StatistikActions.STATISTIK_LOADED:
			return {
				...state,
				statistik: (<StatistikLoadedAction>action).payload,
			};
		case StatistikActions.MONTHLY_STATISTIK_LOADED:
			return {
				...state,
				monthlyStatistik: (<MonthlyStatistikLoadedAction>action).payload,
			};
		case StatistikActions.ZAEHLBLATT_STATISTIK_LOADED:
			return {
				...state,
				zaehlblattStatistik: (<ZaehlblattStatistikLoadedAction>action).payload,
			};
		default:
			return state;
	}
}
