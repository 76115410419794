import {InjectionToken} from '@angular/core';
import {ActionReducerMap} from '@ngrx/store';
import {ReducerConfig} from '@schir-int-client/test-helpers';
import {verfuegungReducer, VerfuegungState} from './verfuegung.reducer';

export const verfuegungFeatureState = 'VerfuegungState';
export const verfuegungReducerInjectionToken = new InjectionToken<ActionReducerMap<VerfuegungRootState>>('Registered VerfuegungReducer');

export interface VerfuegungRootState {
	verfuegungRoot: VerfuegungState;
}

export function getVerfuegungReducer(): ActionReducerMap<VerfuegungRootState> {
	return {
		verfuegungRoot: verfuegungReducer,
	};
}

export const reducerConfig: ReducerConfig<VerfuegungRootState> = {
	reducers: getVerfuegungReducer(),
	injectionToken: verfuegungReducerInjectionToken,
};


