import {Injectable} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {ApiRootFacade} from '@schir-int-client/api-root';

@Injectable({
	providedIn: 'root',
})
export class TitleService {
	private static LEGACY_PREFIX_HH = 'HH ';
	private static LEGACY_SUFFIX = ' - interner Client';

	private static SCHIFF_REGISTER = 'Schiffsregister';
	private static LFPR_REGISTER = 'eLuPfand';


	private isLegacy: boolean;
	private isLFPR: boolean;

	constructor(private ngTitleService: Title, private apiRootFacade: ApiRootFacade) {
		this.apiRootFacade.getCurrentApiRoot().subscribe(apiRootResource => {
			this.isLegacy = apiRootResource?.useLegacyWindowTitles;
			this.isLFPR = apiRootResource?.features.lfpr;
		});
	}

	/**
	 * Creates and sets the tabtitel for the current register type with given suffix.
	 *
	 * @param tabTitleSuffix ignored in case of legacy window titles
	 */
	public setTitle(tabTitleSuffix: string) {
		let title;

		const register = this.isLFPR ? TitleService.LFPR_REGISTER : TitleService.SCHIFF_REGISTER;

		if (this.isLegacy) {
			const legacyPrefix = this.isLFPR ? '' : TitleService.LEGACY_PREFIX_HH;
			const legacySuffix = TitleService.LEGACY_SUFFIX;

			title = legacyPrefix + register + legacySuffix;
		} else {
			title = register + ' - ' + tabTitleSuffix;
		}

		this.ngTitleService.setTitle(title);
	}

	public getTitle(): string {
		return this.ngTitleService.getTitle();
	}
}

export enum TabTitle {
	ADMINISTRATION = 'Administration',
	DASHBOARD = 'Dashboard',
	REGISTER = 'Register',
	VERFAHREN = 'Verfahren'
}
