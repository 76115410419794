import {InjectionToken} from '@angular/core';
import 'whatwg-fetch';
import {EnvironmentLoadedAction} from './environment.actions';
import {reducer, State} from './environment.reducer';
import {getBaseUrl} from './environment.util';
import {FrontendEnvironment} from './environment.model';
import {Store} from '@ngrx/store';
import {AppState} from '@schir-int-client/api-root';
import {Observable} from 'rxjs';
import {frontendEnvironment} from './environment.selectors';
import {first} from 'rxjs/operators';

export const ENVIRONMENT_CONFIG = new InjectionToken('environmentConfig');

export enum ClientType {
	Web = 'web',
	Ios = 'ios'
}

export function loadEnvironment(url?: string, clientId: ClientType = ClientType.Web): Promise<FrontendEnvironment> {
	const envUrl = url ? url : `${getBaseUrl()}api/environment`;
	const headers = new Headers({
		'X-Client': clientId,
	});

	return window.fetch(envUrl, { headers })
		.then(response => response.json())
		.then(env => {
			window['__env__'] = env;
			return env;
		});
}

export function getEnvironmentFactory(): any {
	return window['__env__'];
}

export function frontendEnvironmentFactory(store: Store<AppState>): Observable<FrontendEnvironment> {
	return store.select(<any>frontendEnvironment).pipe(first());
}

export function getEnvironmentInitialState(): State {
	return reducer(undefined, new EnvironmentLoadedAction(getEnvironmentFactory()));
}
