import {Component} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {VorlageFormService} from './vorlage-upload.form.service';
import {BaseEditorComponent} from '@schir-int-client/tech';

@Component({
	selector: 'schir-int-client-add-vorlage-dialog',
	templateUrl: './add-vorlage-dialog.component.html',
	styleUrls: ['./add-vorlage-dialog.component.scss'],
	providers: [VorlageFormService],
})
export class AddVorlageDialogComponent extends BaseEditorComponent {

	constructor(private formService: VorlageFormService) {
		super();
	}

	get form(): UntypedFormGroup {
		return this.formService.form;
	}

	async submit(): Promise<boolean> {
		return this.formService.submit();
	}

	updateVorlage(file: File) {
		this.formService.updateFile(file);
	}
}
