import {Component, HostBinding, OnDestroy} from '@angular/core';
import {Observable, Subscription} from 'rxjs';
import {AufgabeFacade, AufgabeResource} from '@schir-int-client/aufgabe-shared';

@Component({
	selector: 'schir-int-client-adressaten-in-aufgabe-container',
	templateUrl: './adressaten-in-aufgabe-container.component.html',
	styleUrls: ['./adressaten-in-aufgabe-container.component.scss'],
	host: { 'class': 'x-section' },
})
export class AdressatenInAufgabeContainerComponent implements OnDestroy {

	aufgabe: Observable<AufgabeResource>;
	private subscriptions: Subscription[] = [];
	visible: boolean;

	constructor(private aufgabeFacade: AufgabeFacade) {

		this.aufgabe = this.aufgabeFacade.aufgabe;
		this.subscriptions.push(this.aufgabe.subscribe((aufgabe) => {
			this.visible = aufgabe != null;
		}));

	}

	ngOnDestroy(): void {
		this.subscriptions.forEach(s => s.unsubscribe());
	}

	@HostBinding('class')
	public get class(): string {
		return this.visible ? 'x-section' : 'x-section-hidden';
	}
}
