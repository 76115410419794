import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {AsSelectablePipe} from './register.pipe';
import {EffectsModule} from '@ngrx/effects';
import {RegisterBlattEffects} from './register.effects';
import {StoreModule} from '@ngrx/store';
import {getRegisterBlattReducer, registerBlattFeatureState, registerBlattReducerInjectionToken} from './register.state';

@NgModule({
	declarations: [AsSelectablePipe],
	imports: [
		CommonModule,
		EffectsModule.forFeature([RegisterBlattEffects]),
		StoreModule.forFeature(registerBlattFeatureState, registerBlattReducerInjectionToken),
	],
	providers: [
		{
			provide: registerBlattReducerInjectionToken,
			useFactory: getRegisterBlattReducer,
		},
	],
	exports: [AsSelectablePipe],
})
export class RegisterSharedModule {}
