import {Component, EventEmitter, Input, Output} from '@angular/core';
import {hasLink} from '@ngxp/rest';
import {AufgabeLinkRel, AufgabeResource} from '@schir-int-client/aufgabe-shared';

@Component({
	selector: 'schir-int-client-korrespondenz-button',
	templateUrl: './korrespondenz-button.component.html',
	styleUrls: ['./korrespondenz-button.component.scss'],
})
export class KorrespondenzButtonComponent {
	@Input() aufgabe: AufgabeResource;

	@Output() openKorrespondenz: EventEmitter<void> = new EventEmitter();

	constructor() { }

	get show(): boolean {
		return hasLink(this.aufgabe, AufgabeLinkRel.CREATE_KORRESPONDENZ_VORLAGE) || hasLink(this.aufgabe, AufgabeLinkRel.KORRESPONDENZ_VORLAGE);
	}

	get tooltip(): string {
		if (hasLink(this.aufgabe, AufgabeLinkRel.CREATE_KORRESPONDENZ_VORLAGE)) {
			return 'Korrespondenzvorlage erstellen';
		}
		if (hasLink(this.aufgabe, AufgabeLinkRel.KORRESPONDENZ_VORLAGE)) {
			return 'Korrespondenz ansehen';
		}
		return null;
	}
}
