import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {EffectsModule} from '@ngrx/effects';
import {EipEffects} from './eip.effects';

@NgModule({
	imports: [
		CommonModule,
		EffectsModule.forFeature([EipEffects]),
	],
})
export class EipSharedModule {}
