import {Action} from '@ngrx/store';
import {Adressat, AdressatWithResource} from '../aufgabe.model';

export enum AdressatActions {
	CREATE_ADRESSAT = 'CREATE_ADRESSAT',
	UPDATE_ADRESSAT_CHANNEL = 'UPDATE_ADRESSAT_CHANNEL',
	UPDATE_ADRESSAT_NOTIZ = 'UPDATE_ADRESSAT_NOTIZ',
	DELETE_ADRESSAT = 'DELETE_ADRESSAT',
}

export class CreateAdressatAction implements Action {
	type = AdressatActions.CREATE_ADRESSAT;

	constructor(public adressat: Adressat) { }
}

export class UpdateAdressatChannelAction implements Action {
	type = AdressatActions.UPDATE_ADRESSAT_CHANNEL;

	constructor(public adressat: AdressatWithResource) { }
}

export class UpdateAdressatNotizAction implements Action {
	type = AdressatActions.UPDATE_ADRESSAT_NOTIZ;

	constructor(public adressat: AdressatWithResource) { }
}

export class DeleteAdressatAction implements Action {
	type = AdressatActions.DELETE_ADRESSAT;

	constructor(public adressat: AdressatWithResource) { }
}
