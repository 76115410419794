import {createFeatureSelector, createSelector, MemoizedSelector} from '@ngrx/store';
import {getEmbeddedResource} from '@ngxp/rest';
import {StateResource} from '@schir-int-client/ngrx-helpers';
import {sameUri} from '@schir-int-client/tech';
import {isNil, isNull, isUndefined} from 'lodash-es';
import {AufgabeListLinkRel} from './aufgabe.linkrel';
import {AufgabeResource} from './aufgabe.model';
import {aufgabeFeatureState, AufgabeRootState} from './aufgabe.state';

export const selectFeature = createFeatureSelector<AufgabeRootState>(aufgabeFeatureState);

export const aufgabenSelector: MemoizedSelector<object, StateResource<AufgabeResource[]>> =
	createSelector(selectFeature, (state: AufgabeRootState) => {
		const aufgaben = state.aufgabeRoot.aufgaben;
		const resultAufgaben = isNull(aufgaben.resource) ? [] : <AufgabeResource[]>getEmbeddedResource(aufgaben.resource, AufgabeListLinkRel.AUFGABE_LIST);

		return { ...aufgaben, resource: resultAufgaben };
	});

export const adressatenByAufgabeSelector =
	createSelector(selectFeature, (state: AufgabeRootState, props) => {
		if (!isNil(state.aufgabeRoot.adressatenByAufgabe)) {
			let adressaten = state.aufgabeRoot.adressatenByAufgabe[props.aufgabeUri];
			return !isUndefined(adressaten) ? adressaten : null;
		}
		return null;
	});

export const vorlageByUriSelector =
	createSelector(selectFeature, (state: AufgabeRootState, props) => {
		if (!isNil(state.aufgabeRoot.vorlagenResourcesByUri)) {
			let vorlage = state.aufgabeRoot.vorlagenResourcesByUri[props.vorlageUri];
			return !isUndefined(vorlage) ? vorlage : null;
		}
		return null;
	});

export const aufgabeSelector: MemoizedSelector<Object, AufgabeResource> =
	createSelector(selectFeature, (state: AufgabeRootState) => {
		return isNil(state.aufgabeRoot.selectedAufgabe) ? null : state.aufgabeRoot.selectedAufgabe;
	});

export const isAufgabeSelectedSelector =
	createSelector(selectFeature, (state: AufgabeRootState, props) => {
		return !isNil(state.aufgabeRoot.selectedAufgabe) && sameUri(state.aufgabeRoot.selectedAufgabe, props.aufgabe);

	});
