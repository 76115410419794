<h2 mat-dialog-title tabindex="0" class="narrow" data-test-id="create-aufgabe-dialog-title">Aufgabe in Verfügung vom
	{{verfuegung.createdAt | formatDate}}</h2>

<form [formGroup]="formService.form">

	<div mat-dialog-content data-test-id="create-aufgabe-dialog-content">
		<mat-form-field appearance="outline">
			<mat-label>Aufgabentyp</mat-label>
			<mat-select cdkFocusInitial data-test-id="select-aufgaben-typ" [formControlName]="formServiceClass.feldAufgabeTyp">
				<mat-option *ngFor="let aufgabenTyp of validAufgabenTypen" [value]="aufgabenTyp"
					data-test-id="value-aufgaben-typ" class="focus-box-shadow-dark">
					{{ aufgabenTyp | labelByAufgabenTyp}}
				</mat-option>
			</mat-select>
			<mat-error data-test-id="aufgabetyp-error">{{messages.CREATE_AUFGABE_TYPE_MISSING}}</mat-error>
		</mat-form-field>
		<schir-int-client-aufgabe-zertifikat-editor *ngIf="formService.controlGroupZertifikat.enabled"
			data-test-id="aufgabe-zertifikat">
		</schir-int-client-aufgabe-zertifikat-editor>
		<schir-int-client-aufgabe-with-static-taetigkeit-editor *ngIf="formService.controlGroupAltesZertifikat.enabled"
			data-test-id="aufgabe-altes-zertifikat" [aufgabenTyp]="aufgabenTypen.ALTES_ZERTIFIKAT"
			[staticTaetigkeitLabel]="aufgabeTypStaticTaetigkeitLabel[aufgabenTypen.ALTES_ZERTIFIKAT]">
		</schir-int-client-aufgabe-with-static-taetigkeit-editor>
		<schir-int-client-aufgabe-versand-editor *ngIf="formService.controlGroupZertifikatsauszug.enabled"
			data-test-id="aufgabe-zertifikatsauszug" [aufgabenTyp]="aufgabenTypen.ZERTIFIKATSAUSZUG">
		</schir-int-client-aufgabe-versand-editor>
		<schir-int-client-aufgabe-versand-editor *ngIf="formService.controlGroupAbschriftZertifikatsauszug.enabled"
			data-test-id="aufgabe-abschrift-zertifikatsauszug"
			[aufgabenTyp]="aufgabenTypen.ABSCHRIFT_ZERTIFIKATSAUSZUG">
		</schir-int-client-aufgabe-versand-editor>
		<schir-int-client-aufgabe-with-static-taetigkeit-editor *ngIf="formService.controlGroupAlterAuszug.enabled"
			data-test-id="aufgabe-alter-auszug" [aufgabenTyp]="aufgabenTypen.ALTER_AUSZUG"
			[staticTaetigkeitLabel]="aufgabeTypStaticTaetigkeitLabel[aufgabenTypen.ALTER_AUSZUG]">
		</schir-int-client-aufgabe-with-static-taetigkeit-editor>
		<schir-int-client-aufgabe-versand-editor *ngIf="formService.controlGroupHypothekenbescheinigung.enabled"
			data-test-id="aufgabe-hypothekenbescheinigung" [aufgabenTyp]="aufgabenTypen.HYPOTHEKENBESCHEINIGUNG">
		</schir-int-client-aufgabe-versand-editor>
		<schir-int-client-aufgabe-versand-editor *ngIf="formService.controlGroupEigentuemerbescheinigung.enabled"
			data-test-id="aufgabe-eigentuemerbescheinigung" [aufgabenTyp]="aufgabenTypen.EIGENTUEMERBESCHEINIGUNG">
		</schir-int-client-aufgabe-versand-editor>
		<schir-int-client-aufgabe-versand-editor *ngIf="formService.controlGroupLoeschungsbescheinigung.enabled"
			data-test-id="aufgabe-loeschungsbescheinigung" [aufgabenTyp]="aufgabenTypen.LOESCHUNGSBESCHEINIGUNG">
		</schir-int-client-aufgabe-versand-editor>
		<schir-int-client-aufgabe-versand-editor *ngIf="formService.controlGroupLastenfreiheitsbescheinigung.enabled"
			data-test-id="aufgabe-lastenfreiheitsbescheinigung"
			[aufgabenTyp]="aufgabenTypen.LASTENFREIHEITSBESCHEINIGUNG">
		</schir-int-client-aufgabe-versand-editor>
		<schir-int-client-aufgabe-versand-editor *ngIf="formService.controlGroupNamensaenderungsbescheinigung.enabled"
			data-test-id="aufgabe-namensaenderungsbescheinigung"
			[aufgabenTyp]="aufgabenTypen.NAMENSAENDERUNGSBESCHEINIGUNG">
		</schir-int-client-aufgabe-versand-editor>
		<schir-int-client-aufgabe-abschrift-editor *ngIf="formService.controlGroupRegisterabschrift.enabled"
			data-test-id="aufgabe-registerabschrift">
		</schir-int-client-aufgabe-abschrift-editor>
		<schir-int-client-aufgabe-mitteilung-editor *ngIf="formService.controlGroupEintragungsmitteilung.enabled"
			data-test-id="aufgabe-eintragungsmitteilung">
		</schir-int-client-aufgabe-mitteilung-editor>
		<schir-int-client-aufgabe-messbrief-editor *ngIf="formService.controlGroupMessbrief.enabled"
 		    data-test-id="aufgabe-messbrief">
		</schir-int-client-aufgabe-messbrief-editor>
		<schir-int-client-aufgabe-with-static-taetigkeit-editor *ngIf="formService.controlGroupSonstige.enabled"
			data-test-id="aufgabe-sonstige" [aufgabenTyp]="aufgabenTypen.SONSTIGE">
		</schir-int-client-aufgabe-with-static-taetigkeit-editor>
		<schir-int-client-aufgabe-with-static-taetigkeit-editor *ngIf="formService.controlGroupAblage.enabled"
			data-test-id="aufgabe-ablage" [aufgabenTyp]="aufgabenTypen.ABLAGE">
		</schir-int-client-aufgabe-with-static-taetigkeit-editor>
		<schir-int-client-aufgabe-rechnungsstellung-editor *ngIf="formService.controlGroupRechnungsstellung.enabled"
			data-test-id="aufgabe-rechnungsstellung">
		</schir-int-client-aufgabe-rechnungsstellung-editor>
		<schir-int-client-aufgabe-with-static-taetigkeit-editor *ngIf="formService.controlGroupKosten.enabled"
			data-test-id="aufgabe-kosten" [aufgabenTyp]="aufgabenTypen.KOSTEN">
		</schir-int-client-aufgabe-with-static-taetigkeit-editor>
		<schir-int-client-aufgabe-wiedervorlage-editor *ngIf="formService.controlGroupWiedervorlage.enabled" [aufgabenTyp]="aufgabenTypen.WIEDERVORLAGE"
			data-test-id="aufgabe-wiedervorlage">
		</schir-int-client-aufgabe-wiedervorlage-editor>
		<schir-int-client-aufgabe-anschreiben-editor *ngIf="formService.controlGroupAnschreiben.enabled"
													 data-test-id="aufgabe-anschreiben"
													 [vorlagen]="vorlagen | async">
		</schir-int-client-aufgabe-anschreiben-editor>
		<schir-int-client-aufgabe-with-static-taetigkeit-editor *ngIf="formService.controlGroupLfprRegisterakteAnlegen.enabled"
																data-test-id="aufgabe-registerakte-anlegen"
																[aufgabenTyp]="aufgabenTypen.LFPR_REGISTERAKTE_ANLEGEN"
																[staticTaetigkeitLabel]="aufgabeTypStaticTaetigkeitLabel[aufgabenTypen.LFPR_REGISTERAKTE_ANLEGEN]">
		</schir-int-client-aufgabe-with-static-taetigkeit-editor>
		<schir-int-client-aufgabe-schuldtitel-editor *ngIf="formService.controlGroupLfprSchuldtitel.enabled"
													 data-test-id="aufgabe-schuldtitel">
		</schir-int-client-aufgabe-schuldtitel-editor>
		<schir-int-client-aufgabe-lfpr-mitteilung-editor *ngIf="formService.controlGroupLfprMitteilung.enabled" data-test-id="aufgabe-mitteilung">
		</schir-int-client-aufgabe-lfpr-mitteilung-editor>
		<schir-int-client-aufgabe-with-static-taetigkeit-editor *ngIf="formService.controlGroupLfprStatistik.enabled"
																data-test-id="aufgabe-statistik"
																[aufgabenTyp]="aufgabenTypen.LFPR_STATISTIK"
																[staticTaetigkeitLabel]="aufgabeTypStaticTaetigkeitLabel[aufgabenTypen.LFPR_STATISTIK]">
		</schir-int-client-aufgabe-with-static-taetigkeit-editor>
		<schir-int-client-aufgabe-with-static-taetigkeit-editor *ngIf="formService.controlGroupLfprVerweisungsvermerk.enabled"
																data-test-id="aufgabe-verweisungsvermerk"
																[aufgabenTyp]="aufgabenTypen.LFPR_VERWEISUNGSVERMERK"
																[staticTaetigkeitLabel]="aufgabeTypStaticTaetigkeitLabel[aufgabenTypen.LFPR_VERWEISUNGSVERMERK]">
		</schir-int-client-aufgabe-with-static-taetigkeit-editor>
		<schir-int-client-aufgabe-with-static-taetigkeit-editor *ngIf="formService.controlGroupLfprDBAktualisieren.enabled"
																data-test-id="aufgabe-db_aktualisieren"
																[aufgabenTyp]="aufgabenTypen.LFPR_DB_AKTUALISIEREN"
																[staticTaetigkeitLabel]="aufgabeTypStaticTaetigkeitLabel[aufgabenTypen.LFPR_DB_AKTUALISIEREN]">
		</schir-int-client-aufgabe-with-static-taetigkeit-editor>
		<schir-int-client-aufgabe-lfpr-blattabschrift-editor *ngIf="formService.controlGroupLfprBlattabschrift.enabled"
																data-test-id="aufgabe-blattabschrift">
		</schir-int-client-aufgabe-lfpr-blattabschrift-editor>
		<schir-int-client-aufgabe-with-static-taetigkeit-editor *ngIf="formService.controlGroupLfprNegativbescheinigung.enabled"
																data-test-id="aufgabe-negativbescheinigung"
																[aufgabenTyp]="aufgabenTypen.LFPR_NEGATIVBESCHEINIGUNG"
																[staticTaetigkeitLabel]="aufgabeTypStaticTaetigkeitLabel[aufgabenTypen.LFPR_NEGATIVBESCHEINIGUNG]">
		</schir-int-client-aufgabe-with-static-taetigkeit-editor>
		<schir-int-client-aufgabe-lfpr-kosten-editor *ngIf="formService.controlGroupLfprKosten.enabled"
																data-test-id="aufgabe-kosten">
		</schir-int-client-aufgabe-lfpr-kosten-editor>
		<schir-int-client-aufgabe-wiedervorlage-editor *ngIf="formService.controlGroupLfprWiedervorlage.enabled" [aufgabenTyp]="aufgabenTypen.LFPR_WIEDERVORLAGE"
													   data-test-id="aufgabe-wiedervorlage">
		</schir-int-client-aufgabe-wiedervorlage-editor>
		<schir-int-client-aufgabe-with-static-taetigkeit-editor *ngIf="formService.controlGroupLfprErledigt.enabled"
																data-test-id="aufgabe-erledigt"
																[aufgabenTyp]="aufgabenTypen.LFPR_ERLEDIGT"
																[staticTaetigkeitLabel]="aufgabeTypStaticTaetigkeitLabel[aufgabenTypen.LFPR_ERLEDIGT]">
		</schir-int-client-aufgabe-with-static-taetigkeit-editor>
		<schir-int-client-aufgabe-lfpr-weglegen-editor *ngIf="formService.controlGroupLfprWeglegen.enabled" [aufgabenTyp]="aufgabenTypen.LFPR_WEGLEGEN"
													   data-test-id="aufgabe-weglegen">
		</schir-int-client-aufgabe-lfpr-weglegen-editor>
		<schir-int-client-aufgabe-lfpr-weglegen-editor *ngIf="formService.controlGroupLfprSammlung.enabled" [aufgabenTyp]="aufgabenTypen.LFPR_SAMMLUNG"
													   data-test-id="aufgabe-sammlung">
		</schir-int-client-aufgabe-lfpr-weglegen-editor>
	</div>

	<mat-dialog-actions>
		<button mat-stroked-button type="button" (click)="onCancel()" class="focus-box-shadow-dark" data-test-id="cancel-button">Abbrechen</button>
		<button mat-flat-button color="primary" (click)="onSave()" type="submit" class="icon-right focus-box-shadow-dark" [disabled]="hasNoAufageTyp() || !isValid()" data-test-id="speichern-button">
			Speichern
		</button>
	</mat-dialog-actions>
</form>
