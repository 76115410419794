import {Action} from '@ngrx/store';
import {GerichtProfile, GerichtProfileResource} from './gerichtprofile.model';

export enum GerichtProfileActions {
	LOAD_GERICHT_PROFILE = 'LOAD_GERICHT_PROFILE',
	LOAD_GERICHT_PROFILE_SUCCESS = 'LOAD_GERICHT_PROFILE_SUCCESS',
	UPDATE_GERICHT_PROFILE = 'UPDATE_GERICHT_PROFILE',
	UPDATE_GERICHT_PROFILE_SUCCESS = 'UPDATE_GERICHT_PROFILE_SUCCESS'
}

export class LoadGerichtProfileAction implements Action {
	type = GerichtProfileActions.LOAD_GERICHT_PROFILE;
}

export class LoadGerichtProfileSuccessAction implements Action {
	type = GerichtProfileActions.LOAD_GERICHT_PROFILE_SUCCESS;

	constructor(public gerichtProfile: GerichtProfileResource) { }
}

export class UpdateGerichtProfileAction implements Action {
	type = GerichtProfileActions.UPDATE_GERICHT_PROFILE;

	constructor(public gerichtProfile: GerichtProfile) { }
}

export class UpdateGerichtProfileSuccessAction implements Action {
	type = GerichtProfileActions.UPDATE_GERICHT_PROFILE_SUCCESS;

	constructor(public gerichtProfile: GerichtProfileResource) { }
}
