import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {EffectsModule} from '@ngrx/effects';
import {StoreModule} from '@ngrx/store';
import {AlternativeTextEffects} from './alternative-text.effects';
import {
	alternativeTexteReducerInjectionToken,
	alternativeTextFeatureState,
	getAlternativeTexteReducer,
} from './alternative-text.state';

@NgModule({
	imports: [
		CommonModule,
		EffectsModule.forFeature([AlternativeTextEffects]),
		StoreModule.forFeature(alternativeTextFeatureState, alternativeTexteReducerInjectionToken),
	],
	providers: [
		{
			provide: alternativeTexteReducerInjectionToken,
			useFactory: getAlternativeTexteReducer,
		},
	],
})
export class AlternativeTextSharedModule {}
