import {Component, HostBinding, OnDestroy} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {VerfuegungFacade, VerfuegungResource} from '@schir-int-client/verfuegung-shared';
import {Observable, Subscription} from 'rxjs';
import {map, switchMap} from 'rxjs/operators';

@Component({
	selector: 'schir-int-client-aufgaben-in-verfuegung-container',
	templateUrl: './aufgaben-in-verfuegung-container.component.html',
	styleUrls: ['./aufgaben-in-verfuegung-container.component.scss'],
	host: { 'class': 'x-section' },
})
export class AufgabenInVerfuegungContainerComponent implements OnDestroy {

	verfuegung: Observable<VerfuegungResource>;
	private subscriptions: Subscription[] = [];
	visible: boolean;

	constructor(
		private verfuegungFacade: VerfuegungFacade,
		private route: ActivatedRoute,
	) {
		this.verfuegung = this.route.params.pipe(
			map(route => atob(decodeURIComponent(route['verfuegungId']))),
			switchMap(url => {
				verfuegungFacade.loadVerfuegung(url);
				return this.verfuegungFacade.verfuegung;
			}),
		);
		this.subscriptions.push(this.verfuegung.subscribe((verfuegung) => {
			this.visible = verfuegung != null;
		}));
	}

	ngOnDestroy(): void {
		this.subscriptions.forEach(s => s.unsubscribe());
	}

	@HostBinding('class')
	public get class(): string {
		return this.visible ? 'x-section' : 'x-section-hidden';
	}

}
