import {ROUTER_NAVIGATED} from '@ngrx/router-store';
import {Action} from '@ngrx/store';
import {createEmptyStateResource, createStateResource, StateResource} from '@schir-int-client/ngrx-helpers';
import {
	LoadFristenListeSuccessAction,
	LoadWiedervorlagenSuccessAction,
	WiedervorlageActions,
} from './wiedervorlage.actions';
import {DatumsBereichResource, WiedervorlageListResource} from './wiedervorlage.model';
import * as moment from 'moment';
import {DatumsBereichActions} from './datumsBereichActions';

export interface WiedervorlageState {
	wiedervorlagen: StateResource<WiedervorlageListResource>;
	fristenListe: StateResource<WiedervorlageListResource>;
}

export const initialState: WiedervorlageState = {
	wiedervorlagen: createEmptyStateResource(),
	fristenListe: createEmptyStateResource(),
};

export interface DatumsBereichState {
	datumsBereich: StateResource<DatumsBereichResource>;
}

export const initialDatumsBereichState: DatumsBereichState = {
	datumsBereich: createStateResource(<DatumsBereichResource>{ anfangsDatum: moment(0) }, false),
};

export function wiedervorlageReducer(state: WiedervorlageState = initialState, action: Action): WiedervorlageState {
	switch (action.type) {
		case WiedervorlageActions.LOAD_WIEDERVORLAGEN:
			return {
				...state,
				wiedervorlagen: createEmptyStateResource(true),
			};
		case WiedervorlageActions.LOAD_WIEDERVORLAGEN_SUCCESS:
			return {
				...state,
				wiedervorlagen: createStateResource((<LoadWiedervorlagenSuccessAction>action).wiedervorlagen),
			};
		case ROUTER_NAVIGATED:
		case WiedervorlageActions.CREATE_WIEDERVORLAGE_SUCCESS:
			return {
				...state,
				wiedervorlagen: { ...state.wiedervorlagen, reload: true },
			};
		case WiedervorlageActions.LOAD_FRISTENLISTE:
			return {
				...state,
				fristenListe: createEmptyStateResource(true),
			};
		case WiedervorlageActions.LOAD_FRISTENLISTE_SUCCESS:
			return {
				...state,
				fristenListe: createStateResource((<LoadFristenListeSuccessAction>action).fristenListe),
			};
		default:
			return state;
	}
}

export function datumsBereichReducer(state: DatumsBereichState = initialDatumsBereichState, action: Action): DatumsBereichState {
	switch (action.type) {
		case DatumsBereichActions.UPDATE_DATUMSBEREICH:
			return {
				...state,
				datumsBereich: createStateResource(<DatumsBereichResource>{
					anfangsDatum: action['von'],
					endDatum: action['bis'],
				}, false),
			};
		default:
			return state;
	}
}
