import {NgModule} from '@angular/core';
import {EffectsModule} from '@ngrx/effects';
import {StoreModule} from '@ngrx/store';
import {StoreDevtoolsModule} from '@ngrx/store-devtools';
import {getEnvironmentInitialState} from '@schir-int-client/environment';
import {environment} from '../../../environments/environment';
import {AppState} from './app-state.model';
import {effects} from './effects';
import {getReducers, reducersInjectionToken} from './reducers';
import {logger as storeLogger} from './logger';

export function onlyForDevEnv(returnForDevelopment, returnForProduction = []) {
	return environment.production ? returnForProduction : returnForDevelopment;
}

export function getInitialState(): Partial<AppState> {
	return {
		environment: getEnvironmentInitialState(),
	};
}

@NgModule({
	imports: [
		StoreModule.forRoot(reducersInjectionToken, {
			metaReducers: onlyForDevEnv([storeLogger]),
			initialState: getInitialState,
		}),
		EffectsModule.forRoot(effects),
		onlyForDevEnv(StoreDevtoolsModule.instrument({ maxAge: 50 })),
	],
	providers: [
		{
			provide: reducersInjectionToken,
			useFactory: getReducers,
		},

	],
})
export class SchirStoreModule {}
