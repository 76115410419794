import {Pipe, PipeTransform} from '@angular/core';
import {Adressat} from '@schir-int-client/aufgabe-shared';
import {isNil} from 'lodash-es';

@Pipe({ name: 'channelByAdressat' })
export class ChannelByAdressatPipe implements PipeTransform {
	transform(adressat: Adressat) {
		if (!isNil(adressat)) {
			const channelList: string[] = this.getChannels(adressat);
			return this.getChannelString(channelList);
		}
	}

	private getChannels(adressat: Adressat) {
		let channelList: string[] = [];
		if (adressat) {
			if (adressat.channelBrief) { channelList.push('Brief'); }
			if (adressat.channelAbholung) { channelList.push('Abholung'); }
			if (adressat.channelEmail) { channelList.push('E-Mail'); }
			if (adressat.channelFax) { channelList.push('Fax'); }
			if (adressat.channelEgvp) { channelList.push('EGVP'); }
		}
		return channelList;
	}

	private getChannelString(channelList: string[]): string {
		return channelList.length > 0 ? channelList.join(', ') : 'Kein Kanal';
	}
}
