import {Resource} from '@ngxp/rest';

export enum TextbausteinTargetColumn {
	AUFSCHRIFT = 'AUFSCHRIFT',
	FLAGGENRECHT = 'FLAGGENRECHT',
	VERAENDERUNGEN = 'VERAENDERUNGEN',
	ERWERBSGRUND = 'ERWERBSGRUND',
	EIGENTUEMER_ERWERBSGRUND = 'EIGENTUEMER_ERWERBSGRUND',
	BELASTUNG = 'BELASTUNG',
	VERAENDERUNGEN_HYPOTHEKEN = 'VERAENDERUNGEN_HYPOTHEKEN',
	VOLLSTRECKUNG = 'VOLLSTRECKUNG',
	GESAMTHAFT = 'GESAMTHAFT',
	ZINSEN = 'ZINSEN',
	RANGVERMERK = 'RANGVERMERK',
	MIGRATION = 'MIGRATION',
	VAW_VERFUEGUNG = 'VAW_VERFUEGUNG',
	BEWILLIGUNG_VERFUEGUNG = 'BEWILLIGUNG_VERFUEGUNG',
	EINSCHRAENKUNG_EIGENTUEMER = 'EINSCHRAENKUNG_EIGENTUEMER',
	OLG = 'OLG',
	VORMERKUNG = 'VORMERKUNG',
	VERZINSUNG = 'VERZINSUNG',
	RANGVORBEHALT = 'RANGVORBEHALT',
	RANGEINTRAG = 'RANGEINTRAG',
	VORRANGSVORBEHALT = 'VORRANGSVORBEHALT',
}

export interface Textbaustein {
	targetColumn: string,
	name: string,
	text: string,
	editedText: string,
	variablesByFieldName: { [fieldName: string]: string },
	disabled: boolean,
	ssr: boolean,
	sbr: boolean,
	bsr: boolean,
	ar: boolean,
	lr: boolean,
	orderIndex: number;
}

export interface TextbausteinResource extends Textbaustein, Resource {}

export interface TextbausteinListResource extends Resource {}


export enum TextbausteinId {
	_1F_FÜHRUNG_DER_BUNDESFLAGGE = 1,
	_1A_GENEHMIGUNG_AUSFLAGGUNG = 2,
	_1L = 3,
	_1RA_AUFHEBUNG_AUSFLAGGUNG = 4,
	_1N = 5,
	ERWERB = 6,
	ERBSCHEIN = 7,
	TESTAMENT = 8,
	ZUSCHLAG = 9,
	ZWANGSVERSTEIGERUNG = 10,
	INSO_ERÖFFNUNG = 11,
	VORMERKUNG = 13,
	_1RAUS_AUFHEBUNG_AUSFLAGGUNG_OHNE_BUNDESFLAGGE = 14,
	_1BP_BUNDESFLAGGE_MIT_VERTRETER = 16,
	_1AVERK = 17,
	_3HYP_HYPOTHEK = 18,
	_3AR_ARRESTVORMERKUNG = 22,
	_3LÖVO_LÖSCHUNGSVORMERKUNG = 23,
	_3AB_ABTRETUNG = 24,
	_3ABAN_ABTRETUNG_ANFANG = 25,
	_3MH_MITHAFT_HAFTUNG = 26,
	_3GA_AUSSCHEIDEN = 27,
	_3V_VERTEILUNG = 28,
	_3ZH_ZWANGSSICHERUNGSHYPOTHEK = 29,
	LEER_VOLLSTRECKUNG = 30,
	TEILBETRAG = 31,
	VOLLSTRECKBAR = 32,
	LEER_GESAMTHAFT = 33,
	BLÄTTER = 34,
	OHNE_ZINSEN = 35,
	JAHRESZINSEN = 36,
	BASISZINSSATZ = 37,
	_21_ALT_1 = 38,
	_21_ALT_2 = 39,
	_1H_HEIMATHAFENVERLEGUNG = 40,
	_1H_HEIMATORTVERLEGUNG = 41,
	_3RANG_RANGVERMERK = 42,
	_3ME_MITHAFT_ERLOSCHEN = 43,
	_3MUE_MITHAFT_ÜBERTRAGEN = 44,
	_3MH_MITHAFT = 45,
	_3MM_MITHAFT_MEHRERE = 46,
	FREITEXT = 47,
	VERLEGUNG_HEIMATORTVERLEGUNG = 48,
	LEER_RANGVERMERK = 49,
	RANG_VOR = 50,
	RANG_NACH = 51,
	GLEICHRANG_MIT = 52,
	VERLEGUNG_HEIMATHAFENVERLEGUNG = 53,
	UMWANDLUNG = 54,
	FERTIGSTELLUNG = 55,
	FREIGABEVERMERK = 56,
	LÖSCHUNG = 57,
	LÖSCHUNG_22_21 = 58,
	ÜBERTRAGUNG_SSR = 59,
	ÜBERTRAGUNG_BSR = 60,
	ÜBERTRAGUNG_SBR = 61,
	MIGRATION_LEER = 62,
	MIGRATION_AM,
	MIGRATION_EINGETRAGEN_AM,
	NEUANMELDUNG_LFPR,
	ERSUCHEN_LFPR,
	ZWANGSVERSTEIGERUNG_LFPR,
	NAMENSAENDERUNG_LFPR,
	LOESCHUNG_LFPR,
	WEITERE_AENDERUNG_LFPR,
	KENNZEICHENAENDERUNG_LFPR,
	ROLLENKENNUNGSAENDERUNG_LFPR,
	WIDERSPRUCH_A_LFPR,
	WIDERSPRUCH_B_LFPR,
	WIDERSPRUCH_LOESCHUNG_LFPR,
	VAW_LFPR,
	VAW_BESCHLUSS_LFPR,
	WIDERSPRUCH_PAR_21_LFPR,
	BEWILLIGUNG_BEWILLIGUNG_LFPR,
	BEWILLIGUNG_EINSTWEILIGE_VERFUEGUNG_LFPR,
	ZWANGSVERSTEIGERUNG_FAHRZEUG_LFPR,
	ZWANGSVERSTEIGERUNG_GEMEINSCHAFT_LFPR,
	INSOLVENZVERMERK_1_LFPR,
	INSOLVENZVERMERK_2_LFPR,
	INSOLVENZVERMERK_3_LFPR,
	RECHTSHAENGIGKEITSVERMERK_LFPR,

	EINSCHRAENKUNG_EIGENTUEMER_1_LFPR,
	EINSCHRAENKUNG_EIGENTUEMER_2_LFPR,
	EINSCHRAENKUNG_EIGENTUEMER_3_LFPR,

	OLG_LEERP_LFPR,
	OLG_BESCHLUSS_LFPR,

	LOESCHUNG_2_LFPR,
	REGISTERPFANDRECHT,
	GESAMTREGISTERPFANDRECHT,
	REGISTERPFANDRECHT_MIT_HOECHSTBETRAG,
	GESAMTREGISTERPFANDRECHT_MIT_HOECHSTBETRAG,
	REGISTERPFANDRECHT_DURCH_ZWANGSVOLLSTRECKUNG,
	REGISTERPFANDRECHT_VERWALTUNGSZWANGSVERFAHREN,
	VERZINSUNG_LEER,
	VERZINSUNG_MIT_ZINSSATZ,
	VERZINSUNG_MIT_ZINSSATZ_AUF_2,
	VERZINSUNG_MIT_ZINSSATZ_UND_NEBENLEISTUNG,
	VERZINSUNG_MIT_ZINSSATZ_UND_NEBENLEISTUNG_AUF_2,
	VORMERKUNG_LEER,
	VORMERKUNG_1,
	VORMERKUNG_2,
	VOLLSTRECKUNG_LEER_LFPR,
	VOLLSTRECKUNG_TEILBETRAG_LFPR,
	VOLLSTRECKUNG_VOLLSTRECKBAR_LFPR,
	RANGVORBEHALT_LEER,
	RANGVORBEHALT_UNTER_AUSNUTZUNG,
	RANGEINTRAG_LEER,
	RANGEINTRAG_EINTRAG,
	VORRANGSVORBEHALT_1,
	VORRANGSVORBEHALT_2,
	VERAENDERUNG_AN_REGISTERPFANDRECHTEN,
	INSOLVENZVERMERK_VERFUEGUNGSBESCHRAENKUNG,
	INSOLVENZVERMERK_EROEFFNUNG,
	LOESCHUNG_ROLLE_LFPR,
	ABTRETUNG = 121,
	BLÄTTER_LFPR = 122,
	VERZINSUNG_MIT_ZINSSATZ_AUF_1,
	VERZINSUNG_MIT_ZINSSATZ_UND_NEBENLEISTUNG_AUF_1,
	RICHTIGSTELLUNG_GESELLSCHAFTSNAME,
	_3GE_GELOESCHT,
	_3GE_GELOESCHT_BELASTUNG,
}

export const subTextbausteine = [
	TextbausteinId.RANG_NACH,
	TextbausteinId.RANG_VOR,
	TextbausteinId.GLEICHRANG_MIT,
	TextbausteinId.OHNE_ZINSEN,
	TextbausteinId.JAHRESZINSEN,
	TextbausteinId.BASISZINSSATZ,
	TextbausteinId.TEILBETRAG,
	TextbausteinId.VOLLSTRECKBAR,
	TextbausteinId.BLÄTTER,
	TextbausteinId.BLÄTTER_LFPR,
	TextbausteinId.MIGRATION_LEER,
	TextbausteinId.MIGRATION_AM,
	TextbausteinId.MIGRATION_EINGETRAGEN_AM,
	TextbausteinId.VAW_LFPR,
	TextbausteinId.VAW_BESCHLUSS_LFPR,
	TextbausteinId.BEWILLIGUNG_BEWILLIGUNG_LFPR,
	TextbausteinId.BEWILLIGUNG_EINSTWEILIGE_VERFUEGUNG_LFPR,
	TextbausteinId.VERZINSUNG_LEER,
	TextbausteinId.VERZINSUNG_MIT_ZINSSATZ,
	TextbausteinId.VERZINSUNG_MIT_ZINSSATZ_AUF_1,
	TextbausteinId.VERZINSUNG_MIT_ZINSSATZ_AUF_2,
	TextbausteinId.VERZINSUNG_MIT_ZINSSATZ_UND_NEBENLEISTUNG,
	TextbausteinId.VERZINSUNG_MIT_ZINSSATZ_UND_NEBENLEISTUNG_AUF_1,
	TextbausteinId.VERZINSUNG_MIT_ZINSSATZ_UND_NEBENLEISTUNG_AUF_2,
	TextbausteinId.VORMERKUNG_LEER,
	TextbausteinId.VORMERKUNG_1,
	TextbausteinId.VORMERKUNG_2,
	TextbausteinId.VOLLSTRECKUNG_LEER_LFPR,
	TextbausteinId.VOLLSTRECKUNG_TEILBETRAG_LFPR,
	TextbausteinId.VOLLSTRECKUNG_VOLLSTRECKBAR_LFPR,
	TextbausteinId.RANGVORBEHALT_LEER,
	TextbausteinId.RANGVORBEHALT_UNTER_AUSNUTZUNG,
	TextbausteinId.RANGEINTRAG_LEER,
	TextbausteinId.RANGEINTRAG_EINTRAG,
	TextbausteinId.VORRANGSVORBEHALT_1,
	TextbausteinId.VORRANGSVORBEHALT_2,
];
