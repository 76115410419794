<ng-container [ngSwitch]="hasRegisterBlatt() && useLink">
	<ng-container *ngSwitchCase="true">
		<a class="aktenzeichen"
		   data-test-id="aktenzeichen-link"
		   tabindex="0"
		   (click)="openRegisterblatt($event)"  (keydown.enter)="openRegisterblatt($event)">{{aktenzeichen}}</a>
	</ng-container>
	<ng-container *ngSwitchCase="false">
		<span class="aktenzeichen" data-test-id="aktenzeichen">{{aktenzeichen}}</span>
	</ng-container>
</ng-container>
