export enum PosteingangLinkRel {
	DOCUMENTS = 'documents',
	CREATE_VERFAHREN = 'createVerfahren',
	ZUGEORDNETE_VORGAENGE = 'zugeordneteVorgaenge',
	DELETE = 'delete'
}

export enum PosteingangListLinkRel {
	POSTEINGANG_LIST = 'posteingangList'
}

export enum DocumentListLinkRel {
	DOCUMENT_LIST = 'documentList'
}

export enum DocumentLinkRel {
	DOWNLOAD = 'download'
}
