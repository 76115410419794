import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import {getUrl, Resource, ResourceUri} from '@ngxp/rest';
import {doIfLoadingRequired, StateResource} from '@schir-int-client/ngrx-helpers';
import {combineLatest, Observable} from 'rxjs';
import {filter, map} from 'rxjs/operators';
import {
	CancelSignatureAction,
	CreateSignaturTaskAction,
	DownloadSignatureAction,
	DownloadSignatureTaskAction,
	DownloadSignerScript,
	LoadSignatureValidatorResponsesAction,
	OpenInProgressDialogAction,
} from './signatur.actions';
import {SignaturLinkRel} from './signatur.linkrel';
import {SignatureResource, SignatureValidatorResponseResource, SignaturTaskResource} from './signatur.model';
import {SignaturState} from './signatur.state';
import {
	inProgressDialogOpenSelector,
	signatureByUriSelector,
	signatureTaskSelector,
	signatureValidatorResponsesSelector,
} from './signature.selectors';
import {changeEntryListSelector} from '@schir-int-client/register-change';


@Injectable({ providedIn: 'root' })
export class SignaturFacade {

	constructor(private store: Store<SignaturState>) { }

	createSignaturTask(entryUris: ResourceUri[]) {
		this.store.dispatch(new CreateSignaturTaskAction(entryUris));
	}

	downloadSignatureTask() {
		this.store.dispatch(new DownloadSignatureTaskAction());
	}

	getSignatureTask(): Observable<SignaturTaskResource> {
		return this.store.select(signatureTaskSelector);
	}

	downloadSignerScript() {
		this.store.dispatch(new DownloadSignerScript());
	}

	cancelSignature() {
		this.store.dispatch(new CancelSignatureAction());
	}

	getInProgressDialogIsOpen(): Observable<boolean> {
		return this.store.select(inProgressDialogOpenSelector);
	}

	openInProgressDialog() {
		this.store.dispatch(new OpenInProgressDialogAction());
	}

	getSignature(resource: Resource): Observable<SignatureResource> {
		return this.store.select(signatureByUriSelector, { signatureUri: getUrl(resource, SignaturLinkRel.DOWNLOAD_SIGNATURE) }).pipe(
			filter(stateResource => !doIfLoadingRequired(stateResource, () => this.store.dispatch(new DownloadSignatureAction(resource)))),
			map(stateResource => stateResource.resource),
		);
	}

	getSignatureValidatorResponses(): Observable<StateResource<SignatureValidatorResponseResource>> {
		return combineLatest([this.store.select(changeEntryListSelector), this.store.select(signatureValidatorResponsesSelector)]).pipe(
			filter(([entryList]) => entryList.loaded),
			filter(([, selected]) => !doIfLoadingRequired(selected, () => this.store.dispatch(new LoadSignatureValidatorResponsesAction()))),
			map(([, selected]) => selected),
		);
	}
}
