import {createSelector} from '@ngrx/store';
import {createEmptyStateResource, StateResource} from '@schir-int-client/ngrx-helpers';
import {selectFeature} from 'libs/aufgabe-shared/src/lib/aufgabe.selectors';
import {AufgabeRootState} from '@schir-int-client/aufgabe-shared';
import {isNil} from 'lodash-es';
import {KorrespondenzVorlageResource} from './korrespondenz-vorlage.model';

export const korrespondenzVorlageSelector =
	createSelector(selectFeature, (state: AufgabeRootState, props) => {
		const resource = state.aufgabeRoot.korrespondenzVorlageByUri[props.korrespondenzVorlageUri];
		const result: StateResource<KorrespondenzVorlageResource> = isNil(resource) ? createEmptyStateResource() : resource;

		return result;
	});
