import {Component, OnDestroy, OnInit} from '@angular/core';
import {
	AdressverwaltungFacade,
	ASK_SWITCH_UNSAVED_KONTAKT_IN_EDIT,
	KontaktResource,
	KontaktSearchMode,
	KontaktWithEditMode,
	SearchInputWithModes,
} from '@schir-int-client/adressverwaltung-shared';
import {
	AccessibilityService,
	addAriaAttributesToMatSelect,
	BaseEditorComponent,
	DialogService,
} from '@schir-int-client/tech';
import {Observable, Subscription} from 'rxjs';
import {KontaktFormService} from './adressverwaltung-kontakt-view/kontakt.formservice';
import {first, skip, take} from 'rxjs/operators';
import {UntypedFormGroup} from '@angular/forms';
import {DialogButtonType} from '@schir-int-client/dialog-shared';

@Component({
	selector: 'schir-int-client-adressverwaltung-dialog',
	templateUrl: './adressverwaltung-dialog.component.html',
	styleUrls: ['./adressverwaltung-dialog.component.scss'],
})
export class AdressverwaltungDialogComponent extends BaseEditorComponent implements OnInit, OnDestroy {
	selectedKontakt: Observable<KontaktResource>;
	editMode: Observable<boolean>;
	searchParameters: SearchInputWithModes;
	private subscriptions: Subscription[] = [];

	constructor(private facade: AdressverwaltungFacade,
	            public formService: KontaktFormService,
	            public dialogService: DialogService,
	            public accessibilityService: AccessibilityService) {
		super();

		this.selectedKontakt = this.facade.selectedKontakt;
		this.editMode = this.facade.editMode;
	}

	get form(): UntypedFormGroup {
		return this.formService.form;
	}

	ngOnInit(): void {
		addAriaAttributesToMatSelect();
	}

	setSelectedKontakt(kontaktWithEditMode: KontaktWithEditMode): void {
		if (kontaktWithEditMode.editMode && this.formService.form.dirty) {
			this.proceedWithCheck(kontaktWithEditMode);
		} else {
			this.doSetSelectedKontakt(kontaktWithEditMode.kontakt);
		}
	}

	private proceedWithCheck(kontaktWithEditMode: KontaktWithEditMode): void {
		this.subscriptions.push(this.dialogService.openDialogSelection(ASK_SWITCH_UNSAVED_KONTAKT_IN_EDIT,
			['Abbrechen', 'Geänderte Daten verwerfen', 'Geänderte Daten speichern'],
			[DialogButtonType.CANCEL, DialogButtonType.CANCEL, DialogButtonType.SAVE],
			[false, false, !this.formService.isValid()],
		).pipe(take(1)).subscribe(data => {
			switch (data.selection) {
				case 0: {
					// abbrechen
					this.formService.form.updateValueAndValidity();
					this.accessibilityService.focusElementById('jurCheckbox');

					break;
				}
				case 1: {
					// Daten verwerfen
					this.doSetSelectedKontakt(kontaktWithEditMode.kontakt);

					break;
				}
				case 2: {
					// Daten speichern
					this.formService.submit();
					this.formService.deactivateEditMode();
					// FIXME: We need to wait for the UPDATE_KONTAKT event to finish before we select the kontakt, since
					//  UPDATE_KONTAKT_SUCCESS selects the updated kontakt (for whatever reason)
					//  is there a better way to do that?
					this.facade.selectedKontakt.pipe(skip(1), first()).subscribe(kontakt => {
						this.doSetSelectedKontakt(kontaktWithEditMode.kontakt);
					});

					break;
				}
			}
		}));
	}

	private doSetSelectedKontakt(kontakt: KontaktResource) {
		this.facade.setSelectedKontakt(kontakt);
		this.formService.patch(kontakt);
	}

	addKontakt(): void {
		this.facade.setSelectedKontakt(<any>{});
		this.formService.patchNewKontakt();
		this.formService.activateEditMode();
	}

	ngOnDestroy(): void {
		this.subscriptions.forEach(s => s.unsubscribe());
		this.facade.setSelectedKontakt(null);
		this.formService.deactivateEditMode();
	}

	onEsc() {
		this.subscriptions.push(this.editMode.pipe(first()).subscribe(_ => {
			super.onEsc();
		}));
	}

	async submit(): Promise<boolean> {
		return this.formService.submit();
	}

	onSubmit(searchString: string) {
		this.searchParameters = {
			searchString: searchString,
			searchModes: [KontaktSearchMode.BEHOERDEN, KontaktSearchMode.JURISTISCH, KontaktSearchMode.NATUERLICH, KontaktSearchMode.AKTIV],
		};
	}
}
