import {ActionReducerMap, createReducer, on} from '@ngrx/store';
import {TextbausteineListLinkRel, TextbausteinListResource, TextbausteinResource} from '@schir-int-client/textbaustein';
import {loadTextbausteine, loadTextbausteineSuccess, updateTextbausteinSuccess} from './textbaustein.actions';
import {getEmbeddedResource} from '@ngxp/rest';
import {isNil, isNull} from 'lodash-es';
import {sameUri} from '@schir-int-client/tech';
import {initialState, TextbausteinRootState} from './textbaustein.state';
import {createStateResource} from '@schir-int-client/ngrx-helpers';
import {InjectionToken} from '@angular/core';

export const textbausteinReducerInjectionToken = new InjectionToken<ActionReducerMap<TextbausteinRootState>>('Registered TextbausteinReducer');

export function getTextbausteinReducer(): ActionReducerMap<TextbausteinRootState> {
	return {
		textbausteinRoot: textbausteineReducer,
	};
}

export const textbausteineReducer = createReducer(
	initialState,
	on(loadTextbausteine, state => ({
		textbausteine: { ...state.textbausteine, loading: true },
	})),
	on(loadTextbausteineSuccess, (_state, { payload }) => ({
		textbausteine: createStateResource(toMap((payload))),
	})),
	on(updateTextbausteinSuccess, (state, { textbaustein }) => ({
		textbausteine: createStateResource(buildNewTextbausteinMap(state.textbausteine.resource, textbaustein)),
	})),
);

function toMap(listResource: TextbausteinListResource): { [targetColumn: string]: TextbausteinResource[] } {
	const textbausteine = <TextbausteinResource[]>getEmbeddedResource(listResource, TextbausteineListLinkRel.TEXTBAUSTEINE_LIST);
	if (isNull(textbausteine)) {
		return {};
	}
	let result: { [targetColumn: string]: TextbausteinResource[] } = {};

	textbausteine.forEach(baustein => {
		if (isNil(result[baustein.targetColumn])) {
			result = { ...result, [baustein.targetColumn]: [baustein] };
		} else {
			result[baustein.targetColumn].push(baustein);
		}
	});
	return result;
}

function buildNewTextbausteinMap(origMap: { [targetColumn: string]: TextbausteinResource[] }, toReplace: TextbausteinResource): { [targetColumn: string]: TextbausteinResource[] } {
	const result: { [targetColumn: string]: TextbausteinResource[] } = {};

	Object.keys(origMap).forEach(targetCol => {
		result[targetCol] = origMap[targetCol].map(baustein => sameUri(baustein, toReplace) ? toReplace : baustein);
	});

	return result;
}
