import {EipConfig} from '@schir-int-client/api-root';
import {EipConstants, EipUser} from './eip.model';
import {XmlUtil} from './xml/xml.util';
import {VerfahrenResource} from '@schir-int-client/verfahren-shared';

export class EipMessages {
	static registerApplicationRequest(clientId: string, clientInstanceId: string): string {
		return XmlUtil.obj2Xml({
			RegisterApplicationRequest: {
				$xmlns: 'http://eip.justiz.de/application/integration/v1.0',
				ClientId: clientId,
				ClientInstanceId: clientInstanceId,
			},
		});
	}

	static applicationReadyRequest(clientId: string, clientInstanceId: string): string {
		return XmlUtil.obj2Xml({
			ApplicationReadyRequest: {
				$xmlns: 'http://eip.justiz.de/application/integration/v1.0',
				ClientId: clientId,
				ClientInstanceId: clientInstanceId,
			},
		});
	}

	static loginResponse(user: EipUser): string {
		return XmlUtil.obj2Xml({
			LoginResponse: {
				$xmlns: 'http://eip.justiz.de/security/integration/v1.0',
				Result: {
					User: {
						Firstname: user.firstName,
						JobDescription: user.jobDesciption,
						Lastname: user.lastName,
						LoginId: user.loginId,
						Title: user.title,
					},
				},
			},
		});
	}

	static getCourtForOrganizationEntityResponse(config: EipConfig): string {
		return XmlUtil.obj2Xml({
			GetCourtForOrganizationEntityResponse: {
				$xmlns: 'http://eip.justiz.de/security/integration/v1.0',
				Result: {
					Court: {
						Identifier: config.courtId,
						Location: config.courtLocation,
						ShortDescription: config.courtDescription,
						Type: config.courtType,
						TypeId: config.courtTypeId,
					},
				},
			},
		});
	}

	static addNewPartRequest(windowHandle: string): string {
		return XmlUtil.obj2Xml({
			AddNewPartRequest: {
				$xmlns: 'http://eip.justiz.de/frame/integration/v1.0',
				PartTypeIdentifier: 'de.justiz.eip.schiffsregister.ui.part.schiffsregisterMainPart',
				ViewIdentifier: 'de.justiz.eip.schiffsregister.ui.view.centralmask',
				WindowHandle: windowHandle,
			},
		});
	}

	static showViewResponse(): string {
		return XmlUtil.obj2Xml({
			ShowViewResponse: {
				$xmlns: 'http://eip.justiz.de/application/integration/v1.0',
				Result: {},
			},
		});
	}

	static activateLawsuitContextRequest(aktenzeichen: string): string {
		return XmlUtil.obj2Xml({
			ActivateLawsuitContextRequest: {
				$xmlns: 'http://eip.justiz.de/lawsuitcontext/integration/v1.0',
				LawsuitId: 'schiffsregister_' + EipConstants.INSTITUTION.identifier + '_' + aktenzeichen,
			},
		});
	}

	static closePartRequest(partInstanceIdentifier: string): string {
		return XmlUtil.obj2Xml({
			ClosePartRequest: {
				$xmlns: 'http://eip.justiz.de/frame/integration/v1.0',
				PartInstanceIdentifier: partInstanceIdentifier,
			},
		});
	}

	static getLawsuitResponse(verfahren: VerfahrenResource) {
		let response = {
			GetLawsuitResponse: {
				Result: {
					Lawsuit: {
						Id: 'schiffsregister_K1101Z_' + verfahren.aktenzeichen,
						Name: verfahren.aktenzeichen,
						OrganizationalEntity: {
							'security:Identifier': 'OE_1',
							'security:Name': 'OE',
							'security:Number': 1,
						},
					},
				},
			},
		};

		response['GetLawsuitResponse']['$xmlns'] = 'http://eip.justiz.de/lawsuitcontext/integration/v1.0';
		response['GetLawsuitResponse']['$xmlns:security'] = 'http://eip.justiz.de/security/integration/v1.0';

		return XmlUtil.obj2Xml(response);
	}
}
