import {AfterContentInit, Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {Actions} from '@ngrx/effects';
import {getUrl} from '@ngxp/rest';
import {DialogService, HandlesBackdropClickAndEscapeKey, Spinner} from '@schir-int-client/tech';
import {
	VorgangActions,
	VorgangFacade,
	VorgangLinkRel,
	VorgangResource,
	VorgangStatusLabel,
	VorgangTypLabel,
	VorgangTypLabelLFPR,
	VorgangTypLabelSchiff,
} from '@schir-int-client/vorgang-shared';
import {Observable, Subscription} from 'rxjs';
import {AssignVorgangDialogComponent} from './assign-vorgang-dialog/assign-vorgang-dialog.component';
import {ApiRootFacade} from '@schir-int-client/api-root';

@Component({
	selector: 'schir-int-client-zugeordnete-vorgaenge',
	templateUrl: './zugeordnete-vorgaenge.component.html',
	styleUrls: ['./zugeordnete-vorgaenge.component.scss'],
})
export class ZugeordneteVorgaengeComponent
	extends HandlesBackdropClickAndEscapeKey<AssignVorgangDialogComponent> implements OnInit, AfterContentInit, OnDestroy {

	@ViewChild('spinner', { static: true }) spinnerRef: ElementRef;

	zugeordneteVorgaenge: Observable<VorgangResource[]>;

	vorgangStatusLabel = VorgangStatusLabel;
	vorgangsTypen: VorgangTypLabel;

	private spinner: Spinner;
	private subscription: Subscription;

	constructor(private facade: VorgangFacade,
	            private actions: Actions,
	            protected dialogService: DialogService,
	            private router: Router,
	            private apiRootFacade: ApiRootFacade) {
		super(dialogService);

		this.zugeordneteVorgaenge = this.facade.zugeordneteVorgaenge;
	}

	ngOnInit(): void {
		this.subscription = this.apiRootFacade.getCurrentApiRoot().subscribe(apiRoot =>
			this.vorgangsTypen = apiRoot.features.lfpr ? VorgangTypLabelLFPR : VorgangTypLabelSchiff,
		);
	}

	openAssignVorgangDialog(vorgang: VorgangResource) {
		this.matDialogRef = this.dialogService.openEditorDialog(this, AssignVorgangDialogComponent, {
			data: {
				vorgang,
			},
		});
	}

	ngAfterContentInit() {
		this.spinner = new Spinner(this.actions, VorgangActions.LOAD_ZUGEORDNETE_VORGAENGE, VorgangActions.ZUGEORDNETE_VORGAENGE_LOADED, this.spinnerRef);
	}

	ngOnDestroy() {
		this.spinner.destroy();
		if (this.subscription) {
			this.subscription.unsubscribe();
		}
	}

	goToVorgang(vorgang: VorgangResource) {
		this.router.navigate(['/verfahren', btoa(getUrl(vorgang, VorgangLinkRel.VERFAHREN)), {
			outlets: {
				'vorgang': btoa(getUrl(vorgang)),
			},
		}]);
	}
}
