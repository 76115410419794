import {Injectable} from '@angular/core';
import {format} from '@schir-int-client/tech';
import {isEmpty, isNil, isNumber, isUndefined} from 'lodash-es';
import {Moment} from 'moment';
import {Textbaustein} from './textbaustein.model';
import {formatNumber} from '@angular/common';

const DATE_VARS: string[] = [
	'VON', 'BIS', 'MIT_WIRKUNG_VOM', 'GENEHMIGUNG_AM', 'ANTRAGS_DATUM', 'BESCHLUSS_AM', 'TESTAMENT_DATUM',
	'BASIS_ZINSSATZ_DATUM', 'BEWILLIGT_AM', 'ANTRAGSDATUM', 'ABTRETUNGSDATUM', 'ABTRETUNGS_DATUM', 'URSPRUENGLICH_EINGETRAGEN_AM',
	'EROEFFNUNGS_DATUM', 'DATUM_GERICHT', 'DATUM_OLG', 'ERSUCHDATUM', 'BEWILLIGUNGSDATUM', 'DATUM_VERZINSUNG1', 'DATUM_VERZINSUNG2', 'DATUM_INSO',
];

const SUB_VARS: string[] = [
	'VOLLSTRECKUNG', 'GESAMTHAFT', 'ZINSEN', 'RANGVERMERK', 'MIGRATION', 'VAW_VERFUEGUNG', 'BEWILLIGUNG_VERFUEGUNG', 'EINSCHRAENKUNG_EIGENTUEMER', 'OLG', 'VORMERKUNG', 'VERZINSUNG', 'RANGVORBEHALT', 'RANGEINTRAG', 'VORRANGSVORBEHALT',
];

const POTENTIALLY_NATURAL_NUMBER_FIELDS: string[] = ['PARAGRAPH'];

const NO_FILL_UP_NUMBER_FIELDS: string[] = ['ZINSSATZ', 'ZINSSATZ1', 'ZINSSATZ2', 'VV_ZINSSATZ', 'PROZENTSATZ'];

/**
 * Achtung! Behandlung der Texte im Textbaustein ist historisch bedingt doppelt implementiert. Die andere Implementierung
 * liegt in TextbausteinProcessor.java. Beide Implementierungen, solange vorhanden, müssen sich gleich verhalten!
 */
@Injectable({ providedIn: 'root' })
export class TextbausteinParser {

	readonly PLATZHALTER_REGEX: RegExp = /#([A-Z\d_]+)(\[(\d+)\]\.([A-Z\d_]+))?#/g;

	public parse(textbaustein: Textbaustein, platzhalterWerte: { [fieldName: string]: string | number | Moment | Date | Array<Object> }, subTexte?: { [subField: string]: string }): string {
		let toParse: string = isEmpty(textbaustein.editedText) ? textbaustein.text : textbaustein.editedText;
		if (isNil(toParse)) {
			return '';
		}

		return this.parseText(textbaustein, toParse, platzhalterWerte, subTexte);
	}

	private parseText(textbaustein: Textbaustein, toParse: string, platzhalterWerte: { [fieldName: string]: string | number | Moment | Date | Array<Object> }, subTexte?: { [subField: string]: string }) {
		return toParse.replace(this.PLATZHALTER_REGEX, (platzhalterGroup, fieldGroup, _, indexGroup, attrFieldGroup) => {
			let value = platzhalterWerte[this.convertToFieldName(fieldGroup)];
			if (isNil(value)) {
				return this.isOptional(fieldGroup, textbaustein.name) ? '' : platzhalterGroup;
			}
			return this.getPlatzhalterValue(value, platzhalterGroup, fieldGroup, indexGroup, attrFieldGroup, subTexte);
		});
	}

	private getPlatzhalterValue(platzhalterWert: string | number | Moment | Date | Array<Object>, platzhalterGroup: string, fieldGroup: string, indexGroup: number | undefined, attrFieldGroup: string | undefined, subTexte: { [subField: string]: string }): string {
		const isList: boolean = !isUndefined(attrFieldGroup);
		let fieldValue = isList ? this.getListFieldValue(<Object>platzhalterWert[indexGroup], attrFieldGroup) : platzhalterWert;

		if (SUB_VARS.includes(fieldGroup) && !isUndefined(subTexte)) {
			fieldValue = subTexte[fieldGroup];
		}

		return this.format(fieldValue, isList ? attrFieldGroup : fieldGroup, platzhalterGroup);
	}

	private getListFieldValue(platzhalterValueObject: object, platzhalterName: string) {
		return platzhalterValueObject[this.convertToFieldName(platzhalterName)];
	}

	private convertToFieldName(platzhalterName: string): string {
		return platzhalterName.toLowerCase().replace(/_([^_])/g, (_, v) => v.toUpperCase());
	}

	private format(value: any, platzhalterName: string, platzhalterGroup: string): string {
		if (isNil(value)) {
			return platzhalterGroup;
		}
		if (DATE_VARS.includes(platzhalterName)) {
			return format(value);
		}
		if (isNumber(value)) {
			if ((this.isPotentiallyNaturalNumberField(platzhalterName) && isNaturalNumber(value)) || this.isNoFillUpNumberField(platzhalterName)) {
				return formatNumberLocale(value, '1.0-5');
			}
			return formatNumberLocale(value);
		}
		if (isEmpty(value)) {
			return platzhalterGroup;
		}
		return value;
	}

	private isPotentiallyNaturalNumberField(platzhalterName: string): boolean {
		return POTENTIALLY_NATURAL_NUMBER_FIELDS.includes(platzhalterName);
	}

	private isNoFillUpNumberField(platzhalterName: string): boolean {
		return NO_FILL_UP_NUMBER_FIELDS.includes(platzhalterName);
	}

	/**
	 * Optionale Platzhalter werden, falls Wert null oder undefined, durch Leerstring ersetzt. Diese Methode ist nur für einfache Strings gedacht.
	 * Gruppen, Listen usw. haben ihre eigene Behandlung.
	 */
	private isOptional(platzhalterName: any, textbausteinName: string) {
		const OPTIONAL_PLATZHALTER = {
			'Registerpfandrecht Verwaltungszwangsverfahren': ['VOLLSTRECKUNGSTITEL', 'VOLLSTRECKUNGSAKTENZEICHEN'],
		};
		return OPTIONAL_PLATZHALTER[textbausteinName]?.includes(platzhalterName);
	}
}

//TODO Prüfen, ob bessere Lösung als Redundanz zu register.util.ts
function isNaturalNumber(value: number) {
	return value % 1 == 0;
}

function formatNumberLocale(value: number, digitsInfo?: string) {
	if (isNaN(value)) {
		return '';
	}

	digitsInfo = isNil(digitsInfo) ? '1.2-5' : digitsInfo;
	return formatNumber(value, 'de-DE', digitsInfo);
}
