import {InjectionToken} from '@angular/core';
import {ActionReducerMap} from '@ngrx/store';
import {alternativeTextReducer, AlternativeTextState} from './alternative-text.reducer';
import {ReducerConfig} from '@schir-int-client/test-helpers';

export const alternativeTextFeatureState = 'AlternativeTextState';
export const alternativeTexteReducerInjectionToken = new InjectionToken<ActionReducerMap<AlternativeTextRootState>>('Registered AlternativeTextReducer');

export interface AlternativeTextRootState {
	alternativeTextRoot: AlternativeTextState;
}

export function getAlternativeTexteReducer(): ActionReducerMap<AlternativeTextRootState> {
	return {
		alternativeTextRoot: alternativeTextReducer,
	};
}

export const reducerConfig: ReducerConfig<AlternativeTextRootState> = {
	reducers: getAlternativeTexteReducer(),
	injectionToken: alternativeTexteReducerInjectionToken,
};
