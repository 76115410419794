<fieldset class="grid" *ngIf="formGroup.enabled" data-test-id="aufgabe-rechnungsstellung-root">
	<legend aria-label="Einstellungen zur Rechnungsstellung"></legend>
	<div class="row" [formGroup]="bodyGroup">
		<mat-radio-group aria-label="Bitte Option wählen" class="column"
			[formControlName]="formServiceClass.feldRechnungsstellungErledigung">
			<mat-radio-button color="primary" class="focus-box-shadow-dark" data-test-id="erledigt-radio-button" [value]="erledigung.ERLEDIGT">
				erledigt</mat-radio-button>
			<mat-radio-button color="primary" class="focus-box-shadow-dark" data-test-id="spaeter-radio-button" [value]="erledigung.SPAETER">
				später</mat-radio-button>
		</mat-radio-group>
	</div>
	<schir-int-client-notiz-in-aufgabe-editor class="row note" [aufgabenTyp]="aufgabenTyp">
	</schir-int-client-notiz-in-aufgabe-editor>
</fieldset>
