import {createFeatureSelector, createSelector} from '@ngrx/store';
import {isNil} from 'lodash-es';
import {userProfileFeatureState, UserProfileRootState} from './userprofile.state';

export const selectUserProfileFeature = createFeatureSelector<UserProfileRootState>(userProfileFeatureState);

export const userProfileSelector =
	createSelector(selectUserProfileFeature, (state: UserProfileRootState) => {
		if (isNil(state) || isNil(state.userProfileRoot)) {
			return null;
		}
		return state.userProfileRoot.userProfile;
	});

