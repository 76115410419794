import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import {ChangeFileAction, FileType} from '@schir-int-client/binary-shared';
import {isNull} from 'lodash-es';
import {filter, map, share, tap} from 'rxjs/operators';
import {DeleteVorlageAction, LoadVorlagenAction, SaveVorlageAction} from './vorlage.actions';
import {VorlageLinkRel} from './vorlage.linkrel';
import {CreateVorlage, VorlageResource} from './vorlage.model';
import {vorlagenSelector, vorlageSelector} from './vorlage.selectors';
import {VorlageRootState} from './vorlage.state';
import {doIfLoadingRequired, StateResource} from '@schir-int-client/ngrx-helpers';
import {ResourceUri} from '@ngxp/rest';
import {KorrespondenzVorlageResource} from '@schir-int-client/korrespondenz-shared';
import {Observable} from 'rxjs';

@Injectable()
export class VorlageFacade {

	private emptyArray: VorlageResource[] = [];

	constructor(private store: Store<VorlageRootState>) { }

	vorlagen: Observable<VorlageResource[]> = this.store.select(vorlagenSelector).pipe(
		tap(vorlagen => {
			if (isNull(vorlagen)) {
				this.store.dispatch(new LoadVorlagenAction());
			}
		}),
		map(vorlagen => {
			return vorlagen ? vorlagen : this.emptyArray;
		}),
		share(),
	);

	saveVorlage(vorlage: CreateVorlage) {
		this.store.dispatch(new SaveVorlageAction(vorlage));
	}

	deleteVorlage(vorlage: VorlageResource) {
		this.store.dispatch(new DeleteVorlageAction(vorlage));
	}

	changeFile(vorlage: VorlageResource, file: File) {
		this.store.dispatch(new ChangeFileAction(vorlage, VorlageLinkRel.UPLOAD_FILE, FileType.VORLAGE, file));
	}

	getVorlage(korrespondenzVorlage: KorrespondenzVorlageResource): Observable<StateResource<VorlageResource>> {
		const vorlageUri: ResourceUri = korrespondenzVorlage.vorlageId;

		return this.store.select(vorlageSelector({ vorlageUri: vorlageUri })).pipe(
			filter((vorlageResource: StateResource<VorlageResource>) => {
				return !doIfLoadingRequired(vorlageResource, () => this.store.dispatch(new LoadVorlagenAction()));
			}),
		);
	}
}
