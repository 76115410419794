import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {map, switchMap, withLatestFrom} from 'rxjs/operators';
import {UserProfileService} from './userprofile.service';
import {
	LoadUserProfileAction,
	LoadUserProfileSuccessAction,
	UpdateUserProfileAction,
	UpdateUserProfileSuccessAction,
	UserProfileActions,
} from './userprofile.actions';
import {UserProfileFacade} from './userprofile.facade';

@Injectable()
export class UserProfileEffects {
	constructor(
		private actions: Actions,
		private userProfileService: UserProfileService,
		private userProfileFacade: UserProfileFacade,
	) {}


	loadUserProfile = createEffect(() => this.actions.pipe(
		ofType(UserProfileActions.LOAD_USER_PROFILE),
		switchMap(action => {
			return this.userProfileService.getProfile((<LoadUserProfileAction>action).user).pipe(
				map(userProfile => new LoadUserProfileSuccessAction(userProfile)),
			);
		}),
	));


	updateUserProfile = createEffect(() => this.actions.pipe(
		ofType(UserProfileActions.UPDATE_USER_PROFILE),
		withLatestFrom(this.userProfileFacade.profile),
		switchMap(([action, profileToUpdate]) => {
			const newValues = (<UpdateUserProfileAction>action).userProfile;
			return this.userProfileService.updateProfile(profileToUpdate, newValues).pipe(
				map(updateProfile => new UpdateUserProfileSuccessAction(updateProfile)),
			);
		}),
	));
}
