/**
 * Übersetzt Zahl in deutsches Zahlwort, aktuell nur ganze Zahlen unter 1 Trillion
 */
export class NumberToWord {
	private unit: string[] = ['', 'tausend', ' Millionen ', ' Milliarden ', ' Billionen ', ' Billiarden '];
	private zahlwort: string[] = ['', 'ein', 'zwei', 'drei', 'vier', 'fünf', 'sechs', 'sieben', 'acht', 'neun'];
	private zehnerZahlwort: string[] = ['', 'zehn', 'zwanzig', 'dreißig', 'vierzig', 'fünfzig', 'sechzig', 'siebzig', 'achtzig', 'neunzig'];

	public map(n: number): string {
		if (n < 0) {
			throw new Error('negative values not supported');
		}
		let result = '';
		let pos = 0;
		const ganzteil = Math.floor(n);
		const bruch = n - ganzteil;
		n = ganzteil;
		while (n > 0) {
			result = this.appendUnit(this.map3(n % 1000), pos) + result;
			n = Math.floor(n / 1000);
			pos++;
		}
		result = result.trim();
		return (result === '' ? 'null' : result) + this.toPercent(bruch);
	}

	public mapStartWithCap(n: number): string {
		const value: string = this.map(n);
		return value.charAt(0).toUpperCase() + value.slice(1);
	}

	private toPercent(n: number): string {
		if (typeof n === 'number') {
			const percent = Math.round(n * 100);
			if (percent > 99 || Math.abs(percent - n * 100) > 0.001) {
				throw new Error('wrong number');
			}
			return percent > 0 ? ' ' + percent + '/100' : '';
		}
		return '';
	}

	private map3(n: number): string {
		const e = n % 10;
		const z = Math.floor(n / 10) % 10;
		const h = Math.floor(n / 100);

		let result = this.zahlwort[h] + (h > 0 ? 'hundert' : '') + this.zahlwort[e] + (e > 0 && z > 1 ? 'und' : '') + this.zehnerZahlwort[z];
		result = result.replace('einzehn', 'elf');
		result = result.replace('zweizehn', 'zwölf');
		result = result.replace('siebenzehn', 'siebzehn');
		return result;
	}

	private appendUnit(input: string, posOfUnit: number) {
		if (input === '') {
			return input;
		}
		let result = input + this.unit[posOfUnit];
		return result.replace(/ein ([MB].+n)en/, 'eine $1').replace(/ein ([MB].+rd)en/, 'eine $1e');
	}
}
