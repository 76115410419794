import {Injectable} from '@angular/core';
import {KontaktResource} from '@schir-int-client/adressverwaltung-shared';
import {TitleCasePipe} from '@angular/common';
import {isNil} from 'lodash-es';

@Injectable({
	providedIn: 'root',
})
export class MailAddressService {

	private mailAddress: string;

	constructor(private titleCasePipe: TitleCasePipe) {}

	/**
	 * Creates a preformatted string (for postal delivery) containing the address-values of a given contact.
	 * Will leave out falsy address-values.
	 * @param kontakt must not be null, but may contain falsy address-values
	 * @return empty string in case of missing address-values
	 */
	public createMailAddressForKontakt(kontakt: KontaktResource): string {
		this.mailAddress = '';

		if (kontakt.juristischePerson) {
			this.addLegalPersonValues(kontakt);
		} else {
			this.addNaturalPersonValues(kontakt);
		}

		this.addDefaultValues(kontakt);

		return this.mailAddress;
	}

	private addLegalPersonValues(kontakt: KontaktResource) {
		this.addLine([kontakt.firmenName]);
		this.addLine([kontakt.firmenName1]);
		this.addLine([kontakt.firmenName2]);
	}

	private addNaturalPersonValues(kontakt: KontaktResource) {
		this.addLine([this.titleCasePipe.transform(kontakt.anrede)]);
		this.addLine([kontakt.titel, kontakt.vorname, kontakt.name, kontakt.namenszusatz]);
	}

	private addDefaultValues(kontakt: KontaktResource) {
		this.addLine([kontakt.strasse, kontakt.hausnummer]);
		this.addLine([kontakt.adresszusatz]);
		this.addLine([kontakt.plz, kontakt.stadt]);
		this.addLine([kontakt.land], true);
	}

	/**
	 * Will add another line to the mail-address-string if at least one value is present in the array.
	 * A space is added after each value except for the last.
	 * @param values may contain falsy string values
	 * @param omitNewLine defaults to false. set true to omit adding '\n' at end of line
	 */
	private addLine(values: string[], omitNewLine?: boolean) {
		const filtered = values.filter(value => !isNil(value) && value != '');

		filtered.forEach((value, index) => {
			this.mailAddress += value;

			if (index < filtered.length - 1) {
				this.mailAddress += ' ';
			}
		});

		if (filtered.length > 0 && !omitNewLine) {
			this.mailAddress += '\n';
		}
	}
}
