export enum SignaturLinkRel {
	SIGNER_SCRIPT = 'signerScript',
	CANCEL = 'cancel',
	DOWNLOAD_SIGNATURE = 'signature'
}

export enum SignatureTaskLinkRel {
	CANCEL = 'cancel',
	VERFAHREN = 'verfahren',
	RELOAD = 'reload'
}

export enum SignatureValidatorResponseListLinkRel {
	SIGNATURE_VALIDATION_RESPONSE_LIST = 'signatureValidatorResponseList'
}
