import {Component, HostListener, Input} from '@angular/core';
import {UserFacade} from '@schir-int-client/user-shared';
import {DialogService, HandlesBackdropClickAndEscapeKey} from '@schir-int-client/tech';
import {UserSettingsDialogComponent} from './user-settings-dialog/user-settings-dialog.component';
import {ApiRootLinkRel, ApiRootResource} from '@schir-int-client/api-root';
import {GerichtSettingsDialogComponent} from './gericht-settings-dialog/gericht-settings-dialog.component';

@Component({
	selector: 'schir-int-client-settings-menu',
	templateUrl: './settings-menu.component.html',
	styleUrls: ['./settings-menu.component.scss'],
})
export class SettingsMenuComponent extends HandlesBackdropClickAndEscapeKey<UserSettingsDialogComponent | GerichtSettingsDialogComponent> {

	toolTipVisible: boolean;

	readonly apiRootLinkRel = ApiRootLinkRel;

	@Input()
	apiRoot: ApiRootResource;

	constructor(private userFacade: UserFacade,
	            protected dialogService: DialogService) {
		super(dialogService);
	}

	public logOut() {
		this.userFacade.logout();
	}

	@HostListener('window:resize', ['$event'])
	onResize(event) {
		this.toolTipVisible = window.innerWidth < 1400;
	}

	openProfileinstellungen() {
		this.matDialogRef = this.dialogService.openEditorDialog(this, UserSettingsDialogComponent, {
			panelClass: 'user-settings',
		});
	}

	openGerichtseinstellungen() {
		this.matDialogRef = this.dialogService.openEditorDialog(this, GerichtSettingsDialogComponent, {
			panelClass: 'gericht-settings',
		});
	}

	getConfirmMessage(): string {
		if (this.matDialogRef.componentInstance instanceof UserSettingsDialogComponent) {
			return 'Die Nutzereinstellungen enthalten ungesicherte Änderungen.';
		} else if (this.matDialogRef.componentInstance instanceof GerichtSettingsDialogComponent) {
			return 'Die Gerichtseinstellungen enthalten ungesicherte Änderungen.';
		}

		return 'Die Einstellungen enthalten ungesicherte Änderungen.';
	}
}
