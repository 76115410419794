import {Component, Input} from '@angular/core';
import {AufgabeFacade, AufgabeLinkRel, AufgabeResource, AufgabeService} from '@schir-int-client/aufgabe-shared';
import {StateResource} from '@schir-int-client/ngrx-helpers';
import {VerfuegungResource, VerfuegungTyp} from '@schir-int-client/verfuegung-shared';
import {Observable} from 'rxjs';
import {CreateAufgabeDialogComponent} from '../create-aufgabe-dialog/create-aufgabe-dialog.component';
import {DialogService, HandlesBackdropClickAndEscapeKey} from '@schir-int-client/tech';
import {hasLink} from '@ngxp/rest';
import {CreateKorrespondenzDialogComponent} from '../create-korrespondenz-dialog/create-korrespondenz-dialog.component';

@Component({
	selector: 'schir-int-client-aufgaben-in-verfuegung',
	templateUrl: './aufgaben-in-verfuegung.component.html',
	styleUrls: ['./aufgaben-in-verfuegung.component.scss'],
})
export class AufgabenInVerfuegungComponent extends HandlesBackdropClickAndEscapeKey<CreateAufgabeDialogComponent | CreateKorrespondenzDialogComponent> {
	@Input() verfuegung: VerfuegungResource;

	aufgaben: Observable<StateResource<AufgabeResource[]>>;

	constructor(private aufgabeFacade: AufgabeFacade,
	            protected dialogService: DialogService) {
		super(dialogService);

		this.aufgaben = aufgabeFacade.getAufgaben();
	}

	openAufgabeDialog(editMode = false) {
		this.matDialogRef = this.dialogService.openEditorDialog(this, CreateAufgabeDialogComponent, {
			data: {
				verfuegung: this.verfuegung,
				editMode: editMode,
			},
		});
	}

	openKorrespondenz(aufgabe) {
		this.aufgabeFacade.setAufgabe(aufgabe);
		if (hasLink(aufgabe, AufgabeLinkRel.KORRESPONDENZ_VORLAGE)) {
			this.aufgabeFacade.downloadKorrespondenz();
		} else {
			this.matDialogRef = this.dialogService.openEditorDialog(this, CreateKorrespondenzDialogComponent);
		}
	}

	sort(aufgaben: AufgabeResource[]): AufgabeResource[] {
		return AufgabeService.sortAufgabenByTyp(aufgaben);
	}

	isKorrespondenz(verfuegung) {
		return verfuegung.typ == VerfuegungTyp.KORRESPONDENZ;
	}
}
