import {Injectable} from '@angular/core';
import {ResourceFactory} from '@ngxp/rest';
import {VerfahrenLinkRel, VerfahrenResource} from '@schir-int-client/verfahren-shared';
import {Observable} from 'rxjs';
import {AlternativeTextLinkrel} from './alternative-text.linkrel';
import {
	AlternativeText,
	AlternativeTextListResource,
	AlternativeTextResource,
	CreateAlternativeText,
} from './alternative-text.model';
import {ChangeEntryLinkRel, ChangeEntryResource} from '@schir-int-client/register-change';

@Injectable({ providedIn: 'root' })
export class AlternativeTextService {
	constructor(private resourceFactory: ResourceFactory) { }

	public getAlternativeTexte(verfahren: VerfahrenResource): Observable<AlternativeTextListResource> {
		return this.resourceFactory.from(verfahren).get(VerfahrenLinkRel.ALTERNATIVE_TEXTE);
	}

	public createAlternativeText(changeEntry: ChangeEntryResource, alternativeText: CreateAlternativeText): Observable<AlternativeTextResource> {
		return this.resourceFactory.from(changeEntry).post(ChangeEntryLinkRel.CREATE_ALTERNATIVE_TEXT, alternativeText);
	}

	public updateAlternativeText(alternativeTexteResource: AlternativeTextResource, alternativeText: AlternativeText): Observable<AlternativeTextResource> {
		return this.resourceFactory.from(alternativeTexteResource).put(AlternativeTextLinkrel.SAVE, alternativeText);
	}

	public deleteAlternativeText(alternativeTextEntry: AlternativeTextResource) {
		return this.resourceFactory.from(alternativeTextEntry).delete(AlternativeTextLinkrel.DELETE);
	}

	public getAlternativeTextEntry(changeEntry: ChangeEntryResource): Observable<AlternativeTextResource> {
		return this.resourceFactory.from(changeEntry).get(ChangeEntryLinkRel.ALTERNATIVE_TEXT);
	}
}
