import {Component} from '@angular/core';
import {CreateAufgabeFormService} from '@schir-int-client/aufgabe';
import {CreateLfprVerfuegungFormservice} from './create-lfpr-verfuegung.formservice';
import {AufgabeMessages, AufgabenTyp, AufgabeTypStaticTaetigkeitLabel} from '@schir-int-client/aufgabe-shared';
import {BaseEditorComponent} from '@schir-int-client/tech';
import {UntypedFormGroup} from '@angular/forms';

@Component({
	selector: 'schir-int-client-create-lfpr-verfuegung-dialog',
	templateUrl: './create-lfpr-verfuegung-dialog.component.html',
	styleUrls: ['./create-lfpr-verfuegung-dialog.component.scss'],
	providers: [CreateLfprVerfuegungFormservice, CreateAufgabeFormService],
})
export class CreateLfprVerfuegungDialogComponent extends BaseEditorComponent {

	readonly messages = AufgabeMessages;
	readonly aufgabenTypen = AufgabenTyp;
	readonly aufgabeTypStaticTaetigkeitLabel = AufgabeTypStaticTaetigkeitLabel;

	constructor(public formService: CreateLfprVerfuegungFormservice) {
		super();
	}

	get form(): UntypedFormGroup {
		return this.formService.form;
	}

	isValid(): boolean {
		return this.formService.checkPreconditions();
	}

	async submit(): Promise<boolean> {
		return this.formService.submit();
	}
}
