import {Pipe, PipeTransform} from '@angular/core';
import {isNil} from 'lodash-es';

@Pipe({ name: 'enumToArray' })
export class EnumToArrayPipe implements PipeTransform {
	transform(enumData: Object) {
		if (!isNil(enumData)) {
			return Object.keys(enumData);
		}
		return null;
	}
}
