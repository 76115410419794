import {EventEmitter, Injectable, Output} from '@angular/core';
import {parseInt} from 'lodash-es';
import {subTextbausteine} from './textbaustein.model';

@Injectable()
export class TextbausteinFreigabetextService {

	@Output() textbausteinSelectedObservable = new EventEmitter<number>();

	textBausteinSelected(textbausteinUrl: string) {
		const strings = textbausteinUrl.split('/');
		const textbausteinId = parseInt(strings[strings.length - 1]);

		if (this.notSubTextbaustein(textbausteinId)) {
			this.textbausteinSelectedObservable.emit(textbausteinId);
		}
	}

	private notSubTextbaustein(textbausteinId: number) {
		return subTextbausteine.indexOf(textbausteinId) == -1;
	}
}
