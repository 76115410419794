import {Component, Input, OnChanges, OnDestroy} from '@angular/core';
import {Router} from '@angular/router';
import {getUrl, ResourceUri} from '@ngxp/rest';
import {
	VerfahrenFacade,
	VerfahrenLinkRel,
	VerfahrenMessages,
	VerfahrenResource,
	VerfahrenStammdaten,
	VerfahrenStatusLabel,
} from '@schir-int-client/verfahren-shared';
import {AmtlichenAusdruckErstellenDialogComponent} from '@schir-int-client/alternative-text';
import {DialogService, HandlesBackdropClickAndEscapeKey, NotizDialogComponent} from '@schir-int-client/tech';
import {isNil} from 'lodash-es';
import {Observable, Subscription} from 'rxjs';
import {
	AktenzeichenRenderer,
	AktenzeichenRendererProvider,
	AktenzeichenUtilService,
} from '@schir-int-client/aktenzeichen-shared';
import {take} from 'rxjs/operators';

@Component({
	selector: 'schir-int-client-verfahren-single',
	templateUrl: './verfahren-single.component.html',
	styleUrls: ['./verfahren-single.component.scss'],
})
export class VerfahrenSingleComponent extends HandlesBackdropClickAndEscapeKey<NotizDialogComponent | AmtlichenAusdruckErstellenDialogComponent> implements OnChanges, OnDestroy {

	@Input() verfahren: VerfahrenResource;

	private subscriptions: Subscription[] = [];

	isSelected: Observable<boolean>;
	verfahrenStatusLabel = VerfahrenStatusLabel;
	private renderer: AktenzeichenRenderer;

	constructor(private facade: VerfahrenFacade,
	            private router: Router,
	            protected dialogService: DialogService,
	            private rendererProvider: AktenzeichenRendererProvider,
	            private aktenzeichenUtil: AktenzeichenUtilService) {
		super(dialogService);

		this.renderer = this.rendererProvider.getRenderer();
	}

	ngOnChanges() {
		this.isSelected = this.facade.isVerfahrenSelected(this.verfahren);
	}

	ngOnDestroy() {
		this.subscriptions.forEach(s => s.unsubscribe());
	}

	openAmtlichenAuszugErstellenDialog() {
		this.selectVerfahren();
		this.matDialogRef = this.dialogService.openEditorDialog(this, AmtlichenAusdruckErstellenDialogComponent);
	}

	downloadSchiffszertifikat() {
		this.selectVerfahren();
		this.facade.downloadSchiffszertifikat();
	}

	downloadSchiffsbrief() {
		this.selectVerfahren();
		this.facade.downloadSchiffsbrief();
	}

	openRegisterBlatt() {
		const verfahrenUri: ResourceUri = this.verfahrenUri.replace('/', '_');
		this.router.navigate(['/verfahrenDetail/' + verfahrenUri]);
	}

	get verfahrenUri() {
		return btoa(getUrl(this.verfahren));
	}

	selectVerfahren() {
		this.facade.setSelectedVerfahren(this.verfahren);
	}

	openVerfahren($event: MouseEvent = null) {
		if ($event?.ctrlKey && $event?.altKey) {
			this.exportVerfahren();
			return;
		}

		this.selectVerfahren();

		const verfahrenUri: ResourceUri = this.verfahrenUri.replace('/', '_');
		this.router.navigate(['/verfahren/' + verfahrenUri]);
	}

	markAsAbgelehnt() {
		this.facade.markAsAbgelehnt(this.verfahren);
	}

	openNotizDialog() {
		const aktenzeichen = this.aktenzeichenUtil.parse(this.verfahren);
		const aktenzeichenRendered = this.renderer.render(aktenzeichen);

		const title = 'Notizen zum Verfahren ' + aktenzeichenRendered;

		this.matDialogRef = this.dialogService.openEditorDialog(this, NotizDialogComponent, {
			data: {
				title: title,
				notiz: this.verfahren.notiz,
			},
		});

		const editor = <NotizDialogComponent>this.matDialogRef.componentInstance;
		this.subscriptions.push(editor.notizEvent.pipe(take(1)).subscribe(data => {
			this.updateNotiz(data);
		}));
	}

	updateNotiz(notiz: string) {
		this.facade.updateNotiz(notiz, getUrl(this.verfahren, VerfahrenLinkRel.NOTIZ));
	}

	deleteVerfahren() {
		this.facade.delete(this.verfahren);
	}

	getSchiffName(): string {
		let schiffsName: string = '';

		if (!isNil(this.verfahren.stammdaten.schiffName)) {
			schiffsName += this.verfahren.stammdaten.schiffName;
		}
		if (!isNil(this.verfahren.stammdaten.unsignedSchiffName)) {
			schiffsName += '(' + this.verfahren.stammdaten.unsignedSchiffName + ')';
		}

		return schiffsName;
	}

	get stammdaten(): VerfahrenStammdaten {
		return this.verfahren.stammdaten;
	}

	get aktenzeichen() {
		const aktenzeichen = this.aktenzeichenUtil.parse(this.verfahren.aktenzeichen);
		return this.renderer.render(aktenzeichen).split(' ').join('_');
	}

	openUsignalZuordnenDialog() {
		const aktenzeichen = this.aktenzeichenUtil.parse(this.verfahren);
		const aktenzeichenRendered = this.renderer.render(aktenzeichen);
		this.subscriptions.push(this.dialogService
			.openDialogYesNo(VerfahrenMessages.CONFIRM_ASSIGN_USIGNAL.replace('{aktenzeichen}', aktenzeichenRendered))
			.pipe(take(1)).subscribe(data => {
				if (data.agree) {
					this.facade.assignUSignal(this.verfahren);
				}
			}));
	}

	private exportVerfahren() {
		const url: string = this.verfahren._links[VerfahrenLinkRel.EXPORT_VERFAHREN].href;
		this.facade.exportVerfahren(url);
	}
}
