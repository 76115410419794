<div class="x-section__headline">
	<h2 class="mat-h1 margin-right-auto" tabindex="0">Vorgänge zum Verfahren</h2>
</div>
<div class="x-section__subline">
	<i class="material-icons">file_copy</i>
	<h3 class="mat-body margin-right-auto" tabindex="0">Vorgänge</h3>
</div>
<div class="x-section__main">
	<div class="c-spinner" data-test-id="spinner" #spinner>
		<mat-spinner></mat-spinner>
	</div>
	<ul class="c-list" *ngIf="vorgaenge | async as _vorgaenge">
		<li class="c-list__item focus-border-dark" *ngFor="let vorgang of _vorgaenge" data-test-id="vorgang">
			<a class="c-list__link" data-test-id="go-to-vorgang" (click)="goToVorgang(vorgang)" (keyup.enter)="goToVorgang(vorgang)" tabindex="0">
				<div class="c-row c-row--header">
					<span class="c-row__item c-row__item--header"
						data-test-id="vorgang-typ">{{ vorgang.typ | enumToLabel: vorgangsTypen }}</span>
					<span class="c-row__item c-row__item--secondary"
						data-test-id="vorgang-status">{{vorgang.status | enumToLabel: vorgangStatusLabel }}</span>
				</div>
				<div class="c-row">
					<span class="c-row__item" data-test-id="vorgang-datum">{{vorgang.anlageDatum | formatDate }}</span>
				</div>
				<div class="c-row c-row--secondary">
					<span class="c-row__item">{{vorgang.notiz}}</span>
				</div>
			</a>
			<div class="c-button-bar c-button-bar--overlay">
				<schir-int-client-button-round data-test-id="assign-to-posteingang" toolTip="Posteingang dem Vorgang zuordnen"
					(click)="openAssignToPosteingangDialog(vorgang)" popup="dialog" materialIcon="post_add" cssClass="focus-box-shadow-dark">
				</schir-int-client-button-round>
			</div>
		</li>
	</ul>
</div>
