import {Injectable} from '@angular/core';
import {ResourceFactory} from '@ngxp/rest';
import {Observable} from 'rxjs';
import {AufgabeLinkRel} from '../aufgabe.linkrel';
import {Adressat, AdressatListResource, AdressatWithResource, AufgabeResource} from '../aufgabe.model';
import {AdressatLinkRel} from './adressat.linkrel';

@Injectable({ providedIn: 'root' })
export class AdressatService {
	constructor(private resourceFactory: ResourceFactory) { }

	createAdressaten(aufgabe: AufgabeResource, adressaten: Adressat[]): Observable<AdressatListResource> {
		return this.resourceFactory.from(aufgabe).post(AufgabeLinkRel.CREATE_ADRESSATEN, adressaten);
	}

	getAdressaten(aufgabe: AufgabeResource): Observable<AdressatListResource> {
		return this.resourceFactory.from(aufgabe).get(AufgabeLinkRel.ADRESSATEN);
	}

	updateChannel(adressat: AdressatWithResource): Observable<AdressatListResource> {
		const adressatWithChannel = {
			channelAbholung: adressat.channelAbholung,
			channelBrief: adressat.channelBrief,
			channelEgvp: adressat.channelEgvp,
			channelEmail: adressat.channelEmail,
			channelFax: adressat.channelFax,
		};
		return this.resourceFactory.from(adressat).patch(adressatWithChannel);
	}

	updateNotiz(adressat: AdressatWithResource): Observable<AdressatListResource> {
		return this.resourceFactory.from(adressat).patch(AdressatLinkRel.NOTIZ, <Adressat>adressat);
	}


	deleteAdressat(adressatWithResource: AdressatWithResource): Observable<AdressatListResource> {
		return this.resourceFactory.from(adressatWithResource).delete(AdressatLinkRel.DELETE);
	}
}
