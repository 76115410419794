import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {EffectsModule} from '@ngrx/effects';
import {StoreModule} from '@ngrx/store';
import {AdressverwaltungEffects} from './adressverwaltung.effects';
import {
	adressverwaltungFeatureState,
	adressverwaltungReducerInjectionToken,
	getAdressverwaltungReducer,
} from './adressverwaltung.state';

@NgModule({
	imports: [
		CommonModule,
		EffectsModule.forFeature([AdressverwaltungEffects]),
		StoreModule.forFeature(adressverwaltungFeatureState, adressverwaltungReducerInjectionToken),
	],
	providers: [
		{
			provide: adressverwaltungReducerInjectionToken,
			useFactory: getAdressverwaltungReducer,
		},
	],
})
export class AdressverwaltungSharedModule {}
