<div class="fileupload" data-test-id="fileupload" (keyup.enter)="openElement()" (keyup.space)="openElement()">
	<input (change)="onFileChanged($event)" [attr.id]="myId" [accept]="accept" style="display: none;" type="file" #fileInput />
	<ng-container *ngIf="!contentChildButton">
		<br />
		<label [attr.for]="myId">
			<a color="primary" mat-flat-button class="focus-box-shadow-dark">
				<span>{{defaultLabel}}</span>
			</a>
		</label>
	</ng-container>
	<label *ngIf="contentChildButton" [attr.for]="myId" data-test-id="fileupload-content-children">
		<ng-content></ng-content>
	</label>
</div>
