export enum AdressverwaltungMessages { }

export enum KontaktKategorieLabel {
	EIGENTUEMER_NEU = 'Eigentümer neu',
	EIGENTUEMER_ALT = 'Eigentümer alt',
	VERTRETER = 'Vertreter',
	BEHOERDEN = 'Behörden',
	GLAEUBIGER_NEU = 'Gläubiger neu',
	GLAEUBIGER_ALT = 'Gläubiger alt',
	NOTAR = 'Notar',
	SONSTIGE = 'Sonstige',
	RECHTSANWALT = 'Rechtsanwalt',
	PHG = 'PhG',
	GESELLSCHAFTER = 'Gesellschafter',
	EINGETRAGENER_EIGENTUEMER = 'Eingetragener Eigentümer',
}

export enum KontaktAnredeLabel {
	EMPTY = '',
	FRAU = 'Frau',
	HERR = 'Herr'
}

export const ASK_SWITCH_UNSAVED_KONTAKT_IN_EDIT = 'Der zum Bearbeiten ausgewählte Kontakt wurde noch nicht gespeichert.';
