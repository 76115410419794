import {Component, Input, OnChanges, OnDestroy} from '@angular/core';
import {AdressverwaltungFacade, KontaktKategorieLabel} from '@schir-int-client/adressverwaltung-shared';
import {AdressatFacade, AdressatLinkRel, AdressatWithResource} from '@schir-int-client/aufgabe-shared';
import {KorrespondenzFacade} from '@schir-int-client/korrespondenz-shared';
import {AddAdressatToAufgabeDialogComponent} from '../../kontakt-list/add-adressat-to-aufgabe-dialog/add-adressat-to-aufgabe-dialog.component';
import {AdressatFormService} from '../../kontakt-list/add-adressat-to-aufgabe-dialog/adressat.formservice';
import {DialogService, HandlesBackdropClickAndEscapeKey, NotizDialogComponent} from '@schir-int-client/tech';
import {isNil} from 'lodash-es';
import {filter, take} from 'rxjs/operators';
import {Subject, Subscription} from 'rxjs';

@Component({
	selector: 'schir-int-client-adressat-in-aufgabe',
	templateUrl: './adressat-in-aufgabe.component.html',
	styleUrls: ['./adressat-in-aufgabe.component.scss'],
	providers: [AdressatFormService],
})
export class AdressatInAufgabeComponent extends HandlesBackdropClickAndEscapeKey<NotizDialogComponent | AddAdressatToAufgabeDialogComponent> implements OnChanges, OnDestroy {

	@Input() adressat: AdressatWithResource;

	kontaktKategorieLabel = KontaktKategorieLabel;
	expand: boolean;

	readonly linkRel = AdressatLinkRel;

	private subscriptions: Subscription[] = [];
	private adressSubscription: Subscription;

	audioAlert: Subject<string> = new Subject<string>();

	constructor(protected dialogService: DialogService,
	            private adressatFacade: AdressatFacade,
	            private formService: AdressatFormService,
	            private korrespondenzFacade: KorrespondenzFacade,
	            private adressverwaltungFacade: AdressverwaltungFacade) {
		super(dialogService);
	}

	get form() {
		return this.formService.form;
	}

	ngOnChanges() {
		this.formService.patchChannel(this.adressat);
	}

	downloadKorrespondenz() {
		this.korrespondenzFacade.getKorrespondenz(this.adressat);
	}

	edit() {
		this.formService.patchChannel(this.adressat);
		this.matDialogRef = this.dialogService.openEditorDialog(this, AddAdressatToAufgabeDialogComponent, {
			data: {
				form: this.form,
				name: this.adressat.resource.name,
				vorname: this.adressat.resource.vorname,
				firmenName: this.adressat.resource.firmenName,
				juristischePerson: this.adressat.resource.juristischePerson,
				kategorie: this.adressat.kategorie,
			},
		});
		const editor = <AddAdressatToAufgabeDialogComponent>this.matDialogRef.componentInstance;
		this.subscriptions.push(editor.reassigned.pipe(filter(submitted => submitted), take(1)).subscribe(_ => {
			this.updateChannel();
		}));
	}

	updateChannel() {
		const adressat: AdressatWithResource = { ...this.adressat, ...this.formService.getChannel() };
		this.adressatFacade.updateChannel(adressat);
	}

	delete() {
		this.adressatFacade.deleteAdressat(this.adressat);
		this.audioAlert.next('Der Adressat wurde aus der Aufgabe gelöscht.');
	}

	openNotizDialog() {
		this.adressSubscription = this.adressverwaltungFacade.getKontaktByUri(this.adressat.kontakt).pipe(
			filter(kontakt => !isNil(kontakt)),
		).subscribe(kontakt => {
			const name = kontakt.juristischePerson ? kontakt.firmenName : kontakt.name;

			const title = 'Notiz zum Adressaten "' + name + '"';

			this.matDialogRef = this.dialogService.openEditorDialog(this, NotizDialogComponent, {
				data: {
					title: title,
					notiz: this.adressat.notiz,
				},
			});

			const editor = <NotizDialogComponent>this.matDialogRef.componentInstance;
			this.subscriptions.push(editor.notizEvent.pipe(take(1)).subscribe((data: string) => {
					this.updateNotiz(data);
				},
			));

			this.matDialogRef.afterClosed().pipe(take(1)).subscribe(() => {
				this.unsubscribeFromAdressSubscription();
			});
		});
	}

	private unsubscribeFromAdressSubscription() {
		if (!isNil(this.adressSubscription)) {
			this.adressSubscription.unsubscribe();
		}
	}

	private updateNotiz(notiz: string) {
		const adressat: AdressatWithResource = { ...this.adressat, notiz: notiz };
		this.adressatFacade.updateNotiz(adressat);
	}

	ngOnDestroy() {
		this.subscriptions.forEach(s => s.unsubscribe());
	}
}
