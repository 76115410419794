import {Action} from '@ngrx/store';
import {Resource} from '@ngxp/rest';
import {DocumentResource, PosteingangListResource, PosteingangResource} from './posteingang.model';

export enum PosteingangActions {
	LOAD_POSTEINGAENGE = 'LOAD_POSTEINGAENGE',
	POSTEINGAENGE_LOADED = 'POSTEINGAENGE_LOADED',
	LOAD_POSTEINGANG = 'LOAD_POSTEINGANG',
	POSTEINGANG_LOADED = 'POSTEINGANG_LOADED',
	ASKTO_POSTEINGANG_ABSCHLIESSEN = 'ASKTO_POSTEINGANG_ABSCHLIESSEN',
	POSTEINGANG_DELETE = 'POSTEINGANG_DELETE',
	POSTEINGANG_DELETED = 'POSTEINGANG_DELETED',
	DOWNLOAD_DOCUMENT = 'DOWNLOAD_DOCUMENT',
	LOAD_ASSIGNED_POSTEINGAENGE = 'LOAD_ASSIGNED_POSTEINGAENGE',
	LOAD_ASSIGNED_POSTEINGAENGE_SUCCESS = 'LOAD_ASSIGNED_POSTEINGAENGE_SUCCESS'
}

export class LoadPosteingaengeAction implements Action {
	type = PosteingangActions.LOAD_POSTEINGAENGE;
}

export class PosteingaengeLoadedAction implements Action {
	type = PosteingangActions.POSTEINGAENGE_LOADED;

	constructor(public payload: PosteingangListResource) { }
}

export class LoadPosteingangAction implements Action {
	type = PosteingangActions.LOAD_POSTEINGANG;

	constructor(public posteingangUri: string) { }
}

export class PosteingangLoadedAction implements Action {
	type = PosteingangActions.POSTEINGANG_LOADED;

	constructor(public payload: PosteingangResource) { }
}

export class AskToPosteingangAbschliessenAction implements Action {
	type = PosteingangActions.ASKTO_POSTEINGANG_ABSCHLIESSEN;
}

export class PosteingangDeleteAction implements Action {
	type = PosteingangActions.POSTEINGANG_DELETE;
}

export class PosteingangDeletedAction implements Action {
	type = PosteingangActions.POSTEINGANG_DELETED;
}

export class DownloadDocumentAction implements Action {
	type = PosteingangActions.DOWNLOAD_DOCUMENT;

	constructor(public payload: DocumentResource) { }
}

export class LoadAssigendPosteingaengeAction implements Action {
	type = PosteingangActions.LOAD_ASSIGNED_POSTEINGAENGE;

	constructor(public resource: Resource, public linkRel: string) { }
}

export class LoadAssigendPosteingaengeSuccessAction implements Action {
	type = PosteingangActions.LOAD_ASSIGNED_POSTEINGAENGE_SUCCESS;

	constructor(public payload: PosteingangListResource, public assignedTo: Resource) { }
}
