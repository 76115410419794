import {Action} from '@ngrx/store';
import {BinaryFileResource} from '@schir-int-client/binary-shared';
import {AufgabeResource} from '@schir-int-client/aufgabe-shared';
import {KorrespondenzCreateUserData, KorrespondenzVorlageResource} from './korrespondenz-vorlage.model';

export enum KorrespondenzVorlageActions {
	LOAD_KORRESPONDENZ_VORLAGE = 'LOAD_KORRESPONDENZ_VORLAGE',
	LOAD_KORRESPONDENZ_VORLAGE_SUCCESS = 'LOAD_KORRESPONDENZ_VORLAGE_SUCCESS',
	CREATE_KORRESPONDENZ_VORLAGE = 'CREATE_KORRESPONDENZ_VORLAGE',
	CREATE_KORRESPONDENZ_VORLAGE_SUCCESS = 'CREATE_KORRESPONDENZ_VORLAGE_SUCCESS',
	DOWNLOAD_KORRESPONDENZ_VORLAGE = 'DOWNLOAD_KORRESPONDENZ_VORLAGE',
	ASKTO_DELETE_KORRESPONDENZ_VORLAGE = 'ASKTO_DELETE_KORRESPONDENZ_VORLAGE',
	DELETE_KORRESPONDENZ_VORLAGE = 'DELETE_KORRESPONDENZ_VORLAGE',
	DELETE_KORRESPONDENZ_VORLAGE_SUCCESS = 'DELETE_KORRESPONDENZ_VORLAGE_SUCCESS'
}

export class LoadKorrespondenzVorlageAction implements Action {
	type = KorrespondenzVorlageActions.LOAD_KORRESPONDENZ_VORLAGE;

	constructor(public aufgabeResource: AufgabeResource) { }
}

export class LoadKorrespondenzVorlageSuccessAction implements Action {
	type = KorrespondenzVorlageActions.LOAD_KORRESPONDENZ_VORLAGE_SUCCESS;

	constructor(public korrepsondenzVorlage: KorrespondenzVorlageResource) { }
}

export class CreateKorrespondenzVorlageAction implements Action {
	type = KorrespondenzVorlageActions.CREATE_KORRESPONDENZ_VORLAGE;

	constructor(public request: KorrespondenzCreateUserData) { }
}

export class CreateKorrespondenzVorlageSuccessAction implements Action {
	type = KorrespondenzVorlageActions.CREATE_KORRESPONDENZ_VORLAGE_SUCCESS;

	constructor(public binaryFile: BinaryFileResource) { }
}

export class DownloadKorrespondenzVorlageAction implements Action {
	type = KorrespondenzVorlageActions.DOWNLOAD_KORRESPONDENZ_VORLAGE;
}

export class AskToDeleteKorrespondenzVorlageAction implements Action {
	type = KorrespondenzVorlageActions.ASKTO_DELETE_KORRESPONDENZ_VORLAGE;

	constructor(public korrespondenzVorlage: KorrespondenzVorlageResource) { }
}

export class DeleteKorrespondenzVorlageAction implements Action {
	type = KorrespondenzVorlageActions.DELETE_KORRESPONDENZ_VORLAGE;

	constructor(public korrespondenzVorlage: KorrespondenzVorlageResource) { }
}

export class DeleteKorrespondenzVorlageSuccessAction implements Action {
	type = KorrespondenzVorlageActions.DELETE_KORRESPONDENZ_VORLAGE_SUCCESS;

	constructor(public korrespondenzVorlage: KorrespondenzVorlageResource) { }
}
