import {Injectable} from '@angular/core';
import {ResourceFactory} from '@ngxp/rest';
import {ApiRootLinkRel, ApiRootResource} from '@schir-int-client/api-root';
import {Observable} from 'rxjs';
import {UserListResource} from '../user.model';

@Injectable({ providedIn: 'root' })
export class RechtspflegerService {

	constructor(private resourceFactory: ResourceFactory) { }

	loadRechtspfleger(apiRootResource: ApiRootResource): Observable<UserListResource> {
		return this.resourceFactory.from(apiRootResource).get(ApiRootLinkRel.RECHTSPFLEGERS);
	}
}
