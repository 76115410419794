<mat-dialog-content data-test-id="adressverwaltung-dialog-content">
	<schir-int-client-adressverwaltung-kontakt-list data-test-id="adressverwaltung-kontakt-list-root"
		[selectedKontakt]="selectedKontakt | async" [editMode]="editMode | async" [searchParameters]="searchParameters"
		(setSelectedKontakt)="setSelectedKontakt($event)" (addKontakt)="addKontakt()">
	</schir-int-client-adressverwaltung-kontakt-list>
	<schir-int-client-adressverwaltung-kontakt-view data-test-id="adressverwaltung-kontakt-view-root"
		[selectedKontakt]="selectedKontakt | async" [editMode]="editMode | async" (onSubmit)="onSubmit($event)">
	</schir-int-client-adressverwaltung-kontakt-view>
</mat-dialog-content>
<mat-dialog-actions data-test-id="adressverwaltung-dialog-actions">
	<button mat-stroked-button data-test-id="cancel-button" class="focus-box-shadow-dark" (click)="onCancel()">Schließen</button>
</mat-dialog-actions>
