import {Action, ActionReducer} from '@ngrx/store';
import {ActionType, EnvironmentLoadedAction} from './environment.actions';

export interface State {
	[key: string]: any;
}

const initialState: State = {};

export const reducer: ActionReducer<State> = (state: State = initialState, action: Action): State => {
	switch (action.type) {
		case ActionType.EnvironmentLoaded:
			return {
				...(<EnvironmentLoadedAction>action).payload,
			};
	}

	return state;
};
