import {Injectable} from '@angular/core';
import {ResourceFactory} from '@ngxp/rest';
import {BinaryFileResource} from '@schir-int-client/binary-shared';
import {AufgabeLinkRel, AufgabeResource} from '@schir-int-client/aufgabe-shared';
import {Observable} from 'rxjs';
import {mergeMap} from 'rxjs/operators';
import {KorrespondenzVorlageLinkRel} from './korrespondenz-vorlage.linkrel';
import {KorrespondenzVorlage, KorrespondenzVorlageResource} from './korrespondenz-vorlage.model';

@Injectable()
export class KorrespondenzVorlageService {
	constructor(private resourceFactory: ResourceFactory) { }

	loadKorrespondenzVorlage(aufgabe: AufgabeResource): Observable<KorrespondenzVorlageResource> {
		return this.resourceFactory.from(aufgabe).get(AufgabeLinkRel.KORRESPONDENZ_VORLAGE);
	}

	createKorrespondenzVorlage(aufgabe: AufgabeResource, request: KorrespondenzVorlage): Observable<BinaryFileResource> {
		return this.resourceFactory.from(aufgabe).post(AufgabeLinkRel.CREATE_KORRESPONDENZ_VORLAGE, request);
	}

	downloadKorrespondenzVorlage(aufgabe: AufgabeResource): Observable<BinaryFileResource> {
		return this.loadKorrespondenzVorlage(aufgabe).pipe(
			mergeMap(vorlage => this.resourceFactory.from(vorlage).get<BinaryFileResource>(KorrespondenzVorlageLinkRel.VORLAGE_FILE)),
		);
	}

	deleteKorrespondenzVorlage(KorrespondenzVorlage: KorrespondenzVorlageResource): Observable<void> {
		return this.resourceFactory.from(KorrespondenzVorlage).delete(KorrespondenzVorlageLinkRel.DELETE);
	}
}
