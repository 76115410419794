import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {EffectsModule} from '@ngrx/effects';
import {StoreModule} from '@ngrx/store';
import {VerfahrenEffects} from './verfahren.effects';
import {getVerfahrenReducer, verfahrenFeatureState, verfahrenReducerInjectionToken} from './verfahren.state';

@NgModule({
	imports: [
		CommonModule,
		EffectsModule.forFeature([VerfahrenEffects]),
		StoreModule.forFeature(verfahrenFeatureState, verfahrenReducerInjectionToken),
	],
	providers: [
		{
			provide: verfahrenReducerInjectionToken,
			useFactory: getVerfahrenReducer,
		},
	],
})
export class VerfahrenSharedModule {}
