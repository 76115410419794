<h2 mat-dialog-title tabindex="0" data-test-id="create-vorgang-dialog">Vorgang zu <schir-int-client-aktenzeichen [useLink]="false" [verfahren]="verfahren"></schir-int-client-aktenzeichen>
	hinzufügen</h2>
<mat-dialog-content [formGroup]="form">
	<mat-form-field appearance="outline" data-test-id="create-vorgang-dialog-typ-select">
		<mat-label>Vorgangstyp</mat-label>
		<mat-select data-test-id="one-value-select-input" [formControlName]="vorgangControlName" id="selectUebergabe">
			<mat-option [value]="null" data-test-id="one-value-select-delete" class="focus-box-shadow-dark"></mat-option>
			<mat-option *ngFor="let typ of filteredVorgangTypes" [value]="typ"
						data-test-id="one-value-select-value" class="focus-box-shadow-dark"> {{ typ | enumToLabel: vorgangsTypen }}</mat-option>
		</mat-select>
	</mat-form-field>
</mat-dialog-content>
<mat-dialog-actions>
	<button mat-stroked-button (click)="onCancel()" class="focus-box-shadow-dark" data-test-id="cancel-button">Abbrechen</button>
	<button mat-flat-button color="primary" [disabled]="!isValid()" class="icon-right focus-box-shadow-dark" data-test-id="hinzufuegen-button"
			(click)="onSave()">Hinzufügen
	</button>
</mat-dialog-actions>
