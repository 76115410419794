import {Injectable} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {CreateVorlage, VorlageFacade} from '@schir-int-client/vorlage-shared';

@Injectable()
export class VorlageFormService {
	public form: UntypedFormGroup;

	constructor(private formBuilder: UntypedFormBuilder, private facade: VorlageFacade) {
		this.initForm();
	}

	private initForm(): void {
		this.form = this.formBuilder.group({
			name: [null, Validators.required],
			file: [null, Validators.required],
		});
	}

	public submit(): boolean {
		if (this.form.valid) {
			this.facade.saveVorlage(this.createVorlage());
			return true;
		}
		return false;
	}

	private createVorlage(): CreateVorlage {
		return <CreateVorlage>{ ...this.form.value, fileId: null };
	}

	public updateFile(file: File) {
		this.form.controls['file'].patchValue(file);
	}
}
