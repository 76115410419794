import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import {Adressat, AdressatWithResource} from '../aufgabe.model';
import {AufgabeRootState} from '../aufgabe.state';
import {
	CreateAdressatAction,
	DeleteAdressatAction,
	UpdateAdressatChannelAction,
	UpdateAdressatNotizAction,
} from './adressat.actions';

@Injectable({ providedIn: 'root' })
export class AdressatFacade {

	constructor(private store: Store<AufgabeRootState>) { }

	createAdressat(adressat: Adressat) {
		this.store.dispatch(new CreateAdressatAction(adressat));
	}

	updateChannel(adressat: AdressatWithResource) {
		this.store.dispatch(new UpdateAdressatChannelAction(adressat));
	}

	updateNotiz(adressat: AdressatWithResource) {
		this.store.dispatch(new UpdateAdressatNotizAction(adressat));
	}

	deleteAdressat(adressat: AdressatWithResource) {
		this.store.dispatch(new DeleteAdressatAction(adressat));
	}
}
