import * as jsonSchemaValidator from './lib/json-schema-validator';
import {toResource} from './lib/resource-helper';

const initSchemaValidator = jsonSchemaValidator.init;
const validateSchema = jsonSchemaValidator.validate;

export * from './lib/mocking';
export {
	configureTestEnvironment,
	configureEffectsTestEnvironment,
	elementFromFixture,
	expectElementFromFixture,
	ReducerConfig,
	expectElementsFromFixture,
	mergeModuleDefs,
	getModuleDefForStore,
} from './lib/setup-and-expect-helper';
export {initSchemaValidator, validateSchema, toResource};

