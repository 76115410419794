import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
import {DoNothingAction} from '@schir-int-client/ngrx-helpers';
import {of} from 'rxjs';
import {map, switchMap, withLatestFrom} from 'rxjs/operators';
import {
	loadTextbausteine,
	loadTextbausteineSuccess,
	updateTextbaustein,
	updateTextbausteinSuccess,
} from './textbaustein.actions';
import {TextbausteinService} from './textbaustein.service';
import {selectFeature} from './textbaustein.selectors';
import {getById, TextbausteinFeatureState} from './textbaustein.state';

@Injectable()
export class TextbausteinEffects {
	constructor(private actions: Actions, private service: TextbausteinService, private store: Store<TextbausteinFeatureState>) { }

	loadTextbausteine = createEffect(() => this.actions.pipe(
		ofType(loadTextbausteine),
		switchMap((action) =>
			this.service.getTextbausteine(action.resources).pipe(
				map(textbausteine => loadTextbausteineSuccess({ payload: textbausteine })),
			),
		),
	));

	updateTextbaustein = createEffect(() => this.actions.pipe(
		ofType(updateTextbaustein),
		withLatestFrom(this.store.select(selectFeature)),
		switchMap(([{ textbausteinId, editedText }, state]) => {
			const textbausteinResource = getById(state, textbausteinId);
			if (textbausteinResource?.editedText === editedText) {
				return of(new DoNothingAction());
			}
			return this.service.updateTextbaustein({
				...textbausteinResource,
				editedText,
			}).pipe(
				map(textbaustein => updateTextbausteinSuccess({ textbaustein })),
			);
		}),
	));
}
