import {Component, Input, OnChanges, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {getUrl} from '@ngxp/rest';
import {VerfahrenResource} from '@schir-int-client/verfahren-shared';
import {
	VorgangFacade,
	VorgangLinkRel,
	VorgangResource,
	VorgangStatusLabel,
	VorgangTypLabel,
	VorgangTypLabelLFPR,
	VorgangTypLabelSchiff,
} from '@schir-int-client/vorgang-shared';
import {Observable, Subscription} from 'rxjs';
import {ApiRootFacade} from '@schir-int-client/api-root';

@Component({
	selector: 'schir-int-client-vorgaenge-zum-verfahren-im-posteingang',
	templateUrl: './vorgaenge-zum-verfahren-im-posteingang.component.html',
	styleUrls: ['./vorgaenge-zum-verfahren-im-posteingang.component.scss'],
})
export class VorgaengeZumVerfahrenImPosteingangComponent implements OnInit, OnChanges, OnDestroy {

	@Input() verfahren: VerfahrenResource;

	vorgaenge: Observable<VorgangResource[]>;

	vorgangStatusLabel = VorgangStatusLabel;
	vorgangsTypen: VorgangTypLabel;
	private subscription: Subscription;

	constructor(
		private vorgangFacade: VorgangFacade,
		private router: Router,
		private apiRootFacade: ApiRootFacade,
	) {}

	ngOnInit() {
		this.vorgaenge = this.vorgangFacade.getVorgaengeByVerfahren(this.verfahren);

		this.subscription = this.apiRootFacade.getCurrentApiRoot().subscribe(apiRoot =>
			this.vorgangsTypen = apiRoot.features.lfpr ? VorgangTypLabelLFPR : VorgangTypLabelSchiff,
		);
	}

	ngOnDestroy(): void {
		if (this.subscription) {
			this.subscription.unsubscribe();
		}
	}

	ngOnChanges() {
		this.vorgaenge = this.vorgangFacade.getVorgaengeByVerfahren(this.verfahren);
	}

	openAssignToPosteingangDialog(vorgang: VorgangResource) {
		this.vorgangFacade.askToAssignVorgangToPosteingang(vorgang);
	}

	goToVorgang(vorgang: VorgangResource) {
		this.router.navigate(['/verfahren', btoa(getUrl(vorgang, VorgangLinkRel.VERFAHREN)), {
			outlets: {
				'vorgang': btoa(getUrl(vorgang)),
			},
		}]);
	}
}
