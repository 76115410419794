import {Component} from '@angular/core';
import {DialogService, HandlesBackdropClickAndEscapeKey} from '@schir-int-client/tech';
import {AddVorlageDialogComponent} from '../add-vorlage-dialog/add-vorlage-dialog.component';

@Component({
	selector: 'schir-int-client-vorlage-upload',
	templateUrl: './vorlage-upload.component.html',
	styleUrls: ['./vorlage-upload.component.scss'],
})
export class VorlageUploadComponent extends HandlesBackdropClickAndEscapeKey<AddVorlageDialogComponent> {

	constructor(public dialogService: DialogService) {
		super(dialogService);
	}

	openDialog() {
		this.matDialogRef = this.dialogService.openEditorDialog(this, AddVorlageDialogComponent);
	}
}
