import {Injectable} from '@angular/core';
import {getUrl, ResourceFactory} from '@ngxp/rest';
import {ApiRootLinkRel, ApiRootResource} from '@schir-int-client/api-root';
import {VorgangLinkRel, VorgangResource} from '@schir-int-client/vorgang-shared';
import {Observable} from 'rxjs';
import {Wiedervorlage, WiedervorlageListResource, WiedervorlageResource} from './wiedervorlage.model';
import {Moment} from 'moment/moment';

@Injectable({ providedIn: 'root' })
export class WiedervorlageService {
	constructor(private resourceFactory: ResourceFactory) {}

	public getAll(apiRoot: ApiRootResource, von: Moment, bis: Moment): Observable<WiedervorlageListResource> {
		const dateFormat = 'YYYY-MM-DD';
		const vonString = von.format ? von.format(dateFormat) : von;
		const bisString = bis.format ? bis.format(dateFormat) : bis;

		let url = getUrl(apiRoot, ApiRootLinkRel.WIEDERVORLAGEN);
		return this.resourceFactory.fromId(url + '?von=' + vonString + '&bis=' + bisString).get();
	}

	public create(vorgang: VorgangResource, wiedervorlage: Wiedervorlage): Observable<WiedervorlageResource> {
		return this.resourceFactory.from(vorgang).post(VorgangLinkRel.CREATE_WIEDERVORLAGE, wiedervorlage);
	}

	public update(vorgang: VorgangResource, wiedervorlage: Wiedervorlage): Observable<WiedervorlageResource> {
		return this.resourceFactory.from(vorgang).patch(VorgangLinkRel.UPDATE_WIEDERVORLAGE, wiedervorlage);
	}

	public getFristenListe(apiRoot: ApiRootResource): Observable<WiedervorlageListResource> {
		return this.resourceFactory.from(apiRoot).get(ApiRootLinkRel.WIEDERVORLAGEN_FRISTEN);
	}
}
