import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {
	AdressverwaltungFacade,
	KontaktKategorie,
	KontaktResource,
	KontaktWithKategorie,
} from '@schir-int-client/adressverwaltung-shared';
import {VerfahrenResource} from '@schir-int-client/verfahren-shared';
import {Observable} from 'rxjs';

@Component({
	selector: 'schir-int-client-kontakt-list',
	templateUrl: './kontakt-list.component.html',
	styleUrls: ['./kontakt-list.component.scss'],
})
export class KontaktListComponent implements OnInit {
	@Input() draggable: boolean = false;
	@Input() unassignable: boolean = false;
	@Input() verfahren: VerfahrenResource;

	@Output() unassignKontakt: EventEmitter<KontaktWithKategorie> = new EventEmitter();

	kategorie = KontaktKategorie;

	eigentuemerNeuKontakte: Observable<KontaktResource[]>;
	eigentuemerAltKontakte: Observable<KontaktResource[]>;
	vertreterKontakte: Observable<KontaktResource[]>;
	glaeubigerNeuKontakte: Observable<KontaktResource[]>;
	glaeubigerAltKontakte: Observable<KontaktResource[]>;
	notarKontakte: Observable<KontaktResource[]>;
	behoerdenKontakte: Observable<KontaktResource[]>;
	sonstigeKontakte: Observable<KontaktResource[]>;
	rechtsanwaltKontakte: Observable<KontaktResource[]>;
	phgKontakte: Observable<KontaktResource[]>;
	gesellschafterKontakte: Observable<KontaktResource[]>;
	eingetragenerEigentuemerKontakte: Observable<KontaktResource[]>;

	constructor(private facade: AdressverwaltungFacade) {}

	ngOnInit() {
		this.eigentuemerNeuKontakte = this.facade.eigentuemerNeuKontakte;
		this.eigentuemerAltKontakte = this.facade.eigentuemerAltKontakte;
		this.vertreterKontakte = this.facade.vertreterKontakte;
		this.glaeubigerNeuKontakte = this.facade.glaeubigerNeuKontakte;
		this.glaeubigerAltKontakte = this.facade.glaeubigerAltKontakte;
		this.notarKontakte = this.facade.notarKontakte;
		this.behoerdenKontakte = this.facade.behoerdenKontakte;
		this.sonstigeKontakte = this.facade.sonstigeKontakte;
		this.rechtsanwaltKontakte = this.facade.rechtsanwaltKontakte;
		this.phgKontakte = this.facade.phgKontakte;
		this.gesellschafterKontakte = this.facade.gesellschafterKontakte;
		this.eingetragenerEigentuemerKontakte = this.facade.eingetragenerEigentuemerKontakte;
	}
}
