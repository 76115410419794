<div class="focus-border-dark">
	<li class="c-list__item" [ngClass]="expand? 'c-list__item--expanded' : ''"
		[attr.data-test-id]="'kontakte-in-kategorie-root '+ (kontakt | testIdFromResource)">
		<a class="c-list__link" data-test-id="kontakt" (click)="expand = !expand" (keyup.enter)="expand = !expand"
		   [ngClass]="expand? 'c-list__item--expanded' : ''" [draggable]="draggable" (dragstart)="drag($event, kontakt)" tabindex="0">
			<div class="c-row c-row--header kontakt-headline" data-test-id="kontakt-headline">
				<span class="c-row__item c-row__item--header">{{kontakt | nameByKontaktPipe}}</span>
				<span *ngIf="!kontakt.active" class="inactive">(inaktiv)</span>
			</div>
			<div class="c-row">
				<span class="c-row__item" data-test-id="firmenName1">{{kontakt.firmenName1}}</span>
			</div>
			<div class="c-row">
				<span class="c-row__item" data-test-id="firmenName2">{{kontakt.firmenName2}}</span>
			</div>
			<div class="c-row mt-1">
				<span class="c-row__item" data-test-id="kategorie">{{kategorie | enumToLabel: kategorieLabel }}</span>
			</div>
			<div class="c-row mt-1">
				<span class="c-row__item" data-test-id="verfahrenKontaktAktenzeichen">{{kontakt.verfahrenKontaktAktenzeichen}}</span>
			</div>
			<div class="c-row c-row--secondary mt-1">
				<span class="c-row__item" data-test-id="verfahrenKontaktNotiz">{{kontakt.verfahrenKontaktNotiz}}</span>
			</div>
		</a>
		<div class="expandable" data-test-id="kontakt-data">
			<p data-test-id="strasse-hausnummer">{{kontakt.strasse}} {{kontakt.hausnummer}}</p>
			<p data-test-id="plz-stadt">{{kontakt.plz}} {{kontakt.stadt}}</p>
			<p data-test-id="notiz">{{kontakt.notiz}}</p>
		</div>
		<span class="visually-hidden" role="alert">{{audioAlert | async}}</span>
		<div class="c-button-bar c-button-bar--overlay">
			<schir-int-client-button-round (click)="openVerfahrenKontaktNotizDialog()"
										   popup="dialog"
										   toolTip="Notiz hinzufügen" materialIcon="notes" data-test-id="open-notiz-dialog-button"
										   cssClass="focus-box-shadow-dark">
			</schir-int-client-button-round>
			<schir-int-client-button-round (click)="openKontaktAktenzeichenDialog()"
										   popup="dialog"
										   toolTip="Aktenzeichen hinzufügen" materialIcon="topic" data-test-id="open-aktenzeichen-dialog-button"
										   cssClass="focus-box-shadow-dark">
			</schir-int-client-button-round>
			<schir-int-client-button-round *ngIf="showAssignButton" (click)="assignKontakt()"
										   toolTip="Kontakt als Adressat hinzufügen" materialIcon="add" data-test-id="assign-kontakt"
										   cssClass="focus-box-shadow-dark">
			</schir-int-client-button-round>
			<schir-int-client-button-round (click)="openKontaktInAdressverwaltung(kontakt)"
										   toolTip="Kontaktdaten bearbeiten" popup="dialog" materialIcon="edit" data-test-id="edit-kontakt"
										   cssClass="focus-box-shadow-dark">
			</schir-int-client-button-round>
			<schir-int-client-button-round *ngIf="hasUnassignLinkForKategorie" (click)="unassign()"
										   toolTip="Kontakt aus dem Verfahren löschen" materialIcon="delete" data-test-id="unassign-kontakt"
										   cssClass="focus-box-shadow-dark">
			</schir-int-client-button-round>
			<schir-int-client-button-round (click)="openFinanzSanktionsListe()"
										   toolTip="Aufruf der Finanzsanktionsliste" materialIcon="euro" data-test-id="link-to-fisalis"
										   cssClass="focus-box-shadow-dark">
			</schir-int-client-button-round>
			<schir-int-client-button-round (click)="copyMailAddressToClipboard(kontakt)"
										   toolTip="Adressdaten in die Zwischenablage kopieren" materialIcon="contact_mail" data-test-id="mail-address-button"
										   cssClass="focus-box-shadow-dark">
			</schir-int-client-button-round>
		</div>
	</li>
</div>
