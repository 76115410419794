import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatLegacyButtonModule as MatButtonModule} from '@angular/material/legacy-button';
import {MatLegacyCheckboxModule as MatCheckboxModule} from '@angular/material/legacy-checkbox';
import {MatLegacyOptionModule as MatOptionModule} from '@angular/material/legacy-core';
import {MatLegacyDialogModule as MatDialogModule} from '@angular/material/legacy-dialog';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatLegacyFormFieldModule as MatFormFieldModule} from '@angular/material/legacy-form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatLegacyInputModule as MatInputModule} from '@angular/material/legacy-input';
import {MatLegacyProgressSpinnerModule as MatProgressSpinnerModule} from '@angular/material/legacy-progress-spinner';
import {MatLegacySelectModule as MatSelectModule} from '@angular/material/legacy-select';
import {MatLegacyTableModule as MatTableModule} from '@angular/material/legacy-table';
import {RouterModule} from '@angular/router';
import {AufgabeModule} from '@schir-int-client/aufgabe';
import {AufgabeSharedModule} from '@schir-int-client/aufgabe-shared';
import {TechModule} from '@schir-int-client/tech';
import {VerfuegungSharedModule} from '@schir-int-client/verfuegung-shared';
import {VerfuegungenInVorgangContainerComponent} from './verfuegungen-in-vorgang-container/verfuegungen-in-vorgang-container.component';
import {CreateVerfuegungDialogComponent} from './verfuegungen-in-vorgang/create-verfuegung-dialog/create-verfuegung-dialog.component';
import {VerfuegungInVorgangComponent} from './verfuegungen-in-vorgang/verfuegung-in-vorgang/verfuegung-in-vorgang.component';
import {VerfuegungenInVorgangComponent} from './verfuegungen-in-vorgang/verfuegungen-in-vorgang.component';
import {CreateKorrespondenzVerfuegungDialogComponent} from './verfuegungen-in-vorgang/create-korrespondenz-verfuegung-dialog/create-korrespondenz-verfuegung-dialog.component';
import {CreateLfprVerfuegungDialogComponent} from './verfuegungen-in-vorgang/create-lfpr-verfuegung-dialog/create-lfpr-verfuegung-dialog.component';

@NgModule({
	declarations: [
		VerfuegungenInVorgangComponent,
		VerfuegungInVorgangComponent,
		VerfuegungenInVorgangContainerComponent,
		CreateVerfuegungDialogComponent,
		CreateLfprVerfuegungDialogComponent,
		CreateKorrespondenzVerfuegungDialogComponent,
	],
	imports: [
		CommonModule,
		VerfuegungSharedModule,
		AufgabeSharedModule,
		RouterModule,
		FormsModule,
		ReactiveFormsModule,
		MatIconModule,
		MatTableModule,
		MatInputModule,
		MatDialogModule,
		MatExpansionModule,
		MatFormFieldModule,
		MatSelectModule,
		MatOptionModule,
		MatCheckboxModule,
		MatButtonModule,
		MatProgressSpinnerModule,
		AufgabeModule,
		TechModule,
	],
	exports: [
		VerfuegungenInVorgangComponent,
		VerfuegungInVorgangComponent,
		CreateVerfuegungDialogComponent,
		CreateLfprVerfuegungDialogComponent,
		CreateKorrespondenzVerfuegungDialogComponent,
	],
})
export class VerfuegungModule {}
