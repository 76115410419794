import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import {AppState} from '@schir-int-client/api-root';
import {Observable} from 'rxjs';
import {LoadMonthlyStatistikAction, LoadStatistikAction, LoadZaehlblattStatistikAction} from './statistik.actions';
import {monthlyStatistikSelector, statistikSelector, zaehlblattStatistikSelector} from './statistik.selectors';
import {MonthlyStatistik, StatistikResource, ZaehlblattStatistikResource} from './statistik.model';

@Injectable({ providedIn: 'root' })
export class StatistikFacade {

	statistik: Observable<StatistikResource> = this.store.select(statistikSelector);
	monthlyStatistik: Observable<MonthlyStatistik> = this.store.select(monthlyStatistikSelector);
	zaehlblattStatistik: Observable<ZaehlblattStatistikResource> = this.store.select(zaehlblattStatistikSelector);

	constructor(private store: Store<AppState>) {}

	loadStatistik() {
		this.store.dispatch(new LoadStatistikAction());
	}

	loadMonthlyStatistik() {
		this.store.dispatch(new LoadMonthlyStatistikAction());
	}

	loadZaehlblattStatistik() {
		this.store.dispatch(new LoadZaehlblattStatistikAction());
	}
}
