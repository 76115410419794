import {Component, Input} from '@angular/core';
import {AufgabeAbschriftBody, AufgabeResource} from '@schir-int-client/aufgabe-shared';

@Component({
	selector: 'schir-int-client-aufgabe-abschrift-body',
	templateUrl: './aufgabe-abschrift-body.component.html',
	styleUrls: ['./aufgabe-abschrift-body.component.scss'],
})
export class AufgabeAbschriftBodyComponent {

	@Input() aufgabe: AufgabeResource;

	constructor() { }

	get body(): AufgabeAbschriftBody {
		return <AufgabeAbschriftBody>this.aufgabe.body;
	}
}
