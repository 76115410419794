import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {EffectsModule} from '@ngrx/effects';
import {StoreModule} from '@ngrx/store';
import {StatistikEffects} from './statistik.effects';
import {getStatistikReducer, statistikFeatureState, statistikReducerInjectionToken} from './statistik.state';

@NgModule({
	imports: [
		CommonModule,
		EffectsModule.forFeature([StatistikEffects]),
		StoreModule.forFeature(statistikFeatureState, statistikReducerInjectionToken),
	],
	providers: [
		{
			provide: statistikReducerInjectionToken,
			useFactory: getStatistikReducer,
		},
	],
})
export class StatistikSharedModule {}
