import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {EffectsModule} from '@ngrx/effects';
import {StoreModule} from '@ngrx/store';
import {SignaturEffects} from './signatur.effects';
import {SignaturFacade} from './signatur.facade';
import {getSignaturReducer, signaturReducerInjectionToken} from './signatur.reducer';
import {SignaturService} from './signatur.service';
import {signaturFeatureState} from './signatur.state';


@NgModule({
	imports: [
		CommonModule,
		EffectsModule.forFeature([SignaturEffects]),
		StoreModule.forFeature(signaturFeatureState, signaturReducerInjectionToken),
	],
	providers: [
		SignaturFacade,
		SignaturService,
		{
			provide: signaturReducerInjectionToken,
			useFactory: getSignaturReducer,
		},
	],
})
export class SignaturSharedModule {}
