<div class="focus-border-dark">
	<li class="c-list__item" data-test-id="verfahren-single" [ngClass]="isSelected() ? 'c-list__item--active' : ''">
		<a class="c-list__link" (click)="updateSelectedVerfahren()" (keyup.enter)="updateSelectedVerfahren()" data-test-id="adressverwaltung-link"
		   tabindex="0">
			<div class="c-row c-row--header">
				<schir-int-client-aktenzeichen data-test-id="aktenzeichen" class="c-row__item c-row__item--header" [verfahren]="verfahren" [useLink]="false"></schir-int-client-aktenzeichen>
				<span class="c-row__item c-row__item--secondary" data-test-id="status">{{verfahren.status | enumToLabel: verfahrenStatusLabel}}</span></div>
			<div class="c-row">
			<span class="c-row__item"
				  data-test-id="schiffs-und-eigentuemer-name">{{verfahren.stammdaten.schiffName}}</span>
			</div>
			<div class="c-row c-row--secondary">
				<span class="c-row__item">{{verfahren.notiz}}</span>
			</div>
			<i class="material-icons c-list__icon">arrow_right</i>
		</a>

		<div class="c-button-bar c-button-bar--overlay">
			<schir-int-client-button-round *ngIf="showAddUsignalButton()" materialIcon="notification_important"
										   data-test-id="add-usignal" (click)="openUsignalZuordnenDialog(verfahren)" toolTip="U-Sinal zuordnen"
										   popup="dialog" cssClass="focus-box-shadow-dark">
			</schir-int-client-button-round>
			<schir-int-client-create-vorgang-button [verfahren]="verfahren" [withPosteingang]="true" cssClass="focus-box-shadow-dark">
			</schir-int-client-create-vorgang-button>
			<schir-int-client-button-round (click)="downloadAusdruck(false)" data-test-id="download-pdf-button"
										   materialIcon="picture_as_pdf" toolTip="Registerausdruck erstellen und herunterladen" cssClass="focus-box-shadow-dark">
			</schir-int-client-button-round>
		</div>
	</li>
</div>
