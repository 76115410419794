import {Action} from '@ngrx/store';
import {Resource} from '@ngxp/rest';
import {BinaryFileResource, FileType} from './binary.model';

export enum BinaryActions {
	CHANGE_FILE = 'CHANGE_FILE',
	CHANGE_FILE_SUCCESS = 'CHANGE_FILE_SUCCESS',
	DOWNLOAD_FILE = 'DOWNLOAD_FILE'
}

export class ChangeFileAction implements Action {
	type = BinaryActions.CHANGE_FILE;

	constructor(public resource: Resource, public linkRel: string, public fileType: FileType, public file: File) { }
}

export class ChangeFileSuccessAction implements Action {
	type = BinaryActions.CHANGE_FILE_SUCCESS;

	constructor(public fileType: FileType) { }
}

export class DownloadFileAction implements Action {
	type = BinaryActions.DOWNLOAD_FILE;

	constructor(public binaryFile: BinaryFileResource) { }
}
