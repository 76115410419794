import {createFeatureSelector, createSelector, MemoizedSelector} from '@ngrx/store';
import {getEmbeddedResource} from '@ngxp/rest';
import {createEmptyStateResource, StateResource} from '@schir-int-client/ngrx-helpers';
import {isNil} from 'lodash-es';
import {KontaktListLinkRel} from './adressverwaltung.linkrel';
import {KontakteInVerfahren, KontaktResource} from './adressverwaltung.model';
import {adressverwaltungFeatureState, AdressverwaltungRootState} from './adressverwaltung.state';

const EMPTY_ARRAY = [];

export const selectFeature = createFeatureSelector<AdressverwaltungRootState>(adressverwaltungFeatureState);

export const kontakteSelector: MemoizedSelector<object, StateResource<KontaktResource[]>> =
	createSelector(selectFeature, (state: AdressverwaltungRootState) => {
		const fromState = state?.adressverwaltungRoot.kontakte;

		if (isNil(fromState)) {
			return createEmptyStateResource<KontaktResource[]>();
		}
		if (fromState.loaded) {
			return {
				...fromState,
				resource: <KontaktResource[]>getEmbeddedResource(fromState.resource, KontaktListLinkRel.KONTAKT_LIST),
			};
		}
		return { ...fromState, resource: EMPTY_ARRAY };
	});

export const kontakteInVerfahrenSelector: MemoizedSelector<object, StateResource<KontakteInVerfahren>> = createSelector(
	selectFeature,
	(state: AdressverwaltungRootState) => {
		const fromState = state?.adressverwaltungRoot.kontakteInVerfahren;
		if (isNil(fromState)) {
			return createEmptyStateResource<KontakteInVerfahren>();
		}
		if (fromState.loaded) {
			return { ...fromState, resource: fromState.resource };
		}
		return { ...fromState, resource: {} };
	},
);

export const selectedKontaktSelector: MemoizedSelector<object, KontaktResource> =
	createSelector(selectFeature, (state: AdressverwaltungRootState) => {
		if (!isNil(state.adressverwaltungRoot.selectedKontakt)) {
			return state.adressverwaltungRoot.selectedKontakt;
		}
		return null;
	});

export const editModeSelector: MemoizedSelector<object, boolean> =
	createSelector(selectFeature, (state: AdressverwaltungRootState) => state?.adressverwaltungRoot.editMode);
