import {Resource, ResourceUri} from '@ngxp/rest';
import {PartiellRoetungsBereich, SpaltenName} from '../../../register-shared/src/lib/register.model';

//CHANGE ENTRIES
export interface ChangeEntry {
	spaltenName: SpaltenName;
	positionInSpalte: number;
	freigabeText: string;
	spaltenWert: any;
	hiddenFreigabeText?: boolean;
}

export interface ChangeEntryResource extends ChangeEntry, Resource {}

export interface ChangeEntryListResource extends Resource {}

export interface ChangeEntryVermerkResource extends ChangeEntryVermerk, Resource {}

export interface ChangeEntryVermerk extends ChangeEntry {
	spaltenWert: {
		vermerk: string;
	};
}

export interface RegisterBlattChangeEntry {
	deleteEntry: Boolean;
	refId: string;
}

export interface ChangeEntryRoetungResource extends ChangeEntryRoetung, Resource {}

export interface ChangeEntryRoetung extends ChangeEntry {
	spaltenWert: {
		idGeroeteterEintrag: ResourceUri,
		geroeteterWert: string | number | Object
	};
}

export interface ChangeEntryPartielleRoetung extends ChangeEntry {
	spaltenWert: {
		idGeroeteterEintrag: ResourceUri,
		geroeteterWert: string,
		geroeteterBereich: PartiellRoetungsBereich
	};
}

export interface ChangeEntryTeilloeschenResource extends ChangeEntryTeilloeschen, Resource {}

export interface ChangeEntryTeilloeschen extends ChangeEntry {
	sortiermerkmal: number;
	spaltenWert: TeilloeschenWert;
}

export interface TeilloeschenWert {
	idTeilgeloeschterEintrag: ResourceUri,
	betragTeilloeschung: number
	waehrungTeilloeschung: string
}


export interface ChangeRoetenEntryResource extends ChangeEntryResource {}

export interface ChangePartiellRoetenEntryResource extends ChangeEntryResource {}

export interface ChangeEntryLaufendeNummerKorrekturResource extends ChangeEntryLaufendeNummerKorrektur, Resource {}

export interface ChangeEntryLaufendeNummerKorrektur extends ChangeEntry {
	spaltenWert: LaufendeNummerKorrekturWert;
}

export interface LaufendeNummerKorrekturWert {
	idKorrigierterEintrag: ResourceUri,
	korrigierteLaufendeNummer: string
}

export interface ChangeEntryBetragsKorrekturResource extends ChangeEntryBetragsKorrektur, Resource {}

export interface ChangeEntryBetragsKorrektur extends ChangeEntry {
	spaltenWert: BetragsKorrekturWert;
}

export interface BetragsKorrekturWert {
	idKorrigierterEintrag: ResourceUri,
	korrigierterBetrag: number,
	korrigierteWaehrung: string
}

export interface StringChangeEntry extends ChangeEntry {
	spaltenWert: {
		value: string
	};
}

export interface StringChangeEntryResource extends StringChangeEntry, Resource {}

export interface NumericChangeEntry extends ChangeEntry {
	spaltenWert: {
		value: number
	};
}

export interface NumericChangeEntryResource extends NumericChangeEntry, Resource {}

export interface FlaggenrechtChangeEntry extends ChangeEntry {}

export interface FlaggenrechtChangeEntryResource extends FlaggenrechtChangeEntry, ChangeEntryResource {}

export interface ChangeEntriesMap {
	[spaltenNamen: string]: ChangeEntryResource[];
}

export interface RegisterBlattChangeNumericEntry extends RegisterBlattChangeEntry {
	numericValue: number;
}

export interface VeraenderungEntry {
	spaltenWert: {
		zuSpalte: string;
		textbaustein: ResourceUri,
		text: string,
		platzhalterWerte: Object
	};
}

export interface GattungHauptbaustoffEntry {
	spaltenWert: GattungHauptbaustoffWert;
}

export interface GattungHauptbaustoffWert {
	gattung: string,
	hauptbaustoff: string
}

export interface UebereinstimmungEntryResource extends UebereinstimmungEntry, Resource {}

export interface UebereinstimmungEntry extends ChangeEntry {
	spaltenWert: UebereinstimmungWert;
}

export interface UebereinstimmungWert {
	value: string,
	prefix: string
}

export interface StapellaufEntry {
	spaltenWert: StapellaufWert;
}

export interface StapellaufWert {
	jahr: number,
	bauort: string,
	werft: string
}

export interface MaschinenleistungEntry {
	spaltenWert: MaschinenleistungWert;
}

export interface MaschinenleistungWert {
	masszahl: string,
	einheit: string,
}

export interface EigentuemerSchiffspartenEntry {
	spaltenWert: EigentuemerSchiffspartenWert;
}

export interface EigentuemerSchiffspartenWert {
	laufendeNummer: string,
	schiffsparten: string,
	freitext: string,
	laufendeNummerAnhang: string,
	name: string,
	geburtsDatum: Date,
	firmenSitz: string,
	registergerichtSitz: string,
	handelsregisterNummer: string,
	anteilSchiff: string
}

export interface MessbriefEntry {
	spaltenWert: MessbriefWert;
}

export interface MessbriefWert {
	aussteller: string,
	ausstellDatum: Date
}

export interface LoeschungenEntry {
	spaltenWert: LoeschungenWert;
}

export interface LoeschungenWert {
	laufendeNummer: string,
	betrag: number,
	betragAusgeschrieben: string,
	waehrung: string,
	waehrungAusgeschrieben: string,
}

export interface ErwerbsgrundEntry {
	spaltenWert: ErwerbsgrundWert;
}

export interface ErwerbsgrundWert {
	laufendeNummer: string
	textbaustein: ResourceUri,
	text: string,
	platzhalterWerte: Object
}

export interface EigentuemerErwerbsgrundEntry {
	spaltenWert: EigentuemerErwerbsgrundWert;
}

export interface EigentuemerErwerbsgrundWert {
	laufendeNummer: string,
	laufendeNummerEigentuemer: string,
	freitext: string,
	laufendeNummerAnhang: string,
	name: string,
	geburtsDatum: string,
	firmenSitz: string,
	registergerichtSitz: string,
	handelsregisterNummer: string,
	anteil: string,
	weitereEigentuemer?: WeitererEigentuemerWert[],
	textbaustein: ResourceUri,
	text: string,
	platzhalterWerte: Object,
	weitererFreitext: string
}

export interface WeitererEigentuemerWert {
	laufendeNummer: string,
	freitext: string,
	laufendeNummerAnhang: string,
	name: string,
	geburtsDatum: string,
	firmenSitz: string,
	registergerichtSitz: string,
	handelsregisterNummer: string,
	anteil: string,
	weitererFreitext: string
}

export interface ChangeEntryBelastungResource extends BelastungEntry, Resource {}

export interface BelastungEntry extends ChangeEntry {
	spaltenWert: BelastungWert;
}

export interface BelastungWert {
	laufendeNummer: string,
	vormerkung: boolean,
	vormerkungId: ResourceUri,
	umschreibung: boolean,
	textbaustein: ResourceUri,
	text: string,
	platzhalterWerte: Object
}

export interface ChangeEntryVeraenderungenHypothekenResource extends VeraenderungenHypothekenEntry, Resource {}

export interface VeraenderungenHypothekenEntry extends ChangeEntry {
	spaltenWert: VeraenderungenHypothekenWert;
}

export interface VeraenderungenHypothekenWert {
	laufendeNummer: string,
	betrag: number
	textbaustein: ResourceUri,
	text: string,
	platzhalterWerte: Object
}

export interface ApiError {
	field: string,
	messageCode: string,
	message: string
}

export interface BatchEntry {
	operation: BatchOperation;
	entry: ChangeEntry;
}

export enum BatchOperation {
	ADD = 'ADD',
	UPDATE = 'UPDATE',
	DELETE = 'DELETE'
}
