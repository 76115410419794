import {InjectionToken} from '@angular/core';
import {ActionReducerMap} from '@ngrx/store';
import {ReducerConfig} from '@schir-int-client/test-helpers';
import {registerBlattReducer, RegisterBlattState} from './register.reducer';

export const registerBlattFeatureState = 'RegisterBlattState';
export const registerBlattReducerInjectionToken = new InjectionToken<ActionReducerMap<RegisterBlattRootState>>('Registered RegisterBlattReducer');

export interface RegisterBlattRootState {
	registerBlattRoot: RegisterBlattState,
}

export function getRegisterBlattReducer(): ActionReducerMap<RegisterBlattRootState> {
	return {
		registerBlattRoot: registerBlattReducer,
	};
}

export const reducerConfig: ReducerConfig<RegisterBlattRootState> = {
	reducers: getRegisterBlattReducer(),
	injectionToken: registerBlattReducerInjectionToken,
};
