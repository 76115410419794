<div #assignMenuRoot data-test-id="assign-menu" class="assign-menu">
	<p data-test-id="assign-menu-header"><b>
		Rollen für {{ formatTitle() }} im Verfahren <span class="aktenzeichen">{{ selectedVerfahrenAktenzeichen }}</span>
	</b></p>
	<div class="grid">
		<schir-int-client-one-value-editor *ngFor="let kontaktKategorie of (kontaktKategorie | enumToArray)" [type]="'checkbox'" [formGroup]="form"
										   [label]="kontaktKategorie | enumToLabel: kontaktKategorieLabel"
										   [fieldControl]="getFormControl(kontaktKategorie.toLowerCase())"
										   (value)="onValueChange(kontaktKategorie)" data-test-id="assign-menu-item">
		</schir-int-client-one-value-editor>
	</div>
	<span class="visually-hidden" role="alert">{{ audioAlert | async }}</span>
</div>
