import {Action} from '@ngrx/store';
import {cloneDeep} from 'lodash-es';

const actions = {
	emptyAction: { type: 'empty action', payload: undefined },
};

export function getEmptyAction() {
	return cloneDeep(actions.emptyAction);
}

export class DoNothingAction implements Action {
	type = 'DO_NOTHING';
}
