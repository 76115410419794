import {createFeatureSelector, createSelector, MemoizedSelector} from '@ngrx/store';
import {DefaultProjectorFn} from '@ngrx/store/src/selector';
import {getEmbeddedResource} from '@ngxp/rest';
import {createEmptyStateResource, StateResource} from '@schir-int-client/ngrx-helpers';
import {isNil} from 'lodash-es';
import {registerBlattFeatureState, RegisterBlattRootState} from '../../../register-shared/src/lib/register.state';
import {ChangeEntryListLinkRel} from './register-change.linkrel';
import {
	ChangeEntriesMap,
	ChangeEntryListResource,
	ChangeEntryResource,
	StringChangeEntryResource,
} from './register-change.model';

export const selectFeature = createFeatureSelector<RegisterBlattRootState>(registerBlattFeatureState);

const EMPTY_ARRAY = [];

export interface RegisterFeatureState {
	RegisterBlattState: RegisterBlattRootState;
}

export const changeEntryListSelector: MemoizedSelector<object, StateResource<ChangeEntryListResource>, DefaultProjectorFn<StateResource<ChangeEntryListResource>>> =
	createSelector(selectFeature, (state: RegisterBlattRootState) => {
		return state?.registerBlattRoot.changeEntries;
	});

export const changeEntriesSelector: MemoizedSelector<object, StateResource<ChangeEntriesMap>, DefaultProjectorFn<StateResource<ChangeEntriesMap>>> =
	createSelector(changeEntryListSelector, (changeEntries: StateResource<ChangeEntryListResource>) => {

		if (isNil(changeEntries)) {
			return createEmptyStateResource();
		}

		if (!changeEntries.loaded) {
			return { ...changeEntries, resource: <ChangeEntriesMap>{} };
		}
		const entryList = changeEntries.resource;

		let result: ChangeEntriesMap = {};
		result = getAndAddToMap(result, entryList, ChangeEntryListLinkRel.ROETEN_CHANGE_ENTRIES);
		result = getAndAddToMap(result, entryList, ChangeEntryListLinkRel.AUFSCHRIFT);
		result = getAndAddToMap(result, entryList, ChangeEntryListLinkRel.PARTIELL_ROETEN_CHANGE_ENTRIES);
		result = getAndAddToMap(result, entryList, ChangeEntryListLinkRel.TEXT_CHANGE_ENTRIES);
		result = getAndAddToMap(result, entryList, ChangeEntryListLinkRel.NUMERIC_CHANGE_ENTRIES);
		result = getAndAddToMap(result, entryList, ChangeEntryListLinkRel.GATTUNG_HAUPTBAUSTOFF_ENTRIES);
		result = getAndAddToMap(result, entryList, ChangeEntryListLinkRel.FLAGGENRECHT_CHANGE_ENTRIES);
		result = getAndAddToMap(result, entryList, ChangeEntryListLinkRel.EIGENTUEMER_CHANGE_ENTRIES);
		result = getAndAddToMap(result, entryList, ChangeEntryListLinkRel.BELASTUNG_CHANGE_ENTRIES);
		result = getAndAddToMap(result, entryList, ChangeEntryListLinkRel.TEILLOESCHUNG_ENTRIES);
		result = getAndAddToMap(result, entryList, ChangeEntryListLinkRel.TAG_EINTRAGUNG_LOESCHUNG_CHANGE_ENTRIES);
		result = getAndAddToMap(result, entryList, ChangeEntryListLinkRel.UEBEREINSTIMMUNG_ENTRIES);
		result = getAndAddToMap(result, entryList, ChangeEntryListLinkRel.MIGRATION_ENTRIES);
		result = getAndAddToMap(result, entryList, ChangeEntryListLinkRel.LOESCHGRUND_ENTRIES);
		result = getAndAddToMap(result, entryList, ChangeEntryListLinkRel.TAG_EINTRAGUNG_ENTRIES);
		result = getAndAddToMap(result, entryList, ChangeEntryListLinkRel.ERWERBSGRUND_CHANGE_ENTRIES);
		result = getAndAddToMap(result, entryList, ChangeEntryListLinkRel.EIGENTUEMER_ERWERBSGRUND_CHANGE_ENTRIES);
		result = getAndAddToMap(result, entryList, ChangeEntryListLinkRel.MASCHINENLEISTUNG_CHANGE_ENTRIES);
		result = getAndAddToMap(result, entryList, ChangeEntryListLinkRel.MESSBRIEF_CHANGE_ENTRIES);
		result = getAndAddToMap(result, entryList, ChangeEntryListLinkRel.STAPELLAUF_CHANGE_ENTRIES);
		result = getAndAddToMap(result, entryList, ChangeEntryListLinkRel.VERAENDERUNGEN_CHANGE_ENTRIES);
		result = getAndAddToMap(result, entryList, ChangeEntryListLinkRel.VERAENDERUNGEN_HYPOTHEKEN_CHANGE_ENTRIES);
		result = getAndAddToMap(result, entryList, ChangeEntryListLinkRel.LOESCHUNGEN_CHANGE_ENTRIES);
		result = getAndAddToMap(result, entryList, ChangeEntryListLinkRel.VERMERK_ENTRIES);
		result = getAndAddToMap(result, entryList, ChangeEntryListLinkRel.BETRAGS_KORREKTUR_ENTRIES);
		result = getAndAddToMap(result, entryList, ChangeEntryListLinkRel.LAUFENDE_NUMMER_KORREKTUR_ENTRIES);

		return { ...changeEntries, resource: result };
	});

function getAndAddToMap(map: ChangeEntriesMap, entryList: ChangeEntryListResource, linkRel: string): ChangeEntriesMap {
	let result = { ...map };
	const entries = <StringChangeEntryResource[]>getEmbeddedOrEmpty(entryList, linkRel);
	entries.forEach(entry => {
		result = addToMap(result, entry);
	});
	return result;
}

function addToMap(map: ChangeEntriesMap, entry: ChangeEntryResource): ChangeEntriesMap {
	const spaltenName: string = entry.spaltenName;
	return {
		...map,
		[spaltenName]: isNil(map[spaltenName]) ? [entry] : map[spaltenName].slice(0).concat([entry]),
	};
}

function getEmbeddedOrEmpty(entryList: ChangeEntryListResource, linkRel: string) {
	const embedded = getEmbeddedResource(entryList, linkRel);
	return isNil(embedded) ? EMPTY_ARRAY : embedded;
}
