<button *ngIf="numberOfButtons > 1" mat-mini-fab color="primary" class="more-button" type="button"
		data-test-id="more-button" (mouseenter)="showMore()" (mouseleave)="hideMore()"
		(keyup.enter)="showMore()" (keyup.escape)="hideMore()"
		(click)="showMore()"
		(focus)="showMore()"
		(focusout)="focusChanged($event)"
		[attr.aria-haspopup]="'menu'"
		[attr.aria-label]="'Weitere Funktionen'"
		[matTooltip]="tooltip"
		[matTooltipPosition]="position">
	<mat-icon>more_vert</mat-icon>
	<div class="more-buttons-container" #moreButtonsContainer (mouseenter)="mouseMovedToMore()">
		<div class="more-buttons">
			<ng-container *ngTemplateOutlet="buttons"></ng-container>
		</div>
	</div>
</button>

<ng-container *ngIf="numberOfButtons == 1">
	<ng-container *ngTemplateOutlet="buttons"></ng-container>
</ng-container>

<ng-template #buttons>
	<span data-test-id="more-button-buttons" class="button-list"><ng-content></ng-content></span>
</ng-template>
