/// <reference types="jasmine" />
import {cloneDeep, isArray, isUndefined, mergeWith, toArray} from 'lodash-es';
import {ComponentFixture, TestBed} from '@angular/core/testing';
import {InjectionToken} from '@angular/core';
import {ActionReducerMap, StoreModule} from '@ngrx/store';
import {provideMockActions} from '@ngrx/effects/testing';

let defaultModuleDef = {
	imports: [],
	providers: [],
	declarations: [],
	schemas: [],
};

export function expectElementFromFixture<T>(fixture: ComponentFixture<T>, domQuery?: string): jasmine.Matchers<{} | null> {
	return expect(elementFromFixture(fixture, domQuery));
}

export function elementFromFixture(fixture, domQuery) {
	let nativeElement = getNativeElement(fixture);
	return isUndefined(domQuery) ? nativeElement : elementByQuery(nativeElement, domQuery);
}

function getNativeElement(fixture) {
	fixture.detectChanges();
	return fixture.nativeElement;
}

function elementByQuery(rootElement, domQuery) {
	return rootElement.querySelector(domQuery);
}

export interface ReducerConfig<T> {
	injectionToken: InjectionToken<ActionReducerMap<T>>;
	reducers: ActionReducerMap<T>;
}

export function expectElementsFromFixture(fixture, domQuery) {
	return expect(elementsFromFixture(fixture, domQuery));
}


function elementsFromFixture(fixture, domQuery) {
	const /** @type {?} */ nativeElement = getNativeElement(fixture);
	return elementsByQuery(nativeElement, domQuery);
}

function elementsByQuery(rootElement, domQuery) {
	return toArray(rootElement.querySelectorAll(domQuery));
}

export function configureEffectsTestEnvironment(
	// tslint:disable-next-line:variable-name
	EffectsClass, actionsFn, moduleDef, reducerConfig, appState?) {
	configureTestEnvironment(mergeModuleDefs(getModuleDefForStore(reducerConfig, appState), {
		providers: [
			EffectsClass,
			provideMockActions(actionsFn),
		],
	}, moduleDef));
}

export function configureTestEnvironment(moduleDef) {
	return TestBed
		.configureTestingModule(moduleDef);
}

export function mergeModuleDefs(...args: any[]) {
	const moduleDefs = [];
	for (let _i = 0; _i < args.length; _i++) {
		moduleDefs[_i] = args[_i];
	}
	return moduleDefs.reduce(function (moduleDef1, moduleDef2) { return mergeWith(moduleDef1, moduleDef2, function (newValue, value) { return isArray(newValue) ? newValue.concat(value) : undefined; }); }, cloneDeep(defaultModuleDef));
}

export function getModuleDefForStore(reducerConfig, appState) {
	return {
		imports: [
			StoreModule.forRoot(reducerConfig.injectionToken, {
				initialState: isUndefined(appState) ? {} : appState,
			}),
		],
		providers: [
			{ provide: reducerConfig.injectionToken, useValue: reducerConfig.reducers },
		],
	};
}
