import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {EffectsModule} from '@ngrx/effects';
import {StoreModule} from '@ngrx/store';
import {PosteingangEffects} from './posteingang.effects';
import {featureState, getPosteingangReducer, posteingangReducerInjectionToken} from './posteingang.state';

@NgModule({
	imports: [
		CommonModule,
		EffectsModule.forFeature([PosteingangEffects]),
		StoreModule.forFeature(featureState, posteingangReducerInjectionToken),
	],
	providers: [
		{
			provide: posteingangReducerInjectionToken,
			useFactory: getPosteingangReducer,
		},
	],
})
export class PosteingangSharedModule {}
