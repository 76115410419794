import {createFeatureSelector, createSelector} from '@ngrx/store';
import {getEmbeddedResource} from '@ngxp/rest';
import {isNil, isUndefined} from 'lodash-es';
import {UnterscheidungssignalListLinkRel} from './admin.linkrel';
import {UnterscheidungssignalResource} from './admin.model';
import {adminRootFeatureState, AdminRootState} from './admin.state';

export const selectFeature = createFeatureSelector<AdminRootState>(adminRootFeatureState);

export const adminRootSelector =
	createSelector(selectFeature, (state: AdminRootState) => {
		if (!isNil(state)) {
			const adminRoot = state.adminRoot.admin;
			return isUndefined(adminRoot) ? null : adminRoot;
		}
	});

export const unterscheidungssignaleSelector =
	createSelector(selectFeature, (state: AdminRootState) => {
		return state.adminRoot.unterscheidungssignale;
	});

export const freieUnterscheidungssignaleCountSelector =
	createSelector(selectFeature, (state: AdminRootState) => {
		if (!isNil(state.adminRoot.unterscheidungssignale.resource)) {
			const signale: UnterscheidungssignalResource[] = getEmbeddedResource(state.adminRoot.unterscheidungssignale.resource, UnterscheidungssignalListLinkRel.UNTERSCHEIDUNGSSIGNALE);

			return isNil(signale) ? 0 : signale.filter(signal => isNil(signal.verfahren)).length;
		}
	});

export const usignalErrorSelector =
	createSelector(selectFeature, (state: AdminRootState) => {
		return state.adminRoot.usignalError;
	});
