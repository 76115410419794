<fieldset class="grid" *ngIf="formGroup.enabled" data-test-id="aufgabe-abschrift-root">
	<legend aria-label="Einstellungen zur Registerabschrift"></legend>
	<div class="row" [formGroup]="bodyGroup">
		<div class="column">
			<mat-form-field appearance="outline">
				<mat-label>beglaubigte</mat-label>
				<input min="1" matInput type="number" data-test-id="beglaubigte-input"
					[formControlName]="formServiceClass.feldRegisterabschriftBeglaubigte">
				<mat-error *ngIf="bodyGroup.get(formServiceClass.feldRegisterabschriftBeglaubigte).invalid"
					data-test-id="invalid-beglaubigte-message">Eingabe muss eine ganze Zahl sein</mat-error>
			</mat-form-field>
		</div>
		<div class="column">
			<mat-form-field appearance="outline">
				<mat-label>einfache</mat-label>
				<input min="1" matInput type="number" data-test-id="einfache-input"
					[formControlName]="formServiceClass.feldRegisterabschriftEinfache">
				<mat-error *ngIf="bodyGroup.get(formServiceClass.feldRegisterabschriftEinfache).invalid"
					data-test-id="invalid-einfache-message">Eingabe muss eine ganze Zahl sein</mat-error>
			</mat-form-field>
		</div>
	</div>
	<schir-int-client-notiz-in-aufgabe-editor class="row note" [aufgabenTyp]="aufgabenTyp">
	</schir-int-client-notiz-in-aufgabe-editor>
</fieldset>
