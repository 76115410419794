import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import {isEmpty} from 'lodash-es';
import {Observable} from 'rxjs';
import {share, tap} from 'rxjs/operators';
import {UserResource} from '../user.model';
import {UserRootState} from '../user.state';
import {LoadRechtspflegerAction} from './rechtspfleger.actions';
import {rechtspflegerSelector} from './rechtspfleger.selectors';

@Injectable({ providedIn: 'root' })
export class RechtspflegerFacade {

	constructor(private store: Store<UserRootState>) { }

	rechtspfleger: Observable<UserResource[]> = this.store.select(rechtspflegerSelector).pipe(
		tap(rechtspfleger => {
			if (isEmpty(rechtspfleger)) {
				this.store.dispatch(new LoadRechtspflegerAction());
			}
		}),
		share(),
	);
}
