import {Component, Inject, OnDestroy} from '@angular/core';
import {
	MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
	MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import {AppNotification} from '../notification.model';
import {ApiRoot, ApiRootFacade} from '@schir-int-client/api-root';
import {filter, take} from 'rxjs/operators';
import {isNil} from 'lodash-es';
import {UserService} from '@schir-int-client/user-shared';
import {Subscription} from 'rxjs';

@Component({
	selector: 'schir-int-client-notification-alert',
	templateUrl: './notification-alert.component.html',
	styleUrls: ['./notification-alert.component.scss'],
})
export class NotificationAlertComponent implements OnDestroy {

	notification: AppNotification;
	apiRoot: ApiRoot;
	private subscription: Subscription;

	constructor(private dialogRef: MatDialogRef<NotificationAlertComponent>,
	            @Inject(MAT_DIALOG_DATA) notification: AppNotification,
	            private apiRootFacade: ApiRootFacade,
	            private userService: UserService) {
		this.notification = notification;
		this.subscription = this.apiRootFacade.getCurrentApiRoot().pipe(filter(apiRoot => !isNil(apiRoot))).subscribe(apiRoot => {
			this.apiRoot = apiRoot;
		});
	}

	ok() {
		this.dialogRef.close();
	}

	copyAttachment() {
		this.userService.getUser().pipe(take(1)).subscribe(user => {
			let info = {
				id: this.notification.id,
				timestamp: new Date(),
				version: this.apiRoot?.version,
				ort: this.apiRoot?.ort,
				user: user.firstName + ' ' + user.lastName,
				message: this.notification.message,
			};

			let infoString = JSON.stringify(info, null, '\t');

			this.copyToClipboard(infoString);
		});
	}

	//taken from https://stackoverflow.com/questions/49102724/angular-5-copy-to-clipboard
	copyToClipboard(val: string) {
		const selBox = document.createElement('textarea');
		selBox.style.position = 'fixed';
		selBox.style.left = '0';
		selBox.style.top = '0';
		selBox.style.opacity = '0';
		selBox.value = val;
		document.body.appendChild(selBox);
		selBox.focus();
		selBox.select();
		document.execCommand('copy');
		document.body.removeChild(selBox);
	}

	get messageTokens(): string[] {
		return this.notification.message.split('\n');
	}

	get headline() {
		return this.notification.headline;
	}

	get attachment(): any {
		return this.notification.attachment;
	}

	get attachmentLabel(): string {
		return this.notification.attachmentLabel;
	}

	get errorId(): string {
		return this.notification.id;
	}

	ngOnDestroy(): void {
		if (this.subscription) {
			this.subscription.unsubscribe();
		}
	}

}
