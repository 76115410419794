import {Action} from '@ngrx/store';
import {
	AlternativeText,
	AlternativeTextListResource,
	AlternativeTextResource,
	CreateAlternativeText,
} from './alternative-text.model';
import {FlaggenrechtChangeEntryResource} from '@schir-int-client/register-change';

export enum AlternativeTextActions {
	LOAD_ALTERNATIVE_TEXTE = 'LOAD_ALTERNATIVE_TEXTE',
	LOAD_ALTERNATIVE_TEXTE_SUCCESS = 'LOAD_ALTERNATIVE_TEXTE_SUCCESS',
	LOAD_ALTERNATIVE_TEXT_ENTRY = 'LOAD_ALTERNATIVE_TEXT_ENTRY',
	CREATE_ALTERNATIVE_TEXT_ENTRY = 'CREATE_ALTERNATIVE_TEXT_ENTRY',
	CREATE_ALTERNATIVE_TEXT_ENTY_SUCCESS = 'CREATE_ALTERNATIVE_TEXT_ENTRY_SUCCESS',
	UPDATE_ALTERNATIVE_TEXT_ENTRY = 'UPDATE_ALTERNATIVE_TEXT_ENTRY',
	UPDATE_ALTERNATIVE_TEXT_ENTRY_SUCCESS = 'UPDATE_ALTERNATIVE_TEXT_ENTRY_SUCCESS',
	DELETE_ALTERNATIVE_TEXT_ENTRY = 'DELETE_ALTERNATIVE_TEXT_ENTRY',
	DELETE_ALTERNATIVE_TEXT_ENTRY_SUCCESS = 'DELETE_ALTERNATIVE_TEXT_ENTRY_SUCCESS'
}

export class LoadAlternativeTexteAction implements Action {
	type = AlternativeTextActions.LOAD_ALTERNATIVE_TEXTE;

	constructor() { }
}

export class LoadAlternativeTexteSuccessAction implements Action {
	type = AlternativeTextActions.LOAD_ALTERNATIVE_TEXTE_SUCCESS;

	constructor(public alternativeTexte: AlternativeTextListResource) { }
}

export class CreateAlternativeTextEntryAction implements Action {
	type = AlternativeTextActions.CREATE_ALTERNATIVE_TEXT_ENTRY;

	constructor(public flaggenrechtResource: FlaggenrechtChangeEntryResource, public alternativeText: CreateAlternativeText) { }
}

export class CreateAlternativeTextEntrySuccessAction implements Action {
	type = AlternativeTextActions.CREATE_ALTERNATIVE_TEXT_ENTY_SUCCESS;

	constructor(public alternativeTextResource: AlternativeTextResource) { }
}

export class UpdateAlternativeTextEntryAction implements Action {
	type = AlternativeTextActions.UPDATE_ALTERNATIVE_TEXT_ENTRY;

	constructor(public alternativeTextResource: AlternativeTextResource, public alternativeText: AlternativeText) { }
}

export class UpdateAlternativeTextEntrySuccessAction implements Action {
	type = AlternativeTextActions.UPDATE_ALTERNATIVE_TEXT_ENTRY_SUCCESS;

	constructor(public alternativeTextResource: AlternativeTextResource) { }
}

export class DeleteAlternativeTextEntryAction implements Action {
	type = AlternativeTextActions.DELETE_ALTERNATIVE_TEXT_ENTRY;

	constructor(public alternativeTextResource: AlternativeTextResource) { }
}

export class DeleteAlternativeTextEntrySuccessAction implements Action {
	type = AlternativeTextActions.DELETE_ALTERNATIVE_TEXT_ENTRY_SUCCESS;

	constructor(public alternativeTextResource: AlternativeTextResource) { }
}
