import {Pipe, PipeTransform} from '@angular/core';
import {AdminMessages, EntityError, UnprocessableEntityError} from '@schir-int-client/admin-shared';

@Pipe({ name: 'errorAsFormattedLines' })
export class ErrorAsFormattedLinesPipe implements PipeTransform {
	transform(error: EntityError): string[] {
		let errorLines: string[] = [];

		if (error.type === 0) {//Refactoring: use ErrorType
			(<UnprocessableEntityError>error).error.issues.forEach(error => {
				const subStart: number = error.field.indexOf('[');
				const subEnd: number = error.field.indexOf(']');
				const rowNum: string = error.field.substring(subStart + 1, subEnd);
				const message: string = error.message;

				errorLines.push(AdminMessages.UPLOAD_USIGNALE_ERROR.replace('{1}', rowNum).replace('{2}', message));
			});
		}

		return errorLines;
	}
}
