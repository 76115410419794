import {EventEmitter, Injectable, OnDestroy} from '@angular/core';
import {MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {Observable, Subscription} from 'rxjs';
import {filter, map, take} from 'rxjs/operators';
import {DialogAcknowledgeComponent} from './dialog-acknowledge/dialog-acknowledge.component';
import {DialogYesNoComponent} from './dialog-yes-no/dialog-yes-no.component';
import {NotificationType} from './notification.model';
import {DialogSelectionComponent} from './dialog-selection/dialog-selection.component';
import {ActiveEditorService} from './activeEditor.service';
import {ComponentType} from '@angular/cdk/overlay';
import {
	DialogButtonType,
	DialogDataAcknowledge,
	DialogDataSelection,
	DialogDataYesNo,
} from '@schir-int-client/dialog-shared';
import {HandlesBackdropClickAndEscapeKey, SupportsWarningDialogBeforeClose} from '..';

export const JaNeinLabels: string[] = ['Ja', 'Nein'];

@Injectable({
	providedIn: 'root',
})
export class DialogService implements OnDestroy {

	private subscriptions: Subscription[] = [];

	constructor(private dialog: MatDialog, private activeEditorService: ActiveEditorService) {}

	openDialogYesNo(message: string,
	                label: string[] = JaNeinLabels,
	                msgParam: string[] = [],
	                additionalContent?: string[]): Observable<any> {
		const dialogRef = this.dialog.open(DialogYesNoComponent, {
			data: <DialogDataYesNo>{
				message: this.fillMessage(message, msgParam),
				additionalContent: additionalContent,
				label: [label[0], label[1]],
				agree: null,
			},
			panelClass: 'c-dialog--small',
		});
		return dialogRef.afterClosed();
	}

	openDialogSelection(message: string, label: string[],
	                    buttonType: DialogButtonType[],
	                    disabled?: boolean[],
	                    msgParam: string[] = [],
	                    additionalContent?: string[]): Observable<any> {
		const dialogRef = this.dialog.open(DialogSelectionComponent, {
			data: <DialogDataSelection>{
				message: this.fillMessage(message, msgParam),
				additionalContent: additionalContent,
				label: label,
				buttonType: buttonType,
				disabled: disabled,
				selection: null,
			},
			panelClass: 'c-dialog--small',
		});
		return dialogRef.afterClosed();
	}

	openConfirmationDialog(message: string,
	                       msgParam: string[] = [],
	                       buttonLabels: string[] = JaNeinLabels): Observable<boolean> {
		return this.openDialogYesNo(message, buttonLabels, msgParam).pipe(map(data => data ? data.agree : false));
	}

	openAcknowledgeDialog(message: string,
	                      notificationType: NotificationType,
	                      msgParam: string[] = [],
	                      buttonLabel: string = 'Ok'): Observable<boolean> {
		const dialogRef = this.dialog.open(DialogAcknowledgeComponent, {
			data: <DialogDataAcknowledge>{
				message: this.fillMessage(message, msgParam),
				notificationType,
				buttonLabel,
				acknowledged: null,
			},
			panelClass: 'c-dialog--small',
		});

		return dialogRef.afterClosed().pipe(map((data: DialogDataAcknowledge) => data ? data.acknowledged : false));
	}

	private fillMessage(message: string, msgParam: string[]): string {
		let filledMessage = message;
		msgParam.forEach((param, index) => filledMessage = filledMessage.replace('{' + index + '}', param));
		return filledMessage;
	}

	openEditorDialog<T extends SupportsWarningDialogBeforeClose & Submittable>(owner: HandlesBackdropClickAndEscapeKey<T>,
	                                                                           editorType: ComponentType<T>,
	                                                                           config?: any): MatDialogRef<T> {
		owner.matDialogRef = this.dialog.open(editorType, {
			...config,
			disableClose: true,
		});

		this.activeEditorService.setActiveEditor(owner.matDialogRef.componentInstance, owner.matDialogRef);
		owner.preventCloseOfDirtyForm();

		this.closeDialogOnSubmit(owner.matDialogRef);

		return owner.matDialogRef;
	}

	closeDialogOnSubmit(dialogRef: MatDialogRef<Submittable>): void {
		this.subscriptions.push(dialogRef.componentInstance.submitted.pipe(filter(submitted => submitted), take(1)).subscribe(_ => {
			dialogRef.close();
		}));
	}

	ngOnDestroy(): void {
		this.subscriptions.forEach(s => s.unsubscribe());
	}
}

export interface Submittable {
	submitted: EventEmitter<boolean>;
}
