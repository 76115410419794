import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import {isNil} from 'lodash-es';
import {Observable} from 'rxjs';
import {filter} from 'rxjs/operators';
import {GerichtProfile, GerichtProfileResource} from './gerichtprofile.model';
import {gerichtProfileSelector} from './gerichtprofile.selectors';
import {LoadGerichtProfileAction, UpdateGerichtProfileAction} from './gerichtprofile.actions';
import {GerichtProfileRootState} from './gerichtprofile.state';

@Injectable({ providedIn: 'root' })
export class GerichtProfileFacade {

	profile: Observable<GerichtProfileResource> = this.store.select(gerichtProfileSelector);

	constructor(private store: Store<GerichtProfileRootState>) {}

	getGerichtProfile(): Observable<GerichtProfileResource> {
		return this.store.select(gerichtProfileSelector).pipe(
			filter(profile => {
				if (isNil(profile)) {
					this.store.dispatch(new LoadGerichtProfileAction());
					return false;
				}
				return true;
			}),
		);
	}

	updateGerichtProfile(profile: GerichtProfile) {
		this.store.dispatch(new UpdateGerichtProfileAction(profile));
	}
}
