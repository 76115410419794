<h2 mat-dialog-title tabindex="0" class="narrow" data-test-id="create-verfahren-dialog">Verfahren anlegen (Neueintragung)</h2>
<mat-dialog-content [formGroup]="form">
	<mat-form-field appearance="outline">
		<mat-label>Register</mat-label>
		<mat-select class="registerSelect" [formControlName]="registerControlName" data-test-id="select-register">
			<mat-option *ngFor="let r of registerSelectionList" [value]="r" attr.data-test-id="{{'value-' + r?.toLowerCase()}}" class="focus-box-shadow-dark">
				{{r}}
			</mat-option>
		</mat-select>
	</mat-form-field>
	<mat-form-field *ngIf="hasBlattnummerInput" appearance="outline">
		<mat-label>Blattnummer</mat-label>
		<input matInput [formControlName]="blattnummerControlName" [disabled]="blattnummerGenerieren" type="number" min="0" max="99999999"
			   step="1" lang="de" data-test-id="blattnummer-input">
		<mat-error>Bitte geben Sie eine Blattnummer mit maximal 8 Ziffern ein.</mat-error>
	</mat-form-field>
</mat-dialog-content>
<mat-dialog-actions>
	<button mat-stroked-button (click)="onCancel()" class="focus-box-shadow-dark" data-test-id="cancel-button">Abbrechen</button>
	<button mat-flat-button (click)="onSave()" [disabled]="!isValid()" color="primary" class="icon-right focus-box-shadow-dark"
			data-test-id="speichern-button">Speichern
	</button>
</mat-dialog-actions>
