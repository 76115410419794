import {Action, ActionReducer} from '@ngrx/store';
import {ActionType, ApiRootLoadedAction} from './api-root.actions';
import {ApiRootResource} from './api-root.model';

export interface State {
	apiRoot: ApiRootResource | null;
}

export const initialState: State = {
	apiRoot: null,
};

export const reducer: ActionReducer<State> = (state: State = initialState, action: Action): State => {
	switch (action.type) {
		case ActionType.ApiRootLoaded:
			return {
				...state,
				apiRoot: (<ApiRootLoadedAction>action).payload,
			};
	}

	return state;
};
