import {Action} from '@ngrx/store';
import {Resource, ResourceUri} from '@ngxp/rest';
import {VerfahrenResource} from '@schir-int-client/verfahren-shared';
import {VorgangListResource, VorgangResource, VorgangStatus, VorgangTyp} from './vorgang.model';

export enum VorgangActions {
	ASKTO_CREATE_VORGANG_IN_VERFAHREN = 'ASKTO_CREATE_VORGANG_IN_VERFAHREN',
	CREATE_VORGANG_IN_VERFAHREN = 'CREATE_VORGANG_IN_VERFAHREN',
	CREATE_VORGANG_IN_VERFAHREN_SUCCESS = 'CREATE_VORGANG_IN_VERFAHREN_SUCCESS',
	CREATE_VORGANG_WITH_POSTEINGANG_IN_VERFAHREN = 'CREATE_VORGANG_WITH_POSTEINGANG_IN_VERFAHREN',
	CREATE_VORGANG_WITH_POSTEINGANG_IN_VERFAHREN_SUCCESS = 'CREATE_VORGANG_WITH_POSTEINGANG_IN_VERFAHREN_SUCCESS',
	LOAD_VORGANG = 'LOAD_VORGANG',
	LOAD_VORGANG_BY_LINK = 'LOAD_VORGANG_BY_LINK',
	LOAD_VORGANG_SUCCESS = 'LOAD_VORGANG_SUCCESS',
	LOAD_ZUGEORDNETE_VORGAENGE = 'LOAD_ZUGEORDNETE_VORGAENGE',
	LOAD_VORGAENGE_BY_VERFAHREN = 'LOAD_VORGAENGE_BY_VERFAHREN',
	LOAD_VORGAENGE_BY_VERFAHREN_SUCCESS = 'LOAD_VORGAENGE_BY_VERFAHREN_SUCCESS',
	SELECT_VORGANG = 'SELECT_VORGANG',
	SELECT_VORGANG_BY_URI = 'SELECT_VORGANG_BY_URI',
	ZUGEORDNETE_VORGAENGE_LOADED = 'ZUGEORDNETE_VORGAENGE_LOADED',
	LOAD_VORGAENGE_RCHTSPFLEGE = 'LOAD_VORGAENGE_RCHTSPFLEGE',
	LOAD_VORGAENGE_RCHTSPFLEGE_SUCCESS = 'LOAD_VORGAENGE_RCHTSPFLEGE_SUCCESS',
	ASSIGN_VORGANG = 'ASSIGN_VORGANG',
	LOAD_VORGAENGE_IN_RUECKLAUF = 'LOAD_VORGAENGE_IN_RUECKLAUF',
	VORGAENGE_IN_RUECKLAUF_LOADED = 'VORGAENGE_IN_RUECKLAUF_LOADED',
	ASKTO_ASSIGN_VORGANG_TO_POSTEINGANG = 'ASKTO_ASSIGN_VORGANG_TO_POSTEINGANG',
	ASSIGN_VORGANG_TO_POSTEINGANG = 'ASSIGN_VORGANG_TO_POSTEINGANG',
	ASSIGN_VORGANG_TO_POSTEINGANG_SUCCESS = 'ASSIGN_VORGANG_TO_POSTEINGANG_SUCCESS',
	UPDATE_VORGANG_NOTIZ = 'UPDATE_VORGANG_NOTIZ',
	UPDATE_VORGANG_NOTIZ_SUCCESS = 'UPDATE_VORGANG_NOTIZ_SUCCESS',
	MARK_AS_RECHTSPFLEGE = 'MARK_AS_RECHTSPFLEGE',
	MARK_AS_RUECKLAUF = 'MARK_AS_RUECKLAUF',
	CREATE_WIEDERVORLAGE = 'CREATE_WIEDERVORLAGE',
	MARK_AS_ABGESCHLOSSEN = 'MARK_AS_ABGESCHLOSSEN',
	MARKED_SUCCESS = 'MARKED_SUCCESS',
	DELETE = 'VORGANG_DELETE',
	DELETE_SUCCESS = 'VORGANG_DELETE_SUCCESS',
	PROPOSE_WIEDERVORLAGE_DATUM = 'PROPOSE_WIEDERVORLAGE_DATUM'
}

export class AsktoCreateVorgangInVerfahrenAction implements Action {
	type = VorgangActions.ASKTO_CREATE_VORGANG_IN_VERFAHREN;

	constructor(public vorgangType: VorgangTyp, public createVorgangUri: ResourceUri) { }
}

export class CreateVorgangWithPosteingangInVerfahrenAction implements Action {
	type = VorgangActions.CREATE_VORGANG_WITH_POSTEINGANG_IN_VERFAHREN;

	constructor(public vorgangType: VorgangTyp, public createVorgangUri: ResourceUri) { }
}

export class CreateVorgangInVerfahrenAction implements Action {
	type = VorgangActions.CREATE_VORGANG_IN_VERFAHREN;

	constructor(public vorgangType: VorgangTyp, public createVorgangUri: ResourceUri) { }
}

export class CreateVorgangInVerfahrenSuccessAction implements Action {
	type = VorgangActions.CREATE_VORGANG_IN_VERFAHREN_SUCCESS;

	constructor(public vorgaenge: VorgangListResource, public verfahren: VerfahrenResource) { }
}

export class CreateVorgangWithPosteingangInVerfahrenSuccessAction implements Action {
	type = VorgangActions.CREATE_VORGANG_WITH_POSTEINGANG_IN_VERFAHREN_SUCCESS;

	constructor(public vorgaenge: VorgangListResource) { }
}

export class LoadVorgangAction implements Action {
	type = VorgangActions.LOAD_VORGANG;

	constructor() { }
}

export class LoadVorgangByLinkAction implements Action {
	type = VorgangActions.LOAD_VORGANG_BY_LINK;

	constructor(public resource: Resource, public linkRel: string) { }
}

export class VorgangLoadedAction implements Action {
	type = VorgangActions.LOAD_VORGANG_SUCCESS;

	constructor(public vorgang: VorgangResource) { }
}

export class SelectVorgangAction implements Action {
	type = VorgangActions.SELECT_VORGANG;

	constructor(public vorgang: VorgangResource) { }
}

export class SelectVorgangByUriAction implements Action {
	type = VorgangActions.SELECT_VORGANG_BY_URI;

	constructor(public uri: ResourceUri) { }
}

export class LoadZugeordneteVorgaengeAction implements Action {
	type = VorgangActions.LOAD_ZUGEORDNETE_VORGAENGE;
}

export class ZugeordneteVorgaengeLoadedAction implements Action {
	type = VorgangActions.ZUGEORDNETE_VORGAENGE_LOADED;

	constructor(public zugeordneteVorgangList: VorgangListResource) { }
}

export class LoadVorgaengeByVerfahrenAction implements Action {
	type = VorgangActions.LOAD_VORGAENGE_BY_VERFAHREN;

	constructor(public verfahren: VerfahrenResource) { }
}

export class LoadVorgaengeByVerfahrenSuccessAction implements Action {
	type = VorgangActions.LOAD_VORGAENGE_BY_VERFAHREN_SUCCESS;

	constructor(public vorgaenge: VorgangListResource, public verfahren: VerfahrenResource) { }
}

export class LoadVorgaengeRechtspflegeAction {
	type = VorgangActions.LOAD_VORGAENGE_RCHTSPFLEGE;
}

export class VorgaengeRechtspflegeLoadedAction {
	type = VorgangActions.LOAD_VORGAENGE_RCHTSPFLEGE_SUCCESS;

	constructor(public vorgaenge: VorgangResource[]) { }
}

export class AssignVorgangAction implements Action {
	type = VorgangActions.ASSIGN_VORGANG;

	constructor(public vorgang: VorgangResource, public rechtspfleger: string, public status: VorgangStatus) { }
}

export class LoadVorgaengeInRuecklaufAction implements Action {
	type = VorgangActions.LOAD_VORGAENGE_IN_RUECKLAUF;

	constructor() { }
}

export class VorgaengeInRuecklaufLoadedAction implements Action {
	type = VorgangActions.VORGAENGE_IN_RUECKLAUF_LOADED;

	constructor(public vorgaenge: VorgangListResource) { }
}

export class AskToAssignVorgangToPosteingangAction implements Action {
	type = VorgangActions.ASKTO_ASSIGN_VORGANG_TO_POSTEINGANG;

	constructor(public vorgangResource: VorgangResource) { }
}

export class AssignVorgangToPosteingangAction implements Action {
	type = VorgangActions.ASSIGN_VORGANG_TO_POSTEINGANG;

	constructor(public vorgangResource: VorgangResource) { }
}

export class AssignVorgangToPosteingangSuccess implements Action {
	type = VorgangActions.ASSIGN_VORGANG_TO_POSTEINGANG_SUCCESS;
}

export class UpdateVorgangNotizAction implements Action {
	type = VorgangActions.UPDATE_VORGANG_NOTIZ;

	constructor(public notiz: string, public vorgangUri: ResourceUri) { }
}

export class UpdateVorgangNotizSuccessAction implements Action {
	type = VorgangActions.UPDATE_VORGANG_NOTIZ_SUCCESS;

	constructor(public vorgangResource: VorgangResource) { }
}

export class VorgangMarkAsRechtspflegeAction implements Action {
	type = VorgangActions.MARK_AS_RECHTSPFLEGE;

	constructor(public vorgang: VorgangResource) { }
}

export class VorgangMarkAsRuecklaufAction implements Action {
	type = VorgangActions.MARK_AS_RUECKLAUF;

	constructor(public vorgang: VorgangResource) { }
}

export class VorgangMarkAsAbgeschlossenAction implements Action {
	type = VorgangActions.MARK_AS_ABGESCHLOSSEN;

	constructor(public vorgang: VorgangResource) { }
}

export class VorgangMarkedSuccessAction implements Action {
	type = VorgangActions.MARKED_SUCCESS;

	constructor(public vorgang: VorgangResource) { }
}

export class VorgangDeleteAction implements Action {
	type = VorgangActions.DELETE;

	constructor(public vorgang: VorgangResource) { }
}

export class VorgangDeleteSuccessAction implements Action {
	type = VorgangActions.DELETE_SUCCESS;
}

export class VorgangProposeWiederVorlageDatumAction implements Action {
	type = VorgangActions.PROPOSE_WIEDERVORLAGE_DATUM;

	constructor(public vorgang: VorgangResource) { }
}
