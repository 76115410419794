import {Component} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {AufgabenTyp} from '@schir-int-client/aufgabe-shared';
import {CreateAufgabeFormService} from '../../create-aufgabe-dialog/create-aufgabe.formservice';

@Component({
	selector: 'schir-int-client-aufgabe-messbrief-editor',
	templateUrl: './aufgabe-messbrief-editor.component.html',
	styleUrls: ['./aufgabe-messbrief-editor.component.scss'],
})
export class AufgabeMessbriefEditorComponent {

	readonly aufgabenTyp: AufgabenTyp = AufgabenTyp.MESSBRIEF;
	readonly formServiceClass = CreateAufgabeFormService;

	constructor(public formService: CreateAufgabeFormService) { }

	get formGroup(): UntypedFormGroup {
		return <UntypedFormGroup>this.formService.controlGroupMessbrief;
	}

	get bodyGroup(): UntypedFormGroup {
		return <UntypedFormGroup>this.formGroup.controls[CreateAufgabeFormService.feldAufgabeBody];
	}

	get originalGroup(): UntypedFormGroup {
		return <UntypedFormGroup>this.bodyGroup.controls[CreateAufgabeFormService.groupMessbriefOriginal];
	}

	get originalChecked(): boolean {
		return this.bodyGroup.controls[CreateAufgabeFormService.feldMessbriefOriginal].value;
	}

	get ergaenzenGroup(): UntypedFormGroup {
		return <UntypedFormGroup>this.bodyGroup.controls[CreateAufgabeFormService.groupMessbriefErgaenzen];
	}

	get ergaenzenChecked(): boolean {
		return this.bodyGroup.controls[CreateAufgabeFormService.feldMessbriefErgaenzen].value;
	}


}
