import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA} from '@angular/material/legacy-dialog';
import {getUrl, ResourceUri} from '@ngxp/rest';
import {VerfahrenLinkRel, VerfahrenResource} from '@schir-int-client/verfahren-shared';
import {
	VorgangFacade,
	VorgangTyp,
	VorgangTypLabel,
	VorgangTypLabelLFPR,
	VorgangTypLabelSchiff,
} from '@schir-int-client/vorgang-shared';
import {addAriaAttributesToMatSelect, BaseEditorComponent} from '@schir-int-client/tech';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {ApiRootFacade} from '@schir-int-client/api-root';
import {Subscription} from 'rxjs';

@Component({
	selector: 'schir-int-client-create-vorgang-dialog',
	templateUrl: './create-vorgang-dialog.component.html',
	styleUrls: ['./create-vorgang-dialog.component.scss'],
})
export class CreateVorgangDialogComponent extends BaseEditorComponent implements OnInit, OnDestroy {

	readonly vorgangControlName = 'vorgang';

	verfahren: VerfahrenResource;
	withPosteingang: boolean;

	vorgangsTypen: VorgangTypLabel;
	private subscription: Subscription;

	form: UntypedFormGroup = new UntypedFormGroup({
		[this.vorgangControlName]: new UntypedFormControl(null, Validators.required),
	});

	constructor(@Inject(MAT_DIALOG_DATA) data: any, private vorgangFacade: VorgangFacade, private apiRootFacade: ApiRootFacade) {
		super();

		this.verfahren = data.verfahren;
		this.withPosteingang = data.withPosteingang;
	}

	ngOnInit(): void {
		addAriaAttributesToMatSelect();

		this.subscription = this.apiRootFacade.getCurrentApiRoot().subscribe(apiRoot =>
			this.vorgangsTypen = apiRoot.features.lfpr ? VorgangTypLabelLFPR : VorgangTypLabelSchiff,
		);
	}

	ngOnDestroy() {
		if (this.subscription) {
			this.subscription.unsubscribe();
		}
	}

	get filteredVorgangTypes() {
		return Object.keys(this.vorgangsTypen).filter(typ => typ != VorgangTyp.NEUEINTRAGUNG);
	}

	async submit(): Promise<boolean> {
		const createVorgangUri: ResourceUri = getUrl(this.verfahren, VerfahrenLinkRel.CREATE_VORGANG);
		const typ: VorgangTyp = this.form.controls[this.vorgangControlName].value;


		if (this.withPosteingang) {
			this.vorgangFacade.createVorgangWithPosteingangInVerfahren(typ, createVorgangUri);
		} else {
			this.vorgangFacade.createVorgangInVerfahren(typ, createVorgangUri);
		}

		return true;
	}
}
