import {InjectionToken} from '@angular/core';
import {ActionReducerMap} from '@ngrx/store';
import {ReducerConfig} from '@schir-int-client/test-helpers';
import {userReducer, UserState} from './user.reducer';

export const userFeatureState = 'UserState';
export const userReducerInjectionToken = new InjectionToken<ActionReducerMap<UserRootState>>('Registered UserReducer');

export interface UserRootState {
	userRoot: UserState;
}

export function getUserReducer(): ActionReducerMap<UserRootState> {
	return {
		userRoot: userReducer,
	};
}

export const reducerConfig: ReducerConfig<UserRootState> = {
	reducers: getUserReducer(),
	injectionToken: userReducerInjectionToken,
};
