import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import {LoadingGuard, StateResource} from '@schir-int-client/ngrx-helpers';
import {isNil} from 'lodash-es';
import {combineLatest, Observable} from 'rxjs';
import {filter, map, shareReplay, take} from 'rxjs/operators';
import {
	BetragsKorrekturWert,
	ChangeEntriesMap,
	changeEntriesSelector,
	changeEntryListSelector,
	ChangeEntryResource,
	LaufendeNummerKorrekturWert,
	LoadChangeEntries,
	TeilloeschenWert,
} from '@schir-int-client/register-change';
import {
	CreateBetragsKorrekturAction,
	CreateLaufendeNummerKorrekturAction,
	CreateTeilloeschenAction,
	PartiellRoetenRegisterBlattEntryAction,
	RoetenRegisterBlattEntryAction,
} from './register.actions';
import {PartiellRoetungsBereich, RegisterName, SpaltenName} from './register.model';
import {RegisterBlattState} from './register.reducer';
import {VerfahrenFacade, verfahrenSingleSelector} from '@schir-int-client/verfahren-shared';
import {loadTextbausteine, TextbausteinResource, TextbausteinTargetColumn} from '@schir-int-client/textbaustein';
import {selectTextbausteine} from '../../../textbaustein/src/lib/textbaustein/textbaustein.selectors';

@Injectable({ providedIn: 'root' })
export class RegisterBlattFacade {

	changeEntriesMap = this.getChangeEntriesMap();

	constructor(private store: Store<RegisterBlattState>, private verfahrenFacade: VerfahrenFacade) {}

	tiefeUmfangSeitenhoeheEntries: Observable<ChangeEntryResource[]> = this.getChangeEntriesByColumn([SpaltenName.TIEFE, SpaltenName.SEITENHOEHE, SpaltenName.UMFANG]);
	imoNummerUnterscheidungsSignalEntries: Observable<ChangeEntryResource[]> = this.getChangeEntriesByColumn([SpaltenName.IMO_NUMMER, SpaltenName.UNTERSCHEIDUNGS_SIGNAL]);
	gattungHauptbstoffEntries: Observable<ChangeEntryResource[]> = this.getChangeEntriesByColumn([SpaltenName.GATTUNG_HAUPTBAUSTOFF]);
	nameEntries: Observable<ChangeEntryResource[]> = this.getChangeEntriesByColumn([SpaltenName.NAME]);
	laengeUeberAllesEntries: Observable<ChangeEntryResource[]> = this.getChangeEntriesByColumn([SpaltenName.LAENGE_UEBER_ALLES]);
	laengeEntries: Observable<ChangeEntryResource[]> = this.getChangeEntriesByColumn([SpaltenName.LAENGE]);
	breiteEntries: Observable<ChangeEntryResource[]> = this.getChangeEntriesByColumn([SpaltenName.BREITE]);
	bruttoraumgehaltKubikmeterEntries: Observable<ChangeEntryResource[]> = this.getChangeEntriesByColumn([SpaltenName.BRUTTORAUMGEHALT_KUBIKMETER]);
	bruttoraumgehaltRegistertonnenEntries: Observable<ChangeEntryResource[]> = this.getChangeEntriesByColumn([SpaltenName.BRUTTORAUMGEHALT_REGISTERTONNEN]);
	bruttoraumzahlEntries: Observable<ChangeEntryResource[]> = this.getChangeEntriesByColumn([SpaltenName.BRUTTORAUMZAHL]);
	nettoraumgehaltKubikmeterEntries: Observable<ChangeEntryResource[]> = this.getChangeEntriesByColumn([SpaltenName.NETTORAUMGEHALT_KUBIKMETER]);
	nettoraumgehaltRegistertonnenEntries: Observable<ChangeEntryResource[]> = this.getChangeEntriesByColumn([SpaltenName.NETTORAUMGEHALT_REGISTERTONNEN]);
	nettoraumzahlEntries: Observable<ChangeEntryResource[]> = this.getChangeEntriesByColumn([SpaltenName.NETTORAUMZAHL]);
	heimathafenEntries: Observable<ChangeEntryResource[]> = this.getChangeEntriesByColumn([SpaltenName.HEIMATHAFEN]);
	tragfaehigkeitEntries: Observable<ChangeEntryResource[]> = this.getChangeEntriesByColumn([SpaltenName.TRAGFAEHIGKEIT]);
	eichscheinEntries: Observable<ChangeEntryResource[]> = this.getChangeEntriesByColumn([SpaltenName.EICHSCHEIN]);
	maschinenleistungEntries: Observable<ChangeEntryResource[]> = this.getChangeEntriesByColumn([SpaltenName.MASCHINENLEISTUNG]);
	messbriefEntries: Observable<ChangeEntryResource[]> = this.getChangeEntriesByColumn([SpaltenName.MESSBRIEF]);
	stapellaufEntries: Observable<ChangeEntryResource[]> = this.getChangeEntriesByColumn([SpaltenName.STAPELLAUF]);
	veraenderungenEntries: Observable<ChangeEntryResource[]> = this.getChangeEntriesByColumn([SpaltenName.VERAENDERUNGEN]);
	eigentuemerSchiffspartenEntries: Observable<ChangeEntryResource[]> = this.getChangeEntriesByColumn([SpaltenName.EIGENTUEMER_SCHIFFSPARTEN]);
	erwerbsgrundEntries: Observable<ChangeEntryResource[]> = this.getChangeEntriesByColumn([SpaltenName.ERWERBSGRUND]);
	eigentuemerErwerbsgrundEntries: Observable<ChangeEntryResource[]> = this.getChangeEntriesByColumn([SpaltenName.EIGENTUEMER_ERWERBSGRUND]);
	flaggenrechtEntries: Observable<ChangeEntryResource[]> = this.getChangeEntriesByColumn([SpaltenName.FLAGGENRECHT]);
	loeschungenEntries: Observable<ChangeEntryResource[]> = this.getChangeEntriesByColumn([SpaltenName.LOESCHUNGEN]);
	veraenderungenHypothekenEntries: Observable<ChangeEntryResource[]> = this.getChangeEntriesByColumn([SpaltenName.VERAENDERUNGEN_HYPOTHEKEN]);
	belastungEntries: Observable<ChangeEntryResource[]> = this.getChangeEntriesByColumn([SpaltenName.BELASTUNG]);
	loeschgrundEntries: Observable<ChangeEntryResource[]> = this.getChangeEntriesByColumn([SpaltenName.LOESCHGRUND]);
	tagEintragungEntries: Observable<ChangeEntryResource[]> = this.getChangeEntriesByColumn([SpaltenName.TAG_EINTRAGUNG, SpaltenName.UEBEREINSTIMMUNG, SpaltenName.MIGRATION]);
	tagEintragungLoeschgrundEntries: Observable<ChangeEntryResource[]> = this.getChangeEntriesByColumn([SpaltenName.LOESCHGRUND, SpaltenName.TAG_EINTRAGUNG, SpaltenName.UEBEREINSTIMMUNG, SpaltenName.MIGRATION]);
	aufschriftEntries: Observable<ChangeEntryResource[]> = this.getChangeEntriesByColumn([SpaltenName.AUFSCHRIFT]);
	urkundeEntries: Observable<ChangeEntryResource[]> = this.getChangeEntriesByColumn([SpaltenName.URKUNDE]);
	eigentumsnachweisEntries: Observable<ChangeEntryResource[]> = this.getChangeEntriesByColumn([SpaltenName.EIGENTUMSNACHWEIS]);
	tagEintragungVeraenderungenEntries: Observable<ChangeEntryResource[]> = this.getChangeEntriesByColumn([SpaltenName.VERAENDERUNGEN, SpaltenName.TAG_EINTRAGUNG]);

	// LFPR
	blattLuftfahrzeugrolleEntries: Observable<ChangeEntryResource[]> = this.getChangeEntriesByColumn([SpaltenName.BLATT_LUFTFAHRZEUG_ROLLE]);
	staatsangehoerigkeitEntries: Observable<ChangeEntryResource[]> = this.getChangeEntriesByColumn([SpaltenName.STAATSANGEHOERIGKEIT]);
	artEntries: Observable<ChangeEntryResource[]> = this.getChangeEntriesByColumn([SpaltenName.ART]);
	musterEntries: Observable<ChangeEntryResource[]> = this.getChangeEntriesByColumn([SpaltenName.MUSTER]);
	werknummerEntries: Observable<ChangeEntryResource[]> = this.getChangeEntriesByColumn([SpaltenName.WERKNUMMER]);

	private loadingTextbausteine = new LoadingGuard(this.store);
	private loadingEntryMap = new LoadingGuard(this.store);


	private readonly textbausteine = combineLatest([this.store.select(selectTextbausteine), this.store.select(changeEntryListSelector)]).pipe(
		filter(([, changeEntryList]) => changeEntryList.loaded),
		filter(([bausteine, changeEntryList]) => !this.loadingTextbausteine.mustLoadFirst(bausteine, () => loadTextbausteine({ resources: changeEntryList.resource }))),
	);

	roetenRegisterBlattEntry(entry: ChangeEntryResource) {
		this.store.dispatch(new RoetenRegisterBlattEntryAction(entry));
	}

	partiellRoetenRegisterBlattEntry(entry: ChangeEntryResource, roetungsBereich: PartiellRoetungsBereich) {
		this.store.dispatch(new PartiellRoetenRegisterBlattEntryAction(entry, roetungsBereich));
	}

	teilloeschen(entry: ChangeEntryResource, teilloeschenWert: TeilloeschenWert) {
		this.store.dispatch(new CreateTeilloeschenAction(entry, teilloeschenWert));
	}

	laufendeNummerKorrigieren(entry: ChangeEntryResource, laufendeNummerKorrekturWert: LaufendeNummerKorrekturWert) {
		this.store.dispatch(new CreateLaufendeNummerKorrekturAction(entry, laufendeNummerKorrekturWert));
	}

	betragKorrigieren(entry: ChangeEntryResource, betragsKorrekturWert: BetragsKorrekturWert) {
		this.store.dispatch(new CreateBetragsKorrekturAction(entry, betragsKorrekturWert));
	}

	getTextbausteine(targetColumn: TextbausteinTargetColumn): Observable<TextbausteinResource[]> {
		return this.textbausteine.pipe(
			map(([bausteine]) => isNil(bausteine.resource) ? <TextbausteinResource[]>[] : <TextbausteinResource[]>bausteine.resource[targetColumn]),
			map(this.removeUnassociatedTextbausteine()),
		);
	}

	private removeUnassociatedTextbausteine(): (textbausteine: TextbausteinResource[]) => TextbausteinResource[] {
		return textbausteine => {
			const currentRegisterName = this.getCurrentRegisterName();
			return textbausteine.filter(baustein => baustein[currentRegisterName.toLowerCase()]);
		};
	}

	public getCurrentRegisterName(): RegisterName {
		let register: RegisterName;
		this.verfahrenFacade.selectedVerfahren.pipe(take(1)).subscribe(verfahren => register = verfahren.register).unsubscribe();
		return register;
	}

	getChangeEntriesByColumn(spaltenName: SpaltenName[]): Observable<ChangeEntryResource[]> {
		return this.changeEntriesMap.pipe(
			map(stateResource => stateResource.resource),
			map(map => spaltenName.map(name => this.getFromMapOrEmpty(name, map)).reduce((prev, curr) => prev.concat(curr)), []),
			map(entries => entries.sort(this.sortNullSafe)),
		);
	}


	getChangeEntriesMap(): Observable<StateResource<ChangeEntriesMap>> {
		return combineLatest([this.store.select(changeEntriesSelector), this.store.select(verfahrenSingleSelector)]).pipe(
			filter(([, verfahren]) => {
				if (isNil(verfahren)) {
					this.loadingEntryMap.reset();
				}
				return !isNil(verfahren);
			}),
			filter(([entriesMap, verfahren]) =>
				!this.loadingEntryMap.mustLoadFirst(entriesMap, () => new LoadChangeEntries(verfahren), verfahren.blattNummer + verfahren.register)),
			map(([entriesMap]) => entriesMap),
			shareReplay(), // TODO:  Lfpr-BlattNummer Observable liefert bei Erstellung einer Verfügung zum Teil alte Statewerte
		);
	}

	sortNullSafe(e1: ChangeEntryResource, e2: ChangeEntryResource): number {
		const n1 = e1.positionInSpalte;
		const n2 = e2.positionInSpalte;

		if (isNil(n1)) {
			return 1;
		}
		if (isNil(n2)) {
			return -1;
		}
		return n1 - n2;
	}

	getFromMapOrEmpty(name: SpaltenName, map: ChangeEntriesMap): ChangeEntryResource[] {
		const entries = map[name];
		return isNil(entries) ? [] : entries;
	}
}
