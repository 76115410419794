import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {EffectsModule} from '@ngrx/effects';
import {StoreModule} from '@ngrx/store';
import {AdressatEffect} from './adressat/adressat.effect';
import {AufgabeEffects} from './aufgabe.effects';
import {LabelByAufgabenTypPipe, LabelByAufgabePipe} from './aufgabe.pipe';
import {aufgabeFeatureState, aufgabeReducerInjectionToken, getAufgabeReducer} from './aufgabe.state';

@NgModule({
	imports: [
		CommonModule,
		EffectsModule.forFeature([
			AufgabeEffects,
			AdressatEffect,
		]),
		StoreModule.forFeature(aufgabeFeatureState, aufgabeReducerInjectionToken),
	],
	declarations: [
		LabelByAufgabenTypPipe,
		LabelByAufgabePipe,
	],
	exports: [
		LabelByAufgabePipe,
		LabelByAufgabenTypPipe,
	],
	providers: [
		{
			provide: aufgabeReducerInjectionToken,
			useFactory: getAufgabeReducer,
		},
	],
})
export class AufgabeSharedModule {}
