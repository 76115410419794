import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {EffectsModule} from '@ngrx/effects';
import {USignalEffects} from './usignal.effects';

@NgModule({
	imports: [
		CommonModule,
		EffectsModule.forFeature([USignalEffects]),
	],
})
export class UsignalModule {}
