import {InjectionToken} from '@angular/core';
import {ActionReducerMap} from '@ngrx/store';
import {ReducerConfig} from '@schir-int-client/test-helpers';
import {vorgangReducer, VorgangState} from './vorgang.reducer';

export const vorgangFeatureState = 'VorgangState';
export const vorgangReducerInjectionToken = new InjectionToken<ActionReducerMap<VorgangRootState>>('Registered VorgangReducer');

export interface VorgangRootState {
	vorgangRoot: VorgangState;
}

export function getVorgangReducer(): ActionReducerMap<VorgangRootState> {
	return {
		vorgangRoot: vorgangReducer,
	};
}

export const reducerConfig: ReducerConfig<VorgangRootState> = {
	reducers: getVorgangReducer(),
	injectionToken: vorgangReducerInjectionToken,
};


