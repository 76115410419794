import {
	AfterViewChecked,
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	ContentChildren,
	ElementRef,
	QueryList,
	ViewChild,
} from '@angular/core';
import {LegacyTooltipPosition as TooltipPosition} from '@angular/material/legacy-tooltip';

@Component({
	selector: 'schir-int-client-more-button',
	templateUrl: './more-button.component.html',
	styleUrls: ['./more-button.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})

export class MoreButtonComponent implements AfterViewChecked {

	@ContentChildren('button') contentButtons: QueryList<ElementRef>;
	numberOfButtons: number;

	isOpenByClick: boolean = false;

	@ViewChild('moreButtonsContainer')
	moreButtonsContainer: ElementRef;
	private readonly defaultTooltip = 'Weitere Funktionen';
	tooltip: string = this.defaultTooltip;
	private readonly defaultTooltipPosition = 'right';
	position: TooltipPosition = this.defaultTooltipPosition;

	constructor(private changeDetector: ChangeDetectorRef) {}

	ngAfterViewChecked() {
		this.setNumberOfButtons(this.contentButtons.length);
	}

	public setNumberOfButtons(numberOfButtons: number) {
		this.numberOfButtons = numberOfButtons;
		this.changeDetector.detectChanges();
	}

	focusChanged(event) {
		if (this.moreButtonsContainer.nativeElement.contains(event.relatedTarget)) {
			this.tooltip = event.relatedTarget.getAttribute('aria-label') == this.defaultTooltip ? this.defaultTooltip : null;
		} else {
			this.hideMore();
			this.tooltip = this.defaultTooltip;
		}
	}

	moreButtonClicked() {
		this.isOpenByClick = !this.isOpenByClick;
		if (this.isOpenByClick) {
			this.showMore();
		} else {
			this.hideMore();
		}
	}

	showMore() {
		this.moreButtonsContainer.nativeElement.style.display = 'flex';
	}

	hideMore() {
		if (!this.isOpenByClick) {
			this.moreButtonsContainer.nativeElement.style.display = 'none';
		}
		this.tooltip = this.defaultTooltip;
	}

	mouseMovedToMore() {
		this.tooltip = null;
	}
}
