import {AfterViewInit, Component, OnInit} from '@angular/core';
import {Selector, Store} from '@ngrx/store';
import {ApiRootFacade, ApiRootResource, AppState} from '@schir-int-client/api-root';
import {VorgangFacade, VorgangResource} from '@schir-int-client/vorgang-shared';
import {combineLatest, Observable} from 'rxjs';
import {AccessibilityService} from '@schir-int-client/tech';
import {FrontendEnvironment, frontendEnvironment} from '@schir-int-client/environment';
import {TabTitle, TitleService} from '../../../../tech/src/lib/title/title.service';
import {map} from 'rxjs/operators';
import {VerfahrenFacade, VerfahrenResource} from '@schir-int-client/verfahren-shared';

@Component({
	selector: 'schir-int-client-dashboard-page',
	templateUrl: './dashboard-page.component.html',
	styleUrls: ['./dashboard-page.component.scss'],
})

export class DashboardPageComponent implements OnInit, AfterViewInit {

	apiRoot: Observable<ApiRootResource>;
	frontendEnvironment: Observable<FrontendEnvironment>;
	vorgaengeForRechtspflege: Observable<VorgangResource[]>;
	vorgaengeInRuecklauf: Observable<VorgangResource[]>;
	isLFPR: boolean;

	constructor(private vorgangFacade: VorgangFacade,
	            private verfahrenFacade: VerfahrenFacade,
	            private store: Store<AppState>,
	            private accessibilityService: AccessibilityService,
	            public titleService: TitleService,
	            private apiRootFacade: ApiRootFacade) {
		this.apiRoot = this.store.select(this.getApiRootState());
		this.frontendEnvironment = this.store.select(<any>frontendEnvironment);
		this.store = store;
		this.isLFPR = this.apiRootFacade.isLFPR;
	}

	ngOnInit(): void {
		// TODO Remove as soon as the window title configuration in eIP is updated
		this.titleService.setTitle(TabTitle.DASHBOARD);
		this.vorgaengeInRuecklauf = this.addStandortInfo(this.vorgangFacade.vorgaengeInRuecklauf);
		this.vorgaengeForRechtspflege = this.addStandortInfo(this.vorgangFacade.vorgaengeRechtspfleger);
	}

	private addStandortInfo(vorgaengeResource: Observable<VorgangResource[]>) {
		return combineLatest([vorgaengeResource, this.verfahrenFacade.verfahren])
			.pipe(
				map(([vorgaenge, verfahrenList]) => {
					vorgaenge.forEach(vorgang => {
						const verfahren: VerfahrenResource = verfahrenList.resource?._embedded['verfahrenList'].find(v => v._links.self.href === vorgang.verfahrenId);
						if (verfahren) {
							vorgang.standortAkte = verfahren.stammdaten.standortAkte;
						}
					});
					return vorgaenge;
				}),
			);
	}

	ngAfterViewInit() {
		this.accessibilityService.focusElementById('search-input');
	}

	getApiRootState(): Selector<AppState, ApiRootResource | null> {
		return (state: AppState) => state.apiRoot.apiRoot;
	}
}
