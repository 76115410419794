import {Aktenzeichen} from './aktenzeichen.model';
import {AktenzeichenUtilService} from './aktenzeichen-util.service';

export abstract class AktenzeichenRenderer {

	constructor(protected aktenzeichenUtil: AktenzeichenUtilService) {}

	abstract render(aktenzeichen: Aktenzeichen): string;
}

