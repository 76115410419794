import {ROUTER_NAVIGATED, RouterNavigationAction} from '@ngrx/router-store';
import {Action} from '@ngrx/store';
import {
	belongsToCurrentVorgang,
	createEmptyStateResource,
	createStateResource,
	StateResource,
} from '@schir-int-client/ngrx-helpers';
import {
	LoadVerfahrenSingleSuccessAction,
	VerfahrenActions,
	VerfahrenResource,
} from '@schir-int-client/verfahren-shared';
import {VorgangActions} from '@schir-int-client/vorgang-shared';
import {
	CreateKorrespondenzVerfuegungByVorgangSuccessAction,
	CreateVerfuegungByVorgangSuccessAction,
	LoadVerfuegungenByVorgangSuccessAction,
	LoadVerfuegungSuccessAction,
	UpdateVerfuegungNotizSuccessAction,
	VerfuegungActions,
	VerfuegungDeleteSuccessAction,
} from './verfuegung.actions';
import {VerfuegungListResource, VerfuegungResource} from './verfuegung.model';
import {Resource} from '@ngxp/rest';

export interface VerfuegungState {
	selectedVerfuegung: StateResource<VerfuegungResource>;
	verfuegungen: StateResource<VerfuegungListResource>;
}

export const initialState: VerfuegungState = {
	selectedVerfuegung: createEmptyStateResource(),
	verfuegungen: createEmptyStateResource(),
};

let uriVorgang: string;

export function verfuegungReducer(state: VerfuegungState = initialState, action: Action): VerfuegungState {
	switch (action.type) {
		case VerfuegungActions.SET_SELECTED_VERFUEGUNG_ACTION:
		case VerfuegungActions.LOAD_VERFUEGUNG_SUCCESS:

			const actionVerfuegung = (<LoadVerfuegungSuccessAction>action).verfuegung;
			const stateSelectedVerfuegung = state.selectedVerfuegung;

			if (stateSelectedVerfuegung && stateSelectedVerfuegung.resource && actionVerfuegung._links.self.href === stateSelectedVerfuegung.resource._links.self.href) {
				return state;
			}
			return {
				...state,
				selectedVerfuegung: createStateResource((<LoadVerfuegungSuccessAction>action).verfuegung),
			};
		case VerfuegungActions.LOAD_VERFUEGUNGEN_BY_VORGANG:
			return {
				...state,
				verfuegungen: { ...state.verfuegungen, loading: true },
			};
		case VerfuegungActions.LOAD_VERFUEGUNGEN_BY_VORGANG_SUCCESS:
			const selectedVerfuegung = state.selectedVerfuegung.resource;
			const verfuegungListResource = (<LoadVerfuegungenByVorgangSuccessAction>action).verfuegungen;

			if (verfuegungListResource._embedded) {
				const verfuegungList: Resource | Resource [] = verfuegungListResource._embedded['verfuegungList'];

				//set first verfuegung as selected if the selected verfuegung is from other vorgang or none has been selected before
				if (!selectedVerfuegung || !belongsToCurrentVorgang(verfuegungList, selectedVerfuegung)) {
					return {
						...state,
						selectedVerfuegung: createStateResource(verfuegungList[0]),
						verfuegungen: createStateResource(verfuegungListResource),
					};
				}
			}
			return {
				...state,
				verfuegungen: createStateResource(verfuegungListResource),
			};
		case VerfuegungActions.CREATE_VERFUEGUNG_BY_VORGANG:
			return {
				...state,
				selectedVerfuegung: { ...state.selectedVerfuegung, loading: true },
			};
		case VerfuegungActions.CREATE_KORRESPONDENZ_VERFUEGUNG_BY_VORGANG:
			return {
				...state,
				selectedVerfuegung: { ...state.selectedVerfuegung, loading: true },
			};
		case VerfuegungActions.CREATE_VERFUEGUNG_BY_VORGANG_SUCCESS:
			return {
				...state,
				verfuegungen: { ...state.verfuegungen, reload: true },
				selectedVerfuegung: createStateResource((<CreateVerfuegungByVorgangSuccessAction>action).verfuegung),
			};
		case VerfuegungActions.DELETE_VERFUEGUNG_SUCCESS:
			const verfuegungen = (<VerfuegungDeleteSuccessAction>action).verfuegungen;
			const deleted = (<VerfuegungDeleteSuccessAction>action).deleted;
			const selected = state.selectedVerfuegung.resource;

			if (selected?._links.self.href == deleted?._links.self.href) {
				return {
					...state,
					verfuegungen: createStateResource(verfuegungen),
					selectedVerfuegung: createStateResource(verfuegungen?._embedded?.['verfuegungList'][0]),
				};
			}
			return {
				...state,
				verfuegungen: createStateResource(verfuegungen),
			};
		case VerfuegungActions.CREATE_KORRESPONDENZ_VERFUEGUNG_BY_VORGANG_SUCCESS:
			return {
				...state,
				verfuegungen: { ...state.verfuegungen, reload: true },
				selectedVerfuegung: createStateResource((<CreateKorrespondenzVerfuegungByVorgangSuccessAction>action).verfuegung),
			};
		case VerfahrenActions.LOAD_VERFAHREN_SINGLE_SUCCESS:
			const verfahrenAction: LoadVerfahrenSingleSuccessAction = (<LoadVerfahrenSingleSuccessAction>action);
			const loadedVerfahren: VerfahrenResource = verfahrenAction.verfahren;
			const predecessor: VerfahrenResource = verfahrenAction.predecessor;

			//dont change the selection if the verfahren is loaded for the first time or the same verfahren is loaded again
			if (!predecessor || (loadedVerfahren && predecessor && predecessor.aktenzeichen === loadedVerfahren.aktenzeichen)) {
				return {
					...state,
					verfuegungen: createEmptyStateResource(),
				};
			}
			//else remove the selection
			return {
				...state,
				verfuegungen: createEmptyStateResource(),
				selectedVerfuegung: createEmptyStateResource(),
			};
		case VorgangActions.LOAD_VORGANG_SUCCESS:
			return {
				...state,
				verfuegungen: createEmptyStateResource(),
				selectedVerfuegung: createEmptyStateResource(),
			};
		case VerfuegungActions.UPDATE_VERFUEGUNG_NOTIZ_SUCCESS:
			return {
				...state,
				selectedVerfuegung: createStateResource((<UpdateVerfuegungNotizSuccessAction>action).verfuegung),
				verfuegungen: { ...state.verfuegungen, reload: true },
			};
		case ROUTER_NAVIGATED:
			const routerAction = <RouterNavigationAction>action;
			const lastUriVorgang = uriVorgang;
			const newUri: RegExpExecArray = /vorgang:[^)]*/g.exec(routerAction.payload.event.url);
			uriVorgang = newUri ? newUri[0] : 'none';
			return (lastUriVorgang === uriVorgang) ? state : {
				...state,
				verfuegungen: createEmptyStateResource(),
			};
		default:
			return state;
	}
}
