import {Resource} from '@ngxp/rest';
import {RegisterName} from '@schir-int-client/register-shared';

export interface Kontakt {
	juristischePerson: boolean;
	anrede: KontaktAnrede;
	firmenName: string;
	firmenName1: string;
	firmenName2: string;
	titel: string;
	vorname: string;
	name: string;
	geburtsname: string;
	namenszusatz: string;
	weitereVornamen: string;
	sitz: string;
	zustaendigesAmtsgericht: string;
	handelsregisterNummer: string;
	geburtsDatum: Date;
	strasse: string;
	hausnummer: string;
	plz: string;
	stadt: string;
	land: string;
	postfach: string;
	adresszusatz: string;
	egvpAdresse: string;
	email: string;
	telefon: string;
	telefon2: string;
	telefon3: string;
	fax: string;
	deMail: string;
	notiz: string;
	title: string;
	behoerde: boolean;
	verfahrenKontaktNotiz: string;
	verfahrenKontaktAktenzeichen: string;
	active: boolean;
	kontaktId?: string;
	aktenzeichen?: string;
	autoKontaktRegister: Map<KontaktKategorie, RegisterName[]>;
}

export interface SearchInputWithModes {
	searchString: string;
	searchModes: KontaktSearchMode[];
}

export interface KontaktWithEditMode {
	kontakt: KontaktResource;
	editMode: boolean;
}

export interface KontaktWithKategorie {
	kontakt: KontaktResource;
	kategorie: KontaktKategorie;
}

export enum KontaktSearchMode {
	BEHOERDEN = 'behoerden',
	JURISTISCH = 'juristisch',
	NATUERLICH = 'natuerlich',
	INAKTIV = 'inaktiv',
	AKTIV = 'aktiv'
}

export enum KontaktKategorie {
	EIGENTUEMER_NEU = 'EIGENTUEMER_NEU',
	EIGENTUEMER_ALT = 'EIGENTUEMER_ALT',
	EINGETRAGENER_EIGENTUEMER = 'EINGETRAGENER_EIGENTUEMER',
	NOTAR = 'NOTAR',
	GLAEUBIGER_NEU = 'GLAEUBIGER_NEU',
	GLAEUBIGER_ALT = 'GLAEUBIGER_ALT',
	VERTRETER = 'VERTRETER',
	RECHTSANWALT = 'RECHTSANWALT',
	PHG = 'PHG',
	GESELLSCHAFTER = 'GESELLSCHAFTER',
	BEHOERDEN = 'BEHOERDEN',
	SONSTIGE = 'SONSTIGE',
}

export enum KontaktAnrede {
	FRAU = 'FRAU',
	HERR = 'HERR'
}

export enum KontaktOption {
	DARSTELLUNG = 'DARSTELLUNG'
}

export interface KontaktListResource extends Resource {}

export interface KontaktResource extends Kontakt, Resource {}

export type KontakteInVerfahren = { [kontaktKategorie: string]: Array<KontaktResource> };
