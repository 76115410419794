import {Action} from '@ngrx/store';
import {Wiedervorlage, WiedervorlageListResource} from './wiedervorlage.model';
import {Moment} from 'moment/moment';

export enum WiedervorlageActions {
	CREATE_WIEDERVORLAGE = 'CREATE_WIEDERVORLAGE',
	CREATE_WIEDERVORLAGE_SUCCESS = 'CREATE_WIEDERVORLAGE_SUCCESS',

	UPDATE_WIEDERVORLAGE = 'UPDATE_WIEDERVORLAGE',
	UPDATE_WIEDERVORLAGE_SUCCCESS = 'UPDATE_WIEDERVORLAGE_SUCCESS',

	LOAD_WIEDERVORLAGEN = 'LOAD_WIEDERVORLAGEN',
	LOAD_WIEDERVORLAGEN_SUCCESS = 'LOAD_WIEDERVORLAGEN_SUCCESS',

	LOAD_FRISTENLISTE = 'LOAD_FRISTENLISTE',
	LOAD_FRISTENLISTE_SUCCESS = 'LOAD_FRISTENLISTE_SUCCESS'
}

export class LoadWiedervorlagenAction implements Action {
	type = WiedervorlageActions.LOAD_WIEDERVORLAGEN;

	constructor(public von: Moment, public bis: Moment) { }
}

export class LoadWiedervorlagenSuccessAction implements Action {
	type = WiedervorlageActions.LOAD_WIEDERVORLAGEN_SUCCESS;

	constructor(public wiedervorlagen: WiedervorlageListResource) { }
}

export class CreateWiedervorlageAction implements Action {
	type = WiedervorlageActions.CREATE_WIEDERVORLAGE;

	constructor(public wiedervorlage: Wiedervorlage) { }
}

export class CreateWiedervorlageSuccessAction implements Action {
	type = WiedervorlageActions.CREATE_WIEDERVORLAGE_SUCCESS;

	constructor(public wiedervorlage: Wiedervorlage) { }
}

export class UpdateWiedervorlageAction implements Action {
	type = WiedervorlageActions.UPDATE_WIEDERVORLAGE;

	constructor(public wiedervorlage: Wiedervorlage) { }
}

export class UpdateWiedervorlageSuccessAction implements Action {
	type = WiedervorlageActions.UPDATE_WIEDERVORLAGE_SUCCCESS;

	constructor(public wiedervorlage: Wiedervorlage) { }
}

export class LoadFristenListeAction implements Action {
	type = WiedervorlageActions.LOAD_FRISTENLISTE;
}

export class LoadFristenListeSuccessAction implements Action {
	type = WiedervorlageActions.LOAD_FRISTENLISTE_SUCCESS;

	constructor(public fristenListe: WiedervorlageListResource) { }
}


