import {Inject, Injectable, InjectionToken} from '@angular/core';
import {HttpService} from '@ngxp/rest';
import {Observable} from 'rxjs';
import {ApiRootResource} from './api-root.model';

export const REMOTE_HOST = new InjectionToken('remoteHost');

@Injectable()
export class ApiRootService {
	constructor(private httpService: HttpService, @Inject(REMOTE_HOST) private remoteHost: string) { }

	load(): Observable<ApiRootResource> {
		// TODO: add type parameter to httpService methods
		return <Observable<ApiRootResource>>this.httpService.get(this.remoteHost);
	}
}
