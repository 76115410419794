import {createSelector} from '@ngrx/store';
import {getEmbeddedResource} from '@ngxp/rest';
import {isNil} from 'lodash-es';
import {UserListLinkRel} from '../user.linkrel';
import {UserResource} from '../user.model';
import {selectUserFeature} from '../user.selectors';
import {UserRootState} from '../user.state';

export const selectFeature = selectUserFeature;

const emptyArray = [];

export const rechtspflegerSelector =
	createSelector(selectFeature, (state: UserRootState) => {
		if (isNil(state?.userRoot?.rechtspfleger)) {
			return emptyArray;
		}
		return <UserResource[]>getEmbeddedResource(state.userRoot.rechtspfleger, UserListLinkRel.USER_LIST);
	});
