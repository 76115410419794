import {createFeatureSelector, createSelector} from '@ngrx/store';
import {getEmbeddedResource, ResourceUri} from '@ngxp/rest';
import {isNil, isNull} from 'lodash-es';
import {VorlageListLinkRel} from './vorlage.linkrel';
import {VorlageResource} from './vorlage.model';
import {vorlageFeatureState, VorlageRootState} from './vorlage.state';
import {createEmptyStateResource, StateResource} from '@schir-int-client/ngrx-helpers';

export const selectFeature = createFeatureSelector<VorlageRootState>(vorlageFeatureState);

export const vorlagenSelector = createSelector(selectFeature, (state: VorlageRootState) => {
	if (!isNull(state.vorlageRoot.vorlagen)) {
		const vorlagen = getEmbeddedResource<VorlageResource[]>(state.vorlageRoot.vorlagen, VorlageListLinkRel.VORLAGE_LIST, false);
		return vorlagen ? vorlagen : null;
	}
	return null;
});

export const vorlageSelector = (props: { vorlageUri: ResourceUri }) => createSelector(selectFeature, (state: VorlageRootState) => {
	let vorlagenElement = state.vorlageRoot.vorlagenResourcesByUri[props.vorlageUri];

	return isNil(vorlagenElement) ? <StateResource<VorlageResource>>createEmptyStateResource() : vorlagenElement;
});
