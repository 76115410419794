export class EipConstants {
	static INSTITUTION: EipInstitution = {
		identifier: 'K1101Z',
		location: 'Hamburg',
		shortDescription: 'Amtsgericht Hamburg',
		type: 'Amtsgericht',
		typeId: 'AG',
	};
	static CLIIENT_ID: string = 'de.justiz.eip.schiffsregister.ui.client';
}

export enum EipStatus {
	INITIAL = 'INITIAL',
	CONFIGURATION_STORED_LOCALLY = 'CONFIGURATION_STORED_LOCALLY',
	CONNECTED = 'CONNECTED'
}

export class EipInstitution {
	identifier: string;
	location: string;
	shortDescription: string;
	type: string;
	typeId: string;
}

export interface EipUser {
	firstName: string;
	lastName: string;
	jobDesciption: string;
	loginId: string;
	title: string;
}
