<form class="pdf-form" data-test-id="korrespondenz-formular" [formGroup]="form">
	<h2 mat-dialog-title class="narrow" tabindex="0" data-test-id="title">{{ aufgabe | async | labelByAufgabe }} bearbeiten</h2>

	<div mat-dialog-content class="dialog-content">
		<mat-form-field appearance="outline">
			<mat-label>Vorlage</mat-label>
			<mat-select cdkFocusInitial [formControlName]="formService.feldVorlage" data-test-id="one-value-select-input">
				<mat-option *ngFor="let vorlage of vorlagen | async" [value]="getVorlageUrl(vorlage)"
							data-test-id="one-value-select-value" class="focus-box-shadow-dark">
					{{vorlage.name}}
				</mat-option>
			</mat-select>
			<mat-error>Vorlage {{messages.MISSING_REQUIRED_SELECT_VALUE}}</mat-error>
		</mat-form-field>

		<mat-form-field appearance="outline" class="date-picker">
			<mat-label>Datum</mat-label>

			<input [formControlName]="formService.feldDatum" data-test-id="one-value-date-input" matInput
				   [matDatepicker]="picker" type="text"/>
			<mat-datepicker-toggle matSuffix [for]="picker" matTooltip="Kalender �ffnen" aria-label="Kalender �ffnen"></mat-datepicker-toggle>
			<mat-datepicker #picker></mat-datepicker>
			<mat-error>Datum {{messages.MISSING_VALUE}}</mat-error>
		</mat-form-field>

		<mat-form-field appearance="outline">
			<mat-label>Rechtspfleger</mat-label>
			<input [formControlName]="formService.feldRechtspfleger" matInput data-test-id="one-value-text-input"/>
		</mat-form-field>
	</div>

	<mat-dialog-actions>
		<button mat-stroked-button (click)="onCancel()" class="focus-box-shadow-dark" data-test-id="cancel-button">Abbrechen</button>
		<button type="submit" data-test-id="create-korrespondenz-button"
				(click)="onSave()" [disabled]="!isValid()" mat-flat-button color="primary" class="focus-box-shadow-dark">
			Korrespondenzvorlage erstellen
		</button>
	</mat-dialog-actions>
</form>
