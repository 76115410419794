import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {EffectsModule} from '@ngrx/effects';
import {TextbausteinEffects} from './textbaustein/textbaustein.effects';
import {TextbausteinService} from './textbaustein/textbaustein.service';
import {TextbausteinFreigabetextService} from './textbaustein/textbaustein-freigabetext.service';
import {StoreModule} from '@ngrx/store';
import {textbausteinFeatureState} from './textbaustein/textbaustein.state';
import {getTextbausteinReducer, textbausteinReducerInjectionToken} from './textbaustein/textbausteine.reducer';

@NgModule({
	imports: [
		CommonModule,
		EffectsModule.forFeature([TextbausteinEffects]),
		StoreModule.forFeature(textbausteinFeatureState, textbausteinReducerInjectionToken),
	],
	providers: [
		TextbausteinService,
		TextbausteinFreigabetextService,
		{
			provide: textbausteinReducerInjectionToken,
			useFactory: getTextbausteinReducer,
		},
	],
})
export class TextbausteinModule {}
