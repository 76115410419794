import {Pipe, PipeTransform} from '@angular/core';
import {Kontakt} from '@schir-int-client/adressverwaltung-shared';
import {isNil} from 'lodash-es';

@Pipe({ name: 'nameByKontaktPipe' })
export class NameByKontaktPipe implements PipeTransform {
	transform(kontakt: Kontakt) {
		if (!isNil(kontakt)) {
			if (kontakt.juristischePerson && !isNil(kontakt.firmenName)) {
				return kontakt.firmenName;
			} else if (isNil(kontakt.vorname)) {
				return kontakt.name;
			} else if (isNil(kontakt.name)) {
				return kontakt.vorname;
			} else {
				return `${kontakt.vorname} ${kontakt.name}`;
			}
		}
	}
}
