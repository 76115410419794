import {ElementRef, Inject, Injectable} from '@angular/core';
import {DOCUMENT} from '@angular/common';
import {Subject} from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AccessibilityService {

	constructor(@Inject(DOCUMENT) private document: Document) {}

	public focusElementById(id: string) {
		const element: HTMLElement = <HTMLElement>this.document.querySelectorAll(`[id="${id}"]`)[0];
		if (element) {
			if (element.tagName.toLowerCase() === 'schir-int-client-button-round') {
				(<HTMLElement>element.firstChild).focus();
			} else if (element.tagName.toLowerCase() === 'schir-int-client-one-value-editor') {
				(<HTMLElement>element.firstElementChild.firstElementChild.firstElementChild).focus();
			} else {
				element.focus();
			}
		}
	}

	public focusElementByIdWithPrefix(id: string) {
		const element: HTMLElement = <HTMLElement>this.document.querySelectorAll(`[id$="${id}"]`)[0];
		if (element) {
			element.focus();
		}
	}

	public focusElementByDataTitle(content: string) {
		(<HTMLElement>this.document.querySelector(`[data-title="${content}"]`)).focus();
	}

	public isDescriptionVisbible(element: HTMLElement): boolean {
		return element.offsetParent !== null;
	}

	/**
	 * Laut Spezifikation wird ein aria-live-Feld genau dann vorgelesen, wenn dessen Wert sich ändert. Das ist auch z.B. bei
	 * den Suchergebnissen so, jedoch nicht überall. Daher wird der Inhalt nach einer gewissen Zeit gelöscht, um mehrfaches
	 * Vorlesen zu vermeiden.
	 */
	shout(message: string, audioAlert: Subject<string>) {
		audioAlert.next(message);
		setTimeout(() => audioAlert.next(''), 3000);
	}

	/**
	 * Setzt den Fokus auf den ersten Tab einer tab-list. Eine Direktive dafür funktioniert nicht, da Direktiven nur das
	 * Element sehen, auf dem sie stehen und nicht dessen Child-Elemente (auch nach timeout oder per querySelector). Das zu
	 * fokussierende Element ist nicht Teil des Templates, sondern wird durch Angular eingefügt.
	 */
	public focusFirstTab(): void {
		this.createObserver(() => <HTMLElement>document.querySelector('.mat-tab-labels div'), () => true, () => false);
	}

	/**
	 * Setzt den Fokus auf ein angegebenes Element, wenn er innerhalb einer bestimmten Zeit (aktueller Vorgang) verloren geht.
	 * @param container Referenz auf einen Knoten im Objektbaum
	 * @param selector bestimmt darin das zu fokussierende Element.
	 */
	public fixLostFocus(container: ElementRef, selector: string) {
		const timeLimit = Date.now() + 300; // Nur der aktuelle Vorgang soll gefixt werden.
		this.createObserver(() => <HTMLElement>container.nativeElement?.querySelector(selector),
			() => !document.activeElement || document.activeElement.tagName === 'body' || document.activeElement['offsetParent'] === null,
			() => Date.now() > timeLimit);
	}

	private createObserver(getTargetElement: () => HTMLElement, doSetFocus: () => boolean, doAbort: () => boolean) {
		const observer = new MutationObserver(function (mutations) {
			if (doAbort()) {
				observer.disconnect();
			} else if (doSetFocus()) {
				const target = getTargetElement();
				if (target) {
					target.focus();
					observer.disconnect();
				}
			}
		});

		observer.observe(document, { attributes: false, childList: true, characterData: false, subtree: true });
	}
}
