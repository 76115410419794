import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {getUrl} from '@ngxp/rest';
import {DownloadFileAction} from '@schir-int-client/binary-shared';
import {DoNothingAction} from '@schir-int-client/ngrx-helpers';
import {DialogService} from '@schir-int-client/tech';
import {VerfahrenFacade} from '@schir-int-client/verfahren-shared';
import {AufgabeFacade} from 'libs/aufgabe-shared/src/lib/aufgabe.facade';
import {map, mergeMap, switchMap, withLatestFrom} from 'rxjs/operators';
import {
	AskToDeleteKorrespondenzVorlageAction,
	CreateKorrespondenzVorlageAction,
	CreateKorrespondenzVorlageSuccessAction,
	DeleteKorrespondenzVorlageAction,
	DeleteKorrespondenzVorlageSuccessAction,
	KorrespondenzVorlageActions,
	LoadKorrespondenzVorlageAction,
	LoadKorrespondenzVorlageSuccessAction,
} from './korrespondenz-vorlage.actions';
import {KorrespondenzVorlageMessages} from './korrespondenz-vorlage.messages';
import {KorrespondenzVorlage, KorrespondenzVorlageResource} from './korrespondenz-vorlage.model';
import {KorrespondenzVorlageService} from './korrespondenz-vorlage.service';

@Injectable()
export class KorrespondenzVorlageEffects {
	constructor(
		private actions: Actions,
		private service: KorrespondenzVorlageService,
		private aufgabeFacade: AufgabeFacade,
		private verfahrenFacade: VerfahrenFacade,
		private dialogService: DialogService,
	) { }


	loadKorrespondenzVorlage = createEffect(() => this.actions.pipe(
		ofType(KorrespondenzVorlageActions.LOAD_KORRESPONDENZ_VORLAGE),
		mergeMap(action => {
			return this.service.loadKorrespondenzVorlage((<LoadKorrespondenzVorlageAction>action).aufgabeResource).pipe(
				map(korrespondenzVorlage => new LoadKorrespondenzVorlageSuccessAction(korrespondenzVorlage)),
			);
		}),
	));


	createKorrespondenzVorlage = createEffect(() => this.actions.pipe(
		ofType(KorrespondenzVorlageActions.CREATE_KORRESPONDENZ_VORLAGE),
		withLatestFrom(this.verfahrenFacade.verfahrenSingle, this.aufgabeFacade.aufgabe),
		switchMap(([action, verfahren, aufgabe]) => {
			const korrespondenzVorlage: KorrespondenzVorlage = {
				...(<CreateKorrespondenzVorlageAction>action).request,
				verfahrenId: getUrl(verfahren),
			};
			return this.service.createKorrespondenzVorlage(aufgabe, korrespondenzVorlage).pipe(
				map(binaryFile => new CreateKorrespondenzVorlageSuccessAction(binaryFile)));
		}),
	));


	downloadKorrespondenzVorlage = createEffect(() => this.actions.pipe(
		ofType(KorrespondenzVorlageActions.DOWNLOAD_KORRESPONDENZ_VORLAGE),
		withLatestFrom(this.aufgabeFacade.aufgabe),
		switchMap(([, aufgabe]) => this.service.downloadKorrespondenzVorlage(aufgabe).pipe(
			map(file => new DownloadFileAction(file)),
		)),
	));


	confirmDeleteKorrespondenz = createEffect(() => this.actions.pipe(
		ofType(KorrespondenzVorlageActions.ASKTO_DELETE_KORRESPONDENZ_VORLAGE),
		switchMap(action => {
			return this.dialogService.openConfirmationDialog(KorrespondenzVorlageMessages.ASK_TO_DELETE_KORRESPONDENZ_VORLAGE).pipe(
				map(confirmed => confirmed ? new DeleteKorrespondenzVorlageAction((<AskToDeleteKorrespondenzVorlageAction>action).korrespondenzVorlage) : new DoNothingAction()),
			);
		}),
	));


	deleteKorrespondenz = createEffect(() => this.actions.pipe(
		ofType(KorrespondenzVorlageActions.DELETE_KORRESPONDENZ_VORLAGE),
		switchMap(action => {
			const korrespondenzVorlage: KorrespondenzVorlageResource = (<DeleteKorrespondenzVorlageAction>action).korrespondenzVorlage;
			return this.service.deleteKorrespondenzVorlage(korrespondenzVorlage).pipe(
				map(() => new DeleteKorrespondenzVorlageSuccessAction(korrespondenzVorlage)));
		}),
	));
}
