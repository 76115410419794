import {Component, Input} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {AufgabenTyp} from '@schir-int-client/aufgabe-shared';
import {CreateAufgabeFormService} from '../../create-aufgabe-dialog/create-aufgabe.formservice';

@Component({
	selector: 'schir-int-client-aufgabe-with-static-taetigkeit-editor',
	templateUrl: './aufgabe-with-static-taetigkeit-editor.component.html',
	styleUrls: ['./aufgabe-with-static-taetigkeit-editor.component.scss'],
})
export class AufgabeWithStaticTaetigkeitEditorComponent {

	@Input() staticTaetigkeitLabel: string;
	@Input() aufgabenTyp: AufgabenTyp;

	constructor(private formService: CreateAufgabeFormService) { }

	get formGroup(): UntypedFormGroup {
		return <UntypedFormGroup>this.formService.form.controls[this.aufgabenTyp];
	}
}
