import {ChangeDetectionStrategy, Component, ElementRef, ViewChild} from '@angular/core';
import {Actions} from '@ngrx/effects';
import {getEmbeddedResource, getUrl} from '@ngxp/rest';
import {
	DocumentLinkRel,
	DocumentListLinkRel,
	DocumentListResource,
	DocumentResource,
	PosteingangActions,
	PosteingangFacade,
	PosteingangLinkRel,
	PosteingangResource,
	PosteingangStatus,
	PosteingangStatusLabel,
} from '@schir-int-client/posteingang-shared';
import {Spinner} from '@schir-int-client/tech';
import {Observable} from 'rxjs';
import {
	AktenzeichenRenderer,
	AktenzeichenRendererProvider,
	AktenzeichenUtilService,
} from '@schir-int-client/aktenzeichen-shared';

@Component({
	selector: 'schir-int-client-posteingang-list',
	templateUrl: './posteingang-list.component.html',
	styleUrls: ['./posteingang-list.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PosteingangListComponent {

	@ViewChild('spinner', { static: true }) spinnerRef: ElementRef;

	posteingaenge: Observable<PosteingangResource[]>;
	posteingangStatusLabel = PosteingangStatusLabel;
	posteingangStatus = PosteingangStatus;
	private spinner: Spinner;
	private aktenzeichenRenderer: AktenzeichenRenderer;

	constructor(private facade: PosteingangFacade,
	            private actions: Actions,
	            private aktenzeichenRendererProvicer: AktenzeichenRendererProvider,
	            private aktenzeichenUtil: AktenzeichenUtilService) {
		this.posteingaenge = this.facade.posteingaenge;
		this.aktenzeichenRenderer = this.aktenzeichenRendererProvicer.getRenderer();
	}

	documents(posteingang: PosteingangResource): DocumentResource[] {
		const docs: DocumentListResource = getEmbeddedResource(posteingang, PosteingangLinkRel.DOCUMENTS, false);
		if (docs === null) {
			return null;
		}
		const list: DocumentResource[] = getEmbeddedResource(docs, DocumentListLinkRel.DOCUMENT_LIST, false);
		return list ? list : [];
	}

	open(document: DocumentResource) {
		location.href = getUrl(document, DocumentLinkRel.DOWNLOAD);
	}

	ngAfterContentInit() {
		this.spinner = new Spinner(this.actions, PosteingangActions.LOAD_POSTEINGAENGE, PosteingangActions.POSTEINGAENGE_LOADED, this.spinnerRef);
	}

	ngOnDestroy() {
		this.spinner.destroy();
	}

	renderAktenzeichen(longAktenzeichen: string): string {
		if (this.aktenzeichenUtil.isValidAktenzeichen(longAktenzeichen)) {
			const aktenzeichen = this.aktenzeichenUtil.parse(longAktenzeichen);

			return this.aktenzeichenRenderer.render(aktenzeichen);
		}

		return longAktenzeichen;
	}
}
