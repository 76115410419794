import {Component} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {AufgabenTyp} from '@schir-int-client/aufgabe-shared';
import {CreateAufgabeFormService} from '../../create-aufgabe-dialog/create-aufgabe.formservice';

@Component({
	selector: 'schir-int-client-aufgabe-abschrift-editor',
	templateUrl: './aufgabe-abschrift-editor.component.html',
	styleUrls: ['./aufgabe-abschrift-editor.component.scss'],
})
export class AufgabeAbschriftEditorComponent {
	readonly formServiceClass = CreateAufgabeFormService;
	readonly aufgabenTyp: AufgabenTyp = AufgabenTyp.REGISTERABSCHRIFT;

	constructor(private formService: CreateAufgabeFormService) { }

	get formGroup(): UntypedFormGroup {
		return <UntypedFormGroup>this.formService.controlGroupRegisterabschrift;
	}

	get bodyGroup(): UntypedFormGroup {
		return <UntypedFormGroup>this.formGroup.controls[CreateAufgabeFormService.feldAufgabeBody];
	}
}
