import {Component, Input, OnDestroy} from '@angular/core';
import {getUrl, hasLink} from '@ngxp/rest';
import {DialogService} from '@schir-int-client/tech';
import {
	VerfahrenFacade,
	VerfahrenLinkRel,
	VerfahrenMessages,
	VerfahrenResource,
	VerfahrenStatusLabel,
} from '@schir-int-client/verfahren-shared';
import isNil from 'lodash-es/isNil';
import {RegisterName} from '@schir-int-client/register-shared';
import {
	AktenzeichenRenderer,
	AktenzeichenRendererProvider,
	AktenzeichenUtilService,
} from '@schir-int-client/aktenzeichen-shared';
import {take} from 'rxjs/operators';
import {Subscription} from 'rxjs';

@Component({
	selector: 'schir-int-client-verfahren-search-single',
	templateUrl: './verfahren-search-single.component.html',
	styleUrls: ['./verfahren-search-single.component.scss'],
})
export class VerfahrenSearchSingleComponent implements OnDestroy {
	private renderer: AktenzeichenRenderer;

	@Input() verfahren: VerfahrenResource;
	@Input() selectedVerfahren: VerfahrenResource;

	private subscriptions: Subscription[] = [];

	verfahrenStatusLabel = VerfahrenStatusLabel;

	constructor(
		private facade: VerfahrenFacade,
		private dialogService: DialogService,
		private rendererProvider: AktenzeichenRendererProvider,
		private aktenzeichenUtil: AktenzeichenUtilService,
	) {
		this.renderer = this.rendererProvider.getRenderer();
	}

	showAddUsignalButton(): boolean {
		return hasLink(this.verfahren, VerfahrenLinkRel.ASSIGN_USIGNAL) && this.verfahren.register === RegisterName.SSR;
	}

	isSelected(): boolean {
		return !isNil(this.selectedVerfahren) && getUrl(this.verfahren) == getUrl(this.selectedVerfahren);
	}

	updateSelectedVerfahren() {
		this.facade.setSelectedVerfahren(this.verfahren);
	}

	downloadAusdruck(beglaubigt: boolean) {
		this.updateSelectedVerfahren();
		this.facade.downloadAusdruck(beglaubigt);
	}

	openUsignalZuordnenDialog(verfahren: VerfahrenResource) {
		const aktenzeichen = this.aktenzeichenUtil.parse(verfahren.aktenzeichen);

		this.subscriptions.push(this.dialogService
			.openDialogYesNo(VerfahrenMessages.CONFIRM_ASSIGN_USIGNAL.replace('{aktenzeichen}', this.renderer.render(aktenzeichen)))
			.pipe(take(1)).subscribe(data => {
				if (data.agree) {
					this.facade.assignUSignal(verfahren);
				}
			}));
	}

	ngOnDestroy() {
		this.subscriptions.forEach(s => s.unsubscribe());
	}
}
