import {Action} from '@ngrx/store';
import {
	BatchEntry,
	ChangeEntry,
	ChangeEntryListResource,
	ChangeEntryResource,
	ChangeEntryVermerk,
} from './register-change.model';
import {VerfahrenResource} from '@schir-int-client/verfahren-shared';

export enum RegisterBlattChangeActions {
	REFRESH_STATE = 'REFRESH_STATE',
	LOAD_CHANGE_ENTRIES = 'LOAD_CHANGE_ENTRIES',
	LOAD_CHANGE_ENTRIES_SUCCESS = 'LOAD_CHANGE_ENTRIES_SUCCESS',
	UPDATE_CHANGE_ENTRY = 'UPDATE_CHANGE_ENTRY',
	ADD_CHANGE_ENTRY = 'ADD_CHANGE_ENTRY',
	ADD_ENTRY_BATCH = 'ADD_ENTRY_BATCH',
	ADD_VERMERK_ENTRY = 'ADD_VERMERK_ENTRY',
	DELETE_ENTRY = 'DELETE_ENTRY',
	CREATE_UMSCHREIBUNG_ENTRY = 'CREATE_UMSCHREIBUNG_ENTRY'
}

export class RefreshStateAction implements Action {
	type = RegisterBlattChangeActions.REFRESH_STATE;
}

export class LoadChangeEntries implements Action {
	type = RegisterBlattChangeActions.LOAD_CHANGE_ENTRIES;

	constructor(public verfahren: VerfahrenResource) { }
}

export class LoadChangeEntriesSuccessAction implements Action {
	type = RegisterBlattChangeActions.LOAD_CHANGE_ENTRIES_SUCCESS;

	constructor(public resources: ChangeEntryListResource) { }
}

export class UpdateChangeEntryAction implements Action {
	type = RegisterBlattChangeActions.UPDATE_CHANGE_ENTRY;

	constructor(public entry: ChangeEntryResource) { }
}

export class AddChangeEntryAction implements Action {
	type = RegisterBlattChangeActions.ADD_CHANGE_ENTRY;

	constructor(public entry: ChangeEntry) { }
}

export class AddEntryBatchAction implements Action {
	type = RegisterBlattChangeActions.ADD_ENTRY_BATCH;

	constructor(public entries: BatchEntry[]) { }
}

export class AddVermerkEntryAction implements Action {
	type = RegisterBlattChangeActions.ADD_VERMERK_ENTRY;

	constructor(public entry: ChangeEntryVermerk) { }
}

export class DeleteEntryAction implements Action {
	type = RegisterBlattChangeActions.DELETE_ENTRY;

	constructor(public entry: ChangeEntryResource) { }
}

export class CreateUmschreibungEntryAction implements Action {
	type = RegisterBlattChangeActions.CREATE_UMSCHREIBUNG_ENTRY;

	constructor(public entryResource: ChangeEntryResource, public newEntry: ChangeEntry) { }
}
