import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {getEmbeddedResource} from '@ngxp/rest';
import {
	noLimit,
	VerfahrenListLinkRel,
	VerfahrenListResource,
	VerfahrenResource,
} from '@schir-int-client/verfahren-shared';
import {StateResource} from '@schir-int-client/ngrx-helpers';
import {Router} from '@angular/router';
import {VerfahrenGlobalSearchFormService} from '../verfahren-global-search/verfahren-global-search.formservice';

@Component({
	selector: 'schir-int-client-verfahren-list',
	templateUrl: './verfahren-list.component.html',
	styleUrls: ['./verfahren-list.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VerfahrenListComponent {

	@Input() verfahren: StateResource<VerfahrenListResource>;

	constructor(private router: Router, private formService: VerfahrenGlobalSearchFormService) { }

	getVerfahrenArray(): VerfahrenResource[] {
		return this.verfahren.loaded ? getEmbeddedResource(this.verfahren.resource, VerfahrenListLinkRel.VERFAHREN_LIST) : [];
	}

	hasMore(): boolean {
		return this.verfahren.resource && (this.verfahren.resource.page.totalElements > this.verfahren.resource.page.size);
	}

	loadAllVerfahren() {
		this.formService.onSubmit(noLimit);
		this.router.navigate(['verfahren']);
	}
}
