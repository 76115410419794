import {Component, OnDestroy, OnInit} from '@angular/core';
import {MatLegacyTableDataSource as MatTableDataSource} from '@angular/material/legacy-table';
import {MonthlyStatistik, MonthlyStatistikEntry, StatistikFacade} from '@schir-int-client/statistik-shared';
import {RegisterName} from '@schir-int-client/register-shared';
import {VerfahrenStatus} from '@schir-int-client/verfahren-shared';
import {BaseEditorComponent, ClipboardService} from '@schir-int-client/tech';
import {UntypedFormGroup} from '@angular/forms';
import {ApiRootFacade, ApiRootResource} from '@schir-int-client/api-root';
import {combineLatest, Subscription} from 'rxjs';


@Component({
	selector: 'schir-int-client-monats-statistik',
	templateUrl: './monats-statistik.component.html',
	styleUrls: ['./monats-statistik.component.scss'],
})
export class MonatsStatistikComponent extends BaseEditorComponent implements OnInit, OnDestroy {

	displayedRegisters: RegisterName[] = [];
	displayedColumns: string[] = [];
	firstColumnTemplate: string[] = [];
	tableTemplate: any[];
	dataSource: MatTableDataSource<any[]>;
	monthlyStatistik: MonthlyStatistik;

	private subscription: Subscription;

	constructor(private facade: StatistikFacade,
	            private clipboardService: ClipboardService,
	            private apiRootFacade: ApiRootFacade) {
		super();
	}

	ngOnInit() {
		this.subscription = combineLatest([this.facade.monthlyStatistik, this.apiRootFacade.getCurrentApiRoot()])
			.subscribe(([mStatistik, apiRoot]) => {
				this.monthlyStatistik = mStatistik;
				this.adjustforLFPR(apiRoot);

				this.prepareTableTemplate();
				this.createTable(this.mapToColumns(mStatistik, true));
			});
	}

	private adjustforLFPR(apiRoot: ApiRootResource) {
		if (apiRoot.features.lfpr) {
			this.displayedRegisters = [RegisterName.LR, RegisterName.AR];
			this.firstColumnTemplate = this.firstColumLFPR;
		} else {
			this.displayedRegisters = [RegisterName.SSR, RegisterName.BSR, RegisterName.SBR, RegisterName.AR];
			this.firstColumnTemplate = this.firstColum;
		}
	}

	private addDisplayedColumn(columnName: string) {
		if (this.displayedColumns.indexOf(columnName) === -1) {
			this.displayedColumns.push(columnName);
		}
	}

	private createTable(columns: Map<number, Array<any>>) {
		columns.forEach(column => {
			const columnName = column[0];

			for (let row = 0; row < this.tableTemplate.length; row++) {
				this.tableTemplate[row][columnName] = column[row];
			}

			this.addDisplayedColumn(columnName);
		});
	}

	private mapToColumns(mStatistik: MonthlyStatistik, onlyLatest: boolean) {
		const columns = new Map<number, Array<any>>();

		const months = mStatistik[RegisterName.AR].length;
		const start = onlyLatest && months > 12 ? months - 12 : 0;

		for (let col = start; col < months; col++) {
			const columEntries = [];

			for (let register of this.displayedRegisters) {
				const entry: MonthlyStatistikEntry = mStatistik[register][col];

				columEntries.push(new Date(entry.datum).toLocaleDateString('de-DE', {
					day: '2-digit',
					month: '2-digit',
					year: 'numeric',
				}));
				columEntries.push(entry[VerfahrenStatus.VORLAEUFIG.toLowerCase()]);
				columEntries.push(entry[VerfahrenStatus.EINGETRAGEN.toLowerCase()]);
				columEntries.push(entry[VerfahrenStatus.ABGELEHNT.toLowerCase()]);
				columEntries.push(entry[VerfahrenStatus.GESCHLOSSEN.toLowerCase()]);
				columEntries.push(entry[VerfahrenStatus.GELOESCHT.toLowerCase()]);
				columEntries.push(this.computeSum(entry));
			}

			columns.set(col, columEntries);
		}
		return columns;
	}

	private computeSum(entry: MonthlyStatistikEntry): number {
		let sum = 0;
		for (let property in entry) {
			if (property !== 'datum' && property !== 'register') {
				sum += entry[property];
			}
		}
		return sum;
	}

	exportAsCsv() {
		this.prepareTableTemplate();

		const columns = this.mapToColumns(this.monthlyStatistik, false);

		columns.forEach(column => {
			const columnName = column[0];

			for (let row = 0; row < this.tableTemplate.length; row++) {
				this.tableTemplate[row][columnName] = column[row];
			}
		});

		let csvString = '';

		for (let entry of this.tableTemplate) {
			for (let data in entry) {
				csvString += entry[data] + ',';
			}
			csvString = csvString.slice(0, csvString.length - 1) + '\n';
		}

		this.clipboardService.copyAndShowSnackBar(csvString, 'Die gesamte Monats-Statistik wurde in die Zwischenablage kopiert.');
	}

	get form(): UntypedFormGroup {
		return new UntypedFormGroup({});
	}

	async submit(): Promise<boolean> {
		this.exportAsCsv();
		return true;
	}

	private prepareTableTemplate() {
		const template = <any>[];

		this.firstColumnTemplate.forEach(value => template.push({ status: value }));

		this.addDisplayedColumn('status');
		this.tableTemplate = template;

		this.dataSource = new MatTableDataSource<any[]>(template);
	}

	ngOnDestroy(): void {
		if (this.subscription) {
			this.subscription.unsubscribe();
		}
	}

	private firstColumLFPR = [
		'LR',
		'vorläufig',
		'eingetragen',
		'abgelehnt',
		'geschlossen',
		'gelöscht',
		'Summe',
		'AR',
		'vorläufig',
		'eingetragen',
		'abgelehnt',
		'geschlossen',
		'gelöscht',
		'Summe',
	];

	private firstColum = [
		'SSR',
		'vorläufig',
		'eingetragen',
		'abgelehnt',
		'geschlossen',
		'gelöscht',
		'Summe',
		'BSR',
		'vorläufig',
		'eingetragen',
		'abgelehnt',
		'geschlossen',
		'gelöscht',
		'Summe',
		'SBR',
		'vorläufig',
		'eingetragen',
		'abgelehnt',
		'geschlossen',
		'gelöscht',
		'Summe',
		'AR',
		'vorläufig',
		'eingetragen',
		'abgelehnt',
		'geschlossen',
		'gelöscht',
		'Summe',
	];
}
