import {Component, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {
	VerfahrenActions,
	VerfahrenSearchKey,
	VerfahrenSearchModeLabel,
	VerfahrenStatus,
	VerfahrenStatusLabel,
} from '@schir-int-client/verfahren-shared';
import {VerfahrenGlobalSearchFormService} from './verfahren-global-search.formservice';
import {Actions, ofType} from '@ngrx/effects';
import {MatLegacyCheckbox as MatCheckbox} from '@angular/material/legacy-checkbox';
import {Subscription} from 'rxjs';
import {DialogService, NotificationType} from '@schir-int-client/tech';
import {filter} from 'rxjs/operators';
import {isNil} from 'lodash-es';
import {ApiRootFacade} from '@schir-int-client/api-root';

@Component({
	selector: 'schir-int-client-verfahren-global-search',
	templateUrl: './verfahren-global-search.component.html',
	styleUrls: ['./verfahren-global-search.component.scss'],
})
export class VerfahrenGlobalSearchComponent implements OnInit, OnDestroy {

	private static readonly MISSING_FILTER_MSG = 'Bitte wählen Sie wenigstens einen Kriterium- und einen Status-Filter für die Suche aus.';

	searchModeLabel: VerfahrenSearchModeLabel[];
	statusModes = VerfahrenStatus;
	statusModeLabel = VerfahrenStatusLabel;
	allFiltersSelected = true;
	private subscriptions: Subscription[] = [];
	searchFields: VerfahrenSearchKey[];

	constructor(private router: Router,
	            readonly formService: VerfahrenGlobalSearchFormService,
	            private reloadVerfahrenAction: Actions,
	            private dialogService: DialogService,
	            private apiRootFacade: ApiRootFacade) {
		this.subscriptions.push(reloadVerfahrenAction.pipe( //TODO subscribe to facade observable?
			ofType(VerfahrenActions.RELOAD_SEARCH_RESULT),
		).subscribe(() => {
			if (this.router.url.startsWith('/verfahren/')) {
				this.reloadCurrentPage();
			} else {
				this.onSearch();
			}
		}));
	}

	reloadCurrentPage() {
		let currentUrl = this.router.url;
		this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
			this.router.navigate([currentUrl.split('(')[0]]);
		});
	}

	ngOnInit() {
		this.subscriptions.push(this.formService.allFiltersSelected.subscribe(value => {
			this.allFiltersSelected = value;
		}));
		this.subscriptions.push(this.apiRootFacade.getCurrentApiRoot().pipe(filter(apiRoot => !isNil(apiRoot))).subscribe(apiRoot => {
			this.searchFields = apiRoot.features.searchableFields;
			this.searchModeLabel = this.searchFields.map(elem => elem in VerfahrenSearchModeLabel ? VerfahrenSearchModeLabel[elem] : elem);
		}));
	}

	ngOnDestroy() {
		this.subscriptions.forEach(s => s.unsubscribe());
	}

	onSearch() {
		if (this.formService.enoughFiltersSelected()) {
			this.formService.onSubmit();
			this.router.navigate(['verfahren']);
		} else {
			this.dialogService.openAcknowledgeDialog(VerfahrenGlobalSearchComponent.MISSING_FILTER_MSG, NotificationType.INFO);
		}
	}

	toggleAllFilters($event: MouseEvent) {
		$event.stopPropagation(); //stop mouseevent from closing menu
		this.formService.selectAllFilter();
	}

	onCheck($event: MouseEvent, cb: MatCheckbox) {
		$event.stopPropagation(); //stop mouseevent from closing menu
		this.formService.refreshAllChkbxState(cb);
	}

	checkboxLabelAll() {
		return 'Anklickbar: Suche in allen Feldern aktivieren, Kontrollfeld ' + (this.formService.allChkbx.value ? '' : 'nicht ') + 'aktiviert';
	}

	checkboxLabelSearchMode(key: number) {
		const filters: string[] = this.formService.getSearchModeFilters();
		const checked: boolean = filters.indexOf(this.formService.searchFields[key]) > -1;
		return 'Anklickbar: In ' + this.searchModeLabel[key] + ' suchen, Kontrollfeld ' + (checked ? '' : 'nicht ') + 'aktiviert';
	}

	checkboxLabelStatus(key: string) {
		const checked: boolean = this.formService.getStatusFilters().indexOf(<VerfahrenStatus>key) > -1;
		return 'Anklickbar: Nach Status ' + VerfahrenStatusLabel[key] + ' filtern, Kontrollfeld ' + (checked ? '' : 'nicht ') + 'aktiviert';
	}
}
