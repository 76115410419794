import {Component, Input} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {isVermerk} from 'libs/register-shared/src/lib/register.util';
import {AmtlicherAusdruckErstellenDialogFormService} from '../amtlicher-ausdruck-erstellen-dialog.formservice';
import {ChangeEntryResource} from '@schir-int-client/register-change';

@Component({
	selector: 'schir-int-client-amtlichen-ausdruck-erstellen-entry',
	templateUrl: './amtlichen-ausdruck-erstellen-entry.component.html',
	styleUrls: ['./amtlichen-ausdruck-erstellen-entry.component.scss'],
})
export class AmtlichenAusdruckErstellenEntryComponent {

	@Input() form: UntypedFormGroup;

	readonly formServiceClass = AmtlicherAusdruckErstellenDialogFormService;

	constructor(private formService: AmtlicherAusdruckErstellenDialogFormService) { }

	fillTextareaWithText(formGroup: UntypedFormGroup): void {
		this.formService.fillAlternativeTextWithTranslatedText(formGroup);
	}

	hasTranslation(formControl: UntypedFormGroup): boolean {
		const changeEntryResource: ChangeEntryResource = formControl.value.flaggenrechtResource;
		return !isVermerk(changeEntryResource);
	}
}
