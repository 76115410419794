import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatLegacyProgressSpinnerModule as MatProgressSpinnerModule} from '@angular/material/legacy-progress-spinner';
import {RouterModule} from '@angular/router';
import {PosteingangModule} from '@schir-int-client/posteingang';
import {TechModule} from '@schir-int-client/tech';
import {WiedervorlageModule} from '@schir-int-client/wiedervorlage';
import {VorgangModule} from '@schir-int-client/vorgang';
import {DashboardPageComponent} from './dashboard-page/dashboard-page.component';
import {EingangListComponent} from './eingang-list/eingang-list.component';
import {RuecklaufComponent} from './ruecklauf/ruecklauf.component';
import {AktenzeichenModule} from '@schir-int-client/aktenzeichen';

@NgModule({
	imports: [
		CommonModule,
		MatGridListModule,
		PosteingangModule,
		RouterModule,
		VorgangModule,
		TechModule,
		MatProgressSpinnerModule,
		WiedervorlageModule,
		AktenzeichenModule,
	],
	declarations: [
		DashboardPageComponent,
		RuecklaufComponent,
		EingangListComponent,
	],
})
export class DashboardModule {}
