import {Action} from '@ngrx/store';
import {ApiRootResource} from './api-root.model';

export enum ActionType {
	LoadApiRoot = '[API Root] Load API Root',
	ApiRootLoaded = '[API Root] API Root loaded'
}

export class LoadApiRootAction implements Action {
	public type = ActionType.LoadApiRoot;
}

export class ApiRootLoadedAction implements Action {
	public type = ActionType.ApiRootLoaded;

	constructor(public payload: ApiRootResource) { }
}
