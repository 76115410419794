import {Injectable, OnDestroy} from '@angular/core';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {
	AdressverwaltungFacade,
	Kontakt,
	KontaktKategorie,
	KontaktResource,
} from '@schir-int-client/adressverwaltung-shared';
import {isNil} from 'lodash-es';
import {Subscription} from 'rxjs';
import {filter} from 'rxjs/operators';
import {ApiRootFacade} from '@schir-int-client/api-root';

@Injectable()
export class KontaktFormService implements OnDestroy {
	form: UntypedFormGroup;
	kontaktResource: KontaktResource;

	juristischePersonSubscription: Subscription;
	behoerdeSubscription: Subscription;

	behoerdeAutoKontakt: string[] = [];

	constructor(public formBuilder: UntypedFormBuilder, public facade: AdressverwaltungFacade, protected apiRootFacade: ApiRootFacade) {
		this.initForm();

		apiRootFacade.getCurrentApiRoot().pipe(filter(apiRoot => !isNil(apiRoot))).subscribe(apiRoot => {
			this.behoerdeAutoKontakt = apiRoot.features.behoerdeAutoKontakt;

			for (let register of this.behoerdeAutoKontakt) {
				this.form.addControl(register.toLowerCase(), new UntypedFormControl(false));
			}
		});

		this.subscribeToFields();
	}

	private initForm(): void {
		this.form = this.formBuilder.group({
			juristischePerson: new UntypedFormControl(false),
			anrede: new UntypedFormControl(null),
			firmenName: new UntypedFormControl(null),
			firmenName1: new UntypedFormControl(null),
			firmenName2: new UntypedFormControl(null),
			titel: new UntypedFormControl(null),
			vorname: new UntypedFormControl(null),
			name: new UntypedFormControl(null),
			geburtsname: new UntypedFormControl(null),
			namenszusatz: new UntypedFormControl(null),
			weitereVornamen: new UntypedFormControl(null),
			sitz: new UntypedFormControl(null),
			zustaendigesAmtsgericht: new UntypedFormControl(null),
			handelsregisterNummer: new UntypedFormControl(null),
			geburtsDatum: new UntypedFormControl(null),
			strasse: new UntypedFormControl(null),
			hausnummer: new UntypedFormControl(null),
			plz: new UntypedFormControl(null),
			stadt: new UntypedFormControl(null),
			land: new UntypedFormControl(null),
			postfach: new UntypedFormControl(null),
			adresszusatz: new UntypedFormControl(null),
			egvpAdresse: new UntypedFormControl(null),
			email: new UntypedFormControl(null),
			telefon: new UntypedFormControl(null),
			telefon2: new UntypedFormControl(null),
			telefon3: new UntypedFormControl(null),
			fax: new UntypedFormControl(null),
			deMail: new UntypedFormControl(null),
			notiz: new UntypedFormControl(null),
			behoerde: new UntypedFormControl(false),
		});
	}

	public subscribeToFields(): void {
		this.subscribeBehoerde();
		this.subscribeJuristischePerson();
	}

	private subscribeJuristischePerson(): void {
		this.juristischePersonSubscription = this.form.controls.juristischePerson.valueChanges.subscribe(juristischePerson => {
			if (juristischePerson) {
				this.form.controls.firmenName.setValidators(Validators.required);
				this.form.controls.firmenName.markAsTouched();
				this.form.controls.name.clearValidators();
			} else {
				this.form.controls.firmenName.clearValidators();
				this.form.controls.name.setValidators(Validators.required);
				this.form.controls.name.markAsTouched();
			}
			this.form.controls.firmenName.updateValueAndValidity();
			this.form.controls.name.updateValueAndValidity();
			if (this.form.controls.firmenName.status == 'VALID') {
				this.form.controls.firmenName.markAsUntouched();
			}
			if (this.form.controls.name.status == 'VALID') {
				this.form.controls.name.markAsUntouched();
			}
		});
	}

	private subscribeBehoerde(): void {
		this.behoerdeSubscription = this.form.controls.behoerde.valueChanges.subscribe(behoerde => {
			if (!behoerde) {
				for (const register of this.behoerdeAutoKontakt) {
					this.form.get(register.toLowerCase()).patchValue(false);
				}
			}
		});
	}

	public isBehoerde(): boolean {
		return this.form.controls.behoerde.value;
	}

	public patch(kontaktResource: KontaktResource): void {
		this.kontaktResource = kontaktResource;

		this.form.reset();
		this.form.patchValue(kontaktResource);

		const autoKontaktRegisterBehoerden = kontaktResource?.autoKontaktRegister[KontaktKategorie.BEHOERDEN];

		if (!isNil(autoKontaktRegisterBehoerden)) {
			for (const register of this.behoerdeAutoKontakt) {
				this.form.get(register.toLowerCase()).patchValue(autoKontaktRegisterBehoerden.includes(register));
			}
		}
	}

	public patchNewKontakt(): void {
		this.kontaktResource = null;
		this.form.reset();
		this.form.patchValue(this.createNewKontakt());
	}

	private createNewKontakt(): KontaktResource {
		return <KontaktResource>{
			autoKontaktRegister: new Map(),
			behoerde: false,
			juristischePerson: false,
		};
	}

	public activateEditMode() {
		this.facade.setEditMode(true);
	}

	public deactivateEditMode() {
		this.facade.setEditMode(false);
	}

	public submit(): boolean {
		if (this.isValid()) {
			const kontakt: Kontakt = this.updateAutoKontaktRegister(this.form.value);

			if (!isNil(this.kontaktResource)) {
				this.facade.updateKontakt(kontakt);
			} else {
				this.facade.createKontakt(kontakt);
			}

			return true;
		}
		return false;
	}

	private updateAutoKontaktRegister(kontaktToUpdate: Kontakt): Kontakt {
		let kontakt = { ...kontaktToUpdate };

		kontakt.autoKontaktRegister = new Map();

		const autoKontaktRegisterBehoerden = [];

		for (const register of this.behoerdeAutoKontakt) {
			if (this.form.get(register.toLowerCase())?.value) {
				autoKontaktRegisterBehoerden.push(register);
			}

			delete kontakt[register.toLowerCase()];
		}

		if (autoKontaktRegisterBehoerden.length > 0) {
			kontakt.autoKontaktRegister[KontaktKategorie.BEHOERDEN] = autoKontaktRegisterBehoerden;
		}

		return kontakt;
	}

	public isValid(): boolean {
		return this.form.valid;
	}

	ngOnDestroy(): void {
		if (!isNil(this.juristischePersonSubscription)) {
			this.juristischePersonSubscription.unsubscribe();
		}
		if (!isNil(this.behoerdeSubscription)) {
			this.behoerdeSubscription.unsubscribe();
		}
	}
}
