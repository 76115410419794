import {Action} from '@ngrx/store';
import {Resource} from '@ngxp/rest';
import {UserResource} from './user.model';

export enum UserActions {
	LOGGED_IN = 'LOGGED_IN',
	USER_LOADED = 'USER_LOADED',
	LOGOUT = 'LOGOUT',
	LOGGED_OUT = 'LOGGED_OUT',
	IS_UNAUTHORIZED = 'IS_UNAUTHORIZED',
	LOAD_USER_FROM_RESOURCE = 'LOAD_USER_FROM_RESOURCE',
	LOAD_USER_SUCCESS = 'LOAD_USER_SUCCESS'
}

export class UserLoggedInAction implements Action {
	type = UserActions.LOGGED_IN;
}

export class UserLoadedAction implements Action {
	type = UserActions.USER_LOADED;

	constructor(public user: UserResource) { }
}

export class UserLogoutAction implements Action {
	type = UserActions.LOGOUT;
}

export class UserLoggedOutAction implements Action {
	type = UserActions.LOGGED_OUT;
}

export class UserIsUnauthorizedAction implements Action {
	type = UserActions.IS_UNAUTHORIZED;
}

export class LoadUserFromResourceAction implements Action {
	type = UserActions.LOAD_USER_FROM_RESOURCE;

	constructor(public resource: Resource, public linkRel: string) { }
}

export class LoadUserSuccessAction implements Action {
	type = UserActions.LOAD_USER_SUCCESS;

	constructor(public user: UserResource) { }
}
