import {Injectable} from '@angular/core';
import {ResourceFactory} from '@ngxp/rest';
import {Observable} from 'rxjs';
import {GerichtProfile, GerichtProfileResource} from './gerichtprofile.model';
import {GerichtProfileLinkRel} from './gerichtprofile.linkrel';
import {AdminRootResource} from 'libs/admin-shared/src/lib/admin.model';
import {AdminRootLinkRel} from 'libs/admin-shared/src/lib/admin.linkrel';

@Injectable({ providedIn: 'root' })
export class GerichtProfileService {

	constructor(private resourceFactory: ResourceFactory) { }

	getProfile(adminRoot: AdminRootResource): Observable<GerichtProfileResource> {
		return this.resourceFactory.from(adminRoot).get(AdminRootLinkRel.GERICHT_PROFILE);
	}

	updateProfile(profileToUpdate: GerichtProfileResource, newValues: GerichtProfile): Observable<GerichtProfileResource> {
		return this.resourceFactory.from(profileToUpdate).post(GerichtProfileLinkRel.EDIT, newValues);
	}
}
