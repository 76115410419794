import {Component, OnDestroy} from '@angular/core';
import {StateResource} from '@schir-int-client/ngrx-helpers';
import {
	NewVerfahren,
	noLimit,
	VerfahrenActions,
	VerfahrenFacade,
	VerfahrenListLinkRel,
	VerfahrenListResource,
	VerfahrenResource,
	VerfahrenSearchKey,
	VerfahrenStatus,
} from '@schir-int-client/verfahren-shared';
import {Observable, Subject, Subscription} from 'rxjs';
import {CreateVerfahrenDialogComponent} from '../create-verfahren-dialog/create-verfahren-dialog.component';
import {Actions, ofType} from '@ngrx/effects';
import {getEmbeddedResource} from '@ngxp/rest';
import {isNil} from 'lodash-es';
import {ApiRootFacade} from '@schir-int-client/api-root';
import {filter, take} from 'rxjs/operators';
import {DialogService, HandlesBackdropClickAndEscapeKey} from '@schir-int-client/tech';

@Component({
	selector: 'schir-int-client-verfahren-search',
	templateUrl: './verfahren-search.component.html',
	styleUrls: ['./verfahren-search.component.scss'],
})
export class VerfahrenSearchComponent extends HandlesBackdropClickAndEscapeKey<CreateVerfahrenDialogComponent> implements OnDestroy {
	verfahrenResultList: Observable<StateResource<VerfahrenResource[]>>;
	verfahren: Observable<StateResource<VerfahrenListResource>>;
	private subscriptions: Subscription[] = [];
	selectedVerfahren: Observable<VerfahrenResource>;
	searchBy: string;
	showDefaultVerfahrenSearch = true;
	hasMore: boolean;
	totalElements: number;
	searchFields: VerfahrenSearchKey[];
	audioAlert: Subject<string> = new Subject<string>();

	constructor(private facade: VerfahrenFacade,
	            private reloadVerfahrenAction: Actions,
	            private apiRootFacade: ApiRootFacade,
	            protected dialogService: DialogService) {
		super(dialogService);

		this.selectedVerfahren = this.facade.selectedVerfahren;
		this.verfahrenResultList = this.facade.verfahrenResultList;
		this.verfahren = this.facade.verfahren;

		this.subscriptions.push(reloadVerfahrenAction.pipe(
			ofType(VerfahrenActions.RELOAD_SEARCH_RESULT),
		).subscribe(() => this.onSearch()));

		this.subscriptions.push(this.verfahren.subscribe(verfahren => {
			if (verfahren.resource) {
				this.hasMore = verfahren.resource.page.totalElements > verfahren.resource.page.size;
				this.totalElements = verfahren.resource.page.totalElements;
				this.facade.setSelectedVerfahren(getEmbeddedResource(verfahren.resource, VerfahrenListLinkRel.VERFAHREN_LIST)[0]);
				this.audioAlert.next('Suchergebnisse aktualisiert: ' + this.totalElements + ' Verfahren');
			}
		}));
		this.subscriptions.push(this.apiRootFacade.getCurrentApiRoot().pipe(filter(apiRoot => !isNil(apiRoot))).subscribe(apiRoot => {
			this.searchFields = apiRoot.features.searchableFields;
		}));
	}

	ngOnDestroy() {
		this.subscriptions.forEach(s => s.unsubscribe());
	}

	changeChildView() {
		this.showDefaultVerfahrenSearch = false;
	}

	onSearch(limit?: number) {
		this.audioAlert.next('');
		this.facade.searchVerfahren({
			searchString: this.searchBy == null ? '' : this.searchBy,
			searchIn: this.searchFields,
			filterByStatus: Object.keys(VerfahrenStatus).map(k => VerfahrenStatus[k]),
		}, limit);
	}

	openCreateVerfahrenDialog() {
		this.matDialogRef = this.dialogService.openEditorDialog(this, CreateVerfahrenDialogComponent, {
			data: { hasBlattnummerInput: false },
		});

		this.subscriptions.push(this.matDialogRef.componentInstance.onCreateNewVerfahren.pipe(take(1)).subscribe((verfahren: NewVerfahren) => {
			this.facade.createNeueintragung(verfahren.register);
		}));
	}

	loadAllVerfahren() {
		this.onSearch(noLimit);
	}

	clear() {
		this.searchBy = '';
	}
}
