export interface AppNotification {
	notificationType: NotificationType,
	id: string,
	message: string,
	headline?: string,
	attachment?: any,
	attachmentLabel?: string
}

export enum NotificationType {
	ERROR = 'ERROR',
	INFO = 'INFO'
}
