import {Component} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {AufgabenTyp, AufgabeVersandTaetigkeit, AufgabeZertifikatTaetigkeit} from '@schir-int-client/aufgabe-shared';
import {CreateAufgabeFormService} from '../../create-aufgabe-dialog/create-aufgabe.formservice';

@Component({
	selector: 'schir-int-client-aufgabe-zertifikat-editor',
	templateUrl: './aufgabe-zertifikat-editor.component.html',
	styleUrls: ['./aufgabe-zertifikat-editor.component.scss'],
})
export class AufgabeZertifikatEditorComponent {

	readonly taetigkeiten = AufgabeZertifikatTaetigkeit;
	readonly formServiceClass = CreateAufgabeFormService;
	readonly aufgabenTyp = AufgabenTyp;

	constructor(private formService: CreateAufgabeFormService) { }

	get formGroup(): UntypedFormGroup {
		return <UntypedFormGroup>this.formService.controlGroupZertifikat;
	}

	get bodyGroup(): UntypedFormGroup {
		return <UntypedFormGroup>this.formGroup.controls[CreateAufgabeFormService.feldAufgabeBody];
	}

	protected readonly versandTaetigkeit = AufgabeVersandTaetigkeit;
}
