import {InjectionToken} from '@angular/core';
import {ActionReducerMap} from '@ngrx/store';
import {ReducerConfig} from '@schir-int-client/test-helpers';
import {posteingangReducer, PosteingangState} from './posteingang.reducer';

export const featureState = 'PosteingangState';
export const posteingangReducerInjectionToken = new InjectionToken<ActionReducerMap<PosteingangRootState>>('Registered PosteingangReducer');

export interface PosteingangRootState {
	posteingangRoot: PosteingangState;
}

export function getPosteingangReducer(): ActionReducerMap<PosteingangRootState> {
	return {
		posteingangRoot: posteingangReducer,
	};
}

export const reducerConfig: ReducerConfig<PosteingangRootState> = {
	reducers: getPosteingangReducer(),
	injectionToken: posteingangReducerInjectionToken,
};
