import {Selector} from '@ngrx/store';
import {isNull} from 'lodash-es';
import {ApiRootResource} from './api-root.model';
import {AppState} from './app-state.model';

export const apiRoot: Selector<AppState, ApiRootResource | null> = getApiRoot();

export function getApiRoot(): Selector<AppState, ApiRootResource | null> { //TODO use createSelector()
	return (state: AppState) => state?.apiRoot?.apiRoot;
}

export function getApiRootIsLoaded(): Selector<AppState, boolean> {
	return (state: AppState) => {
		const apiRoot = getApiRoot()(state);
		return !isNull(apiRoot);
	};
}
