import {Injectable} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {Adressat} from '@schir-int-client/aufgabe-shared';

@Injectable()
export class AdressatFormService {
	form: UntypedFormGroup;

	constructor(private formBuilder: UntypedFormBuilder) {
		this.initForm();
	}

	initForm() {
		this.form = this.formBuilder.group({
			channelBrief: null,
			channelFax: null,
			channelEmail: null,
			channelAbholung: null,
			channelEgvp: null,
		});
	}

	patchChannel(adressat: Adressat) {
		this.form.controls['channelBrief'].patchValue(adressat.channelBrief);
		this.form.controls['channelFax'].patchValue(adressat.channelFax);
		this.form.controls['channelEmail'].patchValue(adressat.channelEmail);
		this.form.controls['channelAbholung'].patchValue(adressat.channelAbholung);
		this.form.controls['channelEgvp'].patchValue(adressat.channelEgvp);
	}

	patchChannelBriefToTrue() {
		this.form.controls['channelBrief'].patchValue(true);
	}

	getChannel() {
		return {
			channelBrief: this.form.controls['channelBrief'].value,
			channelFax: this.form.controls['channelFax'].value,
			channelEmail: this.form.controls['channelEmail'].value,
			channelAbholung: this.form.controls['channelAbholung'].value,
			channelEgvp: this.form.controls['channelEgvp'].value,
		};
	}
}
