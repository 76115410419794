import {isFunction, isUndefined} from 'lodash-es';
import {default as Ajv} from 'ajv';

let ajv;
const config = {
	failOnMissingSchema: true,
	failOnSchemaError: true,
};

interface ValidateSchema {
	(data): boolean;

	errors: any;
}

export function init(schemas: { [key: string]: {} }, failOnMissingSchema: boolean = true, failOnSchemaError: boolean = true) {
	ajv = new Ajv({
		schemas,
		multipleOfPrecision: 10,
	});
	config.failOnMissingSchema = failOnMissingSchema;
	config.failOnSchemaError = failOnSchemaError;
}

export function validate<T>(schema: string, data: T): T {
	if (isUndefined(ajv)) {
		throw new Error('JSON Schema Validator has not been initialized yet.');
	}

	const schemaUri = `resource:///schemas/${schema}.json`;
	const validateSchema: ValidateSchema = ajv.getSchema(schemaUri);

	if (!isFunction(validateSchema) && config.failOnMissingSchema) {
		throw new Error(`Schema <${schemaUri}> not found.`);
	}

	if (isFunction(validateSchema)) {
		if (!validateSchema(data)) {
			const errorMessages = formatErrorMessages(data, validateSchema.errors);

			if (config.failOnSchemaError) {
				throw new Error(errorMessages);
			} else {
				console.warn(errorMessages);
			}
		}
	}

	return data;
}

function formatErrorMessages(data, errors) {
	return [
		'JSON Schema Validation Error',
		JSON.stringify(data, null, 4),
		...errors.map(error => `${error.dataPath} ${error.message} (${JSON.stringify(error, null, 4)});`),
	]
		.join('\n');
}
