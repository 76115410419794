import {Component, Input, OnChanges} from '@angular/core';
import {getUrl} from '@ngxp/rest';
import {DocumentLinkRel, DocumentResource} from '@schir-int-client/posteingang-shared';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';

@Component({
	selector: 'schir-int-client-pdf-viewer',
	templateUrl: './pdf-viewer.component.html',
	styleUrls: ['./pdf-viewer.component.scss'],
})
export class PdfViewerComponent implements OnChanges {

	@Input() documents: DocumentResource[];
	@Input() selectedDocumentIndex: number;

	docUrl: SafeResourceUrl;

	constructor(private sanitizer: DomSanitizer) { }

	ngOnChanges() {
		if (this.documents.length) {
			if (!this.selectedDocumentIndex) {
				this.selectedDocumentIndex = 0;
			}
			this.setDocUrl(this.documents[this.selectedDocumentIndex]);
		}
	}

	setDocUrl(document: DocumentResource) {
		this.docUrl = this.sanitizer.bypassSecurityTrustResourceUrl(getUrl(document, DocumentLinkRel.DOWNLOAD));
	}
}
