import {Component, EventEmitter, HostListener} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {DialogButtonType} from '@schir-int-client/dialog-shared';
import {DialogService, Submittable} from '../dialog.service';

export interface PotentiallyDirty {
	isDirty(): boolean;
}

@Component({
	template: '',
})
export abstract class SupportsWarningDialogBeforeClose implements PotentiallyDirty {

	onKeyUpEsc: EventEmitter<boolean> = new EventEmitter();
	onClickButtonClose: EventEmitter<any> = new EventEmitter();

	warningDialogVisible: boolean = false;

	/**
	 * Ermöglicht Parent-Komponenten auf das KeyUp-Event zu reagieren, ohne dass es zu einer Mehrfach-Ausführung kommt.
	 */
	@HostListener('window:keydown.esc')
	public onEsc(): void {
		if (!this.warningDialogVisible) {
			this.onKeyUpEsc.emit();
		}
	}

	warningDialogOpened(): void {
		this.warningDialogVisible = true;
	}

	warningDialogClosed(): void {
		this.warningDialogVisible = false;
	}

	abstract submit(): Promise<boolean>

	abstract reset();

	abstract isValid(): boolean;

	abstract isDirty(): boolean;
}

export abstract class BaseEditorComponent extends SupportsWarningDialogBeforeClose implements Submittable {

	submitted = new EventEmitter<boolean>();

	abstract get form(): UntypedFormGroup;

	async onSave() {
		this.submitted.emit(await this.submit());
	}

	onCancel() {
		this.onClickButtonClose.emit();
	}

	isValid(): boolean {
		return this.form.valid;
	}

	isDirty(): boolean {
		return this.form.dirty;
	}

	reset(): void {
		this.form.reset();
	}

}

export abstract class HandlesBackdropClickAndEscapeKey<T extends SupportsWarningDialogBeforeClose> {

	getConfirmMessage(): string {
		return RegisterValidationMessages.ASK_EXIT_DIALOG_WITHOUT_SAVING;
	}

	protected constructor(protected dialogService: DialogService) {}

	public matDialogRef: MatDialogRef<T>;

	public preventCloseOfDirtyForm() {
		//Escape-Key abfangen
		this.matDialogRef.componentInstance.onKeyUpEsc.subscribe(editMode => {
			this.showConfirmDialogConditionally(editMode);
		});
		//Klick auf Hintergrund abfangen
		this.matDialogRef.backdropClick().subscribe(() => {
			this.showConfirmDialogConditionally(false);
		});
		//Close-Event auch abfangen
		this.matDialogRef.componentInstance.onClickButtonClose.subscribe(() => {
			this.showConfirmDialogConditionally(false);
		});
	}

	/**
	 * Achtung! - muss manchmal anders implementiert werden.
	 * @protected
	 */
	protected showConfirmDialogConditionally(editMode: boolean) {
		if (this.matDialogRef.componentInstance['warningDialogOpened']) {
			this.matDialogRef.componentInstance.warningDialogOpened();
		}

		if (this.matDialogRef.componentInstance.isDirty()) {
			this.createConfirmDialogAndCloseOnAgree();
		} else {
			this.closeAndSetActiveEditorNull();
		}
	}

	protected createConfirmDialogAndCloseOnAgree() {
		const dialogSubscription = this.dialogService.openDialogSelection(
			this.getConfirmMessage(),
			['Abbrechen', 'Verwerfen und Schließen', 'Speichern und Schließen'],
			[DialogButtonType.CANCEL, DialogButtonType.CANCEL, DialogButtonType.SAVE],
			[false, false, !this.matDialogRef.componentInstance.isValid()],
		).subscribe(async data => {
			if (data) {
				if (data.selection == 1) {
					this.matDialogRef.componentInstance.reset();
					this.closeAndSetActiveEditorNull();
				} else if (data.selection == 2) {
					await this.matDialogRef.componentInstance.submit();
					this.closeAndSetActiveEditorNull();
				}
			}

			if (this.matDialogRef.componentInstance['warningDialogClosed']) {
				this.matDialogRef.componentInstance.warningDialogClosed();
			}

			dialogSubscription.unsubscribe();
		});
	}

	protected closeAndSetActiveEditorNull(): void {
		this.matDialogRef.close();

		if (this.matDialogRef.componentInstance['warningDialogClosed']) {
			this.matDialogRef.componentInstance.warningDialogClosed();
		}
	}
}

export enum RegisterValidationMessages {
	MISSING_REQUIRED_SELECT_VALUE = 'muss ausgewählt werden',
	WRONG_DECIMAL_NUMBER = 'maximal zwei Nachkommastellen',
	NO_VALID_VALUE = 'Bitte geben sie einen gültigen Wert ein.',
	ASK_EXIT_DIALOG_WITHOUT_SAVING = 'Die Daten wurden noch nicht gespeichert.'
}
