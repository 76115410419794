import {Action} from '@ngrx/store';
import {FrontendEnvironment} from './environment.model';

export const ActionType: { [key: string]: ActionType } = {
	EnvironmentLoaded: '[Environment] Environment loaded',
};

export type ActionType = '[Environment] Environment loaded';

export class EnvironmentLoadedAction implements Action {
	public type = ActionType.EnvironmentLoaded;

	constructor(public payload: FrontendEnvironment) {}
}
