import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {getEmbeddedResource, getUrl, hasEmbeddedResource} from '@ngxp/rest';
import {AdressverwaltungService} from '@schir-int-client/adressverwaltung-shared';
import {isNull} from 'lodash-es';
import {forkJoin} from 'rxjs';
import {filter, map, mergeMap, switchMap, withLatestFrom} from 'rxjs/operators';
import {
	AdressatenEnrichedAction,
	AufgabeActions,
	ClearAdressatenByAufgabeAction,
	LoadAdressatenByAufgabeAction,
	LoadAdressatenSuccessAction,
	UpdateAufgabeAction,
} from '../aufgabe.actions';
import {AufgabeFacade} from '../aufgabe.facade';
import {AdressatResource, AdressatWithResource, AufgabeResource} from '../aufgabe.model';
import {
	AdressatActions,
	CreateAdressatAction,
	DeleteAdressatAction,
	UpdateAdressatChannelAction,
	UpdateAdressatNotizAction,
} from './adressat.actions';
import {AdressatListLinkRel} from './adressat.linkrel';
import {AdressatService} from './adressat.service';

@Injectable()
export class AdressatEffect {

	private aufgabeDefiningAdressaten: AufgabeResource;

	constructor(
		private actions: Actions,
		private adressverwaltungService: AdressverwaltungService,
		private adressatService: AdressatService,
		private aufgabeFacade: AufgabeFacade,
	) { }


	loadAdressatenInAufgabe = createEffect(() => this.actions.pipe(
		ofType(AufgabeActions.LOAD_ADRESSATEN, AufgabeActions.LOAD_AUFGABE_SUCCESS),
		withLatestFrom(this.aufgabeFacade.aufgabe),
		filter(([, aufgabe]) => {
			// FINDME: diese Bremse ist an der falschen Stelle aber wurde hier minimalinvasiv eingebaut, da Lagevorgang nicht vom PO entschieden
			if (aufgabe === this.aufgabeDefiningAdressaten) {
				return false;
			}
			this.aufgabeDefiningAdressaten = aufgabe;
			return true;
		}),
		switchMap(([, aufgabe]) => {
			return this.adressatService.getAdressaten(aufgabe).pipe(
				map(adressatenList => {
					return new LoadAdressatenSuccessAction(getUrl(aufgabe), adressatenList);
				}),
			);
		}),
	));


	enrichAdressatenWithResource = createEffect(() => this.actions.pipe(
		ofType(AufgabeActions.LOAD_ADRESSATEN_SUCCESS),
		switchMap(action => {
			const aufgabeUri = (<LoadAdressatenSuccessAction>action).aufgabeUri;
			const adressatenRes: AdressatResource[] = getEmbeddedResource((<LoadAdressatenSuccessAction>action).adressaten, AdressatListLinkRel.ADRESSAT_LIST);
			const adressaten: AdressatWithResource[] = isNull(adressatenRes) ? [] : adressatenRes.map(adressat => {
				return { ...adressat, resource: null };
			});
			return forkJoin(
				adressaten.map(adressat => {
					return this.adressverwaltungService.get(adressat.kontakt).pipe(
						map(kontakt => <AdressatWithResource>({ ...adressat, resource: kontakt })),
					);
				}),
			).pipe(
				map(adressatenWithResource => {
					return new AdressatenEnrichedAction(aufgabeUri, adressatenWithResource);
				}),
			);
		}),
	));


	createAdressat = createEffect(() => this.actions.pipe(
		ofType(AdressatActions.CREATE_ADRESSAT),
		withLatestFrom(this.aufgabeFacade.aufgabe),
		switchMap(([action, aufgabe]) => {
			return this.adressatService.createAdressaten(aufgabe, [(<CreateAdressatAction>action).adressat]).pipe(
				map(() => {
					this.aufgabeDefiningAdressaten = null;
					return new LoadAdressatenByAufgabeAction(aufgabe);
				}),
			);
		}),
	));


	updateChannel = createEffect(() => this.actions.pipe(
		ofType(AdressatActions.UPDATE_ADRESSAT_CHANNEL),
		withLatestFrom(this.aufgabeFacade.aufgabe),
		switchMap(([action, aufgabe]) => {
			return this.adressatService.updateChannel((<UpdateAdressatChannelAction>action).adressat).pipe(
				map(adressatenList => new LoadAdressatenSuccessAction(getUrl(aufgabe), adressatenList)),
			);
		}),
	));


	updateNotiz = createEffect(() => this.actions.pipe(
		ofType(AdressatActions.UPDATE_ADRESSAT_NOTIZ),
		withLatestFrom(this.aufgabeFacade.aufgabe),
		switchMap(([action, aufgabe]) => {
			return this.adressatService.updateNotiz((<UpdateAdressatNotizAction>action).adressat).pipe(
				map(adressatenList => new LoadAdressatenSuccessAction(getUrl(aufgabe), adressatenList)),
			);
		}),
	));


	deleteAdressat = createEffect(() => this.actions.pipe(
		ofType(AdressatActions.DELETE_ADRESSAT),
		withLatestFrom(this.aufgabeFacade.aufgabe),
		switchMap(([action, aufgabe]) => {
			return this.adressatService.deleteAdressat((<DeleteAdressatAction>action).adressat).pipe(
				mergeMap(adressatList => {
					if (hasEmbeddedResource(adressatList, AdressatListLinkRel.ADRESSAT_LIST)) {
						return [new LoadAdressatenSuccessAction(getUrl(aufgabe), adressatList)];
					} else {
						return [new ClearAdressatenByAufgabeAction(getUrl(aufgabe)),
							new UpdateAufgabeAction(aufgabe)];
					}
				}),
			);
		}),
	));
}
