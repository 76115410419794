import {createAction, props} from '@ngrx/store';
import {ResourceUri} from '@ngxp/rest';
import {TextbausteinListResource, TextbausteinResource} from './textbaustein.model';
import {ChangeEntryListResource} from '@schir-int-client/register-change';

export enum TextbausteinAction {
	LOAD_TEXTBAUSTEINE = 'LOAD_TEXTBAUSTEINE',
	LOAD_TEXTBAUSTEINE_SUCCESS = 'LOAD_TEXTBAUSTEINE_SUCCESS',
	UPDATE_TEXTBAUSTEIN = 'UPDATE_TEXTBAUSTEIN',
	UPDATE_TEXTBAUSTEIN_SUCCESS = 'UPDATE_TEXTBAUSTEIN_SUCCESS'
}

export const loadTextbausteine = createAction(
	TextbausteinAction.LOAD_TEXTBAUSTEINE,
	props<{ resources: ChangeEntryListResource }>(),
);

export const loadTextbausteineSuccess = createAction(
	TextbausteinAction.LOAD_TEXTBAUSTEINE_SUCCESS,
	props<{ payload: TextbausteinListResource }>(),
);

export const updateTextbaustein = createAction(
	TextbausteinAction.UPDATE_TEXTBAUSTEIN,
	props<{ textbausteinId: ResourceUri, editedText: string }>(),
);

export const updateTextbausteinSuccess = createAction(
	TextbausteinAction.UPDATE_TEXTBAUSTEIN_SUCCESS,
	props<{ textbaustein: TextbausteinResource }>(),
);
