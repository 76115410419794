import {Component, OnDestroy, OnInit} from '@angular/core';
import {AccessibilityService, BaseEditorComponent, DialogService, Selectable} from '@schir-int-client/tech';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {
	PersonInProfile,
	UserFacade,
	UserProfile,
	UserProfileFacade,
	UserProfileResource,
} from '@schir-int-client/user-shared';
import {Subscription} from 'rxjs';

@Component({
	selector: 'schir-int-client-user-settings-dialog',
	templateUrl: './user-settings-dialog.component.html',
	styleUrls: ['./user-settings-dialog.component.scss'],
})
export class UserSettingsDialogComponent extends BaseEditorComponent implements OnInit, OnDestroy {

	form: UntypedFormGroup = new UntypedFormGroup({
		anwender: new UntypedFormGroup({
			anrede: new UntypedFormControl(null),
			name: new UntypedFormControl(null),
			amtsbezeichnung: new UntypedFormControl(null),
			dienstbezeichnung: new UntypedFormControl(null),
			telefon: new UntypedFormControl(null),
		}),
		rechtspfleger: new UntypedFormGroup({
			anrede: new UntypedFormControl(null),
			name: new UntypedFormControl(null),
			amtsbezeichnung: new UntypedFormControl(null),
			dienstbezeichnung: new UntypedFormControl(null),
			telefon: new UntypedFormControl(null),
		}),
		serviceEinheitTelefon: new UntypedFormControl(null),
	});

	userProfile: UserProfileResource;

	private subscriptions: Subscription[] = [];

	anredeValues: Selectable[] = [{ label: 'Herr', value: 'Herr' }, { label: 'Frau', value: 'Frau' }];

	constructor(public dialogService: DialogService,
	            public accessibilityService: AccessibilityService,
	            public facade: UserProfileFacade,
	            public userFacade: UserFacade) {
		super();
	}

	getFormControl(control: string) {
		return <UntypedFormControl>this.form.controls[control];
	}

	getSubFormControl(group: string, element: string) {
		return <UntypedFormControl>(<UntypedFormGroup>this.form.controls[group]).controls[element];
	}

	ngOnInit(): void {
		this.subscriptions.push(this.userFacade.getCurrentUser().subscribe(
			userResource => {
				this.subscriptions.push(this.facade.getUserProfile(userResource)
					.subscribe(userProfileResource => {
						this.setPerson('anwender', userProfileResource.anwender);
						this.setPerson('rechtspfleger', userProfileResource.rechtspfleger);
						this.getFormControl('serviceEinheitTelefon').setValue(userProfileResource.serviceEinheitTelefon);
						this.userProfile = userProfileResource;
					}));
			},
		));
	}

	private setPerson(group: string, person: PersonInProfile) {
		this.getSubFormControl(group, 'anrede').setValue(person.anrede);
		this.getSubFormControl(group, 'name').setValue(person.name);
		this.getSubFormControl(group, 'amtsbezeichnung').setValue(person.amtsbezeichnung);
		this.getSubFormControl(group, 'dienstbezeichnung').setValue(person.dienstbezeichnung);
		this.getSubFormControl(group, 'telefon').setValue(person.telefon);
	}

	async submit(): Promise<boolean> {
		const newUserProfile: UserProfile = this.form.value;
		this.facade.updateUserProfile(newUserProfile);

		return true;
	}

	ngOnDestroy(): void {
		this.subscriptions.forEach(s => s.unsubscribe());
	}

}
