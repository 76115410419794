<div class="row" data-test-id="amtlichen-ausdruck-erstellen-entry" [formGroup]="form">
	<div class="column">
		<mat-form-field appearance="outline">
			<mat-label>Eintrag Abt. 1 rück Das Flaggenrecht betreffende Eintragungen</mat-label>
			<textarea matInput readonly cdkTextareaAutosize data-test-id="original-text"
				[formControlName]="formServiceClass.feldOriginalText">
			</textarea>
		</mat-form-field>
		<div>
			<button *ngIf="hasTranslation(form)" mat-flat-button color="primary" type="button" class="translate-button" data-test-id="translate-button"
				(click)="fillTextareaWithText(form)">
				Übersetzen
			</button>
		</div>
		<mat-form-field appearance="outline">
			<mat-label>Eintrag zum Flaggenrecht</mat-label>
			<textarea matInput  data-test-id="alternativer-text" cdkTextareaAutosize
				[formControlName]="formServiceClass.feldAlternativeText">
			</textarea>
		</mat-form-field>
	</div>
	<mat-checkbox matInput color="primary" data-test-id="original-text-checkbox"
		[formControlName]="formServiceClass.feldContainOriginalCheckbox">
	</mat-checkbox>
</div>
