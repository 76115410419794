import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {applyDefaultErrorHandling} from '@schir-int-client/ngrx-helpers';
import {catchError, map, switchMap} from 'rxjs/operators';
import {ActionType, ApiRootLoadedAction} from './api-root.actions';
import {ApiRootService} from './api-root.service';

@Injectable()
export class ApiRootEffects {

	constructor(
		private actions: Actions,
		private apiRootService: ApiRootService,
	) { }


	loadApiRoot = createEffect(() => this.actions.pipe(
		ofType(ActionType.LoadApiRoot),
		switchMap(() => {
			return this.apiRootService.load().pipe(
				map(apiRootResource => new ApiRootLoadedAction(apiRootResource)),
				catchError(applyDefaultErrorHandling));
		}),
	));
}
