import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatLegacyButtonModule as MatButtonModule} from '@angular/material/legacy-button';
import {MatLegacyOptionModule as MatOptionModule} from '@angular/material/legacy-core';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatLegacyDialogModule as MatDialogModule} from '@angular/material/legacy-dialog';
import {MatLegacyFormFieldModule as MatFormFieldModule} from '@angular/material/legacy-form-field';
import {MatLegacyInputModule as MatInputModule} from '@angular/material/legacy-input';
import {MatLegacyProgressSpinnerModule as MatProgressSpinnerModule} from '@angular/material/legacy-progress-spinner';
import {MatLegacySelectModule as MatSelectModule} from '@angular/material/legacy-select';
import {MatLegacyTableModule as MatTableModule} from '@angular/material/legacy-table';
import {RouterModule, Routes} from '@angular/router';
import {AdressatenInAufgabeContainerComponent, AdressverwaltungModule} from '@schir-int-client/adressverwaltung';
import {TechModule} from '@schir-int-client/tech';
import {VerfuegungFacade} from '@schir-int-client/verfuegung-shared';
import {VorgangFacade, VorgangSharedModule} from '@schir-int-client/vorgang-shared';
import {WiedervorlageModule} from '@schir-int-client/wiedervorlage';
import {AufgabenInVerfuegungContainerComponent} from '@schir-int-client/aufgabe';
import {VerfuegungenInVorgangContainerComponent} from '@schir-int-client/verfuegung';
import {CreateVorgangButtonComponent} from './create-vorgang-button/create-vorgang-button.component';
import {CreateVorgangDialogComponent} from './create-vorgang-button/create-vorgang-dialog/create-vorgang-dialog.component';
import {VorgaengeInVerfahrenContainerComponent} from './vorgaenge-in-verfahren-container/vorgaenge-in-verfahren-container.component';
import {ChangeVorgangStatusDialogComponent} from './vorgaenge-in-verfahren/change-vorgang-status-dialog/change-vorgang-status-dialog.component';
import {VorgaengeInVerfahrenComponent} from './vorgaenge-in-verfahren/vorgaenge-in-verfahren.component';
import {PosteingangSelectorComponent} from './vorgaenge-in-verfahren/vorgang-in-verfahren/posteingang-selector/posteingang-selector.component';
import {VorgangInVerfahrenComponent} from './vorgaenge-in-verfahren/vorgang-in-verfahren/vorgang-in-verfahren.component';
import {VorgaengeZumVerfahrenImPosteingangComponent} from './vorgaenge-zum-verfahren-im-posteingang/vorgaenge-zum-verfahren-im-posteingang.component';
import {AssignVorgangDialogComponent} from './zugeordnete-vorgaenge/assign-vorgang-dialog/assign-vorgang-dialog.component';
import {ZugeordneteVorgaengeComponent} from './zugeordnete-vorgaenge/zugeordnete-vorgaenge.component';
import {MatLegacyMenuModule as MatMenuModule} from '@angular/material/legacy-menu';
import {MatIconModule} from '@angular/material/icon';
import {ButtonWithMenuComponent} from './vorgaenge-in-verfahren/button-with-menu/button-with-menu.component';
import {MatLegacyTooltipModule as MatTooltipModule} from '@angular/material/legacy-tooltip';
import {MatLegacyRadioModule as MatRadioModule} from '@angular/material/legacy-radio';
import {AuthGuard} from '../../../guards/src';
import {AktenzeichenModule} from '@schir-int-client/aktenzeichen';
import {StandortAkteComponent} from './vorgaenge-in-verfahren/standort-akte/standort-akte.component';
import {ZaehlblattErfassenComponent} from './vorgaenge-in-verfahren/zaehlblatt/zaehlblatt-erfassen.component';
import {ZaehlblattAnzeigenComponent} from './vorgaenge-in-verfahren/zaehlblatt/zaehlblatt-anzeigen.component';
import {KennzeichenComponent} from './kennzeichen/kennzeichen.component';

const routes: Routes = [
	{
		path: 'verfahren/:verfahrenId',
		component: VorgaengeInVerfahrenContainerComponent,
		canActivate: [AuthGuard],
		canActivateChild: [AuthGuard],
		children: [
			{
				path: ':vorgangId',
				component: VerfuegungenInVorgangContainerComponent,
				outlet: 'vorgang',
			},
			{
				path: ':verfuegungId',
				component: AufgabenInVerfuegungContainerComponent,
				outlet: 'verfuegung',
			},
			{
				path: ':aufgabeId',
				component: AdressatenInAufgabeContainerComponent,
				outlet: 'aufgabe',
			},
		],
	},
];

@NgModule({
	imports: [
		CommonModule,
		VorgangSharedModule,
		MatTableModule,
		MatButtonModule,
		MatOptionModule,
		MatSelectModule,
		MatDialogModule,
		MatIconModule,
		MatMenuModule,
		MatTooltipModule,
		TechModule,
		MatDatepickerModule,
		ReactiveFormsModule,
		MatFormFieldModule,
		MatInputModule,
		MatProgressSpinnerModule,
		RouterModule.forChild(routes),
		WiedervorlageModule,
		AdressverwaltungModule,
		MatRadioModule,
		FormsModule,
		AktenzeichenModule,
	],
	declarations: [
		ZugeordneteVorgaengeComponent,
		VorgaengeInVerfahrenComponent,
		VorgangInVerfahrenComponent,
		CreateVorgangDialogComponent,
		VorgaengeZumVerfahrenImPosteingangComponent,
		AssignVorgangDialogComponent,
		VorgaengeInVerfahrenContainerComponent,
		PosteingangSelectorComponent,
		ChangeVorgangStatusDialogComponent,
		CreateVorgangButtonComponent,
		ButtonWithMenuComponent,
		StandortAkteComponent,
		KennzeichenComponent,
		ZaehlblattErfassenComponent,
		ZaehlblattAnzeigenComponent,
	],
	exports: [
		ZugeordneteVorgaengeComponent,
		VorgaengeInVerfahrenComponent,
		VorgangInVerfahrenComponent,
		VorgaengeZumVerfahrenImPosteingangComponent,
		CreateVorgangButtonComponent,
	],
	providers: [
		VorgangFacade,
		VerfuegungFacade,
	],
})
export class VorgangModule {}
