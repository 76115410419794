import {Action} from '@ngrx/store';
import {UserProfileResource} from './userprofile.model';
import {LoadUserProfileSuccessAction, UpdateUserProfileSuccessAction, UserProfileActions} from './userprofile.actions';

export interface UserProfileState {
	userProfile: UserProfileResource;
}

export const initialState: UserProfileState = {
	userProfile: undefined,
};

export function userProfileReducer(state: UserProfileState = initialState, action: Action): UserProfileState {
	switch (action.type) {
		case UserProfileActions.LOAD_USER_PROFILE_SUCCESS:
			return {
				...state,
				userProfile: (<LoadUserProfileSuccessAction>action).userProfile,
			};
		case UserProfileActions.UPDATE_USER_PROFILE_SUCCESS:
			return {
				...state,
				userProfile: (<UpdateUserProfileSuccessAction>action).userProfile,
			};
		default:
			return state;
	}
}
