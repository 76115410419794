import {Actions} from '@ngrx/effects';
import {ElementRef} from '@angular/core';
import {Action} from '@ngrx/store';
import {Subscription} from 'rxjs';

export class Spinner {

	actionSubsription: Subscription;

	private cssClassNameToShowSpinner = 'c-spinner--visible';

	constructor(actions: Actions, showAction: string, hideAction: string, private spinnerRef: ElementRef) {
		this.actionSubsription = actions.subscribe((action: Action) => {
			if (action.type == showAction) {
				this.showSpinner();
			}
			if (action.type == hideAction) {
				this.hideSpinner();
			}
		});
	}

	destroy() {
		this.actionSubsription.unsubscribe();
	}

	private showSpinner() {
		this.spinnerRef.nativeElement.classList.add(this.cssClassNameToShowSpinner);
	}

	private hideSpinner() {
		this.spinnerRef.nativeElement.classList.remove(this.cssClassNameToShowSpinner);
	}
}
