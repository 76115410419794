import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {map, switchMap, tap} from 'rxjs/operators';
import {
	DownloadKorrespondenzAction,
	DownloadKorrespondenzenArchivAction,
	DownloadKorrespondenzenArchivSuccessAction,
	DownloadKorrespondenzenMergeAction,
	DownloadKorrespondenzenMergeSuccessAction,
	DownloadKorrespondenzSuccessAction,
	KorrespondenzActions,
} from './korrespondenz.actions';
import {KorrespondenzService} from './korrespondenz.service';

@Injectable()
export class KorrespondenzEffects {
	constructor(
		private actions: Actions,
		private korrespondenzService: KorrespondenzService,
	) { }


	downloadKorrespondenz = createEffect(() => this.actions.pipe(
		ofType(KorrespondenzActions.DOWNLOAD_KORRESPONDENZ),
		switchMap(action => this.korrespondenzService.downloadKorrespondenz((<DownloadKorrespondenzAction>action).adressatResource).pipe(
			map(data => new DownloadKorrespondenzSuccessAction(data)),
		)),
	));


	saveKorrespondenz = createEffect(() => this.actions.pipe(
		ofType(KorrespondenzActions.DOWNLOAD_KORRESPONDENZ_SUCCESS),
		tap(action => this.korrespondenzService.saveFile((<DownloadKorrespondenzSuccessAction>action).data, 'korrespondenz.doc')),
	), { dispatch: false });


	downloadKorrespondenzenArchiv = createEffect(() => this.actions.pipe(
		ofType(KorrespondenzActions.DOWNLOAD_KORRESPONDENZEN_ARCHIV),
		switchMap(action => this.korrespondenzService.downloadKorrespondenzenArchiv((<DownloadKorrespondenzenArchivAction>action).aufgabeResource).pipe(
			map(data => new DownloadKorrespondenzenArchivSuccessAction(data)),
		)),
	));


	saveKorrespondenzenArchiv = createEffect(() => this.actions.pipe(
		ofType(KorrespondenzActions.DOWNLOAD_KORRESPONDENZEN_ARCHIV_SUCCESS),
		tap(action => {
			let data = (<DownloadKorrespondenzenArchivSuccessAction>action).data;
			this.korrespondenzService.saveFile(data.blob, data.name ? data.name : 'korrespondenzen.zip');
		}),
	), { dispatch: false });


	downloadKorrespondenzenMerge = createEffect(() => this.actions.pipe(
		ofType(KorrespondenzActions.DOWNLOAD_KORRESPONDENZEN_MERGE),
		switchMap(action => this.korrespondenzService.downloadKorrespondenzenMerge((<DownloadKorrespondenzenMergeAction>action).aufgabeResource).pipe(
			map(data => new DownloadKorrespondenzenMergeSuccessAction(data)),
		)),
	));


	saveKorrespondenzenMerge = createEffect(() => this.actions.pipe(
		ofType(KorrespondenzActions.DOWNLOAD_KORRESPONDENZEN_MERGE_SUCCESS),
		tap(action => {
			let data = (<DownloadKorrespondenzenMergeSuccessAction>action).data;
			this.korrespondenzService.saveFile(data.blob, data.name ? data.name : 'korrespondenzen.docx');
		}),
	), { dispatch: false });
}
