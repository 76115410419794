import {ROUTER_NAVIGATED, RouterNavigationAction} from '@ngrx/router-store';
import {Action} from '@ngrx/store';
import {getEmbeddedResource, getUrl, ResourceUri} from '@ngxp/rest';
import {createEmptyStateResource, createStateResource, StateResource} from '@schir-int-client/ngrx-helpers';
import {appendVorgaengeForRueckLauf, replaceResourceInMap} from '@schir-int-client/tech';
import {LoadVerfahrenSingleSuccessAction, VerfahrenActions} from '@schir-int-client/verfahren-shared';
import {isNil} from 'lodash-es';
import {
	CreateVorgangWithPosteingangInVerfahrenSuccessAction,
	LoadVorgaengeByVerfahrenSuccessAction,
	LoadVorgangByLinkAction,
	SelectVorgangAction,
	SelectVorgangByUriAction,
	UpdateVorgangNotizSuccessAction,
	VorgaengeInRuecklaufLoadedAction,
	VorgaengeRechtspflegeLoadedAction,
	VorgangActions,
	VorgangLoadedAction,
	VorgangMarkedSuccessAction,
	ZugeordneteVorgaengeLoadedAction,
} from './vorgang.actions';
import {VorgangListLinkRel} from './vorgang.linkrel';
import {VorgangListResource, VorgangResource} from './vorgang.model';

export interface VorgangState {
	selectedVorgangUri: ResourceUri;
	selectedVorgang: StateResource<VorgangResource>;
	vorgangByUri: { [vorgangUri: string]: StateResource<VorgangResource> };
	vorgaengeByVerfahren: {
		[verfahrenUri: string]: VorgangResource[];
	};
	zugeordneteVorgaenge: VorgangListResource;
	vorgaengeRechtspflege: VorgangResource[];
	vorgaengeInRuecklauf: VorgangListResource;
}

export const initialState: VorgangState = {
	selectedVorgangUri: null,
	selectedVorgang: createEmptyStateResource(),
	vorgangByUri: {},
	vorgaengeByVerfahren: {},
	zugeordneteVorgaenge: null,
	vorgaengeRechtspflege: null,
	vorgaengeInRuecklauf: null,
};

export function vorgangReducer(state: VorgangState = initialState, action: Action): VorgangState {
	switch (action.type) {
		case VorgangActions.SELECT_VORGANG:
			return {
				...state,
				selectedVorgangUri: getUrl((<SelectVorgangAction>action).vorgang),
				selectedVorgang: createStateResource((<SelectVorgangAction>action).vorgang),
			};
		case VorgangActions.SELECT_VORGANG_BY_URI:
			return {
				...state,
				selectedVorgangUri: ((<SelectVorgangByUriAction>action).uri),
			};
		case VorgangActions.LOAD_VORGANG_BY_LINK:
			const loadAction = <LoadVorgangByLinkAction>action;
			const uri = getUrl(loadAction.resource, loadAction.linkRel);
			return {
				...state,
				vorgangByUri: {
					...state.vorgangByUri,
					[uri]: isNil(state.vorgangByUri[uri]) ? createEmptyStateResource(true) : {
						...state.vorgangByUri[uri],
						loading: true,
					},
				},
			};
		case VorgangActions.LOAD_VORGANG_SUCCESS:
			const loaded = (<VorgangLoadedAction>action).vorgang;
			return {
				...state,
				vorgangByUri: { ...state.vorgangByUri, [getUrl(loaded)]: createStateResource(loaded) },
				selectedVorgang: (!state.selectedVorgang.loaded || getUrl(loaded) === state.selectedVorgangUri) ? createStateResource(loaded) : state.selectedVorgang,
				vorgaengeByVerfahren: <{ [verfahrenUri: string]: VorgangResource[] }>replaceResourceInMap(state.vorgaengeByVerfahren, loaded),
			};
		case VorgangActions.ZUGEORDNETE_VORGAENGE_LOADED:
			return {
				...state,
				zugeordneteVorgaenge: (<ZugeordneteVorgaengeLoadedAction>action).zugeordneteVorgangList,
			};
		case VorgangActions.CREATE_VORGANG_WITH_POSTEINGANG_IN_VERFAHREN_SUCCESS:
			return {
				...state,
				zugeordneteVorgaenge: (<CreateVorgangWithPosteingangInVerfahrenSuccessAction>action).vorgaenge,
			};
		case VorgangActions.CREATE_VORGANG_IN_VERFAHREN_SUCCESS:
			const embeddedResource: VorgangResource[] = getEmbeddedResource((<LoadVorgaengeByVerfahrenSuccessAction>action).vorgaenge, VorgangListLinkRel.VORGANG_LIST);
			return {
				...state,
				vorgaengeByVerfahren:
					{
						...state.vorgaengeByVerfahren,
						[getUrl((<LoadVorgaengeByVerfahrenSuccessAction>action).verfahren)]: embeddedResource,
					},
				selectedVorgang: createStateResource(embeddedResource[0]),

			};
		case VorgangActions.LOAD_VORGAENGE_BY_VERFAHREN_SUCCESS:
			//set first vorgang from list as selected if none exists or a new verfahren was loaded
			const successAction = <LoadVorgaengeByVerfahrenSuccessAction>action;
			if (!state.selectedVorgang.resource || successAction.verfahren.aktenzeichen !== state.selectedVorgang.resource.aktenzeichen) {
				if (successAction.vorgaenge._embedded && successAction.vorgaenge._embedded['vorgangList']) {
					const vorgangResource: VorgangResource = successAction.vorgaenge._embedded['vorgangList'][0];
					return {
						...state,
						selectedVorgangUri: vorgangResource._links.self.href,
						selectedVorgang: createStateResource(vorgangResource, false),
						vorgaengeByVerfahren:
							{
								...state.vorgaengeByVerfahren,
								[getUrl(successAction.verfahren)]: getEmbeddedResource(successAction.vorgaenge, VorgangListLinkRel.VORGANG_LIST),
							},
					};
				}
			}
			return {
				...state,
				vorgaengeByVerfahren:
					{
						...state.vorgaengeByVerfahren,
						[getUrl(successAction.verfahren)]: getEmbeddedResource(successAction.vorgaenge, VorgangListLinkRel.VORGANG_LIST),
					},
			};
		case VorgangActions.LOAD_VORGAENGE_RCHTSPFLEGE_SUCCESS:
			return {
				...state,
				vorgaengeRechtspflege: (<VorgaengeRechtspflegeLoadedAction>action).vorgaenge,
			};
		case VorgangActions.VORGAENGE_IN_RUECKLAUF_LOADED:
			return {
				...state,
				vorgaengeInRuecklauf: (<VorgaengeInRuecklaufLoadedAction>action).vorgaenge,
			};
		case VerfahrenActions.LOAD_VERFAHREN_SINGLE_SUCCESS:
			//dont remove selection if the same verfahren was loaded
			if (state.selectedVorgang && state.selectedVorgang.resource && (<LoadVerfahrenSingleSuccessAction>action).verfahren.aktenzeichen === state.selectedVorgang.resource.aktenzeichen) {
				return state;
			}
			return {
				...state,
				selectedVorgangUri: null,
				selectedVorgang: createEmptyStateResource(),
			};
		case VorgangActions.UPDATE_VORGANG_NOTIZ_SUCCESS:
			return {
				...state,
				vorgaengeByVerfahren: <{ [verfahrenUri: string]: VorgangResource[] }>replaceResourceInMap(state.vorgaengeByVerfahren, (<UpdateVorgangNotizSuccessAction>action).vorgangResource),
				selectedVorgang: getCurrentVorgang(state, action),
			};
		case VorgangActions.MARKED_SUCCESS:
			return {
				...state,
				vorgaengeByVerfahren: <{ [verfahrenUri: string]: VorgangResource[] }>replaceResourceInMap(state.vorgaengeByVerfahren, (<VorgangMarkedSuccessAction>action).vorgang),
				selectedVorgang: getCurrentVorgang(state, action),
				vorgaengeInRuecklauf: appendVorgaengeForRueckLauf(state, action),
			};
		case ROUTER_NAVIGATED:
			const routerAction = <RouterNavigationAction>action;
			return routerAction.payload.event.url.startsWith('/verfahren') ? state : {
				...state,
				vorgangByUri: {},
			};

		default:
			return state;
	}
}

function getCurrentVorgang(state: VorgangState, action): StateResource<VorgangResource> {
	return state.selectedVorgang.loaded ? createStateResource((action).vorgangResource) : state.selectedVorgang;
}
