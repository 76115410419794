import {Component, Input} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {AufgabenTyp} from '@schir-int-client/aufgabe-shared';
import {CreateAufgabeFormService} from '../../../create-aufgabe-dialog/create-aufgabe.formservice';

@Component({
	selector: 'schir-int-client-aufgabe-lfpr-weglegen-editor',
	templateUrl: './aufgabe-lfpr-weglegen-editor.component.html',
	styleUrls: ['./aufgabe-lfpr-weglegen-editor.component.scss'],
})
export class AufgabeLfprWeglegenEditorComponent {
	@Input() aufgabenTyp: AufgabenTyp;
	readonly formServiceClass = CreateAufgabeFormService;

	constructor(private formService: CreateAufgabeFormService) {}

	get formGroup(): UntypedFormGroup {
		return <UntypedFormGroup>this.formService.form.controls[this.aufgabenTyp];
	}

	get bodyGroup(): UntypedFormGroup {
		return <UntypedFormGroup>this.formGroup.controls[CreateAufgabeFormService.feldAufgabeBody];
	}

	get aufbewahrenBisFieldControl(): UntypedFormControl {
		return <UntypedFormControl>this.bodyGroup.controls[CreateAufgabeFormService.feldLfprAufbewahrenBis];
	}
}

