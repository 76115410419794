import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {AppNotificationService} from './app-notification.service';
import {ApiError} from './tech.model';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {

	readonly errorMessage: string = 'Der Server hat mit einem Fehler geantwortet:\n';
	readonly copyAttachment: string = '\n\nBitte kopieren sie die Details zum Fehler in eine E-Mail und senden Sie diese an Ihren technischen Support.';
	readonly forbiddenMessage: string = 'Sie besitzen nicht die notwendigen Rechte für den Zugriff auf die angeforderte Resource.';

	constructor(
		private notificationService: AppNotificationService,
	) { }

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		return next.handle(req).pipe(
			catchError((error: HttpErrorResponse, caught) => {
				if (error.status >= 500 && error.status < 600) {
					if (error.error instanceof Blob) {
						this.convertBlobResponse(error).then(response => {
							this.notificationService.handleError(this.errorMessage + error.message + this.copyAttachment, error.error.id, response);
						});
					} else {
						this.notificationService.handleError(this.errorMessage + error.message + this.copyAttachment, error.error.id, error.error);
					}
				} else if (error.status === 403) {
					this.notificationService.handleError(this.forbiddenMessage, error.error.id, error.error);
				}
				return throwError(error);
			}),
		);
	}

	private convertBlobResponse(err: HttpErrorResponse): Promise<ApiError> {
		return new Promise<any>((resolve, reject) => {
			let reader = new FileReader();
			reader.onload = (e: Event) => {
				try {
					const errmsg = JSON.parse((<any>e.target).result);
					resolve(errmsg);
				} catch (e) {
					reject(err);
				}
			};
			reader.onerror = (e) => {
				reject(err);
			};
			reader.readAsText(err.error);
		});
	}
}
