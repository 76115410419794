import {Injectable, OnDestroy} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {AufgabenTyp} from '@schir-int-client/aufgabe-shared';
import {VerfuegungFacade} from '@schir-int-client/verfuegung-shared';
import {CreateAufgabeFormService} from '@schir-int-client/aufgabe';
import {isNil} from 'lodash-es';
import {Subscription} from 'rxjs';
import {filter, take} from 'rxjs/operators';

@Injectable()
export class CreateLfprVerfuegungFormservice implements OnDestroy {

	readonly controlAufgabeRegisterakte = new UntypedFormControl();
	readonly controlSchuldtitel = new UntypedFormControl();
	readonly controlMitteilung = new UntypedFormControl();
	readonly controlStatistik = new UntypedFormControl();
	readonly controlVerweisungsvermerk = new UntypedFormControl();
	readonly controlDBAktualisieren = new UntypedFormControl();
	readonly controlBlattabschrift = new UntypedFormControl();
	readonly controlVerfuegungNotiz = new UntypedFormControl();
	readonly controlNegativbescheinigung = new UntypedFormControl();
	readonly controlKosten = new UntypedFormControl();
	readonly controlWiedervorlage = new UntypedFormControl();
	readonly controlErledigt = new UntypedFormControl();
	readonly controlWeglegen = new UntypedFormControl();
	readonly controlSammlung = new UntypedFormControl();

	form: UntypedFormGroup = new UntypedFormGroup({
		controlAufgabeRegisterakte: this.controlAufgabeRegisterakte,
		controlSchuldtitel: this.controlSchuldtitel,
		controlMitteilung: this.controlMitteilung,
		controlStatistik: this.controlStatistik,
		controlVerweisungsvermerk: this.controlVerweisungsvermerk,
		controlDBAktualisieren: this.controlDBAktualisieren,
		controlBlattabschrift: this.controlBlattabschrift,
		controlNegativbescheinigung: this.controlNegativbescheinigung,
		controlKosten: this.controlKosten,
		controlWiedervorlage: this.controlWiedervorlage,
		controlErledigt: this.controlErledigt,
		controlWeglegen: this.controlWeglegen,
		controlSammlung: this.controlSammlung,
		controlVerfuegungNotiz: this.controlVerfuegungNotiz,
	});

	private typeControlsSubscriptions: Subscription;


	constructor(private facade: VerfuegungFacade, private aufgabeFormService: CreateAufgabeFormService) {
		this.typeControlsSubscriptions = this.buildTypeControlSubscription(this.controlAufgabeRegisterakte, AufgabenTyp.LFPR_REGISTERAKTE_ANLEGEN);
		this.typeControlsSubscriptions.add(this.buildTypeControlSubscription(this.controlSchuldtitel, AufgabenTyp.LFPR_SCHULDTITEL));
		this.typeControlsSubscriptions.add(this.buildTypeControlSubscription(this.controlMitteilung, AufgabenTyp.LFPR_MITTEILUNG));
		this.typeControlsSubscriptions.add(this.buildTypeControlSubscription(this.controlStatistik, AufgabenTyp.LFPR_STATISTIK));
		this.typeControlsSubscriptions.add(this.buildTypeControlSubscription(this.controlVerweisungsvermerk, AufgabenTyp.LFPR_VERWEISUNGSVERMERK));
		this.typeControlsSubscriptions.add(this.buildTypeControlSubscription(this.controlDBAktualisieren, AufgabenTyp.LFPR_DB_AKTUALISIEREN));
		this.typeControlsSubscriptions.add(this.buildTypeControlSubscription(this.controlBlattabschrift, AufgabenTyp.LFPR_BLATTABSCHRIFT));
		this.typeControlsSubscriptions.add(this.buildTypeControlSubscription(this.controlNegativbescheinigung, AufgabenTyp.LFPR_NEGATIVBESCHEINIGUNG));
		this.typeControlsSubscriptions.add(this.buildTypeControlSubscription(this.controlKosten, AufgabenTyp.LFPR_KOSTEN));
		this.typeControlsSubscriptions.add(this.buildTypeControlSubscription(this.controlWiedervorlage, AufgabenTyp.LFPR_WIEDERVORLAGE));
		this.typeControlsSubscriptions.add(this.buildTypeControlSubscription(this.controlErledigt, AufgabenTyp.LFPR_ERLEDIGT));
		this.typeControlsSubscriptions.add(this.buildTypeControlSubscription(this.controlWeglegen, AufgabenTyp.LFPR_WEGLEGEN));
		this.typeControlsSubscriptions.add(this.buildTypeControlSubscription(this.controlSammlung, AufgabenTyp.LFPR_SAMMLUNG));
	}

	private buildTypeControlSubscription(formControl: UntypedFormControl, aufgabenTyp: AufgabenTyp): Subscription {
		return formControl.valueChanges.subscribe(selected => {
			selected ? this.aufgabeFormService.enableAufgabenTyp(aufgabenTyp) : this.aufgabeFormService.disableAufgabenTyp(aufgabenTyp);
		});
	}

	submit(): boolean {
		if (this.checkPreconditions()) {
			const creating = this.facade.createVerfuegungByVorgang(this.controlVerfuegungNotiz.value);

			if (this.isAnyAufgabeSelected()) {
				creating.pipe(
					filter(created => !created.loading),
					take(1),
				).subscribe(() => this.aufgabeFormService.submit());
			}
			return true;
		}
		return false;
	}

	checkPreconditions(): boolean {
		return !this.isAnyAufgabeSelected() || (this.isAnyAufgabeSelected() && this.aufgabeFormService.isValid());
	}

	private isAnyAufgabeSelected(): boolean {
		return this.controlAufgabeRegisterakte.value
			|| this.controlSchuldtitel.value
			|| this.controlMitteilung.value
			|| this.controlStatistik.value
			|| this.controlVerweisungsvermerk.value
			|| this.controlBlattabschrift.value
			|| this.controlNegativbescheinigung.value
			|| this.controlKosten.value
			|| this.controlWiedervorlage.value
			|| this.controlErledigt.value
			|| this.controlWeglegen;
	}

	ngOnDestroy(): void {
		if (!isNil(this.typeControlsSubscriptions)) {
			this.typeControlsSubscriptions.unsubscribe();
		}
	}
}
