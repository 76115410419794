import {LongAktenzeichenRenderer} from './long-aktenzeichen-renderer';
import {Injectable} from '@angular/core';
import {filter} from 'rxjs/operators';
import {ShortAktenzeichenRenderer} from './short-aktenzeichen-renderer';
import {AktenzeichenRenderer} from './aktenzeichen-renderer';
import {ApiRootFacade} from '@schir-int-client/api-root';
import {isNil} from 'lodash-es';
import {AktenzeichenUtilService} from './aktenzeichen-util.service';

@Injectable({
	providedIn: 'root',
})
export class AktenzeichenRendererProvider {
	private renderer: AktenzeichenRenderer = new LongAktenzeichenRenderer(this.aktenzeichenUtilService);

	constructor(private apiRootFacade: ApiRootFacade,
	            private aktenzeichenUtilService: AktenzeichenUtilService) {

		this.apiRootFacade.getCurrentApiRoot().pipe(filter(apiRoot => !isNil(apiRoot)))
			.subscribe(apiRoot => {
				this.renderer = apiRoot.shortAktenzeichen ? new ShortAktenzeichenRenderer(this.aktenzeichenUtilService) : new LongAktenzeichenRenderer(this.aktenzeichenUtilService);
			});
	}

	getRenderer(): AktenzeichenRenderer {
		return this.renderer;
	}
}
