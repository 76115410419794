import {NgModule} from '@angular/core';
import {AktenzeichenComponent} from './aktenzeichen.component';
import {CommonModule} from '@angular/common';

@NgModule({
	imports: [
		CommonModule,
	],
	declarations: [AktenzeichenComponent],
	exports: [AktenzeichenComponent],
	providers: [],
})
export class AktenzeichenModule {}
