import {
	AfterViewInit,
	ChangeDetectionStrategy,
	Component,
	ElementRef,
	OnDestroy,
	OnInit,
	ViewChild,
} from '@angular/core';
import {StateResource} from '@schir-int-client/ngrx-helpers';
import {NewVerfahren, VerfahrenFacade, VerfahrenListResource} from '@schir-int-client/verfahren-shared';
import {Observable, Subject, Subscription} from 'rxjs';
import {CreateVerfahrenDialogComponent} from '../create-verfahren-dialog/create-verfahren-dialog.component';
import {DialogService, HandlesBackdropClickAndEscapeKey} from '@schir-int-client/tech';
import {TabTitle, TitleService} from '../../../../tech/src/lib/title/title.service';
import {take} from 'rxjs/operators';

@Component({
	selector: 'schir-int-client-verfahren-main',
	templateUrl: './verfahren-main.component.html',
	styleUrls: ['./verfahren-main.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VerfahrenMainComponent extends HandlesBackdropClickAndEscapeKey<CreateVerfahrenDialogComponent> implements OnInit, AfterViewInit, OnDestroy {

	@ViewChild('verfahrenRoot', { static: false }) verfahrenRoot: ElementRef;

	verfahrenList: Observable<StateResource<VerfahrenListResource>>;

	private subscriptions: Subscription[] = [];
	audioAlert: Subject<string> = new Subject<string>();

	constructor(private facade: VerfahrenFacade,
	            public titleService: TitleService,
	            protected dialogService: DialogService) {
		super(dialogService);
	}

	ngOnInit() {
		this.verfahrenList = this.facade.verfahren;
		this.titleService.setTitle(TabTitle.VERFAHREN);
	}

	ngAfterViewInit() {
		//select create-verfahren-button if verfahrenList is empty
		this.subscriptions.push(this.verfahrenList.subscribe(verfahrenList => {
			this.audioAlert.next(''); // damit Alert auch vorgelesen wird, wenn gleichviele Verfahren gefunden
			setTimeout(() => {
				if (verfahrenList.resource && verfahrenList.resource.page.size === 0) {
					const button: HTMLElement = (<HTMLElement>this.verfahrenRoot.nativeElement).querySelector('schir-int-client-button-round button');
					if (button) {
						button.focus();
					}
				}
				if (verfahrenList.resource) {
					this.audioAlert.next('Suchergebnisse aktualisiert: ' + verfahrenList.resource.page.totalElements + ' Verfahren');
				}
			});
		}));
	}

	ngOnDestroy() {
		this.subscriptions.forEach(s => s.unsubscribe());
	}

	openCreateVerfahrenDialog() {
		this.matDialogRef = this.dialogService.openEditorDialog(this, CreateVerfahrenDialogComponent, {
			data: { hasBlattnummerInput: true },
		});

		this.subscriptions.push(this.matDialogRef.componentInstance.onCreateNewVerfahren.pipe(take(1)).subscribe((verfahren: NewVerfahren) => {
			this.facade.createVerfahren(verfahren);
		}));
	}
}
