<a mat-mini-fab color="primary" *ngIf="(vorgang | hasResourceLink: 'posteingang')" data-test-id="nav-to-posteingang" matTooltip="Zum Posteingang" aria-label="Zum Posteingang"
	routerLink="/posteingangBearbeiten/{{getPosteingangByVorgang(vorgang)}}">
	<i class="material-icons icon-small">email</i>
</a>

<button *ngIf="(vorgang | hasResourceLink: 'posteingaenge')" class="c-button c-button--round button-with-options" matTooltip="Zum Posteingang" aria-label="Zum Posteingang"
	(mouseenter)="loadPosteingaenge.emit()" data-test-id="select-posteingang">
	<i class="material-icons icon-small">email</i>

	<div class="options" *ngIf="posteingaenge">
		<div class="options__buttons">
			<schir-int-client-spinner [diameter]=50 [stateResource]="posteingaenge">
				<a *ngFor="let posteingang of posteingaenge.resource; let i = index"
					routerLink="/posteingangBearbeiten/{{getPosteingangRouterLink(posteingang)}}"
					data-test-id="posteingang-select-link">
					{{posteingang.eingangsDatum | formatDate}}
				</a>
			</schir-int-client-spinner>
		</div>
	</div>
</button>
