import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import {ResourceUri} from '@ngxp/rest';
import {doIfLoadingRequired, StateResource} from '@schir-int-client/ngrx-helpers';
import {VerfahrenFacade} from '@schir-int-client/verfahren-shared';
import {isEmpty, isNil} from 'lodash-es';
import {Observable, of} from 'rxjs';
import {filter, mergeMap} from 'rxjs/operators';
import {
	CreateAlternativeTextEntryAction,
	DeleteAlternativeTextEntryAction,
	LoadAlternativeTexteAction,
	UpdateAlternativeTextEntryAction,
} from './alternative-text.actions';
import {
	AlternativeText,
	AlternativeTextResource,
	CreateAlternativeText,
	FlaggenrechtWithAlternativeText,
} from './alternative-text.model';
import {AlternativeTextState} from './alternative-text.reducer';
import {
	alternativeByUriSelector,
	alternativeTextSelectors,
	isAlternativeTexteSavedSelector,
} from './alternative-text.selectors';
import {FlaggenrechtChangeEntryResource} from '@schir-int-client/register-change';

@Injectable({ providedIn: 'root' })
export class AlternativeTextFacade {

	constructor(
		private store: Store<AlternativeTextState>,
		private verfahrenFacade: VerfahrenFacade,
	) { }

	getAlternativeTexte(): Observable<StateResource<AlternativeTextResource[]>> {
		return this.store.select(alternativeTextSelectors).pipe(
			filter(alternativeTexte => !doIfLoadingRequired(alternativeTexte, () => this.store.dispatch(new LoadAlternativeTexteAction()))),
		);
	};

	// Careful: Sideeffects only take effect if observable is subcribed.
	erstellen(entries: FlaggenrechtWithAlternativeText[]): Observable<StateResource<boolean>> {
		entries.forEach(entry => isNil(entry.alternativeTextResource) ? this.proceedWithNewEntry(entry) : this.proceedWithExistingEntry(entry));

		return this.isAlternativeTextSaved().pipe(
			mergeMap(saveState => saveState.resource ? this.downloadAmtlicherAuszug() : of(saveState)),
		);
	}

	private downloadAmtlicherAuszug(): Observable<StateResource<boolean>> {
		return this.verfahrenFacade.downloadAmtlicherAuszug();
	}

	private proceedWithNewEntry(entry: FlaggenrechtWithAlternativeText): void {
		if (!isEmpty(entry.alternativeText) || !entry.containOriginalText) {
			const alternativeTextToCreate: CreateAlternativeText = {
				alternativeText: entry.alternativeText,
				containOriginalText: entry.containOriginalText,
			};
			this.createAlternativeTextEntry(entry.flaggenrechtResource, alternativeTextToCreate);
		}
	}

	private proceedWithExistingEntry(entry: FlaggenrechtWithAlternativeText): void {
		if (isEmpty(entry.alternativeText) && entry.containOriginalText) {
			this.deleteAlternativeTextEntry(entry.alternativeTextResource);
		} else {
			const alternativeResource: AlternativeTextResource = entry.alternativeTextResource;
			const alternativeTextToUpdate: AlternativeText = {
				alternativeText: entry.alternativeText,
				containOriginalText: entry.containOriginalText,
				spaltenEintragId: alternativeResource.spaltenEintragId,
			};
			this.updateAlternativeTextEntry(alternativeResource, alternativeTextToUpdate);
		}
	}

	createAlternativeTextEntry(flaggenrechtChangeEntryResource: FlaggenrechtChangeEntryResource, alternativeTexte: CreateAlternativeText): void {
		this.store.dispatch(new CreateAlternativeTextEntryAction(flaggenrechtChangeEntryResource, alternativeTexte));
	}

	updateAlternativeTextEntry(alternativeTextEntry: AlternativeTextResource, alternativeText: AlternativeText): void {
		this.store.dispatch(new UpdateAlternativeTextEntryAction(alternativeTextEntry, alternativeText));
	}

	deleteAlternativeTextEntry(alternativeTextEntry: AlternativeTextResource): void {
		this.store.dispatch(new DeleteAlternativeTextEntryAction(alternativeTextEntry));
	}

	getAlternativeTextByUri(uri: ResourceUri): Observable<AlternativeTextResource> {
		return this.store.select(alternativeByUriSelector, { changeEntryId: uri });
	}

	isAlternativeTextSaved(): Observable<StateResource<boolean>> {
		return this.store.select(isAlternativeTexteSavedSelector);
	}
}
