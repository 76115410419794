<div class="x-section__headline">
	<h2 class="mat-h1">Statistik</h2>
</div>
<div class="x-section__subline">
	<div data-test-id="statistik-header" class="mat-body">{{today}}</div>
	<schir-int-client-button-round data-test-id="export-tages-statistik-button" (click)="exportTagesStatistik()" materialIcon="description"
								   toolTip="Tages-Statistik exportieren" cssClass="focus-box-shadow-dark smaller-button">
	</schir-int-client-button-round>
	<schir-int-client-button-round data-test-id="open-export-monats-statistik-button" (click)="exportMonatsStatistik()" materialIcon="event"
								   toolTip="Monats-Statistik exportieren" popup="dialog" cssClass="focus-box-shadow-dark smaller-button">
	</schir-int-client-button-round>
</div>
<table data-test-id="statistik-table" mat-table [dataSource]="dataSource" aria-label="Anzahl Vorgänge nach Status und Register">

	<ng-container matColumnDef="Status">
		<th mat-header-cell *matHeaderCellDef> Status </th>
		<td mat-cell *matCellDef="let element" class="col-center"> {{element.status | toUmlauts}} </td>
	</ng-container>

	<ng-container matColumnDef="SSR">
		<th mat-header-cell *matHeaderCellDef> SSR </th>
		<td mat-cell *matCellDef="let element"> {{element.ssr}} </td>
	</ng-container>

	<ng-container matColumnDef="BSR">
		<th mat-header-cell *matHeaderCellDef> BSR </th>
		<td mat-cell *matCellDef="let element"> {{element.bsr}} </td>
	</ng-container>

	<ng-container matColumnDef="SBR">
		<th mat-header-cell *matHeaderCellDef> SBR </th>
		<td mat-cell *matCellDef="let element"> {{element.sbr}} </td>
	</ng-container>

	<ng-container matColumnDef="AR">
		<th mat-header-cell *matHeaderCellDef> AR </th>
		<td mat-cell *matCellDef="let element"> {{element.ar}} </td>
	</ng-container>

	<ng-container matColumnDef="LR">
		<th mat-header-cell *matHeaderCellDef> LR </th>
		<td mat-cell *matCellDef="let element"> {{element.lr}} </td>
	</ng-container>

	<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
	<tr mat-row *matRowDef="let row; columns: displayedColumns;" class="helper"></tr>
</table>
