import {createFeatureSelector, createSelector, MemoizedSelector} from '@ngrx/store';
import {DefaultProjectorFn} from '@ngrx/store/src/selector';
import {getEmbeddedResource} from '@ngxp/rest';
import {StateResource} from '@schir-int-client/ngrx-helpers';
import {sameUri} from '@schir-int-client/tech';
import {isNil, isNull, isUndefined} from 'lodash-es';
import {VerfahrenListLinkRel} from './verfahren.linkrel';
import {VerfahrenResource} from './verfahren.model';
import {verfahrenFeatureState, VerfahrenRootState} from './verfahren.state';

export const selectFeature = createFeatureSelector<VerfahrenRootState>(verfahrenFeatureState);
const EMPTY_ARRAY = <VerfahrenResource[]>[];


export const verfahrenSelector = createSelector(selectFeature, (state: VerfahrenRootState) => {
	return state.verfahrenRoot.verfahren;
});

export const verfahrenListAsArraySelector: MemoizedSelector<object, StateResource<VerfahrenResource[]>> = createSelector(selectFeature, (state: VerfahrenRootState) => {
	return {
		...state.verfahrenRoot.verfahren,
		resource: isNull(state.verfahrenRoot.verfahren.resource) ? EMPTY_ARRAY : getEmbeddedResource<VerfahrenResource[]>(state.verfahrenRoot.verfahren.resource, VerfahrenListLinkRel.VERFAHREN_LIST),
	};
});

export const verfahrenSingleSelector =
	createSelector(selectFeature, (state: VerfahrenRootState) => {
		if (isNil(state?.verfahrenRoot)) {
			return null;
		}

		const detailedVerfahren = state.verfahrenRoot.verfahrenSingle;
		return isUndefined(detailedVerfahren) ? null : detailedVerfahren;
	});

export const zaehlblattSelector =
	createSelector(selectFeature, (state: VerfahrenRootState) => {
		if (isNil(state?.verfahrenRoot)) {
			return null;
		}

		const zaehlblatt = state.verfahrenRoot.zaehlblatt;
		return isUndefined(zaehlblatt) ? null : zaehlblatt;
	});

export const blattNummerByVerfahrenSelector =
	createSelector(selectFeature, (state: VerfahrenRootState) => {
		if (!isNil(state?.verfahrenRoot.verfahrenSingle)) {
			const register = state.verfahrenRoot.verfahrenSingle.blattNummer;
			return isUndefined(register) ? null : register;
		}
		return null;
	});

export const selectedVerfahrenSelector =
	createSelector(selectFeature, (state: VerfahrenRootState) => {
		if (!isNil(state?.verfahrenRoot)) {
			const selectedVerfahren = state.verfahrenRoot.selectedVerfahren;
			return isUndefined(selectedVerfahren) ? null : selectedVerfahren;
		}
	});

export const verfahrenSearchBySelector =
	createSelector(selectFeature, (state: VerfahrenRootState) => {
		if (!isNil(state?.verfahrenRoot)) {
			const verfahrenSearchBy = state.verfahrenRoot.searchQuery;
			return isUndefined(verfahrenSearchBy) ? null : verfahrenSearchBy;
		}
	});

export const isVerfahrenSelectedSelector =
	createSelector(selectFeature, (state: VerfahrenRootState, props) => {
		return sameUri(state.verfahrenRoot.selectedVerfahren, props.verfahren);
	});


export const isAmtlicherAuszugDoneSelector: MemoizedSelector<object, StateResource<boolean>, DefaultProjectorFn<StateResource<boolean>>> =
	createSelector(selectFeature, (state: VerfahrenRootState) => state.verfahrenRoot.downloadAmtlicherAuszugDone);
