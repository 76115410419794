export enum AufgabeLinkRel {
	CREATE_ADRESSATEN = 'createAdressaten',
	ADRESSATEN = 'adressaten',
	DELETE = 'delete',
	EDIT = 'edit',
	MARK_AS_ERLEDIGT = 'markAsErledigt',
	MARK_AS_FREIGEGEBEN = 'markAsFreigegeben',
	VORLAGE = 'vorlage',
	KORRESPONDENZ_VORLAGE = 'korrespondenzVorlage',
	CREATE_KORRESPONDENZ_VORLAGE = 'createKorrespondenzVorlage',
	DOWNLOAD_KORRESPONDENZEN_ARCHIV = 'downloadKorrespondenzenArchiv',
	DOWNLOAD_KORRESPONDENZEN_MERGE = 'downloadKorrespondenzenMerge',
}

export enum AufgabeListLinkRel {
	AUFGABE_LIST = 'aufgabeList'
}
