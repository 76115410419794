import {NgModule} from '@angular/core';
import {ErrorPageComponent} from './error-page/error-page.component';
import {CommonModule} from '@angular/common';

@NgModule({
	imports: [
		CommonModule,
	],
	exports: [
		ErrorPageComponent,
	],
	declarations: [ErrorPageComponent],
})
export class ErrorModule {}
