import {Component, Input} from '@angular/core';
import {KontaktKategorieLabel} from '@schir-int-client/adressverwaltung-shared';
import {
	AufgabeResource,
	AufgabeVersandTaetigkeitLabel,
	AufgabeZertifikatBody,
	AufgabeZertifikatTaetigkeitLabel,
} from '@schir-int-client/aufgabe-shared';
import {EnumToLabelPipe} from '@schir-int-client/tech';
import {join} from 'lodash-es';

@Component({
	selector: 'schir-int-client-aufgabe-zertifikat-body',
	templateUrl: './aufgabe-zertifikat-body.component.html',
	styleUrls: ['./aufgabe-zertifikat-body.component.scss'],
})
export class AufgabeZertifikatBodyComponent {

	@Input() aufgabe: AufgabeResource;

	readonly zertifikatTaetigkeitLabel = AufgabeZertifikatTaetigkeitLabel;

	constructor(private enumToLabelPipe: EnumToLabelPipe) { }

	get body(): AufgabeZertifikatBody {
		return <AufgabeZertifikatBody>this.aufgabe.body;
	}

	get versandTaetigkeit(): string {
		const versandTaetigkeit = this.body.versandTaetigkeit ? this.enumToLabelPipe.transform(this.body.versandTaetigkeit, AufgabeVersandTaetigkeitLabel) : 'senden an';
		const empfaenger = this.body.sendenAn.map(kategorie => KontaktKategorieLabel[kategorie]).join(', ');

		return join(['und', versandTaetigkeit, empfaenger], ' ');
	}
}
