import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {EffectsModule} from '@ngrx/effects';
import {KorrespondenzVorlageEffects} from './korrespondenz-vorlage/korrespondenz-vorlage.effects';
import {KorrespondenzVorlageService} from './korrespondenz-vorlage/korrespondenz-vorlage.service';
import {KorrespondenzEffects} from './korrespondenz/korrespondenz.effects';
import {KorrespondenzService} from './korrespondenz/korrespondenz.service';

@NgModule({
	imports: [
		CommonModule,
		EffectsModule.forFeature([
			KorrespondenzVorlageEffects,
			KorrespondenzEffects,
		]),
	],
	providers: [
		KorrespondenzVorlageService,
		KorrespondenzService,
	],
})
export class KorrespondenzSharedModule {}
