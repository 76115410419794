import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import {isNil} from 'lodash-es';
import {combineLatest, Observable} from 'rxjs';
import {filter, map} from 'rxjs/operators';
import {LoadingGuard} from '@schir-int-client/ngrx-helpers';
import {
	AddZaehlblattAktionenAction,
	DeleteZaehlblattAktionAction,
	LoadZaehlblattAction,
	VerfahrenLinkRel,
	VerfahrenResource,
	VerfahrenRootState,
	verfahrenSingleSelector,
	ZaehlblattAktionen,
	ZaehlblattEntry,
	ZaehlblattResource,
	zaehlblattSelector,
} from '@schir-int-client/verfahren-shared';

@Injectable({ providedIn: 'root' })
export class ZaehlblattFacade {
	zaehlblatt: Observable<ZaehlblattResource> = this.getZaehlblatt();

	private lastLoadedZaehlblatt: ZaehlblattResource;

	private loading = new LoadingGuard(this.store);

	constructor(private store: Store<VerfahrenRootState>) {
		this.zaehlblatt.subscribe(zaehlblatt => this.lastLoadedZaehlblatt = zaehlblatt);
	}

	private getZaehlblatt(): Observable<ZaehlblattResource> {
		return combineLatest([this.store.select(verfahrenSingleSelector), this.store.select(zaehlblattSelector)]).pipe(
			filter(([verfahren]) => !isNil(verfahren?._links[VerfahrenLinkRel.ZAEHLBLATT])),
			filter(([verfahren, zaehlblatt]) =>
				!this.loading.mustLoadFirst(zaehlblatt, () => new LoadZaehlblattAction(verfahren), verfahren._links[VerfahrenLinkRel.ZAEHLBLATT].href),
			),
			map(([, zaehlblatt]) => zaehlblatt.resource));
	}

	addZaehlblattAktionen(values: ZaehlblattAktionen, verfahren: VerfahrenResource): void {
		if (this.lastLoadedZaehlblatt?._links && this.lastLoadedZaehlblatt._links.self.href === verfahren._links[VerfahrenLinkRel.ZAEHLBLATT]?.href) {
			this.store.dispatch(new AddZaehlblattAktionenAction(values, this.lastLoadedZaehlblatt));
		}
	}

	delete(entry: ZaehlblattEntry) {
		this.store.dispatch(new DeleteZaehlblattAktionAction(entry));
	}
}
