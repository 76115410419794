import {Action} from '@ngrx/store';
import {Moment} from 'moment/moment';

export enum DatumsBereichActions {
	UPDATE_DATUMSBEREICH = 'UPDATE_DATUMSBEREICH',
	GET_DATUMSBEREICH = 'GET_DATUMSBEREICH'
}

export class UpdateDatumsBereichAction implements Action {
	type = DatumsBereichActions.UPDATE_DATUMSBEREICH;

	constructor(public von: Moment, public bis: Moment) {}
}

export class GetDatumsBereichAction implements Action {
	type = DatumsBereichActions.GET_DATUMSBEREICH;

}
