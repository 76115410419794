import {Resource} from '@ngxp/rest';

export enum PosteingangStatus {
	NEU = 'NEU', ERROR = 'ERROR', IN_BEARBEITUNG = 'IN_BEARBEITUNG',
}

export enum DokumentTyp {
	ATTACHMENT = 'ATTACHMENT',
	PDF_REPRESENTATION = 'PDF_REPRESENTATION',
	PROTOCOL = 'PROTOCOL',
	CERTIFICATE = 'CERTIFICATE',
	TRANSFER_NOTE = 'TRANSFER_NOTE',
}

export interface Posteingang {
	status: PosteingangStatus;
	vorname: string;
	nachname: string;
	firmenName: string;
	aktenzeichenVerfahren: string;
	laufendeNr: string;
	eingangsDatum: Date;
}

export interface PosteingangResource extends Posteingang, Resource {
	_embedded: {
		documents: DocumentListResource;
	};
}

export interface PosteingangListResource extends Resource {
	_embedded: {
		posteingangList: PosteingangResource;
	};
}

export interface Document {
	name: string;
	type: DokumentTyp;
}

export interface DocumentResource extends Document, Resource {}

export interface DocumentListResource extends Document, Resource {
	_embedded: {
		documentList: DocumentResource[];
	};
}
