<h2 mat-dialog-title data-test-id="dialog-title" tabindex="0" class="narrow">Statuswechsel Vorgang</h2>
<mat-dialog-content [formGroup]="form">
	<mat-radio-group aria-label="Neuen Status wählen" [formControlName]="formService.statusControlName" data-test-id="status-radiogroup">
		<mat-radio-button *ngFor="let status of getFilteredOptions()" [value]="status"
						  color="primary" class="focus-box-shadow-dark">{{ status | enumToLabel: vorgangStatusLabel }}</mat-radio-button>
	</mat-radio-group>

	<div *ngIf="selectedStatus() == vorgangStatus.WIEDERVORLAGE">
		<mat-form-field appearance="outline" class="date-picker" data-test-id="change-wiedervorlagedatum">
			<mat-label>Datum</mat-label>
			<input id="wiedervorlage-date-input" matInput [matDatepicker]="picker" [formControlName]="formService.wiedervorlageDatumControlName"
				   data-test-id="wiedervorlage-date-input" [required]="true">
			<mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
			<mat-datepicker #picker></mat-datepicker>
			<mat-error data-test-id="wiedervorlage-date-error">{{vorgangMessages.WIEDERVORLAGE_DATE_MISSING}}</mat-error>
		</mat-form-field>
		<p *ngIf="formService.showDatumsHinweis">Zu diesem Vorgang gibt es mehrere Aufgaben des Typs "Wiedervorlage"</p>
	</div>

	<mat-dialog-actions>
		<button mat-stroked-button (click)="onCancel()" data-test-id="cancel-button" class="focus-box-shadow-dark">Abbrechen</button>
		<button mat-flat-button (click)="onSave()" [disabled]="!isValid()" color="primary" class="icon-right focus-box-shadow-dark"
				data-test-id="set-status-button">Status setzen
		</button>
	</mat-dialog-actions>

</mat-dialog-content>
