import {Component, Input} from '@angular/core';
import {
	AufgabeRechnungsstellungBody,
	AufgabeRechnungsstellungErledigungLabel,
	AufgabeResource,
} from '@schir-int-client/aufgabe-shared';

@Component({
	selector: 'schir-int-client-aufgabe-rechnungsstellung-body',
	templateUrl: './aufgabe-rechnungsstellung-body.component.html',
	styleUrls: ['./aufgabe-rechnungsstellung-body.component.scss'],
})
export class AufgabeRechnungsstellungBodyComponent {

	@Input() aufgabe: AufgabeResource;

	readonly erledigungLabel = AufgabeRechnungsstellungErledigungLabel;

	constructor() { }

	get body(): AufgabeRechnungsstellungBody {
		return <AufgabeRechnungsstellungBody>this.aufgabe.body;
	}
}
