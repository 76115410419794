import {Pipe, PipeTransform} from '@angular/core';
import {isNil} from 'lodash-es';

@Pipe({ name: 'toUmlauts' })
export class ToUmlautsPipe implements PipeTransform {
	transform(value: string) {
		if (!isNil(value)) {
			return value.replace(/Oe|OE|oe|Ae|AE|ae|Ue|UE|ue|sz/g, (match) => this.mappings[match]);
		}
		return value;
	}

	mappings = {
		Oe: 'Ö',
		OE: 'Ö',
		oe: 'ö',
		Ae: 'Ä',
		AE: 'Ä',
		ae: 'ä',
		Ue: 'Ü',
		UE: 'Ü',
		ue: 'ü',
		sz: 'ß',
	};
}
