import {Action} from '@ngrx/store';
import {AdressatResource, AufgabeResource} from '@schir-int-client/aufgabe-shared';
import {DownloadedFile} from '@schir-int-client/tech';

export enum KorrespondenzActions {
	DOWNLOAD_KORRESPONDENZ = 'DOWNLOAD_KORRESPONDENZ',
	DOWNLOAD_KORRESPONDENZ_SUCCESS = 'DOWNLOAD_KORRESPONDENZ_SUCCESS',

	DOWNLOAD_KORRESPONDENZEN_ARCHIV = 'DOWNLOAD_KORRESPONDENZEN_ARCHIV',
	DOWNLOAD_KORRESPONDENZEN_ARCHIV_SUCCESS = 'DOWNLOAD_KORRESPONDENZEN_ARCHIV_SUCCESS',

	DOWNLOAD_KORRESPONDENZEN_MERGE = 'DOWNLOAD_KORRESPONDENZEN_MERGE',
	DOWNLOAD_KORRESPONDENZEN_MERGE_SUCCESS = 'DOWNLOAD_KORRESPONDENZEN_MERGE_SUCCESS'
}

export class DownloadKorrespondenzAction implements Action {
	type = KorrespondenzActions.DOWNLOAD_KORRESPONDENZ;

	constructor(public adressatResource: AdressatResource) { }
}

export class DownloadKorrespondenzSuccessAction implements Action {
	type = KorrespondenzActions.DOWNLOAD_KORRESPONDENZ_SUCCESS;

	constructor(public data: Blob) { }
}

export class DownloadKorrespondenzenArchivAction implements Action {
	type = KorrespondenzActions.DOWNLOAD_KORRESPONDENZEN_ARCHIV;

	constructor(public aufgabeResource: AufgabeResource) { }
}

export class DownloadKorrespondenzenArchivSuccessAction implements Action {
	type = KorrespondenzActions.DOWNLOAD_KORRESPONDENZEN_ARCHIV_SUCCESS;

	constructor(public data: DownloadedFile) { }
}

export class DownloadKorrespondenzenMergeAction implements Action {
	type = KorrespondenzActions.DOWNLOAD_KORRESPONDENZEN_MERGE;

	constructor(public aufgabeResource: AufgabeResource) { }
}

export class DownloadKorrespondenzenMergeSuccessAction implements Action {
	type = KorrespondenzActions.DOWNLOAD_KORRESPONDENZEN_MERGE_SUCCESS;

	constructor(public data: DownloadedFile) { }
}
