import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import {getUrl, ResourceUri} from '@ngxp/rest';
import {ChangeFileAction, FileType} from '@schir-int-client/binary-shared';
import {doIfLoadingRequired, StateResource} from '@schir-int-client/ngrx-helpers';
import {AufgabeLinkRel} from 'libs/aufgabe-shared/src/lib/aufgabe.linkrel';
import {AufgabeResource} from 'libs/aufgabe-shared/src/lib/aufgabe.model';
import {AufgabeRootState} from 'libs/aufgabe-shared/src/lib/aufgabe.state';
import {Observable} from 'rxjs';
import {filter} from 'rxjs/operators';
import {AskToDeleteKorrespondenzVorlageAction, LoadKorrespondenzVorlageAction} from './korrespondenz-vorlage.actions';
import {KorrespondenzVorlageLinkRel} from './korrespondenz-vorlage.linkrel';
import {KorrespondenzVorlageResource} from './korrespondenz-vorlage.model';
import {korrespondenzVorlageSelector} from './korrespondenz-vorlage.selectors';

@Injectable({ providedIn: 'root' })
export class KorrespondenzVorlageFacade {

	constructor(private store: Store<AufgabeRootState>) { }

	deleteKorrespondenzVorlage(korrespondenzVorlage: KorrespondenzVorlageResource) {
		this.store.dispatch(new AskToDeleteKorrespondenzVorlageAction(korrespondenzVorlage));
	}

	uploadKorrespondenzVorlage(korrespondenzVorlage: KorrespondenzVorlageResource, file: File) {
		this.store.dispatch(new ChangeFileAction(korrespondenzVorlage, KorrespondenzVorlageLinkRel.UPLOAD_FILE, FileType.KORRESPONDENZ, file));
	}

	getKorrespondenzVorlageByAufgabe(aufgabe: AufgabeResource): Observable<StateResource<KorrespondenzVorlageResource>> {
		const korrespondenzVorlageUri: ResourceUri = getUrl(aufgabe, AufgabeLinkRel.KORRESPONDENZ_VORLAGE);
		return this.store.select(korrespondenzVorlageSelector, { korrespondenzVorlageUri }).pipe(
			filter(korrespondenzVorlageStateResource => !doIfLoadingRequired(korrespondenzVorlageStateResource, () => this.store.dispatch(new LoadKorrespondenzVorlageAction(aufgabe)))),
		);
	}
}
