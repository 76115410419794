<div class="focus-border-dark">
	<li class="c-list__item" [attr.data-test-id]="'aufgabe-root '+ (aufgabe | testIdFromResource)"
		[ngClass]="{ 'c-list__item--active': (isSelected | async) === true }">
		<a class="c-list__link" data-test-id="aufgabe-button"
		   [routerLink]="['/verfahren',verfahrenUri | async, { outlets: { aufgabe: aufgabeUri } }]" (click)="setAufgabe()"
		   [elementFocus]="(isSelected | async) === true">
			<div *ngIf="(isSelected | async)" class="visually-hidden">selektierte Aufgabe</div>
			<div class="c-row c-row--header">
				<span class="c-row__item c-row__item--header" data-test-id="typ">{{ aufgabe | labelByAufgabe }}</span>
				<span class="c-row__item c-row__item--secondary"
					  data-test-id="status">{{ aufgabe.status | enumToLabel: aufgabeStatusLabel }}</span>
				<span class="visually-hidden" role="alert">{{audioAlert | async}}</span>
			</div>

			<schir-int-client-aufgabe-zertifikat-body *ngIf="hasZertifikatBody" data-test-id="aufgabe-zertifikat-body"
													  [aufgabe]="aufgabe">
			</schir-int-client-aufgabe-zertifikat-body>
			<schir-int-client-aufgabe-versand-body *ngIf="hasVersandBody" data-test-id="aufgabe-versand-body"
												   [aufgabe]="aufgabe">
			</schir-int-client-aufgabe-versand-body>
			<schir-int-client-aufgabe-abschrift-body *ngIf="isRegisterabschrift" data-test-id="aufgabe-abschrift-body"
													 [aufgabe]="aufgabe">
			</schir-int-client-aufgabe-abschrift-body>
			<schir-int-client-aufgabe-mitteilung-body *ngIf="isEintragungsMitteilung" data-test-id="aufgabe-mitteilung-body"
													  [aufgabe]="aufgabe">
			</schir-int-client-aufgabe-mitteilung-body>
			<schir-int-client-aufgabe-wiedervorlage-body *ngIf="isWiedervorlage" data-test-id="aufgabe-wiedervorlage-body"
														 [aufgabe]="aufgabe">
			</schir-int-client-aufgabe-wiedervorlage-body>
			<schir-int-client-aufgabe-rechnungsstellung-body *ngIf="isRechnungsstellung"
															 data-test-id="aufgabe-rechnungsstellung-body" [aufgabe]="aufgabe">
			</schir-int-client-aufgabe-rechnungsstellung-body>

			<div class="c-row" *ngIf="aufgabe.typ == aufgabenTyp.ALTES_ZERTIFIKAT">
				<span data-test-id="altes-zertifikat-text">{{staticTaetigkeitLabel[aufgabe.typ]}}</span>
			</div>
			<div class="c-row" *ngIf="aufgabe.typ == aufgabenTyp.ALTER_AUSZUG">
				<span data-test-id="alter-auszug-text">{{staticTaetigkeitLabel[aufgabe.typ]}}</span>
			</div>
			<div class="c-row" *ngIf="vorlage | async as _vorlage">
				<span>Vorlage: <span data-test-id="vorlage">{{_vorlage.name}}</span></span>
			</div>
			<div class="c-row c-row--secondary">
				<span class="c-row__item c-row__item--multiline" data-test-id="notiz">{{aufgabe.notiz}}</span>
			</div>
			<i *ngIf="!(isSelected | async)" class="material-icons c-list__icon" aria-hidden="true" alt="Pfeil rechts">arrow_right</i>
		</a>

		<div class="c-button-bar c-button-bar--overlay increase-z-index">
			<schir-int-client-korrespondenz-button data-test-id="korrespondenz-button" [aufgabe]="aufgabe"
												   (openKorrespondenz)="openKorrespondenzInAufgaben()">
			</schir-int-client-korrespondenz-button>

			<schir-int-client-button-round *ngIf="(aufgabe | hasResourceLink: linkRel.MARK_AS_FREIGEGEBEN)"
										   toolTip="Aufgabe freigeben" data-test-id="aufgabe-freigegeben-button"
										   materialIcon="check_box_outline_blank"
										   (click)="markAsFreigegeben()" cssClass="focus-box-shadow-dark">
			</schir-int-client-button-round>
			<schir-int-client-button-round *ngIf="(aufgabe | hasResourceLink: linkRel.MARK_AS_ERLEDIGT)"
										   toolTip="Aufgabe als erledigt markieren" data-test-id="aufgabe-erledigt-button" materialIcon="check"
										   (click)="markAsErledigt()" cssClass="focus-box-shadow-dark">
			</schir-int-client-button-round>
			<schir-int-client-button-round *ngIf="(aufgabe | hasResourceLink: linkRel.EDIT)" toolTip="Aufgabe bearbeiten"
										   popup="dialog"
										   data-test-id="aufgabe-bearbeiten-button" materialIcon="edit" (click)="editAufgabe()"
										   cssClass="focus-box-shadow-dark">
			</schir-int-client-button-round>
			<schir-int-client-button-round *ngIf="(aufgabe | hasResourceLink: linkRel.DELETE)" toolTip="Aufgabe löschen"
										   data-test-id="aufgabe-delete-button" materialIcon="delete" (click)="delete()"
										   cssClass="focus-box-shadow-dark">
			</schir-int-client-button-round>

			<ng-container *ngIf="aufgabe | hasResourceLink: linkRel.KORRESPONDENZ_VORLAGE">
				<schir-int-client-korrespondenz-vorlage-buttons
					*ngIf="korrespondenzVorlage | async as _korrespondenzVorlage"
					[korrespondenzVorlage]="korrespondenzVorlage | async"
					(upload)="uploadKorrespondenzVorlage($event, _korrespondenzVorlage.resource)"
					(delete)="deleteKorrespondezVorlage(_korrespondenzVorlage.resource)">
				</schir-int-client-korrespondenz-vorlage-buttons>
			</ng-container>
		</div>
	</li>
</div>
