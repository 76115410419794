import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {EffectsModule} from '@ngrx/effects';
import {StoreModule} from '@ngrx/store';
import {VorgangEffects} from './vorgang.effects';
import {getVorgangReducer, vorgangFeatureState, vorgangReducerInjectionToken} from './vorgang.state';

@NgModule({
	imports: [
		CommonModule,
		EffectsModule.forFeature([VorgangEffects]),
		StoreModule.forFeature(vorgangFeatureState, vorgangReducerInjectionToken),
	],
	providers: [
		{
			provide: vorgangReducerInjectionToken,
			useFactory: getVorgangReducer,
		},
	],
})
export class VorgangSharedModule {}
