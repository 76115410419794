import {Pipe, PipeTransform} from '@angular/core';
import {isNil} from 'lodash-es';

@Pipe({ name: 'enumToLabel' })
export class EnumToLabelPipe implements PipeTransform {
	transform(enumValue: any, enumLabel: any) {

		if (!isNil(enumValue) && !isNil(enumLabel)) {
			return enumLabel[enumValue];
		}
		return null;
	}
}
