import {Component, EventEmitter, Inject, OnDestroy, OnInit, Output} from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA} from '@angular/material/legacy-dialog';
import {RegisterName} from '@schir-int-client/register-shared';
import {NewVerfahren} from '@schir-int-client/verfahren-shared';
import {addAriaAttributesToMatSelect, BaseEditorComponent} from '@schir-int-client/tech';
import {ApiRootFacade} from '@schir-int-client/api-root';
import {filter} from 'rxjs/operators';
import {isNil} from 'lodash-es';
import {AbstractControl, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {AktenzeichenUtilService} from '@schir-int-client/aktenzeichen-shared';
import {Subscription} from 'rxjs';

@Component({
	selector: 'schir-int-client-create-verfahren-dialog',
	templateUrl: './create-verfahren-dialog.component.html',
	styleUrls: ['./create-verfahren-dialog.component.scss'],
})
export class CreateVerfahrenDialogComponent extends BaseEditorComponent implements OnInit, OnDestroy {

	readonly registerControlName = 'register';
	readonly blattnummerControlName = 'blattnummer';

	readonly registerName = RegisterName;

	@Output() onCreateNewVerfahren: EventEmitter<NewVerfahren> = new EventEmitter();
	hasBlattnummerInput: boolean;

	blattnummerGenerieren: boolean = false;

	registerSelectionList: RegisterName[];

	private subscription: Subscription;

	form: UntypedFormGroup = new UntypedFormGroup({
		[this.registerControlName]: new UntypedFormControl(null, Validators.required),
		[this.blattnummerControlName]: new UntypedFormControl(null, Validators.max(AktenzeichenUtilService.MAX_BLATTNUMMER)),
	});

	constructor(@Inject(MAT_DIALOG_DATA) data: any, private apiRootFacade: ApiRootFacade) {
		super();

		this.hasBlattnummerInput = data.hasBlattnummerInput;
		this.blattnummerControl.markAsTouched();

		this.subscription = apiRootFacade.getCurrentApiRoot().pipe(filter(apiRoot => !isNil(apiRoot))).subscribe(apiRoot => {
			this.registerSelectionList = apiRoot.features.registers.map(elem => (<any>RegisterName)[elem]);

			this.form.controls[this.registerControlName].patchValue(apiRoot.features.lfpr ? RegisterName.LR : RegisterName.SSR);
		});
	}

	ngOnInit(): void {
		addAriaAttributesToMatSelect();
	}

	async submit(): Promise<boolean> {
		const verfahren: NewVerfahren = {
			register: this.form.controls[this.registerControlName].value,
			blattNummer: this.blattnummer,
		};

		this.onCreateNewVerfahren.emit(verfahren);

		return true;
	}

	get blattnummer(): number {
		return this.blattnummerGenerieren ? null : this.blattnummerControl.value;
	}

	get blattnummerControl(): AbstractControl {
		return this.form.get(this.blattnummerControlName);
	}

	ngOnDestroy(): void {
		if (this.subscription) {
			this.subscription.unsubscribe();
		}
	}
}
