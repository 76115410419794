import {ROUTER_NAVIGATED, RouterNavigationAction} from '@ngrx/router-store';
import {Action} from '@ngrx/store';
import {
	createEmptyStateResource,
	createStateResource,
	getFirstVerfahrenInList,
	StateResource,
} from '@schir-int-client/ngrx-helpers';
import {sameUri} from '@schir-int-client/tech';
import {
	AdressverwaltungActions,
	AssignKontaktSuccessAction,
} from 'libs/adressverwaltung-shared/src/lib/adressverwaltung.actions';
import {
	LoadVerfahrenSingleSuccessAction,
	LoadZaehlblattSuccessAction,
	UnassignKontaktSuccessAction,
	VerfahrenActions,
	VerfahrenLoadedAction,
	VerfahrenSearchAction,
	VerfahrenSearchSuccessAction,
	VerfahrenUpdatedSuccessAction,
} from './verfahren.actions';
import {
	VerfahrenListResource,
	VerfahrenResource,
	VerfahrenSearchQuery,
	VerfahrenStatus,
	ZaehlblattResource,
} from './verfahren.model';
import {VerfahrenSearchModeLabel} from './verfahren.messages';

export interface VerfahrenState {
	verfahren: StateResource<VerfahrenListResource>;
	verfahrenSingle: VerfahrenResource;
	selectedVerfahren: VerfahrenResource; //TODO prüfen, wozu selectedVerfahren überhaupt genutzt wird neben Fokusdarstellung (ggf. unnötig)
	searchQuery: VerfahrenSearchQuery;
	downloadAmtlicherAuszugDone: StateResource<boolean>;
	zaehlblatt: StateResource<ZaehlblattResource>;
}

export const initialState: VerfahrenState = {
	verfahren: createEmptyStateResource(),
	verfahrenSingle: null,
	selectedVerfahren: null,
	searchQuery: {
		searchString: null,
		searchIn: Object.keys(VerfahrenSearchModeLabel),
		filterByStatus: Object.keys(VerfahrenStatus).map(k => VerfahrenStatus[k]),
	},
	downloadAmtlicherAuszugDone: createEmptyStateResource(),
	zaehlblatt: createEmptyStateResource(),
};

export function verfahrenReducer(state: VerfahrenState = initialState, action: Action): VerfahrenState {
	switch (action.type) {
		case VerfahrenActions.DOWNLOAD_AMTLICHER_AUSZUG:
			return {
				...state,
				downloadAmtlicherAuszugDone: createEmptyStateResource(true),
			};
		case VerfahrenActions.DOWNLOAD_AMTLICHER_AUSZUG_SUCCESS:
			return {
				...state,
				downloadAmtlicherAuszugDone: createStateResource(true),
			};
		case VerfahrenActions.LOAD_VERFAHREN:
			return {
				...state,
				verfahren: createEmptyStateResource(true),
			};
		case VerfahrenActions.VERFAHREN_LOADED:
			return {
				...state,
				verfahren: createStateResource((<VerfahrenLoadedAction>action).verfahrenList),
				selectedVerfahren: getFirstVerfahrenInList((<VerfahrenLoadedAction>action).verfahrenList),
			};
		case VerfahrenActions.VERFAHREN_UPDATED_SUCCESS:
			const updatedVerfahren = (<VerfahrenUpdatedSuccessAction>action).verfahren;
			return {
				...state,
				verfahren: { ...state.verfahren, reload: true },
				selectedVerfahren: sameUri(state.selectedVerfahren, updatedVerfahren) ? updatedVerfahren : state.selectedVerfahren,
				verfahrenSingle: sameUri(state.verfahrenSingle, updatedVerfahren) ? updatedVerfahren : state.verfahrenSingle,
			};
		case VerfahrenActions.LOAD_VERFAHREN_SINGLE_SUCCESS:
			return {
				...state,
				verfahrenSingle: (<LoadVerfahrenSingleSuccessAction>action).verfahren,
				zaehlblatt: createEmptyStateResource(),
				selectedVerfahren: (<LoadVerfahrenSingleSuccessAction>action).verfahren,
			};
		case VerfahrenActions.LOAD_ZAEHLBLATT_SUCCESS:
			return {
				...state,
				zaehlblatt: createStateResource((<LoadZaehlblattSuccessAction>action).zaehlblatt),
			};
		case VerfahrenActions.SEARCH_VERFAHREN:
			return {
				...state,
				verfahren: createEmptyStateResource(true),
				searchQuery: (<VerfahrenSearchAction>action).query,
			};
		case VerfahrenActions.SEARCH_VERFAHREN_SUCCESS:
			const verfahrenListResource = (<VerfahrenSearchSuccessAction>action).verfahren;
			//set first search result as selected
			let selectedVerf: VerfahrenResource = null;
			if (verfahrenListResource._embedded) {
				selectedVerf = verfahrenListResource._embedded['verfahrenList'][0];
			}
			return {
				...state,
				verfahren: createStateResource(verfahrenListResource),
				selectedVerfahren: selectedVerf,
				verfahrenSingle: null,
			};
		case AdressverwaltungActions.ASSIGN_KONTAKT_SUCCESS:
			return {
				...state,
				verfahren: { ...state.verfahren, reload: true },
				verfahrenSingle: (<AssignKontaktSuccessAction>action).verfahren,
				selectedVerfahren: (<AssignKontaktSuccessAction>action).verfahren,
			};
		case VerfahrenActions.UNASSIGN_KONTAKT_SUCCESS:
			return {
				...state,
				verfahren: { ...state.verfahren, reload: true },
				verfahrenSingle: (<UnassignKontaktSuccessAction>action).verfahren,
				selectedVerfahren: (<UnassignKontaktSuccessAction>action).verfahren,
			};
		case ROUTER_NAVIGATED:
			const routerAction = <RouterNavigationAction>action;

			if (routerAction.payload.event.url.startsWith('/verfahrenDetail')) {
				return { ...initialState, searchQuery: state.searchQuery, verfahren: createEmptyStateResource() };
			}

			return routerAction.payload.event.url.startsWith('/verfahren') ? state : initialState;
		default:
			return state;
	}
}
