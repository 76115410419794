import {EMPTY} from 'rxjs';


export function applyDefaultErrorHandling(error) {
	console.log(error);
	return EMPTY;
}

export function getEnumValues(enumeration: any): any[] {
	const values = [];

	Object.keys(enumeration).forEach(key => values.push(enumeration[key]));

	return values;
}
