import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import {getEmbeddedResource} from '@ngxp/rest';
import {isNull} from 'lodash-es';
import {Observable} from 'rxjs';
import {map, share, tap} from 'rxjs/operators';
import {
	AskToPosteingangAbschliessenAction,
	DownloadDocumentAction,
	LoadPosteingaengeAction,
	LoadPosteingangAction,
} from './posteingang.actions';
import {PosteingangListLinkRel} from './posteingang.linkrel';
import {DocumentResource, PosteingangResource} from './posteingang.model';
import {PosteingangState} from './posteingang.reducer';
import {documentsSelector, posteingaengeSelector, posteingangSelector} from './posteingang.selectors';

@Injectable()
export class PosteingangFacade {

	private emptyArray = [];

	constructor(
		private store: Store<PosteingangState>,
	) { }

	posteingang: Observable<PosteingangResource> = this.store.select(posteingangSelector);
	posteingaenge: Observable<PosteingangResource[]> = this.store.select(posteingaengeSelector)
		.pipe(
			tap(posteingaengeState => {
				if (isNull(posteingaengeState.resource) || posteingaengeState.reload) {
					this.store.dispatch(new LoadPosteingaengeAction());
				}
			}),
			map(posteingaengeState => {
				return posteingaengeState.resource ? getEmbeddedResource(posteingaengeState.resource, PosteingangListLinkRel.POSTEINGANG_LIST) : this.emptyArray;
			}),
		);
	documents: Observable<DocumentResource[]> = this.store.select(documentsSelector)
		.pipe(
			map(documents => {
				return documents ? documents : this.emptyArray;
			}),
			share(),
		);

	dispatchLoadPosteingangAction(uri: string) {
		this.store.dispatch(new LoadPosteingangAction(uri));
	}


	posteingangAbschliessen() {
		this.store.dispatch(new AskToPosteingangAbschliessenAction());
	}

	loadPosteingaenge() {
		this.store.dispatch(new LoadPosteingaengeAction());
	}

	downloadDokument(document: DocumentResource) {
		this.store.dispatch(new DownloadDocumentAction(document));
	}
}
