import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {EffectsModule} from '@ngrx/effects';
import {StoreModule} from '@ngrx/store';
import {AdminEffects} from './admin.effects';
import {adminReducerInjectionToken, adminRootFeatureState, getAdminReducer} from './admin.state';

@NgModule({
	imports: [
		CommonModule,
		EffectsModule.forFeature([AdminEffects]),
		StoreModule.forFeature(adminRootFeatureState, adminReducerInjectionToken),
	],
	providers: [
		{
			provide: adminReducerInjectionToken,
			useFactory: getAdminReducer,
		},
	],
})
export class AdminSharedModule {}
