import {Resource} from '@ngxp/rest';

export interface BinaryFile {
	name: string;
	createdAt: Date;
}

export interface BinaryFileResource extends BinaryFile, Resource {}


export enum FileType {
	VORLAGE,
	KORRESPONDENZ
}
