import {Pipe, PipeTransform} from '@angular/core';
import {hasLink, Resource} from '@ngxp/rest';

@Pipe({ name: 'hasResourceLink' })
export class HasResourceLinkPipe implements PipeTransform {
	transform(resource: Resource, link: string) {
		const result: boolean = hasLink(resource, link);
		return result;
	}
}
