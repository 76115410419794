import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import {doIfLoadingRequired, StateResource} from '@schir-int-client/ngrx-helpers';
import {Observable} from 'rxjs';
import {filter} from 'rxjs/operators';
import {
	CreateWiedervorlageAction,
	LoadFristenListeAction,
	LoadWiedervorlagenAction,
	UpdateWiedervorlageAction,
} from './wiedervorlage.actions';
import {Wiedervorlage, WiedervorlageResource} from './wiedervorlage.model';
import {WiedervorlageState} from './wiedervorlage.reducer';
import {fristenListeSelector, wiedervorlagenSelector} from './wiedervorlage.selectors';
import {Moment} from 'moment/moment';
import {DatumsBereichFacade} from './datumsbereich.facade';


@Injectable({ providedIn: 'root' })
export class WiedervorlageFacade {

	constructor(private store: Store<WiedervorlageState>, private vonFacade: DatumsBereichFacade) { }

	getWiedervorlagen(von: Moment, bis: Moment): Observable<StateResource<WiedervorlageResource[]>> {
		return this.store.select(wiedervorlagenSelector).pipe(
			filter(wiedervorlagen => !doIfLoadingRequired(wiedervorlagen, () => this.store.dispatch(new LoadWiedervorlagenAction(von, bis)))),
		);
	}

	refreshWiedervorlagen(von: Moment, bis: Moment) {
		this.store.dispatch(new LoadWiedervorlagenAction(von, bis));
		this.vonFacade.updateDatumsBereich(von, bis);
	}

	createWiedervorlage(wiedervorlage: Wiedervorlage) {
		this.store.dispatch(new CreateWiedervorlageAction(wiedervorlage));
	}

	updateWiedervorlage(wiedervorlage: Wiedervorlage) {
		this.store.dispatch(new UpdateWiedervorlageAction(wiedervorlage));
	}

	loadFristenListe() {
		this.store.dispatch(new LoadFristenListeAction());
	}

	getFristenListe(): Observable<StateResource<WiedervorlageResource[]>> {
		return this.store.select(fristenListeSelector);
	}
}
