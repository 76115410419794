import {createEmptyStateResource, StateResource} from '@schir-int-client/ngrx-helpers';
import {TextbausteinResource} from '@schir-int-client/textbaustein';

export const textbausteinFeatureState = 'TextbausteinState';

export interface TextbausteinFeatureState {
	textbausteinState: TextbausteinRootState;
}

export interface TextbausteinRootState {
	textbausteinRoot: TextbausteinState;
}

export interface TextbausteinState {
	textbausteine: StateResource<{ [targetColumn: string]: TextbausteinResource[] }>;
}

export const initialState: TextbausteinState = {
	textbausteine: createEmptyStateResource(),
};

export function getById(state: TextbausteinRootState, id: string): TextbausteinResource {
	for (let [, textbausteine] of Object.entries(state.textbausteinRoot.textbausteine.resource)) {
		for (let textbausteinResource of textbausteine) {
			if (textbausteinResource._links.self.href === id) {
				return textbausteinResource;
			}
		}
	}
	return null;
}
