import {Action} from '@ngrx/store';
import {VerfahrenResource} from '@schir-int-client/verfahren-shared';
import {
	Kontakt,
	KontakteInVerfahren,
	KontaktKategorie,
	KontaktListResource,
	KontaktResource,
	KontaktWithKategorie,
	SearchInputWithModes,
} from './adressverwaltung.model';

export enum AdressverwaltungActions {
	LOAD_KONTAKTE = 'LOAD_KONTAKTE',
	LOAD_KONTAKTE_SUCCESS = 'LOAD_KONTAKTE_SUCCESS',
	CREATE_KONTAKT = 'CREATE_KONTAKT',
	CREATE_KONTAKT_SUCCESS = 'CREATE_KONTAKT_SUCCESS',
	SEARCH_KONTAKT = 'SEARCH_KONTAKT',
	ASSIGN_KONTAKT = 'ASSIGN_KONTAKT',
	ASSIGN_KONTAKT_SUCCESS = 'ASSIGN_KONTAKT_SUCCESS',
	UPDATE_KONTAKT = 'UPDATE_KONTAKT',
	UPDATE_KONTAKT_SUCCESS = 'UPDATE_KONTAKT_SUCCESS',
	LOAD_KONTAKTE_BY_VERFAHREN = 'LOAD_KONTAKTE_BY_VERFAHREN',
	LOAD_KONTAKTE_BY_VERFAHREN_SUCCESS = 'LOAD_KONTAKTE_BY_VERFAHREN_SUCCESS',
	SET_SELECTED_KONTAKT = 'SET_SELECTED_KONTAKT',
	CHANGE_KONTAKT_EDIT_MODE = 'CHANGE_KONTAKT_EDIT_MODE',
	UPDATE_VERFAHREN_KONTAKT_NOTIZ = 'UPDATE_VERFAHREN_KONTAKT_NOTIZ',
	UPDATE_VERFAHREN_KONTAKT_NOTIZ_SUCCESS = 'UPDATE_VERFAHREN_KONTAKT_NOTIZ_SUCCESS',
	UPDATE_VERFAHREN_KONTAKT_AKTENZEICHEN = 'UPDATE_VERFAHREN_KONTAKT_AKTENZEICHEN',
	UPDATE_VERFAHREN_KONTAKT_AKTENZEICHEN_SUCCESS = 'UPDATE_VERFAHREN_KONTAKT_AKTENZEICHEN_SUCCESS',
}

export class LoadKontakteAction implements Action {
	type = AdressverwaltungActions.LOAD_KONTAKTE;
}

export class LoadKontakteSuccessAction implements Action {
	type = AdressverwaltungActions.LOAD_KONTAKTE_SUCCESS;

	constructor(public kontakte: KontaktListResource) { }
}

export class CreateKontaktAction implements Action {
	type = AdressverwaltungActions.CREATE_KONTAKT;

	constructor(public kontakt: Kontakt) { }
}

export class CreateKontaktSuccessAction implements Action {
	type = AdressverwaltungActions.CREATE_KONTAKT_SUCCESS;

	constructor(public kontakt: KontaktResource) { }
}

export class AssignKontaktAction implements Action {
	type = AdressverwaltungActions.ASSIGN_KONTAKT;

	constructor(public kategorie: KontaktKategorie) { }
}

export class AssignKontaktSuccessAction implements Action {
	type = AdressverwaltungActions.ASSIGN_KONTAKT_SUCCESS;

	constructor(public assignedToKategorie: KontaktKategorie, public verfahren: VerfahrenResource) { }
}

export class LoadKontakteByVerfahrenSuccessAction implements Action {
	type = AdressverwaltungActions.LOAD_KONTAKTE_BY_VERFAHREN_SUCCESS;

	constructor(public kontakte: KontakteInVerfahren) { }
}

export class SearchKontaktAction implements Action {
	type = AdressverwaltungActions.SEARCH_KONTAKT;

	constructor(public searchInputWithMode: SearchInputWithModes) { }
}

export class UpdateKontaktAction implements Action {
	type = AdressverwaltungActions.UPDATE_KONTAKT;

	constructor(public newKontakt: Kontakt) { }
}

export class UpdateKontaktSuccessAction implements Action {
	type = AdressverwaltungActions.UPDATE_KONTAKT_SUCCESS;

	constructor(public kontakt: KontaktResource) { }
}

export class SetSelectedKontaktAction implements Action {
	type = AdressverwaltungActions.SET_SELECTED_KONTAKT;

	constructor(public kontakt: KontaktResource) { }
}


export class LoadKontakteByVerfahrenAction implements Action {
	type = AdressverwaltungActions.LOAD_KONTAKTE_BY_VERFAHREN;

	constructor(public verfahren: VerfahrenResource) { }
}

export class ChangeKontaktEditModeAction implements Action {
	type = AdressverwaltungActions.CHANGE_KONTAKT_EDIT_MODE;

	constructor(public editMode: boolean) { }
}

export class UpdateVerfahrenKontaktNotizAction implements Action {
	type = AdressverwaltungActions.UPDATE_VERFAHREN_KONTAKT_NOTIZ;

	constructor(public kontakt: KontaktWithKategorie) {}
}

export class UpdateVerfahrenKontaktNotizSuccessAction implements Action {
	type = AdressverwaltungActions.UPDATE_VERFAHREN_KONTAKT_NOTIZ_SUCCESS;

	constructor(public kontakt: KontaktWithKategorie) {}
}

export class UpdateVerfahrenKontaktAktenzeichenAction implements Action {
	type = AdressverwaltungActions.UPDATE_VERFAHREN_KONTAKT_AKTENZEICHEN;

	constructor(public kontakt: KontaktWithKategorie) {}
}

export class UpdateVerfahrenKontaktAktenzeichenSuccessAction implements Action {
	type = AdressverwaltungActions.UPDATE_VERFAHREN_KONTAKT_AKTENZEICHEN_SUCCESS;

	constructor(public kontakt: KontaktWithKategorie) {}
}


