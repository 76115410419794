<div *ngIf="formGroup.enabled" class="grid" data-test-id="aufgabe-wiedervorlage-root">

	<div class="form-date-picker">
		<mat-form-field appearance="outline" [formGroup]="formGroup">
			<mat-label>Datum Wiedervorlage</mat-label>
			<input data-test-id="wiedervorlage-datum" matInput [matDatepicker]="picker"
				[formControl]="wiedervorlageDatumFieldControl">
			<mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
			<mat-datepicker #picker></mat-datepicker>
			<mat-error *ngIf="wiedervorlageDatumFieldControl.errors"
				data-test-id="wiedervorlage-datum-error-pattern-date">
				Datum Wiedervorlage muss ein Datum sein
			</mat-error>
		</mat-form-field>
	</div>
	<schir-int-client-notiz-in-aufgabe-editor class="row note" data-test-id="aufgabe-notiz" [aufgabenTyp]="aufgabenTyp">
	</schir-int-client-notiz-in-aufgabe-editor>
</div>