import {Selectable, SpaltenFeld} from '@schir-int-client/tech';


export interface PartiellRoetungsBereich {
	von: number;
	bis: number;
}


export interface RegisterBlattAttributeEntry {
	name: string;
	value: string;
	deleted: boolean;
	createdAt: string;
}

export interface RegisterBlattColumn {
	name?: SpaltenName,
	fieldName?: string,
	positionName?: string
	vermerkColumn?: boolean,
	label?: string;
}

export interface Seite {
	fullName: string;
	shortName: string;
	description: string;
	columns: Map<string, RegisterBlattColumn>;
}

export enum RegisterName {
	SSR = 'SSR',
	BSR = 'BSR',
	SBR = 'SBR',
	AR = 'AR',
	LR = 'LR'
}

export enum SpaltenName {
	NAME = 'NAME',
	IMO_NUMMER = 'IMO_NUMMER',
	UNTERSCHEIDUNGS_SIGNAL = 'UNTERSCHEIDUNGS_SIGNAL',
	LAENGE_UEBER_ALLES = 'LAENGE_UEBER_ALLES',
	GATTUNG_HAUPTBAUSTOFF = 'GATTUNG_HAUPTBAUSTOFF',
	LAENGE = 'LAENGE',
	BREITE = 'BREITE',
	BRUTTORAUMGEHALT_KUBIKMETER = 'BRUTTORAUMGEHALT_KUBIKMETER',
	BRUTTORAUMGEHALT_REGISTERTONNEN = 'BRUTTORAUMGEHALT_REGISTERTONNEN',
	BRUTTORAUMZAHL = 'BRUTTORAUMZAHL',
	NETTORAUMGEHALT_KUBIKMETER = 'NETTORAUMGEHALT_KUBIKMETER',
	NETTORAUMGEHALT_REGISTERTONNEN = 'NETTORAUMGEHALT_REGISTERTONNEN',
	NETTORAUMZAHL = 'NETTORAUMZAHL',
	TIEFE = 'TIEFE',
	UMFANG = 'UMFANG',
	SEITENHOEHE = 'SEITENHOEHE',
	MESSBRIEF = 'MESSBRIEF',
	VERAENDERUNGEN = 'VERAENDERUNGEN',
	FLAGGENRECHT = 'FLAGGENRECHT',
	STAPELLAUF = 'STAPELLAUF',
	HEIMATHAFEN = 'HEIMATHAFEN',
	MASCHINENLEISTUNG = 'MASCHINENLEISTUNG',
	EIGENTUEMER_SCHIFFSPARTEN = 'EIGENTUEMER_SCHIFFSPARTEN',
	TAG_EINTRAGUNG = 'TAG_EINTRAGUNG',
	LOESCHGRUND = 'LOESCHGRUND',
	UEBEREINSTIMMUNG = 'UEBEREINSTIMMUNG',
	MIGRATION = 'MIGRATION',
	ERWERBSGRUND = 'ERWERBSGRUND',
	LOESCHUNGEN = 'LOESCHUNGEN',
	BELASTUNG = 'BELASTUNG',
	VERAENDERUNGEN_HYPOTHEKEN = 'VERAENDERUNGEN_HYPOTHEKEN',
	TRAGFAEHIGKEIT = 'TRAGFAEHIGKEIT',
	EICHSCHEIN = 'EICHSCHEIN',
	AUFSCHRIFT = 'AUFSCHRIFT',
	URKUNDE = 'URKUNDE',
	EIGENTUMSNACHWEIS = 'EIGENTUMSNACHWEIS',

	// LFPR
	LUFTFAHRZEUG = 'LUFTFAHRZEUG',

	BLATT_LUFTFAHRZEUG_ROLLE = 'BLATT_LUFTFAHRZEUG_ROLLE',
	STAATSANGEHOERIGKEIT = 'STAATSANGEHOERIGKEIT',
	ART = 'ART',
	MUSTER = 'MUSTER',
	WERKNUMMER = 'WERKNUMMER',
	EIGENTUEMER_ERWERBSGRUND = 'EIGENTUEMER_ERWERBSGRUND'
}

export enum SpaltenNameLabelSSR {
	NAME = '"Name"',
	IMO_NUMMER = '"IMO-Nummer"',
	UNTERSCHEIDUNGS_SIGNAL = '"Unterscheidungssignal"',
	LAENGE_UEBER_ALLES = '"Länge über alles"',
	GATTUNG_HAUPTBAUSTOFF = '"Gattung, Hauptbaustoff"',
	LAENGE = '"Länge"',
	BREITE = '"Breite"',
	BRUTTORAUMGEHALT_KUBIKMETER = '"Bruttoraumgehalt in Kubikmetern"',
	BRUTTORAUMGEHALT_REGISTERTONNEN = '"Bruttoraumgehalt in Registertonnen"',
	BRUTTORAUMZAHL = '"Bruttoraumzahl"',
	NETTORAUMGEHALT_KUBIKMETER = '"Nettoraumgehalt in Kubikmetern"',
	NETTORAUMGEHALT_REGISTERTONNEN = '"Nettoraumgehalt in Registertonnen"',
	NETTORAUMZAHL = '"Nettoraumzahl"',
	TIEFE = '"Tiefe"',
	UMFANG = '"Umfang"',
	SEITENHOEHE = '"Seitenhöhe"',
	MESSBRIEF = '"Messbrief"',
	VERAENDERUNGEN = '"Veränderungen"',
	FLAGGENRECHT = '"Flaggenrecht"',
	STAPELLAUF = '"Stapellauf, Bauort, Schiffswerft"',
	HEIMATHAFEN = '"Heimathafen"',
	MASCHINENLEISTUNG = '"Maschinenleistung"',
	EIGENTUEMER_SCHIFFSPARTEN = '"Eigentümer, Korrespondentreeder, Schiffsparten"',
	TAG_EINTRAGUNG = '"Tag der Eintragung"',
	LOESCHGRUND = '"Löschgrund"',
	UEBEREINSTIMMUNG = '"Übereinstimmung"',
	MIGRATION = '"Migration"',
	ERWERBSGRUND = '"Erwerbsgrund, Grundlage der Eintragung, Eigentumsbeschränkungen, Veränderungen"',
	LOESCHUNGEN = '"Löschungen"',
	BELASTUNG = '"Belastung"',
	VERAENDERUNGEN_HYPOTHEKEN = '"Veränderungen"',
	TRAGFAEHIGKEIT = '"Tragfähigkeit, Wasserverdrängung"',
	EICHSCHEIN = '"Eichschein"',
	AUFSCHRIFT = '"Aufschrift"',
	URKUNDE = '"Urkunde"',
	EIGENTUMSNACHWEIS = '"Eigentumsnachweis"'
}

enum SpaltenNameLabelBSR {
	HEIMATHAFEN = '"Heimatort"',
	EIGENTUEMER_SCHIFFSPARTEN = '"Eigentümer"',
}

enum SpaltenNameLabelSBR {
	NAME = '"Name, Nummer oder sonstige Bezeichnung, Gattung"',
	STAPELLAUF = '"Bauort, Schiffswerft"',
	EIGENTUEMER_SCHIFFSPARTEN = '"Eigentümer, Korrespondentreeder"',
}

export enum SpaltenNameLabelLFPR {
	BLATT_LUFTFAHRZEUG_ROLLE = 'Blatt der Luftfahrzeugrolle',
	STAATSANGEHOERIGKEIT = 'Staatsangehörigkeit- und Eintragungszeichen',
	ART = 'Art',
	MUSTER = 'Muster',
	WERKNUMMER = 'Werknummer der Zelle'
}


export const SPALTEN_NAMEN_LABELS_SSR = {
	...SpaltenNameLabelSSR,
};

export const SPALTEN_NAMEN_LABELS_BSR = {
	...SpaltenNameLabelSSR,
	...SpaltenNameLabelBSR,
};

export const SPALTEN_NAMEN_LABELS_SBR = {
	...SpaltenNameLabelSSR,
	...SpaltenNameLabelSBR,
};

export const gattungen: string[] = [
	'Fahrgastschiff',
	'Traditionsschiff',
	'Ro-Ro-Schiff',
	'Frachtschiff',
	'Stückgutfrachter',
	'Kühlschiff',
	'Vielzweckfrachtschiff',
	'Versorgungsschiff',
	'Autotransportschiff',
	'Bohrinselversorgungsschiff',
	'Massengutschiff',
	'Öltanker',
	'Produktentankschiff',
	'Tankschiff',
	'Flüssiggastanker',
	'Chemikalientankschiff',
	'Fischereifahrzeug',
	'Schlepper',
	'Eisbrecher',
	'Personenzubringerboot',
	'Lotsenschiff',
	'Leichter',
	'Schute',
	'Ponton',
	'Sportboot',
	'Segelyacht',
	'Segelyacht (Katamaran)',
	'Motoryacht',
	'Motorsegelyacht',
	'Sonstige',
	'Containerschiff',
	'Tagesausflugsschiff',
	'Mehrzweckfrachtschiff',
].sort();

export const hauptbaustoffe: string[] = [
	'Stahl',
	'GFK (Glasfaserverstärkter Kunststoff)',
	'Kunststoff',
	'Holz',
	'Aluminium',
	'Zement',
	'Komposit',
	'Sonstiges',
	'Glasfaser',
	'Beton',
	'Eisen',
];

export const erwerbsarten: string[] = [
	'Ankauf',
	'Ankauf aus dem Ausland',
	'Kauf',
	'Neubau',
	'Sicherungsübereignung',
];

export const genehmigungen: string[] = [
	'Anmeldung',
	'Bewilligung',
	'Einigung',
	'Antrag',
];

export const vollstreckungstitel: string[] = [
	'des Vollstreckungsbescheids',
	'des Urteils',
	'des Beschlusses',
	'des Vergleichs',
	'der notariellen Urkunde',
];

export const vollstreckungsquelle: string[] = [
	'Amtsgericht',
	'anderes Gericht',
];

export const namensaenderunsgrund: string[] = [
	'Ersuchens des Luftfahrt Bundesamtes in Braunschweig',
	'des Auszugs aus dem Familienbuch',
	'des Auszugs aus dem Handelsregister',
	'des Auszugs aus dem Genossenschaftsregister',
	'des Auszugs aus dem Vereinsregister',
];

export const vGerichte: string[] = [
	'Amtsgerichts Braunschweig',
	'Landgerichts Braunschweig',
];

export const loeschungen2Lfpr: string[] = [
	'Widerspruch',
	'Widerspruch gem. § 82 LuftfzgG',
	'Zwangsversteigerungsvermerk',
	'Verfügungsbeschränkung',
	'Eröffnung des Verfahrens',
	'Schutzvermerk von Amts wegen',
];

export const verfuegungBescheinigungLfpr: string[] = [
	'Aufgrund der einstweiligen Verfügung',
	'Unter Bezugnahme auf die Bescheinigung',
];

export const absaetze: string[] = [
	'1',
	'2',
];

export const gerichtsarten: string[] = [
	'Amtsgerichts',
	'Landgerichts',
];

export const haftungen: string[] = [
	'Das Schiff haftet mit',
	'Das Schiff ist aus der Mithaft entlassen',
	'Die Schiffe haften mit',
	'Die Schiffe sind aus der Mithaft entlassen',
];

export const einheiten: string[] = [
	'kW',
	'PS',
	'',
];

export const aussteller: string[] = [
	'Bundesamt für Seeschifffahrt und Hydrographie',
	'DNV GL',
	'American Bureau of Shipping',
	'Bureau Veritas',
	'China Classification Society',
	'Croatian Register of Shipping',
	'Det Norske Veritas',
	'Germanischer Lloyd',
	'Indian Register of Shipping',
	'Korean Register of Shipping',
	'Lloyds Register of Shipping',
	'ClassNK',
	'Polish Register of Shipping',
	'Registro Italiano Navale',
	'Russian Maritime Register of Shipping',
];

export const veranlassungen: string[] = [
	'Vollstreckungsbescheid',
	'Urteil',
	'Beschluss',
];

export const rangordnung: string[] = [
	'im Range vor',
	'im Range nach',
	'im Gleichrang mit',
];

export const heimathaefen: string[] = [
	'Aachen',
	'Amberg',
	'Ansbach',
	'Arnsberg',
	'Aschaffenburg',
	'Augsburg',
	'Aurich',
	'Bad Hersfeld',
	'Bad Homburg v.d. Höhe',
	'Bad Kreuznach',
	'Bad Oeynhausen',
	'Bamberg',
	'Bayreuth',
	'Berlin',
	'Bielefeld',
	'Bochum',
	'Bonn',
	'Braunschweig',
	'Bremen',
	'Charlottenburg',
	'Chemnitz',
	'Coburg',
	'Coesfeld',
	'Cottbus',
	'Darmstadt',
	'Deggendorf',
	'Dortmund',
	'Dresden',
	'Duisburg',
	'Düren',
	'Düsseldorf',
	'Eschwege',
	'Essen',
	'Flensburg',
	'Frankfurt (Oder)',
	'Frankfurt am Main',
	'Freiburg im Breisgau',
	'Friedberg (Hessen)',
	'Fritzlar',
	'Fulda',
	'Fürth',
	'Gelsenkirchen',
	'Gießen',
	'Göttingen',
	'Gütersloh',
	'Hagen',
	'Hamburg',
	'Hamm',
	'Hanau',
	'Hannover',
	'Haren (Ems)',
	'Hildesheim',
	'Hof',
	'Ingolstadt',
	'Iserlohn',
	'Jena',
	'Kaiserslautern',
	'Kassel',
	'Kempten',
	'Kiel',
	'Kleve',
	'Koblenz',
	'Korbach',
	'Krefeld',
	'Köln',
	'Königstein im Taunus',
	'Landau in der Pfalz',
	'Landshut',
	'Leipzig',
	'Lemgo',
	'Limburg a.d. Lahn',
	'Ludwigshafen am Rhein',
	'Lübeck',
	'Lüneburg',
	'Mainz',
	'Mannheim',
	'Marburg',
	'Memmingen',
	'Montabaur',
	'Mönchengladbach',
	'München',
	'Münster',
	'Neubrandenburg',
	'Neuruppin',
	'Neuss',
	'Nürnberg',
	'Offenbach am Main',
	'Oldenburg (Oldenburg)',
	'Osnabrück',
	'Paderborn',
	'Passau',
	'Pinneberg',
	'Potsdam',
	'Recklinghausen',
	'Regensburg',
	'Rostock',
	'Saarbrücken',
	'Schweinfurt',
	'Schwerin',
	'Siegburg',
	'Siegen',
	'Stadthagen',
	'Steinfurt',
	'Stendal',
	'Stralsund',
	'Straubing',
	'Stuttgart',
	'Tostedt',
	'Traunstein',
	'Ulm',
	'Walsrode',
	'Weiden in der Oberpfalz',
	'Wetzlar',
	'Wiesbaden',
	'Wittlich',
	'Wuppertal',
	'Würzburg',
	'Zweibrücken',
];

export const loeschgruende: string[] = [
	'Wegen Verlustes des Rechts zur Führung der Bundesflagge',
	'Wegen Verzichts auf die freiwillige Eintragung',
	'Wegen Verlegung des Heimatortes in das Ausland',
	'Wegen Abwrackung des Schiffes',
	'Wegen Fertigstellung des Schiffsbauwerks',
	'Wegen § 22 SchRegO von Amts wegen',
];

export const aenderungen: string[] = [
	'Namensänderung',
	'Neuvermessung',
	'Neue Maschinenleistung',
	'Neueichung'];

export const loeschungen: string[] = [
	'Verlustes des Rechts zur Führung der Bundesflagge',
	'Verzichts auf die freiwillige Eintragung',
	'Verlegung des Heimatortes in das Ausland',
	'Fertigstellung des Schiffsbauwerks'];

export const hypotheken: string[] = [
	'Schiffshypothek',
	'Höchstbetragsschiffshypothek'];

export const raenge: string[] = [
	'Rang vor',
	'Rang nach',
	'Gleichrang mit',
];

export const abteilungen: string[] = [
	'Abteilung II',
	'Abteilung III',
];

export const beschraenkungsart: string[] = [
	'Verfügungsbeschränkung gemäß §21 Abs.2 Nr.2 InsO gegen die Gläubigerin: Verfügungen des Schuldners sind nur mit Zustimmung des vorläufigen Insolvenzverwalters wirksam.',
	'Allgemeines Verfügungsverbot gemäß § 21 Abs. 2 Nr. 2 InsO gegen die Gläubigerin.',
];

export const beschraenkungsart_label: string[] = [
	'Verfügungsbeschränkung',
	'Allgemeines Verfügungsverbot',
];

export enum FreigabeText {
	EINGETRAGEN_AM = 'Eingetragen am',
	EINGETRAGEN_AM_LC = 'eingetragen am',
	GELOESCHT_AM = 'Gelöscht am',
	GELOESCHT_AM_LC = 'gelöscht am',
	NEU_GEFASST_AM = 'Neu gefasst am',
	NEU_GEFASST_AM_LC = 'neu gefasst am',
	FREIGEGEBEN_AM = 'Freigegeben am',
	FREIGEGEBEN_AM_LC = 'freigegeben am',
	GESCHLOSSEN_AM = 'Geschlossen am',
	GESCHLOSSEN_AM_LC = 'geschlossen am',
	UE_VERMERK = 'Die Übereinstimmung mit dem Inhalt des bisherigen Blattes wird bescheinigt. \nEingetragen am'
}

export enum WeitereFreigabeText {
	HIER_GELOESCHT_AM_LC = 'hier gelöscht am',
	AM_LC = 'am',
	HIER_GELOESCHT_AM = 'Hier gelöscht am',
	WIDERSPRUCH_GELOESCHT_AM = 'Widerspruch gelöscht am',
	VON_AMTS_WEGEN_BERICHTIGT = 'Von Amts wegen berichtigt',
	VON_AMTS_WEGEN_BERICHTIGT_LC = 'von Amts wegen berichtigt'
}

export function toSelectables(values: string[]): Selectable[] {
	return values.map(v => <Selectable>{ label: v.replaceAll('&nbsp;', ' '), value: v });
}

export enum EigentuemerColumnFields {
	laufendeNummer = 'laufendeNummer',
	schiffsparten = 'schiffsparten',
	freitext = 'freitext',
	weitererFreitext = 'weitererFreitext',
	laufendeNummerAnhang = 'laufendeNummerAnhang',
	eigentuemerName = 'name',
	geburtsDatum = 'geburtsDatum',
	firmenSitz = 'firmenSitz',
	registergerichtSitz = 'registergerichtSitz',
	handelsregisterNummer = 'handelsregisterNummer',
	anteilSchiff = 'anteilSchiff',
	eigentuemerSelect = 'eigentuemerSelect',
	// LFPR
	anteil = 'anteil',
	laufendeNummerEigentuemer = 'laufendeNummerEigentuemer'
}

export const prefixBySpaltenName = {
	[SpaltenName.IMO_NUMMER]: 'a) ',
	[SpaltenName.UNTERSCHEIDUNGS_SIGNAL]: 'b) ',
	[SpaltenName.TIEFE]: 'aa) ',
	[SpaltenName.UMFANG]: 'bb) ',
	[SpaltenName.SEITENHOEHE]: 'cc) ',
};

export interface FachlicheSpalte {
	spaltenNamen: SpaltenName[];
	felder: SpaltenFeld[];
	hasSignatureHint: boolean;
	platzhalterFelder: SpaltenPlatzhalterFeld[];
	physischeSpalten: PhysischeSpalte[];
	freigabeTextMap?: Map<number, FreigabeText | WeitereFreigabeText>;
}

export interface PhysischeSpalte {
	isVermerkSpalte: boolean;
	isSignatureHintSpalte: boolean;
	isFluentSignatureHint?: (RegisterName, ChangeEntryResource) => boolean;
	isPartiellRoetenSpalte?: boolean;
	positionName: (RegisterName) => string;
	formatter: (ChangeEntryResource) => string;
	fieldName?: string;
}

export interface SpaltenPlatzhalterFeld extends SpaltenFeld {}

export interface SpaltenPlatzhalterSimpleFeld extends SpaltenPlatzhalterFeld {
	type: string,
	hidden?: boolean,
	values?,
	defaultValue?
}

export interface SpaltenPlatzhalterComplexFeld extends SpaltenPlatzhalterFeld {
	felder: SpaltenPlatzhalterSimpleFeld[];
}

export interface MultiSpaltenFeld extends SpaltenFeld {
	spaltenName: SpaltenName;
}
