import {InjectionToken} from '@angular/core';
import {ActionReducerMap} from '@ngrx/store';
import {ReducerConfig} from '@schir-int-client/test-helpers';
import {adressverwaltungReducer, AdressverwaltungState} from './adressverwaltung.reducer';

export const adressverwaltungFeatureState = 'AdressverwaltungState';
export const adressverwaltungReducerInjectionToken = new InjectionToken<ActionReducerMap<AdressverwaltungRootState>>('Registered AdressverwaltungReducer');

export interface AdressverwaltungRootState {
	adressverwaltungRoot: AdressverwaltungState;
}

export function getAdressverwaltungReducer(): ActionReducerMap<AdressverwaltungRootState> {
	return {
		adressverwaltungRoot: adressverwaltungReducer,
	};
}

export const reducerConfig: ReducerConfig<AdressverwaltungRootState> = {
	reducers: getAdressverwaltungReducer(),
	injectionToken: adressverwaltungReducerInjectionToken,
};
