import {Component} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {
	DocumentResource,
	PosteingangFacade,
	PosteingangResource,
	PosteingangStatusLabel,
} from '@schir-int-client/posteingang-shared';
import {VerfahrenFacade, VerfahrenResource} from '@schir-int-client/verfahren-shared';
import {getUriFromPath} from '@schir-int-client/tech';
import {Observable} from 'rxjs';
import {
	AktenzeichenRenderer,
	AktenzeichenRendererProvider,
	AktenzeichenUtilService,
} from '@schir-int-client/aktenzeichen-shared';

@Component({
	selector: 'schir-int-client-posteingang-bearbeiten',
	templateUrl: './posteingang-bearbeiten.component.html',
	styleUrls: ['./posteingang-bearbeiten.component.scss'],
})
export class PosteingangBearbeitenComponent {
	posteingang: Observable<PosteingangResource>;
	documents: Observable<DocumentResource[]>;
	selectedVerfahren: Observable<VerfahrenResource>;
	posteingangStatusLabel = PosteingangStatusLabel;
	private aktenzeichenRenderer: AktenzeichenRenderer;

	constructor(
		private facade: PosteingangFacade,
		private route: ActivatedRoute,
		private verfahrenFacade: VerfahrenFacade,
		private aktenzeichenRendererProvider: AktenzeichenRendererProvider,
		private aktenzeichenUtil: AktenzeichenUtilService,
	) {
		this.posteingang = this.facade.posteingang;
		this.documents = this.facade.documents;
		const posteingangUri = getUriFromPath(this.route, 'posteingangUri');
		this.facade.dispatchLoadPosteingangAction(posteingangUri);
		this.selectedVerfahren = this.verfahrenFacade.selectedVerfahren;
		this.aktenzeichenRenderer = aktenzeichenRendererProvider.getRenderer();
	}

	abschliessen() {
		this.facade.posteingangAbschliessen();
	}

	renderAktenzeichen(longAktenzeichen: string): string {
		if (this.aktenzeichenUtil.isValidAktenzeichen(longAktenzeichen)) {
			const aktenzeichen = this.aktenzeichenUtil.parse(longAktenzeichen);

			return this.aktenzeichenRenderer.render(aktenzeichen);
		}

		return longAktenzeichen;
	}
}
