import {ROUTER_NAVIGATION} from '@ngrx/router-store';
import {Action} from '@ngrx/store';
import {getUrl} from '@ngxp/rest';
import {createEmptyStateResource, createStateResource, StateResource} from '@schir-int-client/ngrx-helpers';
import {
	LoadAssigendPosteingaengeAction,
	LoadAssigendPosteingaengeSuccessAction,
	PosteingaengeLoadedAction,
	PosteingangActions,
	PosteingangLoadedAction,
} from './posteingang.actions';
import {PosteingangListResource, PosteingangResource} from './posteingang.model';

export interface PosteingangState {
	posteingang: PosteingangResource;
	posteingaenge: StateResource<PosteingangListResource>;
	assignedPosteingaenge: { [assignedTo: string]: StateResource<PosteingangListResource> };
}

export const initialState: PosteingangState = {
	posteingang: null, posteingaenge: createEmptyStateResource<PosteingangListResource>(), assignedPosteingaenge: {},
};

export function posteingangReducer(state: PosteingangState = initialState, action: Action): PosteingangState {
	switch (action.type) {
		case PosteingangActions.POSTEINGAENGE_LOADED:
			return {
				...state,
				posteingaenge: createStateResource((<PosteingaengeLoadedAction>action).payload),
			};
		case PosteingangActions.POSTEINGANG_LOADED:
			return {
				...state,
				posteingang: (<PosteingangLoadedAction>action).payload,
			};
		case PosteingangActions.LOAD_ASSIGNED_POSTEINGAENGE:
			return {
				...state,
				assignedPosteingaenge: {
					...state.assignedPosteingaenge,
					[getUrl((<LoadAssigendPosteingaengeAction>action).resource)]: createEmptyStateResource(true),
				},
			};
		case PosteingangActions.LOAD_ASSIGNED_POSTEINGAENGE_SUCCESS:
			const currentAction = <LoadAssigendPosteingaengeSuccessAction>action;
			return {
				...state,
				assignedPosteingaenge: {
					...state.assignedPosteingaenge,
					[getUrl(currentAction.assignedTo)]: createStateResource(currentAction.payload),
				},
			};
		case ROUTER_NAVIGATION:
			return {
				...state,
				assignedPosteingaenge: {},
			};
		default:
			return state;
	}
}
