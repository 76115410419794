import {Component, ContentChild, ElementRef, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {uniqueId} from 'lodash-es';

@Component({
	selector: 'schir-int-client-file-upload',
	templateUrl: './file-upload.component.html',
	styleUrls: ['./file-upload.component.scss'],
})
export class FileUploadComponent {

	@ContentChild('fileUploadButton', { static: true }) contentChildButton: ElementRef;

	@ViewChild('fileInput') fileInput;

	@Input() accept: string = '*/*';

	@Output() fileChanged: EventEmitter<File> = new EventEmitter();

	readonly defaultLabel: string = 'Datei hochladen';
	readonly myId: string = uniqueId();

	constructor() { }

	onFileChanged($event: Event) {
		const files = (<HTMLInputElement>$event.target).files;
		this.fileChanged.emit(files[0]);
	}

	clear() {
		this.fileInput.nativeElement.value = null;
	}

	openElement() {
		this.fileInput.nativeElement.click();
	}
}
