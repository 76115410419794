export enum BelastungFeldname {
	laufendeNummer = 'laufendeNummer',
	betrag = 'betrag',
	waehrung = 'waehrung',
	vormerkung = 'vormerkung',
	glaeubigerSelect = 'glaeubigerSelect',
	glaeubiger2Select = 'glaeubiger2Select'
}

export enum ErwerbsgrundFeldname {
	laufendeNummer = 'laufendeNummer',
	eigentumsBeschraenkung = 'eigentumsBeschraenkung',
	migrationSelected = 'migrationSelected',
	migrationGroup = 'migration',
	urspruenglichEingetragenAm = 'urspruenglichEingetragenAm',
	vawVerfuegungGroup = 'vawVerfuegung',
	verwaltungsGericht = 'verwaltungsGericht',
	aktenzeichen = 'aktenzeichen',
	beschlussAm = 'beschlussAm',
	bewilligungVerfuegungGroup = 'bewilligungVerfuegung',
	bewilligtAm = 'bewilligtAm',
	urNrTyp = 'urNrTyp',
	urNr = 'urNr',
	notarSelect = 'notarSelect',
	anteil = 'anteil',
	eigentuemerSelect = 'eigentuemerSelect',
	eigentuemer = 'eigentuemer',
	lfdNr = 'lfdNr',
	ort = 'ort',
	ersuchDatum = 'ersuchdatum',
	eroeffnungsDatum = 'eroeffnungsDatum',
	loeschungAuswahl = 'loeschungAuswahl',
	eintragungszeichen = 'eintragungszeichen',
	kennzeichen = 'kennzeichen',
	einschraenkungEigentuemerGroup = 'einschraenkungEigentuemer',
	klaeger = 'klaeger',
	verfuegungBescheinigung = 'verfuegungBescheinigung',
	ortGericht = 'ortGericht',
	datumGericht = 'datumGericht',
	olgGroup = 'olg',
	ortOlg = 'ortOlg',
	datumOlg = 'datumOlg',
	aktenzeichenOlg = 'aktenzeichenOlg',
}

export enum GattungHauptbaustoffFeldName {
	gattung = 'gattung',
	hauptbaustoff = 'hauptbaustoff'
}

export enum LoeschungenFeldName {
	laufendeNummer = 'laufendeNummer',
	betrag = 'betrag',
	betragAusgeschrieben = 'betragAusgeschrieben',
	waehrung = 'waehrung',
	waehrungAusgeschrieben = 'waehrungAusgeschrieben',
}

export enum MaschinenleistungFeldName {
	einheit = 'einheit',
	masszahl = 'masszahl'
}

export enum MessbriefFeldName {
	aussteller = 'aussteller',
	messbriefAusstellDatum = 'ausstellDatum'
}

export enum StapellaufFeldName {
	jahr = 'jahr',
	bauort = 'bauort',
	werft = 'werft'
}

export enum VeraenderungenHypothekenFeldName {
	laufendeNummer = 'laufendeNummer',
	betrag = 'betrag',
	waehrung = 'waehrung'
}
