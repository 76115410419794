import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {getUrl} from '@ngxp/rest';
import {createStateResource, StateResource} from '@schir-int-client/ngrx-helpers';
import {PosteingangResource} from '@schir-int-client/posteingang-shared';
import {
	VorgangFacade,
	VorgangResource,
	VorgangService,
	VorgangStatusLabel,
	VorgangTypLabel,
	VorgangTypLabelLFPR,
	VorgangTypLabelSchiff
} from '@schir-int-client/vorgang-shared';
import {
	DialogService,
	EnumToLabelPipe,
	HandlesBackdropClickAndEscapeKey,
	JaNeinLabels,
	NotizDialogComponent,
} from '@schir-int-client/tech';
import {Observable, of, Subscription} from 'rxjs';
import {ChangeVorgangStatusDialogComponent} from '../change-vorgang-status-dialog/change-vorgang-status-dialog.component';
import {ActivatedRoute, Router} from '@angular/router';
import {ApiRootFacade} from '@schir-int-client/api-root';
import {isNil} from 'lodash-es';
import {take} from 'rxjs/operators';

@Component({
	selector: 'schir-int-client-vorgang-in-verfahren',
	templateUrl: './vorgang-in-verfahren.component.html',
	styleUrls: ['./vorgang-in-verfahren.component.scss'],
})
export class VorgangInVerfahrenComponent extends HandlesBackdropClickAndEscapeKey<NotizDialogComponent | ChangeVorgangStatusDialogComponent>
	implements OnInit, OnDestroy {

	@Input() vorgang: VorgangResource;

	@Input() selected: boolean;
	@Output() selectedChange: EventEmitter<VorgangResource> = new EventEmitter<VorgangResource>();
	@Output() notizChange: EventEmitter<string> = new EventEmitter<string>();

	vorgangsTypen: VorgangTypLabel;
	vorgangStatusLabel = VorgangStatusLabel;
	private subscriptions: Subscription[] = [];
	deletable : Observable<boolean>;

	posteingaenge: Observable<StateResource<PosteingangResource[]>> = of(createStateResource<PosteingangResource[]>([], false));

	constructor(private vorgangFacade: VorgangFacade,
	            protected dialogService: DialogService,
	            private enumToLabel: EnumToLabelPipe,
	            private router: Router,
	            private activeRoute: ActivatedRoute,
	            private service: VorgangService,
	            private apiRootFacade: ApiRootFacade) {
		super(dialogService);
	}

	ngOnInit(): void {
		if (this.selected) {
			this.setSelectedVorgang();
			const vorgang = this.getVorgang();
			// do not navigate if already on that vorgang:
			if (!this.router.url.includes(vorgang)) {
				this.router.navigate([{
					outlets: {
						vorgang: vorgang,
						verfuegung: null,
						aufgabe: null,
					},
				}], { relativeTo: this.activeRoute });
			}
		}

		this.subscriptions.push(this.apiRootFacade.getCurrentApiRoot().subscribe(apiRoot =>
			this.vorgangsTypen = apiRoot.features.lfpr ? VorgangTypLabelLFPR : VorgangTypLabelSchiff,
		));

		this.deletable = this.vorgangFacade.isDeletable(this.vorgang);
	}

	ngOnDestroy() {
		this.subscriptions.forEach(s => s.unsubscribe());
	}

	setSelectedVorgang() {
		this.selectedChange.emit(this.vorgang);
	}

	openNotizDialog() {
		const title = 'Notizen zu Vorgang ' + this.enumToLabel.transform(this.vorgang.typ, this.vorgangsTypen);

		this.matDialogRef = this.dialogService.openEditorDialog(this, NotizDialogComponent, {
			data: {
				title: title,
				notiz: this.vorgang.notiz,
			},
		});

		const editor: NotizDialogComponent = <NotizDialogComponent>this.matDialogRef.componentInstance;

		this.subscriptions.push(editor.notizEvent.pipe(take(1)).subscribe(data => {
			this.updateNotiz(data);
		}));
	}

	updateNotiz(notiz: string) {
		this.notizChange.emit(notiz);
	}

	getVorgang() {
		return btoa(getUrl(this.vorgang));
	}

	openStatusDialog() {
		this.setSelectedVorgang();
		this.dialogService.openEditorDialog(this, ChangeVorgangStatusDialogComponent, {
			data: {
				vorgang: this.vorgang,
			},
		});
	}

	loadPosteingaenge() {
		this.posteingaenge = this.vorgangFacade.loadPosteingaenge(this.vorgang);
	}

	statusChangeAllowed() {
		return this.service.getAllowedNextStatus(this.vorgang).length > 1;
	}

	openDeleteDialog() {
		let typ = this.vorgang.typ;
		if (VorgangTypLabelSchiff[typ]) {
			typ = VorgangTypLabelSchiff[typ];
		} else if (VorgangTypLabelLFPR[typ]) {
			typ = VorgangTypLabelLFPR[typ];
		}

		const message = 'Möchten Sie den ausgewählten Vorgang ' + typ + ' wirklich löschen?';
		const additionalContents = isNil(this.vorgang.notiz) ? [] : ['Notiz: ' + this.vorgang.notiz];

		this.dialogService.openDialogYesNo(message, JaNeinLabels, [], additionalContents).pipe(take(1)).subscribe(data => {
			if (data.agree) {
				this.vorgangFacade.deleteVorgang(this.vorgang);
			}
		});
	}
}
