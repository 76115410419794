import {Component} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {AufgabenTyp} from '@schir-int-client/aufgabe-shared';
import {CreateAufgabeFormService} from '../../../create-aufgabe-dialog/create-aufgabe.formservice';

@Component({
	selector: 'schir-int-client-aufgabe-schuldtitel-editor',
	templateUrl: './aufgabe-schuldtitel-editor.component.html',
	styleUrls: ['./aufgabe-schuldtitel-editor.component.scss'],
})
export class AufgabeSchuldtitelEditorComponent {

	readonly aufgabenTyp: AufgabenTyp = AufgabenTyp.LFPR_SCHULDTITEL;
	readonly formServiceClass = CreateAufgabeFormService;

	constructor(public formService: CreateAufgabeFormService) { }

	get formGroup(): UntypedFormGroup {
		return <UntypedFormGroup>this.formService.controlGroupLfprSchuldtitel;
	}

	get bodyGroup(): UntypedFormGroup {
		return <UntypedFormGroup>this.formGroup.controls[CreateAufgabeFormService.feldAufgabeBody];
	}
}
