import {Component, Inject} from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA} from '@angular/material/legacy-dialog';
import {DialogButtonType, DialogDataSelection} from '@schir-int-client/dialog-shared';

@Component({
	selector: 'schir-int-client-dialog-selection',
	templateUrl: './dialog-selection.component.html',
	styleUrls: ['./dialog-selection.component.scss'],
})
export class DialogSelectionComponent {

	constructor(@Inject(MAT_DIALOG_DATA) public data: DialogDataSelection) {}

	select(selection: number, keyboard: boolean) {
		this.data.selection = selection;
		this.data.keyboard = keyboard;
	}

	checkSaveButton(buttonType) {
		return buttonType === DialogButtonType.SAVE;
	}

	isDisabled(idx: number): boolean {
		if (this.data.disabled && this.data.disabled.length >= idx) {
			return this.data.disabled[idx];
		}

		return false;
	}
}
