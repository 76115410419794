<header>
	<h1 class="sr-only">Schiffsregister</h1>
	<schir-int-client-navbar [apiRoot]="apiRoot | async" [currentUser]="currentUser | async" *ngIf="isReady | async"></schir-int-client-navbar>
</header>

<main class="l-container" *ngIf="isReady | async">
	<router-outlet></router-outlet>
</main>

<schir-int-client-error-page class="l-container" *ngIf="isError | async"></schir-int-client-error-page>
