import {createFeatureSelector, createSelector} from '@ngrx/store';
import {statistikFeatureState, StatistikRootState} from './statistik.state';

export const selectFeature = createFeatureSelector<StatistikRootState>(statistikFeatureState);

export const statistikSelector = createSelector(selectFeature, (state: StatistikRootState) => {
	return state.statistikRoot.statistik;
});

export const monthlyStatistikSelector = createSelector(selectFeature, (state: StatistikRootState) => {
	return state.statistikRoot.monthlyStatistik;
});

export const zaehlblattStatistikSelector = createSelector(selectFeature, (state: StatistikRootState) => {
	return state.statistikRoot.zaehlblattStatistik;
});
