import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
import {ApiRootFacade} from '@schir-int-client/api-root';
import {LoadVorgangByLinkAction, vorgangSelector} from '@schir-int-client/vorgang-shared';
import {map, mergeMap, switchMap, tap, withLatestFrom} from 'rxjs/operators';
import {
	CreateWiedervorlageAction,
	CreateWiedervorlageSuccessAction,
	LoadFristenListeSuccessAction,
	LoadWiedervorlagenAction,
	LoadWiedervorlagenSuccessAction,
	UpdateWiedervorlageAction,
	UpdateWiedervorlageSuccessAction,
	WiedervorlageActions,
} from './wiedervorlage.actions';
import {WiedervorlageLinkRel, WiedervorlageListLinkRel} from './wiedervorlage.linkrel';
import {WiedervorlageService} from './wiedervorlage.service';
import {WiedervorlageListResource, WiedervorlageResource} from '@schir-int-client/wiedervorlage-shared';
import {getEmbeddedResource} from '@ngxp/rest';
import {ClipboardService} from '@schir-int-client/tech';

@Injectable()
export class WiedervorlageEffects {
	constructor(
		private actions: Actions,
		private service: WiedervorlageService,
		private apiRootFacade: ApiRootFacade,
		private store: Store<any>,
		private clipboardService: ClipboardService,
	) {}


	create = createEffect(() => this.actions.pipe(
		ofType(WiedervorlageActions.CREATE_WIEDERVORLAGE),
		withLatestFrom(this.store.select(vorgangSelector)),
		switchMap(([action, vorgang]) =>
			this.service.create(vorgang.resource, (<CreateWiedervorlageAction>action).wiedervorlage).pipe(
				mergeMap(wiedervorlage => [
					new CreateWiedervorlageSuccessAction(wiedervorlage),
					new LoadVorgangByLinkAction(wiedervorlage, WiedervorlageLinkRel.VORGANG)]),
			)),
	));


	update = createEffect(() => this.actions.pipe(
		ofType(WiedervorlageActions.UPDATE_WIEDERVORLAGE),
		withLatestFrom(this.store.select(vorgangSelector)),
		switchMap(([action, vorgang]) =>
			this.service.update(vorgang.resource, (<UpdateWiedervorlageAction>action).wiedervorlage).pipe(
				mergeMap(wiedervorlage => [
					new UpdateWiedervorlageSuccessAction(wiedervorlage),
					new LoadVorgangByLinkAction(wiedervorlage, WiedervorlageLinkRel.VORGANG)]),
			)),
	));


	loadAll = createEffect(() => this.actions.pipe(
		ofType(WiedervorlageActions.LOAD_WIEDERVORLAGEN),
		withLatestFrom(this.apiRootFacade.apiRoot),
		switchMap(([action, apiRoot]) => {
			return this.service.getAll(apiRoot, (<LoadWiedervorlagenAction>action).von, (<LoadWiedervorlagenAction>action).bis).pipe(
				map(wiedervorlagen => new LoadWiedervorlagenSuccessAction(wiedervorlagen)),
			);
		}),
	));


	loadFristenListe = createEffect(() => this.actions.pipe(
		ofType(WiedervorlageActions.LOAD_FRISTENLISTE),
		withLatestFrom(this.apiRootFacade.apiRoot),
		switchMap(([, apiRoot]) => {
			return this.service.getFristenListe(apiRoot).pipe(
				tap(resource => this.copyFristenListeToClipboard(resource)),
				map(resource => new LoadFristenListeSuccessAction(resource)));
		}),
	));

	copyFristenListeToClipboard(value: WiedervorlageListResource) {
		let text = '';
		const wiedervorlagen: WiedervorlageResource[] = getEmbeddedResource(value, WiedervorlageListLinkRel.WIEDERVORLAGE_LIST);

		if (wiedervorlagen && wiedervorlagen.length > 0) {
			wiedervorlagen.forEach(wvResource => {
				text = text.concat(wvResource.vorgang.aktenzeichen + '\n');
			});

			this.clipboardService.copyAndShowSnackBar(text, 'Die Fristenliste wurde in die Zwischenablage kopiert.');
		}
	}
}
