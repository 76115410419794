import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {AufgabenTyp, AufgabeVersandTaetigkeit} from '@schir-int-client/aufgabe-shared';
import {isNil} from 'lodash-es';
import {Subscription} from 'rxjs';
import {CreateAufgabeFormService} from '../../create-aufgabe-dialog/create-aufgabe.formservice';

@Component({
	selector: 'schir-int-client-aufgabe-versand-editor',
	templateUrl: './aufgabe-versand-editor.component.html',
	styleUrls: ['./aufgabe-versand-editor.component.scss'],
})
export class AufgabeVersandEditorComponent implements OnInit, OnDestroy {
	@Input() aufgabenTyp: AufgabenTyp;

	readonly versandTaetigkeit = AufgabeVersandTaetigkeit;
	readonly formServiceClass = CreateAufgabeFormService;

	vorabPerFaxControl = new UntypedFormControl(false);
	vorabPerFaxSubscription: Subscription;

	constructor(private formService: CreateAufgabeFormService) { }

	get formGroup(): UntypedFormGroup {
		return <UntypedFormGroup>this.formService.form.controls[this.aufgabenTyp];
	}

	get bodyGroup(): UntypedFormGroup {
		return <UntypedFormGroup>this.formGroup.controls[CreateAufgabeFormService.feldAufgabeBody];
	}

	get hasFax(): boolean {
		return this.formService.hasFax(this.aufgabenTyp);
	}

	get vorabPerFax(): boolean {
		return this.vorabPerFaxControl.value;
	}

	ngOnInit() {
		if (this.hasFax) {
			this.vorabPerFaxSubscription = this.vorabPerFaxControl.valueChanges.subscribe(selected =>
				selected ? this.formService.enableFaxEmpfaenger(this.aufgabenTyp) : this.formService.disableFaxEmpfaenger(this.aufgabenTyp),
			);
			const faxEmfaenger = !isNil(this.formService.getFaxEmpfaengerControl(this.aufgabenTyp).value);
			this.vorabPerFaxControl.setValue(faxEmfaenger);
		}
	}

	ngOnDestroy() {
		if (!isNil(this.vorabPerFaxSubscription)) {
			this.vorabPerFaxSubscription.unsubscribe();
		}
	}
}
