<fieldset class="grid" *ngIf="aufgabenTyp && formGroup.enabled" data-test-id="aufgabe-versand-root">
	<legend [attr.aria-label]="'Einstellungen zu '+aufgabenTyp"></legend>
	<div class="form-date-picker">
		<mat-form-field appearance="outline" [formGroup]="formGroup">
			<mat-label>Aufbewahren bis</mat-label>
			<input data-test-id="wiedervorlage-datum" matInput [matDatepicker]="picker"
				   [formControl]="aufbewahrenBisFieldControl">
			<mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
			<mat-datepicker #picker></mat-datepicker>
			<mat-error *ngIf="aufbewahrenBisFieldControl.errors"
					   data-test-id="wiedervorlage-datum-error-pattern-date">
				Der Wert "Aufbewahren bis" muss ein Datum sein.
			</mat-error>
		</mat-form-field>
	</div>
	<schir-int-client-notiz-in-aufgabe-editor class="row note" data-test-id="aufgabe-notiz" [aufgabenTyp]="aufgabenTyp">
	</schir-int-client-notiz-in-aufgabe-editor>
</fieldset>

