import {Component, EventEmitter, Input, Output} from '@angular/core';
import {getUrl} from '@ngxp/rest';
import {StateResource} from '@schir-int-client/ngrx-helpers';
import {PosteingangResource} from '@schir-int-client/posteingang-shared';
import {VorgangLinkRel, VorgangResource} from '@schir-int-client/vorgang-shared';


@Component({
	selector: 'schir-int-client-posteingang-selector',
	templateUrl: './posteingang-selector.component.html',
	styleUrls: ['./posteingang-selector.component.scss'],
})
export class PosteingangSelectorComponent {

	@Input() vorgang: VorgangResource;
	@Input() posteingaenge: StateResource<PosteingangResource[]>;

	@Output() loadPosteingaenge: EventEmitter<void> = new EventEmitter<void>();


	constructor() { }

	getPosteingangByVorgang(vorgang: VorgangResource) {
		return btoa(getUrl(vorgang, VorgangLinkRel.POSTEINGANG));
	}

	getPosteingangRouterLink(posteingang: PosteingangResource) {
		return btoa(getUrl(posteingang));
	}
}
