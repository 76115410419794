import {NgModule} from '@angular/core';
import {RestModule} from '@ngxp/rest';
import {ENVIRONMENT_CONFIG} from '@schir-int-client/environment';
import {ApiRootService, REMOTE_HOST} from './api-root.service';

export function remoteHostFactory(environment: any) {
	return environment.remoteHost;
}

@NgModule({
	imports: [RestModule],
	providers: [
		ApiRootService,
		{
			provide: REMOTE_HOST,
			useFactory: remoteHostFactory,
			deps: [ENVIRONMENT_CONFIG],
		},
	],
})
export class ApiRootModule {}
