import {createFeatureSelector, createSelector, MemoizedSelector} from '@ngrx/store';
import {getEmbeddedResource} from '@ngxp/rest';
import {StateResource} from '@schir-int-client/ngrx-helpers';
import {isNull} from 'lodash-es';
import {WiedervorlageListLinkRel} from './wiedervorlage.linkrel';
import {WiedervorlageResource} from './wiedervorlage.model';
import {wiedervorlageFeatureState, WiedervorlageRootState} from './wiedervorlage.state';

export const selectFeature = createFeatureSelector<WiedervorlageRootState>(wiedervorlageFeatureState);

const EMPTY_ARRAY = [];

export const wiedervorlagenSelector: MemoizedSelector<object, StateResource<WiedervorlageResource[]>> =
	createSelector(selectFeature, (state: WiedervorlageRootState) => {
		const selected = state.wiedervorlageRoot.wiedervorlagen;

		if (isNull(selected.resource)) {
			return { ...selected, resource: EMPTY_ARRAY };
		}
		return {
			...selected,
			resource: <WiedervorlageResource[]>getEmbeddedResource(selected.resource, WiedervorlageListLinkRel.WIEDERVORLAGE_LIST),
		};
	});

export const fristenListeSelector: MemoizedSelector<object, StateResource<WiedervorlageResource[]>> =
	createSelector(selectFeature, (state: WiedervorlageRootState) => {
		const selected = state.wiedervorlageRoot.fristenListe;

		if (isNull(selected.resource)) {
			return { ...selected, resource: EMPTY_ARRAY };
		}
		return {
			...selected,
			resource: <WiedervorlageResource[]>getEmbeddedResource(selected.resource, WiedervorlageListLinkRel.WIEDERVORLAGE_LIST),
		};
	});
