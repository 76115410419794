import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {getUrl, Resource} from '@ngxp/rest';
import {saveAs} from 'file-saver';
import {isNil} from 'lodash-es';
import {Observable} from 'rxjs';
import {BinaryFileLinkRel} from './binary.linkrel';
import {BinaryFileResource} from './binary.model';

@Injectable({ providedIn: 'root' })
export class BinaryService {
	constructor(private httpClient: HttpClient) { }

	public changeFile(resource: Resource, linkRel: string, file: File): Observable<any> {
		const formData: FormData = new FormData();

		formData.append('file', <Blob>file, file.name);

		return this.httpClient.post(getUrl(resource, linkRel), formData, { observe: 'response' });
	}

	public downloadFile(file: BinaryFileResource) {
		let headers = new HttpHeaders();
		headers = headers.set('Accept', 'application/*');

		this.httpClient.get<Blob>(getUrl(file, BinaryFileLinkRel.DOWNLOAD), { headers, responseType: 'blob' as 'json' })
			.subscribe(data => {
				if (!isNil(data)) {
					saveAs(data, file.name);
				}
			});
	}
}
