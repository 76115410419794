import {Component, OnDestroy, OnInit} from '@angular/core';
import * as moment from 'moment';
import {StatistikFacade, StatistikResource} from '@schir-int-client/statistik-shared';
import {Observable, Subscription} from 'rxjs';
import {ClipboardService, DialogService, HandlesBackdropClickAndEscapeKey, ToUmlautsPipe} from '@schir-int-client/tech';
import {MatLegacyTableDataSource as MatTableDataSource} from '@angular/material/legacy-table';
import {MonatsStatistikComponent} from './monatsstatistik/monats-statistik.component';
import {AdminFacade} from '@schir-int-client/admin-shared';
import {ApiRootFacade, ApiRootResource} from '@schir-int-client/api-root';
import {RegisterName} from '@schir-int-client/register-shared';

@Component({
	selector: 'schir-int-client-statistik',
	templateUrl: './statistik.component.html',
	styleUrls: ['./statistik.component.scss'],
})
export class StatistikComponent extends HandlesBackdropClickAndEscapeKey<MonatsStatistikComponent> implements OnInit, OnDestroy {
	displayedColumns: string[] = [];
	today: string = moment().format('DD.MM.YYYY - HH:mm');

	dataSource: MatTableDataSource<any[]> = new MatTableDataSource<any[]>();

	csvString: string;

	statistik: Observable<StatistikResource>;
	private subscriptions: Subscription[] = [];
	werte: StatistikResource;

	private exportableRegister: string[];


	constructor(private statistikFacade: StatistikFacade,
	            protected dialogService: DialogService,
	            private adminFacade: AdminFacade,
	            private clipboardService: ClipboardService,
	            private apiRootFacade: ApiRootFacade) {
		super(dialogService);

		this.statistik = statistikFacade.statistik;
	}

	ngOnInit(): void {
		this.statistikFacade.loadStatistik();
		this.subscriptions.push(this.statistik.subscribe(value => {
			this.werte = value;
			this.prepareTableData(value);
		}));

		this.statistikFacade.loadMonthlyStatistik();

		this.subscriptions.push(this.apiRootFacade.getCurrentApiRoot().subscribe(apiRootResource => {
			this.adjustForLFPR(apiRootResource);
		}));
	}

	private adjustForLFPR(apiRootResource: ApiRootResource) {
		if (apiRootResource.features.lfpr) {
			this.displayedColumns = ['Status', 'LR', 'AR'];
			this.exportableRegister = [RegisterName.LR, RegisterName.AR];
		} else {
			this.displayedColumns = ['Status', 'SSR', 'BSR', 'SBR', 'AR'];
			this.exportableRegister = [RegisterName.SSR, RegisterName.SBR, RegisterName.BSR, RegisterName.AR];
		}
	}

	ngOnDestroy() {
		this.subscriptions.forEach(s => s.unsubscribe());
	}

	private prepareTableData(werte: StatistikResource) {
		const status = ['Abgelehnt', 'Vorlaeufig', 'Geloescht', 'Geschlossen', 'Eingetragen'];

		let tableEntries: any[] = [];

		for (let s in status) {
			let entry = {};
			entry['status'] = status[s];
			for (let key in werte) {
				entry[key.toLowerCase()] = werte[key][status[s].toUpperCase()];
			}
			tableEntries.push(entry);
		}
		this.addSummenZeile(werte, tableEntries);
		this.dataSource.data = tableEntries;
	}

	private addSummenZeile(werte: StatistikResource, tableEntries: any[]) {
		let summenZeile = { status: 'Summe' };

		for (let key in werte) {
			const values: number[] = Object.values(werte[key]);
			summenZeile[key.toLowerCase()] = values.reduce((p, c) => p + c, 0);
		}
		tableEntries.push(summenZeile);
	}

	exportTagesStatistik() {
		const pipe = new ToUmlautsPipe();
		this.csvString = '';

		for (let register of this.exportableRegister) {
			this.csvString += this.formatString(register + ',') + moment().format('DD.MM.YYYY') + '\n';

			let summe = 0;
			for (let statusValue in this.werte[register]) {
				const amount = this.werte[register][statusValue];
				this.csvString += this.formatString(pipe.transform(statusValue.toLowerCase()) + ',') + amount + '\n';
				summe += amount;
			}
			this.csvString += '\n' + this.formatString('Summe,') + summe + '\n\n';
		}

		this.clipboardService.copyAndShowSnackBar(this.csvString, 'Die Tages-Statistik wurde in die Zwischenablage kopiert.');
	}

	formatString(text: string): string {
		return text + new Array(14 - text.length).join(' ');
	}

	exportMonatsStatistik() {
		this.dialogService.openEditorDialog(this, MonatsStatistikComponent);
	}
}
