import {AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';
import {KontaktOption, KontaktSearchMode, SearchInputWithModes} from '@schir-int-client/adressverwaltung-shared';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {AccessibilityService} from '@schir-int-client/tech';
import {Subject} from 'rxjs';
import {ApiRootFacade} from '@schir-int-client/api-root';

@Component({
	selector: 'schir-int-client-kontakt-search-field',
	templateUrl: './kontakt-search-field.component.html',
	styleUrls: ['./kontakt-search-field.component.scss'],
})
export class KontaktSearchFieldComponent implements OnInit, OnChanges, AfterViewInit {

	@Input() searchParameters: SearchInputWithModes;

	@Output() doSearch: EventEmitter<SearchInputWithModes> = new EventEmitter();
	@Output() confirmSearch: EventEmitter<string> = new EventEmitter();
	@Output() toggleKontaktDarstellung: EventEmitter<string> = new EventEmitter();

	behoerden: KontaktSearchMode = KontaktSearchMode.BEHOERDEN;
	inaktiv: KontaktSearchMode = KontaktSearchMode.INAKTIV;
	aktiv: KontaktSearchMode = KontaktSearchMode.AKTIV;
	juristisch: KontaktSearchMode = KontaktSearchMode.JURISTISCH;
	natuerlich: KontaktSearchMode = KontaktSearchMode.NATUERLICH;
	audioAlert: Subject<string> = new Subject<string>();
	kontaktdarstellung: KontaktOption = KontaktOption.DARSTELLUNG;

	formGroup = new UntypedFormGroup({
		behoerden: new UntypedFormControl(false),
		juristisch: new UntypedFormControl(false),
		natuerlich: new UntypedFormControl(false),
		inaktiv: new UntypedFormControl(false),
		aktiv: new UntypedFormControl(false),
		[this.kontaktdarstellung]: new UntypedFormControl(false),
	});

	constructor(public accessibilityService: AccessibilityService, private apiRootFacade: ApiRootFacade) {}

	ngOnInit() {
		this.formControl(this.kontaktdarstellung).setValue(this.apiRootFacade.isLFPR);

		this.setSearchModes();
		this.onSearchModeChange();
	}

	ngOnChanges() {
		if (this.searchParameters == null) {
			this.setDefaultSearchParams();
		}
		this.search();
	}

	ngAfterViewInit() {
		this.accessibilityService.focusElementById('kontakt-search-input');
	}

	private setSearchModes() {
		if (this.searchParameters == null) {
			this.setDefaultSearchParams();
		} else {
			for (let kontaktSearchModeKey in KontaktSearchMode) {
				if (this.searchParameters.searchModes.indexOf(KontaktSearchMode[kontaktSearchModeKey]) != -1) {
					this.formControl(KontaktSearchMode[kontaktSearchModeKey]).setValue(true);
				}
			}
		}
	}

	private setDefaultSearchParams() {
		this.searchParameters = {
			searchString: '',
			searchModes: [KontaktSearchMode.NATUERLICH, KontaktSearchMode.JURISTISCH, KontaktSearchMode.BEHOERDEN, KontaktSearchMode.AKTIV],
		};
	}

	formControl(control) {
		return <UntypedFormControl>this.formGroup.controls[control];
	}

	standardFiltersSelected() {
		return (this.formControl('behoerden').value && this.formControl('juristisch').value
			&& this.formControl('natuerlich').value && !this.formControl('inaktiv').value
			&& this.formControl('aktiv').value);
	}

	onSearchModeChange() {
		this.searchParameters.searchModes = [];
		this.formControl('natuerlich').value ? this.searchParameters.searchModes.push(this.natuerlich) : '';
		this.formControl('juristisch').value ? this.searchParameters.searchModes.push(this.juristisch) : '';
		this.formControl('behoerden').value ? this.searchParameters.searchModes.push(this.behoerden) : '';
		this.formControl('aktiv').value ? this.searchParameters.searchModes.push(this.aktiv) : '';
		this.formControl('inaktiv').value ? this.searchParameters.searchModes.push(this.inaktiv) : '';
		this.search();
	}

	clearSearchField() {
		this.searchParameters.searchString = '';
		this.search();
	}

	search() {
		this.doSearch.emit(<SearchInputWithModes>{
			searchString: this.searchParameters.searchString,
			searchModes: this.searchParameters.searchModes,
		});
	}

	toggleFilterElementViaEnter(control) {
		let currentValue = this.formControl(control).value;
		this.formControl(control).setValue(!currentValue);
		this.onSearchModeChange();
	}

	confirm() {
		this.confirmSearch.emit();
	}

	checkBoxLabel(label: string, valueKey: string) {
		return 'Anklickbar ' + label + ' Kontrollfeld ' + (this.formControl(valueKey).value ? '' : 'nicht ') + 'aktiviert';
	}
}
