import {StateResource} from '@schir-int-client/ngrx-helpers';
import {SignatureResource, SignatureValidatorResponseResource, SignaturTaskResource} from './signatur.model';

export const signaturFeatureState = 'SignatureState';

export interface SignaturRootState {
	signatur: SignaturState;
}

export interface SignaturState {
	signaturTask: SignaturTaskResource;
	inProgressDialog: {
		isOpen: boolean
	};
	signatures: { [signatureUri: string]: StateResource<SignatureResource> };
	signatureValidatorResponse: StateResource<SignatureValidatorResponseResource>;
}
