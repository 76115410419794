<schir-int-client-spinner [stateResource]="verfahren">
	<cdk-virtual-scroll-viewport class="c-list" itemSize="72" [minBufferPx]="1024" [maxBufferPx]="2048">
		<ul class="c-list">
			<schir-int-client-verfahren-single *cdkVirtualFor="let singleVerfahren of getVerfahrenArray()"
											   [verfahren]="singleVerfahren">
			</schir-int-client-verfahren-single>
		</ul>
		<div class="center" *ngIf="hasMore()">
			<button mat-stroked-button matDialogClose data-test-id="mehr-button" class="more-elements-button focus-box-shadow-dark" matTooltip="Weitere Verfahren anzeigen" aria-label="Weitere Verfahren anzeigen" (click)="loadAllVerfahren()">Mehr</button>
		</div>
	</cdk-virtual-scroll-viewport>
</schir-int-client-spinner>
