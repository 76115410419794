import {Action} from '@ngrx/store';
import {CreateVorlage, VorlageListResource, VorlageResource} from './vorlage.model';

export enum VorlageActions {
	LOAD_VORLAGEN = 'LOAD_VORLAGEN',
	LOAD_VORLAGEN_SUCCESS = 'LOAD_VORLAGEN_SUCCESS',
	SAVE_VORLAGE = 'SAVE_VORLAGE',
	SAVE_VORLAGE_SUCCESS = 'SAVE_VORLAGE_SUCCESS',
	DELETE_VORLAGE = 'DELETE_VORLAGE',
	LOAD_VORLAGE = 'LOAD_VORLAGE',
	LOAD_VORLAGE_SUCCESS = 'LOAD_VORLAGE_SUCCESS'
}

export class LoadVorlagenAction implements Action {
	type = VorlageActions.LOAD_VORLAGEN;
}

export class VorlagenLoadedAction implements Action {
	type = VorlageActions.LOAD_VORLAGEN_SUCCESS;

	constructor(public payload: VorlageListResource) { }
}

export class LoadVorlageAction implements Action {
	type = VorlageActions.LOAD_VORLAGE;
}

export class VorlageLoadedAction implements Action {
	type = VorlageActions.LOAD_VORLAGE_SUCCESS;

	constructor(public vorlage: VorlageResource) { }
}

export class SaveVorlageAction implements Action {
	type = VorlageActions.SAVE_VORLAGE;

	constructor(public vorlage: CreateVorlage) { }
}

export class VorlageSavedAction implements Action {
	type = VorlageActions.SAVE_VORLAGE_SUCCESS;

	constructor(public vorlage: VorlageResource) { }
}

export class DeleteVorlageAction implements Action {
	type = VorlageActions.DELETE_VORLAGE;

	constructor(public vorlage: VorlageResource) { }
}
