import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {EffectsModule} from '@ngrx/effects';
import {StoreModule} from '@ngrx/store';
import {VorlageEffects} from './vorlage.effects';
import {getVorlageReducer, vorlageFeatureState, vorlageReducerInjectionToken} from './vorlage.state';

@NgModule({
	imports: [
		CommonModule,
		EffectsModule.forFeature([VorlageEffects]),
		StoreModule.forFeature(vorlageFeatureState, vorlageReducerInjectionToken),
	],
	providers: [
		{
			provide: vorlageReducerInjectionToken,
			useFactory: getVorlageReducer,
		},
	],
})
export class VorlageSharedModule {}
