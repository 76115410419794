import {Component, OnDestroy} from '@angular/core';
import {getEmbeddedResource, getUrl} from '@ngxp/rest';
import {BinaryFileLinkRel} from '@schir-int-client/binary-shared';
import {DialogService} from '@schir-int-client/tech';
import {VorlageFacade, VorlageLinkRel, VorlageMessages, VorlageResource} from '@schir-int-client/vorlage-shared';
import {Observable, Subscription} from 'rxjs';

@Component({
	selector: 'schir-int-client-vorlage-list',
	templateUrl: './vorlage-list.component.html',
	styleUrls: ['./vorlage-list.component.scss'],
})
export class VorlageListComponent implements OnDestroy {

	vorlageResources: Observable<VorlageResource[]>;
	dialogSubscription: Subscription;

	constructor(
		private facade: VorlageFacade,
		private dialogService: DialogService,
	) {
		this.vorlageResources = this.facade.vorlagen;
	}

	public openSubmitDialog(vorlage: VorlageResource): void {
		this.dialogSubscription = this.dialogService.openDialogYesNo(VorlageMessages.ASK_DELETE_VORLAGE).subscribe(data => {
			if (data.agree) {
				this.facade.deleteVorlage(vorlage);
			}
		});
	}

	public onFileChanged(vorlage: VorlageResource, event: Event) {
		const file = (<HTMLInputElement>event.target).files.item(0);
		this.facade.changeFile(vorlage, file);
	}

	public open(vorlage: VorlageResource) {
		location.href = getUrl(getEmbeddedResource(vorlage, VorlageLinkRel.FILE), BinaryFileLinkRel.DOWNLOAD);
	}

	ngOnDestroy() {
		if (this.dialogSubscription) {
			this.dialogSubscription.unsubscribe();
		}
	}
}
