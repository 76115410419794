import {
	AufgabenTyp,
	AufgabeRechnungsstellungErledigung,
	AufgabeStatus,
	AufgabeVersandTaetigkeit,
	AufgabeZertifikatTaetigkeit,
} from './aufgabe.model';

export enum AufgabeMessages {
	CREATE_AUFGABE_TYPE_MISSING = 'Bitte wählen sie den Typ der Aufgabe aus',
	DELETE_AUFGABE = 'Möchten Sie diese Aufgabe wirklich löschen?'
}

export const AufgabeTypLabel = {
	[AufgabenTyp.ALTER_AUSZUG]: 'Alten Auszug',
	[AufgabenTyp.ALTES_ZERTIFIKAT]: 'Altes Zertifikat',
	[AufgabenTyp.RECHNUNGSSTELLUNG]: 'Rechnungsstellung',
	[AufgabenTyp.KOSTEN]: 'Kosten',
	[AufgabenTyp.ZERTIFIKAT]: 'Zertifikat / Schiffsbrief',
	[AufgabenTyp.ZERTIFIKATSAUSZUG]: 'Zertifikatsauszug',
	[AufgabenTyp.ABSCHRIFT_ZERTIFIKATSAUSZUG]: 'Begl. Abschrift Zertifikatsauszug',
	[AufgabenTyp.SONSTIGE]: 'Sonstige',
	[AufgabenTyp.ABLAGE]: 'Ablage',
	[AufgabenTyp.HYPOTHEKENBESCHEINIGUNG]: 'Hypothekenbescheinigung',
	[AufgabenTyp.EIGENTUEMERBESCHEINIGUNG]: 'Eigentümerbescheinigung',
	[AufgabenTyp.LOESCHUNGSBESCHEINIGUNG]: 'Löschungsbescheinigung',
	[AufgabenTyp.LASTENFREIHEITSBESCHEINIGUNG]: 'Lastenfreiheitsbescheinigung',
	[AufgabenTyp.NAMENSAENDERUNGSBESCHEINIGUNG]: 'Namensänderungsbescheinigung',
	[AufgabenTyp.REGISTERABSCHRIFT]: 'Registerabschrift',
	[AufgabenTyp.EINTRAGUNGSMITTEILUNG]: 'Eintragungsmitteilung',
	[AufgabenTyp.MESSBRIEF]: 'Messbrief',
	[AufgabenTyp.WIEDERVORLAGE]: 'Wiedervorlage',
	[AufgabenTyp.ANSCHREIBEN]: 'Anschreiben',
	[AufgabenTyp.LFPR_REGISTERAKTE_ANLEGEN]: 'Registerakte anlegen',
	[AufgabenTyp.LFPR_SCHULDTITEL]: 'Schuldtitel',
	[AufgabenTyp.LFPR_MITTEILUNG]: 'Eintragungsmitteilung fertigen',
	[AufgabenTyp.LFPR_STATISTIK]: 'Statistik',
	[AufgabenTyp.LFPR_VERWEISUNGSVERMERK]: 'Verweisungsvermerk',
	[AufgabenTyp.LFPR_DB_AKTUALISIEREN]: 'Datenbank aktualisieren',
	[AufgabenTyp.LFPR_BLATTABSCHRIFT]: 'Blattabschrift',
	[AufgabenTyp.LFPR_NEGATIVBESCHEINIGUNG]: 'Negativbescheinigung erteilen',
	[AufgabenTyp.LFPR_KOSTEN]: 'Kosten',
	[AufgabenTyp.LFPR_WIEDERVORLAGE]: 'Wiedervorlage',
	[AufgabenTyp.LFPR_ERLEDIGT]: 'Fall erledigt',
	[AufgabenTyp.LFPR_WEGLEGEN]: 'Akte weglegen',
	[AufgabenTyp.LFPR_SAMMLUNG]: 'Zur Sammlung',
};

export const AufgabeTypStaticTaetigkeitLabel = {
	[AufgabenTyp.ALTES_ZERTIFIKAT]: 'unbrauchbar machen und zur Akte nehmen',
	[AufgabenTyp.ALTER_AUSZUG]: '(nach Eingang) unbrauchbar machen und zur Akte nehmen',
};

export const AufgabeStatusLabel = {
	[AufgabeStatus.NEU]: 'Neu',
	[AufgabeStatus.ERLEDIGT]: 'Erledigt',
	[AufgabeStatus.FREIGEGEBEN]: 'Freigegeben',
};

export const AufgabeZertifikatTaetigkeitLabel = {
	[AufgabeZertifikatTaetigkeit.FERTIGEN]: 'fertigen',
	[AufgabeZertifikatTaetigkeit.BERICHTIGEN]: 'berichtigen',
	[AufgabeZertifikatTaetigkeit.NACH_EINGANG_BERICHTIGEN]: 'nach Eingang berichtigen',
};

export const AufgabeVersandTaetigkeitLabel = {
	[AufgabeVersandTaetigkeit.SENDEN_AN]: 'senden an',
	[AufgabeVersandTaetigkeit.AUSHAENDIGEN_AN]: 'aushändigen an',
};

export const AufgabeRechnungsstellungErledigungLabel = {
	[AufgabeRechnungsstellungErledigung.ERLEDIGT]: 'Erledigt',
	[AufgabeRechnungsstellungErledigung.SPAETER]: 'Ist später zu erstellen',
};
