import {HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';


export class EipConnnectionParams {
	port: number;
	clientInstanceId: string;

	public static fromHttpParams(params: HttpParams): EipConnnectionParams {
		const config: EipConnnectionParams = {
			port: params.get('port') ? parseInt(params.get('port')) : undefined,
			clientInstanceId: params.get('clientInstanceId'),
		};
		return config.port && config.clientInstanceId ? config : null;
	}
}


@Injectable({ providedIn: 'root' })
export class EipStorage {

	init() {
		const config: EipConnnectionParams = EipConnnectionParams.fromHttpParams(getParams());
		if (config) {
			this.saveConfig(config);
			console.log('Config successfully stored');
		}
	}

	setPort(port: number): void {
		localStorage.setItem('eip.port', Number(port).toString());
	}

	getPort(): number {
		const portStr: string = localStorage.getItem('eip.port');
		return portStr ? parseInt(portStr) : undefined;
	}

	setClientInstanceId(id: string): void {
		localStorage.setItem('eip.clientInstanceId', id);
	}

	getClientInstanceId(): string {
		return localStorage.getItem('eip.clientInstanceId');
	}

	setConfigured(bool: Boolean): void {
		return localStorage.setItem('eip.configured', bool.toString());
	}

	isConfigured(): boolean {
		const configured: string = localStorage.getItem('eip.configured');
		return configured && Boolean(configured).valueOf();
	}

	saveConfig(config: EipConnnectionParams) {
		this.setPort(config.port);
		this.setClientInstanceId(config.clientInstanceId);
		this.setConfigured(this.getPort() && this.getClientInstanceId() ? true : false);
	}

	clear() {
		localStorage.removeItem('eip.port');
		localStorage.removeItem('eip.clientInstanceId');
		localStorage.removeItem('eip.configured');
	}
}


function getParams(): HttpParams {
	const url = window.location.href;
	return url.includes('?') ? new HttpParams({ fromString: url.split('?')[1] }) : new HttpParams();
}
