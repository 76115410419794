import {Component, OnDestroy, OnInit} from '@angular/core';
import {BaseEditorComponent, DialogService, Selectable} from '@schir-int-client/tech';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {Subscription} from 'rxjs';
import {DateSource, GerichtProfile, GerichtProfileFacade, GerichtProfileResource} from '@schir-int-client/user-shared';

@Component({
	selector: 'schir-int-client-gericht-settings-dialog',
	templateUrl: './gericht-settings-dialog.component.html',
	styleUrls: ['./gericht-settings-dialog.component.scss'],
})
export class GerichtSettingsDialogComponent extends BaseEditorComponent implements OnInit, OnDestroy {

	form: UntypedFormGroup = new UntypedFormGroup({
		bezeichnung: new UntypedFormControl(null),
		abteilungsbezeichnung: new UntypedFormControl(null),
		ort: new UntypedFormControl(null),
		strasse: new UntypedFormControl(null),
		plzStrasse: new UntypedFormControl(null),
		postfach: new UntypedFormControl(null),
		plzPostfach: new UntypedFormControl(null),
		sprechzeiten1: new UntypedFormControl(null),
		sprechzeiten2: new UntypedFormControl(null),
		telefon: new UntypedFormControl(null),
		fax: new UntypedFormControl(null),
		internetadresse: new UntypedFormControl(null),
		iban: new UntypedFormControl(null),
		bic: new UntypedFormControl(null),
		datenschutzlink: new UntypedFormControl(null),
		rbbGericht: new UntypedFormControl(null),
		dateSource: new UntypedFormControl(null),
	});

	gerichtProfile: GerichtProfileResource;

	dateSourceSelectables: Selectable[] = [
		{
			label: 'Tagesdatum',
			value: DateSource.NOW,
		},
		{
			label: 'Tag der letzten Eintragung',
			value: DateSource.SIGNATURE,
		},
	];

	private profileSubscription: Subscription;


	constructor(public dialogService: DialogService,
	            public facade: GerichtProfileFacade) {
		super();
	}

	getFormControl(control: string) {
		return <UntypedFormControl>this.form.controls[control];
	}

	ngOnInit(): void {
		this.getGerichtsprofile();
	}

	getGerichtsprofile(): void {
		this.profileSubscription = this.facade.getGerichtProfile()
			.subscribe(gerichtProfileResource => {
				this.getFormControl('bezeichnung').setValue(gerichtProfileResource.bezeichnung);
				this.getFormControl('abteilungsbezeichnung').setValue(gerichtProfileResource.abteilungsbezeichnung);
				this.getFormControl('ort').setValue(gerichtProfileResource.ort);
				this.getFormControl('strasse').setValue(gerichtProfileResource.strasse);
				this.getFormControl('plzStrasse').setValue(gerichtProfileResource.plzStrasse);
				this.getFormControl('postfach').setValue(gerichtProfileResource.postfach);
				this.getFormControl('plzPostfach').setValue(gerichtProfileResource.plzPostfach);
				this.getFormControl('sprechzeiten1').setValue(gerichtProfileResource.sprechzeiten1);
				this.getFormControl('sprechzeiten2').setValue(gerichtProfileResource.sprechzeiten2);
				this.getFormControl('telefon').setValue(gerichtProfileResource.telefon);
				this.getFormControl('fax').setValue(gerichtProfileResource.fax);
				this.getFormControl('internetadresse').setValue(gerichtProfileResource.internetadresse);
				this.getFormControl('iban').setValue(gerichtProfileResource.iban);
				this.getFormControl('bic').setValue(gerichtProfileResource.bic);
				this.getFormControl('datenschutzlink').setValue(gerichtProfileResource.datenschutzlink);
				this.getFormControl('rbbGericht').setValue(gerichtProfileResource.rbbGericht);
				this.getFormControl('dateSource').setValue(gerichtProfileResource.dateSource);
				this.gerichtProfile = gerichtProfileResource;
			});
	}

	async submit(): Promise<boolean> {
		const newGerichtProfile: GerichtProfile = this.form.value;
		this.facade.updateGerichtProfile(newGerichtProfile);

		return true;
	}

	ngOnDestroy(): void {
		this.profileSubscription.unsubscribe();
	}
}

