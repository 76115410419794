<form (ngSubmit)="onSearch()" class="c-searcharea" [formGroup]="formService.form">
	<div class="c-searchfield focus-border-dark">
			<button class="material-icons  focus-box-shadow-dark"  type="button" [matMenuTriggerFor]="menu"  matTooltip="Suchfilter auswählen"
					aria-label="Suchfilter auswählen" aria-haspopup="menu" data-test-id="filter-button">
				<mat-icon [ngClass]="{'highlightFilter' : !allFiltersSelected}"
						  [attr.aria-label]="!allFiltersSelected ? 'Nicht alle Filter sind ausgewählt' : 'Alle Filter sind ausgewählt'">filter_alt
				</mat-icon>
			</button>
			<div class="separator"></div>
			<mat-menu #menu="matMenu" class="wide-mat-menu">
				<fieldset class="no_border">
					<legend class="visually-hidden">Allgemein, 1 von 3</legend>
					<div mat-menu-item class="focus-box-shadow-dark all-filter" (click)="toggleAllFilters($event)" (keydown.enter)="toggleAllFilters($event)">
						<mat-checkbox [formControlName]="formService.all" (click)="$event.preventDefault()" color="primary"
									  matTooltip="Suche in allen Feldern aktivieren"
									  aria-label="{{checkboxLabelAll()}}"
									  id="alle-checkbox"
									  data-test-id="alle-checkbox">Alle
						</mat-checkbox>
					</div>
				</fieldset>
				<div formGroupName="{{formService.options}}">
					<fieldset>
					<legend>Suche in Feldern</legend>
					<div mat-menu-item class="focus-box-shadow-dark" *ngFor="let searchMode of searchFields | keyvalue" (click)="onCheck($event,cb); cb.toggle()" (keyup.enter)="onCheck($event,cb); cb.toggle()">
						<mat-checkbox #cb id="{{searchMode.value}}-checkbox" (click)="$event.preventDefault()"
									  color="primary"
									  matTooltip="In {{searchMode.key | enumToLabel: searchModeLabel}} suchen"
									  [attr.aria-label]="checkboxLabelSearchMode(searchMode.key)"
									  [formControlName]="searchMode.value" attr.data-test-id="{{searchMode.value}}-checkbox"> {{searchMode.key | enumToLabel: searchModeLabel}}
						</mat-checkbox>
					</div>
					</fieldset>
					<fieldset>
						<legend>nach Vorgangsstatus filtern</legend>
					<div mat-menu-item class="focus-box-shadow-dark" *ngFor="let statusMode of statusModes | enumToArray" (click)="onCheck($event,cb); cb.toggle()" (keyup.enter)="onCheck($event, cb); cb.toggle()">
						<mat-checkbox #cb id="{{statusMode}}-checkbox" (click)="$event.preventDefault()"
									  color="primary"
									  matTooltip="Nach Status '{{statusMode | enumToLabel: statusModeLabel}}' filtern"
									  [attr.aria-label]="checkboxLabelStatus(statusMode)"
									  [formControlName]="statusMode" attr.data-test-id="{{statusMode}}-checkbox"> {{statusMode | enumToLabel: statusModeLabel}}
						</mat-checkbox>
					</div>
					</fieldset>
				</div>
			</mat-menu>
		<input type="text" [formControlName]="formService.fieldSearchString" name="searchString"
			   id="search-input" data-test-id="search-input" aria-label="Suchbegriff"
			   placeholder="Suchbegriff"/>
		<button class="material-icons focus-box-shadow-dark" type="button" (click)="formService.reset()"
				data-test-id="clear-search-button" matTooltip="Suche zurücksetzen" aria-label="Suche zurücksetzen">
			<mat-icon>close</mat-icon>
		</button>
		<button class="material-icons focus-box-shadow-dark" type="submit" data-test-id="verfahren-search-button"
				matTooltip="Verfahren suchen" aria-label="Verfahren suchen">
			<mat-icon>search</mat-icon>
		</button>
	</div>
</form>
