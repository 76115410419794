import {CommonModule, TitleCasePipe} from '@angular/common';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatLegacyButtonModule as MatButtonModule} from '@angular/material/legacy-button';
import {MatLegacyDialogModule as MatDialogModule} from '@angular/material/legacy-dialog';
import {MatLegacyFormFieldModule as MatFormFieldModule} from '@angular/material/legacy-form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatLegacyInputModule as MatInputModule} from '@angular/material/legacy-input';
import {MatLegacyProgressSpinnerModule as MatProgressSpinnerModule} from '@angular/material/legacy-progress-spinner';
import {MatLegacyTooltipModule as MatTooltipModule} from '@angular/material/legacy-tooltip';
import {AuthenticationInterceptor} from './authentication.interceptor';
import {ButtonRoundComponent} from './button-round/button-round.component';
import {DialogAcknowledgeComponent} from './dialog-acknowledge/dialog-acknowledge.component';
import {DialogYesNoComponent} from './dialog-yes-no/dialog-yes-no.component';
import {FileUploadComponent} from './file-upload/file-upload.component';
import {HttpErrorInterceptor} from './http-error.interceptor';
import {LOCATION_TOKEN} from './location.token';
import {MoreButtonComponent} from './more-button/more-button.component';
import {NotificationAlertComponent} from './notification-alert/notification-alert.component';
import {NotizDialogComponent} from './notiz-dialog/notiz-dialog.component';
import {ChannelByAdressatPipe} from './pipes/channel-by-adressat';
import {EnumToArrayPipe} from './pipes/enum-to-array';
import {EnumToLabelPipe} from './pipes/enum-to-label';
import {FormatDatePipe} from './pipes/format-date';
import {ErrorAsFormattedLinesPipe} from './pipes/format-error-as-lines';
import {HasResourceLinkPipe} from './pipes/has-resource-link';
import {TestIdFromResource} from './pipes/test-id.pipe';
import {ToResourceUriPipe} from './pipes/to-resource-uri.pipe';
import {SpinnerComponent} from './ui/spinner/spinner.component';
import {ToUmlautsPipe} from './pipes/to-umlauts';
import {FocusDirective} from './directives/focus.directive';
import {DialogFocusDirective} from './directives/dialog-focus.directive';
import {MatLegacyListModule as MatListModule} from '@angular/material/legacy-list';
import {OneValueEditorComponent} from './one-value-editor/one-value-editor.component';
import {MatLegacySelectModule as MatSelectModule} from '@angular/material/legacy-select';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatLegacyCheckboxModule as MatCheckboxModule} from '@angular/material/legacy-checkbox';
import {AutocompleteComponent} from './autocomplete/autocomplete.component';
import {MatLegacyAutocompleteModule as MatAutocompleteModule} from '@angular/material/legacy-autocomplete';
import {DialogSelectionComponent} from './dialog-selection/dialog-selection.component';
import {RouterModule} from '@angular/router';
import {KontaktAktenzeichenDialogComponent} from './kontakt-aktenzeichen-dialog/kontakt-aktenzeichen-dialog.component';
import {SanitizeHtmlPipe} from './pipes/sanitize-html';

@NgModule({
	imports: [
		CommonModule,
		MatDialogModule,
		MatButtonModule,
		MatInputModule,
		MatIconModule,
		MatTooltipModule,
		FormsModule,
		MatFormFieldModule,
		MatProgressSpinnerModule,
		MatListModule,
		ReactiveFormsModule,
		MatSelectModule,
		MatDatepickerModule,
		MatCheckboxModule,
		MatAutocompleteModule,
		RouterModule,
	],
	exports: [
		NotificationAlertComponent,
		DialogYesNoComponent,
		DialogSelectionComponent,
		NotizDialogComponent,
		KontaktAktenzeichenDialogComponent,
		ToResourceUriPipe,
		EnumToLabelPipe,
		HasResourceLinkPipe,
		FormatDatePipe,
		EnumToArrayPipe,
		SanitizeHtmlPipe,
		TestIdFromResource,
		FileUploadComponent,
		ErrorAsFormattedLinesPipe,
		ChannelByAdressatPipe,
		SpinnerComponent,
		MoreButtonComponent,
		ButtonRoundComponent,
		ToUmlautsPipe,
		FocusDirective,
		DialogFocusDirective,
		OneValueEditorComponent,
		AutocompleteComponent,
	],
	declarations: [
		NotificationAlertComponent,
		DialogYesNoComponent,
		DialogSelectionComponent,
		NotizDialogComponent,
		KontaktAktenzeichenDialogComponent,
		ToResourceUriPipe,
		EnumToLabelPipe,
		HasResourceLinkPipe,
		FormatDatePipe,
		EnumToArrayPipe,
		SanitizeHtmlPipe,
		TestIdFromResource,
		FileUploadComponent,
		ErrorAsFormattedLinesPipe,
		ChannelByAdressatPipe,
		SpinnerComponent,
		MoreButtonComponent,
		ButtonRoundComponent,
		DialogAcknowledgeComponent,
		ToUmlautsPipe,
		FocusDirective,
		DialogFocusDirective,
		OneValueEditorComponent,
		AutocompleteComponent,
	],
	providers: [
		TitleCasePipe,
		{
			provide: LOCATION_TOKEN,
			useValue: window.location,
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: HttpErrorInterceptor,
			multi: true,
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: AuthenticationInterceptor,
			multi: true,
		},
	],
})
export class TechModule {}
