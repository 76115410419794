import {Component} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {VorgangFacade, VorgangResource} from '@schir-int-client/vorgang-shared';
import {Observable} from 'rxjs';
import {map, switchMap} from 'rxjs/operators';

@Component({
	selector: 'schir-int-client-verfuegungen-in-vorgang-container',
	templateUrl: './verfuegungen-in-vorgang-container.component.html',
	styleUrls: ['./verfuegungen-in-vorgang-container.component.scss'],
	host: { 'class': 'x-section' },
})
export class VerfuegungenInVorgangContainerComponent {

	vorgang: Observable<VorgangResource>;

	constructor(
		private vorgangFacade: VorgangFacade,
		private route: ActivatedRoute,
	) {
		this.vorgang = this.route.params.pipe(
			map(route => atob(decodeURIComponent(route['vorgangId']))),
			switchMap(url => {
				vorgangFacade.setSelectedVorgangUri(url);
				return this.vorgangFacade.getSelectedVorgang();
			}),
		);
	}
}
