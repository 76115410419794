export enum VerfuegungLinkRel {
	CREATE_VERFUEGUNG = 'createVerfuegung',
	CREATE_KORRESPONDENZ_VERFUEGUNG = 'createKorrespondenzVerfuegung',
	AUFGABEN = 'aufgaben',
	CREATE_AUFGABE = 'createAufgabe',
	MARK_AS_ERLEDIGT = 'markAsErledigt',
	UPDATE_NOTIZ = 'updateNotiz',
	DRUCKEN = 'drucken',
	DELETE = 'delete'
}

export enum VerfuegungListLinkRel {
	VERFUEGUNG_LIST = 'verfuegungList'
}
