import {createFeatureSelector, createSelector, MemoizedSelector} from '@ngrx/store';
import {DefaultProjectorFn, MemoizedSelectorWithProps} from '@ngrx/store/src/selector';
import {getEmbeddedResource} from '@ngxp/rest';
import {createEmptyStateResource, createStateResource, StateResource} from '@schir-int-client/ngrx-helpers';
import {isEmpty, isNil, isNull} from 'lodash-es';
import {AlternativeTextListLinkRel} from './alternative-text.linkrel';
import {AlternativeTextResource} from './alternative-text.model';
import {alternativeTextFeatureState, AlternativeTextRootState} from './alternative-text.state';

export const selectFeature = createFeatureSelector<AlternativeTextRootState>(alternativeTextFeatureState);
const EMPTY_ARRAY = [];

export const alternativeTextSelectors: MemoizedSelector<object, StateResource<AlternativeTextResource[]>> =
	createSelector(selectFeature, (state: AlternativeTextRootState) => {
		if (!isNil(state)) {
			const selected = state.alternativeTextRoot.alternativeTexte;

			if (isNull(selected.resource)) {
				return { ...selected, resource: EMPTY_ARRAY };
			}
			return {
				...selected,
				// TODO: eine solche Link-Relation ist im Backend unbekannt. Texte können für ein Verfahren geladen werden. Es ist abzuklären,
				// ob es sich hier um eine Leiche handelt.
				resource: <AlternativeTextResource[]>getEmbeddedResource(selected.resource, AlternativeTextListLinkRel.ALT_SCHIFFSPAPIERE_TEXT_LIST),
			};
		} else {
			return createEmptyStateResource();
		}
	});

export const alternativeByUriSelector: MemoizedSelectorWithProps<object, any, AlternativeTextResource, DefaultProjectorFn<AlternativeTextResource>> =
	createSelector(selectFeature, (state: AlternativeTextRootState, props) => {
		if (state.alternativeTextRoot.alternativeTexte.loaded) {
			const alternativeTextResources: AlternativeTextResource[] = getEmbeddedResource<AlternativeTextResource[]>(state.alternativeTextRoot.alternativeTexte.resource, AlternativeTextListLinkRel.ALT_SCHIFFSPAPIERE_TEXT_LIST);

			if (isEmpty(alternativeTextResources)) {
				return null;
			}
			for (let alternativeTextResource of alternativeTextResources) {
				if (alternativeTextResource.spaltenEintragId == props.changeEntryId) {
					return alternativeTextResource;
				}
			}

			return null;
		}
	});

export const isAlternativeTexteSavedSelector: MemoizedSelector<object, StateResource<boolean>> =
	createSelector(selectFeature, (state: AlternativeTextRootState) => {
		const alternativeTexteByChangeEntry = state.alternativeTextRoot.alternativeTextByChangeEntry;

		if (isEmpty(alternativeTexteByChangeEntry)) {
			return createStateResource(true);
		}

		const existUnsavedEntries: boolean = Object.values(alternativeTexteByChangeEntry)
			.map(res => res.loading)
			.reduce((prev, curr) => curr || prev);

		return createStateResource(!existUnsavedEntries);
	});
