<nav class="navigation" data-test-id="navigation-root">
	<div class="l-container navigation__container">
		<div class="navbar">
			<a *ngIf="!apiRoot.features.lfpr"
			   class="logo"
			   data-test-id="logo"
			   (click)="showSchiRDetails()"
			   matTooltip="Details zu SchiR anzeigen"
			   attr.aria-label="Details zu SchiR anzeigen"
			   attr.aria-haspopup="dialog">
				<img src="/assets/img/logo-image-black.svg" alt="Schiffsregister-Logo"/>
			</a>

			<a *ngIf="apiRoot.features.lfpr"
			   class="logo"
			   data-test-id="logo"
			   (click)="showSchiRDetails()"
			   matTooltip="Details zu eLuPfand anzeigen"
			   attr.aria-label="Details zu eLuPfand anzeigen"
			   attr.aria-haspopup="dialog">
				<img src="/assets/img/lfpr-logo-image-black.svg" alt="eLuPfand-Logo"/>
			</a>
			<a class="c-button c-button--rounded focus-border-dark"
			   routerLink="/dashboard"
			   routerLinkActive="active"
			   data-test-id="dashboard-button"
			   [attr.aria-label]="toolTipVisible ? 'Dashboard' : null"
			   [matTooltip]="toolTipVisible ? 'Dashboard' : null">
				<i class="material-icons" aria-hidden="true">home</i>
				<span>Dashboard</span>
			</a>
			<a class="c-button c-button--rounded focus-border-dark"
			   *ifResource="apiRoot;hasLink:apiRootLinkRel.VERFAHRENS"
			   routerLink="/verfahren"
			   routerLinkActive="active"
			   data-test-id="verfahren-button"
			   [attr.aria-label]="toolTipVisible ? 'Verfahren' : null"
			   [matTooltip]="toolTipVisible ? 'Verfahren' : null">
				<i class="material-icons" aria-hidden="true">folder</i>
				<span>Verfahren</span>
			</a>
		</div>
		<schir-int-client-verfahren-global-search data-test-id="verfahren-global-search-root">
		</schir-int-client-verfahren-global-search>
		<div class="navbar">
			<a class="c-button c-button--rounded focus-box-shadow-dark" routerLink="/admin" routerLinkActive="active"
				data-test-id="admin-button" *ifResource="apiRoot;hasLink:apiRootLinkRel.ADMIN" [matTooltip]="toolTipVisible ? 'Administration' : null"
			   [attr.aria-label]="toolTipVisible ? 'Administration' : null">
				<i class="material-icons" aria-hidden="true">perm_data_setting</i>
				<span>Administration</span>
			</a>
			<schir-int-client-settings-menu [apiRoot]="apiRoot"></schir-int-client-settings-menu>
		</div>
	</div>
</nav>
