<div *ngIf="verfahren | async as _verfahren" class="c-areas" data-test-id="verfahrensbaum-root" (keydown)="switchFocus($event)">
	<div class="c-areas__area">
		<schir-int-client-vorgaenge-in-verfahren class="x-section"
			[verfahren]="_verfahren" data-test-id="vorgaenge-in-verfahren"></schir-int-client-vorgaenge-in-verfahren>
		<router-outlet name="vorgang"></router-outlet>
		<router-outlet name="verfuegung"></router-outlet>
		<router-outlet name="aufgabe"></router-outlet>
	</div>
	<div class="c-areas__area">
		<schir-int-client-kontakt-list-container></schir-int-client-kontakt-list-container>
	</div>
</div>
