import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import {isNil} from 'lodash-es';
import {Observable} from 'rxjs';
import {UserResource} from '../user.model';
import {UserProfileRootState} from './userprofile.state';
import {userProfileSelector} from './userprofile.selectors';
import {LoadUserProfileAction, UpdateUserProfileAction} from './userprofile.actions';
import {UserProfile, UserProfileResource} from './userprofile.model';
import {filter} from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class UserProfileFacade {

	profile: Observable<UserProfileResource> = this.store.select(userProfileSelector);

	constructor(private store: Store<UserProfileRootState>) {}

	getUserProfile(user: UserResource): Observable<UserProfileResource> {
		return this.store.select(userProfileSelector).pipe(
			filter(profile => {
				if (isNil(profile)) {
					this.store.dispatch(new LoadUserProfileAction(user));
					return false;
				}
				return true;
			}),
		);
	}

	updateUserProfile(profile: UserProfile) {
		this.store.dispatch(new UpdateUserProfileAction(profile));
	}
}
