<ul class="c-list">
	<schir-int-client-kontakte-in-kategorie *ngIf="eigentuemerNeuKontakte | async as _kontakte" data-test-id="eigentuemer-neu-kontakte"
											[kategorie]="kategorie.EIGENTUEMER_NEU" [kontakte]="_kontakte" [draggable]="draggable"
											[unassignable]="unassignable" [verfahren]="verfahren"
											(unassignKontakt)="unassignKontakt.emit($event)">
	</schir-int-client-kontakte-in-kategorie>
	<schir-int-client-kontakte-in-kategorie *ngIf="eigentuemerAltKontakte | async as _kontakte" data-test-id="eigentuemer-alt-kontakte"
											[kategorie]="kategorie.EIGENTUEMER_ALT" [kontakte]="_kontakte" [draggable]="draggable"
											[unassignable]="unassignable" [verfahren]="verfahren"
											(unassignKontakt)="unassignKontakt.emit($event)">
	</schir-int-client-kontakte-in-kategorie>
	<schir-int-client-kontakte-in-kategorie *ngIf="vertreterKontakte | async as _kontakte" data-test-id="vertreter-kontakte"
											[kategorie]="kategorie.VERTRETER" [kontakte]="_kontakte" [draggable]="draggable"
											[unassignable]="unassignable" [verfahren]="verfahren"
											(unassignKontakt)="unassignKontakt.emit($event)">
	</schir-int-client-kontakte-in-kategorie>
	<schir-int-client-kontakte-in-kategorie *ngIf="glaeubigerNeuKontakte | async as _kontakte" data-test-id="glaeubiger-neu-kontakte"
											[kategorie]="kategorie.GLAEUBIGER_NEU" [kontakte]="_kontakte" [draggable]="draggable"
											[unassignable]="unassignable" [verfahren]="verfahren"
											(unassignKontakt)="unassignKontakt.emit($event)">
	</schir-int-client-kontakte-in-kategorie>
	<schir-int-client-kontakte-in-kategorie *ngIf="glaeubigerAltKontakte | async as _kontakte" data-test-id="glaeubiger-alt-kontakte"
											[kategorie]="kategorie.GLAEUBIGER_ALT" [kontakte]="_kontakte" [draggable]="draggable"
											[unassignable]="unassignable" [verfahren]="verfahren"
											(unassignKontakt)="unassignKontakt.emit($event)">
	</schir-int-client-kontakte-in-kategorie>
	<schir-int-client-kontakte-in-kategorie *ngIf="notarKontakte | async as _kontakte" data-test-id="notar-kontakte"
											[kategorie]="kategorie.NOTAR" [kontakte]="_kontakte" [draggable]="draggable" [unassignable]="unassignable"
											[verfahren]="verfahren"
											(unassignKontakt)="unassignKontakt.emit($event)">
	</schir-int-client-kontakte-in-kategorie>
	<schir-int-client-kontakte-in-kategorie *ngIf="behoerdenKontakte | async as _kontakte" data-test-id="behoerden-kontakte"
											[kategorie]="kategorie.BEHOERDEN" [kontakte]="_kontakte" [draggable]="draggable"
											[unassignable]="unassignable" [verfahren]="verfahren"
											(unassignKontakt)="unassignKontakt.emit($event)">
	</schir-int-client-kontakte-in-kategorie>
	<schir-int-client-kontakte-in-kategorie *ngIf="rechtsanwaltKontakte | async as _kontakte" data-test-id="rechtsanwalt-kontakte"
											[kategorie]="kategorie.RECHTSANWALT" [kontakte]="_kontakte" [draggable]="draggable"
											[unassignable]="unassignable" [verfahren]="verfahren"
											(unassignKontakt)="unassignKontakt.emit($event)">
	</schir-int-client-kontakte-in-kategorie>
	<schir-int-client-kontakte-in-kategorie *ngIf="phgKontakte | async as _kontakte" data-test-id="phg-kontakte"
											[kategorie]="kategorie.PHG" [kontakte]="_kontakte" [draggable]="draggable" [unassignable]="unassignable"
											[verfahren]="verfahren"
											(unassignKontakt)="unassignKontakt.emit($event)">
	</schir-int-client-kontakte-in-kategorie>
	<schir-int-client-kontakte-in-kategorie *ngIf="gesellschafterKontakte | async as _kontakte" data-test-id="gesellschafter-kontakte"
											[kategorie]="kategorie.GESELLSCHAFTER" [kontakte]="_kontakte" [draggable]="draggable"
											[unassignable]="unassignable" [verfahren]="verfahren"
											(unassignKontakt)="unassignKontakt.emit($event)">
	</schir-int-client-kontakte-in-kategorie>
	<schir-int-client-kontakte-in-kategorie *ngIf="eingetragenerEigentuemerKontakte | async as _kontakte"
											data-test-id="eingetragener-eigentuemer-kontakte"
											[kategorie]="kategorie.EINGETRAGENER_EIGENTUEMER" [kontakte]="_kontakte" [draggable]="draggable"
											[unassignable]="unassignable" [verfahren]="verfahren"
											(unassignKontakt)="unassignKontakt.emit($event)">
	</schir-int-client-kontakte-in-kategorie>
	<schir-int-client-kontakte-in-kategorie *ngIf="sonstigeKontakte | async as _kontakte" data-test-id="sonstige-kontakte"
											[kategorie]="kategorie.SONSTIGE" [kontakte]="_kontakte" [draggable]="draggable"
											[unassignable]="unassignable" [verfahren]="verfahren"
											(unassignKontakt)="unassignKontakt.emit($event)">
	</schir-int-client-kontakte-in-kategorie>
</ul>
